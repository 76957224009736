import {WrapAuthentication, WrapAuthenticationForm} from "../util/AuthWrapper";
import {ActionTypes as UpdateComponent} from "./ComponentUpdateAction";

export const ActionTypes = {
    PATIENT_LIST_SUCCESS: "PATIENT_LIST_SUCCESS",
    PATIENT_SET_CONTEXT: "PATIENT_SET_CONTEXT",
    PATIENT_SET_CONTEXT_BY_ID: "PATIENT_SET_CONTEXT_BY_ID",
    PATIENT_CLEAR_CONTEXT: "PATIENT_CLEAR_CONTEXT",
    PATIENT_CREATE_PATIENT: "PATIENT_CREATE_PATIENT",
    PATIENT_SET_CREATE_PATIENT_CONTEXT: "PATIENT_SET_CREATE_PATIENT_CONTEXT",
    PATIENT_CLEAR_CREATE_PATIENT_CONTEXT: "PATIENT_CLEAR_CREATE_PATIENT_CONTEXT",
    PATIENT_LIST_VITALS: 'PATIENT_LIST_VITALS',
    PATIENT_LIST_MEDICAL_PROBLEMS: 'PATIENT_LIST_MEDICAL_PROBLEMS',
    PATIENT_LIST_ALLERGIES: 'PATIENT_LIST_ALLERGIES',
    PATIENT_LIST_MEDICATIONS: 'PATIENT_LIST_MEDICATIONS',
    PATIENT_FACILITY_ADDRESS_LIST: 'PATIENT_FACILITY_ADDRESS_LIST',
    PATIENT_SET_FACILITY: 'PATIENT_SET_FACILITY',
    PATIENT_UPDATE: 'PATIENT_UPDATE',
    PATIENT_CLEAR_UPDATE: 'PATIENT_CLEAR_UPDATE',
    PATIENT_GET_PATIENT_MODEL: 'PATIENT_GET_PATIENT_MODEL',
    PATIENT_CLEAR_MODEL_CONTEXT: 'PATIENT_CLEAR_MODEL_CONTEXT',
    PATIENT_GET_ADVANCE_DIRECTIVE: 'PATIENT_GET_ADVANCE_DIRECTIVE',
    PATIENT_ENCOUNTER_LIST_SUCCESS: 'PATIENT_ENCOUNTER_LIST_SUCCESS',
    PATIENT_REMOTE_SET_CONTEXT: "PATIENT_REMOTE_SET_CONTEXT",
    PATIENT_FAMILYHISTORY_LIST_SUCCESS: "PATIENT_FAMILYHISTORY_LIST_SUCCESS",
    GET_LOGIN_TOKEN: "GET_LOGIN_TOKEN",
    PATIENT_QUALITYMEASURE_LIST_SUCCESS: "PATIENT_QUALITYMEASURE_LIST_SUCCESS",
    PATIENT_LIST_HOSPITALIZATION: "PATIENT_LIST_HOSPITALIZATION",
    GET_PATIENT_HOSPITALIZATION_ID: "GET_PATIENT_HOSPITALIZATION_ID",
    PATIENT_LIST_SURGERY: "PATIENT_LIST_SURGERY",
    PATIENT_SURGERY_LIST: "PATIENT_SURGERY_LIST",
    GET_OBSERVATION_PERIOD: "GET_OBSERVATION_PERIOD",
    CLEAR_OBSERVATIONS_BY_COMPONENT: "CLEAR_OBSERVATIONS_BY_COMPONENT",
    ACTIVITY_LEVEL: "ACTIVITY_LEVEL",
    AH_BODY_MASS_INDEX: "AH_BODY_MASS_INDEX",
    AH_BODY_WEIGHT_MEASURED: "AH_BODY_WEIGHT_MEASURED",
    AH_BODY_WEIGHT_MEASURED_LATEST: "AH_BODY_WEIGHT_MEASURED_LATEST",
    AH_BODY_MASS_INDEX_LATEST: "AH_BODY_MASS_INDEX_LATEST",
    GET_PATIENT_BLOOD_PRESSURE: "GET_PATIENT_BLOOD_PRESSURE",
    GET_PATIENT_BLOOD_PRESSURE_DISTRIBUTION: "GET_PATIENT_BLOOD_PRESSURE_DISTRIBUTION",
    CRETAE_PATIENT: "CREATE_PATIENT",
    GET_CLINICAL_DOCUMENT: "GET_CLINICAL_DOCUMENT",
    CLEAR_GET_CLINICAL_DOCUMENT: "CLEAR_GET_CLINICAL_DOCUMENT",
    CLINICAL_DOCUMENT_DOWNLOAD: "CLINICAL_DOCUMENT_DOWNLOAD",
    CLEAR_CLINICAL_DOCUMENT_CONTEXT: "CLEAR_CLINICAL_DOCUMENT_CONTEXT",
    SET_CLINICAL_DOCUMENT_CONTEXT: "SET_CLINICAL_DOCUMENT_CONTEXT",
    GET_CLINICAL_DOCUMENT_CONTEXT: "GET_CLINICAL_DOCUMENT_CONTEXT",
    CREATE_CLINICAL_DOCUMENT: "CREATE_CLINICAL_DOCUMENT",
    HEART_VARIABILITY: "HEART_VARIABILITY",
    CLINICAL_DOCUMENT: "CLINICAL_DOCUMENT",
    EDIT_CLINICAL_DOCUMENT: "EDIT_CLINICAL_DOCUMENT",
    DELETE_CLINICAL_DOCUMENT: "DELETE_CLINICAL_DOCUMENT",
    GET_USER_PATIENT_OBSERVATION_PERIOD: "GET_USER_PATIENT_OBSERVATION_PERIOD",
    GET_USER_PATIENT_OBSERVATION_LATEST: "GET_USER_PATIENT_OBSERVATION_LATEST",
    GET_PATIENT_OBSERVATION_BY_PERIOD_HEART_RATE: "GET_PATIENT_OBSERVATION_BY_PERIOD_HEART_RATE",
    HEART_RATE_SET_TO_DATA_WEEK: "HEART_RATE_SET_TO_DATA_WEEK",
    GET_PATIENT_INSURANCE_INFORMATION: "GET_PATIENT_INSURANCE_INFORMATION",
    ADD_PATIENT_INSURANCE_INFORMATION: "ADD_PATIENT_INSURANCE_INFORMATION",
    UPDATE_PATIENT_INSURANCE_INFORMATION: "UPDATE_PATIENT_INSURANCE_INFORMATION",
    DELETE_PATIENT_INSURANCE_INFORMATION: "DELETE_PATIENT_INSURANCE_INFORMATION",
    UPDATE_PATIENT_PREFERENCE_INSURANCE_INFORMATION: "UPDATE_PATIENT_PREFERENCE_INSURANCE_INFORMATION",
    CLEAR_PATIENT_INSURANCE_INFORMATION: "CLEAR_PATIENT_INSURANCE_INFORMATION",
    GET_PATIENT_COHORT_OBSERVATION_WITH_THRESHOLD: "GET_PATIENT_COHORT_OBSERVATION_WITH_THRESHOLD",
    GET_ANALYTICAL_PROFILE_DETAILS_CARE_TEAM: "GET_ANALYTICAL_PROFILE_DETAILS_CARE_TEAM",
    GET_ANALYTICAL_PROFILE_For_PATIENT: "GET_ANALYTICAL_PROFILE_For_PATIENT",
    HOSPITAL_CLEAR_RECORDS: "HOSPITAL_CLEAR_RECORDS",
    SET_HOSPITALIZATION_CONTEXT: "SET_HOSPITALIZATION_CONTEXT",
    SET_SURGERY_CONTEXT: "SET_SURGERY_CONTEXT",
    CLEAR_HOSPITALIZATION_CONTEXT: "CLEAR_HOSPITALIZATION_CONTEXT",
    CLEAR_SURGERY_CONTEXT: "CLEAR_SURGERY_CONTEXT",
    GET_ALL_PATIENT_RELATIONSHIPS: "GET_ALL_PATIENT_RELATIONSHIPS",
    GET_PATIENT_RELATIONSHIPS: "GET_PATIENT_RELATIONSHIPS",
    DELETE_PATIENT_RELATIONSHIPS: "DELETE_PATIENT_RELATIONSHIPS",
    UPDATE_PATIENT_RELATIONSHIPS: "UPDATE_PATIENT_RELATIONSHIPS",
    ADD_PATIENT_RELATIONSHIPS: "ADD_PATIENT_RELATIONSHIPS",
    CLEAR_PATIENT_RELATIONSHIPS: "CLEAR_PATIENT_RELATIONSHIPS",
    SET_EDIT_PATIENT_TAB: "SET_EDIT_PATIENT_TAB",
    GET_OBSERVATION_ENCOUNTER: "GET_OBSERVATION_ENCOUNTER",
    GET_PATIENT_MEDICATIONS: "GET_PATIENT_MEDICATIONS",
    GET_PATIENT_ROLE_ATTRIBUTES: "GET_PATIENT_ROLE_ATTRIBUTES",

}

function setPatientContext(patient) {
    return async dispatch => {
        dispatch({type: ActionTypes.PATIENT_CLEAR_CONTEXT})
        dispatch({type: ActionTypes.PATIENT_SET_CONTEXT, payload: patient})
    }

}

function clearPatientContext() {
    return async dispatch => {
        dispatch({type: ActionTypes.PATIENT_CLEAR_CONTEXT})
        dispatch({type: ActionTypes.PATIENT_CLEAR_UPDATE})
    }

}

function getPatientById(patientRoleId) {
    return WrapAuthentication("patient/get",
        {payload: {patientRoleId: patientRoleId}},
        ActionTypes.PATIENT_GET_PATIENT_MODEL)
}

function getPatientByIdAndSetContext(patientRoleId) {
    return WrapAuthentication("patient/get",
        {payload: {patientRoleId: patientRoleId}},
        ActionTypes.PATIENT_SET_CONTEXT_BY_ID)
}

function getAssignedPortalPatient() {
    return WrapAuthentication("patient/portal/get",
        {},
        ActionTypes.PATIENT_SET_CONTEXT_BY_ID)
}

function getPatientList(limit, offset, sort, filter, allPatients = true) {
    return WrapAuthentication("patients/get", {
        payload: {allPatients: allPatients},
        sort: sort ? {sorts: sort} : null,
        filter: filter ? filter : {},
        limit: limit,
        offset: offset
    }, ActionTypes.PATIENT_LIST_SUCCESS)
}


function getEncounterList(patientRoleId, limit, offset, sort, filter) {
    return WrapAuthentication("encounter/list/get", {
        payload: {patientRoleId},
        sort: sort ? {sorts: sort} : null,
        filter: filter ? filter : {},
        limit: limit,
        offset: offset
    }, ActionTypes.PATIENT_ENCOUNTER_LIST_SUCCESS)
}

function getLoginToken(payload) {
    return WrapAuthentication("openemr/login/getToken",
        {"payload": payload},
        ActionTypes.GET_LOGIN_TOKEN)
}

//TODO: This is not correct. Needs revamped JB
function getAdvanceDirective(patientRoleId) {
    return WrapAuthentication("patient/advance/directive", {
        payload: {patientRoleId: patientRoleId},
    }, ActionTypes.PATIENT_GET_ADVANCE_DIRECTIVE)

}

function filteringPatientOnInputs(filterPatientData, limit, offset) {
    return WrapAuthentication("patient/searchByColumn",
        {
            "payload": filterPatientData, "limit": limit, "offset": offset
        },
        ActionTypes.PATIENT_LIST_SUCCESS)
}

function createPatient(patient) {
    return WrapAuthentication("patient/create",
        {"payload": patient},
        ActionTypes.PATIENT_SET_CREATE_PATIENT_CONTEXT,
        {},
        [UpdateComponent.UPDATE_PATIENT_LIST])
}

function updatePatient(patient) {
    return WrapAuthentication("patient/update",
        {"payload": patient},
        ActionTypes.PATIENT_SET_CREATE_PATIENT_CONTEXT,
        {},
        [UpdateComponent.UPDATE_PATIENT_CONTEXT, UpdateComponent.UPDATE_PATIENT_LIST])
}

function addFacilityToPatient(patient) {
    return WrapAuthentication("patient/add/facility",
        {"payload": patient},
        ActionTypes.PATIENT_SET_CREATE_PATIENT_CONTEXT)
}


function updateFacilityToPatient(patient) {
    return WrapAuthentication("patient/update/facility",
        {payload: patient},
        ActionTypes.PATIENT_SET_CREATE_PATIENT_CONTEXT)
}

function getPatientDemographicModel(patientRoleId) {
    return WrapAuthentication("patient/model/demographics",
        {payload: {patientRoleId: patientRoleId}},
        ActionTypes.PATIENT_SET_CREATE_PATIENT_CONTEXT)
}

function createEprescribePatient(payload) {
    return WrapAuthentication("veradigm/patient/create",
        {payload: payload},
        ActionTypes.CRETAE_PATIENT)
}

function getPatientMedications(payload) {
    return WrapAuthentication("veradigm/patient/medication/get",
        {payload: payload},
        ActionTypes.GET_PATIENT_MEDICATIONS)
}


function setEditPatientContext(patient) {
    return async dispatch => {
        dispatch({type: ActionTypes.PATIENT_SET_CREATE_PATIENT_CONTEXT, payload: {payload: patient}})
    }
}

function clearEditPatientContext() {
    return async dispatch => {
        dispatch({type: ActionTypes.PATIENT_CLEAR_CREATE_PATIENT_CONTEXT})
        dispatch({type: ActionTypes.PATIENT_CLEAR_UPDATE})
        dispatch({type: ActionTypes.PATIENT_CLEAR_MODEL_CONTEXT})
    }

}

function clearPatientInsurance() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_PATIENT_INSURANCE_INFORMATION})
    }
}

function getVitalData(patientRoleId) {
    return WrapAuthentication("observation/get/latest/vital", {
        payload: {
            patientRoleId: patientRoleId,
        }
    }, ActionTypes.PATIENT_LIST_VITALS)
}

function patientObservationVitals(patientRoleId, dictObservationCategoryId, offset, limit) {
    return WrapAuthentication("observation/get",
        {
            payload: {
                patientRoleId: patientRoleId, dictObservationCategoryId: dictObservationCategoryId
            },
            limit: limit,
            offset: offset
        },
        ActionTypes.PATIENT_LIST_VITALS)
}


function getMedicalProblems(patientRoleId, limit, offset) {
    return WrapAuthentication("patient/medical/problem/get",
        {
            payload: {
                patientRoleId,
            },
            limit: limit,
            offset: offset
        },
        ActionTypes.PATIENT_LIST_MEDICAL_PROBLEMS)
}


function getMedications(patientRoleId) {
    return WrapAuthentication("patient/medication/get", {
        offset: 0,
        limit: 1000,
        sort: {sorts: []},
        filter: {searchAll: null},
        payload: {patientRoleId, active: true}
    }, ActionTypes.PATIENT_LIST_MEDICATIONS)
}

function getHospitalization(patientRoleId) {
    return WrapAuthentication("patient/hospitalization/get", {
        payload: {patientRoleId}
    }, ActionTypes.PATIENT_LIST_HOSPITALIZATION)
}

function getSurgery(patientRoleId) {
    return WrapAuthentication("surgery/get", {
        payload: {patientRoleId}
    }, ActionTypes.PATIENT_LIST_SURGERY)
}

function createMedicalProblem(payload) {
    return WrapAuthentication("patient/medical/problem/create",
        {
            payload
        },
        ActionTypes.PATIENT_LIST_MEDICAL_PROBLEMS)
}

function createHospitalization(payload) {
    return WrapAuthentication("patient/hospitalization/create", {payload}, ActionTypes.GET_PATIENT_HOSPITALIZATION_ID)
    //return WrapAuthenticationForm("patient/hospitalization/create", payload, ActionTypes.PATIENT_LIST_HOSPITALIZATION)
}

function clearHospitalization() {
    return async dispatch => {
        dispatch({type: ActionTypes.HOSPITAL_CLEAR_RECORDS})
    }
}

function updateHospitalization(payload) {
    //return WrapAuthenticationForm("patient/hospitalization/update", payload, ActionTypes.PATIENT_LIST_HOSPITALIZATION)
    return WrapAuthentication("patient/hospitalization/update", {payload}, ActionTypes.PATIENT_LIST_HOSPITALIZATION)
}

function createSurgery(payload) {
    return WrapAuthentication("surgery/create", {payload}, ActionTypes.PATIENT_LIST_SURGERY)
}

function updateSurgery(payload) {
    return WrapAuthentication("surgery/update", {payload}, ActionTypes.PATIENT_LIST_SURGERY)
}


function createMedications(payload) {
    return WrapAuthentication("patient/medication/create", {
        payload
    }, ActionTypes.PATIENT_LIST_MEDICATIONS)
}

function updateMedicalProblem(payload) {
    return WrapAuthentication("patient/medical/problem/update",
        {
            payload
        },
        ActionTypes.PATIENT_LIST_MEDICAL_PROBLEMS)
}

function getAllergies(patientRoleId) {
    return WrapAuthentication("problem/allergy/get", {
        payload: {patientRoleId}
    }, ActionTypes.PATIENT_LIST_ALLERGIES)
}

function createAllergy(payload) {
    return WrapAuthentication("problem/allergy/create", {
        payload,
    }, ActionTypes.PATIENT_LIST_ALLERGIES, {}, [UpdateComponent.UPDATE_PATIENT_ALLERGIES])
}

function updateAllergy(payload) {
    return WrapAuthentication("problem/allergy/update", {
        payload
    }, ActionTypes.PATIENT_LIST_ALLERGIES, {}, [UpdateComponent.UPDATE_PATIENT_ALLERGIES])
}

function updateMedications(payload) {
    return WrapAuthentication("patient/medication/update", {
        payload
    }, ActionTypes.PATIENT_LIST_MEDICATIONS)
}


function setPatientContextRemote(patientRoleId) {
    return WrapAuthentication("patient/get",
        {
            payload: {
                patientRoleId
            }
        },
        ActionTypes.PATIENT_REMOTE_SET_CONTEXT)
}

function setPatientContextByExternalId(externalId) {
    return WrapAuthentication("patient/get/external/id",
        {
            payload: {externalId}
        },
        ActionTypes.PATIENT_REMOTE_SET_CONTEXT)
}

//TODO: This will need to be updated. We can't hard code limits.
function getFacilities() {
    return WrapAuthentication(
        "/facility/get",
        {
            payload: {},
            limit: 100,
            offset: 0,
        },
        ActionTypes.PATIENT_FACILITY_ADDRESS_LIST
    );
}

function getFamilyHistory(personId) {
    return WrapAuthentication("family/history/get",
        {
            "payload": {
                "patientRoleId": personId
            }
        }
        , ActionTypes.PATIENT_FAMILYHISTORY_LIST_SUCCESS)
}

function getQualityMeasureByPatientId(patientRoleId) {
    return WrapAuthentication("qm/byPatientId",
        {"payload": {patientRoleId: patientRoleId}},
        ActionTypes.PATIENT_QUALITYMEASURE_LIST_SUCCESS)
}

function getUserPatientObservationsByPeriod(startDate, endDate, codes, component) {
    return WrapAuthentication("observation/patients/period", {
        payload: {
            startDate,
            endDate,
            codes
        }
    }, ActionTypes.GET_USER_PATIENT_OBSERVATION_PERIOD, {component: component})
}

function getPatientObservationsByPeriod(patientRoleId, startDate, endDate, codes, component) {
    return WrapAuthentication("observation/period", {
        payload: {
            patientRoleId,
            startDate,
            endDate,
            codes
        }
    }, ActionTypes.GET_OBSERVATION_PERIOD, {component: component})

}

function getPatientObservationsByPeriodForHeartRate(patientRoleId, startDate, endDate, codes, component) {
    return WrapAuthentication("observation/period", {
        payload: {
            patientRoleId: patientRoleId,
            startDate: startDate,
            endDate: endDate,
            codes: codes
        }
    }, ActionTypes.GET_PATIENT_OBSERVATION_BY_PERIOD_HEART_RATE, {component: component})
}

function clearObservationsByComponent(component) {

    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_OBSERVATIONS_BY_COMPONENT, component: component})
    }
}


function weightMeasured(payload) {
    return WrapAuthentication("observation/period", {
        payload: payload
    }, ActionTypes.AH_BODY_WEIGHT_MEASURED)
}

function weightMeasuredLatest(payload) {
    return WrapAuthentication("observation/type/latest", {
        payload: payload
    }, ActionTypes.AH_BODY_WEIGHT_MEASURED_LATEST)
}

function bmiMeasuredLatest(payload) {
    return WrapAuthentication("observation/type/latest", {
        payload: payload
    }, ActionTypes.AH_BODY_MASS_INDEX_LATEST)
}

function weightMassIndex(payload) {
    return WrapAuthentication("observation/period", {
        payload: payload
    }, ActionTypes.AH_BODY_MASS_INDEX)
}


function getSleepObservationsByPeriod(patientRoleId, startDate, endDate, component) {
    return WrapAuthentication("observation/sleep/period", {
        payload: {
            patientRoleId,
            startDate,
            endDate,
        }
    }, ActionTypes.GET_OBSERVATION_PERIOD, {component: component})
}

function getPatientBloodPressure(payload) {
    return WrapAuthentication("observation/bloodPressure/get", {
        payload: payload
    }, ActionTypes.GET_PATIENT_BLOOD_PRESSURE)
}

function getPatientBloodPressureDistribution(payload, component) {
    return WrapAuthentication("observation/bloodPressure/distribution", {
        payload: payload
    }, ActionTypes.GET_PATIENT_BLOOD_PRESSURE_DISTRIBUTION, {component: component})
}

function getHeartVariability(payload) {
    return WrapAuthentication("observation/heart/variability", {
        payload: payload
    }, ActionTypes.HEART_VARIABILITY)
}

function getClinicalDocument(payload) {
    return WrapAuthentication("clinical/document/all", {
        payload: payload
    }, ActionTypes.GET_CLINICAL_DOCUMENT)
}

function getPatientObservationsByEncounter(payload) {
    return WrapAuthentication("observation/patients/by/encounter", {
        payload: payload
    }, ActionTypes.GET_OBSERVATION_ENCOUNTER)
}

function clinicalDocument(payload) {
    return WrapAuthenticationForm("clinical/document/create", payload, ActionTypes.CLINICAL_DOCUMENT)
}

function createClinicalDocument(payload) {
    return WrapAuthentication("clinical/document/create", {
        payload: payload
    }, ActionTypes.CREATE_CLINICAL_DOCUMENT)
}

function editClinicalDocument(payload) {
    return WrapAuthentication("clinical/document/edit", {
        payload: payload
    }, ActionTypes.EDIT_CLINICAL_DOCUMENT)
}

function deleteClinicalDocument(payload) {
    return WrapAuthentication("clinical/document/delete", {
        payload: payload
    }, ActionTypes.DELETE_CLINICAL_DOCUMENT)
}

function clinicalDocumentDownload(payload) {
    return WrapAuthentication("clinical/document/download",
        payload,
        ActionTypes.CLINICAL_DOCUMENT_DOWNLOAD)
}


function setClinicalDocumentContext(patient) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_CLINICAL_DOCUMENT_CONTEXT, payload: {payload: patient}})
    }
}

function setHospitalContext(context) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_HOSPITALIZATION_CONTEXT, payload: context})
    }
}

function setSurgeryContext(context) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_SURGERY_CONTEXT, payload: context})
    }
}

function clearHospitalContext() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_HOSPITALIZATION_CONTEXT})
    }
}

function clearSurgeryContext() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_SURGERY_CONTEXT})
    }
}

function clearClinicalDocumentContext() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_CLINICAL_DOCUMENT_CONTEXT})
    }
}

function clearGetClinicalDocument() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_GET_CLINICAL_DOCUMENT})
    }
}

function getLatestObservation(patientRoleId, code, component) {
    return WrapAuthentication("observation/type/latest", {
        payload: {
            patientRoleId: patientRoleId,
            code: code
        }
    }, ActionTypes.GET_USER_PATIENT_OBSERVATION_LATEST, {component: component})
}

function getPatientCohortLatestObservationWithThreshold(code, startDate, component) {
    return WrapAuthentication("observation/cohort/latest",
        {payload: {code, startDate}},
        ActionTypes.GET_PATIENT_COHORT_OBSERVATION_WITH_THRESHOLD, {component: component})
}

function getPatientCohortObservationSumWithThreshold(codes, startDate, endDate, component) {
    return WrapAuthentication("observation/cohort/sum",
        {payload: {codes, startDate, endDate}},
        ActionTypes.GET_PATIENT_COHORT_OBSERVATION_WITH_THRESHOLD, {component: component})
}

function getPatientCohortObservationAvgWithThreshold(codes, startDate, endDate, component) {
    return WrapAuthentication("observation/cohort/avg",
        {payload: {codes, startDate, endDate}},
        ActionTypes.GET_PATIENT_COHORT_OBSERVATION_WITH_THRESHOLD, {component: component})
}

function getPatientInsuranceInformation(payload) {
    return WrapAuthentication("patient/insurance/get", {
        payload: payload
    }, ActionTypes.GET_PATIENT_INSURANCE_INFORMATION)
}

function addPatientInsuranceInformation(payload) {
    return WrapAuthentication("patient/insurance/add", {
        payload: payload
    }, ActionTypes.ADD_PATIENT_INSURANCE_INFORMATION)
}

// function updatePatientInsuranceInformation(payload) {
//     return WrapAuthentication("patient/update/insurance/information", {
//         payload: payload
//     }, ActionTypes.UPDATE_PATIENT_INSURANCE_INFORMATION)
// }

function deletePatientInsuranceInformation(payload) {
    return WrapAuthentication("patient/insurance/delete", {
        payload: payload
    }, ActionTypes.DELETE_PATIENT_INSURANCE_INFORMATION)
}

function updatePatientInsuranceInformationPreference(payload) {
    return WrapAuthentication("patient/insurance/update/preference", {
        payload: payload
    }, ActionTypes.UPDATE_PATIENT_PREFERENCE_INSURANCE_INFORMATION)
}

function setHearRateDataForBar(HeartRateManagementData) {
    return async dispatch => {
        dispatch({type: ActionTypes.HEART_RATE_SET_TO_DATA_WEEK, payload: {payload: HeartRateManagementData}})
    }
}

function AnalyticProfileDetails(component) {
    return WrapAuthentication("deficit/get/analytic/profile/details",
        {},
        ActionTypes.GET_ANALYTICAL_PROFILE_DETAILS_CARE_TEAM, {component: component})
}

function getAnalyticProfileForPatient(payload, component) {
    return WrapAuthentication("deficit/get/analytic/profile",
        {
            payload: payload
        }, ActionTypes.GET_ANALYTICAL_PROFILE_For_PATIENT, {component: component})
}

function getPatientRelationships(payload) {
    return WrapAuthentication("patients/relationship/get/all",
        {
            payload: payload
        }, ActionTypes.GET_PATIENT_RELATIONSHIPS)
}

function getAllPatientRelationships(payload) {
    return WrapAuthentication("patients/relationship/get/all",
        {
            payload: payload
        }, ActionTypes.GET_ALL_PATIENT_RELATIONSHIPS)
}

function deletePatientRelationships(payload) {
    return WrapAuthentication("patients/relationship/delete",
        {
            payload: payload
        }, ActionTypes.DELETE_PATIENT_RELATIONSHIPS)
}

function updatePatientRelationships(payload) {
    return WrapAuthentication("patients/relationship/update",
        {
            payload: payload
        }, ActionTypes.UPDATE_PATIENT_RELATIONSHIPS)
}

function addPatientRelationships(payload) {
    return WrapAuthentication("patients/relationship/add",
        {
            payload: payload
        }, ActionTypes.ADD_PATIENT_RELATIONSHIPS)
}

function getPatientRoleAttributes(payload) {
    return WrapAuthentication("patient/role/attribute",
        {
            payload: payload
        }, ActionTypes.GET_PATIENT_ROLE_ATTRIBUTES)
}

function removePatientRoleAttribute(payload) {
    return WrapAuthentication("patient/role/attribute/remove",
        {
            payload: payload
        }, ActionTypes.GET_PATIENT_ROLE_ATTRIBUTES)
}

function addPatientRoleAttribute(payload) {
    return WrapAuthentication("patient/role/attribute/add",
        {
            payload: payload
        }, ActionTypes.GET_PATIENT_ROLE_ATTRIBUTES)
}

function clearPatientRelationships(payload) {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_PATIENT_RELATIONSHIPS})
    }
}

function setPatientEditTab(tab) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_EDIT_PATIENT_TAB, payload: tab})
    }
}

// function getActivityLevel(payload) {

//     return WrapAuthentication("observation/activityLevel",{
//         payload:payload
//     }, ActionTypes.ACTIVITY_LEVEL)
// }
export const PatientAction =
    {
        getPatientList: getPatientList,
        getAssignedPortalPatient: getAssignedPortalPatient,
        setPatientContext: setPatientContext,
        clearPatientContext: clearPatientContext,
        createPatient: createPatient,
        getPatientModel: getPatientById,
        updatePatient: updatePatient,
        updateFacilityToPatient: updateFacilityToPatient,
        clearEditPatientContext: clearEditPatientContext,
        setEditPatientContext: setEditPatientContext,
        getPatientDemographicModel,
        filteringPatientOnInputs,
        getFacilities,
        addFacilityToPatient,
        getMedicalProblems: getMedicalProblems,
        getAllergies: getAllergies,
        createMedicalProblem,
        createAllergies: createAllergy,
        updateMedicalProblem,
        updateAllergies: updateAllergy,
        getAdvanceDirective,
        getFamilyHistory,
        getEncounterList: getEncounterList,
        setPatientContextRemote: setPatientContextRemote,
        getLoginToken,
        getQualityMeasureByPatientId,
        createMedications,
        updateMedications,
        getMedications: getMedications,
        createHospitalization: createHospitalization,
        createSurgery,
        updateSurgery: updateSurgery,
        getHospitalization,
        getSurgery,
        updateHospitalization,
        patientObservationVitals: patientObservationVitals,
        setPatientContextByExternalId: setPatientContextByExternalId,
        getUserPatientObservationsByPeriod: getUserPatientObservationsByPeriod,
        getPatientObservationsByPeriod: getPatientObservationsByPeriod,
        getPatientByIdAndSetContext: getPatientByIdAndSetContext,
        weightMassIndex: weightMassIndex,
        weightMeasured: weightMeasured,
        weightMeasuredLatest: weightMeasuredLatest,
        bmiMeasuredLatest: bmiMeasuredLatest,
        //getActivityLevel:getActivityLevel
        getSleepObservationsByPeriod: getSleepObservationsByPeriod,
        getPatientBloodPressure: getPatientBloodPressure,
        getPatientBloodPressureDistribution: getPatientBloodPressureDistribution,
        createEprescribePatient: createEprescribePatient,
        getClinicalDocument: getClinicalDocument,
        clinicalDocumentDownload,
        setClinicalDocumentContext,
        clearClinicalDocumentContext,
        createClinicalDocument: createClinicalDocument,
        getHeartVariability,
        clinicalDocument: clinicalDocument,
        editClinicalDocument,
        deleteClinicalDocument,
        clearGetClinicalDocument,
        clearObservationsByComponent: clearObservationsByComponent,
        getLatestObservation: getLatestObservation,
        getPatientObservationsByPeriodForHeartRate: getPatientObservationsByPeriodForHeartRate,
        setHearRateDataForBar,
        getVitalData,
        getPatientInsuranceInformation: getPatientInsuranceInformation,
        addPatientInsuranceInformation: addPatientInsuranceInformation,
        deletePatientInsuranceInformation,
        updatePatientInsuranceInformationPreference: updatePatientInsuranceInformationPreference,
        clearPatientInsurance: clearPatientInsurance,
        getPatientCohortLatestObservationWithThreshold: getPatientCohortLatestObservationWithThreshold,
        AnalyticProfileDetails: AnalyticProfileDetails,
        getAnalyticProfileForPatient: getAnalyticProfileForPatient,
        getPatientCohortObservationSumWithThreshold: getPatientCohortObservationSumWithThreshold,
        clearHospitalization: clearHospitalization,
        clearHospitalContext: clearHospitalContext,
        setHospitalContext: setHospitalContext,
        setSurgeryContext: setSurgeryContext,
        clearSurgeryContext: clearSurgeryContext,
        getAllPatientRelationships: getAllPatientRelationships,
        deletePatientRelationships: deletePatientRelationships,
        updatePatientRelationships: updatePatientRelationships,
        addPatientRelationships: addPatientRelationships,
        clearPatientRelationships: clearPatientRelationships,
        setPatientEditTab: setPatientEditTab,
        getPatientRelationships: getPatientRelationships,
        getPatientObservationsByEncounter: getPatientObservationsByEncounter,
        getPatientMedications: getPatientMedications,
        getPatientRoleAttributes: getPatientRoleAttributes,
        addPatientRoleAttribute: addPatientRoleAttribute,
        removePatientRoleAttribute: removePatientRoleAttribute,
    }