import React from 'react';
import moment from "moment"
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {PatientAction} from "../../action/PatientAction";
import TileHeader from '../../layout/TileHeader';
import "hammerjs";
import {ObservationThreshold, PhixPermissions} from "../../config/GlobalConfig";
import {Col, Row} from 'react-grid-system';
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {ObservationsThresholdAction} from "../../action/ObservationsThresholdAction";


const HRVPatientPanelComponent = (props) => {
    const {PatientActions, PatientContext} = props

    const [heartRate, setHearRateData] = React.useState({});
    const [hrvAverage, sethrvAverage] = React.useState({});
    const [hrvWeekAverage, sethrvWeekAverage] = React.useState({});
    const [observationData, setObservationData] = React.useState({
        Normal: {},
        Minimum: {},
        Target: {},
    });

    React.useEffect(() => {
        if (PatientContext?.patientRoleId) {
            let patientRoleId = PatientContext.patientRoleId;
            let dictObservationTypeCode = "AH_HRV";
            let componentType = "HRVPatientPanelComponent";
            let observationByPeriodForDict = [dictObservationTypeCode];
            props.PatientActions.getLatestObservation(patientRoleId, dictObservationTypeCode, componentType);
            const todayDate = moment().startOf('day').format('YYYY-MM-DD');
            props.PatientActions.getPatientObservationsByPeriodForHeartRate(patientRoleId, todayDate, todayDate, [dictObservationTypeCode], "HRVPatientPanelToday")
            const startDate = moment().startOf('week').format('YYYY-MM-DD');
            const endDate = moment().endOf('week').format('YYYY-MM-DD');
            props.PatientActions.getPatientObservationsByPeriodForHeartRate(patientRoleId, startDate, endDate, observationByPeriodForDict, componentType);
            props.ObservationsThresholdActions.getObservationThresholdsByObservationCode("AH_HRV", "HRVPatientPanelComponent");
        }
    }, [PatientContext])

    React.useEffect(() => {
        if (props?.observationThresholdHRVPatientPanelComponent) {
            const val = props?.observationThresholdHRVPatientPanelComponent;
            for (let i = 0; i < val.length; i++) {
                if (val[i].description === "Normal") {
                    observationData.Normal.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Normal.value = val[i]?.value
                    observationData.Normal.rangeText = val[i]?.rangeText
                    observationData.Normal.rangeColor = val[i]?.rangeColor
                } else if (val[i].description === "Below Minimum") {
                    observationData.Minimum.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Minimum.value = val[i]?.value
                    observationData.Minimum.rangeText = val[i]?.rangeText
                    observationData.Minimum.rangeColor = val[i]?.rangeColor
                } else if (val[i].description === "Below Target") {
                    observationData.Target.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Target.value = val[i]?.value
                    observationData.Target.rangeText = val[i]?.rangeText
                    observationData.Target.rangeColor = val[i]?.rangeColor
                }
            }
        }
    }, [props?.observationThresholdHRVPatientPanelComponent])

    function averageHRVCount(component) {
        let countHRV = 0;
        let totalHRV = 0;
        if (props?.getPatientObservationsByPeriodForRestingAndWalkingHeartRate?.[component]) {
            let latestRWHR = props.getPatientObservationsByPeriodForRestingAndWalkingHeartRate[component];
            for (let i = 0; i < latestRWHR.length; i++) {
                if (latestRWHR[i].dictObservationTypeCode === "AH_HRV" && latestRWHR[i].value !== "") {
                    countHRV = countHRV + 1;
                    totalHRV = totalHRV + parseFloat(latestRWHR[i].value) * 1000;
                }
            }
            let averageHRVNum = totalHRV / countHRV;
            let averageHRV = Math.round(averageHRVNum);
            let colorHRV = ObservationThreshold.color;
            let textHRV = "";

            /*if (averageHRV >= 100) {
                colorHRV = "#6AA84F";
                textHRV = "";
            } else if (averageHRV > 50 && averageHRV <= 100) {
                colorHRV = "#E69138";
                textHRV = "Below provider target but above critical range.";
            } else if (averageHRV <= 60) {
                colorHRV = "#E06666";
                textHRV = "Please consult with your provider.";
            }*/
            if (observationData?.Normal && observationData.Normal?.value?.value) {
                if (observationData.Normal.dictOperatorDisplayName === ">=" && averageHRV >= observationData.Normal.value.value) {
                    colorHRV = observationData.Normal.rangeColor ? observationData.Normal.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Normal.rangeText;
                } else if (observationData.Normal.dictOperatorDisplayName === "<=" && averageHRV <= observationData.Normal.value.value) {
                    colorHRV = observationData.Normal.rangeColor ? observationData.Normal.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Normal.rangeText;
                } else if (observationData.Normal.dictOperatorDisplayName === ">" && averageHRV > observationData.Normal.value.value) {
                    colorHRV = observationData.Normal.rangeColor ? observationData.Normal.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Normal.rangeText;
                } else if (observationData.Normal.dictOperatorDisplayName === "<" && averageHRV < observationData.Normal.value.value) {
                    colorHRV = observationData.Normal.rangeColor ? observationData.Normal.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Normal.rangeText;
                } else if (observationData.Normal.dictOperatorDisplayName === "==" && averageHRV === observationData.Normal.value.value) {
                    colorHRV = observationData.Normal.rangeColor ? observationData.Normal.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Normal.rangeText;
                }
            } else if (observationData?.Normal && observationData.Normal?.value?.min && observationData.Normal?.value?.max) {
                if (averageHRV >= observationData.Normal.value.min && averageHRV <= observationData.Normal.value.max) {
                    colorHRV = observationData.Normal.rangeColor ? observationData.Normal.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Normal.rangeText;
                }
            }

            if (observationData?.Minimum && observationData.Minimum?.value?.value) {
                if (observationData.Minimum.dictOperatorDisplayName === ">=" && averageHRV >= observationData.Minimum.value.value) {
                    colorHRV = observationData.Minimum.rangeColor ? observationData.Minimum.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Minimum.rangeText;
                } else if (observationData.Minimum.dictOperatorDisplayName === "<=" && averageHRV <= observationData.Minimum.value.value) {
                    colorHRV = observationData.Minimum.rangeColor ? observationData.Minimum.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Minimum.rangeText;
                } else if (observationData.Minimum.dictOperatorDisplayName === ">" && averageHRV > observationData.Minimum.value.value) {
                    colorHRV = observationData.Minimum.rangeColor ? observationData.Minimum.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Minimum.rangeText;
                } else if (observationData.Minimum.dictOperatorDisplayName === "<" && averageHRV < observationData.Minimum.value.value) {
                    colorHRV = observationData.Minimum.rangeColor ? observationData.Minimum.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Minimum.rangeText;
                } else if (observationData.Minimum.dictOperatorDisplayName === "==" && averageHRV === observationData.Minimum.value.value) {
                    colorHRV = observationData.Minimum.rangeColor ? observationData.Minimum.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Minimum.rangeText;
                }
            } else if (observationData?.Minimum && observationData.Minimum?.value?.min && observationData.Minimum?.value?.max) {
                if (averageHRV >= observationData.Minimum.value.min && averageHRV <= observationData.Minimum.value.max) {
                    colorHRV = observationData.Minimum.rangeColor ? observationData.Minimum.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Minimum.rangeText;
                }
            }

            if (observationData?.Target && observationData.Target?.value?.value) {
                if (observationData.Target.dictOperatorDisplayName === ">=" && averageHRV >= observationData.Target.value.value) {
                    colorHRV = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Target.rangeText;
                } else if (observationData.Target.dictOperatorDisplayName === "<=" && averageHRV <= observationData.Target.value.value) {
                    colorHRV = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Target.rangeText;
                } else if (observationData.Target.dictOperatorDisplayName === ">" && averageHRV > observationData.Target.value.value) {
                    colorHRV = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Target.rangeText;
                } else if (observationData.Target.dictOperatorDisplayName === "<" && averageHRV < observationData.Target.value.value) {
                    colorHRV = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Target.rangeText;
                } else if (observationData.Target.dictOperatorDisplayName === "==" && averageHRV === observationData.Target.value.value) {
                    colorHRV = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Target.rangeText;
                }
            } else if (observationData?.Target && observationData.Target?.value?.min && observationData.Target?.value?.max) {
                if (averageHRV >= observationData.Target.value.min && averageHRV <= observationData.Target.value.max) {
                    colorHRV = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textHRV = observationData.Target.rangeText;
                }
            }
            return {averageHRV: averageHRV, color: colorHRV, text: textHRV};
        } else return {};
    }

    React.useEffect(() => {
        let componentType = "HRVPatientPanelComponent";
        if (props?.getLatestObservation?.[componentType]) {
            let hrdata = props.getLatestObservation[componentType];
            setHearRateData({
                value: Math.round(parseFloat(hrdata.value) * 1000),
                sourceName: hrdata.sourceName,
                startDate: hrdata.effectiveStartDate
            });
        }
        if (props?.getPatientObservationsByPeriodForRestingAndWalkingHeartRate?.HRVPatientPanelToday) {
            sethrvAverage(averageHRVCount("HRVPatientPanelToday"))
        }
        if (props?.getPatientObservationsByPeriodForRestingAndWalkingHeartRate?.[componentType]) {
            sethrvWeekAverage(averageHRVCount(componentType))
        }
    }, [props?.getLatestObservation, props?.getPatientObservationsByPeriodForHeartRate, props?.getPatientObservationsByPeriodForRestingAndWalkingHeartRate])

    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                <TileContainer>
                    <TileHeader className="boxHeader ah-draggable-header"
                                title={"Heart Rate Variability"} image={"../images/hospitalHeart.png"}/>
                    <TileBody>
                        <Row style={{margin: '0', height: "100%"}} onClick={() => {
                            PatientActions.setSavePatientContext({...PatientContext, selectedValue: 'day'})
                            props.PopupActions.showWindow(PopupWindows.HEART_RATE_VARIABILITY_MANAGEMENT_BAR_WINDOW);
                        }}>
                            <Col style={{borderRight: "solid 2px #DDDDDD", margin: '10px', padding: '0px 0px'}}>
                                <Row>
                                    <Col> <span style={{
                                        width: '100%',
                                        color: "#666666",
                                        fontSize: '12px',
                                        fontWeight: '600'
                                    }}>{heartRate?.startDate ? moment(heartRate?.startDate).format('MM/DD/YYYY') : ''}</span></Col>
                                    <Col> <span style={{
                                        width: '100%',
                                        color: "#666666",
                                        fontSize: '12px',
                                        fontWeight: 'bold'
                                    }}>{heartRate?.startDate ? moment(heartRate?.startDate).format('hh:mm a') : ''}</span></Col>
                                </Row>
                                {heartRate && Object.keys(heartRate).length > 0 ? <div>
                            <span style={{
                                width: '100%',
                                float: "left",
                                lineHeight: '35px',
                                textAlign: "left",
                                fontSize: "28px",
                                fontWeight: '600',
                                color: ObservationThreshold.color
                            }}>{heartRate?.value ? heartRate?.value + " ms" : "-"} </span>
                                    <span style={{
                                        width: '100%',
                                        float: "left", fontSize: '12px'
                                    }}>Source : {heartRate.sourceName}</span>
                                </div> : ''}
                            </Col>
                            <Col style={{borderRight: "solid 2px #DDDDDD", margin: '10px', padding: '0px 0px'}}>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    color: "#666666",
                                    fontSize: '12px',
                                    fontWeight: '600'
                                }}>Today's Average </span>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    lineHeight: '35px',
                                    textAlign: "left",
                                    fontSize: "23px",
                                    fontWeight: '600',
                                    color: hrvAverage?.color
                                }}>{hrvAverage?.averageHRV ? hrvAverage.averageHRV + " ms" : "-"}
                                </span>
                                <span style={{
                                    width: '100%',
                                    float: "left", fontSize: '12px'
                                }}>{hrvAverage?.text}</span>
                            </Col>
                            <Col style={{margin: '10px', padding: '0px 0px'}}>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    color: "#666666",
                                    fontSize: '12px',
                                    fontWeight: '600'
                                }}>Current Week Average </span>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    lineHeight: '35px',
                                    textAlign: "left",
                                    fontSize: "23px",
                                    fontWeight: '600',
                                    color: hrvWeekAverage?.color
                                }}>{hrvWeekAverage?.averageHRV ? hrvWeekAverage.averageHRV + " ms" : "-"}
                                </span>
                                <span style={{
                                    width: '100%',
                                    float: "left", fontSize: '12px'
                                }}>{hrvWeekAverage?.text}</span>
                            </Col>
                        </Row>
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        PatientContext: state.patient.patientContext,
        getLatestObservation: state.patient.getLatestObservation,
        getPatientObservationsByPeriodForHeartRate: state.patient.getPatientObservationsByPeriodForHeartRate,
        getPatientObservationsByPeriodForRestingAndWalkingHeartRate: state.patient.getPatientObservationsByPeriodForHeartRate,
        observationThresholdHRVPatientPanelComponent: state.observationThreshold.componentThresholds?.HRVPatientPanelComponent,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        ObservationsThresholdActions: bindActionCreators(ObservationsThresholdAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HRVPatientPanelComponent);