import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PopupWindowActions as PopupActions, PopupWindows,} from "../../action/PopupWindowAction";
import {useForm} from "react-hook-form";
import {UserActions} from "../../action/UserAction";
import {
    Field,
    FieldDatePicker,
    FieldRow,
    FieldSection,
    FieldTextInput,
    FieldUserDropDown
} from "../InputControls/FormFields";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Fade} from "@progress/kendo-react-animation";
import moment from 'moment';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import { useToast } from "../../providers/toast/ToastProvider";

const AddPatientWindow = (props) => {
    const dispatch = useDispatch()
    const showWindow = useSelector(state => state.popupWindow.showWindow)
    const windowState = useSelector(state => state.popupWindow.state)


    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        formState: {errors},
    } = useForm({defaultValues: {active: true}});

    const hideDialog = async () => {
        await PopupActions.clearWindowState(PopupWindows.USER_CREATE_PORTAL_REGISTRATION)(dispatch);
        await reset()
        await PopupActions.hideWindow(PopupWindows.USER_CREATE_PORTAL_REGISTRATION)(dispatch);
    };

    useEffect(() => {
        if (windowState[PopupWindows.USER_CREATE_PORTAL_REGISTRATION]) {
            let patient = windowState[PopupWindows.USER_CREATE_PORTAL_REGISTRATION]
            console.log(patient)
            setValue("firstName", patient.firstName)
            setValue("lastName", patient.lastName)
            setValue("email", patient.email)
            setValue("phone", patient.phone)
            setValue("patientRoleId", patient.patientRoleId)
            if (patient?.dateOfBirth)
                setValue("dateOfBirth", moment(patient.dateOfBirth, "YYYY-MM-DD").toDate());
        }
    }, [windowState])
    const {addToast} = useToast()


    const [success, setSuccess] = React.useState(false)
    const [sending, setSending] = React.useState(false)

    const Notify = () => {
        addToast({severity: "success", summary: "Invite Sent", detail: "Portal Registration has been sent."})
    }

    const sendRegistration = async (form) => {
        let payload = {}
        payload.firstName = form.firstName
        payload.lastName = form.lastName
        payload.email = form.email
        payload.phoneNumber = form.phoneNumber
        payload.registeredToUserRoleId = form.registeredToUserRoleId.userRoleId
        payload.dateOfBirth = moment(form.dateOfBirth).format("yyy-MM-DD")
        payload.patientRoleId = form?.patientRoleId ? form?.patientRoleId : null
        setSending(true)
        await UserActions.createPortalRegistration(payload)(dispatch)
        Notify()
        await PopupActions.clearWindowState(PopupWindows.USER_CREATE_PORTAL_REGISTRATION)(dispatch);
        reset({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            registeredToUserRoleId: form.registeredToUserRoleId,
            dateOfBirth: "",
            patientRoleId: ""
        })
        setSending(false)
    };


    return (
        <Dialog
            visible={showWindow[PopupWindows.USER_CREATE_PORTAL_REGISTRATION]}
            header={"Send User Registration"}
            style={{width: 800}}
            footer={<Button
                disabled={sending}
                
                onClick={handleSubmit(sendRegistration)}
                label={"Send Registration"}/>
            }
            onHide={hideDialog}
            initialHeight={360}
            initialWidth={800}
            modal
        >

            <FieldSection>
                <FieldRow>

                    <Field col={6} label={"First Name"} field={"firstName"} error={errors} register={register}
                           options={{required: "First Name is required."}}>
                        <FieldTextInput/>
                    </Field>

                    <Field col={6} label={"Last Name"} field={"lastName"} error={errors} register={register}
                           options={{required: "Last Name is required."}}>
                        <FieldTextInput/>
                    </Field>
                </FieldRow>
                <FieldRow>

                    <Field col={6} label={"Email"} field={"email"} error={errors} register={register}
                           options={{required: "Email is required."}}>
                        <FieldTextInput/>
                    </Field>

                    <Field col={6} label={"Cell Phone"} field={"phoneNumber"} error={errors} register={register}>
                        <FieldTextInput/>
                    </Field>
                </FieldRow>
                <FieldRow>
                    <Field col={6} label={"Date of Birth"} field={"dateOfBirth"} error={errors} control={control}
                           register={register} options={{required: "Date of birth is required."}}>
                        <FieldDatePicker/>
                    </Field>
                    <Field col={6} label={"Assigned Provider"} field={"registeredToUserRoleId"} error={errors}
                           control={control}
                           register={register} options={{required: "Assigned provider is required"}}>
                        <FieldUserDropDown/>
                    </Field>

                </FieldRow>
            </FieldSection>
        </Dialog>)
        ;
};


function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        createPatientContext: state.patient.createPatientContext,
        patient: state.patient,
        patientContext: state.patient.patientContext,
    };
}


export default AddPatientWindow
