import React, {useState} from 'react';
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle
} from '@progress/kendo-react-charts';
import 'hammerjs';
import PropTypes from "prop-types";
import GlobalStyles from '../../config/GlobalStyles';
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {Button} from "primereact/button";
import {bindActionCreators} from "redux";
import {PatientAction} from "../../action/PatientAction";
import {connect} from 'react-redux';
import moment from 'moment';
import {ObservationCodes} from "../../config/GlobalConfig";
import {RadioButton} from "@progress/kendo-react-inputs";
import {Col, Row} from "react-grid-system";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Dialog} from "primereact/dialog";

const categories = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const categoriesInDay = ['12 am', '3 am', '6 am', '9 am', '12 pm', '3 pm', '6 pm', '9 pm'];
const categoriesInMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const [initCurrentWeek] = [[]];
const series = [5000, 5000, 5000, 5000, 5000, 5000, 5000,];

const ActivityLevelBarComponent = (props) => {
    const {PatientActions, PatientContext, ActivityLevelBarComponentData, selectActivityType} = props
    const [prevWeekChartData, setPreviousWeekChartData] = React.useState([]);
    const [currWeekChartData, setCurrentWeekChartData] = React.useState([]);
    const [currentWeek, setCurrentWeek] = React.useState(initCurrentWeek);
    const [weeksPrevious, setWeeksPrevious] = React.useState(0)

    const [datePeriod, setDatePeriod] = useState({
        startDate: moment().startOf('week').subtract(1, "week").format('YYYY-MM-DD'),
        endDate: moment().endOf('week').format('YYYY-MM-DD')
    })
    const [averageLine, setAverageLine] = React.useState([])
    const [gridData, setGridData] = React.useState([]);
    const [selectedValue, setSelectedValue] = React.useState(selectActivityType?.selectedValue ? selectActivityType?.selectedValue : "day");
    const [commonChart, setCommonChartData] = React.useState([]);
    const [weeklyAvergate, setWeeklyAverage] = React.useState();
    const [gridTitle, setGridTitle] = React.useState("Time");
    const [graphSeries, setGraphSeries] = React.useState(Array(7).fill(5000))
    const [isDisable, setIsDisable] = React.useState(false);
    const [weeksPreviousFlag, setWeekPrevousFlag] = React.useState(true);
    const [xAxis, setXAxis] = React.useState("");

    React.useEffect(() => {
        setSelectedValue(selectActivityType?.selectedValue ? selectActivityType?.selectedValue : "day")
        if (selectedValue === "day") {
            let startDate = moment().startOf('day').subtract(weeksPrevious, "day").format('YYYY-MM-DD')
            let endDate = moment().endOf('day').subtract(weeksPrevious, "day").add(1, "day").format('YYYY-MM-DD')
            setDatePeriod({startDate: startDate, endDate: endDate})
        }
        setWeekPrevousFlag(false);
        //setWeekPrevousFlag(true);
    }, [selectActivityType])

    const handleChange = React.useCallback(
        (e) => {
            setSelectedValue(e.value);
            setWeeksPrevious(0);
            PatientActions.setSavePatientContext({...PatientContext, selectedValue: e.value})
            if (e.value === "day") setGridTitle("Time")
            else if (e.value === "week") {
                setGridTitle("Date")
                setGraphSeries(Array(7).fill(5000))
            } else {
                setGridTitle("Month")
                setGraphSeries(Array(12).fill(5000))
            }
        },
        [setSelectedValue])


    const hideDialog = () => {
        setWeekPrevousFlag(false);
        setWeeksPrevious(0);
        let startDate = moment().startOf('week').format('YYYY-MM-DD')
        let endDate = moment().endOf('week').format('YYYY-MM-DD')
        let currentDate = moment().startOf('day').format('YYYY-MM-DD');
        setDatePeriod({startDate: startDate, endDate: endDate, today: currentDate})
        props.PopupActions.hideWindow(PopupWindows.ACTIVITY_LEVEL_BAR_WINDOW)
    };

    React.useEffect(() => {
        if (weeksPreviousFlag) {
            if (selectedValue === "week") {
                let startDate = moment().startOf('week').subtract(weeksPrevious, "week").format('YYYY-MM-DD')
                let endDate = moment().endOf('week').subtract(weeksPrevious, "week").format('YYYY-MM-DD')
                setDatePeriod({startDate: startDate, endDate: endDate})
            }
            if (selectedValue === "month") {
                let startDate = moment().startOf('year').subtract(weeksPrevious, "year").format('YYYY-MM-DD')
                let endDate = moment().endOf('year').subtract(weeksPrevious, "year").format('YYYY-MM-DD')
                // const currentYear = moment().format('YYYY');
                setDatePeriod({startDate: startDate, endDate: endDate});
                // if(currentYear === moment(startDate).format('YYYY') && currentYear === moment(endDate).format('YYYY')) {
                //     setIsDisable(false);
                //     setDatePeriod({ startDate: startDate, endDate: endDate });
                // } else { setIsDisable(true) }
            }
            if (selectedValue === "day") {
                let startDate = moment().startOf('day').subtract(weeksPrevious, "day").format('YYYY-MM-DD')
                let endDate = moment().endOf('day').subtract(weeksPrevious, "day").format('YYYY-MM-DD')
                setDatePeriod({startDate: startDate, endDate: endDate})
            }
        } else {
            if (selectedValue === "week") {
                let startDate = moment().startOf('week').format('YYYY-MM-DD')
                let endDate = moment().endOf('week').format('YYYY-MM-DD')
                setDatePeriod({startDate: startDate, endDate: endDate})
            }
            if (selectedValue === "month") {
                let startDate = moment().startOf('year').format('YYYY-MM-DD')
                let endDate = moment().endOf('year').format('YYYY-MM-DD')
                setDatePeriod({startDate: startDate, endDate: endDate});
            }
            if (selectedValue === "day") {
                let startDate = moment().startOf('day').format('YYYY-MM-DD')
                let endDate = moment().endOf('day').format('YYYY-MM-DD')
                setDatePeriod({startDate: startDate, endDate: endDate})
            }
            setWeekPrevousFlag(true);
        }

    }, [weeksPrevious, selectedValue]);


    React.useEffect(() => {
        if (PatientContext?.patientRoleId)
            PatientActions.getPatientObservationsByPeriod(PatientContext?.patientRoleId,
                datePeriod.startDate,
                datePeriod.endDate,
                [ObservationCodes.STEP.STEP_COUNT],
                "ActivityLevelBarComponent")

    }, [datePeriod]);

    React.useEffect(() => {
        if (ActivityLevelBarComponentData?.length > 0) {
            let dates = ActivityLevelBarComponentData.reduce((acc, d) => {
                return acc.add(moment(d.effectiveStartDate).format('YYYY-MM-DD'))
                //acc.add(moment(d.effectiveStartDate).format('YYYY-MM-DD'))
            }, new Set())

            let data = [];
            ActivityLevelBarComponentData.forEach((element) => data.push(element.value));
            var sum = getAverageForEachWeek(data)

            setCurrentWeek(sum);
            let chartData = prepareData()
            setPreviousWeekChartData(chartData?.prevChart?.filter((e) => e !== null))
            setCurrentWeekChartData(chartData?.currChart?.filter((e) => e !== null))
            setCommonChartData(chartData?.common);
        } else {
            setCurrentWeek([]);
            setPreviousWeekChartData([]);
            setCurrentWeekChartData([]);
            setCommonChartData([]);
            setGridData([]);
            setWeeklyAverage(0)
        }

    }, [ActivityLevelBarComponentData])


    React.useEffect(() => {
        getAverageForEachWeek()
    }, [currentWeek])

    function getAverageLineData() {
        let sumPrev = prevWeekChartData?.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        let sumCurr = currWeekChartData?.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        let avgArr = Array(7).fill(sumPrev / 7);
        let avgArrC = Array(7).fill(sumCurr / 7);
        // avgArr.push(...avgArrC)
        return avgArr
    }

    function getAverageForEachWeek(data = null) {
        let sum
        if (data !== null) {
            sum = data?.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        } else {
            sum = currentWeek?.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        }
        return (Array(7).fill(sum / 7))
    }

    function dateFormat(date, show = false) {
        if (selectedValue === "month" && show) return moment(date).format('MMMM YYYY')
        if (selectedValue === "week" && show) return moment(date).format('MM/DD/YYYY')
        if (selectedValue === "day" && show) return moment(date).format('h:MM a');
        if (selectedValue === "day") return date;
        if (selectedValue === "week") return moment(date).format('YYYY-MM-DD')
        if (selectedValue === "month") return moment(date).format('MMM-YYYY')
    }

    function getWeeklyAverage(data) {
        const field = data.map((i) => i.field);
        const sum = field.reduce((a, b) => a + b, 0);
        const finalCount = Number(sum / field.length).toFixed(2)
        setWeeklyAverage(finalCount)
        if (selectedValue === "week") setAverageLine(Array(7).fill(finalCount))
        if (selectedValue === "month") setAverageLine(Array(12).fill(finalCount))
    }

    function filterDayData(data) {
        const filterActivityBarData = [];
        const date = data.map((i) => moment(i.effectiveStartDate).format('YYYY-MM-DD h a'));
        const setSerialized = new Set(date);
        const uniqueArrSerialized = [...setSerialized];

        uniqueArrSerialized.forEach((item) => {
            const filtered = data.filter((i) => item === moment(i.effectiveStartDate).format('YYYY-MM-DD h a'));
            const valueArray = filtered.map((i) => parseFloat(i.value));
            const finalValue = valueArray.reduce((x, y) => x + y, 0);
            filterActivityBarData.push({value: finalValue, effectiveStartDate: filtered[0].effectiveStartDate});
        })
        return filterActivityBarData;
    }

    function prepareData() {
        if (ActivityLevelBarComponentData?.length > 0) {
            let middleOfWeek = moment(datePeriod.endDate).subtract(6, "days").endOf("day").format("YYYY-MM-DD HH:MM");

            let currentWeekArr = []
            let prevWeekArr = []
            let commonArr = []

            const filterActivityBarData = [];
            const date = ActivityLevelBarComponentData.map((i) => dateFormat(i.effectiveStartDate));
            const setSerialized = new Set(date);
            const uniqueArrSerialized = [...setSerialized];

            if (selectedValue !== 'day') {
                uniqueArrSerialized.forEach((item) => {
                    const data = ActivityLevelBarComponentData.filter((i) => item === dateFormat(i.effectiveStartDate));
                    const valueArray = data.map((i) => parseFloat(i.value));
                    let finalValue;
                    if (selectedValue === "month") {
                        const date = data.map((i) => moment(i.effectiveStartDate).format('YYYY-MM-DD'));
                        const setSerialized = new Set(date);
                        const removeDuplicate = [...setSerialized];
                        const daysInMonth = moment(moment(item).format('YYYY-MM')).daysInMonth();
                        if (removeDuplicate.length > daysInMonth) {
                            finalValue = valueArray.reduce((x, y) => x + y / daysInMonth, 0);
                        } else {
                            finalValue = valueArray.reduce((x, y) => x + y / removeDuplicate.length, 0);
                        }
                    } else {
                        finalValue = valueArray.reduce((x, y) => x + y, 0);
                    }
                    filterActivityBarData.push({value: finalValue, effectiveStartDate: item});
                })
            } else {
                const data = filterDayData(ActivityLevelBarComponentData)
                data.forEach(i => filterActivityBarData.push(i));
            }
            let a = filterActivityBarData.map((item) => {
                if (moment(item.effectiveStartDate).utc().local().format('YYYY-MM-DD') < moment(middleOfWeek).utc().local().format('YYYY-MM-DD')) {
                    prevWeekArr.push({
                        field: parseInt(item.value),
                        categoryField: moment(item.effectiveStartDate).utc().local().format('ddd'),
                        date: moment(item.effectiveStartDate).utc().local().format('MM/DD/YYYY')
                    })
                } else {
                    currentWeekArr.push({
                        field: parseInt(item.value),
                        categoryField: moment(item.effectiveStartDate).utc().local().format('ddd'),
                        date: moment(item.effectiveStartDate).utc().local().format('MM/DD/YYYY')
                    })
                }
            })
            filterActivityBarData.map((item) => {
                let categoryField;
                if (selectedValue === "week") categoryField = moment(item.effectiveStartDate).utc().local().format('ddd');
                if (selectedValue === "month") categoryField = moment(item.effectiveStartDate).utc().local().format('MMM')
                if (selectedValue === "day") categoryField = moment(item.effectiveStartDate).utc().local().format('h a');
                commonArr.push({
                    ...item,
                    field: parseInt(item.value),
                    categoryField: categoryField,
                    date: dateFormat(item.effectiveStartDate, true),
                    dummyDate: moment(item.effectiveStartDate).utc().local().format('YYYY-MM-DD')
                })
            })

            if (selectedValue === "day") {
                const arr = [];
                commonArr.forEach((i) => {
                    const start = moment(i.effectiveStartDate).format('h a')
                    const end = moment(i.effectiveStartDate).add(1, 'hours').format('h a')
                    const dt = start.split(" ");
                    const dte = end.split(" ");
                    // const filterData = commonArr.filter((c)=> moment(c.effectiveStartDate).format('h a').toString() >= start && moment(c.effectiveStartDate).format('h a').toString() <= end);
                    // const mapData = filterData.map((c)=> c.field);
                    // const filedValue = mapData.reduce((x,y) => x+y, 0);
                    arr.push({...i, date: `${dt[0]} - ${dte[0]} ${dt[1]}`});
                })
                const xAxis = commonArr.map((i) => moment(i.effectiveStartDate).format('h a'))
                setXAxis(xAxis.length <= 24 ? xAxis : "");
                setGridData(arr);

            } else if (selectedValue === "month") {
                const filterArray = [];
                categoriesInMonth.forEach((item) => {
                    const data = commonArr.filter((i) => item === i.categoryField);
                    filterArray.push(data[0]);
                })
                setGridData(filterArray.filter((i) => i !== undefined));
            } else {
                setGridData(commonArr);
            }

            let preWR = prepareWeekData(prevWeekArr)
            let curWR = prepareWeekData(currentWeekArr)
            let common = prepareWeekData(commonArr);
            getWeeklyAverage(commonArr)
            let chartData = prepareGraphData(preWR, 'prev')
            let chartData1 = prepareGraphData(curWR, 'curr')
            let commonChart;

            if (selectedValue === "day") {
                commonChart = commonArr.map((i) => i.value);
            } else {
                commonChart = prepareGraphData(common, "")
            }

            return {
                'prevChart': chartData,
                'currChart': chartData1,
                'common': commonChart
            }
        }
    }

    function prepareGraphData(arrayVal, weekName) {
        if (selectedValue === "week") {
            let objArray = []
            weekDays.forEach((item) => {
                objArray.push(arrayVal[item] ? arrayVal[item] : 0)
            }, [])
            var nullArray = [null, null, null, null, null, null, null]
            if (weekName === 'curr') {
                objArray.unshift(...nullArray)
            } else if (weekName === "prev") {
                objArray.push(...nullArray)
            }
            return objArray
        }
        if (selectedValue === "month") {
            let objArray = []
            categoriesInMonth.forEach((item) => {
                objArray.push(arrayVal[item] ? arrayVal[item] : 0)
            }, [])
            return objArray
        }
        if (selectedValue === "day") {
            let objArray = []
            categoriesInDay.forEach((item) => {
                objArray.push(arrayVal[item] ? arrayVal[item] : 0)
            }, [])
            return objArray
        }
    }

    function prepareWeekData(wArray) {
        let b = wArray.reduce((acc, item) => {
            acc[item.categoryField] = acc[item.categoryField] ? acc[item.categoryField] : 0 + item.field;
            return acc
        }, {})
        return b
    }

    const GlobStyle = {
        ...GlobalStyles.newTitleWrapper,
        overflow: 'hidden', width: '100%',
    }
    return (
        <Dialog
            header={"Activity Level"}
            visible={props.showWindow[PopupWindows.ACTIVITY_LEVEL_BAR_WINDOW]}
            onHide={hideDialog}
            style={{height: 650, width: 650}}
        >

            <div style={GlobStyle}>
                <Row style={{textAlign: 'center'}}>
                    <Col>
                        <RadioButton
                            name="group1"
                            value="day"
                            label="Day"
                            checked={selectedValue === "day"}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col>
                        <RadioButton
                            name="group1"
                            value="week"
                            label="Week"
                            checked={selectedValue === "week"}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col>
                        <RadioButton
                            name="group1"
                            value="month"
                            label="Year"
                            checked={selectedValue === "month"}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col>
                        <Button className="k-button k-button-icon" style={{border: "none", background: "none"}}
                                disabled={isDisable}
                                onClick={(e) => {
                                    setWeeksPrevious(weeksPrevious + 1);
                                    // if(currentYear === moment(datePeriod.startDate).format('YYYY') && currentYear === moment(datePeriod.endDate).format('YYYY')) {
                                    // }
                                }}
                        >
                            <span style={{fontSize: "30px", color: '#7585C2'}} role="presentation"
                                  className="k-icon k-i-arrow-60-left"/>
                        </Button>

                        <Button className="k-button k-button-icon" style={{border: "none", background: "none"}}
                                onClick={(e) => {
                                    if (weeksPrevious === 0)
                                        setWeeksPrevious(0)
                                    else
                                        setWeeksPrevious(weeksPrevious - 1)
                                }}
                        >
                            <span style={{fontSize: "30px", color: '#7585C2'}} role="presentation"
                                  className="k-icon k-i-arrow-60-right"/>
                        </Button>
                    </Col>
                </Row>
                <Row style={{paddingTop: "10px"}}>
                    <Col>
                        {selectedValue === "week" && (
                            <>
                                    <span style={{paddingLeft: "10px", color: "#888888", fontSize: '14px'}}>
                                        {moment(datePeriod.startDate).format('MM/DD/YY')} - {moment(datePeriod.endDate).format('MM/DD/YY')}{' '}
                                    </span>
                                <span style={{paddingLeft: "10px", color: "#888888", fontSize: '14px'}}>
                                        Average steps for period shown : {weeklyAvergate}
                                    </span>
                            </>
                        )}
                        {selectedValue === "day" && (
                            <>
                                    <span style={{paddingLeft: "10px", color: "#888888", fontSize: '14px'}}>
                                        {moment(datePeriod.startDate).format('MM/DD/YY')}
                                    </span>
                            </>
                        )}
                        {selectedValue === "month" && (
                            <>
                                    <span style={{paddingLeft: "10px", color: "#888888", fontSize: '14px'}}>
                                        {moment(datePeriod.endDate).format('YYYY')}{' '}
                                    </span>
                            </>
                        )}

                    </Col>
                </Row>

                <Chart style={{height: "250px", width: "auto", paddingTop: 10}}>
                    <ChartTitle text=""/>
                    <ChartLegend position="bottom" orientation="horizontal"/>
                    <ChartCategoryAxis>
                        {selectedValue === 'day' && (
                            <ChartCategoryAxisItem categories={xAxis ? xAxis : categoriesInDay}>
                                <ChartCategoryAxisTitle text=""/>
                            </ChartCategoryAxisItem>
                        )}
                        {selectedValue === 'week' && (
                            <ChartCategoryAxisItem categories={categories}>
                                <ChartCategoryAxisTitle text=""/>
                            </ChartCategoryAxisItem>
                        )}
                        {selectedValue === 'month' && (
                            <ChartCategoryAxisItem categories={categoriesInMonth}>
                                <ChartCategoryAxisTitle text=""/>
                            </ChartCategoryAxisItem>
                        )}
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            type="column"
                            color='#6AA84F'
                            gap={0.6} spacing={0.4}
                            data={commonChart}
                            tooltip={{
                                visible: true,
                                background: "white",
                                render: (v) => {
                                    return <div>{Number(v.point.value).toFixed(2)} Steps</div>
                                }

                            }}
                        />
                        {/* <ChartSeriesItem
                                type="column"
                                color='#6AA84F'
                                gap={0.2} spacing={0.1}
                                data={currWeekChartData}
                                tooltip={{
                                    visible: true,
                                    background: "white",
                                    render: (v) => {
                                        return <div>{v.point.value.toFixed(2)} Steps</div>
                                    }

                                }}
                            /> */}
                        {selectedValue !== "day" && (
                            <ChartSeriesItem color={'#9FC5F8'}
                                             type="line"
                                             name="Target"
                                             data={graphSeries}
                                             markers={{
                                                 visible: false,
                                             }}
                                             style="normal"
                            />
                        )}
                        {selectedValue !== "day" && (
                            <ChartSeriesItem color={'#FFE599'}
                                             type="line"
                                             name="Average"
                                             tooltip={{
                                                 visible: true,
                                                 background: "white",
                                                 render: (v) => {
                                                     return <div>{Number(v?.point?.value).toFixed(2)} Average</div>
                                                 }
                                             }}
                                // data={currentWeek1}
                                             data={averageLine}
                                             markers={{
                                                 visible: false,
                                             }}
                                             style="normal"
                            />
                        )}
                    </ChartSeries>
                </Chart>
                <Grid
                    style={{
                        maxHeight: "auto", marginTop: "15px",
                        fontWeight: "bold", overflowY: "scroll !important", height: "230px"
                    }}
                    data={gridData}
                    className="grid"
                >
                    <GridColumn field={"date"} title={gridTitle}/>
                    <GridColumn field={"field"}
                                title={selectedValue === "month" ? "Avg. Daily Step Count" : "Step Count"}/>
                </Grid>

            </div>
        </Dialog>
    )

}

ActivityLevelBarComponent.propTypes = {
    showWindow: PropTypes.object,
    PopupActions: PropTypes.object,
    PatientActions: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        ActivityLevelData: state.patient.activityLevelBar,
        PatientContext: state.patient.patientContext,

        ActivityLevelBarComponentData: state.patient.observations.ActivityLevelBarComponent,
        popupState: state.popupWindow.state,
        showWindow: state.popupWindow.showWindow,
        selectActivityType: state.patient.createPatientContext
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLevelBarComponent);