import {Button} from "primereact/button";
import * as React from "react";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Colors} from "../../config/GlobalConfig";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {Card, CardBody, CardHeader} from "@progress/kendo-react-layout";
import {ListView, ListViewHeader} from "@progress/kendo-react-listview";
import {Col, Container, Row} from "../../layout/GridLayout";
import {Tooltip} from "@progress/kendo-react-tooltip";
import GlobalStyles from "../../config/GlobalStyles";
import DictionarySearchList from "./DictionarySearchList";

const MultiDictionarySelect = (props) => {
    const {onChange, style, ref, code} = props

    const [value, setValue] = useState({});
    const [internalValue, setInternalValue] = useState([]);


    const handleRemove = (value) => {
        let d = internalValue.filter((d) => d.id !== value.id)
        setInternalValue(d)
        if (onChange)
            onChange(d)
    }
    useEffect(() => {
        if (props.value)
            setInternalValue(props.value)
        else
            setInternalValue([])
    }, [props.value])

    const handleAdd = (value) => {
        if (value?.id) {
            const s = new Set(internalValue.map(d => d?.id))
            if (!s.has(value.id)) {
                let d = [...internalValue, value]
                // setInternalValue(d)
                if (onChange)
                    onChange(d)
            }
        }
    }


    const listHeader = () => {
        return (
            <ListViewHeader
                style={{
                    color: "rgb(160, 160, 160)",
                    fontSize: 14,
                }}
                className="pl-3 pb-2 pt-2"
            >
                {/* Goal Strategies: {carePlanEdit.condition.displayName} */}
                <Row justify={"start"} align={"center"} style={{
                    borderBottom: "1px solid rgb(242, 242, 242)",
                    backgroundColor: Colors.LIGHT_GRAY,
                    margin: '0'
                }}>
                    <Col col={3}>Code</Col>
                    <Col col={7}>Display Name</Col>
                    <Col col={2}>Action</Col>
                </Row>
            </ListViewHeader>
        );
    };
    const itemRenderer = (props) => {
        let item = props.dataItem;

        return (
            <Tooltip
                anchorElement="element"
                tooltipStyle={{background: "dimgray", color: "white",}}>
                <Container style={{borderBottom: "1px solid rgb(242, 242, 242)", marginLeft: 20}}>
                    <Col col={3} style={GlobalStyles.nextNoWrapEllipsis} title={item.code}>
                        {item.code}
                    </Col>
                    <Col col={7} style={GlobalStyles.nextNoWrapEllipsis} title={item.displayName}>
                        {item.displayName}
                    </Col>
                    <Col col={2}>
                        <Button icon={"pi pi-trash"} text severity={"danger"} onClick={() => handleRemove(item)}/>
                    </Col>
                </Container>
            </Tooltip>
        )
            ;
    };


    return (
        <div style={style}>
            <Card>
                <CardHeader>
                    <DictionarySearchList
                        useIndex={props.useIndex}
                        onChange={(e) => handleAdd(e.target.value)}
                        value={value}
                        code={code}
                        style={{width: "100%"}}/>
                </CardHeader>
                <CardBody>
                    <ListView
                        style={{height: "200px"}}
                        data={internalValue}
                        header={listHeader}
                        item={itemRenderer}
                        textField={"displayName"}
                        selectedField={"selected"}/>
                </CardBody>
            </Card>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        patientContext: state.patient.patientContext,
        dictionaries: state.dictionary.dictionaries,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiDictionarySelect);
