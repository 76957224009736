import {connect} from "react-redux";
import React from "react";
import {bindActionCreators} from "redux";
import {Col, Container, Row} from "react-grid-system";
import {DropDownButton} from "@progress/kendo-react-buttons";
import {DictionaryDispatchAction} from "../../../action/DictionaryDispatchAction";
import ObservationsList from "./ObservationsList";
import {ObservationsThresholdAction} from "../../../action/ObservationsThresholdAction";
import {PhixPermissions} from "../../../config/GlobalConfig";
import ShowForPermissionComponent from "../../shared/ShowForPermissionComponent";

const ObservationThresholdsComponent = (props) => {

    const [observations, setObservations] = React.useState([]);
    const [savedObservations, setSavedObservations] = React.useState(null);

    React.useEffect(() => {
        props.DictionaryAction.getDictionaryByCode("AH_OBSERVATION_TYPE");
    }, []);

    React.useEffect(() => {
        props.DictionaryAction.getDictionaryByCode("AH_OPERATORS");
    }, []);

    React.useEffect(() => {
        if (!savedObservations || savedObservations.length !== props.savedObservationThresholds.length) {
            props.ObservationsThresholdAction.getSavedObservationThresholds().then(() => {
                if (props.savedObservationThresholds)
                    setSavedObservations(props.savedObservationThresholds);
            });
        } else {
            if (props.observationTypes) {
                let newValues = props.observationTypes.filter(ot => savedObservations.indexOf(ot.id) > -1);
                if (newValues.length > 0) {
                    const arrayUniqueByKey = [...new Map(newValues.map(item => [item.displayName, item])).values()];
                    setSavedObservations(null);
                    setObservations(arrayUniqueByKey);
                }
            }

        }
    }, [props.savedObservationThresholds]);

    const handleDomEvent = (eventType) => {
        let item = props.observationTypes.find(ot => ot.displayName === eventType.item);
        if (item) {
            let newValues = [...observations, item];
            const arrayUniqueByKey = [...new Map(newValues.map(item => [item.displayName, item])).values()];
            setObservations(arrayUniqueByKey);
        }
    };


    return <ShowForPermissionComponent component permissions={[PhixPermissions.AH_ADMIN_VIEW_OBSERVATION_THRESHOLD]}>
        <Container style={{height: "100%", width: "100%"}}>
            <Row style={{marginTop: '1%'}}>
                <Col sm={10}>
                    <span> Observation Threshold Configuration </span>
                </Col>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_OBSERVATION_THRESHOLD]}>
                    <Col>
                        <DropDownButton
                            icon={"plus"}
                            text="Add Observation"
                            items={props.observationTypes ? props.observationTypes.filter(v => v.active).map(v => v.displayName) : []}
                            onItemClick={(e) => handleDomEvent(e)}/>
                    </Col>
                </ShowForPermissionComponent>
            </Row>
            <hr></hr>
            <div style={{margin: 0, overflow: "auto", height: "calc(100vh - 230px)"}}>
                {observations ? observations.map(v => <> <ObservationsList observation={v}/>
                    <hr/>
                </>) : <></>}
            </div>
        </Container>
    </ShowForPermissionComponent>


}


function mapStateToProps(state) {
    return {
        observationTypes: state.dictionary.dictionaries.AH_OBSERVATION_TYPE,
        operatorsTypes: state.dictionary.dictionaries.AH_OPERATORS,
        savedObservationThresholds: state.observationThreshold.savedObservationThresholds,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryAction: bindActionCreators(DictionaryDispatchAction, dispatch),
        ObservationsThresholdAction: bindActionCreators(ObservationsThresholdAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ObservationThresholdsComponent);