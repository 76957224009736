import {ActionTypes as ActionType} from "../action/FacilityAction";


const initialState = {
    facilities: {list: [], totalCount: 0},
    facilityContext: {},
}

export const facilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.FACILITY_LIST_SUCCESS: {
            return {...state, facilities: {list: action.payload.payload, totalCount: action.payload.totalCount}};
        }
        case ActionType.FACILITY_LIST_ERROR:
            return {...state, facilityListError: action.payload};
        case ActionType.CLEAR_UPDATE:
            return {...state, updated: false}
        case ActionType.SET_FACILITY_CONTEXT:
            return {...state, facilityContext: action.payload}
        case ActionType.CLEAR_FACILITY_CONTEXT:
            return {...state, facilityContext: {}}
        default:
            return state
    }

}

