import {useSelector} from "react-redux";
import ActivityStreamComponent from "../components/ActivityStreamComponent";
import AeriosSideMenu from "../components/AeriosSideMenu";
import PhixAppBar from "../components/PhixAppBar";
import React, {useEffect} from "react";
import {Route, Switch} from "react-router-dom";
import PatientPanelPage from "./PatientPanelPage";
import AdminPage from "./AdminPage";
import PatientCareTeamListComponent from "../components/PatientCareTeamListComponent";
import PatientListPage from "./PatientListPage";

import {Window,} from "@progress/kendo-react-dialogs";
import CareTeamWorkspacePage from "./CareTeamWorkspacePage";
import ProtectedRoute from "../route/ProtectedRoute";
import NewLoginPage from "./NewLoginPage";
import CohortAnalysisPage from "./CohortAnalysisPage";


function MainPage(props) {

    const [visibleWindow, setVisibleWindow] = React.useState(false);
    const authenticated = useSelector(state => state.auth.authenticated)
    const toggleWindow = () => {
        setVisibleWindow(!visibleWindow);
    };
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return <>

        <div>
            <PhixAppBar/>

            <AeriosSideMenu>

                <Switch>
                    <Route exact={true} path="/passwordreset/:token" component={NewLoginPage}/>
                    <Route exact={true} path="/login" component={NewLoginPage}/>
                    <Route exact={false} path="/register/:token" component={NewLoginPage}/>
                    <Route exact={false} path="/register" component={NewLoginPage}/>
                    <ProtectedRoute exact={true} path="/" component={CareTeamWorkspacePage}/>
                    <ProtectedRoute exact={false} path="/patientList" component={PatientListPage}/>
                    <ProtectedRoute path={"/patientPanel/:patientRoleId?"} component={PatientPanelPage}/>
                    <ProtectedRoute exact={true} path={"/careTeam"} component={PatientCareTeamListComponent}/>
                    <ProtectedRoute exact={true} path={"/admin"} component={AdminPage}/>
                    <ProtectedRoute exact={true} path={"/cohort"} component={CohortAnalysisPage}/>
                </Switch>
            </AeriosSideMenu>

            {visibleWindow &&
                <Window title={"Activity Stream"} className="activityStreamPop" initialWidth={450} initialHeight={700}
                        onClose={toggleWindow}>
                    <ActivityStreamComponent channelType={"userRole"}/>
                </Window>
            }

        </div>
    </>
}


export default MainPage