import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    PERMISSION_GET_PERMISSION_GROUPS: "PERMISSION_GET_PERMISSION_GROUPS",
    ADMIN_PERMISSION_GET_PERMISSION_GROUPS: "ADMIN_PERMISSION_GET_PERMISSION_GROUPS",
    ASSIGN_PERMISSION_TO_PERMISSION_GROUP: "ASSIGN_PERMISSION_TO_PERMISSION_GROUP",
    REMOVE_PERMISSION_TO_PERMISSION_GROUP: "REMOVE_PERMISSION_TO_PERMISSION_GROUP",
    TOGGLE_ACTIVE_TO_PERMISSION_GROUP: "TOGGLE_ACTIVE_TO_PERMISSION_GROUP",
    PERMISSION_DICTIONARY_LIST_SUCCESS: "PERMISSION_DICTIONARY_LIST_SUCCESS",
    PERMISSION_CATEGORY_LIST_SUCCESS: "PERMISSION_CATEGORY_LIST_SUCCESS",
}


function getPermissionGroups() {
    return WrapAuthentication('permission/groups/get', {},
        ActionTypes.PERMISSION_GET_PERMISSION_GROUPS
    )
}
function getAllPermissionGroups() {
    return WrapAuthentication('permission/all', {},
        ActionTypes.ADMIN_PERMISSION_GET_PERMISSION_GROUPS
    )
}

function updateEncounter(encounter) {
    return WrapAuthentication("encounter/update",
        {
            payload: encounter
        },
        ActionTypes.UPDATE_ENCOUNTER)
}

function assignPermissionToPermissionGroup(payload) {
    return WrapAuthentication('permission/assign',{
          payload: payload
      },
      ActionTypes.ASSIGN_PERMISSION_TO_PERMISSION_GROUP)
}
function removePermissionToPermissionGroup(id) {
    return WrapAuthentication('permission/remove',{
          payload: id
      },
      ActionTypes.REMOVE_PERMISSION_TO_PERMISSION_GROUP)
}

function removePermissionFromPermissionGroup(permissionId, permissionGroupId) {
    return WrapAuthentication('permission/groups/permission/remove',{
          payload: {
            permissionGroupId: permissionGroupId,
            permissionId:permissionId
          }
      },
      ActionTypes.REMOVE_PERMISSION_TO_PERMISSION_GROUP)
}
function toggleActivePermissionGroup(permissionGroupId, toActiveInactive) {
    return WrapAuthentication('permission/group/active',{
          payload: {
            permissionGroupId: permissionGroupId,
            active:toActiveInactive
          }
      },
      ActionTypes.TOGGLE_ACTIVE_TO_PERMISSION_GROUP)
}


function getDictionaryByCategory(payload, code) {
    return WrapAuthentication('dictionary/permission/search/by/category',payload,
        ActionTypes.PERMISSION_DICTIONARY_LIST_SUCCESS, {code}
    )
}

function getPermissionCategoriesByCode(code, limit, offset) {
    let l = limit ? limit : 1000
    let o = offset ? offset : 0
    return WrapAuthentication('dictionary/code', {
            payload: {code: code},
            limit: l,
            offset: o,
        },
        ActionTypes.PERMISSION_CATEGORY_LIST_SUCCESS,
        {code}
    )
}

export const PermissionAction =
    {
        getPermissionGroups: getPermissionGroups,
        getAllPermissionGroups: getAllPermissionGroups,
        assignPermissionToPermissionGroup: assignPermissionToPermissionGroup,
        removePermissionToPermissionGroup: removePermissionToPermissionGroup,
        removePermissionFromPermissionGroup: removePermissionFromPermissionGroup,
        toggleActivePermissionGroup: toggleActivePermissionGroup,
        getDictionaryByCategory: getDictionaryByCategory,
        getPermissionCategoriesByCode: getPermissionCategoriesByCode,
    }