import {ActionTypes} from '../action/LaboratoryAction';

const initialState = {
    laboratoryList: {labs: []}
}

export const laboratoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LABORATORY_GET_LABS:
            return {...state, laboratoryList: action.payload}
        case ActionTypes.LABORATORY_GET_INSTALLED_LABS:
            return {...state, installedLaboratoryList: action.payload}
        case ActionTypes.LABORATORY_GET_ROUTES:
            return {...state, installedRoutes: action.payload}
        default:
            return state;
    }
}