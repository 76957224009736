import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import DocumentTreeViewComponent from './DocumentTreeViewComponent';
import DocumentViewerComponent from "./DocumentViewerComponent";
import DocumentInfoComponent from "./DocumentInfoComponent"
import {DictionaryDispatchAction} from "../../../action/DictionaryDispatchAction";
import {Button} from "primereact/button";
import {PopupWindowActions, PopupWindows,} from "../../../action/PopupWindowAction";
import {PatientAction} from "../../../action/PatientAction";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import ShowForPermissionComponent from "../../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../../config/GlobalConfig";
import {Col, Container, Row} from "../../../layout/GridLayout";

const styles = {
    notification: {right: 0, bottom: 0, alignItems: "flex-start", flexWrap: "wrap-reverse"},
}

const DocumentsTabComponent = (props) => {
    const dispatch = useDispatch()
    const clinicalDocumentContext = useSelector(state => state.patient.clinicalDocumentContext)
    const [flag, setFlag] = React.useState(false);
    const [selectedDocument, setSelectedDocument] = React.useState("")
    const [isToast, setIsToast] = React.useState(false);
    const [showDocument, setShowDocument] = React.useState(true);
    const [message, setMessage] = React.useState("")

    React.useEffect(() => {
        DictionaryDispatchAction.getDictionaryByCode('AH_DOCUMENT_CATEGORIES', 500, 0)(dispatch)
        PatientAction.setClinicalDocumentContext({
            ...clinicalDocumentContext,
            setMessage: setMessage,
            setIsToast: setIsToast
        })(dispatch)
    }, [])

    React.useEffect(() => {
    }, [props])

    return (
        <Container style={{height: "100%", width : "100%"}}>
            <Row className={"justify-content-end"} style={{height: "50px", width: "100%"}}>
                <Col className={"flex-grow-0"}>
                    <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_DOCUMENT]}>
                        <Button
                            onClick={() => {
                                PatientAction.setClinicalDocumentContext({
                                    ...clinicalDocumentContext,
                                    edit: false
                                })(dispatch)
                                PopupWindowActions.showWindow(PopupWindows.CREATE_DOCUMENT_WINDOW)(dispatch)
                            }}
                            text
                            tooltip={"Add document to patient chart"}
                            size={"small"}
                            icon={"pi pi-plus"}>

                        </Button>
                    </ShowForPermissionComponent>
                </Col>
            </Row>

            <Row style={{height: "calc(100% - 50px)", margin: "0"}}>
                <Col col={3} style={{border: "1px solid #f1eded", overflowY: "scroll",}}>
                    <DocumentTreeViewComponent setFlag={setFlag} flag={flag} selectedDocument={selectedDocument}
                                               setSelectedDocument={setSelectedDocument}/>
                </Col>
                <Col col={9}>{
                    selectedDocument ?
                        <Container style={{height: "calc(100% - 90px)", }}>
                            <Col col={12} style={{border: "1px solid #f1eded",}}>
                                <DocumentInfoComponent showDocument={showDocument} setShowDocument={setShowDocument}
                                                       setFlag={setFlag} flag={flag}
                                                       selectedDocument={selectedDocument}
                                                       setSelectedDocument={setSelectedDocument}/>
                            </Col>
                            {showDocument ?
                                <Col col={12} style={{
                                    border: "1px solid #f1eded",
                                    height: "100%",

                                }}>
                                    <DocumentViewerComponent setFlag={setFlag} flag={flag}
                                                             showDocument={showDocument}
                                                             setShowDocument={setShowDocument}
                                                             selectedDocument={selectedDocument}
                                                             setSelectedDocument={setSelectedDocument}/>
                                </Col> : <></>}
                        </Container> : <></>
                }
                </Col>
            </Row>

            <NotificationGroup style={styles.notification}>
                {isToast ?
                    <Notification type={{style: "success", icon: true}} closable={true}
                                  onClose={() => setIsToast(false)}>
                        {message}
                    </Notification>
                    :
                    ""
                }
            </NotificationGroup>

        </Container>
    )
}


export default DocumentsTabComponent