import * as React from "react";
import {Grid, GridColumn, GridToolbar} from "@progress/kendo-react-grid";
import {Container, Row} from "react-grid-system";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "primereact/button";
import {ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {Checkbox} from "./InputControls/Checkbox";
import {Input} from "@progress/kendo-react-inputs";
import {PopupWindowActions as PopupActions, PopupWindows} from "../action/PopupWindowAction";
import {InsuranceActions} from "../action/InsuranceAction";
import {filterBy, orderBy} from '@progress/kendo-data-query';
import {PhixPermissions} from "../config/GlobalConfig";
import ShowForPermissionComponent from "./shared/ShowForPermissionComponent";


const AdminInsuranceComponent = (props) => {
    const dispatch = useDispatch()
    const insurancePlans = useSelector(state => state.insurance?.insurancePlans)
    const totalCount = useSelector(state => state.insurance?.totalCount)
    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });
    const [showAllFlag, setShowAllFlag] = React.useState(false);

    const initialSort = [
        {
            field: "planName",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);
    const [searchState, setSearchState] = React.useState("")
    const [filterState, setFilterState] = React.useState(false)
    const [filter, setFilter] = React.useState({logic: "and", filters: []})
    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };


    const showAllfn = (e) => {
        let v = e.target.value;
        setShowAllFlag(v);
    }

    const buildFilters = () => {
        let filters = {}
        if (searchState.length > 0) filters.searchAll = searchState
        if (filter?.filters) {
            if (filter.filters.length > 0) {
                filter.filters.forEach(x => {
                    filters[x.field] = x.value
                })
            }

        }
        return filters;
    }

    React.useEffect(() => {
        InsuranceActions.getInsurancePlans(1000, 0, null, showAllFlag === true ? {planName: searchState} : {
            active: true,
            planName: searchState
        })(dispatch);
    }, [searchState, showAllFlag]);

    const CommandCell = (item) => (
        <td className="k-command-cell">
            <Button icon={"pi pi-pencil"} text onClick={(e) => {
                InsuranceActions.setInsurancePlanContext(item.dataItem)(dispatch);
                PopupActions.setWindowState(PopupWindows.INSURANCE_PLAN_WINDOW, {
                    showAllFlag,
                    searchState
                })(dispatch)
                PopupActions.showWindow(PopupWindows.INSURANCE_PLAN_WINDOW)(dispatch)
            }
            }/>
        </td>
    );

    function IsActiveCheckBox(item) {
        return <td><Checkbox value={item.dataItem.active}/></td>
    }

    return (
        <ShowForPermissionComponent component permissions={[PhixPermissions.AH_ADMIN_VIEW_INSURANCE_PLAN]}>
            <Container style={{height: "100%", width: "100%"}}>

                <Row style={{height: "100%"}}>
                    <Grid
                        {...dataState}
                        style={{height: "100%"}}
                        // data={data}
                        data={insurancePlans && filterBy(orderBy(insurancePlans, sort), filter)}
                        pageSize={dataState.limit}
                        total={totalCount}
                        filterable={filterState}
                        sortable={true}
                        skip={dataState.skip}
                        pageable={{
                            pageSizes: [50, 100, 150],
                            buttonCount: 10,
                            info: true,
                            type: "numeric",
                            previousNext: true,
                        }}
                        filter={filter}
                        onFilterChange={(e) => setFilter(e.filter)}
                        onDataStateChange={dataStateChange}

                        // sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}>
                        <GridToolbar>
                            <Input
                                style={{width: "50%"}}
                                placeholder={"Search"}
                                id={"input-id"}
                                value={searchState}
                                onChange={(e) => setSearchState(e.value)}
                            />
                            <ToolbarSpacer/>
                            <ToolbarItem>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="showAll"
                                              onChange={showAllfn} checked={showAllFlag}/>
                                    <label style={{color: "rgb(116, 132, 194)"}} htmlFor="showAll" className="ml-2">Show
                                        All</label>
                                </div>

                            </ToolbarItem>
                            <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_INSURANCE_PLAN]}>
                                <Button className="rounded-button"
                                        icon="pi pi-plus"
                                        severity={"success"}
                                        tooltip={"Add Insurance Plan"}
                                        text
                                        onClick={(e) => {
                                            InsuranceActions.setInsurancePlanContext(null)(dispatch);
                                            PopupActions.setWindowState(PopupWindows.INSURANCE_PLAN_WINDOW, {showAllFlag: showAllFlag})(dispatch)
                                            PopupActions.showWindow(PopupWindows.INSURANCE_PLAN_WINDOW)(dispatch)
                                        }}/>
                            </ShowForPermissionComponent>
                        </GridToolbar>
                        <GridColumn field="planName" title="Plan Name"/>
                        <GridColumn field="dictInsuranceProviderDisplayname" title="Insurance Provider"/>
                        <GridColumn field="payerId" title="Payer ID"/>
                        <GridColumn field="contactPhone" title="Phone"/>
                        <GridColumn field="contactName" title="Contact Name"/>
                        <GridColumn field="active" title="Active" cell={IsActiveCheckBox} width={80}/>
                        <GridColumn title="Actions" cell={CommandCell} width={80}/>
                    </Grid>
                </Row>

            </Container>
        </ShowForPermissionComponent>
    );
};

export default AdminInsuranceComponent
