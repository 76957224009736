import {bindActionCreators} from "redux";
import {PatientAction} from "../../../action/PatientAction";
import {DictionaryDispatchAction} from "../../../action/DictionaryDispatchAction";
import {DictionaryTypeAction} from "../../../action/DictionaryTypeAction";
import {PersonActions} from "../../../action/PersonAction";
import {PopupWindowActions} from "../../../action/PopupWindowAction";
import {CarePlanActions} from "../../../action/CarePlanAction";
import {connect} from "react-redux";
import React from "react";
import {WindowActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "primereact/button";
import {FieldSection} from "../../InputControls/FormFields";
import DictionaryDropDownList, {Dictionary} from "../../InputControls/DictionaryDropDownList";
import {ListView, ListViewHeader} from "@progress/kendo-react-listview";

const DeterminantStep = (props) => {
    const {
        medicalProblems,
        patientContext,
        PatientActions,
        CarePlanActions,
        stepIndex,
        nextStep,
        previousStep,
        hideDialog,
        steps,
        setFormFromStep,
        carePlanEdit
    } = props
    const [determinants, setDeterminants] = React.useState(carePlanEdit.determinants ? carePlanEdit.determinants : [])
    const [selectedDeterminant, setSelectedDeterminant] = React.useState({})

    React.useEffect(() => {
        if (carePlanEdit?.condition?.id) CarePlanActions.getDeterminantsForProblemEdit(carePlanEdit.condition.id)
    }, [])


    React.useEffect(() => {
        setDeterminants(carePlanEdit.determinants)
    }, [carePlanEdit.determinants])

    const saveDeterminants = () => {
        //CarePlanActions.setEditCarePlanDeterminants(determinants)
        //setFormFromStep("determinants", determinants)
        nextStep()
    }

    const removeDeterminant = (id) => {
        CarePlanActions.removeDeterminantForProblemEdit(id)

    }
    const listHeader = () => {
        return (
            <ListViewHeader
                style={{
                    color: "rgb(160, 160, 160)",
                    fontSize: 14,
                }}
                className="pl-3 pb-2 pt-2"
            >
                Determinants: {carePlanEdit.condition?.displayName}
            </ListViewHeader>
        );
    };

    const itemRenderer = (props) => {
        let item = props.dataItem;
        return (
            <div className={"row k-display-flex k-justify-content-center k-align-items-center k-border-bottom"}
                 style={{borderBottom: "1px solid rgb(242, 242, 242)", height: "40px"}}>
                <div className="col-10 k-display-flex k-align-items-center" style={{height: "40px", margin: 0}}>
                    {item.dictDeterminantDisplayName}
                </div>
                <div className="col-2 k-display-flex k-align-items-center" style={{height: "40px", margin: 0}}>
                    <Button look={"flat"} icon={"delete"} onClick={() => removeDeterminant(item.id)}> </Button>
                </div>
            </div>
        );
    };

    const addDeterminant = () => {
        // console.log(selectedDeterminant)
        if (selectedDeterminant?.id) {
            CarePlanActions.addDeterminantForProblemEdit(carePlanEdit.condition.id, selectedDeterminant.id)
        }else
        {
            alert("Error adding determinant.")
        }

        //if (selectedDeterminant.id) setDeterminants(_.uniq([...determinants, selectedDeterminant]))
    }

    return <div style={{height: "calc(100% - 120px)"}}>
        <FieldSection style={{height: "100%", width: "90%"}}>
            {/*{JSON.stringify(form, null, 4)}*/}
            <div className="sub-title">Determinants</div>
            <div className={"row"}>
                <div className={"col-10"}>
                    <DictionaryDropDownList style={{width: "100%"}}
                                            code={Dictionary.CARE_PLAN_DETERMINANTS}
                                            onChange={(e) => setSelectedDeterminant(e.target.value)}
                    />
                </div>
                <div className={"col-2"}>
                    <Button onClick={addDeterminant} icon={"add"}></Button>
                </div>
            </div>
            <br/>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ListView
                        style={{height: "200px"}}
                        data={determinants}
                        header={listHeader}
                        item={itemRenderer}
                        textField={"dictDeterminantDisplayName"}
                        selectedField={"selected"}/>
                </div>
            </div>
        </FieldSection>

        <WindowActionsBar>
            <Button style={{backgroundColor: "#E06666"}} onClick={hideDialog}>
                Cancel
            </Button>
            {stepIndex > 0 && <Button icon={"arrow-left"} themeColor={"primary"} onClick={previousStep}>
                Back
            </Button>}
            <Button icon={"arrow-right"} themeColor={"primary"} onClick={saveDeterminants}>
                Next
            </Button>
        </WindowActionsBar>
    </div>

}

function mapStateToProps(state) {
    return {
        carePlan: state.patient.carePlan,
        carePlanEdit: state.patient.carePlanEdit,
        showWindow: state.popupWindow.showWindow,
        patientContext: state.patient.patientContext,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        CarePlanActions: bindActionCreators(CarePlanActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeterminantStep)