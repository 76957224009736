import {Button} from "primereact/button";
import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PatientAction} from "../../action/PatientAction";
import {EncounterAction} from "../../action/EncounterAction";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {Col} from "react-grid-system";
import moment from "moment/moment";
import {PermissionsTool} from "../../util/PermissionsTool";
import {Dialog} from "primereact/dialog";
import {Container} from "../../layout/GridLayout";
import {DATE_FORMAT, API_DATE_FORMAT, PhixPermissions} from "../../config/GlobalConfig";
import {
    FormColumn,
    FormDateSelector,
    FormDictionaryDropdown,
    FormMultiDictionaryDropdown,
    FormRow,
    FormTextInput,
    usePhixForm
} from "../InputControls/NewFormFields";
import {v4 as uuidv4} from "uuid";
import { ProgressSpinner } from 'primereact/progressspinner';

const defaultValues = {
    id: null,
    dictClinicalStatusId: null,
    dictAllergyClassId: null,
    displayName: null,
    description: null,
    startDate: null,
    endDate: null,
    patientRoleId: null,
    dictSourceOfInfoId: null,
    dictOccurrenceId: null,
    dictSeverityId: null,
    note: null,
    diagnosisCode: [],
    reactions: []
}
const AddAllergyWindow = (props) => {

    const dispatch = useDispatch()
    const patientContext = useSelector((state) => state.patient.patientContext)
    const allergyContext = useSelector((state) => state.popupWindow.state[PopupWindows.ADD_ALLERGIES_WINDOW]?.allergyContext)
    const showWindow = useSelector((state) => state.popupWindow.showWindow)
    const permissions = useSelector((state) => state.auth.session.permissions)
    const encounterContext = useSelector(state => state.encounters.encounterContext)
    const allergiesSel = useSelector(state => state.encounters.allergies)
    // useTraceUpdate(patientContext, "Allergies: ")
    // useTraceUpdate(allergyContext, "Allergies: ")
    // useTraceUpdate(showWindow, "Allergies: ")
    // useTraceUpdate(permissions, "Allergies: ")
    const [isReset, setIsReset] = React.useState(true)

    const [{
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch,
    }, PhixForm] = usePhixForm({defaultValues: defaultValues});

    // const [allergyContext, setAllergiesContext] = React.useState(popupState[PopupWindows.ADD_ALLERGIES_WINDOW]?.allergyContext)
    const allergiesPermission = PermissionsTool({
        permissionInfo: permissions,
        viewPermissions: [PhixPermissions.AH_VIEW_ALLERGY],
        editPermissions: [PhixPermissions.AH_EDIT_ALLERGY]
    });

    const hideDialog = async () => {
        await PopupWindowActions.hideWindow(PopupWindows.ADD_ALLERGIES_WINDOW)(dispatch);
        reset(defaultValues)


    };

    React.useEffect(() => {
        if (allergyContext?.id) {
            reset(allergyContext)
        }
    }, [allergyContext])


    async function saveAllergiesAndReset(form) {
        let savePayload = {
            id: uuidv4(),
            dictClinicalStatusId: form?.dictClinicalStatusId?.id ? form?.dictClinicalStatusId?.id : form?.dictClinicalStatusId,
            displayName: form?.displayName,
            diagnosisCode: form.diagnosisCode && form.diagnosisCode.length > 0 ? form.diagnosisCode.map(i => i?.id) : [],
            startDate: form?.startDate ? moment(form?.startDate).format(DATE_FORMAT) : null,
            endDate: form?.endDate ? moment(form?.endDate).format(DATE_FORMAT) : null,
            patientRoleId: patientContext.patientRoleId,
            dictSourceOfInfoId: form?.dictSourceOfInfoId?.id ? form?.dictSourceOfInfoId?.id : form?.dictSourceOfInfoId,
            dictSeverityId: form?.dictSeverityId?.id ? form?.dictSeverityId?.id : form?.dictSeverityId,
            dictAllergyClassId: form?.dictAllergyClassId?.id ? form?.dictAllergyClassId?.id : form?.dictAllergyClassId,
            dictOccurrenceId: form?.dictOccurrenceId?.id ? form?.dictOccurrenceId?.id : form?.dictOccurrenceId,
            reactions: form?.reactions?.length ? form?.reactions.map(i => i?.id ? i?.id : i) : [],
            note: form?.note,
        };
        setIsReset(false)
        await PatientAction.createAllergies(savePayload)(dispatch)
        await PatientAction.getAllergies(patientContext?.patientRoleId)(dispatch)
        await EncounterAction.saveEncounterAllergy({encounterId: encounterContext.id, issueId: savePayload.id})(dispatch)
        await EncounterAction.setAllergyId({id: savePayload.id})(dispatch);
        await reset(defaultValues)
        setIsReset(true)
    }
    async function saveAllergies(form) {
        if (form?.id) {

            let savePayload = {
                id: form.id,
                displayName: form?.displayName,
                patientRoleId: patientContext.patientRoleId,
                dictClinicalStatusId: form?.dictClinicalStatusId?.id ? form?.dictClinicalStatusId?.id : form?.dictClinicalStatusId,

                note: form?.note,
                startDate: form?.startDate ? moment(form?.startDate).format(DATE_FORMAT) : null,
                endDate: form?.endDate ? moment(form?.endDate).format(DATE_FORMAT) : null,
                diagnosisCode: form.diagnosisCode && form.diagnosisCode.length > 0 ? form.diagnosisCode.map(i => i?.id) : [],
                dictSourceOfInfoId: form?.dictSourceOfInfoId?.id ? form?.dictSourceOfInfoId?.id : form?.dictSourceOfInfoId,
                dictSeverityId: form?.dictSeverityId?.id ? form?.dictSeverityId?.id : form?.dictSeverityId,
                dictAllergyClassId: form?.dictAllergyClassId?.id ? form?.dictAllergyClassId?.id : form?.dictAllergyClassId,
                dictOccurrenceId: form?.dictOccurrenceId?.id ? form?.dictOccurrenceId?.id : form?.dictOccurrenceId,
                reactions: form?.reactions?.length ? form?.reactions.map(i => i?.id ? i?.id : i) : []
            };
            await PatientAction.updateAllergies(savePayload)(dispatch)
            await PatientAction.getAllergies(patientContext?.patientRoleId)(dispatch)
            await EncounterAction.setAllergyId({id: savePayload.id})(dispatch);
            if (!allergiesSel.find(v=> v.id === savePayload.id))
                await EncounterAction.saveEncounterAllergy({encounterId: encounterContext.id, issueId: savePayload.id})(dispatch)
            await hideDialog()
        } else {
            let savePayload = {
                id: uuidv4(),
                dictClinicalStatusId: form?.dictClinicalStatusId?.id ? form?.dictClinicalStatusId?.id : form?.dictClinicalStatusId,
                displayName: form?.displayName,
                diagnosisCode: form.diagnosisCode && form.diagnosisCode.length > 0 ? form.diagnosisCode.map(i => i?.id) : [],
                startDate: form?.startDate ? moment(form?.startDate).format(DATE_FORMAT) : null,
                endDate: form?.endDate ? moment(form?.endDate).format(DATE_FORMAT) : null,
                patientRoleId: patientContext.patientRoleId,
                dictSourceOfInfoId: form?.dictSourceOfInfoId?.id ? form?.dictSourceOfInfoId?.id : form?.dictSourceOfInfoId,
                dictSeverityId: form?.dictSeverityId?.id ? form?.dictSeverityId?.id : form?.dictSeverityId,
                dictAllergyClassId: form?.dictAllergyClassId?.id ? form?.dictAllergyClassId?.id : form?.dictAllergyClassId,
                dictOccurrenceId: form?.dictOccurrenceId?.id ? form?.dictOccurrenceId?.id : form?.dictOccurrenceId,
                reactions: form?.reactions?.length ? form?.reactions.map(i => i?.id ? i?.id : i) : [],
                note: form?.note,
            };
            await PatientAction.createAllergies(savePayload)(dispatch)
            await PatientAction.getAllergies(patientContext?.patientRoleId)(dispatch)
            await EncounterAction.saveEncounterAllergy({encounterId: encounterContext.id, issueId: savePayload.id})(dispatch)
            await EncounterAction.setAllergyId({id: savePayload.id})(dispatch);
            await hideDialog()

        }
    }

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'diagnosisCode') {
                let v = value?.diagnosisCode.map(d => d.displayName).join("; ")
                if (v.length > 0) setValue("displayName", v)
            }

        })
        return () => subscription.unsubscribe();
    }, [watch]);

    const canEditable = () => {
        if (allergiesPermission.isEdit) {
            return true;
        } else {
            return false;
        }
    }


    return (
        <Dialog
            visible={showWindow[PopupWindows.ADD_ALLERGIES_WINDOW]}
            maximizable
            header={allergyContext?.id ? "Edit Allergy" : "Add Allergy"}
            onHide={hideDialog}
            footer={<Container className={"justify-content-end"}>
                {!allergyContext?.id && <Button primary onClick={handleSubmit(saveAllergiesAndReset)} label={"Save and Add New"}/>}
                <Button primary onClick={handleSubmit(saveAllergies)} label={allergyContext?.id ? "Update" : "Save and Close"}
                        severity={"success"}/>


                <Button onClick={hideDialog} label={"Cancel"} severity={"danger"}/>

            </Container>}
            style={{height: 725, width: 800}}
        >
            <div style={{pointerEvents: canEditable() ? 'block' : 'none'}}>
                {isReset ? <PhixForm>
                    <FormRow>
                        <Col sm={6}/>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Clinical Status"}
                                field={"dictClinicalStatusId"}
                                rules={{required: "Clinical Status is required."}}
                                code={Dictionary.PROBLEM_STATUS}
                                defaultItemCode={"FHIR_PROBLEM_ACTIVE"}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormMultiDictionaryDropdown
                                showCode
                                fullObject
                                code={Dictionary.ICD10}
                                label={"Diagnosis Codes"}
                                field={"diagnosisCode"}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow style={{marginTop: 10}}>
                        <FormColumn col={12}>
                            <FormTextInput
                                label={"Allergy"}
                                field={"displayName"}
                                rules={{required: "Allergy is required."}}/>
                        </FormColumn>

                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Allergy Class"}
                                field={"dictAllergyClassId"}
                                code={Dictionary.AH_ALLERGY_CLASS}
                            />
                        </FormColumn>
                        <Col col={6} style={{width: '50%', paddingTop: '20px', fontSize: "12px", textAlign: "center"}}
                             className={"align-items-center"}>
                            <i>Allergy Class is optional but recommended to check for drug interactions.</i>
                        </Col>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDateSelector dateFormat={API_DATE_FORMAT}
                                              label={"Onset Date"}
                                              field={"startDate"}
                                              defaultNow={true}
                                              rules={{required: "Onset date is required"}}/>
                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDateSelector label={"Abatement Date"}
                                              field={"endDate"}
                                              dateFormat={API_DATE_FORMAT}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Source of Info"}
                                field={"dictSourceOfInfoId"}
                                code={Dictionary.AH_CONDITION_SOURCE}
                            />
                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Occurrence"}
                                field={"dictOccurrenceId"}
                                code={Dictionary.AH_ALLERGY_OCCURRENCE}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Severity"}
                                field={"dictSeverityId"}
                                code={Dictionary.AH_ALLERGY_SEVERITY}
                            />
                        </FormColumn>


                    </FormRow>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormMultiDictionaryDropdown
                                fullObject
                                showCode={false}
                                label={"Reaction"}
                                field={"reactions"}
                                code={Dictionary.AH_ALLERGY_REACTION}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow style={{marginTop: 10}}>
                        <FormColumn col={12}>
                            <FormTextInput label={"Notes"} field={"note"}/>
                        </FormColumn>
                    </FormRow>
                </PhixForm> : <ProgressSpinner/>}


            </div>
        </Dialog>)
};


// function mapStateToProps(state) {
//     return {
//         showWindow: state.popupWindow.showWindow,
//         patient: state.patient.patientContext,
//         popupState: state.popupWindow.state,
//     };
// }
//
// function mapDispatchToProps(dispatch) {
//     return {
//         PatientActions: bindActionCreators(PatientAction, dispatch),
//         PersonActions: bindActionCreators(PersonActions, dispatch),
//         PopupActions: bindActionCreators(PopupWindowActions, dispatch),
//     };
// }

export default AddAllergyWindow;
