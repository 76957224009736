import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Col, Container} from "../../layout/GridLayout";
import {TabPanel, TabView} from "primereact/tabview";
import {PaginatedEndpoint} from "../../util/AuthWrapper";
import {Dictionary} from "./DictionaryDropDownList";
import PhixDataGrid from "../../typescript/component/data/PhixDataGrid";
import {GridToolbarContainer, GridToolbarQuickFilter} from "@mui/x-data-grid";
import {IconButton, ListItem, Paper} from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {UserPostAction} from "../../action/UserAction";
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import {Colors} from "../../config/GlobalConfig";
import Tooltip from "@mui/material/Tooltip";

const MultiProblemCheckboxSelector = (props) => {
        const {
            onChange,
        } = props
        const [internalValue, setInternalValue] = useState(props.value || []);
        const [favorite, setFavorite] = useState(false)
        const [activeTab, setActiveTab] = useState(0)
        const [update, setUpdate] = useState(0)
        const getData = useCallback(PaginatedEndpoint("dictionary/search/index/paginated", {
            dictionaryTypeCode: Dictionary.ICD10,
            active: true,
            favorite
        }), [favorite, update])

        const HandleSelect = useCallback((row) => {
            let item = row?.row
            let d = internalValue
            let f = d.findIndex(i => i.id === item.id)
            if (f < 0)
                setInternalValue([...d, item])

        }, [internalValue])

        useEffect(() => {
            if (onChange)
                onChange({value: internalValue})
        }, [internalValue])

        function CustomToolbar() {
            return (
                <GridToolbarContainer container>
                    <GridToolbarQuickFilter style={{width: "100%", padding: 5}} debounceMs={300}/>
                </GridToolbarContainer>
            );
        }

        const ToggleFavorite = (item) => {
            if (item?.favorite) {
                UserPostAction.removeDictionaryFavoriteToCurrentUser(item.id, item.dictionaryTypeId).then(() => {
                    setUpdate(update + 1)
                })
            } else {
                UserPostAction.addDictionaryFavoriteToCurrentUser(item.id, item.dictionaryTypeId).then(() => {
                    setUpdate(update + 1)
                })
            }
        }

        const CommandCell = (item) => {
            return <Container >
                <Tooltip title={"Toggle Favorite"}>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            ToggleFavorite(item.row)
                        }
                        }>
                        {item.row?.favorite ? <FavoriteIcon style={{color: Colors.RED}}/> : <FavoriteBorderIcon/>}
                    </IconButton>
                </Tooltip>
            </Container>;
        }


        const columns = [
            {
                field: "code",
                headerName: "ICD10",
                sortable: false
                // flex: 1,
                // valueFormatter: (e) => FormatUtcDateTimeToLocalZoneStringWithFormat(e.encounterDate)
            },
            {
                field: "displayName",
                headerName: "Description",
                sortable: false,
                flex: 1,
                renderCell: (item) => {
                    let fields = JSON.parse(item.row?.additionalFields)
                    if (fields?.billable) {
                        return (<td>{item?.row?.displayName}</td>)
                    } else
                        return (<td><b style={{color: "Red"}}>{item?.row?.displayName}</b></td>)


                }
            },
            {
                filterable: false,
                sortable: false,
                // field: "actions",
                // headerName: "F",
                width: 25,
                renderCell: CommandCell
            }


        ]


        function ProblemsArray() {
            const handleDelete = (problem) => () => {
                setInternalValue((chips) => chips.filter((chip) => chip.id !== problem.id));
            };

            function ProblemLabel(item) {
                let fields = item?.additionalFields ? JSON.parse(item?.additionalFields) : {}
                if (fields?.billable) {
                    return (<td>{item?.code}</td>)
                } else
                    return (<td><b style={{color: "Red"}}>{item?.code}</b></td>)
            }

            if (internalValue?.length > 0)
                return (

                    <Paper
                        sx={{

                            display: 'flex',
                            justifyContent: 'start',
                            flexGrow: 0,
                            flexWrap: 'wrap',
                            width: "100%",
                            // listStyle: 'none',
                            p: 1,
                            m: 0,
                        }}
                        component="ul"
                    >


                        {internalValue.map((data) => {

                            console.log(data)

                            return (
                                <ListItem key={data.id} sx={{p: 0.1, width: "auto"}}>
                                    <Chip
                                        // icon={<HighlightOffIcon/>}
                                        label={ProblemLabel(data)}
                                        onDelete={handleDelete(data)}
                                    />
                                </ListItem>
                            );
                        })}

                    </Paper>
                );
        }


        return (

            <Container>
                {/*<Col col={12}>*/}
                {/*    <hr/>*/}
                {/*</Col>*/}
                <Col col={12}>
                    <TabView activeIndex={activeTab} onTabChange={e => {
                        setActiveTab(e.index)
                        if (e.index === 1)
                            setFavorite(true)
                        else
                            setFavorite(false)
                    }}>
                        <TabPanel header={<SearchIcon style={{color: Colors.GREEN}}/>}>
                            <Container gutter style={{width: "100%", height: "100%"}}>
                                <Col col={12}>
                                    <ProblemsArray/>
                                </Col>
                                <Col col={12} style={{height: 400}}>
                                    <PhixDataGrid
                                        columns={columns}
                                        onCellClick={e => {
                                            console.log(e)
                                        }}
                                        onRowClick={HandleSelect}
                                        orderableFavorite
                                        loadingIndicator
                                        apiFunction={getData}
                                        rows={[]}
                                        slots={{toolbar: CustomToolbar}}
                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: {debounceMs: 500},
                                            },
                                        }}>

                                    </PhixDataGrid>
                                </Col>
                            </Container>
                        </TabPanel>

                        <TabPanel header={<FavoriteIcon style={{color: Colors.RED}}/>}>
                            <Container gutter style={{width: "100%", height: "100%"}}>
                                <Col col={12}>
                                    <ProblemsArray/>
                                </Col>
                                <Col col={12} style={{height: 400}}>
                                    <PhixDataGrid

                                        columns={columns}
                                        onRowClick={HandleSelect}
                                        orderableFavorite
                                        loadingIndicator
                                        apiFunction={getData}
                                        rows={[]}
                                        slots={{toolbar: CustomToolbar}}
                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: {debounceMs: 500},
                                            },
                                        }}
                                    >
                                    </PhixDataGrid>
                                </Col>
                            </Container>
                        </TabPanel>


                    </TabView>

                </Col>
            </Container>
        );
    }
;

export default MultiProblemCheckboxSelector
