import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {ExpandableAction} from "../../action/ExpandableAction";
import {connect} from "react-redux";
import React from "react";
import {getComponent} from "../shared/ComponentRegistry";
import {Dialog} from "primereact/dialog";


const ExpandableWindow = (props) => {
    const WindowComponent = getComponent(props.component)


    return (
        <Dialog
            visible={props.visible}
            header={"blah"}
            headerClassName={"hidden"}
            maximized={true}
            maximizable
            title={props.title}
            className="expandable-window"
            onClose={(e) => props.expandedWindow.toggleExpandableComponent()}>
            <div style={{height: "100%"}}>
                <WindowComponent {...props.componentProps}/>
            </div>
        </Dialog>
    )


}

ExpandableWindow.propTypes = {
    title: PropTypes.string,
    expanded: PropTypes.element,
    expandedWidth: PropTypes.string,
    expandedHeight: PropTypes.string,

}

function mapStateToProps(state) {
    return {
        visible: state.expandedWindow.expandableComponentVisible,
        component: state.expandedWindow.expandableComponent,
        componentProps: state.expandedWindow.componentProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        expandedWindow: bindActionCreators(ExpandableAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableWindow)