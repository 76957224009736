import { connect } from "react-redux";
import React from "react";
import TileHeader from "../../layout/TileHeader";
import { PatientAction } from "../../action/PatientAction";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import { TileContainer } from "../../layout/TileContainer";
import { TileBody } from "../../layout/TileBody";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { RadioButton } from "@progress/kendo-react-inputs";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import { PopupWindowActions, PopupWindows } from "../../action/PopupWindowAction";
import { UserActions } from "../../action/UserAction";
import { PhixPermissions } from "../../config/GlobalConfig";

const styles = {
    container: {
        width: "100%",
        padding: 0,
        margin: 0,
        height: "calc(100% - 35px)",
    },
    titleh5: {
        margin: '0',
        padding: '0',
        color: '#888a8e',
        fontSize: '22px',
    },
    row: {
        height: "100%",
        padding: 0,
        margin: 0,

    },
}


const HeartRateVariabilityComponent = (props) => {
    const history = useHistory()
    const [gridData, setGridData] = React.useState([]);
    const [radioValue, setRadioValue] = React.useState("male")

    // React.useEffect(() => {
    //     setGridData([{age: "18-25"}, {age: "26-35"}, {age: "36-45"}, {age: "46-55"}, {age: "56-65"}, {age: "66-75"}, {age: "75+"}])
    // }, [])

    React.useEffect(() => {
        const payload = {
            startDate: moment().startOf('day').subtract(7, "days").format('YYYY-MM-DD'),
            endDate: moment().endOf('day').format('YYYY-MM-DD'),
            gender: radioValue
        }
        props.PatientActions.getHeartVariability(payload);
    }, [])

    React.useEffect(() => {
        const data = [];
        if (props?.getHeartVariability) {
            gridData.forEach((o) => {
                const getRec = props?.getHeartVariability?.find((i) => i.age === o.age);
                if (getRec) data.push(getRec)
            })
        }
        
        const filterNullData = props?.getHeartVariability?.filter((newVal) => {
              return newVal.age!="null"; 
        });
        setGridData(filterNullData);
        if (!props?.getHeartVariability || !props?.getHeartVariability?.length) setGridData([])
    }, [props?.getHeartVariability])

    const handleChange = React.useCallback(
        (e) => {
            setRadioValue(e.value);
            const payload = {
                startDate: moment().startOf('day').subtract(7, "days").format('YYYY-MM-DD'),
                endDate: moment().endOf('day').format('YYYY-MM-DD'),
                gender: e.value
            }
            props.PatientActions.getHeartVariability(payload);
        },
        [setRadioValue])

    function calculateAge(bDate) {
        const dateOfBirth = bDate;
        if (dateOfBirth === "")
            return "(N/A)"
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    function inRange(d, start, end) {
        return (
            isFinite(d = parseInt(d, 10)) &&
                isFinite(start = parseInt(start, 10)) &&
                isFinite(end = parseInt(end, 10)) ?
                start <= d && d <= end :
                NaN
        );
    }

    const test = (selectedAgeRange) => {
        let filteredPatientList = props.patients ? props.patients : []
        const tdelete = [{ age: "18-25" }, { age: "26-35" }, { age: "36-45" }, { age: "46-55" }, { age: "56-65" }, { age: "66-75" }];
        if (props.getHeartVariability != undefined) {
            let patientAgeRange = selectedAgeRange;
            // props?.getHeartVariability?.map((element) => {
            //     patientAgeRange = element.age
            // })
            if (Object.keys(filteredPatientList).length > 0) {
                
                const sortedPatientList = filteredPatientList.filter((i, k) => {
                    const patientAge = calculateAge(i.dateOfBirth)
                    if (patientAgeRange != null) {
                        const ageRange = patientAgeRange.split("-");
                        const result = inRange(patientAge, ageRange[0], ageRange[1])
                        return inRange(patientAge, ageRange[0], ageRange[1])
                    }
                })
                return sortedPatientList
            }
        } else {
            return props.patients ? props.patients : []
        }
    }

    const handleClick = (e) => {
        // console.log(e.dataItem.patientRoleId)
        // props.PatientActions.getPatientList(50, 0, [
        //     {
        //         field: "lastName",
        //         dir: "asc",
        //     },
        // ], {}, false)
        // const patientwithinRange = test(e.dataItem.age);
        // console.log(patientwithinRange)
        // if (patientwithinRange != undefined && patientwithinRange.length > 0) {
        //     const listPatientwithinRange = patientwithinRange.map((i) => {
        //         return i.patientRoleId
        //     })
        //     console.log(patientwithinRange, listPatientwithinRange)
        //     props.UserActions.getPatientsForPieChart({
        //         patientRoleId: listPatientwithinRange
        //     });
        // }
        props.UserActions.getPatientsForPieChart({
            patientRoleId: [e.dataItem.patientRoleId]
        });
        props.PopupActions.showWindow(PopupWindows.PATIENT_LIST_FOR_PIE)
    }
    const handleClicks = (e, patientRoleId, value) => {
        console.log(e)
        if (value > 0) {
            props.UserActions.getPatientsForPieChart({
                patientRoleId: [patientRoleId]
            });

        }else{
            props.UserActions.getPatientsForPieChart({
                patientRoleId: []
            });
        }
        props.PopupActions.showWindow(PopupWindows.PATIENT_LIST_FOR_PIE)
    }
    const handleValue050 = (props) => {
        
        return (
            <td onClick={(e) => handleClicks(e, props.dataItem.patientRoleId, props.dataItem.value050)}>
                {props.dataItem.value050}
            </td>
        )
    }
    const handleValu5175 =(props)=>{
        return(
            <td onClick={(e)=>handleClicks(e,props.dataItem.patientRoleId,props.dataItem.value5175)}>{props.dataItem.value5175}</td>
        )
    }
    const handleValue76100 =(props)=>{
        return(
            <td onClick={(e)=>handleClicks(e,props.dataItem.patientRoleId,props.dataItem.value76100)}>{props.dataItem.value76100}</td>
        )
    }
    const handleValue100plus = (props)=>{
        return(
            <td onClick={(e)=>handleClicks(e,props.dataItem.patientRoleId,props.dataItem.value100plus)}>{props.dataItem.value100plus}</td>
        )
    }
    const Title = () => {
        return (
            <Row style={{ width: "200%", height: 20, margin: 0, padding: 0 }}>
                <Col sm={12}
                    style={{
                        fontSize: "16px",
                        color: "black",
                        padding: 0,
                        margin: 0
                    }}>
                    {"Heart Rate Variability"}
                    <span style={{ float: 'right', width: '160px', position: 'relative', zIndex: '999999' }}>

                        <span style={{ marginRight: '15px' }}>
                            <RadioButton
                                name="group1"
                                value="male"
                                label="Men"
                                checked={radioValue === "male"}
                                onChange={handleChange}
                            /></span>
                        <RadioButton
                            name="group1"
                            value="female"
                            label="Women"
                            checked={radioValue === "female"}
                            onChange={handleChange}
                        />
                    </span>
                </Col>
            </Row>
        )
    }

    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                <TileContainer>
                    <TileHeader title={<Title />} image={"../images/hospitalHeart.png"}
                    />
                    <TileBody>
                        {gridData ? (
                            <>
                                <Grid
                                    style={{ height: "100%" }}
                                    data={gridData}
                                // onRowClick={(e) => handleClick(e)}
                                >
                                    <GridColumn field={"age"} title={"Age Group"} />
                                    <GridColumn field={"value050"} cell={handleValue050} title={"0-50 ms"} />
                                    <GridColumn field={"value5175"} cell={handleValu5175} title={"51-75 ms"} />
                                    <GridColumn field={"value76100"} cell={handleValue76100} title={"76-100 ms"} />
                                    <GridColumn field={"value100plus"} cell={handleValue100plus} title={"100+ ms"} />
                                </Grid>
                            </>
                        ) : (
                            <Row style={styles.row}>
                                <Col sm={12}
                                    style={{ padding: '12px 0px 15px 25px', height: "100%" }}>
                                    <span style={{ ...styles.titleh5 }}>No Data Available</span>
                                </Col>
                            </Row>)}
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
        patients: state.patient.patientList.patients,
        patientContext: state.patient.patientContext,
        advanceDirective: state.patient.advanceDirective,
        getHeartVariability: state.patient.getHeartVariability
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeartRateVariabilityComponent)