import {filterBy, orderBy} from "@progress/kendo-data-query";
import {Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {Button} from "primereact/button";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Checkbox} from "@progress/kendo-react-inputs";
import * as React from "react";
import {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {PatientAction} from "../../action/PatientAction";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import _, {isObject} from "lodash";

const MultiMedicalProblemSelect = (props) => {
    const {isActive, PatientActions,onChange, value, style} = props
    const [active, setActive] = useState(isActive)
    const [sort, setSort] = useState([
        {
            "field": "selected",
            "dir": "desc"
        },
        {
            "field": "displayName",
            "dir": "asc"
        }
    ]);
    const [filter, setFilter] = useState([]);
    const [data, setData] = useState([]);
    const [internalValue, setInternalValue] = useState(new Set());
    const [filterState, setFilterState] = useState(false)
    const [actionTitle, setActionTitle] = useState("Action");

    const patientContext = useSelector(state => state.patient.patientContext)
    const medicalProblems = useSelector(state => state.patient.medicalProblems)
    useEffect(() => {
        if (typeof props.patientRoleIDprop !== "undefined" || props.propReferralCheck === "Yes") {
            PatientActions.getMedicalProblems(props.patientRoleIDprop)
        } else if (patientContext?.patientRoleId) {
            PatientActions.getMedicalProblems(patientContext?.patientRoleId)
        }
    }, [patientContext, props.patientRoleIDprop])


    useEffect(() => {
        if (props?.fieldRequestFrom?.name === "medicalProblems" || props?.fieldRequestFrom?.name === "additional.RelatedDiagnoses") {
            setActionTitle("Select")
        } else {
            setActionTitle("Action")
        }
    }, [props?.fieldRequestFrom?.name])

    function handleChange(v) {
        // // console.log(`Setting value:`)
        // // console.log(v)

        if (onChange) {
            // // console.log(data)
            let newData = data.filter(d => v.has(d.id))
            // // console.log(newData)
            onChange([...medicalProblems.filter(mp => v.has(mp?.id))])
        }
        setInternalValue(v)
        // // console.log(value)
    }

    const ToggleSelected = (i) => {
        let id = i?.id
        if (id) {
            if (internalValue.has(id)) {
                let newData = new Set([...internalValue].filter(d => d !== id))
                // // console.log("toggle!")
                // // console.log(newData)
                handleChange(newData)
            } else {
                let newData = new Set([...internalValue, id])
                handleChange(newData)
            }
        }
    }


    useEffect(() => {
        // // console.log("Received new value!")
        // // console.log(value)
        if (_.isNil(value) || _.isEmpty(value)) {
            // // // console.log("No value setting empty set")
            setInternalValue(new Set())
        } else {
            let d
            if (isObject(value[0])) {
                d = new Set(value.map(p => p.id))
            } else {
                d = new Set(value)
            }
            // // // console.log(d)
            if (!_.eq(d, internalValue)) {
                // // console.log(d)
                setInternalValue(d)
            }
        }
    }, [value])


    // useEffect(() => {
    //     setData(medicalProblems)
    // }, [medicalProblems])

    useEffect(() => {
        if (props.patientRoleIDprop)
            setData(medicalProblems)
        else if (patientContext?.patientRoleId && typeof props.propReferralCheck === "undefined")
            setData(medicalProblems)
        else
            setData([])
    }, [medicalProblems])

    const AddMedicalProblem = () => {
        props.PopupActions.showWindow(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW);
    };

    const filterActive = (data) => {
        if (!active) {
            return data
        } else {
            return data.filter(r => {
                if (r.dictProblemStatusCode !== "FHIR_PROBLEM_INACTIVE") return r
            })
        }
    }


    const SelectProblemAction = (props) => {
        let selected = internalValue.has(props.dataItem?.id)
        return <td className="k-command-cell">
            <Button icon={"pi pi-check"}
                    severity={selected ? "success" : "secondary"}
                    text={!selected}
                    onClick={() => ToggleSelected(props.dataItem)}/>
        </td>;
    }

    return (
        <div style={style}>
            <Toolbar>
                <ToolbarItem>
                    <Button togglable={true}
                            icon={filterState ? "pi pi-filter-fill" : "pi pi-filter"}
                            text
                            onClick={() => setFilterState(!filterState)}/>
                </ToolbarItem>
                <ToolbarSpacer/>
                <ToolbarItem>
                    <Checkbox label={"Active only"} checked={active} onClick={() => {
                        setActive(!active)
                    }}/>
                </ToolbarItem>
                <ToolbarItem>
                    <Button icon="pi pi-plus" onClick={AddMedicalProblem} text/>
                </ToolbarItem>


            </Toolbar>
            <Grid
                style={{height: "calc(100% - 50px)"}}
                data={filterBy(orderBy(filterActive(data), sort), filter)}
                total={props.totalCount}
                filterable={filterState}
                filter={filter}
                onFilterChange={(e) => setFilter(e.filter)}
                sortable={true}
                sort={sort}
                onRowClick={(item) => {
                    props.PopupActions.setWindowState(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW, {problemContext: item.dataItem})
                    props.PopupActions.showWindow(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW)
                }}
                onSortChange={(e) => {
                    setSort(e.sort);
                }}>
                <GridColumn field="action" title={actionTitle} cell={SelectProblemAction} width={"80px"}
                            filterable={false}/>
                <GridColumn field="description" title="Condition" width={"300px"}/>
                <GridColumn field="dictProblemCategoryDisplayName" title="Category" width={"120px"}/>
                <GridColumn field="startDate" title="Onset" width={"120px"}/>
                <GridColumn field="dictProblemStatusDisplayName" title="Status"/>
            </Grid>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        patientContext: state.patient.patientContext,
        medicalProblems: state.patient.medicalProblems,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),

        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiMedicalProblemSelect);
