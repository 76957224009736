import * as React from "react";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {useForm} from "react-hook-form";
import {FacilityAction} from "../../action/FacilityAction";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "primereact/button";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import _ from "lodash";
import {
    Field,
    FieldCheckboxInput,
    FieldDictionaryDropDown,
    FieldRow,
    FieldSection,
    FieldTextInput
} from "../InputControls/FormFields";
import {PhixPermissions} from "../../config/GlobalConfig";
import ShowForPermissionComponent, {usePermission} from "../shared/ShowForPermissionComponent";
import {Dialog} from "primereact/dialog";
import {Col, Container} from "../../layout/GridLayout";

const EditFacilityWindow = (props) => {
    const dispatch = useDispatch()
    const showWindow = useSelector(state => state.popupWindow.showWindow)
    const facilityContext = useSelector(state => state.facility.facilityContext)
    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        formState: {errors},
    } = useForm({defaultValues: {active: true}});

    const editable = usePermission([PhixPermissions.AH_ADMIN_EDIT_FACILITY])

    const hideDialog = async () => {

        await FacilityAction.clearFacilityContext()(dispatch)
        await PopupWindowActions.hideWindow(PopupWindows.EDIT_FACILITY_WINDOW)(dispatch)
        reset({
            id: null,
            code: null,
            dictTaxonomyId: null,
            dictPosCodeId: null,
            active: null,
            facilityName: null,
            facilityDescription: null,
            facilityNpi: null,
            facilityEin: null,
            address1: null,
            address2: null,
            city: null,
            dictStateId: null,
            zipCode: null,
            country: null,
            isServiceLocation: null,
            isBillingLocation: null,
            dictCountryId: null
        });
    };

    const saveFacility = async (form) => {
        let payload = {}
        payload.code = form.code
        payload.description = form.facilityDescription
        payload.dictCountryId = form?.dictCountryId?.id ? form?.dictCountryId.id : form?.dictCountryId
        payload.displayName = form.facilityName
        payload.npi = form.facilityNpi
        payload.dictTaxonomyId = form?.dictTaxonomyId?.id ? form?.dictTaxonomyId.id : form?.dictTaxonomyId
        payload.isServiceLocation = form?.isServiceLocation
        payload.isBillingLocation = form?.isBillingLocation
        payload.federalEin = form?.facilityEin
        //payload.dict_pos_code_id = form?.dictPosCodeId?.id ? form?.dictPosCodeId.id : form?.dictPosCodeId
        payload.dictPosCodeId = form?.dictPosCodeId?.id ? form?.dictPosCodeId.id : form?.dictPosCodeId
        payload.active = form?.active
        payload.dictStateId = form?.dictStateId?.id ? form?.dictStateId?.id : form?.dictStateId
        payload.street1 = form?.address1
        payload.street2 = form?.address2
        payload.city = form?.city
        payload.zipCode = form?.zipCode
        if (form.id) {
            payload.id = form.id
            await FacilityAction.updateFacility(payload)(dispatch)
            await hideDialog()
        } else {
            // save
            await FacilityAction.createFacility(payload)(dispatch)
            await hideDialog()

        }
        // build the payload here and call either update or save.
    };

    React.useEffect(() => {
        if (!_.isEmpty(facilityContext)) {
            setValue("id", facilityContext.id)
            setValue("code", facilityContext.code)
            setValue("dictTaxonomyId", facilityContext?.dictTaxonomyId)
            setValue("dictPosCodeId", facilityContext?.dictPosCodeId)
            setValue("active", facilityContext?.active)
            setValue("facilityName", facilityContext?.displayName)
            setValue("facilityDescription", facilityContext?.description)
            setValue("facilityNpi", facilityContext?.npi)
            setValue("facilityEin", facilityContext?.federalEin)
            setValue("address1", facilityContext?.street1)
            setValue("address2", facilityContext?.street2)
            setValue("city", facilityContext?.city)
            setValue("dictStateId", facilityContext?.dictStateId)
            setValue("zipCode", facilityContext?.zipCode)
            setValue("country", facilityContext?.country)
            setValue("isServiceLocation", facilityContext?.isServiceLocation)
            setValue("isBillingLocation", facilityContext?.isBillingLocation)
            setValue("dictCountryId", facilityContext?.dictCountryId)


        }
    }, [facilityContext])

    return (<Dialog
        visible={showWindow[PopupWindows.EDIT_FACILITY_WINDOW]}
        header={!_.isEmpty(facilityContext) ? `Edit Facility` : "Add Facility"}
        maximizable
        style={{width: "80%"}}
        footer={<Container>
            <Col className={"flex-grow-1"}/>
            <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_FACILITY]}>
                <Button onClick={handleSubmit(saveFacility)} label={facilityContext?.id ? "Update" : "Save"}
                        severity={"success"}/>
            </ShowForPermissionComponent>
            <Button onClick={hideDialog} label={"Cancel"} severity={"danger"}/>
        </Container>}
        onHide={hideDialog}>
        <div className={"sub-title"}>Facility Information</div>
        <FieldSection disabled={!editable}>
            <FieldRow>
                <Field col={10} label={"Facility Name"} field={"facilityName"} error={errors}
                       control={control}
                       register={register} options={{required: "Facility Name is required."}}>
                    <FieldTextInput/>
                </Field>
                <Field col={2} label={"Active"} field={"active"} error={errors}
                       control={control}
                       register={register}>
                    <FieldCheckboxInput/>
                </Field>
            </FieldRow>

            <FieldRow>
                <Field col={4} label={"Facility Code"} field={"code"} error={errors} register={register}
                       control={control}>
                    <FieldTextInput/>
                </Field>
                <Field col={4} label={"Billing Location?"} field={"isBillingLocation"} error={errors}
                       control={control}
                       register={register}>
                    <FieldCheckboxInput/>
                </Field>
                <Field col={4} label={"Service Location?"} field={"isServiceLocation"} error={errors}
                       control={control}
                       register={register}>
                    <FieldCheckboxInput/>
                </Field>
            </FieldRow>
            <FieldRow>
                <Field col={12} label={"Facility Description"} field={"facilityDescription"} error={errors}
                       control={control}
                       register={register}>
                    <FieldTextInput/>
                </Field>
            </FieldRow>

            <FieldRow>
                <Field col={6} label={"Facility NPI"} field={"facilityNpi"} error={errors} register={register}
                       control={control}>
                    <FieldTextInput/>
                </Field>
                <Field col={6} label={"Facility EIN"} field={"facilityEin"} error={errors}
                       control={control}
                       register={register}>
                    <FieldTextInput/>
                </Field>
            </FieldRow>

            <FieldRow>
                <Field col={6} label={"Taxonomy"} field={"dictTaxonomyId"} error={errors} register={register}
                       control={control}>
                    <FieldDictionaryDropDown code={Dictionary.TAXONOMY} dataItem={facilityContext}/>
                </Field>
                <Field col={6} label={"Place of Service"} field={"dictPosCodeId"} error={errors}
                       control={control}
                       register={register} options={{required: "Place of service is required."}}>
                    <FieldDictionaryDropDown code={Dictionary.PLACE_OF_SERVICE} dataItem={facilityContext}/>
                </Field>
            </FieldRow>


        </FieldSection>
        <div className={"sub-title"}>Facility Address</div>


        <FieldSection disabled={!editable}>
            <FieldRow>
                <Field col={6} label={"Address 1"} field={"address1"} error={errors} register={register}
                       control={control}
                       options={{required: "Address is required."}}>
                    <FieldTextInput/>
                </Field>
                <Field col={6} label={"Address 2"} field={"address2"} error={errors} register={register}
                       control={control}>
                    <FieldTextInput/>
                </Field>
            </FieldRow>

            <FieldRow>
                <Field col={6} label={"City"} field={"city"} error={errors} register={register}
                       options={{required: "City is required."}} control={control}>
                    <FieldTextInput/>
                </Field>
                <Field col={6} label={"State"} field={"dictStateId"} error={errors} control={control}
                       register={register} options={{required: "State is required."}}>
                    <FieldDictionaryDropDown code={Dictionary.STATES} dataItem={facilityContext}/>
                </Field>
            </FieldRow>

            <FieldRow>
                <Field col={6} label={"Zip Code"} field={"zipCode"} error={errors} register={register}
                       options={{required: "Zip code is required."}} control={control}>
                    <FieldTextInput/>
                </Field>

                <Field col={6} label={"Country"} field={"dictCountryId"} error={errors} control={control}
                       register={register} options={{required: "Country is required."}}>
                    <FieldDictionaryDropDown code={Dictionary.COUNTRIES} dataItem={facilityContext}
                                             defaultItemCode={"US"}/>
                </Field>
            </FieldRow>
        </FieldSection>

    </Dialog>)
};


export default EditFacilityWindow
