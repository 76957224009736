import React, {useEffect, useRef, useState} from 'react';
import {createPortal} from "react-dom";
import {VERADIGM_URL} from "../../config/GlobalConfig";

export const VeradigmRenderer = (props) => {
    const [container, setContainer] = useState(null);
    const newWindow = useRef(window);

    useEffect(() => {
        const div = document.createElement("div");
        setContainer(div);
    }, []);

    useEffect(() => {
        if (container) {
            newWindow.current = window.open(
                "",
                "_blank"
            );
            newWindow.current?.document?.body.appendChild(container);
            const curWindow = newWindow.current;
            return () => curWindow;
        }
    }, [container]);

    return container && createPortal(props.children, container);
};

export const VeradigmHtmlTemplate = (props) => {
    return `
    <html>
    <script id="myScript">
    function display_c() {
        var refresh = 100; // Refresh rate in milli seconds
        mytime = setTimeout(document.forms.samlpost.submit(), refresh);
    }
    document.forms.samlpost.submit();
    </script>
    <body onload="document.forms.samlpost.submit();">
    <form id="samlpost" method="post" action="${VERADIGM_URL}" onload="document.forms.samlpost.submit();">
        <input type="hidden" name="SAMLResponse" value=${props.value} />    
    </form>
    </body>
    </html>
    `;
}

export const VeradigmSsoComponent = (props) => {
    props.setEprescribeFlag(false);
    const divRef = useRef();
    useEffect(() => {
        if (props.value != null) {
            const str = VeradigmHtmlTemplate(props);
            const fragment = document.createRange().createContextualFragment(str);
            divRef.current.append(fragment);
        }
    }, [props])

    return <div ref={divRef}/>;
}