import {Button} from "primereact/button";

export const EditCommandCell = (props) => {
    const {dataItem} = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem?.newItem || false;
    return inEdit ? (
        <td className="k-command-cell">
            <Button
                text
                icon={"pi pi-save"}
                primary={true}
                severity={"success"}
                onClick={() => isNewItem ? props.add(dataItem) : props.update(dataItem)}/>
            <Button
                text
                severity={"danger"}
                icon={isNewItem ? "pi pi-ban" : "pi pi-trash"}
                onClick={() =>
                    isNewItem ? props.cancel(dataItem) : props.remove(dataItem)
            }/>
        </td>
    ) : (
        <td className="k-command-cell">
            <Button icon={"pi pi-pencil"} onClick={() => props.edit(dataItem)}
                    severity={"success"}
                    text/>
            <Button
                text
                severity={"danger"}
                icon={"pi pi-trash"}
                onClick={() =>
                    props.remove(dataItem)
                }/>
        </td>
    );
};