import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    FACILITY_LIST_SUCCESS: "FACILITY_LIST_SUCCESS",
    FACILITY_LIST_ERROR: "FACILITY_LIST_ERROR",
    SET_FACILITY_CONTEXT: "SET_FACILITY_CONTEXT",
    CLEAR_FACILITY_CONTEXT: "CLEAR_FACILITY_CONTEXT",
    FACILITY_SET_CREATE_FACILITY_CONTEXT: "FACILITY_SET_CREATE_FACILITY_CONTEXT",
    FACILITY_UPDATE: "FACILITY_UPDATE",
    CLEAR_UPDATE: "CLEAR_UPDATE",
}

function setFacilityContext(facility) {
    return async dispatch =>
        dispatch({type: ActionTypes.SET_FACILITY_CONTEXT, payload: facility})

}

function clearFacilityContext() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_FACILITY_CONTEXT})
        dispatch({type: ActionTypes.CLEAR_UPDATE})
    }
}

function getFacilityList(limit, offset, sort, filter) {
    return WrapAuthentication('facility/get', {
            payload: "",
            limit: limit,
            offset: offset,
            sort: sort ? {sorts: sort} : null,
            filter: filter ? filter : {active: true},
        },
        ActionTypes.FACILITY_LIST_SUCCESS
    )
}

function createFacility(facility) {
    return WrapAuthentication('facility/create',
        {"payload": facility},
        ActionTypes.CLEAR_FACILITY_CONTEXT)
}

function updateFacility(facility) {
    return WrapAuthentication('facility/update',
        {"payload": facility},
        ActionTypes.CLEAR_FACILITY_CONTEXT)
}

// function updateUser(user) {
//     return WrapAuthentication("user/update",
//         {"payload": user},
//         ActionTypes.USER_UPDATE, ActionTypes.USER_LIST_ERROR)
// }

export const FacilityAction =
    {
        getFacilityList: getFacilityList,
        createFacility: createFacility,
        setFacilityContext: setFacilityContext,
        clearFacilityContext: clearFacilityContext,
        updateFacility: updateFacility,
    } 