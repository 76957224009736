import {Checkbox as PRCheckbox} from "primereact/checkbox";
import PropTypes from "prop-types";

export const Checkbox = (props) => {
    const {onChange, value, checked, labelStyle, style, label} = props

    const handleChange = (e) => {
        e.value = e.checked
        e.target.value = e.checked
        if (onChange)
            onChange(e)
    }
    let v = checked || value
    return (
        <div className="flex align-content-center align-items-center" style={{height: "100%"}}>
            <PRCheckbox onChange={handleChange} style={style || {}} checked={v}/>
            <label style={{color: "rgb(116, 132, 194)", ...labelStyle}} className="ml-2">
                {label}
            </label>
        </div>)
}
Checkbox.propTypes={
    value: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
}