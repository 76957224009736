import {PostApi, WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    DICTIONARY_LIST_SUCCESS: "DICTIONARY_LIST_SUCCESS",
    SET_DICTIONARY_CONTEXT: "SET_DICTIONARY_CONTEXT",
    CLEAR_DICTIONARY_CONTEXT: "CLEAR_DICTIONARY_CONTEXT",
    GET_DICTIONARY_BY_CODE: "GET_DICTIONARY_BY_CODE",
    GET_DICTIONARY_BY_ID: "GET_DICTIONARY_BY_ID",
    ADD_RELATIONSHIP_TO_DICTIONARY: "ADD_RELATIONSHIP_TO_DICTIONARY",
    DELETE_RELATIONSHIP_TO_DICTIONARY: "DELETE_RELATIONSHIP_TO_DICTIONARY",
    GET_RELATIONSHIP_TO_DICTIONARY: "GET_RELATIONSHIP_TO_DICTIONARY",
    CLEAR_RELATIONSHIP_TO_DICTIONARY: "CLEAR_RELATIONSHIP_TO_DICTIONARY",
}

function setDictionaryContext(dictionary) {
    return async dispatch =>
        dispatch({type: ActionTypes.SET_DICTIONARY_CONTEXT, payload: dictionary})

}

function clearDictionaryContext(dictionary) {
    return async dispatch =>
        dispatch({type: ActionTypes.CLEAR_DICTIONARY_CONTEXT, dictionary})
}

function getDictionaryList(dictionaryTypeId, limit, offset) {
    return WrapAuthentication('dictionary/all', {
            "payload": dictionaryTypeId, limit: limit, offset: offset
        },
        ActionTypes.DICTIONARY_LIST_SUCCESS
    )
}

function getDictionaryListFilter(dictionaryTypeCode, limit, offset, sort, filter) {
    return WrapAuthentication('dictionary/search', {
            payload: {
                allDict: true,
            },
            sort: sort ? {sorts: sort} : null,
            filter: filter ? {...filter, dictionaryTypeCode: dictionaryTypeCode} : {dictionaryTypeCode},
            limit: limit,
            offset: offset
        },
        ActionTypes.DICTIONARY_LIST_SUCCESS
    )
}


function searchDictionaryList(dictionaryTypeCode, limit, offset, sort, filter) {
    let l = limit ? limit : 500
    let o = offset ? offset : 0
    return WrapAuthentication('dictionary/search', {
            payload: {
                allDict: false,
                active: true
            },
            sort: sort ? {sorts: sort} : null,
            filter: filter ? {...filter, dictionaryTypeCode: dictionaryTypeCode} : {dictionaryTypeCode},
            limit: l,
            offset: o
        },
        ActionTypes.GET_DICTIONARY_BY_CODE, {code: dictionaryTypeCode}
    )
}

//searchTextIndex
function searchDictionaryListByIndex(dictionaryTypeCode, searchString, limit, offset) {
    let l = limit ? limit : 1000
    let o = offset ? offset : 0
    return WrapAuthentication('dictionary/search/index', {
            payload: {
                dictionaryTypeCode,
                searchString,
                active: true,

            },
            limit: l,
            offset: o
        },
        ActionTypes.GET_DICTIONARY_BY_CODE, {code: dictionaryTypeCode}
    )
}

function getDictionaryByCode(code, limit, offset) {
    let l = limit ? limit : 1000
    let o = offset ? offset : 0
    return WrapAuthentication('dictionary/code', {
            payload: {code: code},
            limit: l,
            offset: o,
        },
        ActionTypes.GET_DICTIONARY_BY_CODE,
        {code}
    )
}

function getDictionaryById(id) {
    return WrapAuthentication('dictionary/get', {
            payload: id
        },
        ActionTypes.GET_DICTIONARY_BY_ID
    )
}

function addRelationshipToDictionary(payload) {
    return WrapAuthentication('dictionary/relationship/add', {
            payload: payload
        },
        ActionTypes.ADD_RELATIONSHIP_TO_DICTIONARY
    )
}

function deleteRelationshipToDictionary(payload) {
    return WrapAuthentication('dictionary/relationship/delete', {
            payload: payload
        },
        ActionTypes.DELETE_RELATIONSHIP_TO_DICTIONARY
    )
}

function getRelationshipToDictionary(payload) {
    return WrapAuthentication('dictionary/relationship/all', {
            payload: payload
        },
        ActionTypes.GET_RELATIONSHIP_TO_DICTIONARY
    )
}


function clearRelationshipToDictionary() {
    return async dispatch =>
        dispatch({type: ActionTypes.CLEAR_RELATIONSHIP_TO_DICTIONARY})

}

async function getDictionaryByIdOrCode(dictionaryTypeCode, id, code) {

    return PostApi("dictionary/get/id/or/code", {
        payload: {
            id,
            code,
            dictionaryTypeCode
        }
    })
}


export const DictionaryDispatchAction =
    {
        searchDictionaryList: searchDictionaryList,
        searchDictionaryListByIndex: searchDictionaryListByIndex,
        getDictionaryList: getDictionaryList,
        getDictionaryListFilter: getDictionaryListFilter,
        setDictionaryContext: setDictionaryContext,
        clearDictionaryContext: clearDictionaryContext,
        getDictionaryByCode: getDictionaryByCode,
        getDictionaryById: getDictionaryById,
        addRelationshipToDictionary: addRelationshipToDictionary,
        deleteRelationshipToDictionary: deleteRelationshipToDictionary,
        getRelationshipToDictionary: getRelationshipToDictionary,
    }
export const DictionaryAction = {
    getDictionaryByIdOrCode: getDictionaryByIdOrCode
}