import * as React from "react";
import {PatientAction} from "../../action/PatientAction";
import {useDispatch, useSelector} from "react-redux";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {Button} from "primereact/button";
import {GridToolbar} from "@progress/kendo-react-grid";
import {
    Field,
    FieldDatePicker,
    FieldDictionaryDropDown,
    FieldInsurancePlanComboBox,
    FieldRow,
    FieldSection,
    FieldTextInput
} from "../InputControls/FormFields";
import {useForm} from "react-hook-form";
import {v4 as uuidv4} from "uuid";
import {Col, Row} from "../../layout/GridLayout";
import moment from "moment";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Fade} from "@progress/kendo-react-animation";

const _ = require('lodash');

const EditCommandCell = (props) => {
    const {data} = props;
    const inEdit = data[props.editField] || false;
    const isNewItem = data?.newItem || false;
    return inEdit ? (
        <Row>
            <Button icon={isNewItem ? "pi pi-save" : "pi pi-save"}
                    severity={"success"}
                    style={{marginRight :5}}
                    label={"Save"}
                    tooltip={"Save Insurance"}
                    onClick={(e) => isNewItem ? props.add(e) : props.update(e)}> </Button>
            <Button
                    label={isNewItem ? "Cancel" : "Delete Insurance"}
                    tooltip={isNewItem ? "Cancel" : "Delete Insurance"}
                    icon={isNewItem ? "pi pi-ban" : "pi pi-trash"}
                    onClick={(e) =>
                        isNewItem ? props.cancel(e, data) : props.remove(e)}> </Button>
        </Row>
    ) : (
        <div style={{marginTop: "5%"}}>
            <Button
                    icon={"pi pi-pencil"}
                    text
                    severity={"success"}
                    onClick={(e) => props.edit(e)}></Button>
            <Button
                    style={{backgroundColor: "#E06666"}}
                    icon={"delete"}
                    onClick={(e) =>
                        props.remove(e)
                    }> </Button>
        </div>
    );
};

const InsuranceDetailComponent = (props) => {
    const [relationship, setRelationship] = React.useState(null);
    const [dataItem, setDataItem] = React.useState({});
    const [disabled, setDisabled] = React.useState(true);
    const dispatch = useDispatch()
    const editField = "inEdit";
    const {
        handleSubmit,
        register,
        control,
        reset,
        setValue,
        getValues,
        watch,
        formState: {errors},
    } = useForm({defaultValues: {description: ""}});


    React.useEffect(() => {
        if (props.data?.id){
            setDataItem(props.data);
        }
    }, [props.data]);

    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'relationship') {
                setRelationship(value?.relationship)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    React.useEffect(() => {
        setValue("id", dataItem?.id)
        if (dataItem?.startDate)
            setValue("startDate", moment(dataItem?.startDate, "YYYY-MM-DD").toDate());

        setValue("planId", dataItem?.insurancePlanId);
        setValue("policyNumber", dataItem?.policyNumber);
        setValue("groupNumber", dataItem?.groupNumber);
        setValue("relationship", dataItem?.dictPersonRelationshipId);

        setValue("guarantorFirstName", dataItem?.guarantorFirstName);
        setValue("guarantorMiddleName", dataItem?.guarantorMiddleName);
        setValue("guarantorLastName", dataItem?.guarantorLastName);
        if (dataItem?.guarantorDateOfBirth)
            setValue("guarantorDateOfBirth", moment(dataItem?.guarantorDateOfBirth, "YYYY-MM-DD").toDate());
        setValue("guarantorGender", dataItem?.guarantorDictGenderId);
        setValue("guarantorSsn", dataItem?.guarantorSsn);
        setValue("guarantorStreet1", dataItem?.guarantorStreet1);
        setValue("guarantorStreet2", dataItem?.guarantorStreet2);
        setValue("guarantorCity", dataItem?.guarantorCity);
        setValue("guarantorState", dataItem?.guarantorDictStateId);
        setValue("guarantorZipCode", dataItem?.guarantorZipCode);
        setValue("dictCountryID", dataItem?.guarantorDictCountryId);
        setDisabled(!dataItem.inEdit);
    }, [dataItem]);

    React.useEffect(() => {
        if (relationship?.displayName === "Self") {
            setValue("guarantorFirstName", dataItem?.guarantorFirstName || props?.patientContext?.firstName);
            setValue("guarantorMiddleName", dataItem?.guarantorMiddleName || props?.patientContext?.middleName);
            setValue("guarantorLastName", dataItem?.guarantorLastName || props?.patientContext?.lastName);
            if (dataItem?.guarantorDateOfBirth) {
                setValue("guarantorDateOfBirth", moment(dataItem?.guarantorDateOfBirth, "YYYY-MM-DD").toDate());
            } else {
                setValue("guarantorDateOfBirth", moment(props?.patientContext?.dateOfBirth, "YYYY-MM-DD").toDate());
            }
            setValue("guarantorGender", dataItem?.guarantorDictGenderId || props?.patientContext?.dictGenderId);
            setValue("guarantorSsn", dataItem?.guarantorSsn);
            setValue("guarantorStreet1", dataItem?.guarantorStreet1 || props?.patientContext?.street1);
            setValue("guarantorStreet2", dataItem?.guarantorStreet2 || props?.patientContext?.street2);
            setValue("guarantorCity", dataItem?.guarantorCity || props?.patientContext?.city);
            setValue("guarantorState", dataItem?.guarantorDictStateId || props?.patientContext?.dictStateId);
            setValue("guarantorZipCode", dataItem?.guarantorZipCode || props?.patientContext?.zipCode);
        }
    }, [relationship]);


    const remove = (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete this insurance plan ?")) {
            PatientAction.deletePatientInsuranceInformation({id: dataItem?.id})(dispatch).then(() => {
                props.updatePatientInsuranceInformationAllPreferences();
                PatientAction.getPatientInsuranceInformation({patientRoleId: props.patientContext?.patientRoleId})(dispatch)
            });
        }
    }
    const createInsuranceForPatient = (form) => {
        let payload = {
            id: dataItem?.id,
            patientRoleId: props.patientContext?.patientRoleId,
            insurancePlanId: form?.planId?.id ? form.planId.id : form.planId,
            startDate: form?.startDate?.toISOString()?.split("T")[0],
            policyNumber: form?.policyNumber,
            groupNumber: form?.groupNumber,
            insurancePreference: props.insuranceType,
            dictRelationshipId: form?.relationship.id
        };
        let guarantor = {
            personId: dataItem?.guarantorPersonId,
            firstName: form?.guarantorFirstName,
            middleInitial: form?.guarantorMiddleName,
            lastName: form?.guarantorLastName,
            dateOfBirth: form?.guarantorDateOfBirth.toISOString().split("T")[0],
            dictGenderId: form?.guarantorGender?.id,
            ssn: form?.guarantorSsn,
            address1: form?.guarantorStreet1,
            address2: form?.guarantorStreet2,
            city: form?.guarantorCity,
            dictStateId: form?.guarantorState?.id,
            zipCode: form?.guarantorZipCode,
            dictCountryId: form.guarantorCountry?.id
        }
        payload.guarantor = guarantor;
        PatientAction.addPatientInsuranceInformation(payload)(dispatch);

    };
    const enterEdit = (e) => {
        e.preventDefault();
        setDataItem({...dataItem, inEdit: true});
    };

    const EditButtons = () => {
        return (
            <EditCommandCell
                data={dataItem}
                editField={editField}
                cancel={props.cancel}
                remove={remove}
                update={handleSubmit(createInsuranceForPatient)}
                add={handleSubmit(createInsuranceForPatient)}
                edit={enterEdit}
            />
        );
    };

    const AddressComp = () => {
        return <>
            <div className="sub-title">Guarantor</div>
            <FieldRow>
                <Field col={2}
                       label={"Relationship"} field={"relationship"} error={errors}
                       control={control}
                       register={register}
                       disabled={disabled}
                       options={{required: "Relationship is required"}}>
                    <FieldDictionaryDropDown
                        code={Dictionary.AH_PERSON_RELATIONSHIP}
                    />
                </Field>
                <Field col={4} label={"Guarantor First"} field={"guarantorFirstName"} error={errors}
                       register={register}
                       control={control}
                       placeholder={"First"}
                       disabled={disabled}
                       options={{required: "Subscriber first name is required"}}>
                    <FieldTextInput value={dataItem?.firstName}/>
                </Field>
                <Field col={2} label={"Guarantor Middle"} field={"guarantorMiddleName"} error={errors}
                       register={register}
                       control={control}
                       placeholder={"M"}
                       disabled={disabled}
                    // options={{required: "Subscriber Middle Initial is required"}}
                >
                    <FieldTextInput/>
                </Field>
                <Field col={4} label={"Guarantor Last"} field={"guarantorLastName"} error={errors}
                       register={register}
                       control={control}
                       placeholder={"Last"}
                       disabled={disabled}
                       options={{required: "Subscriber Last name is required"}}>
                    <FieldTextInput/>
                </Field>
            </FieldRow>
            <FieldRow>
                <Field label={"Date of Birth"} field={"guarantorDateOfBirth"} error={errors}
                       register={register}
                       control={control}
                       disabled={disabled}
                       options={{required: "Subscriber Date of Birth is required"}}>
                    <FieldDatePicker/>
                </Field>
                <Field label={"Gender"} field={"guarantorGender"} error={errors}
                       control={control}
                       register={register}
                       disabled={disabled}
                       options={{required: "Subscriber Gender is required"}}>
                    <FieldDictionaryDropDown
                        code={Dictionary.GENDER}
                    />
                </Field>
                <Field label={"SSN"} field={"guarantorSsn"} error={errors}
                       register={register}
                       control={control}
                       disabled={disabled}
                       options={{required: "Subscriber SSN is required"}}>
                    <FieldTextInput/>
                </Field>
            </FieldRow>
            <FieldRow>
                <Field label={"Address 1"} field={"guarantorStreet1"} error={errors}
                       register={register}
                       control={control}
                       disabled={disabled}
                       options={{required: "Subscriber Address 1 is required"}}>
                    <FieldTextInput/>
                </Field>
                <Field label={"Address 2"} field={"guarantorStreet2"} error={errors}
                       register={register}
                       control={control}
                       disabled={disabled}>
                    <FieldTextInput/>
                </Field>
                <Field label={"City"} field={"guarantorCity"} error={errors}
                       register={register}
                       control={control}
                       disabled={disabled}
                       options={{required: "Subscriber City is required"}}>
                    <FieldTextInput/>
                </Field>
                <Field label={"State"} field={"guarantorState"} error={errors}
                       register={register}
                       control={control}
                       disabled={disabled}
                       options={{required: "Subscriber State is required"}}>
                    <FieldDictionaryDropDown
                        code={Dictionary.STATES}
                    />
                </Field>
                <Field label={"Zip"} field={"guarantorZipCode"} error={errors}
                       register={register}
                       control={control}
                       disabled={disabled}
                       options={{required: "Subscriber Zip is required"}}>
                    <FieldTextInput/>
                </Field>
                <Field label={"Country"} field={"guarantorCountry"} error={errors}
                       register={register}
                       control={control}
                       disabled={disabled}
                       options={{required: "Subscriber Country is required"}}>
                    <FieldDictionaryDropDown
                        defaultItemCode={"US"}
                        code={Dictionary.COUNTRIES}
                    />
                </Field>
            </FieldRow>
        </>

    }
    const Arrow = (p) => {
        if (p.insuranceType === "Primary") {
            return <span style={{marginLeft: "90%"}}
                         onClick={() => props.primaryToSecondary()}
                         className="k-icon k-i-sort-desc-sm"/>;
        } else if (p.insuranceType === "Secondary") {
            return <>
            <span style={{marginLeft: "85%"}}
                  onClick={() => props.primaryToSecondary()}
                  className="k-icon k-i-sort-asc-sm"/>
                <span className="k-icon k-i-sort-desc-sm"
                      onClick={() => props.secondaryToTertiary()}/>
            </>
        } else {
            return <span style={{marginLeft: "90%"}}
                         onClick={() => props.secondaryToTertiary()}
                         className="k-icon k-i-sort-asc-sm"/>;
        }
    }

    const InsurancePlanDetails = () => {
        return <><FieldRow>
                   <Field label={"Insurance Plan"} field={"planId"}
                          value="Plan Name"
                          error={errors}
                          register={register}
                          disabled={!dataItem.inEdit}
                          control={control}
                          options={{required: "Insurance Plan is required."}}>
                       <FieldInsurancePlanComboBox
                           defaultItemId={dataItem.insurancePlanId}
                           disabled={!dataItem.inEdit}
                       />
                   </Field>
                   <Field label={"Effective Date"} field={"startDate"} error={errors}
                          register={register}
                          control={control}
                          disabled={disabled}>
                       <FieldDatePicker/>
                   </Field>

               </FieldRow>
               <FieldRow>
                   <Field label={"Policy Number"} field={"policyNumber"} error={errors}
                          register={register}
                          control={control}
                          disabled={disabled}
                          options={{required: "policy number is required"}}>
                       <FieldTextInput/>
                   </Field>
                   <Field label={"Group Number"} field={"groupNumber"} error={errors}
                          register={register}
                          control={control}
                          disabled={disabled}>
                       <FieldTextInput/>
                   </Field>
               </FieldRow></>
    }

    return <div>
        <Row className="sub-title">
            {props.insuranceType}
            <Arrow insuranceType={props.insuranceType}/>
        </Row>
        <FieldSection>
            <InsurancePlanDetails/>
            <AddressComp/>
            <Row>
                <Col>
                    <EditButtons/>
                </Col>
            </Row>
        </FieldSection>
    </div>
}

const InsuranceInformationComponent = (props) => {
    const patientContext = useSelector(s => s.patient.createPatientContext)
    const insuranceInformation = useSelector(state => state.patient.insuranceInformation)
    const insuranceTypes = ["Primary", "Secondary", "Tertiary"];
    const [data, setData] = React.useState([]);
    const [success, setSuccess] = React.useState(false);
    const dispatch = useDispatch()

    React.useEffect(() => {
        // InsuranceActions.getInsurancePlans(null, 1000, 0)(dispatch)
        PatientAction.getPatientInsuranceInformation({patientRoleId: patientContext?.patientRoleId})(dispatch)
    }, []);

    React.useEffect(() => {
        setData(_.sortBy(insuranceInformation, "insurancePreference"));
    }, [insuranceInformation]);

    React.useEffect(() => {
        if (success === true) {
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
        }
    }, [success]);

    const primaryToSecondary = () => {
        if (insuranceInformation.length > 1) {
            const pToS = {
                id: data[0]?.id,
                patientRoleId: patientContext?.patientRoleId,
                insurancePreference: insuranceTypes[1]
            }

            const sToP = {
                id: data[1]?.id,
                patientRoleId: patientContext?.patientRoleId,
                insurancePreference: insuranceTypes[0]
            }
            PatientAction.updatePatientInsuranceInformationPreference(pToS)(dispatch).then(() => {
                PatientAction.updatePatientInsuranceInformationPreference(sToP)(dispatch).then(() => {
                    PatientAction.getPatientInsuranceInformation({patientRoleId: patientContext?.patientRoleId})(dispatch)
                })
            })
        }
    }
    const secondaryToTertiary = () => {
        if (insuranceInformation.length > 2) {
            const pToS = {
                id: data[1]?.id,
                patientRoleId: patientContext?.patientRoleId,
                insurancePreference: insuranceTypes[2]
            }

            const sToP = {
                id: data[2]?.id,
                patientRoleId: patientContext?.patientRoleId,
                insurancePreference: insuranceTypes[1]
            }
            props.PatientActions.updatePatientInsuranceInformationPreference(pToS).then(() => {
                props.PatientActions.updatePatientInsuranceInformationPreference(sToP).then(() => {
                    props.PatientActions.getPatientInsuranceInformation({patientRoleId: patientContext?.patientRoleId})
                })
            })
        }
    }

    const updatePatientInsuranceInformationAllPreferences = () => {
        insuranceInformation.forEach((d, i) => {
            const pToS = {
                id: data[i]?.id,
                patientRoleId: patientContext?.patientRoleId,
                insurancePreference: insuranceTypes[i]
            }
            if (i === insuranceInformation.length - 1) {
                PatientAction.updatePatientInsuranceInformationPreference(pToS)(dispatch).then(() => {
                    PatientAction.getPatientInsuranceInformation({patientRoleId: patientContext?.patientRoleId})(dispatch)
                })
            } else {
                PatientAction.updatePatientInsuranceInformationPreference(pToS);
            }
        });
    }



    const cancel = (e, dataItem) => {
        e.preventDefault();
        const data1 = [...data];
        const originalItem = data.find((p) => p.id === dataItem.id);
        if (!originalItem.newItem) {

        } else {
            data1.splice(data1.indexOf(originalItem), 1);
            setData(data1);
        }
    }

    const addNew = () => {
        if (data.length < 3) {
            let newDataItem = {
                id: uuidv4(),
                newItem: true,
                inEdit: true,
                insuranceType: insuranceTypes[data.length]
            };
            setData([...data, newDataItem]);
        }
    };

    return (
        <div>
            <GridToolbar>
                <Button  icon={"pi pi-plus"} onClick={addNew} tooltip={"Add Insurance"} text
                        severity={"success"}/>
                <Button  icon={"pi pi-refresh"} tooltip={"Refresh Insurances"}
                        text
                        onClick={() => PatientAction.getPatientInsuranceInformation({patientRoleId: patientContext?.patientRoleId})(dispatch)}/>
            </GridToolbar>
            {
                data && data.map((d, i) => {
                    return <div>
                        <InsuranceDetailComponent
                            secondaryToTertiary={secondaryToTertiary}
                            primaryToSecondary={primaryToSecondary}
                            insuranceType={insuranceTypes[i]}
                            patientContext={patientContext}
                            PatientActions={props.PatientActions}
                            cancel={cancel}
                            data={d}
                            setSuccess={setSuccess}
                            updatePatientInsuranceInformationAllPreferences={updatePatientInsuranceInformationAllPreferences}
                            dictionaries={props.dictionaries}
                            insurancePlans={props.insurancePlans}/>
                    </div>
                })
            }

            <Row>
                <NotificationGroup style={{
                    top: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                }}>
                    <Fade>
                        {success && (
                            <Notification
                                type={{
                                    style: !success ? "success" : "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setSuccess(false)}
                            >
                                <span>{!success ? "" : "Required mandatory fields"}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>
            </Row>
        </div>
    )
}

export default InsuranceInformationComponent