const initialState = {
    errors: []
}

export const debugReducer = (state = initialState, action) => {
    switch (action.type) {
        case "AH_ERROR":

            return {...state, errors: [...state.errors, action.payload]}
        default:
            return state
    }

}