import {connect, useDispatch} from "react-redux";
import React, {useCallback} from "react";
import {bindActionCreators} from "redux";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import FormPreviewWindow from "../form/FormRendererComponent";
import {FormAction, getFormSchemaList, setEditFormContext} from "../../action/FormAction";
import {VitalAction} from "../../action/VitalAction";
import {EncounterAction} from "../../action/EncounterAction";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Row} from "../../layout/GridLayout";

const VitalWindow = (props) => {
    const code = 'ah_vitals';
    const dispatch = useDispatch();

    const [sort, setSort] = React.useState([{
        field: "displayName",
        dir: "asc",
    }]);

    React.useEffect(() => {
        getFormSchemaList(100, 0, sort, {});
    }, [])

    const hideDialog = async () => {
        props.PopupActions.hideWindow(PopupWindows.EDIT_VITAL)
        props.FormActions.clearFormContext();
        props.FormActions.setEditFormContext({});
        refreshForms();
    }
    const refreshForms = useCallback((sort, filter) =>
        dispatch(getFormSchemaList(100, 0, sort, filter)
        ), [dispatch]
    )

    function DisplayForm() {
        if (props?.formList?.payload) {
            const payload = props?.formList?.payload.filter(item => item.code == code)[0];
            setFormContext(payload)
        } else {
            refreshForms()
        }

        if (props.editFormContext && Object.keys(props.editFormContext).length > 0) {
            return <FormPreviewWindow
                show={false}
                editFormContext={props.editFormContext}/>
        } else {
            return null;
        }
    }

    const clearForm = () => {
        props.FormActions.clearFormContext();
        props.FormActions.setEditFormContext({});
        refreshForms()
        hideDialog();
    }
    const setFormContext = useCallback((form) => dispatch(setEditFormContext(form)), [dispatch])

    function saveEncounterForm(form, isDraft) {
        if (form) {
            let savePayload = {
                //schemaId: form.formSchemaId,
                formId: form?.id,
                encounterId: null,
                value: JSON.stringify(form.formData),
                patientRoleId: props.patientContext.patientRoleId,
                //dictFormStatus: props.dictionaries['AH_FORM_STATUS'].find(v => v.code == "Complete").id
            };
            props.VitalActions.saveVitalFormSchema(savePayload)
                .then(() => {
                    clearForm();
                    hideDialog();

                })
        }
    }


    return (
        <Dialog header={"Edit Vital"}
                maximizable
                visible={props.showWindow[PopupWindows.EDIT_VITAL]}
                footer={<Row className={"justify-content-end"}>
                    <Button severity={"success"}
                            label={"Save"}
                            onClick={() => {
                                if (props.editFormContext) {
                                    saveEncounterForm(props.editFormContext, false)
                                }
                            }}/>
                    <Button label={"Cancel"}
                            severity={"danger"}
                            onClick={clearForm}/>
                </Row>}
                onHide={hideDialog}>
            <div style={{height: "100%", overflowY: "auto"}}>
                <DisplayForm/>
            </div>

        </Dialog>
    );

}

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        editFormContext: state.form.editFormContext,
        formList: state.form.formList,
        patientContext: state.patient.patientContext,
        dictionaries: state.dictionary.dictionaries,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        FormActions: bindActionCreators(FormAction, dispatch),
        VitalActions: bindActionCreators(VitalAction, dispatch),
        EncounterAction: bindActionCreators(EncounterAction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VitalWindow);