import { connect } from "react-redux";
import { DrawerContent, TileLayout } from "@progress/kendo-react-layout";
import DictionaryListComponent from "./DictionaryListComponent";
import React from "react";
import { Box } from "@mui/material";


const dictionaryListTile = [
    {
        defaultPosition: {
            col: 1,
            colSpan: 4,
            rowSpan: 6,
        },
        body: <DictionaryListComponent />,
        header: "Dictionary List",
    }
];

const styles = {
    layout:
    {
        width: "95%",
        height: "calc(100vh - 90px)"
    }
}

function DictionaryPanelPage(props) {
    return <>
        <TileLayout
            style={styles.layout}
            ignoreDrag={(e) => {
                return !(e.target.classList.contains("k-card-title"));
            }} items={dictionaryListTile} />
    </>
}


function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryPanelPage)