import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "primereact/button"
import {Checkbox} from "../../../InputControls/Checkbox";
import {PatientAction} from "../../../../action/PatientAction";
import {PopupWindowActions, PopupWindows} from "../../../../action/PopupWindowAction";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import ShowForPermissionComponent from "../../../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../../../config/GlobalConfig";
import {PermissionsTool} from "../../../../util/PermissionsTool";
import {Col, Container, Row} from "../../../../layout/GridLayout";
import {DateTimeGridCell} from "../../../../util/DateTools";

const HospitalizationsComponent = (props) => {
    const dispatch = useDispatch()
    const patientContext = useSelector(state => state.patient.patientContext)
    const hospitalization = useSelector(state => state.patient.hospitalization)
    const hospitalizationID = useSelector(state => state.patient.hospitalizationID)
    const [active, setActive] = React.useState(true)
    const [hospitalizationPermission, setHospitalizationPermission] = React.useState({isEdit: false, isView: false})
    const initialSort = [
        {
            field: "condition",
            dir: "asc",
        },
    ];
    const [sort, setSort] = React.useState(initialSort);
    const [filter, setFilter] = React.useState([]);
    React.useEffect(() => {
        if (patientContext?.patientRoleId) PatientAction.getHospitalization(patientContext?.patientRoleId)(dispatch)
    }, [patientContext])
    React.useEffect(() => {
        if (patientContext?.patientRoleId) PatientAction.getHospitalization(patientContext?.patientRoleId)(dispatch)
    }, [hospitalizationID])

    React.useEffect(() => {
        setHospitalizationPermission(PermissionsTool({
            permissionInfo: props.permissionInfo,
            viewPermissions: ["AH_VIEW_HOSPITALIZATION"],
            editPermissions: ["AH_EDIT_HOSPITALIZATION"]
        }));
    }, [props?.permissionInfo]);

    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });

    const [filterState, setFilterState] = React.useState(false)

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const AddHospitalizationsSurgeries = () => {

        PatientAction.setHospitalContext(null)(dispatch);
        PopupWindowActions.showWindow(PopupWindows.ADD_HOSPITALIZATIONS_SURGERIES_WINDOW)(dispatch)
    };

    const filterActive = (data) => {
        if (!active) {
            return data
        } else {
            return data.filter(r => {
                if (r.dictClinicalStatusCode !== "FHIR_PROBLEM_INACTIVE") return r
            })
        }
    }

    const CommandCell = (props) => {
        const documentFileId = props?.dataItem?.fileReferenceId//'635015bdd8dbeb35dda48761'; //;
        return (
            <td>
                <Button onClick={(e) => displayDocument(documentFileId)}>Documents</Button>
            </td>
        )
    }
    const displayDocument = (documentFileId) => {
        props.PopupActions.showWindow(PopupWindows.SHOW_DOCUMENT_WINDOW);
        props.PopupActions.setWindowState(PopupWindows.SHOW_DOCUMENT_WINDOW, {documentFileId: documentFileId});
    }

    return (
        <Container style={{height: "100%", padding: 0, margin: 0}}>
            <Row gutter className={"justify-content-center"} style={{width: "100%", height: 50}}>
                <Col col={3} className={"flex-grow-0"}>
                    <Button togglable={true}
                            text
                            tooltip={"Toggle filtering"}
                            size={"small"}
                            onClick={() => setFilterState(!filterState)}
                            icon={(filterState) ? "pi pi-filter-fill" : "pi pi-filter"}/>

                </Col>
                <Col className={"flex-grow-5"}/>
                <Col col={2} className="flex align-items-center flex-grow-0">
                    <Checkbox inputId="viewAll"
                              onChange={(e) => setActive(!active)}
                              checked={active}/>
                    <label style={{fontSize: 14}} htmlFor="viewAll" className="ml-2">Active only</label>
                </Col>

                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_HOSPITALIZATION]}>
                    <Col className={"flex-grow-0"}>
                        <Button text="Image" onClick={AddHospitalizationsSurgeries}
                                tooltip={"Add hospitalization"}
                                size={"small"}
                                icon={"pi pi-plus"}/>
                    </Col>

                </ShowForPermissionComponent>

            </Row>
            <Row style={{width: "100%", height: "calc(100% - 50px)", padding: 0}}>
                    <Grid
                        {...dataState}
                        style={{height: "100%"}}
                        data={filterBy(orderBy(filterActive(hospitalization), sort), filter)}
                        pageSize={dataState.limit}
                        // style={{height: "calc(100vh - 100px)"}}
                        total={props.totalCount}
                        filterable={filterState}
                        filter={filter}
                        onFilterChange={(e) => setFilter(e.filter)}
                        sortable={true}
                        sort={sort}
                        skip={dataState.skip}
                        onRowClick={(item) => {
                            PatientAction.setHospitalContext(item.dataItem)(dispatch);
                            PopupWindowActions.showWindow(PopupWindows.ADD_HOSPITALIZATIONS_SURGERIES_WINDOW)(dispatch);

                        }}
                        pageable={{
                            buttonCount: 20,
                            info: true,
                            type: "numeric",
                            pageSizes: true,
                            previousNext: true,
                        }}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                        onDataStateChange={dataStateChange}>
                        <GridColumn field="admissionDate" title="Admission Date" cell={DateTimeGridCell}/>
                        <GridColumn field="description" title="Admitting Diagnosis"/>
                        <GridColumn field="displayName" title="Diagnostic Code"/>
                        {/* <GridColumn field="surgicalDisplayName" title="Surgical Procedure"/> */}
                        <GridColumn field="dischargeDate" title="Discharge Date" cell={DateTimeGridCell}/>
                        <GridColumn field="dictSourceOfInfoDisplayName" title="Source"/>
                        <GridColumn field="outcomeDisplayName" title="Outcome"/>
                        {/* <GridColumn cell={CommandCell} title="Actions"/> */}
                    </Grid>
            </Row>
        </Container>
    );
};


export default HospitalizationsComponent;
