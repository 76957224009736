import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {useForm} from "react-hook-form";
import {Field, FieldRow, FieldTextInput} from "./InputControls/FormFields";
import {Button} from "primereact/button";
import {SecuritySettingsAction} from "../action/SecuritySettingsAction";
import {Container} from "../layout/GridLayout";

const TelehealthConfigurationComponent = (props) => {

    const {
        register,
        handleSubmit,
        reset,
        control,
        getValues,
        setValue,
        watch,
        formState: {errors, isDirty, isSubmitting, touchedFields, submitCount},
    } = useForm({defaultValues: {active: true}});

    React.useEffect(() => {
        const payload = {tenantId: props.tenant.tenantId, userRoleId: props.loggedInUser.userRoleId};
        props.SecuritySettingsActions.getSecuritySetting(payload);
    }, []);

    const keyNames = {
        telehealthAddress: "telehealthAddress"
    }

    React.useEffect(() => {
        if (props?.securitySettings?.length > 0) {
            props.securitySettings.forEach(v => {
                if (keyNames[v.key] && v.value) {
                    setValue(v.key, v.value[keyNames[v.key]]);
                }
            })
        }
    }, [props?.securitySettings]);

    const saveTelehealth = (form) => {
        const data = {};
        const name = "telehealthAddress";
        data[keyNames[name]] = form?.telehealthAddress;
        const payload = {key: name, value: data};
        props.SecuritySettingsActions.insertOrUpdateSecuritySettings(payload);
    }

    return <Container>
        <FieldRow>
            <Field label={"Telehealth Address (External)"} field={"telehealthAddress"}
                   error={errors} register={register} control={control}>
                <FieldTextInput/>
            </Field>
            <Field label={" "} field={""} error={errors} register={register}>
                <Button text label={"Save"}
                        onClick={handleSubmit(saveTelehealth)}/>
            </Field>
        </FieldRow>
    </Container>

};


function mapStateToProps(state) {
    return {
        securitySettings: state.securitySettings.securitySettings,
        tenant: state.Configuration.tenant.tenant,
        loggedInUser: state.user.loggedinUser
    };
}

function mapDispatchToProps(dispatch) {
    return {
        SecuritySettingsActions: bindActionCreators(SecuritySettingsAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TelehealthConfigurationComponent)
