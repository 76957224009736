import {ActionTypes} from "../action/AuthAction";


export const initialState = {
    loading: false,
    authenticated: false,
    mfa: true,
    mfaSuccess: false,
    session: {permissions: []}
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_ERROR:
            return {...state, authError: action.payload};
        case ActionTypes.LOGIN_SUCCESS:
            let d = {...action.payload, permissions: action.payload.permissionInfo.map(p => p.permissionCode)}
            return {...state, isAuthenticated: true, session: d, authError: null};
        case ActionTypes.MFA_SUCCESS:
            return {...state, mfaSuccess: true};
        case ActionTypes.SET_MFA:
            return {...state, mfa: action.payload};
        case ActionTypes.CLEAR_ERROR:
            return {...state, authError: null}
        case ActionTypes.RESET_PASSWORD_SUCCESS:
            return {...state, resetPassword: action.payload, authError: null};
        case ActionTypes.RESET_PASSWORD_ERROR:
            return {...state, resetPassword: action.payload, authError: null};
        case ActionTypes.RESET_NEW_PASSWORD_SUCCESS:
            return {...state, resetNewPassword: action.payload, authError: null};
        case ActionTypes.RESET_NEW_PASSWORD_ERROR:
            return {...state, resetNewPassword: action.payload, authError: null};
        case ActionTypes.SET_LOGIN_SUCCESS:
            return {...state, authenticated: true};
        // case ActionTypes.LOGOUT:
        //     return initialState
        default:
            return state
    }

}

