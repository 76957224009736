import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Col, Container, Row} from "../../layout/GridLayout";
import {TabPanel, TabView} from "primereact/tabview";
import {PaginatedEndpoint} from "../../util/AuthWrapper";
import ExternalVendorDropdownList from "./ExternalVendorDropdownList";
import {Column} from "primereact/column";
import {ListBox} from "primereact/listbox";
import {Button} from "primereact/button";
import {Tooltip} from "primereact/tooltip";
import {Dialog} from "primereact/dialog";
import MultiMedicalProblemSelect from "./MultiMedicalProblemSelect";
import {Colors} from "../../config/GlobalConfig";
import PhixDataGrid from "../../typescript/component/data/PhixDataGrid";


const CurrentOrdersList = props => {
    const {onChange, values} = props
    const [showCodingWindow, setShowCodingWindow] = useState(false)
    const [orderProblem, setOrderProblem] = useState([])
    const orderTemplate = (option) => {
        function RemoveOrder(id) {

            let orders = values.filter(o => o.id !== id)
            if (onChange)
                onChange(orders)

        }


        return (


            <Row className={"flex flex-row align-items-center"}>
                <Tooltip target=".custom-target-icon"/>
                <Col className={"align-items-center"}
                     col={10}>
                    <div onClick={e => {
                        console.log(option)
                        setOrderProblem(option)
                        setShowCodingWindow(!showCodingWindow)
                    }}
                         className="w-full custom-target-icon white-space-nowrap text-overflow-ellipsis overflow-hidden align-items-center"
                         data-pr-tooltip={option?.problems?.length > 0 ? `${option.displayName} - Linked` : option.displayName}
                         data-pr-position="top">
                        {option?.problems ? (
                            <b style={{color: Colors.ORANGE}}><i>{option.displayName}</i></b>) : option.displayName}

                    </div>
                </Col>
                <Col col={1}>
                    <Button icon={"pi pi-trash"}
                            tooltip={"Remove this order"}
                            onClick={e => {
                                e.preventDefault()
                                RemoveOrder(option.id)
                            }}
                            severity={"danger"}
                            text/>
                </Col>
            </Row>
        );
    };

    function UpdateOrderProblems(option, problems) {
        let idx = values.findIndex(item => item.id === option.id)
        values[idx] = {...option, problems}
        console.log(values)
        console.log(idx)
        console.log(values[idx])
        if (onChange)
            onChange(values)
    }

    return (
        <div className={"w-full h-full"}>
            <Dialog header="Associate Problem with Order" visible={showCodingWindow} modal={false}
                    style={{width: '50vw'}}
                    footer={<Container className={"w-full justify-content-end"}>
                        <Col>
                            <Button
                                onClick={e => setShowCodingWindow(false)}
                                severity={"success"}
                            >Done</Button>
                        </Col>
                    </Container>}
                    onHide={() => setShowCodingWindow(false)}>
                <MultiMedicalProblemSelect
                    value={orderProblem?.problems}
                    onChange={v => UpdateOrderProblems(orderProblem, v)}/>
            </Dialog>

            <ListBox
                emptyMessage={"Add Orders to the right"}
                itemTemplate={orderTemplate}
                options={values}
                optionLabel="displayName"
                className="w-full h-full"/>
        </div>
    )
}

const MultiOrderCheckboxSelector = (props) => {
        const {
            onChange,
            style,
            ref,
            code,
            value,
            className,
            dictVendorTypeId = "c68cf7dd-d191-487e-80bc-f0df5f359bb6"
        } = props
        const [internalValue, setInternalValue] = useState([]);
        const [externalVendorId, setExternalVendorId] = useState(null);
        const [favorite, setFavorite] = useState(false)
        const [activeTab, setActiveTab] = useState(0)
        const getData = useCallback(PaginatedEndpoint("vendor/orderable/get", {
            externalVendorId,
            favorite
        }), [externalVendorId, favorite])

        const HandleSelect = useCallback((event) => {
            let item = event?.data
            let d = internalValue
            let f = d.findIndex(i => i.id === item.id)
            if (f < 0)
                setInternalValue([...d, item])

        }, [internalValue])

        useEffect(() => {
            if (onChange)
                onChange({value: internalValue})
        }, [internalValue])

        let columns = [
            {
                field: "code",
                headerName: "Code",
                flex: 0,
                // valueFormatter: (e) => FormatUtcDateTimeToLocalZoneStringWithFormat(e.encounterDate)
            },
            {
                field: "displayName",
                headerName: "Name",
                flex: 1,
                // valueFormatter: (e) => FormatUtcDateTimeToLocalZoneStringWithFormat(e.encounterDate)
            },]

        return (

            <Container gutter>
                <Col col={12}>
                    <div className="p-float-label" style={{width: "100%", fontSize: 12}}>
                        <ExternalVendorDropdownList style={{width: "100%"}}
                                                    dictVendorTypeId={dictVendorTypeId}
                                                    onChange={(e) => setExternalVendorId(e.value?.id)}/>
                        <label style={{width: "90%"}} htmlFor={"ExternalVendor"}>External Vendor</label>
                    </div>
                </Col>
                <Col col={12}>
                    <TabView activeIndex={activeTab} onTabChange={e => {
                        setActiveTab(e.index)
                        if (e.index === 1)
                            setFavorite(true)
                        else
                            setFavorite(false)
                    }}>
                        <TabPanel header={"Search All"}>
                            <Container gutter style={{width: "100%", height: 500}}>
                                <Col col={8} style={{height: 500}}>
                                    <PhixDataGrid
                                        // multiSelect
                                        columns={columns}
                                        onRowClick={HandleSelect}
                                        orderableFavorite
                                        // selectedValue={internalValue}
                                        // onSelectionChange={(e) => setInternalValue(e.value)}
                                        apiFunction={getData}
                                        rows={[]}>
                                        <Column field="code" header="Code"></Column>
                                        <Column field="displayName" header="Name"></Column>

                                    </PhixDataGrid>
                                </Col>
                                <Col col={4}>
                                    <CurrentOrdersList values={internalValue}
                                                       onChange={value => setInternalValue(value)}/>
                                </Col>

                            </Container>
                        </TabPanel>
                        <TabPanel header={"Favorites"}>
                            <Container gutter style={{width: "100%", height: 500}}>
                                <Col col={8} style={{height: 500}}>
                                    <PhixDataGrid
                                        // multiSelect
                                        columns={columns}
                                        onRowClick={HandleSelect}
                                        orderableFavorite
                                        // selectedValue={internalValue}
                                        // onSelectionChange={(e) => setInternalValue(e.value)}
                                        apiFunction={getData}
                                        rows={[]}>
                                        <Column field="code" header="Code"></Column>
                                        <Column field="displayName" header="Name"></Column>

                                    </PhixDataGrid>
                                </Col>
                                <Col col={4}>
                                    <CurrentOrdersList values={internalValue}
                                                       onChange={value => setInternalValue(value)}/>
                                </Col>

                            </Container>
                        </TabPanel>

                    </TabView>

                </Col>
            </Container>
        );
    }
;

export default MultiOrderCheckboxSelector
