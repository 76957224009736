import React, {useEffect} from 'react';
import TileHeader from '../../layout/TileHeader';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import moment from 'moment';
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {PatientAction} from "../../action/PatientAction";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {PhixPermissions, ObservationThreshold} from "../../config/GlobalConfig";
import {ObservationsThresholdAction} from "../../action/ObservationsThresholdAction";


const BloodPressureComponent = (props) => {
    const {PatientActions, bloodPressure, user, PatientContext} = props;
    const [data, setData] = React.useState([]);
    const [observationData, setObservationData] = React.useState({
                        Systolic:{
                            High:{},
                            Normal:{},
                            Elevated:{}
                        },
                        Diastolic:{
                            High:{},
                            Normal:{},
                            Elevated:{}
                        }
    });


    useEffect(() => {
        const payload = {
            patientRoleId: PatientContext?.patientRoleId,
            monthPrevious: 6,
            codes: ["AH_DIASTOLIC_BP", "AH_SYSTOLIC_BP"],
            startDate: moment().add(1, "days").format("YYYY-MM-DD")
        }
        PatientActions.getPatientBloodPressure(payload);
    }, [])

    useEffect(() => {
        if(props?.observationThresholdSystolic && props?.observationThresholdDiastolic){
            console.log("props?.observationThresholdSystolic ",props?.observationThresholdSystolic," props?.observationThresholdDiastolic ",props?.observationThresholdDiastolic)
            const val = props.observationThresholdSystolic;
            for(let i = 0; i < val.length; i++){
                if(val[i].description=="High"){
                    observationData.Systolic.High.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Systolic.High.value = val[i].value
                    observationData.Systolic.High.rangeText = val[i].rangeText
                    observationData.Systolic.High.rangeColor = val[i].rangeColor
                } else if(val[i].description=="Normal"){
                    observationData.Systolic.Normal.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Systolic.Normal.value = val[i].value
                    observationData.Systolic.Normal.rangeText = val[i].rangeText
                    observationData.Systolic.Normal.rangeColor = val[i].rangeColor
                } else if(val[i].description=="Elevated"){
                    observationData.Systolic.Elevated.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Systolic.Elevated.value = val[i].value
                    observationData.Systolic.Elevated.rangeText = val[i].rangeText
                    observationData.Systolic.Elevated.rangeColor = val[i].rangeColor
                }
            }

            const valDia = props.observationThresholdDiastolic;
            for(let i = 0; i < valDia.length; i++){
                if(valDia[i].description=="High"){
                    observationData.Diastolic.High.dictOperatorDisplayName = valDia[i].dictOperatorDisplayName
                    observationData.Diastolic.High.value = valDia[i].value
                    observationData.Diastolic.High.rangeText = valDia[i].rangeText
                    observationData.Diastolic.High.rangeColor = valDia[i].rangeColor
                } else if(valDia[i].description=="Normal"){
                    observationData.Diastolic.Normal.dictOperatorDisplayName = valDia[i].dictOperatorDisplayName
                    observationData.Diastolic.Normal.value = valDia[i].value
                    observationData.Diastolic.Normal.rangeText = valDia[i].rangeText
                    observationData.Diastolic.Normal.rangeColor = valDia[i].rangeColor
                } else if(valDia[i].description=="Elevated"){
                    observationData.Diastolic.Elevated.dictOperatorDisplayName = valDia[i].dictOperatorDisplayName
                    observationData.Diastolic.Elevated.value = valDia[i].value
                    observationData.Diastolic.Elevated.rangeText = valDia[i].rangeText
                    observationData.Diastolic.Elevated.rangeColor = valDia[i].rangeColor
                }
            }
        }
    }, [props?.observationThresholdSystolic, props?.observationThresholdDiastolic])

    useEffect(() => {
        if (bloodPressure?.length > 0) {
            let acc = {}
            let d = bloodPressure.reduce((acc, item) => {
                if (acc.hasOwnProperty(item.effectiveStartDate)) {
                    if (item.dictObservationTypeCode === 'AH_DIASTOLIC_BP') {
                        //acc[item.effectiveStartDate].Diastolic = `${item.value} mmHg`
                        acc[item.effectiveStartDate].Diastolic = `${item.value} mmHg`
                    }
                    if (item.dictObservationTypeCode === 'AH_SYSTOLIC_BP') {
                        //acc[item.effectiveStartDate].Systolic = `${item.value} mmHg`
                        acc[item.effectiveStartDate].Systolic = `${item.value} mmHg`
                    }
                } else {
                    acc[item.effectiveStartDate] = {Date: moment(item.effectiveStartDate).format("ll")}
                    if (item.dictObservationTypeCode === 'AH_DIASTOLIC_BP') {
                        //acc[item.effectiveStartDate].Diastolic = `${item.value} mmHg`
                        acc[item.effectiveStartDate].Diastolic = `${item.value} mmHg`
                    }
                    if (item.dictObservationTypeCode === 'AH_SYSTOLIC_BP') {
                        //acc[item.effectiveStartDate].Systolic = `${item.value} mmHg`
                        acc[item.effectiveStartDate].Systolic = `${item.value} mmHg`
                    }
                }
                return acc
            }, acc)
            let data = Object.keys(d)
                .map(function (key) {
                    return d[key];
                });
            let filter = data.sort(function (left, right) {
                return moment.utc(right.Date).diff(moment.utc(left.Date))
            })
            setData(filter)
        } else
            setData([])


    }, [bloodPressure])

    const rowRender = (trElement, props) => {
        /*const normal = {
            backgroundColor: "#6AA84F",
            fontSize: "9pt"
        };
        const elevated = {
            backgroundColor: "#F1C232",
            fontSize: "9pt"
        };
        const high = {
            backgroundColor: "#E06666",
            fontSize: "9pt"
        }
        let trProps;
        if ((parseInt(props.dataItem.Systolic.split(" mmHg").join('')) >= 120 && parseInt(props.dataItem.Systolic.split(" mmHg").join('')) <= 129) && (parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) < 80)) {
            trProps = {style: elevated, className: 'bloodPresRow'}
            props.dataItem.Date = props.dataItem.Date.includes("Elevated") ? props.dataItem.Date : `${props.dataItem.Date}  Elevated`;
        } else if (parseInt(props.dataItem.Systolic.split(" mmHg").join('')) > 129 || parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) > 79) {
            trProps = {style: high, className: 'bloodPresRow'}
            props.dataItem.Date = props.dataItem.Date.includes("High") ? props.dataItem.Date : `${props.dataItem.Date} High`;
        } else if (parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) < 80 && parseInt(props.dataItem.Systolic.split(" mmHg").join('')) < 120) {
            trProps = {style: normal, className: 'bloodPresRow'}
            props.dataItem.Date = props.dataItem.Date.includes("Normal") ? props.dataItem.Date : `${props.dataItem.Date}  Normal`;
        }*/

        const normal = {
            backgroundColor: observationData.Systolic.Normal.rangeColor,
            fontSize: "9pt"
        };
        const elevated = {
            backgroundColor: observationData.Systolic.Elevated.rangeColor,
            fontSize: "9pt"
        };
        const high = {
            backgroundColor: observationData.Systolic.High.rangeColor,
            fontSize: "9pt"
        }

        let trProps;
        let status ="true";
        if(status =="true" && ((observationData.Systolic?.High && observationData.Systolic?.High?.value?.value) || (observationData.Diastolic?.High && observationData.Diastolic?.High?.value?.value))){
            if(
                (observationData.Systolic.High.dictOperatorDisplayName==">=" && observationData.Diastolic.High.dictOperatorDisplayName==">=") && 
                (parseInt(props.dataItem.Systolic.split(" mmHg").join('')) >= parseInt(observationData.Systolic.High?.value?.value) || 
                parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) >= parseInt(observationData.Diastolic.High?.value?.value))
			){
                trProps = {style: high, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("High") ? props.dataItem.Date : `${props.dataItem.Date} High`;
	            status ="false";
            } else if(
				(observationData.Systolic.High.dictOperatorDisplayName=="<=" && observationData.Diastolic.High.dictOperatorDisplayName=="<=")
				&& 
				(parseInt(props.dataItem.Systolic.split(" mmHg").join('')) >= parseInt(observationData.Systolic.High?.value?.value) || 
                parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) <= parseInt(observationData.Diastolic.High?.value?.value))
			){
	            trProps = {style: high, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("High") ? props.dataItem.Date : `${props.dataItem.Date} High`;
                status ="false";
            } else if((observationData.Systolic.High.dictOperatorDisplayName==">" && 			observationData.Diastolic.High.dictOperatorDisplayName==">") && 
                        (parseInt(props.dataItem.Systolic.split(" mmHg").join('')) > parseInt(observationData.Systolic.High?.value?.value) || parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) > parseInt(observationData.Diastolic.High?.value?.value))
			){
	            trProps = {style: high, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("High") ? props.dataItem.Date : `${props.dataItem.Date} High`;
                status ="false";
            } else if((observationData.Systolic.High.dictOperatorDisplayName=="<" && observationData.Diastolic.High.dictOperatorDisplayName=="<") && (parseInt(props.dataItem.Systolic.split(" mmHg").join('')) < parseInt(observationData.Systolic.High?.value?.value) || parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) < parseInt(observationData.Diastolic.High?.value?.value))
			){
	            trProps = {style: high, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("High") ? props.dataItem.Date : `${props.dataItem.Date} High`;
                status ="false";
            } else if((observationData.Systolic.High.dictOperatorDisplayName=="==" && observationData.Diastolic.High.dictOperatorDisplayName=="==") && (parseInt(props.dataItem.Systolic.split(" mmHg").join('')) == parseInt(observationData.Systolic.High?.value?.value) || parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) == parseInt(observationData.Diastolic.High?.value?.value))
			){
	            trProps = {style: high, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("High") ? props.dataItem.Date : `${props.dataItem.Date} High`;
                status ="false";
            }
        } else if(status =="true" && ((observationData.Systolic?.High && observationData.Systolic.High?.value?.min) || 	(observationData.Diastolic.High?.value?.min && observationData.Diastolic.High?.value?.max))){
            if((parseInt(props.dataItem.Systolic.split(" mmHg").join('')) > parseInt(observationData.Systolic.High?.value?.min) && parseInt(props.dataItem.Systolic.split(" mmHg").join('')) < parseInt(observationData.Systolic.High?.value?.max)) || (parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) > parseInt(observationData.Diastolic.High?.value?.min) && parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) < parseInt(observationData.Diastolic.High?.value?.max))){
	            trProps = {style: high, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("High") ? props.dataItem.Date : `${props.dataItem.Date} High`;
	            status ="false";
            }     
        }

        if(status =="true" && observationData.Systolic?.Elevated?.value?.value){
            if(observationData.Systolic.Elevated.dictOperatorDisplayName==">=" && parseInt(props.dataItem.Systolic.split(" mmHg").join('')) >= parseInt(observationData.Systolic.Elevated?.value?.value)){
                trProps = {style: elevated, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Elevated") ? props.dataItem.Date : `${props.dataItem.Date}  Elevated`;
                status ="false";
            } else if(observationData.Systolic.Elevated.dictOperatorDisplayName=="<=" && parseInt(props.dataItem.Systolic.split(" mmHg").join('')) <= parseInt(observationData.Systolic.Elevated?.value?.value)){
	            trProps = {style: elevated, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Elevated") ? props.dataItem.Date : `${props.dataItem.Date}  Elevated`;
                status ="false";
            } else if(observationData.Systolic.Elevated.dictOperatorDisplayName==">" && parseInt(props.dataItem.Systolic.split(" mmHg").join('')) > parseInt(observationData.Systolic.Elevated?.value.value)){
	            trProps = {style: elevated, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Elevated") ? props.dataItem.Date : `${props.dataItem.Date}  Elevated`;
                status ="false";
            } else if(observationData.Systolic.Elevated.dictOperatorDisplayName=="<" && parseInt(props.dataItem.Systolic.split(" mmHg").join('')) < parseInt(observationData.Systolic.Elevated.value.value)){
	            trProps = {style: elevated, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Elevated") ? props.dataItem.Date : `${props.dataItem.Date}  Elevated`;
                status ="false";
            } else if(observationData.Systolic.Elevated.dictOperatorDisplayName=="==" && parseInt(props.dataItem.Systolic.split(" mmHg").join('')) == parseInt(observationData.Systolic.Elevated.value.value)){
	            trProps = {style: elevated, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Elevated") ? props.dataItem.Date : `${props.dataItem.Date}  Elevated`;
                status ="false";
            }
        } else if(status =="true" && observationData.Systolic?.Elevated?.value?.max && observationData.Systolic.Elevated?.value?.min){
            if(parseInt(props.dataItem.Systolic.split(" mmHg").join('')) > parseInt(observationData.Systolic.Elevated?.value?.min) && parseInt(props.dataItem.Systolic.split(" mmHg").join('')) < parseInt(observationData.Systolic?.Elevated?.value?.max)){
	            trProps = {style: elevated, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Elevated") ? props.dataItem.Date : `${props.dataItem.Date}  Elevated`;
	            status ="false";
            }     
        }

        if(status =="true" && ((observationData.Systolic?.Normal && observationData.Systolic?.Normal?.value?.value) || (observationData.Diastolic?.Normal && observationData.Diastolic?.Normal?.value?.value))){
            if((observationData.Systolic.Normal.dictOperatorDisplayName==">=" && observationData.Diastolic.Normal.dictOperatorDisplayName==">=") && (parseInt(props.dataItem.Systolic.split(" mmHg").join('')) >= parseInt(observationData.Systolic.Normal?.value?.value) || parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) >= parseInt(observationData.Diastolic.Normal?.value?.value))){
                trProps = {style: normal, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Normal") ? props.dataItem.Date : `${props.dataItem.Date}  Normal`;
                status ="false";
            } else if((observationData.Systolic.Normal.dictOperatorDisplayName=="<=" && observationData.Diastolic.Normal.dictOperatorDisplayName=="<=") && (parseInt(props.dataItem.Systolic.split(" mmHg").join('')) >= parseInt(observationData.Systolic.Normal?.value?.value) || parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) <= parseInt(observationData.Diastolic.Normal?.value?.value))){
	            trProps = {style: normal, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Normal") ? props.dataItem.Date : `${props.dataItem.Date}  Normal`;
                status ="false";
            } else if((observationData.Systolic.Normal.dictOperatorDisplayName==">" && observationData.Diastolic.Normal.dictOperatorDisplayName==">") && (parseInt(props.dataItem.Systolic.split(" mmHg").join('')) > parseInt(observationData.Systolic.Normal?.value?.value) || parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) > parseInt(observationData.Diastolic.Normal?.value?.value))){
	            trProps = {style: normal, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Normal") ? props.dataItem.Date : `${props.dataItem.Date}  Normal`;
                status ="false";
            } else if((observationData.Systolic.Normal.dictOperatorDisplayName=="<" && observationData.Diastolic.Normal.dictOperatorDisplayName=="<") && (parseInt(props.dataItem.Systolic.split(" mmHg").join('')) < parseInt(observationData.Systolic.Normal?.value?.value) || parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) < parseInt(observationData.Diastolic.Normal?.value?.value))){
	            trProps = {style: normal, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Normal") ? props.dataItem.Date : `${props.dataItem.Date}  Normal`;
                status ="false";
            } else if((observationData.Systolic.Normal.dictOperatorDisplayName=="==" && observationData.Diastolic.Normal.dictOperatorDisplayName=="==") && (parseInt(props.dataItem.Systolic.split(" mmHg").join('')) == parseInt(observationData.Systolic.Normal?.value?.value) || parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) == parseInt(observationData.Diastolic.Normal?.value?.value))){
	            trProps = {style: normal, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Normal") ? props.dataItem.Date : `${props.dataItem.Date}  Normal`;
                status ="false";
            }
        } else if(status =="true" && ((observationData.Systolic?.Normal && observationData.Systolic.Normal?.value?.min) || (observationData.Diastolic.Normal?.value?.min && observationData.Diastolic.Normal?.value?.max))){
            if((parseInt(props.dataItem.Systolic.split(" mmHg").join('')) > parseInt(observationData.Systolic.Normal?.value?.min) && parseInt(props.dataItem.Systolic.split(" mmHg").join('')) < parseInt(observationData.Systolic.Normal?.value?.max)) || (parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) > parseInt(observationData.Diastolic.Normal?.value?.min) && parseInt(props.dataItem.Diastolic.split(" mmHg").join('')) < parseInt(observationData.Diastolic.Normal?.value?.max))){
	            trProps = {style: normal, className: 'bloodPresRow'}
				props.dataItem.Date = props.dataItem.Date.includes("Normal") ? props.dataItem.Date : `${props.dataItem.Date}  Normal`;
	            status ="false";
            }     
        } 

        return React.cloneElement(
            trElement,
            {...trProps},
            trElement.props.children
        );
    };

    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                <TileContainer>
                    <TileHeader title="Blood Pressure" image={"../images/blood.png"}/>
                    <TileBody>
                        <Grid
                            style={{height: "100%"}}
                            data={data}
                            rowRender={rowRender}
                            className="gridLayout bloodPresHeader">
                            <GridColumn field={"Date"}/>
                            <GridColumn field={"Systolic"}/>
                            <GridColumn field={"Diastolic"}/>
                        </Grid>
                    </TileBody>

                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        PatientContext: state.patient.patientContext,
        bloodPressure: state.patient.bloodPressure,
        user: state.user,
        observationThresholdSystolic: state.observationThreshold.componentThresholds?.Systolic,
        observationThresholdDiastolic: state.observationThreshold.componentThresholds?.Diastolic,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        ObservationsThresholdActions: bindActionCreators(ObservationsThresholdAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BloodPressureComponent);