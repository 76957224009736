import { Action } from "@progress/kendo-react-dateinputs/dist/npm/calendar/models";
import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    GET_SOCIAL_HISTORY_DATA: "GET_SOCIAL_HISTORY_DATA",
}

function getFamilyHistoryData(patientRoleId) {
    return WrapAuthentication("form/get/code/latest",{
        payload:{
            patientRoleId: patientRoleId,
            code:'ah_family_history'
        }
    }, ActionTypes.GET_SOCIAL_HISTORY_DATA)
}



export const FamilyHistoryActions = {
    getFamilyHistoryData:getFamilyHistoryData,
}