export const ActionTypes = {
    SET_EXPANDABLE_COMPONENT: "SET_EXPANDABLE_COMPONENT",
    TOGGLE_EXPANDABLE_VISIBLE: "TOGGLE_EXPANDABLE_VISIBLE",
    SET_EXPANDABLE_COMPONENT_PROPS: "SET_EXPANDABLE_COMPONENT_PROPS",
}

function setExpandableComponent(c) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_EXPANDABLE_COMPONENT, payload: c})
    }

}

function toggleExpandableComponent(props) {
    return async dispatch =>
        dispatch({type: ActionTypes.TOGGLE_EXPANDABLE_VISIBLE, payload: props})
}

function setExpandedComponentProps(props) {
    return async dispatch =>
        dispatch({type: ActionTypes.SET_EXPANDABLE_COMPONENT_PROPS, payload: props})
}

export const ExpandableAction =
    {
        setExpandableComponent: setExpandableComponent,
        toggleExpandableComponent: toggleExpandableComponent,
        setExpandedComponentProps: setExpandedComponentProps,
    }