import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {Responsive, WidthProvider} from "react-grid-layout";
import {ParseComponent} from "../components/shared/ComponentRegistry";

const ResponsiveGridLayout = WidthProvider(Responsive);


function CohortAnalysisPage(props) {
    const screenLayout = [
        {i: "CohortAnalysisComponent", x: 0, y: 0, w: 12, h: 7, minW: 4, isResizable: true},
        // {i: "ActivityStreamComponent", x: 8, y: 0, w: 4, h: 7, minW: 4, params: {channelType: "userRole"}},
    ]
    return <>
        <ResponsiveGridLayout
            style={{height: "calc(100vh - 60px)", width: "100%", overflowY: "auto", overflowX: "hidden"}}
            resizeHandles={["se"]}
            draggableHandle={".ah-draggable-header"}
            // className="layout"
            layouts={{lg: screenLayout}}
            breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
            cols={{lg: 12, md: 12, sm: 4, xs: 4, xxs: 4}}>
            {screenLayout.map(c => ParseComponent(c))}
        </ResponsiveGridLayout>
    </>
}


CohortAnalysisPage.propTypes = {
    // authActions: PropTypes.object,
    // layoutActions: PropTypes.object,
}

export default CohortAnalysisPage