import PropTypes from "prop-types";
import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {filterBy} from "@progress/kendo-data-query";
import {PermissionAction} from "../../action/PermissionAction";
import {isObject} from "lodash";


const PermissionGroupDropDownList = (props) => {

    const {setDefaultValue, defaultItemCode, PermissionActions, dataItem, required, permissionGroups, value} = props
    const [data, setData] = React.useState(permissionGroups)
    const idField = props.idField ? props.idField : "id"


    const dropdownRef = React.createRef()
    const [internalValue, setInternalValue] = React.useState(dataItem && data ? data.find((c) => c.id === dataItem[idField]) : null)

    const filterData = (filter) => {
        return filterBy(permissionGroups.slice(), filter);
    };
    const filterChange = (event) => {
        setData(filterData(event.filter));
    };
    const onSetDefaultValue = (value) => {
        if (props.onChange) {
            const data = {
                target: dropdownRef.current,
                value: value,
            }
            props.onChange(data);
        }
    };

    const handleChange = (e) => {
        setInternalValue(e.target.value)
        if (props.onChange) {
            props.onChange(e)
        }
    }
    const useMountEffect = (fun) => React.useEffect(fun, [])

    React.useEffect(() => {
        setData(permissionGroups);
    }, [permissionGroups])

    React.useEffect(() => {
        // console.log(`Permission Group - ${value}`)
        if (isObject(value)) {
            setInternalValue(value)
        } else {
            let v = data ? data.find((c) => c.id === value) : null
            // console.log(v)
            setInternalValue(data ? data.find((c) => c.id === value) : null)
        }
        if (defaultItemCode && value === null) {
            let value = data ? data.find((c) => c.code === defaultItemCode) : null
            onSetDefaultValue(value)
            setInternalValue(value)
        }
    }, [data, value])

    useMountEffect(() => {
        PermissionActions.getPermissionGroups()
    })


    let DefaultVal = {displayName: "Please Select ..."};


    if (setDefaultValue) {
        DefaultVal = setDefaultValue ? setDefaultValue : null;
        //DefaultVal = data ? data.find((c) => c.displayName === setDefaultValue) : null;
    }


    return (<>
        <DropDownList style={props.style}
                      ref={dropdownRef}
                      required={required}
                      disabled={props.disabled}
                      data={data}
                      defaultItem={DefaultVal}
                      value={internalValue}
                      dataItemKey={"id"}
                      textField={"displayName"}
                      filterable={true}
                      onFilterChange={filterChange}
                      onChange={handleChange}
        />

    </>)
}


PermissionGroupDropDownList.propTypes = {
    style: PropTypes.object,
    code: PropTypes.string.isRequired,
    idField: PropTypes.string.isRequired,
    dataItem: PropTypes.object,
    defaultItemCode: PropTypes.string,
    onChange: PropTypes.func,
    register: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        permissionGroups: state.permission.permissionGroups,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PermissionActions: bindActionCreators(PermissionAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionGroupDropDownList)