import {PhixPermissions} from "../../../config/GlobalConfig";
import {Grid, GridColumn, GridToolbar} from "@progress/kendo-react-grid";
import {filterBy} from "@progress/kendo-data-query";
import ShowForPermissionComponent from "../../shared/ShowForPermissionComponent";
import {Button} from "primereact/button";
import {PopupWindowActions as PopupActions, PopupWindows} from "../../../action/PopupWindowAction";
import {Input} from "@progress/kendo-react-inputs";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {LaboratoryAction} from "../../../action/LaboratoryAction";

export function ExternalVendorsComponent(props) {
    const dispatch = useDispatch();
    const data = useSelector(state => state.laboratory.installedLaboratoryList);
    const [selected, setSelected] = useState(1)
    const [gridData, setGridData] = useState([])
    const [filter, setFilter] = useState({logic: "and", filters: []})
    useEffect(() => {
        LaboratoryAction.getInstalledDornLaboratories().then((data) => {
                dispatch(data)
            }
        )
    }, []);

    useEffect(() => {
        if (data?.length)
            setGridData(data)
    }, [data])

    return (<ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_ADMIN_VIEW_LABS]}>
        <Grid style={{height: "100%"}}
              data={filterBy(gridData, filter)}
              filterable
              filter={filter}
              onFilterChange={(e) => setFilter(e.filter)}
              sortable={true}>
            <GridToolbar>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_LABS]}>
                    <Button icon={"plus"}
                            onClick={() => PopupActions.showWindow(PopupWindows.ADD_LAB_VENDOR_WINDOW)(dispatch)}>Add
                        Lab</Button>
                </ShowForPermissionComponent>
                <Input
                    style={{width: "50%"}}
                    placeholder={"Search"}
                    id={"input-id"}
                    // value={searchState}
                    // onChange={(e) => setSearchState(e.value)}
                />
            </GridToolbar>
            <GridColumn field={"code"} title={"Lab code"}/>
            <GridColumn field={"displayName"} title={"Name"}/>
            <GridColumn field={"description"} title={"Description"}/>
        </Grid>
    </ShowForPermissionComponent>)
}