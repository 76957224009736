import {ActionTypes} from "../action/ExpandableAction";


const initialState = {
    expandableComponentVisible: false,
    expandableComponent: null,
    componentProps: {},
}

export const expandableWindowReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_EXPANDABLE_COMPONENT:
            return {...state, expandableComponent: action.payload}
        case ActionTypes.TOGGLE_EXPANDABLE_VISIBLE:
            return {...state, expandableComponentVisible: action.payload}
        case ActionTypes.SET_EXPANDABLE_COMPONENT_PROPS:
            return {...state, componentProps: action.payload}
        default:
            return state
    }

}

