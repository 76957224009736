import {filterBy, orderBy} from "@progress/kendo-data-query";
import {Button} from "primereact/button"
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Checkbox} from "../../../InputControls/Checkbox";
import * as React from "react";
import {connect, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../../../action/DictionaryDispatchAction";
import {PatientAction} from "../../../../action/PatientAction";
import {PersonActions} from "../../../../action/PersonAction";
import {UserActions} from "../../../../action/UserAction"
import {PopupWindowActions, PopupWindows} from "../../../../action/PopupWindowAction";
import {OpenEmrActions} from "../../../../action/OpenEmrAction";
import {VeradigmRenderer, VeradigmSsoComponent} from "../../../shared/VeradigmRenderer";
import {PhixPermissions} from "../../../../config/GlobalConfig";
import {PermissionsTool} from "../../../../util/PermissionsTool";
import ShowForPermissionComponent from "../../../shared/ShowForPermissionComponent";
import {Col, Container, Row} from "../../../../layout/GridLayout";
import {DateGridCellWithFormat} from "../../../../util/DateTools";

const MedicationsComponent = (props) => {
    const {PatientActions, patientContext, auth} = props

    const [active, setActive] = React.useState(true)
    const [eprescribeFlag, setEprescribeFlag] = React.useState(false)

    const saml = useSelector(state => state.user.getSAMLValuePatientContext)

    const initialSort = [
        {
            field: "condition",
            dir: "asc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);
    const [filter, setFilter] = React.useState([]);
    const [medicationPermission, setMedicationPermission] = React.useState({isEdit: false, isView: false})


    React.useEffect(() => {
        props.UserActions.getUsersInfo({userRoleId: auth?.userRoleId});
        // props.UserActions.getEprescribeUserAndPatient({patientRoleId: patientContext?.patientRoleId})
    }, [])

    React.useEffect(() => {
        setMedicationPermission(PermissionsTool({
            permissionInfo: props.permissionInfo,
            viewPermissions: ["AH_VIEW_MEDICATION"],
            editPermissions: ["AH_EDIT_MEDICATION"]
        }));
    }, [props?.permissionInfo]);

    async function submitEprescribe() {
        if (patientContext?.patientRoleId) {
            await props.UserActions.getSAMLValuePatientContext({patientRoleId: patientContext?.patientRoleId})
            setEprescribeFlag(true)
        }
    }


    React.useEffect(() => {
        if (patientContext?.patientRoleId) PatientActions.getMedications(patientContext?.patientRoleId)
    }, [patientContext])


    React.useEffect(() => {
        props.DictionaryAction.getDictionaryByCode('AH_PROBLEM_STATUS')
    }, [])

    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });

    const [filterState, setFilterState] = React.useState(false)

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const AddMedications = () => {
        props.PopupActions.showWindow(PopupWindows.ADD_MEDICATIONS_WINDOW);
    };

    const MedicationDiagnoses = (props) => {
        return <td>{
            props?.dataItem?.medicalProblems?.map(v => <tr>{v?.displayName || v?.description}</tr>)
        }</td>;
    }

    const filterActive = (data) => {
        if (!active) {
            return data.filter(r => {
                if (r.dictClinicalStatusCode !== "AH_MEDICATION_CLINICAL_ACTIVE") return r
            })
        } else {
            return data.filter(r => {
                if (r.dictClinicalStatusCode === "AH_MEDICATION_CLINICAL_ACTIVE") return r
            })
        }
    }

    return (
        <Container style={{height: "100%", padding: 0}}>
            <Row gutter className={"justify-content-center"} style={{width: "100%"}}>
                <Col col={3} className={"flex-grow-0"}>
                    <Button togglable={true}
                            text
                            tooltip={"Toggle filtering"}
                            size={"small"}
                            onClick={() => setFilterState(!filterState)}
                            icon={(filterState) ? "pi pi-filter-fill" : "pi pi-filter"}/>

                </Col>
                <Col className={"flex-grow-5"}/>
                {patientContext?.eprescribePatient && (
                    <Col className={"flex-grow-0"}>
                        <Button
                            size={"small"}
                            primary={true}
                            onClick={submitEprescribe}
                        >ePrescribe</Button>
                    </Col>
                )}
                <Col col={2} className="flex align-items-center flex-grow-0">
                    <Checkbox inputId="viewAll"
                              onChange={(e) => setActive(!active)}
                              checked={active}/>
                    <label style={{fontSize: 14}} htmlFor="viewAll" className="ml-2">Active only</label>
                </Col>

                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_MEDICATION]}>
                    <Col className={"flex-grow-0"}>
                        <Button text="Image" onClick={AddMedications}
                                tooltip={"Add medication"}
                                size={"small"}
                                icon={"pi pi-plus"}/>
                    </Col>

                </ShowForPermissionComponent>

            </Row>
            <Row style={{width: "100%", height: "calc(100% - 50px)", padding: 0}}>
                <Grid
                    {...dataState}
                    style={{height: "100%", width: "100%"}}
                    //TODO: Medication list here
                    data={filterBy(orderBy(filterActive(props.medications), sort), filter)}
                    pageSize={dataState.limit}
                    // style={{height: "calc(100vh - 100px)"}}
                    total={props.totalCount}
                    filterable={filterState}
                    filter={filter}
                    onFilterChange={(e) => setFilter(e.filter)}
                    sortable={true}
                    sort={sort}
                    skip={dataState.skip}
                    onRowClick={(item) => {
                        if (medicationPermission.isEdit || medicationPermission.isView) {
                            props.PopupActions.setWindowState(PopupWindows.ADD_MEDICATIONS_WINDOW, {medicationContext: item.dataItem})
                            props.PopupActions.showWindow(PopupWindows.ADD_MEDICATIONS_WINDOW)
                        }
                    }}
                    pageable={{
                        buttonCount: 20,
                        info: true,
                        type: "numeric",
                        pageSizes: true,
                        previousNext: true,
                    }}
                    onSortChange={(e) => {
                        setSort(e.sort);
                    }}

                    onDataStateChange={dataStateChange}
                >
                    <GridColumn field="displayName" title="Medication Name"/>
                    {/* <GridColumn field="displayName" title="Diagnostic Codes" /> */}
                    <GridColumn title="Linked Diagnoses" cell={MedicationDiagnoses} width="350px"/>
                    <GridColumn field="startDate" title="Start Date" width="120px"
                                cell={DateGridCellWithFormat("MM/dd/yyyy")}/>
                    <GridColumn field="endDate" title="End Date" width="120px"
                                cell={DateGridCellWithFormat("MM/dd/yyyy")}/>
                    <GridColumn field="dictClinicalStatusDisplayName" title="Clinical Status" width="120px"/>
                </Grid>
                <Container>
                    {eprescribeFlag &&
                        <VeradigmRenderer>
                            <VeradigmSsoComponent
                                value={saml?.samlResponse ? saml.samlResponse : null}
                                setEprescribeFlag={setEprescribeFlag}/>
                        </VeradigmRenderer>
                    }
                </Container>
            </Row>
        </Container>
    );
};

function mapStateToProps(state) {
    return {
        patientContext: state.patient.patientContext,
        medications: state.patient.medications,
        PROBLEM_STATUS: state.dictionary.dictionaries.AH_PROBLEM_STATUS,
        auth: state.auth.session,
        getUsersInfo: state.user.getUsersInfo,
        getSAML: state.user.getSAML,
        getSAMLValuePatientContext: state.user.getSAMLValuePatientContext,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        DictionaryAction: bindActionCreators(DictionaryDispatchAction, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
        OpenEmrActions: bindActionCreators(OpenEmrActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicationsComponent);
