import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    GET_QUALITY_MEASURE : "GET_QUALITY_MEASURE",
    GET_MEASURE_COHORT_SUCCESS : "GET_MEASURE_COHORT",
    GET_MEASURE_COHORTLIST_SUCCESS : "GET_MEASURE_COHORTLIST",
    GET_MEASURE_COHORTDETAILS_SUCCESS : "GET_MEASURE_COHORTDETAILS",
    GET_MEASURE_COHORTDETAILS_CLEAR : "GET_MEASURE_COHORTDETAILS_CLEAR",
    SET_COHORT_TYPE : "SET_COHORT_TYPE",
    SET_DROPDOWN_VALUES : "SET_DROPDOWN_VALUES",
    SET_DROPDOWN_DEFAULT_VALUE : "SET_DROPDOWN_DEFAULT_VALUE",
    SET_DROPDOWN_VALUES_ERROR : "SET_DROPDOWN_VALUES_ERROR",
    CLEAR_DROPDOWN_VALUES : "CLEAR_DROPDOWN_VALUES",
    SET_SECOND_DROPDOWN_DEFAULT_VALUE : "SET_SECOND_DROPDOWN_DEFAULT_VALUE",
    SET_MEASURE_SELECTION : "SET_MEASURE_SELECTION",
    SET_WORKFLOW : "SET_WORKFLOW",
}


function getQualityMeasureByPatientId(patientRoleId) {
    return WrapAuthentication("qm/byPatientId",
    {"payload": {patientRoleId: patientRoleId}},
    ActionTypes.GET_QUALITY_MEASURE)
}
function getPatientMeasureCohort(UserRoleId) {
    return WrapAuthentication("qm/patientMeasureCohort",
    {"payload": {userRoleId: UserRoleId}},
    ActionTypes.GET_MEASURE_COHORT_SUCCESS)
}
function getTeamMeasureCohort(managerUserRoleId) {
    return WrapAuthentication("qm/myTeamMeasureCohort",
    {"payload": {managerUserRoleId: managerUserRoleId}},
    ActionTypes.GET_MEASURE_COHORT_SUCCESS)
}
function getstateMeasureCohort(stateId) {
    return WrapAuthentication("qm/stateMeasureCohort",
    {"payload": {stateId: stateId}},
    ActionTypes.GET_MEASURE_COHORT_SUCCESS)
}
function getgeoGraphicMeasureCohort(geoGraphicId) {
    return WrapAuthentication("qm/geoGraphicMeasureCohort",
    {"payload": {geoGraphicId: geoGraphicId}},
    ActionTypes.GET_MEASURE_COHORT_SUCCESS)
}

function getserviceOrganizationMeasureCohort(serviceOrganizationId) {
    return WrapAuthentication("qm/serviceOrganizationMeasureCohort",
    {"payload": {serviceOrganizationId: serviceOrganizationId}},
    ActionTypes.GET_MEASURE_COHORT_SUCCESS)
}
function getMeasureCohortList(patientRoleId) {
    return WrapAuthentication("qm/measureCohortList",
    {"payload": {patientRoleId: patientRoleId}},
    ActionTypes.GET_MEASURE_COHORTLIST_SUCCESS)
}
function getMeasureCohortDetails(dictionaryId) {
    return WrapAuthentication("qm/measureCohortDetails",
    {"payload": {dictionaryId: dictionaryId}},
    ActionTypes.GET_MEASURE_COHORTDETAILS_SUCCESS)
}


function clearMeasureCohartDetails() {
    return async dispatch => {
        dispatch({type: ActionTypes.GET_MEASURE_COHORTDETAILS_CLEAR})
    }
}
function setcohortType(cohortType) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_COHORT_TYPE, payload:cohortType})
    }
}

function setdropdownvalues(endpoint,displayName) {
    return WrapAuthentication(endpoint,{},
    ActionTypes.SET_DROPDOWN_VALUES,{displayName:displayName})
}
function setdropdefaultvalue(cohortType) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_DROPDOWN_DEFAULT_VALUE, payload:cohortType})
    }
}
function setseconddropdefaultvalue(value) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_SECOND_DROPDOWN_DEFAULT_VALUE, payload:value})
    }
}
function cleardropdownvalues() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_DROPDOWN_VALUES})
    }
}

function setMeasureSelection(measureName) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_MEASURE_SELECTION, payload:measureName})
    }
}
function setWorkFlow(workflow) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_WORKFLOW, payload:workflow})
    }
}
export const QualityMeasureActions = {
    getQualityMeasureByPatientId,
    getPatientMeasureCohort,
    getTeamMeasureCohort,
    getstateMeasureCohort,
    getgeoGraphicMeasureCohort,
    getserviceOrganizationMeasureCohort,
    getMeasureCohortList:getMeasureCohortList,
    getMeasureCohortDetails,
    clearMeasureCohartDetails,
    setcohortType,
    setdropdownvalues,
    setdropdefaultvalue,
    setseconddropdefaultvalue,
    cleardropdownvalues,
    setMeasureSelection,
    setWorkFlow
}