import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TileHeader from '../../layout/TileHeader';
import 'hammerjs';
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {TileRow} from "../../layout/TileRow";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {ObservationCodes, PhixPermissions} from "../../config/GlobalConfig";
import {
    AverageObservationsByDay,
    CalculateCohortObservationThresholds,
    ThresholdChart,
    ThresholdList
} from "../../util/ObservationThresholds";
import {PatientAction} from "../../action/PatientAction";
import moment from "moment/moment";
import {AeriosNoDataLoadingIndicator} from "../AeriosLoadingIndicator";

// 1ef295bb-924e-4f63-bd17-ec5ce135f6ce
const SleepQualityCareTeamComponent = (props) => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [data, setData] = React.useState([])
    const [thresholds, setThresholds] = useState([])
    const latestCohortObservation = useSelector(state => state.patient.latestCohortObservation)

    React.useEffect(() => {
        let endDate = moment().format("YYYY-MM-DD")
        let startDate = moment().subtract(ObservationCodes.DAY_PREVIOUS.SLEEP_QUALITY, "days").format("YYYY-MM-DD")
        const fetchData = async () => {
            await PatientAction.getPatientCohortObservationSumWithThreshold([ObservationCodes.SLEEP.ASLEEP], startDate, endDate, "SleepQualityCareTeamComponent")(dispatch)
        }
        fetchData().then(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (latestCohortObservation?.SleepQualityCareTeamComponent?.observations) {
            setData(AverageObservationsByDay(latestCohortObservation.SleepQualityCareTeamComponent.observations))
        }
    }, [latestCohortObservation?.SleepQualityCareTeamComponent]);

    useEffect(() => {
        if (data?.length && latestCohortObservation?.SleepQualityCareTeamComponent?.thresholds) {
            let c = CalculateCohortObservationThresholds(data, latestCohortObservation.SleepQualityCareTeamComponent.thresholds)
            console.log(data)
            console.log(c)
            setThresholds(c)
        }
    }, [data])

    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                <TileContainer>
                    <TileHeader className="boxHeader ah-draggable-header"
                                title={"Sleep Quality"} image={"../images/sleep.png"}>
                    </TileHeader>
                    <TileBody fluid style={{padding: 0, backgroundColor: '#FFF'}}
                              differentStyleTitle={"updatedTitleWrapperGrayBg"}>
                        <AeriosNoDataLoadingIndicator loading={loading} data={thresholds}>
                            <TileRow>
                                <ThresholdList thresholds={thresholds}/>
                                <ThresholdChart thresholds={thresholds}/>
                            </TileRow>
                        </AeriosNoDataLoadingIndicator>
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}


export default SleepQualityCareTeamComponent;