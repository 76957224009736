import * as React from "react";
import {connect} from "react-redux";
import "./index.css";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {SocialHistoryActions} from "../../../../action/SocialHistoryAction";
import {bindActionCreators} from "redux";
import {orderBy} from "@progress/kendo-data-query";
import _ from "lodash";
import {Button} from "primereact/button"
import {PopupWindowActions, PopupWindows} from "../../../../action/PopupWindowAction";
import {FormAction} from "../../../../action/FormAction";
import ShowForPermissionComponent from "../../../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../../../config/GlobalConfig";
import {Col, Container, Row} from "../../../../layout/GridLayout";

const SocialHistoryComponent = (props) => {
    const {
        SocialHistoryActions,
        patientContext,
        FormActions
    } = props;

    const initialSort = [
        {
            field: "Group",
            dir: "asc",
        },
        {
            field: "Sort",
            dir: "asc",
        },
    ];
    const [sort, setSort] = React.useState(initialSort);
    const [socialHistory, setSocialHistory] = React.useState([])
    React.useEffect(() => {
        SocialHistoryActions.getSocialHistory(patientContext.patientRoleId)
        SocialHistoryActions.getSocialHistoryData(patientContext?.patientRoleId)
    }, [])

    React.useEffect(() => {
        if (patientContext?.patientRoleId) SocialHistoryActions.getSocialHistoryData(patientContext?.patientRoleId)
    }, [patientContext])

    React.useEffect(() => {
        let data = props.socialHistory.map(s => {
            let value = JSON.parse(s.value)
            return {
                // id: s.id,
                //description: s.description,
                value: s.value.replace(/[\[\]'"]+/g, ''),
                group: s.description,
                question: s.displayName,

            }
        })
        let d = _.chain(data)
            .sortBy("order")
            .sortBy("group")
            .value()
        setSocialHistory(d)
    }, [props.socialHistory])

    const editHandler = () => {
        props.PopupActions.setWindowState(PopupWindows.EDIT_SOCIAL_HISTORY);
        props.PopupActions.showWindow(PopupWindows.EDIT_SOCIAL_HISTORY);
    }

    return (
        <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_SOCIAL_HISTORY]} component>
            <Container style={{height: "100%", padding: 0, margin: 0}}>
                <Row gutter className={"justify-content-center"} style={{width: "100%", height: 50}}>
                    <Col className={"flex-grow-1"}/>
                    <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_SOCIAL_HISTORY]}>
                        <Button text
                                tooltip={"Edit Social History"}
                                size={"small"}
                                icon={"pi pi-pencil"}
                                onClick={() => editHandler()}/>
                    </ShowForPermissionComponent>

                </Row>
                <Row style={{width: "100%", height: "calc(100% - 50px)", padding: 0}}>
                        <Grid
                            style={{height: "100%"}}
                            data={orderBy(socialHistory, sort)}
                            sortable={false}
                            sort={sort}
                            onSortChange={(e) => {
                                setSort(e.sort);
                            }}
                        >
                            <GridColumn field="group" title="Group"/>
                            {/* <GridColumn field="id" title="order"/> */}
                            <GridColumn field="question" title="Question"/>
                            <GridColumn field="value" title="Answer"/>
                        </Grid>
                </Row>
            </Container>
        </ShowForPermissionComponent>
    )
};

function mapStateToProps(state) {
    return {
        patientContext: state.patient.patientContext,
        socialHistory: state.patient.socialHistory,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        SocialHistoryActions: bindActionCreators(SocialHistoryActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        FormActions: bindActionCreators(FormAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialHistoryComponent);
