import * as React from "react";
import {TabStrip, TabStripTab} from "@progress/kendo-react-layout";
import {useSelector} from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import MedicalProblemsComponent from "../ConditionsTab/MedicalProblems/MedicalProblemsComponent";
import AllergiesComponent from "../ConditionsTab/Allergies/AllergiesComponent";
import FamilyHistoryComponent from "../ConditionsTab/FamilyHistory/FamilyHistoryComponent";
import SocialHistoryComponent from "../ConditionsTab/SocialHistory/SocialHistoryComponent";
import MedicationsComponent from "./Medications/MedicationsComponent";
import AssessmentsTabComponent from "../AssessmentsTab/AssessmentsTabComponent";
import HospitalizationsComponent from "./HospitalizationsSurgeries/HospitalizationsComponent";
import ImmunizationsTabComponent from "../ImmunizationsTab/ImmunizationsTabComponent";

import {PhixPermissions} from "../../../config/GlobalConfig";
import SurgeriesComponent from "./Surgeries/SurgeriesComponent";
import {Col, Container} from "../../../layout/GridLayout";

const initialTabs = [
    {
        title: "Assessments",
        content: <AssessmentsTabComponent/>,
        visible: true,
        permission: PhixPermissions.AH_VIEW_ASSESSMENT

    },
    {
        title: "Allergies",
        content: <AllergiesComponent/>,
        visible: true,
        permission: PhixPermissions.AH_VIEW_ALLERGY

    },
    {
        title: "Social History",
        content: <SocialHistoryComponent/>,
        visible: true,
        permission: PhixPermissions.AH_VIEW_SOCIAL_HISTORY
    },
    {
        title: "Family History",
        content: <FamilyHistoryComponent/>,
        visible: true,
        permission: PhixPermissions.AH_VIEW_FAMILY_HISTORY
    },
    {
        title: "Hospitalizations",
        content: <HospitalizationsComponent/>,
        visible: true,
        permission: PhixPermissions.AH_VIEW_HOSPITALIZATION
    },
    {
        title: "Surgeries",
        content: <SurgeriesComponent/>,
        visible: true,
        permission: PhixPermissions.AH_VIEW_HOSPITALIZATION
    },
    {
        title: "Immunizations",
        content: <ImmunizationsTabComponent/>,
        visible: true,
        permission: PhixPermissions.AH_VIEW_IMMUNIZATION
        //     icon: "fa-eyedropper",
    }
];

const styles = {
    tabContainer: {
        width: "100%",
        background: "#BAC1E0",
        padding: "5px",
        textAlign: "center",
        color: "#FFF"
    },
    tabContainerSelected: {
        width: "100%",
        background: "#7280c0",
        padding: "5px",
        textAlign: "center",
        color: "#FFF"
    },
    tabTitle: {
        width: "100%",
        color: "#FFF"
    },
}

const Title = (props) => {
    return (
        <Container style={props.selected === props.index ? styles.tabContainerSelected : styles.tabContainer}>
            <Col>
                <span style={styles.tabTitle} className="tabTitle">{props.content}</span>
            </Col>
        </Container>


    );
};

const ConditionsTabComponent = () => {

    const [selected, setSelected] = React.useState(0);
    const permissions = useSelector(state => state.auth.session.permissions)
    const [tabs, setTabs] = React.useState(initialTabs.filter(i => permissions.includes(i.permission)));

    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    return (
        <TabStrip scrollable={false}
            // style={{width: "100%", height: "100%"}}
                  selected={selected}
                  onSelect={handleSelect}
                  animation={false}
                  tabPosition={"top"}
                  tabContentStyle={{height: "100%", width: "100%"}}>
            {tabs.map((item, index) => {
                return (
                    item.visible && (
                        <TabStripTab
                            title={<Title content={item.title} icon={item.icon} selected={selected} index={index}/>}
                            key={index}
                            contentClassName={"ah-tab"}
                        >
                            {item.content}
                        </TabStripTab>
                    )
                );
            })}
        </TabStrip>
    );
};

export default ConditionsTabComponent

// function mapStateToProps(state) {
//     return {
//         patientContext: state.patient.patientContext,
//         medicalProblems: state.patient.medicalProblems,
//     };
// }
//
// function mapDispatchToProps(dispatch) {
//     return {
//         PatientActions: bindActionCreators(PatientAction, dispatch),
//         PopupActions: bindActionCreators(PopupWindowActions, dispatch),
//     };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ConditionsTabComponent)
