import {connect} from "react-redux";
import React from "react";
import {bindActionCreators} from "redux";
import {WindowActionsBar} from "@progress/kendo-react-dialogs";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {CarePlanActions} from "../../action/CarePlanAction";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {useForm} from "react-hook-form";
import {Dictionary} from "../../components/InputControls/DictionaryDropDownList";
import {Button} from "primereact/button";
import {Field, FieldDictionaryDropDown, FieldSection, FieldTextInput} from "../InputControls/FormFields";
import {Dialog} from "primereact/dialog";

const AddGoalToConditionWindow = (props) => {

    const [problem, setProblem] = React.useState(null);

    React.useEffect(() => {
        if (props?.condition?.id) setProblem(props.condition);
    }, [props.condition])

    const {
        handleSubmit,
        register,
        control,
        reset,
        setValue,
        formState: {errors},
    } = useForm({defaultValues: {description: ""}});

    const [dataState, setDataState] = React.useState({
        take: 5, skip: 0,
    });

    const sort = [{
        field: "displayName", dir: "asc",
    },];

    const hideDialog = () => {
        setProblem(null);
        reset({description: null, status: null, category :null});
        props.CarePlanActions.setEditCarePlanCondition({payload: {}});
        props.PopupActions.clearWindowState(PopupWindows.ADD_GOAL_TO_CONDITION_WINDOW);
        props.PopupActions.hideWindow(PopupWindows.ADD_GOAL_TO_CONDITION_WINDOW);
    }

    function onSubmit(form) {
        props.CarePlanActions.addGoalToCondition(problem.id, form.description, form.status.id, form.category.id).then(() => {
            props.CarePlanActions.getCarePlanForPatientContext(dataState.take, dataState.skip, sort);
            hideDialog();
        });
    }

    return (
        <Dialog header={"Add Goal"}
                style={{width: 500}}
                visible={props.showWindow[PopupWindows.ADD_GOAL_TO_CONDITION_WINDOW]}
                onHide={hideDialog}
        >
            <FieldSection>
                <Field label={"Goal"} field={"description"}
                       error={errors}
                       register={register}
                       control={control}
                       options={{required: "Goal description is required"}}>
                    <FieldTextInput/>
                </Field>
                <Field label={"Status"} field={"status"} error={errors}
                       control={control}
                       register={register}
                       options={{required: "Status is required"}}>
                    <FieldDictionaryDropDown
                        code={Dictionary.CARE_PLAN_GOAL_STATUS}
                        active={true}
                        defaultItemCode={"AH_GOAL_STATUS_IN_PROGRESS"}
                        setDefaultValue={{displayName: "Select status"}}
                    />
                </Field>
                <Field label={"Category"} field={"category"}
                       value="dictGoalCategoryDisplayName"
                       error={errors}
                       register={register}
                       control={control}
                       options={{required: "Category is required"}}>
                    <FieldDictionaryDropDown
                        code={Dictionary.CARE_PLAN_GOALS}
                        setDefaultValue={{displayName: "Select category"}}
                    />
                </Field>
            </FieldSection>
            <WindowActionsBar>
                <Button

                    onClick={handleSubmit(onSubmit)}>

                    Add </Button>
                <Button onClick={hideDialog}>
                    Cancel
                </Button>

            </WindowActionsBar>
        </Dialog>
    );

}

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        dictionaries: state.dictionary.dictionaries,
        popupState: state.popupWindow.state,
        condition: state.patient.carePlanEdit?.condition,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        CarePlanActions: bindActionCreators(CarePlanActions, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGoalToConditionWindow);
