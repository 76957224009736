import {PaginatedEndpoint} from "../util/AuthWrapper";

export function GetMedicationsPaginatedFn(patientRoleId, active) {

    return PaginatedEndpoint("patient/medication/get", {patientRoleId: patientRoleId, active: active})
}

const MedicationActions = {
    GetMedicationsPaginatedFn: GetMedicationsPaginatedFn
}
export default MedicationActions