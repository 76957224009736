import * as React from 'react';
import {useEffect} from 'react';
import {AppBar, AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import SettingsMenuComponent from "./Settings/SettingsMenuComponent";
import {useMediaQuery} from "react-responsive";
import {Button} from "primereact/button";
import {useDispatch, useSelector} from "react-redux";
import {Badge, BadgeContainer} from "@progress/kendo-react-indicators";
import {UserActions} from "../action/UserAction";
import {VeradigmSsoComponent, VeradigmRenderer} from "./shared/VeradigmRenderer";
import {IoNotificationsOutline} from "react-icons/io5";

let phixLogo = '../images/phix/Phix Logo_2c_vector.png'
let mobileLogo = '../images/phix/Phix Logo_2c_vector.png'

const styles = {
    header: {
        backgroundColor: `#7585C2`
    },
    logo: {
        height: `40px`
    },
    menu: {
        backgroundColor: `white`
    },
    messageAvatarImg: {
        width: "100%",
    },
};

function LoggedInAppBar(props) {
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.user.loggedinUser)
    const authenticated = useSelector(state => state.auth.authenticated)
    const userRoleId = useSelector(state => state.auth.session.userRoleId)
    const taskCount = useSelector(state => state.user.userTasks.taskCount)
    useEffect(() => {
        UserActions.getUser({userRoleId: userRoleId})(dispatch)
    }, [authenticated])

    const {submitEprescribe} = props

    return <>
        {currentUser?.eprescribeUser &&
            <AppBarSection>
                <BadgeContainer>
                    <Button
                        icon={IoNotificationsOutline}
                        primary={true}
                        fillMode={"flat"}
                        onClick={submitEprescribe}/>
                    <Badge themeColor={"error"}>{taskCount}</Badge>
                </BadgeContainer>
            </AppBarSection>
        }
        <AppBarSpacer
            style={{
                width: 4,
            }}
        />
        <AppBarSection>
            <SettingsMenuComponent/>
        </AppBarSection>


    </>;
}

function PhixAppBar(props) {
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.user.loggedinUser)
    const authenticated = useSelector(state => state.auth.authenticated)
    const tenantConfig = useSelector(state => state.Configuration.tenant)
    const saml = useSelector(state => state.user.getSAML)
    const [eprescribeFlag, setEprescribeFlag] = React.useState(false)

    async function submitEprescribe() {
        if (currentUser?.eprescribeUser) {
            await UserActions.getSamlTaskMode()(dispatch)
            setEprescribeFlag(true)
        }
    }

    useEffect(() => {
        if (currentUser?.eprescribeUser) {
            UserActions.getVeradigmUserTasks()(dispatch)
        }
        const timer = setTimeout(() => {
            if (currentUser?.eprescribeUser) {
                UserActions.getVeradigmUserTasks()(dispatch)
            }
        }, 5000)
        return () => clearTimeout(timer)
    }, [currentUser])

    const isMobile = useMediaQuery({query: '(max-width: 1224px)'})
    const {interfaceActions} = props
    const AppLogo = () => {
        if (tenantConfig?.SMALL_LOGO?.base64) {
            return <img style={styles.logo} src={`data:image/png;base64,${tenantConfig?.SMALL_LOGO?.base64}`}/>

        } else {
            return <img style={styles.logo} src={phixLogo}/>
        }
    }
    return (

        <AppBar style={styles.header}>
            {isMobile && <Button icon={"k-icon k-i-rows"} primary onClick={() => interfaceActions.toggleSideBar()}/>}
            <AppBarSpacer style={{width: 4}}/>
            {isMobile ?
                <AppBarSection>
                    <AppLogo/>
                </AppBarSection> :
                <AppBarSection>
                    <AppLogo/>
                </AppBarSection>}


            <AppBarSpacer style={{width: 32}}/>
            <AppBarSpacer/>
            {authenticated && <LoggedInAppBar {...props} submitEprescribe={submitEprescribe}/>}
            {eprescribeFlag && (
                <VeradigmRenderer>
                    <VeradigmSsoComponent
                        value={saml?.samlResponse ? saml.samlResponse : null}
                        setEprescribeFlag={setEprescribeFlag}/>
                </VeradigmRenderer>
            )}
        </AppBar>
    )
}


export default PhixAppBar;
