import {ActionTypes} from "../action/PopupWindowAction";


const initialState = {
    showWindow: {},
    lineChart: {
        data: [],
        categories: [],
        title: "",
    },
    state: {},
}

export const popupWindowReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.POPUP_SHOW_WINDOW:
            return {...state, showWindow: {...state.showWindow, [action.window]: true}};
        case ActionTypes.CLEAR_POPUP_WINDOW_STATE:
            return {...state, state: {...state.state, [action.window]: {}}}
        case ActionTypes.POPUP_HIDE_WINDOW:
            return {...state, showWindow: {...state.showWindow, [action.window]: false}};
        case ActionTypes.SET_POPUP_WINDOW_STATE:
            return {...state, state: {...state.state, [action.window]: action.state}};
        case ActionTypes.SHOW_LINE_CHART_WINDOW:
            return {
                ...state,
                showWindow: {...state.showWindow, [action.window]: true},
                lineChart: {data: action.data, categories: action.categories, title: action.title, offset: action.offset, setOffset: action.setOffset }
            }
        case ActionTypes.UPDATE_LINE_CHART_WINDOW:
            return {
                ...state,
                lineChart: {data: action.data, categories: action.categories, title: action.title, offset: action.offset, setOffset: action.setOffset  }
            }
        case ActionTypes.HIDE_LINE_CHART_WINDOW:
            return {
                ...state,
                showWindow: {...state.showWindow, [action.window]: false},
                lineChart: {data: [], categories: [], title: ""}
            }
        default:
            return state
    }

}

