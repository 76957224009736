import _ from "lodash";
import moment from "moment/moment";

export function checkIfValidUUID(str) {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(str);
}


export function objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}


export function DictionaryIdFromObjectMaybe(obj) {
    if (_.isObject(obj)) {
        return obj?.id
    } else {
        return obj
    }

}

export function stringOrJson(value) {
    if (typeof value === "string")
        return (JSON.parse(value))
    else
        return value

}

export function stitchOptionalStrings(strArray, separator = " ") {
    let a = strArray.filter(item => item !== undefined)
    return a.join(separator)

}

function urltoFile(b64, filename, mimeType) {
    let url = `data:${mimeType};${b64}`
    return (fetch(url)
            .then(function (res) {
                return res.arrayBuffer();
            })
            .then(function (buf) {
                return new File([buf], filename, {type: mimeType});
            })
    );
}

export function parseDate(d) {
    if (d)
        return moment(d, "YYYY-MM-DD").toDate()
    else
        return undefined
}


export function FilterDefaultValuesFromForm(form, defaultValues) {
    let kys = Object.keys(defaultValues)
    Object.keys(form).forEach((key) => kys.includes(key) || delete form[key]);
    return form

}