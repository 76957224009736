import React, {useRef, useState} from "react";
import {BaseRequestAction} from "../action/BaseRequestAction";
import {useDispatch, useSelector} from "react-redux";
import TileHeader from "../layout/TileHeader";
import {Components} from "./shared/ComponentRegistry";
import {PopupWindowActions, PopupWindows} from "../action/PopupWindowAction";
import RequestListComponent from "./RequestListComponent";
import ShowForPermissionComponent from './shared/ShowForPermissionComponent';
import {TileContainer} from "../layout/TileContainer";
import {TileBody} from "../layout/TileBody";
import {TileRow} from "../layout/TileRow";
import {PhixPermissions} from "../config/GlobalConfig";
import {Button} from "primereact/button";
import {Col, Container} from "../layout/GridLayout";
import {Dialog} from 'primereact/dialog';
import UserDropDownList from "./InputControls/NewUserDropDownList";
import {RadioButton} from "primereact/radiobutton";

const TaskListComponent = (props) => {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch()
    const requestList = useSelector(state => state.BaseRequest)
    const requestListContext = useSelector(state => state.BaseRequest.requestListContext)
    const loggedinUser = useSelector(state => state.user.loggedinUser.userRoleId)
    const [assignedUser, setAssignedUser] = useState(requestListContext?.assignedUser);
    const [createdUser, setCreatedUser] = useState(requestListContext?.createdUser);
    const defaultCategory = {
        name: "In progress / Not Started",
        key: "in_progress_not_started",
        codes: ["AH_IN_PROGRESS", "AH_NOT_STARTED"]
    };
    const setFilterApplied = () => {
        if (requestListContext?.createdUser || (requestListContext?.requestListCategory?.key !== defaultCategory.key) || (requestListContext?.assignedUser !== loggedinUser)) {
            return true;
        } else {
            return false;
        }
    }
    const [isFilterApplied, setIsFilterApplied] = useState(setFilterApplied());
    const menu = useRef(null)
    const openNewRequestWindow = (event) => {
        PopupWindowActions.showWindow(PopupWindows.CREATE_REQUEST_WINDOW)(dispatch)
    }

    const categories = [
        {
            name: "In progress / Not Started",
            key: "in_progress_not_started",
            codes: ["AH_IN_PROGRESS", "AH_NOT_STARTED"]
        },
        {
            name: "Show Completed / Closed Only",
            key: "show_completed_closed_only",
            codes: ["AH_COMPLETED", "AH_CLOSED_NOT_COMPLETED"]
        },
        {name: "Show all", key: "show_all"}
    ]
    const [selectedCategory, setSelectedCategory] = useState(defaultCategory);

    React.useEffect(() => {
        setAssignedUser(requestListContext?.assignedUser)
        setCreatedUser(requestListContext?.createdUser)
        setSelectedCategory(requestListContext?.requestListCategory)
        setIsFilterApplied(setFilterApplied(requestListContext))
    }, [loggedinUser, requestListContext])

    const clearFilters = () => {
        setAssignedUser(loggedinUser)
        setCreatedUser(null)
        setSelectedCategory(defaultCategory)
        const payload = {
            assignedUser: loggedinUser,
            createdUser: null,
            requestListCategory: defaultCategory,
            isFilterApplied: false
        }
        BaseRequestAction.requestListContext(payload)(dispatch);
        setVisible(false)
    }
    React.useEffect(() => {
        setIsFilterApplied(setFilterApplied(requestListContext))
    }, [requestListContext])

    const FilterFooterContent = () => {
        return <div>
            <Button label="Apply" icon="pi pi-check" onClick={() => {
                var payload = {
                    assignedUser: assignedUser,
                    createdUser: createdUser,
                    requestListCategory: selectedCategory
                }
                payload.isFilterApplied = setFilterApplied(payload);
                BaseRequestAction.requestListContext(payload)(dispatch);
                setVisible(false)
            }} autoFocus/>
            <Button label="Clear" onClick={clearFilters} icon="pi pi-times" className="p-button-text"/>
        </div>
    };
    return (
        <React.Fragment>
            <TileContainer>
                <ShowForPermissionComponent component
                                            permissions={[PhixPermissions.AH_VIEW_REFERRALS, PhixPermissions.AH_EDIT_REFERRALS, PhixPermissions.AH_VIEW_REQUEST, PhixPermissions.AH_EDIT_REQUEST]}>
                    <TileHeader
                        headerColor={"black"}
                        expanded={Components.RequestComponent}
                        title={"Task List"}>
                        <Container gutter={false}>
                            <ShowForPermissionComponent
                                permissions={[PhixPermissions.AH_EDIT_REQUEST, PhixPermissions.AH_EDIT_REFERRALS]}>
                                <Col>
                                    <Button
                                        style={{height: 25, width: 25, color: "black"}}
                                        onClick={openNewRequestWindow}
                                        tooltipOptions={{position: "top"}}
                                        tooltip={"Create a new task"}
                                        rounded
                                        text
                                        icon={"pi pi-plus"}
                                    />
                                </Col>
                            </ShowForPermissionComponent>
                            <Col>
                                <Dialog header="Filters" footer={FilterFooterContent} visible={visible}
                                        style={{width: '22vw'}} onHide={() => setVisible(false)}>
                                    <Container>
                                        <Col col={12}>
                                            <label>{"Assigned to: "}</label>
                                        </Col>
                                        <Col col={12}>
                                            <UserDropDownList
                                                value={assignedUser}
                                                onChange={(e) => {
                                                    setAssignedUser(e?.value || e?.target?.value)
                                                }}
                                                style={{width: "100%"}}/>
                                        </Col>
                                        <Col col={12}>
                                            <label>{"Created by: "}</label>
                                        </Col>
                                        <Col col={12}>
                                            <UserDropDownList
                                                value={createdUser}
                                                onChange={(e) => {
                                                    setCreatedUser(e?.value || e?.target?.value)
                                                }}
                                                style={{width: "100%"}}/>
                                        </Col>
                                        <Col col={12}>
                                            <hr/>
                                        </Col>

                                        {categories.map((category) => {
                                            return (
                                                <Col col={12} key={category.key} className="flex align-items-center">
                                                    <RadioButton inputId={category.key} name="category" value={category}
                                                                 onChange={(e) => setSelectedCategory(e.value)}
                                                                 checked={selectedCategory.key === category.key}/>
                                                    <label htmlFor={category.key}
                                                           className="ml-2">{category.name}</label>
                                                    <hr/>
                                                </Col>
                                            );
                                        })}
                                    </Container>
                                </Dialog>
                                <Button text
                                        tooltipOptions={{position: "top"}}
                                        tooltip={"Filter Task List"}
                                        style={{
                                            height: 25,
                                            width: 25,
                                            color: isFilterApplied ? "red" : "black"
                                        }}
                                        onClick={(e) => setVisible(!visible)}
                                        size={"small"}
                                        rounded
                                        icon={isFilterApplied ? "pi pi-filter-fill" : "pi pi-filter"}/>
                            </Col>
                        </Container>
                    </TileHeader>
                    <TileBody>
                        <TileRow style={{height: "100%"}}>
                            <RequestListComponent/>
                        </TileRow>
                    </TileBody>
                </ShowForPermissionComponent>
            </TileContainer>
        </React.Fragment>
    );
};

TaskListComponent.propTypes = {};

export default TaskListComponent
