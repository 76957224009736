import * as React from 'react';
import {Button} from "@progress/kendo-react-buttons";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {AuthAction} from "../../action/AuthAction";
import {connect} from "react-redux";
import {Col, Container, Row} from "react-grid-system";

const styles = {
    container: {
        marginTop: "2rem",
        marginRight: "4rem",
        marginLeft: "4rem",
        marginBottom: "4rem",
        width: "100%",
        padding: 0,
    },
    buttons: {
        width: "7rem"
    }
}
const LogoutComponent = (props) => {
    const handleSubmit = () => {
        props.authActions.logout()
    }
    return <Container style={styles.container}>
        <Row>
            <h2>Are you sure you want to logout?</h2>
        </Row>
        <Row style={{marginTop: "4rem"}}>
            <Col xs={4}/>
            <Col xs={4}>
                <Button type={"submit"} primary={true} style={styles.buttons} onClick={handleSubmit}>Yes</Button>
            </Col>
            <Col xs={4}>
                <Button style={styles.buttons} onClick={props.onCancel}>Cancel</Button>
            </Col>
        </Row>
    </Container>
};

LogoutComponent.propTypes = {
    authActions: PropTypes.object,
}


function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated
    };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutComponent);
