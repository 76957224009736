import React, {useEffect} from "react";
import {FormBuilder} from "@ginkgo-bioworks/react-json-schema-form-builder";
import {bindActionCreators} from "redux";
import {FormAction} from "../../action/FormAction";
import {connect} from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button} from "primereact/button";
import {Checkbox} from "@progress/kendo-react-inputs";
import moment from "moment";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../config/GlobalConfig";
import {Col, Container, Row} from "../../layout/GridLayout";
import {UtcNowIsoString} from "../../util/DateTools";

function PhixFormBuilder(props) {
    const {FormActions, editFormContext} = props;
    const [schema, setSchema] = React.useState("{}")
    const [uiSchema, setUiSchema] = React.useState("{}")
    useEffect(() => {
        if (editFormContext?.formSchema) {
            if (typeof editFormContext.formSchema === "object") {
                setSchema(JSON.stringify(editFormContext.formSchema))
                setUiSchema(JSON.stringify(editFormContext.formUiSchema))
            } else {
                setSchema(editFormContext.formSchema)
                setUiSchema(editFormContext.formUiSchema)
            }


        }
    }, [])
    useEffect(() => {
        if (editFormContext?.id)
            FormActions.setEditFormContext(props.formList.payload.find(v => v.id === editFormContext.id));
    }, [props.formList])

    function save() {
        FormActions.saveFormSchema({
                id: editFormContext.id,
                code: editFormContext.code,
                dictFormCategoryId: editFormContext.dictFormCategoryId,
                displayName: editFormContext.displayName,
                description: editFormContext.description,
                formSchema: JSON.stringify(schema),
                formData: "{}",
                formUiSchema: JSON.stringify(uiSchema),
                createdDateTime: UtcNowIsoString(),
                modifiedDateTime: UtcNowIsoString()
            }
        ).then(response => {
            FormActions.getFormSchemaList(props.dataState.take, props.dataState.skip, props.sort, {})
            FormActions.getAdminFormSchemaList(props.dataState.take, props.dataState.skip, props.sort, null)
                .then(r => {
                    FormActions.setEditFormContext(props.formList.payload.find(v => v.id === editFormContext.id));
                })
        })

    }

    function publish() {
        if (editFormContext?.formSchemaId) {
            FormActions.publishFormSchema({
                    id: editFormContext.id,
                    formSchemaId: editFormContext.formSchemaId,
                    modifiedDateTime: UtcNowIsoString()
                }
            ).then(response => {
                FormActions.getFormSchemaList(props.dataState.take, props.dataState.skip, props.sort, {})
                FormActions.getAdminFormSchemaList(props.dataState.take, props.dataState.skip, props.sort, null)
                    .then(r => {
                        FormActions.setEditFormContext(props.formList.payload.find(v => v.id === editFormContext.id));
                    })
            })
        } else {
            alert("Please save the form");
        }

    }

    function updateIsActive(event) {
        FormActions.updateFormSchemaHeaderActive(editFormContext.id, event.value).then(response => {
            FormActions.getAdminFormSchemaList(props.dataState.take, props.dataState.skip, props.sort, null)
            FormActions.setEditFormContext({})
            props.setTabSelected(0);
        })
    }

    const customFormInputs = {
        customEmail: {
            displayName: "Custom Email",
            matchIf: [
                {
                    types: ["string"],
                    widget: "email"
                },
            ],
            defaultDataSchema: {},
            defaultUiSchema: {
                "ui:widget": "password"
            },
            type: "array",
            cardBody: (parameters, onChange) => <div>
                <h5>Default email</h5>
                <input
                    value={parameters.default}
                    placeholder="Default"
                    type="array"
                    onChange={(ev) =>
                        onChange({...parameters, default: ev.target.value})
                    }
                />
            </div>,
            modalBody: (parameters, onChange) => <div>
                Extra editing options in modal appear hear
            </div>,
        },
    };

    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        return newDate;
    }

    function CreatedBy() {
        const published = props.publishedFormList?.payload?.find(v => v.code === editFormContext.code);
        let list = [];
        if (editFormContext.modifiedDate) {
            let text = "Current draft updated on " + new Date(editFormContext.modifiedDate).toLocaleDateString('en-US') + " " + moment(convertUTCDateToLocalDate(new Date(editFormContext.modifiedDate))).format('hh:mm a') + " ";
            text += editFormContext?.modifiedByUserRoleId ? "by " + editFormContext?.userName : "";
            list.push(<Col col={12} style={{fontSize: 14, textAlign: "center", paddingBottom: 5}}>{text}</Col>)
        }

        if (published && published.modifiedDate) {
            let text = "Most recently published on " + new Date(published.modifiedDate).toLocaleDateString('en-US') + " " + moment(convertUTCDateToLocalDate(new Date(published.modifiedDate))).format('hh:mm a') + " ";
            text += published?.modifiedByUserRoleId ? "by " + published?.userName : "";
            list.push(<Col col={12} style={{fontSize: 14, textAlign: "center", paddingBottom: 5}}>{text}</Col>)
        }
        return list
    }

    return (
        <Container style={{width : "95%"}}>
            <Col col={12} className={"align-content-center"} style={{height: 75, backgroundColor: "lightgray", borderRadius: 5}}>
                <Row className={"align-content-center align-items-center"} style={{padding: 10, paddingBottom: 15}}>
                    <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_FORM]}>
                        <Col col={1} style={{paddingRight: 10}}>
                            <Button onClick={save} label={"Save Draft"}/>
                        </Col>
                        <Col col={1}>
                            <Button onClick={publish}>Publish</Button>
                        </Col>
                    </ShowForPermissionComponent>
                    <Col col={8}>
                        <Row>
                            <CreatedBy/>
                        </Row>
                    </Col>
                    <Col col={2}>
                        <Checkbox value={editFormContext.active} onChange={updateIsActive} labelPlacement={"before"}
                                  label={"Active"}/>
                    </Col>
                </Row>
            </Col>
            <Col col={12} style={{paddingTop: 20}}>
                <FormBuilder
                    schema={schema}
                    uischema={uiSchema}
                    mods={{customFormInputs}}
                    onChange={(newSchema, newUiSchema) => {
                        setSchema(newSchema)
                        setUiSchema(newUiSchema)

                    }}
                />

            </Col>

        </Container>)


}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        formList: state.form.adminFormList,
        publishedFormList: state.form.formList,
        userList: state.user.userList,
        editFormContext: state.form.editFormContext,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        FormActions: bindActionCreators(FormAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhixFormBuilder)