import * as React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {connect} from "react-redux";
import {Grid, GridColumn, GridToolbar} from "@progress/kendo-react-grid";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {EditCommandCell} from "./EditCommandCell";
import {Button} from "primereact/button";
import {v4 as uuidv4} from 'uuid';
import {InlineDatePicker, InlineDictionaryDropDown, InlineTextInput} from "../InputControls/InlineFields";
import _ from "lodash";
import {UserActions} from "../../action/UserAction";
import moment from "moment";

const DictionaryTypeDropdown = (props) => <InlineDictionaryDropDown
    {...props}
    idField={"dictLicenseTypeId"}
    displayField={"dictLicenseTypeDisplayName"}
    code={Dictionary.LICENSE_TYPE}/>

const StateDropdown = (props) =>
    <InlineDictionaryDropDown
        {...props}
        idField={"dictStateId"}
        displayField={"dictStateDisplayName"}
        code={Dictionary.STATES}/>

const LicenseComponent = (props) => {
    const {userLicense, UserActions, userRoleId} = props
    const [data, setData] = React.useState([]);
    const editField = "inEdit";

    React.useEffect(() => {
        if (userRoleId)
            UserActions.getLicensesForUser(userRoleId);
    }, [userRoleId])

    React.useEffect(() => {
        setData(userLicense)
    }, [userLicense])

    React.useEffect(() => console.log(data), [data])

    const addNew = () => {
        let newDataItem = {
            id: uuidv4(),
            newItem: true,
            inEdit: true,
            userRoleId: userRoleId,
        };
        setData([...data, newDataItem]);
    };

    const validateFields = (dataItem) => {
        let errors = {}
        if (!dataItem.dictLicenseTypeId) errors = {...errors, dictLicenseTypeId: "This field is required."}
        if (!dataItem.licenseNumber) errors = {...errors, licenseNumber: "This field is required."}
        if (!dataItem.startDate) errors = {...errors, startDate: "This field is required."}
        if (_.isEmpty(errors))
            return true
        else {
            itemChange({
                dataIndex: 0,
                dataItem: dataItem,
                field: "errors",
                value: errors,
            })
            return false
        }

    }
    // "2021-12-16"

    const add = (dataItem) => {
        if (validateFields(dataItem)) {
            let payload = {
                userRoleId: userRoleId,
                licenseNumber: dataItem.licenseNumber,
                dictStateId: dataItem?.dictStateId,
                dictLicenseTypeId: dataItem.dictLicenseTypeId,
                startDate: dataItem?.startDate ? moment(dataItem?.startDate).format("YYYY-MM-DD") : null,
                endDate: dataItem?.endDate ? moment(dataItem?.endDate).format("YYYY-MM-DD") : null

            }
            // console.log(payload)
            props.UserActions.createUserLicense(payload)
                .then(() => props.UserActions.getLicensesForUser(userRoleId))
        }
    };

    const update = (dataItem) => {
        if (validateFields(dataItem)) {
            let payload = {
                id: dataItem.id,
                userRoleId: userRoleId,
                licenseNumber: dataItem.licenseNumber,
                dictStateId: dataItem?.dictStateId,
                dictLicenseTypeId: dataItem.dictLicenseTypeId,
                startDate: dataItem?.startDate ? moment(dataItem?.startDate).format("YYYY-MM-DD") : null,
                endDate: dataItem?.endDate ? moment(dataItem?.endDate).format("YYYY-MM-DD") : null
            }
            props.UserActions.updateUserLicense(payload)
                .then(() => UserActions.getLicensesForUser(userRoleId))
        }
    };
    const edit = (props) => {
        return (
            <EditCommandCell
                {...props}
                editField={editField}
                cancel={cancel}
                remove={remove}
                update={update}
                add={add}
                edit={enterEdit}
            />
        );
    };
    const remove = (dataItem) => {
        if (window.confirm("Are you sure you want to delete this license?")) {
            UserActions.deleteUserLicense(dataItem.id).then(() => UserActions.getLicensesForUser(userRoleId))
        }

    }

    const cancel = (dataItem) => {
        const data1 = [...data];
        const originalItem = data.find((p) => p.id === dataItem.id);
        data1.splice(data1.indexOf(originalItem), 1);
        setData(data1);

    }

    const enterEdit = (dataItem) => {
        let newData = data.map((item) =>
            item.id === dataItem.id ? {...item, inEdit: true} : item
        );
        setData(newData);
    };

    const itemChange = (event) => {
        const field = event.field || "";
        const newData = data.map((item) =>
            item.id === event.dataItem.id
                ? {...item, [field]: event.value}
                : item
        );

        setData(newData);
    };
    return (
        <Grid
            editField={editField}
            data={data}
            onItemChange={itemChange}
            dataItemKey={"id"}
        >
            <GridToolbar>
                <Button onClick={addNew} text icon={"pi pi-plus"}/>
                <Button icon={"pi pi-refresh"}
                        text
                        onChange={() => UserActions.getLicensesForUser(userRoleId)}/>
            </GridToolbar>
            <GridColumn
                field="dictLicenseTypeId"
                title="Type"
                //editor={"text"}
                cell={DictionaryTypeDropdown}
            />
            <GridColumn
                field="dictStateId"
                title="State"
                //editor={"text"}
                cell={StateDropdown}
            />
            <GridColumn
                field="startDate"
                title="Start Date"
                cell={InlineDatePicker}
                width={200}
            />
            <GridColumn
                field="endDate"
                title="End Date"
                cell={InlineDatePicker}
                width={200}
            />
            <GridColumn
                field="licenseNumber"
                title="License Number"
                cell={InlineTextInput}
            />
            <GridColumn field="action" title="Action" cell={edit} width={"120px"}/>
        </Grid>
    )
}

LicenseComponent.propTypes = {
    UserActions: PropTypes.object,
    userRoleId: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        userLicense: state.user.userLicenseContext,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LicenseComponent)