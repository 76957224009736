import {bindActionCreators} from "redux";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {connect} from "react-redux";
import {Window} from "@progress/kendo-react-dialogs";
import * as React from "react";
import {Button} from "primereact/button";
import {NotificationAction} from "../../action/NotificationAction";
import moment from "moment";
import {OpenEmrActions} from "../../action/OpenEmrAction";

const styles = {
    notificationWrapper: {
        width: "100%",
        position: 'relative',
        paddingTop: '35px',
        height: "calc(100% - 35px)",
        paddingBottom: '115px'
    },
    notificationBody: {
        // minHeight: '35vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
    },
    row: {
        width: "100%",
    },
    col3: {
        width: "16%",
        float: 'left',
        margin: '0',
        padding: '0px',
    },
    col2: {
        width: "20%",
        float: 'left',
        margin: '0',
        padding: '0px',
    },
    col1: {
        width: "2%",
        float: 'left',
        margin: '0',
        padding: '0px',
    },
    col6: {
        width: "68%",
        float: 'left',
        margin: '0px',
        padding: '0px',
    },
    col7: {
        width: "78%",
        float: 'left',
        margin: '0',
        padding: '0px',
    },
    col5: {
        width: "50%",
        float: 'left',
        margin: '0px',
        padding: '0px',
    },
    colP: {
        margin: '0px',
        padding: '2px 5px',
        fontSize: '12px',
    },
    colPRed: {
        margin: '0px',
        padding: '2px 5px',
        fontSize: '12px',
        color: '#f00',
    },
    colPB: {
        margin: '0px',
        padding: '2px 5px',
        fontSize: '12px',
        fontWeight: '600',
    },
    colIconAct: {
        margin: '5px',
        padding: '0px',
        fontSize: '22px',
        color: 'rgb(113, 186, 96)',
        position: 'relative',
    },
    colIcon: {
        margin: '5px',
        padding: '0px',
        fontSize: '22px',
        color: 'rgb(204, 204, 204)',
        position: 'relative',
    },
    colIconText: {
        position: 'absolute',
        fontSize: '11px',
        color: '#ffffff',
        left: '8px',
        top: '3px',
    },
    groupHeader: {
        backgroundColor: '#dce0ee',
        padding: '4px 5px',
        textAlign: 'center',
        width: '100%',
        float: 'left',
    },
    moreButton: {
        color: '#7585c2',
        fontWeight: '400',
        textDecoration: 'underline',
        float: 'right',
        background: 'none',
        border: 'none',
        margin: '0 5px',
        cursor: 'pointer',
    },
    messageAvatar: {
        border: '2px solid #ffd966',
        width: '25px', height: '25px',
        flexBasis: "25px",
        backgroundColor: "#fff",
        marginRight: "5px"
    },
}


const NotificationDetailsWindow = (props) => {
    const { data, categories, title, notification, OpenEmrActions, patientContext } = props
    const hideDialog = () => {
        props.notificationActions.clearNotificationContext()
        props.PopupActions.hideWindow(PopupWindows.NOTIFICATION_DETAILS_WINDOW)
    }

    const isPassedDate = (date) => {
        const createdDate = moment.utc(date).local().startOf('day')
        const current = moment().startOf('day');
        const diffInDays = moment.duration(createdDate.diff(current)).asDays();
        return diffInDays < 0;
    }

    const handleGo = () => {
        if (notification.actionTypeCode === "AH_AT_SCHEDULE_ENCOUNTER") {
            props.PopupActions.showWindow(PopupWindows.ADD_NOTIFICATION_EVENT_WINDOW)
            props.PopupActions.setWindowState(PopupWindows.ADD_NOTIFICATION_EVENT_WINDOW, { notificationContext: notification })
        } else if (notification.actionTypeCode === "AH_AT_GOTO_ENCOUNTER") {
            //alert('AH_AT_GOTO_ENCOUNTER')
            if (notification.encounterExternalId != null) {
                OpenEmrActions.goToEncounter(notification.patientExternalId, notification.encounterExternalId)
            } else {
                OpenEmrActions.createEncounter(notification.patientExternalId)
            }
        }
    }
    return (
        props.showWindow[PopupWindows.NOTIFICATION_DETAILS_WINDOW] ?
            <Window draggable={true}
                modal={true}
                onClose={hideDialog}
                initialTop={200}
                // minHeight={500}
                initialWidth={600}
                resizable={true}
                title={"Notification Details"}
                closeButton={() => <Button fillMode={"flat"} onClick={hideDialog} icon={"close"} />}>
                <div style={styles.row}>


                    <div style={{ ...styles.row, minHeight: '40px' }}>
                        <strong>
                            {notification.displayMessage}
                        </strong>
                    </div>
                    <div style={{ ...styles.row, minHeight: '30px' }}>
                        <span style={styles.col2}><strong> Reason </strong></span>
                        <span style={styles.col1}>:</span>
                        <span style={styles.col7}>
                            {notification.reason}
                        </span>
                    </div>
                    <div style={{ ...styles.row, minHeight: '30px' }}>
                        <span style={styles.col2}><strong> Action </strong> </span>
                        <span style={styles.col1}>:</span>
                        <span style={styles.col7}>
                            {notification.notificationTypeDescription}
                        </span>
                    </div>


                    <div style={{ ...styles.row, textAlign: 'right' }}>
                        <Button
                            type="button"
                            themeColor={"primary"}
                            onClick={handleGo}
                        >
                            Go
                        </Button>
                    </div>

                    <div style={{ ...styles.row, minHeight: '30px' }}>
                        <span style={styles.col2}>
                            <strong>Provider</strong>
                        </span>
                        <span style={styles.col1}>:</span>
                        <span style={styles.col7}>
                            {notification.userRoleFirstName} {notification.userRoleLastName}
                        </span>
                    </div>

                    <div style={{ ...styles.row, minHeight: '30px' }}>
                        <span style={styles.col2}><strong> Due by </strong> </span>
                        <span style={styles.col1}>:</span>
                        <span style={styles.col7}>
                            <div style={{ ...styles.row, minHeight: '30px' }}>
                                <span>{notification.dueDate.split("T")[0]} </span>
                                {isPassedDate(notification.dueDate) ?
                                    <span style={{ color: 'red' }}>
                                        <i className="k-icon k-i-warning" style={{ color: 'red' }} />
                                        Overdue
                                    </span>
                                    : null}

                            </div>
                        </span>
                    </div>
                </div>
            </Window> : null
    );
}


function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        notification: state.notification.notificationContext,
        patientContext: state.patient.patientContext,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        notificationActions: bindActionCreators(NotificationAction, dispatch),
        OpenEmrActions: bindActionCreators(OpenEmrActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetailsWindow)
