import React, {useCallback, useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux"
import {getFormSchemaList, IFormSchemaContext, setEditFormContext} from "../../../action/FormAction";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {Label} from "@progress/kendo-react-labels";
import {FieldWrapper} from "@progress/kendo-react-form";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";


export const FormSelectorCombo = (props) => {
    const dispatch = useDispatch()
    const [filter, setFilter] = useState("")
    const [data, setData] =useState([])
    const forms = useSelector((state) => {
        return state.form.formList?.payload?.sort(function(a, b) {
                                        var textA = a.dictFormCategoryDisplayName.toUpperCase();
                                        var textB = b.dictFormCategoryDisplayName.toUpperCase();
                                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                    }) || []
    })


    const formElements = useSelector((state) => state.form?.formElements || [])
    const totalCount = useSelector((state) => state.form.formList?.totalCount)
    const [value, setValue] = useState();

    const refreshForms = useCallback((sort, filter) =>
        dispatch(getFormSchemaList(100, 0, sort, filter)
        ), [dispatch]
    )
    const [show, setShow] = React.useState(false);

    const setFormContext = useCallback((form) => dispatch(setEditFormContext(form)), [dispatch])
    useEffect(() => {
        refreshForms(undefined, {displayName: filter})
    }, [filter])

    const isAlreadySelected = (form) => {
        let isExists = formElements.find(v=> v && v.displayName && form && form.displayName && v.displayName === form.displayName);
        return (!isExists);
    }

    const toggleDialog = () => {
        setShow(false);
    };


    return (
        <FieldWrapper>
            <Label>
                Choose a form to include:
            </Label>
            <ComboBox
                textContentType='none'
                style={props.style}
                filterable={true}
                onChange={(e) => {
                    setShow(false);
                    isAlreadySelected(e.value) ? setFormContext(e.value) : setShow(true);
                    setValue("")
                }}
                onFilterChange={(e) => setFilter(e.filter.value)}
                data={forms}
                value={value}
                suggest
                textField={"displayName"}
                groupField={"dictFormCategoryDisplayName"}
                fillMode={"outline"}
            />
            {show && (
                    <Dialog
                      onClose={toggleDialog}
                      width={250}
                      height={250}
                    >
                      <p
                        style={{
                          margin: "25px",
                          textAlign: "center",
                        }}
                      >
                        This form has already been added to the encounter.
                      </p>
                      <DialogActionsBar>
                        <button
                          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                          onClick={toggleDialog}
                        >
                          Ok
                        </button>
                      </DialogActionsBar>
                    </Dialog>
                  )}
        </FieldWrapper>
    )
}

