import {bindActionCreators} from "redux";
import {PatientAction} from "../../../action/PatientAction";
import {DictionaryDispatchAction} from "../../../action/DictionaryDispatchAction";
import {DictionaryTypeAction} from "../../../action/DictionaryTypeAction";
import {PersonActions} from "../../../action/PersonAction";
import {PopupWindowActions} from "../../../action/PopupWindowAction";
import {CarePlanActions} from "../../../action/CarePlanAction";
import {connect} from "react-redux";
import React from "react";
import {Field, FieldDictionaryDropDown, FieldRow, FieldSection, FieldTextInput} from "../../InputControls/FormFields";
import {Dictionary} from "../../InputControls/DictionaryDropDownList";
import {WindowActionsBar} from "@progress/kendo-react-dialogs";
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import {useForm} from "react-hook-form";
import {ListView, ListViewHeader} from "@progress/kendo-react-listview";
import {Col, Container, Row} from 'react-grid-system';

const GoalStep = (props) => {
    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        getValues,
        setValue,
        control,
        formState: {errors, isDirty, isSubmitting, touchedFields, submitCount},
    } = useForm();

    const {
        CarePlanActions,
        stepIndex,
        nextStep,
        previousStep,
        hideDialog,
        setFormFromStep,
        carePlanEdit,
    } = props

    const [goals, setGoals] = React.useState(carePlanEdit.goals ? carePlanEdit.goals : [])
    const [currentGoal, setCurrentGoal] = React.useState({})

    React.useEffect(() => {
        CarePlanActions.getGoalsForProblemEdit(carePlanEdit.condition.id)
    }, [])

    React.useEffect(() => {
        setGoals(carePlanEdit.goals)
    }, [carePlanEdit.goals])

    React.useEffect(() => {
        if (currentGoal.description) {
            setValue("description", currentGoal.description)
            setValue("dictGoalStatusId", {
                id: currentGoal.dictGoalStatusId,
                displayName: currentGoal.dictStatusDisplayName
            })
            setValue("dictGoalCategoryId", {
                id: currentGoal.dictGoalCategoryId,
                displayName: currentGoal.dictGoalCategoryDisplayName
            })
        }
    }, [currentGoal])


    const listHeader = () => {
        return (
            <ListViewHeader
                style={{
                    color: "rgb(160, 160, 160)",
                    fontSize: 14,
                }}
                className="pl-3 pb-2 pt-2"
            >
                Goals: {carePlanEdit.condition.displayName}
            </ListViewHeader>
        );
    };


    const itemRenderer = (props) => {
        let item = props.dataItem;
        return (
            <Container style={{paddingTop: 5}}>
                <Row
                    style={{borderBottom: "1px solid rgb(242, 242, 242)"}}>
                    <Col sm={5}>
                        {item.description}
                    </Col>
                    <Col sm={3}>
                        {item.dictCategoryDisplayName}
                    </Col>
                    <Col sm={2}>
                        {item.dictStatusDisplayName}
                    </Col>
                    <Col sm={1}>
                        <ButtonGroup>
                            {!item?.strategies &&
                                <Button look={"flat"} icon={"delete"} onClick={() => removeGoal(item.id)}/>}
                            <Button look={"flat"} icon={"edit"} onClick={() => setCurrentGoal(item)}/>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Container>
        );
    };

    const removeGoal = (id) => {
        let d = goals.filter((d) => d.id !== id)
        setFormFromStep("goals", d)
        setGoals(d)

    }

    const addGoal = (f) => {
        if (currentGoal?.id) {
            CarePlanActions.updateCarePlanGoal(currentGoal.id, currentGoal.medicalProblemId, f.description, f.dictGoalStatusId.id, f.dictGoalCategoryId.id)

        } else {
            CarePlanActions.addGoalToCondition(carePlanEdit.condition.id, f.description, f.dictGoalStatusId.id, f.dictGoalCategoryId.id)
        }
        reset({
            description: null,
            dictGoalCategoryId: null,
            dictGoalStatusId: null,

        })
        setCurrentGoal({})

    }
    const saveGoals = () => {
        //setFormFromStep("goals", goals)
        nextStep()
    }

    return <div style={{height: "calc(100% - 120px)"}}>

        <FieldSection style={{height: "100%", width: "90%"}}>
            <div className="sub-title">Goal</div>
            <Row align={"center"}>
                <Field col={11} label={"Goal Description"} field={"description"} error={errors} register={register}
                       options={{required: "Goal is required."}}
                       control={control}>
                    <FieldTextInput rows={4}/>
                </Field>
                <Col sm={1} style={{marginTop: 10}}>
                    <Button icon={currentGoal.id ? "save" : "add"} primary={true}
                            onClick={handleSubmit(addGoal)}/>
                </Col>
            </Row>
            <FieldRow>

                <Field col={6} label={"Goal Category"} field={"dictGoalCategoryId"} error={errors}
                       register={register}
                       options={{required: "Goal category is required."}}
                       control={control}>
                    <FieldDictionaryDropDown code={Dictionary.CARE_PLAN_GOALS}
                                             dataItem={currentGoal}
                        // defaultItemCode={"AH_GOAL_STATUS_IN_PROGRESS"}
                    />
                </Field>
                <Field col={6} label={"Goal Status"} field={"dictGoalStatusId"} error={errors}
                       register={register}
                       options={{
                           required: "Goal Status is required.",
                           validate: (d) => d?.id ? true : "This is required."
                       }}
                       control={control}>
                    <FieldDictionaryDropDown code={Dictionary.CARE_PLAN_GOAL_STATUS}
                                             idField={"dictGoalStatusId"}
                                             dataItem={currentGoal}
                                             defaultItemCode={"AH_GOAL_STATUS_IN_PROGRESS"}
                    />
                </Field>
            </FieldRow>
            <Row>
                <Col col={12}>
                    <ListView
                        style={{height: "200px"}}
                        data={goals}
                        header={listHeader}
                        item={itemRenderer}
                        textField={"displayName"}
                        selectedField={"selected"}/>
                </Col>
            </Row>
        </FieldSection>

        <WindowActionsBar>
            <Button style={{backgroundColor: "#E06666"}} onClick={hideDialog}>
                Cancel
            </Button>
            {stepIndex > 0 && <Button icon={"arrow-left"} themeColor={"primary"} onClick={previousStep}>
                Back
            </Button>}
            <Button icon={"arrow-right"} themeColor={"primary"} onClick={saveGoals}>
                Next
            </Button>
        </WindowActionsBar>
    </div>


}

function mapStateToProps(state) {
    return {
        carePlan: state.patient.carePlan,
        showWindow: state.popupWindow.showWindow,
        patientContext: state.patient.patientContext,
        carePlanEdit: state.patient.carePlanEdit,


    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        CarePlanActions: bindActionCreators(CarePlanActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalStep)