import {Dialog} from "primereact/dialog";
import {connect, useDispatch} from "react-redux";
import React, {useCallback} from "react";
import {bindActionCreators} from "redux";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import FormPreviewWindow from "../form/FormRendererComponent";
import {FormAction, getFormSchemaList, setEditFormContext} from "../../action/FormAction";
import {SocialHistoryActions} from "../../action/SocialHistoryAction";
import {FamilyHistoryActions} from "../../action/FamilyHistoryAction";
import {PatientAction} from "../../action/PatientAction";
import {Button} from "primereact/button";
import {Container} from "../../layout/GridLayout";


const FamilyHistoryWindow = (props) => {
    const code = 'ah_family_history';

    const dispatch = useDispatch();

    const hideDialog = async () => {
        props.PopupActions.hideWindow(PopupWindows.EDIT_FAMILY_HISTORY)
        props.FormActions.clearFormContext();
        props.FormActions.setEditFormContext({});
    }

    const clearForm = () => {
        props.FormActions.clearFormContext();
        props.FormActions.setEditFormContext({});
        hideDialog();
    }

    const refreshForms = useCallback((sort, filter) =>
        dispatch(getFormSchemaList(100, 0, sort, filter)
        ), [dispatch]
    )

    function DisplayForm() {
        if (props?.formList?.payload) {
            const payload = props.socialHistoryData;
            setFormContext(payload)
        } else {
            refreshForms()
        }

        if (props.editFormContext && Object.keys(props.editFormContext).length > 0) {
            return <FormPreviewWindow
                show={false}
                editFormContext={props.editFormContext}/>
        } else {
            return null;
        }
    }

    const setFormContext = useCallback((form) => dispatch(setEditFormContext(form)), [dispatch])

    function saveFamilyHistoryForm(form, isDraft) {
        if (form) {
            let savePayload = {
                patientRoleId: props.patient.patientRoleId,
                encounterId: props?.socialHistoryData?.encounterId ? props?.socialHistoryData?.encounterId : null,
                // formId:props?.socialHistoryData?.formId ? props?.socialHistoryData?.formId : null,
                value: JSON.stringify(form.formData)
            };
            //  if(form?.formId || form.formId === null){
            //     delete savePayload.formId
            // }
            props.SocialHistoryActions.createFamilyHistory(savePayload)
                .then(() => {
                    clearForm();
                    props.PatientActions.getFamilyHistory(props.patient?.patientRoleId);
                    props.FamilyHistoryActions.getFamilyHistoryData(props.patient?.patientRoleId);
                    hideDialog();
                });
        }
    }


    return (
        <Dialog visible={props.showWindow[PopupWindows.EDIT_FAMILY_HISTORY]}
                header={"Edit Family History"}
                maximizable
                footer={<Container className={"justify-content-end"}>
                    <Button label={"Save"}
                            severity={"success"}
                            onClick={() => {
                                if (props.editFormContext) {
                                    saveFamilyHistoryForm(props.editFormContext, false)
                                }
                            }}/>

                    <Button label={"Cancel"}
                            severity={"danger"}
                            onClick={clearForm}/>

                </Container>}
                onHide={hideDialog}>
            {
                (Object.keys(props.socialHistoryData).length > 0) ? <div style={{height: "100%", overflowY: "auto"}}>
                    <DisplayForm/>
                </div> : <div> Form was not found </div>
            }

        </Dialog>
    );
}

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        editFormContext: state.form.editFormContext,
        formList: state.form.formList,
        socialHistoryData: state.patient.socialHistoryData,
        patient: state.patient.patientContext,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        FormActions: bindActionCreators(FormAction, dispatch),
        FamilyHistoryActions: bindActionCreators(FamilyHistoryActions, dispatch),
        SocialHistoryActions: bindActionCreators(SocialHistoryActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyHistoryWindow);