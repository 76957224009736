import {connect} from "react-redux";
import React from "react";
import {bindActionCreators} from "redux";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {PatientAction} from "../../action/PatientAction";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {DictionaryTypeAction} from "../../action/DictionaryTypeAction";
import {PersonActions} from "../../action/PersonAction";
import {CarePlanActions} from "../../action/CarePlanAction";
import ConditionStep from "./CarePlanComponents/ConditionStep";
import DeterminantStep from "./CarePlanComponents/DeterminantStep";
import GoalStep from "./CarePlanComponents/GoalStep";
import ClinicalStrategyStep from "./CarePlanComponents/ClinicalStrategyStep";
import {Dialog} from "primereact/dialog";

const items = [
    {
        label: "Condition",
        icon: "fa fa-medkit",

    },
    {
        label: "Determinants",
        icon: "fa fa-stethoscope",
    },
    {
        label: "Goals",
        icon: "fa fa-bullseye",
    },
    {
        label: "Clinical Strategies",
        icon: "fa fa-heartbeat",
    },
];


const CarePlanComponentWindow = (props) => {
    const {carePlan} = props
    const [problems, setProblems] = React.useState([])
    const [stepIndex, setStepIndex] = React.useState(0)
    const [steps, setSteps] = React.useState(items)
    const [form, setForm] = React.useState({})

    const setFormFromStep = (key, value) => {
        setForm({...form, [key]: value})
    }
    const nextStep = (e) => {
        setStepIndex(stepIndex + 1)
    }

    const previousStep = (e) => {
        setStepIndex(stepIndex - 1)
    }


    const handleChange = (e) => {
        if (e.value < stepIndex) setStepIndex(e.value)
    }
    const hideDialog = async () => {
        await setStepIndex(0)
        await CarePlanActions.clearCarePlanEdit()
        await setForm({})
        await props.CarePlanActions.getCarePlanForPatientContext()
        await props.CarePlanActions.clearCarePlanEdit()
        props.PopupActions.hideWindow(PopupWindows.CARE_PLAN_WINDOW)
    }

    const WindowSequence = [
        <ConditionStep stepIndex={stepIndex} previousStep={previousStep} steps={steps} nextStep={nextStep}
                       hideDialog={hideDialog}
                       setFormFromStep={setFormFromStep}
                       form={form}
                       handleChange={handleChange}/>,
        <DeterminantStep stepIndex={stepIndex} previousStep={previousStep} steps={steps} nextStep={nextStep}
                         hideDialog={hideDialog}
                         setFormFromStep={setFormFromStep}
                         form={form}
                         handleChange={handleChange}/>,
        <GoalStep stepIndex={stepIndex} previousStep={previousStep} steps={steps} nextStep={nextStep}
                  setFormFromStep={setFormFromStep}
                  hideDialog={hideDialog}
                  form={form}
                  handleChange={handleChange}/>,
        <ClinicalStrategyStep stepIndex={stepIndex} previousStep={previousStep} steps={steps} nextStep={nextStep}
                              setFormFromStep={setFormFromStep}
                              hideDialog={hideDialog}
                              form={form}
                              handleChange={handleChange}/>,]

    React.useEffect(() => {
        if (carePlan?.medicalProblems) setProblems(carePlan.medicalProblems)
    }, [carePlan])


    return (
        <Dialog onHide={hideDialog} style={{width:"700px"}} visible={props.showWindow[PopupWindows.CARE_PLAN_WINDOW]} header={"Care Plan"}>
            {WindowSequence[stepIndex]}
        </Dialog>)


}

function mapStateToProps(state) {
    return {
        carePlan: state.patient.carePlan,
        showWindow: state.popupWindow.showWindow,
        patientContext: state.patient.patientContext,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        CarePlanActions: bindActionCreators(CarePlanActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarePlanComponentWindow)