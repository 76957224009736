import * as React from "react";
import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PopupWindowActions, PopupWindows} from "../../../../action/PopupWindowAction";
import ShowForPermissionComponent from "../../../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../../../config/GlobalConfig";
import {Col, Container} from "../../../../layout/GridLayout";
import {PaginatedEndpoint} from "../../../../util/AuthWrapper";
import PhixDataGrid from "../../../../typescript/component/data/PhixDataGrid";
import {GridToolbarContainer, GridToolbarQuickFilter} from "@mui/x-data-grid";
import {IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {AddButton} from "../../../../typescript/component/button/Buttons";

const OrdersResultsComponent = (props) => {
    const dispatch = useDispatch()
    const [filterState, setFilterState] = React.useState({})
    const PatientContext = useSelector(state => state.patient.patientContext)

    const getdata = useCallback(
        PaginatedEndpoint("patient/orders/get", {patientRoleId: PatientContext?.patientRoleId})
        , [PatientContext]
    )
    const header = (

        <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ORDER]}>
            <Col className={"flex-grow-0"}>
                <Button text="Image"
                        onClick={e => PopupWindowActions.showWindow(PopupWindows.CREATE_ORDER_WINDOW)(dispatch)}
                        tooltip={"Create Clinical Order"}
                        size={"small"}
                        icon={"pi pi-plus"}/>
            </Col>

        </ShowForPermissionComponent>

    );

    let columns = [
        {
            field: "dictOrderTypeDisplayName",
            headerName: "Order Type",
            flex: 1,
            // valueFormatter: (e) => FormatUtcDateTimeToLocalZoneStringWithFormat(e.encounterDate)
        },
        {
            field: "patientLastName",
            headerName: "Patient",
            valueGetter: (params) => `${params.row.patientLastName || ''} ${params.row.patientFirstName || ''}`,
            flex: 1
        },
        {field: "orderableItemDisplayName", headerName: "Order", flex: 1},
        {field: "dictOrderStatusDisplayName", headerName: "Order Status", flex: 1},
        {
            field: "orderingUserRoleLastName",
            headerName: "Ordering Provider",
            valueGetter: (params) => `${params.row.orderingUserRoleLastName || ''}, ${params.row.orderingUserRoleFirstName || ''}`,
            flex: 1
        },
        {
            flex: 1,
            field: "dueDate",
            headerName: "Order Due date",
            // renderCell: (item) => {
            //     return <td>{(item.row.isLocked ? <LockIcon style={{color: Colors.GREEN}}/> :
            //         <LockOpenIcon style={{color: Colors.RED}}/>)}
            //     </td>
            // }

        },

    ]

    function OpenOrderWindow(context) {
        PopupWindowActions.showWindow(PopupWindows.CREATE_ORDER_WINDOW, {orderContext: context})(dispatch)
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer container>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ORDER]}>
                    <AddButton tooltip={"Add New Clinical Order"} onClick={e => OpenOrderWindow(undefined)}/>
                </ShowForPermissionComponent>
                <GridToolbarQuickFilter style={{marginLeft: "auto"}} debounceMs={300}/>
            </GridToolbarContainer>
    );
    }


    return (
        <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_ORDER]} component>
            <Container style={{height: "100%"}}>

                <Col col={12} style={{height: "100%"}}>
                    <PhixDataGrid
                        density={"compact"}
                        // enablePolling
                        rows={[]}
                        staticFilter={filterState}
                        // style={{width: "100%", padding: 30, marginLeft: 10, marginTop: 10}}
                        columns={columns}
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={m => console.log(m)}
                        sortModel={[{
                            field: "dueDate",
                            sort: "asc"
                        }]}
                        onRowClick={e => OpenOrderWindow(e.row)}
                        // isRowSelectable={item => item.row.encounterCodingSaved && item.row.isLocked}
                        loadingIndicator
                        apiFunction={getdata}
                        checkboxSelection
                        slots={{toolbar: CustomToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: {debounceMs: 500},
                            },
                        }}
                    />
                    {/*<PhixDataTable*/}
                    {/*    onRowClick={e => {*/}
                    {/*        PopupWindowActions.showWindow(PopupWindows.CREATE_ORDER_WINDOW, {orderContext: e.data})(dispatch)*/}
                    {/*    }}*/}
                    {/*    updateKey={"patientOrders"}*/}
                    {/*    paginatorFn={getdata}*/}
                    {/*    header={header}>*/}
                    {/*    <Column field="dictOrderTypeDisplayName" header="Order Type"></Column>*/}
                    {/*    <Column field="orderableItemDisplayName" header="Order"></Column>*/}
                    {/*    <Column field="dictOrderStatusDisplayName" header="Order Status"></Column>*/}
                    {/*    <Column field="orderingUserRoleLastName" header="Ordered By"*/}
                    {/*            body={o => `${o.orderingUserRoleLastName}, ${o.orderingUserRoleFirstName}`}/>*/}
                    {/*    <Column field="dueDate" header="Due Date"/>*/}


                    {/*</PhixDataTable>*/}
                </Col>

            </Container>


        </ShowForPermissionComponent>
    );
    };

    export default OrdersResultsComponent
