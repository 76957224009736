import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    GET_OBSERVATIONS_THRESHOLD: "GET_OBSERVATIONS_THRESHOLD",
    GET_OBSERVATIONS_THRESHOLD_FOR_COMPONENT: "GET_OBSERVATIONS_THRESHOLD_FOR_COMPONENT",
    ADD_OBSERVATIONS_THRESHOLD: "ADD_OBSERVATIONS_THRESHOLD",
    UPDATE_OBSERVATIONS_THRESHOLD: "UPDATE_OBSERVATIONS_THRESHOLD",
    DEACTIVATE_OBSERVATIONS_THRESHOLD: "DEACTIVATE_OBSERVATIONS_THRESHOLD",
    SET_OBSERVATIONS_THRESHOLD: "SET_OBSERVATIONS_THRESHOLD",
    GET_SAVED_OBSERVATIONS_THRESHOLDS: "GET_SAVED_OBSERVATIONS_THRESHOLDS",
}


function getObservationThresholdsById(dictObservationId) {
    return WrapAuthentication('observation/threshold/get', {
            payload: {dictObservationId: dictObservationId}
        },
        ActionTypes.GET_OBSERVATIONS_THRESHOLD
    )
}

function getObservationThresholdsByObservationCode(observationCode, component) {
    return WrapAuthentication('observation/threshold/get/code', {
            payload: {observationCode: observationCode}
        },
        ActionTypes.GET_OBSERVATIONS_THRESHOLD_FOR_COMPONENT, { component: component }
    )
}

function addObservationThresholds(observationThresholds) {
    return WrapAuthentication('observation/threshold/create', {
            payload: observationThresholds
        },
        ActionTypes.ADD_OBSERVATIONS_THRESHOLD
    )
}

function updateObservationThresholds(observationThresholds) {
    return WrapAuthentication('observation/threshold/update', {
            payload: observationThresholds
        },
        ActionTypes.UPDATE_OBSERVATIONS_THRESHOLD
    )
}

function deactivateObservationThresholds(id) {
    return WrapAuthentication('observation/threshold/deactivate', {
            payload: {id: id}
        },
        ActionTypes.DEACTIVATE_OBSERVATIONS_THRESHOLD
    )
}

function getSavedObservationThresholds() {
    return WrapAuthentication('observation/threshold/get/all', {
            payload: {}
        },
        ActionTypes.GET_SAVED_OBSERVATIONS_THRESHOLDS
    )
}

function setObservationThresholdsToEmpty(id) {
    return async dispatch =>
        dispatch({type: ActionTypes.SET_OBSERVATIONS_THRESHOLD, payload: {id: id}})
}

export const ObservationsThresholdAction = {
    getObservationThresholdsByObservationCode: getObservationThresholdsByObservationCode,
    getObservationThresholdsById: getObservationThresholdsById,
    addObservationThresholds: addObservationThresholds,
    updateObservationThresholds: updateObservationThresholds,
    deactivateObservationThresholds: deactivateObservationThresholds,
    setObservationThresholdsToEmpty: setObservationThresholdsToEmpty,
    getSavedObservationThresholds: getSavedObservationThresholds,
}