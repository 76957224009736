import {PostApi} from "../util/AuthWrapper";

export const ActionTypes = {
    LABORATORY_GET_LABS: "LABORATORY_GET_LABS",
    LABORATORY_GET_INSTALLED_LABS: "LABORATORY_GET_INSTALLED_LABS",
    LABORATORY_GET_ROUTES: "LABORATORY_GET_ROUTES",

}

async function getDornLaboratories() {
    let response = await PostApi('laboratory/dorn/get/available/labs', {payload: {}})
    return {type: ActionTypes.LABORATORY_GET_LABS, payload: response?.payload}
}


async function getInstalledDornLaboratories() {
    let response = await PostApi('laboratory/dorn/get/installed/labs', {payload: {}})
    return {type: ActionTypes.LABORATORY_GET_INSTALLED_LABS, payload: response?.payload}
}

async function installDornLaboratory(guid) {
    let response = await PostApi('laboratory/dorn/install/compendium', {payload: guid})
    return response
}

async function getInstallRoutes() {
    let response = await PostApi('laboratory/dorn/get/routes', {})

    return {type: ActionTypes.LABORATORY_GET_ROUTES, payload: response?.payload}
}

async function createRoute(payload) {
    let response = await PostApi('laboratory/dorn/install/route', {payload: payload})
    return response
}

export const LaboratoryAction = {
    getDornLaboratories: getDornLaboratories,
    getInstalledDornLaboratories: getInstalledDornLaboratories,
    installDornLaboratory: installDornLaboratory,
    getInstallRoutes: getInstallRoutes,
    createRoute: createRoute

}