import React from 'react';
import {bindActionCreators} from "redux";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {UserActions} from "../../action/UserAction";
import {connect, useDispatch} from "react-redux";
import {Window} from "@progress/kendo-react-dialogs";
import {Button} from "primereact/button";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {orderBy} from "@progress/kendo-data-query";
import {PatientAction} from "../../action/PatientAction";
import {useHistory} from "react-router-dom";

const PatientListOnGraph = (props) => {
    const history = useHistory()


    const {patients} = props
    const initialSort = [
        {
            field: "lastName",
            dir: "asc",
        },
    ];
    const hideDialog = () => {
        props.UserActions.clearSetPieGraphContext()
        props.PopupActions.hideWindow(PopupWindows.PATIENT_LIST_FOR_PIE)
    }
    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });
    const [sort, setPatientSort] = React.useState(initialSort);
    const [data, setData] = React.useState([])


    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    React.useEffect(() => {
        setData(patients?.length > 0 ? patients : [])
    }, [patients])

    return (
        props.showWindow[PopupWindows.PATIENT_LIST_FOR_PIE] ?
            <Window draggable={true}
                    modal={true}
                    onClose={hideDialog}
                    initialTop={200}
                // minHeight={500}
                    initialWidth={600}
                    resizable={true}
                    title={"Patient Details"}
                    >
                <div className="col-12" style={{width: "100%", height: "100%"}}>
                    <Grid
                        style={{height: "100%"}}
                        data={orderBy(data, sort)}
                        pageSize={dataState.limit}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setPatientSort(e.sort);
                        }}
                        skip={dataState.skip}
                        onRowClick={(e) => {
                            props.PatientActions.clearPatientContext().then(() => {
                                    props.PatientActions.setPatientContext(e.dataItem).then(() => {
                                        hideDialog()
                                        history.push(`/patientPanel`)
                                    })

                                    //Lets use the context pattern here.

                                    hideDialog();

                                }
                            )

                        }}
                        // pageable={{
                        //     buttonCount: 10,
                        //     info: true,
                        //     type: "numeric",
                        //     pageSizes: [50, 100, 150],
                        //     previousNext: true,
                        // }}

                        onDataStateChange={dataStateChange}

                    >
                        <GridColumn field="lastName" title="Last Name"/>
                        <GridColumn field="firstName" title="First Name"/>
                        <GridColumn field="dictGenderName" title="Gender"/>

                    </Grid>
                </div>
            </Window> : null
    )
}

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        patients: state.user.getPatientsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
        PatientActions: bindActionCreators(PatientAction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientListOnGraph)