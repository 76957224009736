import React from 'react';
import {Menubar} from "primereact/menubar"
import {connect} from 'react-redux';
import {PatientAction} from "../../../action/PatientAction";
import {UserActions} from "../../../action/UserAction";
import {PopupWindowActions, PopupWindows,} from "../../../action/PopupWindowAction";
import {bindActionCreators} from "redux";
import moment from "moment";
import {PhixPermissions} from "../../../config/GlobalConfig";
import ShowForPermissionComponent from "../../shared/ShowForPermissionComponent";
import {Col, Container, Row} from "../../../layout/GridLayout";

const DocumentInfoComponent = (props) => {
    const {selectedDocument} = props;
    const [edit, setEdit] = React.useState(false);

    React.useEffect(() => {
        props.PatientActions.setClinicalDocumentContext({...props.clinicalDocumentContext, ...props?.clinicalDocumentDownload})
    }, [props.clinicalDocumentDownload])

    React.useEffect(() => {
        const payload = {
            fileReferenceId: selectedDocument?.fileReferenceId
        }
        props.PatientActions.clinicalDocumentDownload(payload)
    }, [selectedDocument])

    React.useEffect(() => {
        const record = props?.getClinicalDocument?.find((i) => i.clinicalDocumentId === props?.clinicalDocumentContext?.clinicalDocumentId)
        props.PatientActions.setClinicalDocumentContext({...props.clinicalDocumentContext, ...record})
    }, [props.getClinicalDocument])

    const menuItems = [
        {
            label: "Edit Document",
            command: () => {
                setEdit(true);
                props.PatientActions.setClinicalDocumentContext({
                    ...props.clinicalDocumentContext,
                    setEdit: setEdit,
                    edit: true
                })
                props.PopupActions.showWindow(PopupWindows.CREATE_DOCUMENT_WINDOW);
            }

        },
        {
            label: props.showDocument ? "Hide" : "Show",
            command: () => props.showDocument ? props.setShowDocument(false) : props.setShowDocument(true)
        }
    ]

    return (
        <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_DOCUMENT]}>
            <Container>

                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_DOCUMENT]}>
                    <Col col={12}>
                        <Menubar model={menuItems} style={{height: 40, color: "black"}}/>
                    </Col>
                </ShowForPermissionComponent>

                <Row gutter style={{padding: "10px"}}>
                    <Col>
                        File Name : {' '}
                        <span>{props?.clinicalDocumentDownload?.fileName}</span>
                    </Col>
                    <Col>
                        Uploaded : {' '}
                        {/* 'America/New_York' */}
                        {props?.clinicalDocumentContext?.personName ?
                            <span>{moment.utc(props?.clinicalDocumentContext?.uploadedDate).local().format('MMM DD, YYYY h:mm A')} By {props?.clinicalDocumentContext?.personName}</span> : ("")}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            props?.clinicalDocumentContext?.encounterID ? 'Encounter: ' : ''
                        }
                        <a>{props?.clinicalDocumentContext?.encounterID}</a>
                    </Col>
                </Row>
                {/* <Row style={{ marginTop: "10px" }}>
                    <Col>
                        Encounter : {' '}
                        <a>{props?.clinicalDocumentDownload?.fileChunkSize}</a>
                    </Col>
                </Row> */}
            </Container>
        </ShowForPermissionComponent>
    )
}

function mapStateToProps(state) {
    return {
        clinicalDocumentDownload: state.patient.clinicalDocumentDownload,
        clinicalDocumentContext: state.patient.clinicalDocumentContext,
        patientContext: state.patient.patientContext,
        editClinicalDocument: state.patient.editClinicalDocument,
        getClinicalDocument: state.patient.getClinicalDocument,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentInfoComponent);
