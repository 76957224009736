import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
import {entries} from "d3-collection";
import {QualityMeasureActions} from "../../action/QualityMeasureActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Components} from "../shared/ComponentRegistry";
import TileHeader from "../../layout/TileHeader";
import GlobalStyles from "../../config/GlobalStyles";
import {Col, Container, Row} from "react-grid-system";
import PropTypes from "prop-types";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import DictionaryDropDownList, {Dictionary,} from "../InputControls/DictionaryDropDownList";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Button} from "primereact/button";
import ShowForPermissionComponent from '../shared/ShowForPermissionComponent';
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {PhixPermissions} from "../../config/GlobalConfig";

var i = 0;
let svg;

function BarChart({width, height, data, color, onClickBarChart, field}) {
    const ref = useRef();

    useEffect(() => {
        svg = d3
            .select(ref.current)
            .attr("width", width)
            .attr("height", (data.length + 1) * 25);
    });

    useEffect(() => {
        draw();
    }, [data]);

    const draw = () => {
        const svg = d3.select(ref.current),
            lefttext = 150,
            topshift = 20;
        var selection = svg
            .append("g")
            .attr("transform", "translate(0," + topshift + ")")
            .selectAll("rect")
            .data(data);

        var xScale = d3.scaleLinear().domain([0, 100]).range([0, 300]);

        selection
            .enter()
            .append("rect")
            .attr("y", (d, i) => i * 25)
            .attr("x", 0) // (d) => height)
            .attr("height", 20)
            .attr("width", 0)
            .attr("fill", color.backcolor)
            .on("click", function (d, i) {
                onClickBarChart.call(this, d);
            })
            .transition()
            .duration(300)
            .attr("width", (d) => width - lefttext)
            .attr("x", (d) => lefttext);

        selection
            .enter()
            .append("rect")
            .attr("y", (d, i) => i * 25)
            .attr("x", (d) => 0)
            .attr("height", 20)
            .attr("width", 0)
            .attr("fill", color.main)
            .on("click", function (d, i) {
                onClickBarChart.call(this, d);
            })
            .transition()
            .duration(300)
            .attr("width", (d) => xScale(d[field]))
            .attr("x", (d) => lefttext);

        var legend_text = svg
            .append("g")
            .attr("transform", "translate(0," + (topshift + 14) + ")")
            .selectAll("legend_text")
            .data(data)
            .enter();
        let tempbechmark = 80;

        legend_text
            .append("text")
            .attr("class", "legend_text")
            .style("font-size", "25px")
            .style("font-family", "WebComponentsIcons")
            .attr("y", (d, i) => i * 25)
            .attr("x", (d) => xScale(d.benchaMark) + lefttext - 12)
            .attr("dy", "0.32em")
            .style("font-weight", "bold")
            .text("\ue006");

        var legend_text2 = svg
            .append("g")
            .attr("transform", "translate(0," + (topshift + 10) + ")")
            .selectAll("legend_text2")
            .data(data)
            .enter();

        legend_text2
            .append("text")
            .attr("class", "legend_text2")
            .style("font-size", "10px")
            .style("font-family", '"Lato", Helvetica, sans-serif')
            .style("fill", "#333447")
            .attr("y", (d, i) => i * 25)
            // .attr("x", (d) => width - d.benchmark)
            .attr("x", (d) => 0)
            .attr("dy", "0.32em")
            .style("font-weight", "bold")
            .text((d) => d.description);

        var legend_text3 = svg.append("g").attr("transform", "translate(0,10)");

        legend_text3
            .append("text")
            .attr("class", "legend_text3")
            .style("font-size", "10px")
            .style("font-family", '"Lato", Helvetica, sans-serif')
            .style("fill", "#333447")
            .attr("y", 0)
            .attr("x", 150)
            .attr("dy", "0.32em")
            .style("font-weight", "bold")
            .text((d) => "% Cohort Performance vs Benchmark");

        selection
            .exit()
            .transition()
            .duration(300)
            .attr("y", (d) => height)
            .attr("height", 0)
            .remove();
    };
    const chartstyle = {
        height: "100%",
        overflowX: "auto",
        overflowY: "scroll",
        textAlign: "center",
    };
    return (
        <div className="chart" style={chartstyle}>
            <svg ref={ref}/>
        </div>
    );
}

function PieChart({width, height, data, color, onClickPieChart, field}) {
    var margin = {top: 30, right: 60, bottom: 30, left: 60},
        width = 264 - margin.left - margin.right,
        height = 264 - margin.top - margin.bottom,
        tooltip = {width: 100, height: 100, x: 10, y: -30};

    var pie;

    const ref = useRef();

    useEffect(() => {
        svg = d3
            .select(ref.current)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr(
                "transform",
                "translate(" + (width + 110) / 2 + "," + height / 2 + ")"
            );
    });

    useEffect(() => {
        if (data[0].value) draw();
    }, [data]);

    const draw = () => {
        pie = d3
            .pie()
            .sort(null)
            .value((d) => d.value.value);

        var arc = d3
            .arc()
            .innerRadius(0)
            .outerRadius(Math.min(width, height) / 2 - 1);

        const radius = Math.min(width, height) / 2 - 1;

        var arcLabel = function () {
            return d3.arc().innerRadius(0).outerRadius(radius);
        };

        var color = d3
            .scaleOrdinal()
            .domain(data.map((d) => d.category))
            .range(
                d3
                    .quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), data.length)
                    .reverse()
            );
        var arcs = pie(entries(data));

        const getText = (i, value) => {
            let total = data[0].value + data[1].value;
            let pacentage = (100 * value) / total;
            let tooltip;
            if (i === 0) {
                tooltip = "Open " + pacentage + "%";
            } else {
                tooltip = "Up to date " + pacentage + "%";
            }
            return tooltip;
        };

        svg
            .append("g")
            .attr("stroke", "white")
            .selectAll("path")
            .data(arcs)
            .enter()
            .append("path")
            .attr("fill", (d) => d.data.value.color)
            .on("click", function (d, i) {
                onClickPieChart.call(this, d);
            })
            .attr("d", arc)
            .append("title")
            .text((d, i) => `${getText(i, d.data.value.value)}`);

        var outerArc = d3
            .arc()
            .innerRadius(radius * 0.9)
            .outerRadius(radius * 0.9);

        svg
            .selectAll("allPolylines")
            .data(arcs)
            .enter()
            .append("polyline")
            .attr("stroke", "black")
            .style("fill", "none")
            .attr("stroke-width", 1)
            .attr("points", function (d) {
                var posA = arc.centroid(d);
                var posB = outerArc.centroid(d);
                var posC = outerArc.centroid(d);
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1);
                return [posA, posB, posC];
            });
        svg
            .selectAll("allLabels")
            .data(arcs)
            .enter()
            .append("text")
            .style("font-size", "8px")
            .text(function (d, i) {
                return `${getText(i, d.data.value.value)}`;
            })
            .attr("transform", function (d) {
                var pos = outerArc.centroid(d);
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
                return "translate(" + pos + ")";
            })
            .style("text-anchor", function (d) {
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                return midangle < Math.PI ? "start" : "end";
            });
    };
    const chartstyle = {
        height: "100%",
        overflowX: "auto",
        overflowY: "scroll",
        textAlign: "center",
    };
    return (
        <div className="chart" style={chartstyle}>
            <svg ref={ref}></svg>
        </div>
    );
}

const QualityMeasureCareTeamComponent = ({
                                     PopupActions,
                                     QualityMeasureActions,
                                     loggedinUser,
                                     qmcohart,
                                     qmcohartlist,
                                     cohortType,
                                     dropdownvalues,
                                     dropdownvaluesdefaultValue,
                                     seconddropdowndisplayName,
                                     workflowStatus,
                                 }) => {
    // const [workflow, setWorkflow] = React.useState("piechart");
    const setWorkFlow = QualityMeasureActions.setWorkFlow;
    const series = [
        {
            category: "Measures Below",
            value: qmcohart.qMBelowBenchmarkCount,
            color: "#E06666",
        },
        {
            category: "Measures Above",
            value: qmcohart.qMMetBenchmarkCount,
            color: "#6AA84F",
        },
    ];
    const styles = {
        measureBelow: {color: "#E06666", fontSize: "24px"},
        measureAbove: {color: "#6AA84F", fontSize: "24px"},
    };

    const openNewRequestwindow = (obj) => {
        QualityMeasureActions.getMeasureCohortDetails(obj.dictionaryId);
        QualityMeasureActions.setMeasureSelection(obj.description);
        PopupActions.showWindow(PopupWindows.QUALITY_MEASURE_WINDOW);
    };
    const getsetStates = (endpoint, displayName) => {
        QualityMeasureActions.setdropdownvalues(endpoint, displayName);
    };

    const onChangeCohortDictionary = (obj) => {
        QualityMeasureActions.setcohortType(obj.value);
        switch (obj.value.code) {
            case "AH_GEO_REGION":
                getsetStates("QM/geoList", "geoDisplayName");
                break;
            case "AH_GEO_REGION_1":
                getsetStates("QM/geoList", "geoDisplayName");
                break;
            case "AH_GEO_REGION_2":
                getsetStates("QM/geoList", "geoDisplayName");
                break;
            case "AH_MY_PATIENTS":
                getPatient();
                break;
            case "AH_MY_TEAM":
                getTeam();
                break;
            case "AH_SERVICE_ORGANIZATION":
                getsetStates("QM/serviceOrganizationList", "geoDisplayName");
                break;
            case "AH_SERVICE_ORGANIZATION_A":
                getsetStates("QM/serviceOrganizationList", "geoDisplayName");
                break;
            case "AH_SERVICE_ORGANIZATION_B":
                getsetStates("QM/serviceOrganizationList", "geoDisplayName");
                break;
            case "AH_STATE":
                getsetStates("QM/stateList", "stateDisplayName");
                break;
            default:
                return "";
        }
    };
    const onChangeSeconddefaultvalue = (obj) => {
        QualityMeasureActions.setseconddropdefaultvalue(obj.value);
        if (obj.value.serviceOrganizationId) {
            QualityMeasureActions.getserviceOrganizationMeasureCohort(
                obj.value.serviceOrganizationId
            ).then((res) => {
                if (res && res.payload.patientRoleId)
                    QualityMeasureActions.getMeasureCohortList(res.payload.patientRoleId);
            });
        } else if (obj.value.stateId) {
            QualityMeasureActions.getstateMeasureCohort(obj.value.stateId).then(
                (res) => {
                    if (res && res.payload.patientRoleId)
                        QualityMeasureActions.getMeasureCohortList(
                            res.payload.patientRoleId
                        );
                }
            );
        } else if (obj.value.geoGraphicId) {
            QualityMeasureActions.getgeoGraphicMeasureCohort(
                obj.value.geoGraphicId
            ).then((res) => {
                if (res && res.payload.patientRoleId)
                    QualityMeasureActions.getMeasureCohortList(res.payload.patientRoleId);
            });
        }
    };

    const PieChartComponent = (props) => {
        const {
            dropdownvalues,
            onChangeSeconddefaultvalue,
            dropdownvaluesdefaultValue,
            showChart,
        } = props;
        return (
            <Container style={GlobalStyles.panelContainer}>
                <Row style={{height: "50px"}}>
                    <Col lg={12} style={{padding: "2px"}}>
                        <DictionaryDropDownList
                            value={props.cohortType}
                            textField={"displayName"}
                            style={{height: "30px"}}
                            code={Dictionary.AH_COHORT}
                            className={"k-checkbox"}
                            onChange={props.onChangeCohortDictionary}
                        />
                        {dropdownvalues && dropdownvalues.length > 0 && (
                            <DropDownList
                                style={{padding: "2px"}}
                                data={dropdownvalues}
                                onChange={onChangeSeconddefaultvalue}
                                value={dropdownvaluesdefaultValue}
                                textField={seconddropdowndisplayName}
                                filterable={true}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col lg={5} style={{height: "calc(100% - 50px)", paddingRight: 0}}>
                        <Container style={{padding: 0}}>
                            <Row gutterWidth={20}>
                                <Col lg={2}>
                                    <b style={styles.measureBelow}>
                                        {qmcohart.qMBelowBenchmarkCount}
                                    </b>
                                </Col>
                                <Col lg={10}>
                                    <span style={{fontSize: "16px"}}>Measures below Benchmark</span>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={2}>
                                    <b style={styles.measureAbove}>
                                        {qmcohart.qMMetBenchmarkCount}
                                    </b>
                                </Col>
                                <Col lg={10} style={{height: "100%", paddingTop: "5px"}}>
                                    <span style={{fontSize: "16px"}}>Measures Above Benchmark</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <br/> <br/> <br/>
                                    <span style={{fontSize: "8px", position: "absolute", bottom: 0}}>Meeting Benchmark does not indicate 100%</span>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col lg={7} style={{height: "100%", padding: 0}}>
                        <PieChart
                            width={450}
                            data={series}
                            color={"red"}
                            onClickPieChart={showChart ? props.onClickPieChart : () => {
                            }}
                            field={"qmBelowPerc"}
                        />
                    </Col>

                </Row>
            </Container>
        );
    };
    const BelowMeasureBarChart = ({
                                      payload = [],
                                      field,
                                      cohortType,
                                      dropdownvalues,
                                      dropdownvaluesdefaultValue,
                                  }) => {
        const onClickBarChart = (event, e) => {
            openNewRequestwindow(event.currentTarget.__data__);
        };
        const color = {main: "#cc0101", backcolor: "#ec9995"};
        return (
            <div style={{height: "100%", marginTop: "2px"}}>
                <Container>
                    <Row style={{height: "50px"}}>
                        <Col lg={10} style={{padding: "2px"}}>
                            <DictionaryDropDownList
                                disabled={true}
                                value={cohortType}
                                textField={"displayName"}
                                style={{width: "100px", height: "30px"}}
                                code={Dictionary.AH_COHORT}
                                className={"k-checkbox"}
                                onChange={onChangeCohortDictionary}
                            />
                            &nbsp;
                            {dropdownvalues && dropdownvalues.length > 0 && (
                                <DropDownList
                                    disabled={true}
                                    style={{padding: "2px"}}
                                    data={dropdownvalues}
                                    value={dropdownvaluesdefaultValue}
                                    textField={seconddropdowndisplayName}
                                    filterable={true}
                                />
                            )}
                        </Col>

                        <Col lg={2}>
                            {" "}
                            <Button
                                onClick={() => {
                                    setWorkFlow("piechart");
                                }}
                            >
                                Back
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <div className="App" style={{height: "calc(100% - 48px)"}}>
                    <BarChart
                        width={450}
                        style={{height: "100%"}}
                        data={payload}
                        color={color}
                        onClickBarChart={onClickBarChart}
                        field={"qmBelowPerc"}
                    />
                </div>
            </div>
        );
    };
    const MetMeasureBarChart = ({
                                    payload = [],
                                    field,
                                    cohortType,
                                    dropdownvalues,
                                    dropdownvaluesdefaultValue,
                                }) => {
        const onClickBarChart = (event, e) => {
            openNewRequestwindow(event.currentTarget.__data__);
        };
        const color = {main: "#6AA84F", backcolor: "#bdd8b1"};
        return (
            <div style={{height: "100%", marginTop: "2px"}}>
                <Container>
                    <Row style={{height: "50px"}}>
                        <Col lg={10} style={{padding: "2px"}}>
                            <DictionaryDropDownList
                                disabled={true}
                                value={cohortType}
                                textField={"displayName"}
                                style={{width: "100px", height: "30px"}}
                                code={Dictionary.AH_COHORT}
                                className={"k-checkbox"}
                                onChange={onChangeCohortDictionary}
                            />
                            {dropdownvalues && dropdownvalues.length > 0 && (
                                <DropDownList
                                    disabled={true}
                                    style={{padding: "2px"}}
                                    data={dropdownvalues}
                                    value={dropdownvaluesdefaultValue}
                                    textField={seconddropdowndisplayName}
                                    filterable={true}
                                />
                            )}
                        </Col>
                        <Col lg={2}>
                            <Button
                                onClick={() => {
                                    setWorkFlow("piechart");
                                }}
                            >
                                Back
                            </Button>
                        </Col>

                    </Row>
                </Container>
                <div className="App" style={{height: "calc(100% - 48px)"}}>
                    <BarChart
                        width={450}
                        style={{height: "100%"}}
                        data={payload}
                        color={color}
                        onClickBarChart={onClickBarChart}
                        field={"qmMetPerc"}
                    />
                </div>
            </div>
        );
    };
    const onClickPieChart = (event) => {
        if (event.currentTarget.__data__.index == 0) {
            setWorkFlow("redbarchart");
        } else {
            setWorkFlow("greenbarchart");
        }
    };

    let element, props;
    if (workflowStatus === "piechart" && qmcohartlist) {
        props = {
            cohortType,
            dropdownvalues,
            onClickPieChart,
            dropdownvaluesdefaultValue,
            onChangeCohortDictionary,
            onChangeSeconddefaultvalue,
            showChart: qmcohartlist.qmBelowPerc ? true : false,
        };
        element = <PieChartComponent {...props} />;
    } else if (
        workflowStatus === "redbarchart" &&
        qmcohartlist &&
        qmcohartlist.qmBelowPerc
    ) {
        props = {
            dropdownvalues,
            dropdownvaluesdefaultValue,
            cohortType,
            payload: qmcohartlist.qmBelowPerc,
        };
        element = <BelowMeasureBarChart {...props} />;
    } else if (
        workflowStatus === "greenbarchart" &&
        qmcohartlist &&
        qmcohartlist.qmMetPerc
    ) {
        props = {
            dropdownvalues,
            dropdownvaluesdefaultValue,
            cohortType,
            payload: qmcohartlist.qmMetPerc,
        };
        element = <MetMeasureBarChart {...props} />;
    }
    const getPatient = () => {
        QualityMeasureActions.getPatientMeasureCohort(loggedinUser.userRoleId).then(
            (res) => {
                if (res && res?.payload?.patientRoleId)
                    QualityMeasureActions.getMeasureCohortList(res?.payload?.patientRoleId);
            }
        );
    };

    const getTeam = () => {
        QualityMeasureActions.getTeamMeasureCohort(
            loggedinUser.managerUserRoleId
        ).then((res) => {
            if (res && res.payload.patientRoleId)
                QualityMeasureActions.getMeasureCohortList(res.payload.patientRoleId);
        });
    };
    useEffect(() => {
        getPatient();
    }, [loggedinUser.userRoleId]);
    return (
        <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_PATIENT]}>
            <TileContainer>
                <TileHeader
                    title="Quality Measures"
                    expanded={Components.QualityMeasureComponent}
                    style={{fontSize: "15px"}}
                    image={"../images/quality.png"}
                />
                <TileBody>
                    {element}
                </TileBody>
            </TileContainer>
        </ShowForPermissionComponent>
    );
};

QualityMeasureCareTeamComponent.propTypes = {
    loggedinUser: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        loggedinUser: state.user.loggedinUser,
        qmcohart: state.QualityMeasure.MeasureCohort,
        qmcohartlist: state.QualityMeasure.MeasureCohortList,
        cohortType: state.QualityMeasure.cohortType,
        dropdownvalues: state.QualityMeasure.seconddropdown.dropdownvalues,
        seconddropdowndisplayName: state.QualityMeasure.seconddropdown.displayName,
        dropdownvaluesdefaultValue:
        state.QualityMeasure.seconddropdown.defaultValue,
        workflowStatus: state.QualityMeasure.workflow,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        QualityMeasureActions: bindActionCreators(QualityMeasureActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityMeasureCareTeamComponent)
