import {useSelector, useDispatch} from "react-redux";
import React, {Fragment, useEffect} from "react";
import {stringOrJson} from "../../util/MiscTools";
import {Container} from "react-grid-system";
import validator from '@rjsf/validator-ajv8';
import {CustomWidgets} from "./CustomWidgets";
import Form from "@rjsf/mui";
import moment from "moment";
import {FormAction} from "../../action/FormAction";


function FormRendererComponent(props) {
    const [schema, setSchema] = React.useState({})
    const dispatch = useDispatch()
    const [formData, setFormData] = React.useState({})
    const {show} = props
    const editFormContext = useSelector(state => state.form.editFormContext)
    const [uiSchema, setUiSchema] = React.useState({})
    useEffect(() => {
        if (editFormContext?.formSchema) {
            setSchema(stringOrJson(editFormContext.formSchema))
            setUiSchema(stringOrJson(editFormContext.formUiSchema))
            setFormData(stringOrJson(editFormContext.formData))
        }
    }, [editFormContext])

    if (schema)
        return (
            <Container style={{width: "90%"}}>
                <Form
                    widgets={CustomWidgets}
                    style={{height: "100%"}}
                    onChange={(e) => {
                            const d = {...e.formData}
                            if ((editFormContext?.code === "ah_social_history" || editFormContext?.code === "ah_family_history" ) &&  e.formData?.reviewed === true && !e.formData?.review_date){
                                d.review_date = moment().format('YYYY-MM-DD')
                                editFormContext.formData = d;
                                setFormData(d)
                            } else {
                                editFormContext.formData = e.formData
                            }
                        }
                    }
                    schema={schema}
                    uiSchema={uiSchema}
                    formData={formData}
                    validator={validator}
                    disabled={show}>
                    <Fragment/>
                </Form>
            </Container>
        )
    else return null


}


export default FormRendererComponent
