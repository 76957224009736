import {Window} from "@progress/kendo-react-dialogs";
import React from "react";

const PopupWindow = (props) => {
    const {show, children, handleHide, initialWidth = 800, initialHeight = 400, title} = props
    if (show)
        return (
            <Window modal
                    title={title}
                    initialHeight={initialHeight}
                    initialWidth={initialWidth}
                    onClose={handleHide}>
                {children}
            </Window>
        )
    else return null

}

export default PopupWindow