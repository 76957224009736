import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    CARE_PLAN_GET_SUCCESS: "CARE_PLAN_GET_SUCCESS",

    CARE_PLAN_ADD_GOAL_SUCCESS: "CARE_PLAN_ADD_GOAL_SUCCESS",
    CARE_PLAN_EDIT_CLEAR: "CARE_PLAN_EDIT_CLEAR",
    CARE_PLAN_EDIT_SET_CONDITION: "CARE_PLAN_EDIT_SET_CONDITION",
    CARE_PLAN_EDIT_SET_DETERMINANTS: "CARE_PLAN_EDIT_SET_DETERMINANTS",
    CARE_PLAN_EDIT_SET_GOALS: "CARE_PLAN_EDIT_SET_GOALS",
    CARE_PLAN_EDIT_GOALS: "CARE_PLAN_EDIT_GOALS",
    CARE_PLAN_DELETE_GOALS: "CARE_PLAN_DELETE_GOALS",
}

// function getCarePlanForPatientContext() {
//     return async (dispatch, getState) => {
//         let patientContext = getState().patient?.patientContext
//         if (patientContext?.patientRoleId) {
//             return WrapAuthentication("careplan/model/get", {
//                 payload: {patientRoleId: patientContext.patientRoleId},
//             }, ActionTypes.CARE_PLAN_GET_SUCCESS, "ERROR")(dispatch)
//         } else {
//             return async (dispatch, getState) => {
//                 dispatch({type: "ERROR", message: "No patient Context for care plan."})
//             }
//         }
//     }
// }

function getCarePlanForPatientContext(limit, offset, sort) {
    return async (dispatch, getState) => {
        let patientContext = getState().patient?.patientContext
        if (patientContext?.patientRoleId) {
            return WrapAuthentication("careplan/model/get", {
                filter: {patientRoleId: patientContext.patientRoleId},
                payload: {"allPatients": true},
                sort: sort ? {sorts: sort} : null,
                limit: limit,
                offset: offset
            }, ActionTypes.CARE_PLAN_GET_SUCCESS)(dispatch)
        } else {
            return async (dispatch, getState) => {
                dispatch({type: "ERROR", message: "No patient Context for care plan."})
            }
        }
    }
}

function addConditionToCarePlan(carePlanId, medicalProblemId) {
    return async (dispatch, getState) => {
        return WrapAuthentication("careplan/problem/create",
            {
                payload: {carePlanId, medicalProblemId}
            },
            ActionTypes.CARE_PLAN_ADD_GOAL_SUCCESS)(dispatch)
    }
}

function getDeterminantsForProblemEdit(medicalProblemId) {
    return async (dispatch, getState) => {
        return WrapAuthentication("careplan/determinant/get",
            {
                payload: {medicalProblemId: medicalProblemId}
            },
            ActionTypes.CARE_PLAN_EDIT_SET_DETERMINANTS)(dispatch)
    }
}

function addDeterminantForProblemEdit(medicalProblemId, dictDeterminantId) {
    return async (dispatch, getState) => {
        return WrapAuthentication("careplan/determinant/add",
            {
                payload: {
                    medicalProblemId: medicalProblemId,
                    dictDeterminantId: dictDeterminantId
                }
            },
            ActionTypes.CARE_PLAN_EDIT_SET_DETERMINANTS)(dispatch)
    }
}

function removeDeterminantForProblemEdit(determinantId) {
    return async (dispatch, getState) => {
        return WrapAuthentication("careplan/determinant/delete",
            {
                payload: {
                    determinantId: determinantId
                }
            },
            ActionTypes.CARE_PLAN_EDIT_SET_DETERMINANTS)(dispatch)
    }
}


function getGoalsForProblemEdit(medicalProblemId) {
    return async (dispatch, getState) => {
        return WrapAuthentication("careplan/goal/get",
            {
                payload: {medicalProblemId: medicalProblemId}
            },
            ActionTypes.CARE_PLAN_EDIT_SET_GOALS)(dispatch)
    }
}


function addGoalToCondition(medicalProblemId, description, dictGoalStatusId, dictGoalCategoryId) {
    return async (dispatch, getState) => {
        return WrapAuthentication("careplan/goal/add",
            {
                payload: {medicalProblemId, description, dictGoalStatusId, dictGoalCategoryId, active: true},
            },
            ActionTypes.CARE_PLAN_EDIT_SET_GOALS)(dispatch)
    }
}

function updateCarePlanGoal(carePlanId, medicalProblemId, description, dictGoalStatusId, dictGoalCategoryId) {
    return async (dispatch, getState) => {
        // console.log({carePlanId, medicalProblemId, description, dictGoalStatusId, dictGoalCategoryId})
        return WrapAuthentication("careplan/goal/update",
            {
                payload: {carePlanId, medicalProblemId, description, dictGoalStatusId, dictGoalCategoryId},
            },
            ActionTypes.CARE_PLAN_EDIT_SET_GOALS)(dispatch)
    }
}

function deleteCarePlanGoal(carePlanGoalId) {
    return async (dispatch, getState) => {
        return WrapAuthentication("careplan/goal/delete",
            {
                payload: {carePlanGoalId: carePlanGoalId},
            },
            ActionTypes.CARE_PLAN_EDIT_SET_GOALS)(dispatch)
    }
}

function addStrategyToGoal(carePlanGoalId, dictGoalStrategyId, medicalProblemId, description) {

    return async (dispatch, getState) => {
        return WrapAuthentication("careplan/clinical/strategy/add",
            {
                payload: {carePlanGoalId, medicalProblemId, description, dictGoalStrategyId, active: true}
            },
            ActionTypes.CARE_PLAN_EDIT_SET_GOALS)(dispatch)
    }
}

function updateStrategyToGoal(strategyId, dictGoalStrategyId, medicalProblemId, carePlanGoalId, description) {
    let payload = {id: strategyId, dictGoalStrategyId, medicalProblemId, carePlanGoalId, description, active: true}
    // console.log(payload)
    return async (dispatch, getState) => {
        return WrapAuthentication("careplan/clinical/strategy/update",
            {
                payload: {
                    id: strategyId,
                    dictGoalStrategyId,
                    medicalProblemId,
                    carePlanGoalId,
                    description,
                    active: true
                }
            },
            ActionTypes.CARE_PLAN_EDIT_SET_GOALS)(dispatch)
    }
}

function deleteStrategyToGoal(clinicalStrategyId, medicalProblemId) {

    return async (dispatch, getState) => {
        return WrapAuthentication("careplan/clinical/strategy/delete",
            {
                payload: {clinicalStrategyId, medicalProblemId}
            },
            ActionTypes.CARE_PLAN_EDIT_SET_GOALS)(dispatch)
    }
}

function addProblemToCarePlan(carePlanId, medicalProblemId) {

    return async (dispatch, getState) => {
        return WrapAuthentication("careplan/problem/create",
            {
                payload: {carePlanId, medicalProblemId}
            },
            ActionTypes.CARE_PLAN_EDIT_SET_CONDITION)(dispatch)
    }
}

// EDIT Care Plan
function setEditCarePlanCondition(condition) {
    return async dispatch => {
        dispatch({type: ActionTypes.CARE_PLAN_EDIT_SET_CONDITION, payload: condition})
    }
}

function setEditCarePlanDeterminants(determinants) {
    return async dispatch => {
        dispatch({type: ActionTypes.CARE_PLAN_EDIT_SET_DETERMINANTS, payload: {payload: determinants}})
    }
}

function setEditCarePlanGoals(goals) {
    return async dispatch => {
        dispatch({type: ActionTypes.CARE_PLAN_EDIT_SET_GOALS, payload: {payload: goals}})
    }
}

function clearCarePlanEdit() {
    return async dispatch => {
        dispatch({type: ActionTypes.CARE_PLAN_EDIT_CLEAR, payload: {}})
    }
}


export const CarePlanActions =
    {
        getCarePlanForPatientContext,
        getDeterminantsForProblemEdit,
        addDeterminantForProblemEdit,
        removeDeterminantForProblemEdit,
        clearCarePlanEdit,
        getGoalsForProblemEdit,

        setEditCarePlanCondition,
        setEditCarePlanDeterminants,
        addConditionToCarePlan,
        addGoalToCondition,
        updateCarePlanGoal,
        addStrategyToGoal,
        updateStrategyToGoal,
        deleteStrategyToGoal,
        addProblemToCarePlan,
        deleteCarePlanGoal,
        setEditCarePlanGoals,

    }