import {Col, Container, Row} from "react-grid-system";
import * as React from "react";
import {Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartTooltip} from "@progress/kendo-react-charts";
import {PopupWindowActions, PopupWindows} from "../action/PopupWindowAction";
import {UserActions as UserAction} from "../action/UserAction";
import {useDispatch} from "react-redux";


const searchExp = (searchTerms) => new RegExp(searchTerms.reduce((acc, term) => acc ? `${acc}|(?:${term})` : `(?:${term})`, ''), 'gi');

function newSearchExp(searchTerms) {
    return searchTerms.reduce((acc, term) => {
        let not = term.not.join("|")
        let match = term.match.join("|")
        let regex = `^((?!${not}))(?:${match})`
        return acc ? `${acc}${regex}` : regex
    }, "")
}

export function AverageObservationsByDay(data) {
    let reducedData = data.reduce((acc, item) => {
        let k = item.patientRoleId

        if (acc === undefined) {
            acc = {}
        }
        if (acc.hasOwnProperty(k)) {
            acc[k] = {...acc[k], value: acc[k].value + item.value, count: acc[k].count.add(item.effectiveStartDate)}
        } else {
            let count = new Set()
            count.add(item.effectiveStartDate)
            acc[k] = {...item, count}
        }
        return acc
    }, {})
    let i = []
    console.log(reducedData)
    Object.keys(reducedData).forEach((k) => {
        console.log(` days: ${reducedData[k].count.size}`)
        i.push({...reducedData[k], value: reducedData[k].value / reducedData[k].count.size})
    })
    return i
}

// export function SumObservationsByDay(data) {
//     let reducedData = data.reduce((acc, item) => {
//         let k = item.patientRoleId
//         if (acc === undefined) {
//             acc = {}
//         }
//         if (acc.hasOwnProperty(k)) {
//             acc[k] = {...acc[k], value: acc[k].value + item.value}
//         } else {
//             acc[k] = {...item, count: 1}
//         }
//         return acc
//     }, {})
//     let i = []
//     Object.keys(reducedData).forEach((k) => {
//         i.push(reducedData[k])
//     })
//     return i
// }

const sortObservations = (a, sort) => {

    a.sort((a, b) => {
        const bMatch = b.description.match(searchExp(sort)) || [];
        const aMatch = a.description.match(searchExp(sort)) || [];
        return bMatch.length - aMatch.length;
    });
    return a
}

export function CalculateCohortObservationThresholds(data, thresholds, sort = ["^((?!low|normal))high", "^((?!high|normal))low"]) {
    let search = [{not: ["normal", "low"], match: ["high"]}]
    let regex = newSearchExp(search)
    console.log(`Search expression: ${JSON.stringify(regex)}`)
    let response = []
    thresholds.forEach(t => {
        let {rangeText, rangeColor, dictOperatorDisplayName, value, description} = t
        let thresholdFn = GetFunctionForOperator(dictOperatorDisplayName, value)
        let patients = new Set()
        data.forEach(dItem => {
            if (thresholdFn(dItem.value)) {
                patients.add(dItem.patientRoleId)
            }
        })
        response.push({
            rangeText,
            rangeColor,
            dictOperatorDisplayName,
            description,
            value,
            count: [...patients].length,
            patients: [...patients],
            color: rangeColor
        })
    })
    return sortObservations(response, sort)
}

function GetFunctionForOperator(operator, thresholdValue) {
    switch (operator) {
        case ">=" :
            return (v) => parseFloat(v) >= parseFloat(thresholdValue.value)
        case "<=":
            return (v) => parseFloat(v) <= parseFloat(thresholdValue.value)
        case ">" :
            return (v) => parseFloat(v) > parseFloat(thresholdValue.value)
        case "<":
            return (v) => parseFloat(v) < parseFloat(thresholdValue.value)
        case "==":
            return (v) => parseFloat(v) === parseFloat(thresholdValue.value)
        case "between" :
            return (v) => parseFloat(v) < parseFloat(thresholdValue.max) && parseFloat(v) > parseFloat(thresholdValue.min)
        case "..." :
            return (v) => parseFloat(v) < parseFloat(thresholdValue.max) && parseFloat(v) > parseFloat(thresholdValue.min)
        default:
            return (v) => {
                console.error(`No function for operator: ${operator} Threshold: ${JSON.stringify(thresholdValue)}`)
                return false
            }

    }

}

export function ThresholdObservationRow(props) {
    const {rangeColor, count, rangeText} = props

    return (<>
        <Row key={props.id}>
            <Col lg={3} style={{
                flex: '0 0 20%',
                maxWidth: '20%',
            }}>
                <b style={{
                    padding: '2px',
                    position: 'relative',
                    top: '2px',
                    fontSize: '21px',
                    color: rangeColor || "#085394"
                }}>
                    {count}
                </b>
            </Col>
            <Col lg={9} style={{
                height: "20%",
                paddingTop: "4px",
                paddingLeft: "1px",
                paddingRight: "1px",
                lineHeight: '1',
                width: '80%',
                flex: '0 0 80%',
                maxWidth: '80%',
            }}>
                <span style={{fontSize: "16px"}}>{rangeText}</span>
            </Col>

        </Row>
    </>)
}

export function AeriosChartToolTip(props) {
    return <ChartTooltip shared={true}
                         render={(e) => e.point?.dataItem.rangeText}/>
}

export function ThresholdChart(props) {
    const dispatch = useDispatch()
    const {thresholds} = props
    const handleClick = (e) => {
        UserAction.getPatientsList({patientRoleId: e.dataItem.patients})(dispatch)
        PopupWindowActions.showWindow(PopupWindows.PATIENT_LIST_FOR_PIE)(dispatch)
    }

    return (<Col lg={6}
                 style={{
                     marginTop: '-48px',
                     width: '35%',
                     flex: '0 0 35%',
                     maxWidth: '35%',
                     paddingRight: '60px'
                 }}>
        <Chart onSeriesClick={handleClick}
               style={{width: '100%', height: '100%'}}>
            <ChartArea background="#FFF" height="200" width="150"/>
            <ChartLegend visible={false}/>
            <ChartSeries>
                <ChartSeriesItem
                    type="donut" data={thresholds} field="count"
                    categoryField="description"
                    labels={{
                        visible: false,
                        content: ''
                    }}>
                    <AeriosChartToolTip/>
                </ChartSeriesItem>
            </ChartSeries>

        </Chart>
    </Col>)
}

export function ThresholdList(props) {
    const {thresholds} = props
    return (<Col lg={6} style={{
        width: '65%',
        flex: '0 0 65%',
        maxWidth: '65%',
    }}>
        <Container fluid style={{padding: 0, height: "100%"}}>
            {thresholds.map((t) => {
                return (<ThresholdObservationRow key={t.description} {...t}/>)
            })}
        </Container>
    </Col>)
}