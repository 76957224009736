import React, {useEffect} from "react";
import {Label} from "@progress/kendo-react-labels";
import {useDispatch, useSelector} from "react-redux";
import {FacilityAction} from "../../action/FacilityAction";
import {ComboBox, DropDownList} from "@progress/kendo-react-dropdowns";


const FacilityDropDownList = (props) => {
    const dispatch = useDispatch()
    const {dataItem, idField, type, labelName = "Select Facility", defaultValue} = props
    const facilities = useSelector(state => state.facility.facilities)

    const [data, setData] = React.useState(facilities.list);
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(defaultValue ? defaultValue : {})
    const [filter, setFilter] = React.useState("")

    const handleChange = (e) => {
        setValue(e.target.value)
        if (props.onChange) {
            props.onChange(e)
        }
    }
    //
    const filterChange = (event) => {
        console.log(event.filter.value)
        setFilter(event.filter.value)
    };
    useEffect(() => {
        FacilityAction.getFacilityList(1000, 0, null, filter)(dispatch)
    }, [])

    useEffect(() => {
        FacilityAction.getFacilityList(1000, 0, null, filter)(dispatch)
    }, [filter])

    // useEffect(() => {
    //     setData(facilities.list);
    //     setValue(value)
    // }, [facilities.list.length])

    useEffect(() => {
        setData(facilities.list)
    }, [facilities.list])

    useEffect(() => {
            if (dataItem) {
                let id = dataItem.hasOwnProperty(idField) ? dataItem[idField] : "facilityId"
                let value = dataItem && data ? data.find((c) => c.id === id) : null
                setValue(value)
            }
        }
        , [data, dataItem])

    const Element = type === "checkbox" ? ComboBox : DropDownList;
    return (
        <div className={"form-field-facility"}>
            {labelName && <Label>{labelName}</Label>}
            <div className={"k-form-field-wrap"}>
                <Element
                    filterable={true}
                    onFilterChange={filterChange}
                    value={value}
                    data={data}
                    placeholder="Select facility"
                    dataItemKey={"id"}
                    textField="displayName"
                    loading={loading}
                    {...props}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};


export default FacilityDropDownList