import {ActionTypes as EncounterAction} from '../action/EncounterAction';

const initialState = {
    encounterContext: {},
    encounterDocuments:[],
    encounterFormConfig: [],
    dictionaries:{},
    encounterCoding:{},
    assessments:[],
    esignEncounters:[],
    medicalProblemId:null,
    medicationId:null,
    allergyId:null,
    hospitalizationId:null,
    immunizationId:null,
    pdfData:null
}

export const encounterReducer = (state = initialState, action) => {

    switch (action.type) {
        case EncounterAction.SAVE_ENCOUNTER:
            return {...state, encounterContext: action.payload.payload};
        case EncounterAction.SAVE_ENCOUNTER_SIGNATURE:
            return {...state, encounterSignedStatus: action.payload.payload};
        case EncounterAction.UPDATE_ENCOUNTER:
            return {...state, encounterContext: action.payload.payload};
        case EncounterAction.SET_ENCOUNTER_CONTEXT:
             return {...state, encounterContext: action.payload};
        case EncounterAction.SAVE_ENCOUNTER_PATIENT_INSTRUCTIONS:
            if (action?.payload?.payload?.id)
                return {...state, encounterContext: action.payload.payload};
            else
                return state;
        case EncounterAction.GET_ALL_ENCOUNTER_DOCUMENTS:
            return {...state, encounterDocuments: action.payload.payload};
        case EncounterAction.CLEAR_ENCOUNTER:
            return {...state, encounterContext: {}, encounterSignedStatus: null, medicalProblems: [], medications: [], allergies:[], hospitalizations: [], immunizations: []};
        case EncounterAction.GET_ALL_ENCOUNTER_FORM_CONFIGURATION:
            return {...state, encounterFormConfig: action.payload.payload};
        case EncounterAction.CLEAR_ALL_ENCOUNTER_FORM_CONFIGURATION:
            return {...state, encounterFormConfig: []};
        case EncounterAction.ENCOUNTER_GET_DICTIONARY_BY_CODE:
            return {...state, dictionaries: {...state.dictionaries, [action.code]: action.payload.payload}, pagination: {...state.dictionaries.pagination, [action.code]: {totalCount: action.payload.totalCount}} }
        case EncounterAction.GET_ALL_ENCOUNTER_CODING_SERVICE_LINE:
            return {...state, encounterCoding: {...state.encounterCoding, 'serviceLines': action.payload.payload}}
        case EncounterAction.CLEAR_ALL_ENCOUNTER_CODING_SERVICE_LINE:
            return {...state, encounterCoding: {...state.encounterCoding, 'serviceLines': []}}
        case EncounterAction.GET_ENCOUNTER_CODING:
            return {...state, encounterCoding: {...state.encounterCoding, 'codingContext': action.payload.payload}}
        case EncounterAction.CLEAR_ENCOUNTER_CODING:
            return {...state, encounterCoding: {}}
       case EncounterAction.ASSESSMENT_LIST_ASSESSMENTS_ENCOUNTER:
            return {...state, assessments: action.payload.payload}
       case EncounterAction.GET_ALL_ENCOUNTER_ESIGN:
            return {...state, esignEncounters: action.payload.payload}
       case EncounterAction.ENCOUNTER_PDF_DATA:
            return {...state, pdfData: action.payload.payload}
       case EncounterAction.MEDICAL_PROBLEM_ID:
            return {...state, medicalProblemId: action.payload}
       case EncounterAction.MEDICATION_ID:
            return {...state, medicationId: action.payload}
       case EncounterAction.ALLERGY_ID:
            return {...state, allergyId: action.payload}
       case EncounterAction.HOSPITALIZATION_ID:
            return {...state, hospitalizationId: action.payload}
       case EncounterAction.IMMUNIZATION_ID:
            return {...state, immunizationId: action.payload}
       case EncounterAction.CLEAR_CREATED_ISSUES:
            return {...state, medicalProblemId: null, allergyId: null, hospitalizationId: null, medicationId: null}
       case EncounterAction.SAVE_ENCOUNTER_MEDICAL_PROBLEM:
            return {...state, medicalProblems: action.payload}
       case EncounterAction.SAVE_ENCOUNTER_MEDICATION:
            return {...state, medications: action.payload}
       case EncounterAction.SAVE_ENCOUNTER_ALLERGY:
            return {...state, allergies: action.payload}
       case EncounterAction.SAVE_ENCOUNTER_HOSPITALIZATION:
            return {...state, hospitalizations: action.payload}
       case EncounterAction.SAVE_ENCOUNTER_IMMUNIZATION:
            return {...state, immunizations: action.payload}
       case EncounterAction.GET_ENCOUNTER_MEDICAL_PROBLEM:
            return {...state, medicalProblems: action.payload}
       case EncounterAction.GET_ENCOUNTER_MEDICATION:
            return {...state, medications: action.payload}
       case EncounterAction.GET_ENCOUNTER_ALLERGY:
            return {...state, allergies: action.payload}
       case EncounterAction.GET_ENCOUNTER_HOSPITALIZATION:
            return {...state, hospitalizations: action.payload}
       case EncounterAction.GET_ENCOUNTER_IMMUNIZATION:
            return {...state, immunizations: action.payload}
        default:
            return state;
    }

}