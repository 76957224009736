import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import FormPreviewWindow from "../form/FormRendererComponent";
import {Col, Container, Row} from "react-grid-system";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Button} from "primereact/button";
import {Toolbar} from "@progress/kendo-react-buttons";
import {bindActionCreators} from "redux";
import {FormAction} from "../../action/FormAction";
import {Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import {useForm} from "react-hook-form";
import {Field, FieldDictionaryDropDown, FieldRow, FieldSection, FieldTextInput} from "../InputControls/FormFields";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {Checkbox, Input} from "@progress/kendo-react-inputs";
import AeriosFormBuilder from "./PhixFormBuilder";
import {Tab, Tabs} from "@mui/material";
import {PhixPermissions} from "../../config/GlobalConfig";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";

const formBuilderTiles = [
    {
        defaultPosition: {
            col: 1,
            colSpan: 4,
            rowSpan: 6,
        },
        body: <AeriosFormBuilder/>,
        header: "Form Builder",
    },

];


const styles = {
    container:
        {
            width: "95%",
            height: "calc(100vh - 90px)",
            overflowY: "auto",
        },
    grid: {
        height: "100%"
    },
    toolbar: {
        width: "100%"
    },
    row: {
        width: "100%",
    },
    gridRow: {
        width: "100%",
        height: "calc(100% - 100px)"
    },
}

function NewFormWindow(props) {
    const {show, handleForm, hideWindow} = props
    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        watch,
        control,
        getValues,
        setValue,
        formState: {errors, isDirty, isSubmitting, touchedFields, submitCount},
    } = useForm();

    function onSubmit(form) {
        if (handleForm)
            handleForm(form)
        closeDialog()
    }

    function closeDialog() {
        reset()
        hideWindow()
    }

    if (show)
        return (<Window title={"Create new form"} onClose={hideWindow}>
            <FieldSection>
                <FieldRow>
                    <Field control={control} col={12} label={"Form Code"} field={"code"}
                           register={register}
                           error={errors} options={{required: "Form code is required."}}>
                        <FieldTextInput/>
                    </Field>
                </FieldRow>
                <FieldRow>
                    <Field control={control} col={12} label={"Form Name"} field={"displayName"}
                           register={register}
                           error={errors} options={{required: "Form Name is required."}}>
                        <FieldTextInput/>
                    </Field>
                </FieldRow>
                <FieldRow>
                    <Field control={control} col={12} label={"Form Type"} field={"dictFormCategoryId"}
                           register={register}
                           error={errors} options={{required: "Form type is required"}}>
                        <FieldDictionaryDropDown code={Dictionary.AH_FORM_CATEGORY}/>
                    </Field>
                </FieldRow>

            </FieldSection>
            <WindowActionsBar>
                <Button

                    onClick={handleSubmit(onSubmit)}>

                    Create </Button>
                <Button onClick={closeDialog}>
                    Cancel
                </Button>

            </WindowActionsBar>
        </Window>)
    else
        return null

}

function AdminFormBuilderPage(props) {
    const {FormActions, formList} = props
    const [showPreview, setShowPreview] = useState(false)
    const [showNewFormWindow, setShowNewFormWindow] = useState(false)
    const [showEditor, setShowEditor] = useState(false)
    const [data, setData] = useState([])
    const [count, setCount] = useState()
    const [formContext, setFormContext] = useState({})
    const [filter, setFilter] = useState({})
    const [tabSelected, setTabSelected] = useState(0)


    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });
    const [searchState, setSearchState] = React.useState("")
    const [sort, setSort] = React.useState([{
        field: "displayName",
        dir: "asc",
    }]);

    useEffect(() => {
        FormActions.getAdminFormSchemaList(dataState.take, dataState.skip, sort, filter)
        FormActions.getFormSchemaList(dataState.take, dataState.skip, sort, filter)
    }, [dataState, sort, filter])

    useEffect(() => {
        setData(formList.payload)
        setCount(formList.count)
    }, [formList])

    useEffect(() => {
        if (searchState === "")
            setFilter({})
        else
            setFilter({displayName: searchState})
    }, [searchState])

    async function handleForm(form) {
        await FormActions.saveNewFormSchema(form.code, form.displayName, form.dictFormCategoryId.id)
        FormActions.getAdminFormSchemaList(dataState.take, dataState.skip, sort, null)
    }


    const EditFormAction = (props) => {
        return <td className="k-command-cell">
            <Button icon={"pi pi-pencil"}
                    text
                    onClick={() => {
                        setFormContext(props.dataItem)
                        FormActions.setEditFormContext(props.dataItem)
                        setTabSelected(2)
                        setShowEditor(true)

                    }}/>
        </td>;
    }

    function createForm() {
        setShowNewFormWindow(true)
    }

    function IsActiveCheckBox(item) {
        return <Checkbox value={item.dataItem.active}/>
    }


    function FormGridSelector(props) {
        return <Container style={styles.container}>
            <Row style={styles.row}>
                <Col sm={12}>
                    <Toolbar style={styles.toolbar}>
                        <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_FORM]}>
                            <Button icon={"pi pi-plus"} text onClick={createForm}/>
                        </ShowForPermissionComponent>
                        <Input
                            style={{width: "50%"}}
                            placeholder={"Search"}
                            id={"input-id"}
                            value={searchState}
                            onChange={(e) => setSearchState(e.value)}
                        />
                    </Toolbar>
                </Col>
            </Row>
            <Row style={styles.gridRow}>
                <Col sm={12}>
                    <Grid style={styles.grid}
                          pageSize={dataState.take}
                          sortable={true}
                          skip={dataState.skip}
                          total={count}
                          onSortChange={(e) => {
                              setSort(e.sort);
                          }}
                          onDataStateChange={(e) => setDataState(e.dataState)}
                          pageable={{
                              buttonCount: 10,
                              info: true,
                              type: "numeric",
                              pageSizes: [50, 100, 150],
                              previousNext: true,
                          }}
                          sort={sort}
                          data={data}>
                        <GridColumn field="action" title="Action" cell={EditFormAction} width={"60px"}
                                    filterable={false}/>
                        <GridColumn field={"displayName"} title={"Form Name"}/>
                        <GridColumn field={"description"} title={"Description"}/>
                        <GridColumn field={"dictFormCategoryDisplayName"} title={"Form Type"}/>
                        <GridColumn field={"active"} title={"Active"} cell={IsActiveCheckBox}/>
                        <GridColumn field={"revision"} title={"Revision"}/>

                    </Grid>

                </Col>

            </Row>
            <NewFormWindow show={showNewFormWindow}
                           handleForm={handleForm}
                           hideWindow={() => setShowNewFormWindow(false)}/>
        </Container>;
    }

    function TabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <div style={{paddingTop: 10, height: "calc(100vh - 100px)", overflow: "scroll"}}
                 hidden={value !== index}
                 id={`simple-tabpanel-${index}`}
                 aria-labelledby={`simple-tab-${index}`}
            >
                {value === index && (
                    <div>
                        {children}
                    </div>

                )}
            </div>
        );
    }

    return <ShowForPermissionComponent component permissions={[PhixPermissions.AH_ADMIN_VIEW_FORM]}>
        <Container style={{height: "100%", width: "100%"}}>
            <Row>
                <Tabs
                    value={tabSelected}
                    onChange={(e, value) => setTabSelected(value)}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Form Selector"/>
                    <Tab label="Form Preview"/>
                    <Tab label="Form Editor"/>
                    {/*<Tab label="New Forms"/>*/}
                </Tabs>
            </Row>

            <TabPanel value={tabSelected} index={0}>
                <FormGridSelector/>
            </TabPanel>
            <TabPanel value={tabSelected} index={1}>
                <FormPreviewWindow formContext={formContext}/>
            </TabPanel>
            <TabPanel value={tabSelected} index={2}>
                <AeriosFormBuilder setTabSelected={setTabSelected} dataState={dataState} sort={sort} show={showEditor}
                                   FormActions={FormActions}
                                   hideWindow={() => setShowEditor(false)}/>
            </TabPanel>
        </Container>
    </ShowForPermissionComponent>
}


function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        formList: state.form.adminFormList,
        editFormContext: state.form.editFormContext
    };
}

function mapDispatchToProps(dispatch) {
    return {
        FormActions: bindActionCreators(FormAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminFormBuilderPage)
