import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {CareTeamAction} from "../action/CareTeamAction";
import {UserActions} from "../action/UserAction";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {Button} from "primereact/button"
import {Checkbox, Input} from "@progress/kendo-react-inputs";

import {Dictionary} from "./InputControls/DictionaryDropDownList";
import {InlineDictionaryDropDown} from "./InputControls/InlineFields";

import {
    Field,
    FieldDictionaryDropDown,
    FieldRow,
    FieldTextAreaInput,
    FieldUserDropDown
} from "./InputControls/FormFields";
import {useForm} from "react-hook-form";
import ShowForPermissionComponent from "./shared/ShowForPermissionComponent";
import {PhixPermissions} from "../config/GlobalConfig";
import {Col, Container} from "../layout/GridLayout";
import {Dialog} from "primereact/dialog";

const initialSort = [
    {
        field: "Name",
        dir: "asc",
    },
];

const InlineCareTeamRoleDropDown = (props) => {

    return <InlineDictionaryDropDown
        field={"dictCareTeamRoleId"}
        onChange={props.onChange}
        dataItem={props.dataItem}
        idField={"dictCareTeamRoleId"}
        displayField={"careTeamRoleDescription"}
        code={"AH_CARETEAM_ROLE"}/>
}

const PatientCareTeamListComponent = (props) => {
    const {patientContext, careTeamContext, careTeamList, userTeamList} = props
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [sort, setSort] = React.useState(initialSort);
    const [data, setData] = React.useState([]);
    const [searchState, setSearchState] = React.useState("")
    const [editCareTeamMember, setEditCareTeamMember] = React.useState([]);

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: {errors,}
    } = useForm({defaultValues: {active: true}});


    const filterData = (d) => {
        if (searchState.length > 0) {
            return data.filter(d =>
                d.firstName.toLowerCase().includes(searchState.toLowerCase()) ||
                d.lastName.toLowerCase().includes(searchState.toLowerCase()) ||
                d.careTeamRoleName.toLowerCase().includes(searchState.toLowerCase())
            )
        } else {
            return d
        }
    }

    React.useEffect(() => {
        if (props.careTeamList) {
            const newData = props.careTeamList.map(v => {
                const isExist = data.find(is => is.id === v.id);
                if (isExist) {
                    return {...v, isActive: v.careTeamRoleActive, inEdit: isExist.inEdit};
                } else {
                    return {...v, isActive: v.careTeamRoleActive};
                }
            });
            setData(newData)
        }
    }, [props.careTeamList])

    React.useEffect(() => {
            props.CareTeamActions.getCareTeamContext(patientContext.patientRoleId)
        }, [patientContext]
    )
    React.useEffect(() => {
            if (careTeamContext?.careTeamId) {
                props.CareTeamActions.getCareTeamList(careTeamContext.careTeamId)
            }
        }, [careTeamContext]
    )

    const name = (props) => {
        const {firstName = "", middleName = "", lastName = ""} = props.dataItem;
        const fullName = `${firstName}${middleName ? ` ${middleName.charAt(0)}.` : ''}${lastName ? ` ${lastName}` : ''}`;
        return props.dataItem.inEdit ? <td>
            <span>{fullName}</span>
        </td> : <td><span>{fullName}</span></td>;
    }

    const active = (props) => {
        let disable = false;
        if (props.dataItem.inEdit === undefined || props.dataItem.inEdit === false) {
            disable = true;
        }
        return <td><Checkbox disabled={!props.dataItem.isActive || disable} id={props.dataItem.patientRoleId}
                             value={props.dataItem.careTeamRoleActive}
                             onChange={(event) => handleActiveCheckbox(event, props)}/></td>
    }
    const handleActiveCheckbox = (event, props) => {
        let isChecked = event.target.value
        let onChange = {
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: event.syntheticEvent,
            value: isChecked
        }
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value
            });
        }
    }


    const addRecord = () => {
        setVisibleDialog(!visibleDialog);
    };


    const handleSubmitClick = (form) => {
        // console.log(form)
        let payload = {
            careTeamId: careTeamContext ? careTeamContext.careTeamId : '',
            userRoleId: form?.userRoleId?.userRoleId,
            dictCareTeamRoleId: form?.dictCareTeamRoleId?.id,
            createdByUserRoleId: localStorage.getItem('userRoleId'),
            notes: form?.notes,
            active: true,
        }
        props.CareTeamActions.createCareTeamMember(payload)
            .then(() => {
                props.CareTeamActions.getCareTeamList(careTeamContext.careTeamId)
                reset()
                setVisibleDialog(!visibleDialog)
            })
    }
    const MyCommandCell = props => {
        const {dataItem} = props;
        const inEdit = dataItem[props.editField];
        return inEdit ? <td className="k-command-cell">
            <Container className={"justify-content-end"}>
                <Col className={"flex-grow-0"}>
                    <Button icon={"pi pi-save"} size={"small"} onClick={() => props.update(dataItem)}/>
                </Col>
                <Col className={"flex-grow-0"}>
                    <Button icon={"pi pi-times-circle"} size={"small"} severity={"danger"}
                            onClick={() => props.cancel(dataItem)}/>
                </Col>

            </Container>
        </td> : <td className="k-command-cell">
            <Container className={"align-items-center"}>
                <Col className={"flex-grow-0"}>
                    <Button size={"small"} icon={"pi pi-pencil"}
                            tooltip={"Edit care team member"}
                            onClick={() => props.edit(dataItem)}/>
                </Col>
            </Container>
        </td>;
    };
    const getItems = () => {
        return data;
    };


    const update = dataItem => {
        const payload = {
            "id": dataItem.id,
            "careTeamId": dataItem.careTeamId,
            "userRoleId": dataItem.userRoleId,
            "dictCareTeamRoleId": dataItem.careTeamRoleName.id || dataItem.dictCareTeamRoleId,
            "notes": dataItem.notes,
            "active": dataItem.careTeamRoleActive
        }
        props.CareTeamActions.updateCareTeamMember(payload)
            .then(() => {
                setData(data.map(item => item.id === dataItem.id ? {
                    ...item,
                    inEdit: false
                } : item));
                props.CareTeamActions.getCareTeamList(dataItem.careTeamId)
            })
    };

    const cancel = dataItem => {
        const originalItem = getItems().find(p => p.id === dataItem.id);
        const newData = data.map(item => item.id === originalItem.id ? originalItem : item);
        setData(newData.map(item => item.id === dataItem.id ? {
            ...item,
            inEdit: false
        } : item));
    };
    const enterEdit = dataItem => {
        setData(data.map(item => item.id === dataItem.id ? {
            ...item,
            inEdit: true
        } : item));
    };
    const itemChange = event => {
        const newData = data.map(item => item.id === event.dataItem.id ? {
            ...item,
            [event.field || '']: event.value
        } : item);
        setData(newData);
    };
    const CommandCell = props => <ShowForPermissionComponent
        permissions={[PhixPermissions.AH_EDIT_CARETEAM]}>
        <MyCommandCell
            {...props} edit={enterEdit} update={update} cancel={cancel}
            editField="inEdit"/></ShowForPermissionComponent>

    return (
        <ShowForPermissionComponent component={true} permissions={["AH_VIEW_CARETEAM"]}>
            <Container style={{height: "100%", width: "100%"}}>
                <Col col={12}>
                    <Toolbar>
                        <Input
                            style={{width: "50%"}}
                            placeholder={"Search"}
                            id={"input-id"}
                            value={searchState}
                            onChange={(e) => setSearchState(e.value)}
                        />
                        <ToolbarSpacer/>
                        <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_CARETEAM]}>
                            <ToolbarItem>
                                <Button
                                    size={"small"}
                                    icon="pi pi-plus-circle"
                                    text
                                    onClick={addRecord}
                                    label={"Add Care Team Member"}
                                    style={{

                                        color: "#ffffff",
                                        backgroundColor: "#7585c2",

                                    }}/>
                            </ToolbarItem>
                        </ShowForPermissionComponent>
                    </Toolbar>
                </Col>
                <Col col={12} style={{height: "calc(100% - 50px)"}}>
                    <Grid style={{height: "100%"}}
                          sortable={true}
                          data={filterData(data)}
                          editField="inEdit"
                          onItemChange={itemChange}
                          sort={sort}
                    >
                        <GridColumn field="patientFirstName" title="Name" cell={name} editable={false}/>
                        <GridColumn field="careTeamRoleName" title="Role/Relationship" editable={false}/>
                        <GridColumn field="phone" title="Phone" editable={false}/>
                        <GridColumn field="email" title="Email" editable={false}/>
                        <GridColumn field="notes" title="Notes"/>
                        <GridColumn field="careTeamRoleActive" title="Active" cell={active}/>

                        <GridColumn cell={CommandCell}
                                    width="110px"/>
                    </Grid>
                </Col>

                <Dialog visible={visibleDialog} header={"Add Care Team Member "} onHide={() => setVisibleDialog(false)}
                        maximizable
                        style={{width: 800}}
                        footer={<Button size={"small"}
                                        label={"Add Care Team Member"}
                                        onClick={handleSubmit(handleSubmitClick)}/>}>
                    <Container>
                        <FieldRow>
                            <Field col={6} label={"Care Team Role"} field={"dictCareTeamRoleId"} error={errors}
                                   register={register}
                                   control={control}
                            >
                                <FieldDictionaryDropDown code={Dictionary.AH_CARETEAM_ROLE}
                                                         dataItem={editCareTeamMember}/>
                            </Field>
                            <Field col={6} label={"Assigned Provider"} field={"userRoleId"} error={errors}
                                   control={control}
                                   register={register}
                                   options={{required: "Assigned provider is required"}}>
                                <FieldUserDropDown/>
                            </Field>
                        </FieldRow>
                        <FieldRow>
                            <Field col={12} label={"Notes"} field={"notes"} error={errors}
                                   control={control}
                                   register={register}>
                                <FieldTextAreaInput>

                                </FieldTextAreaInput>
                            </Field>
                        </FieldRow>

                    </Container>
                </Dialog>
            </Container>
        </ShowForPermissionComponent>)

};

PatientCareTeamListComponent.propTypes =
    {
        CareTeamActions: PropTypes.object,
    }

function mapStateToProps(state) {
    return {
        careTeamList: state.patient.careTeam.careTeamList,
        totalCount: state.patient.careTeam.totalCount,
        patientCareGetList: state.patient.careTeam.patientCareGetList,
        patientContext: state.patient.createPatientContext,
        careTeamContext: state.patient.careTeam.careTeamContext,
        userTeamList: state.user.userList,
        permissionInfo: state.auth.session.permissions


    };
}

function mapDispatchToProps(dispatch) {
    return {
        CareTeamActions: bindActionCreators(CareTeamAction, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientCareTeamListComponent)