import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {MultiColumnComboBox} from "@progress/kendo-react-dropdowns";
import {InsuranceActions} from "../../action/InsuranceAction";
import _ from "lodash";

export const InsurancePlanComboBox = (props) => {
    const {required, value, defaultItemId} = props
    const [data, setData] = useState([])
    const [filter, setFilter] = React.useState()
    const [internalValue, setInternalValue] = useState(defaultItemId ? defaultItemId : null)
    const dispatch = useDispatch()
    const insurancePlans = useSelector(s => s.insurance?.insurancePlans ? s.insurance.insurancePlans : [])

    const columns = [
        {field: "dictInsuranceProviderDisplayname", header: "Provider Name"},
        {field: "planName", header: "Plan Name"}
    ]

    function mapPlanName(v) {
        return {...v, name: v.dictInsuranceProviderDisplayname + ": " + v.planName}
    }

    useEffect(() => {
        if (data?.length > 0) {
            if (value?.id && internalValue?.id !== value?.id) {
                setInternalValue(value)
            } else if (!_.isNil(value) && _.isNil(internalValue)) {
                let v = data ? data.find((c) => c.id === value) : undefined

                if (!_.isNil(v)) {
                    setInternalValue(mapPlanName(v))

                }

            }
        }


    }, [data, value])

    useEffect(() => {
        if (data?.length > 0) {
            let v = data ? data.find((c) => c.id === defaultItemId) : undefined
            if (!_.isNil(v)) {
                setInternalValue(mapPlanName(v))
            }
        }
    }, [data, defaultItemId])

    const filterChange = (event) => {
        setFilter(event.filter.value)
        //setData(filterData(event.filter));
    };
    const handleChange = (e) => {
        if (e.value.id) {
            setInternalValue(e.target.value)
            if (props.onChange) {
                props.onChange(e)
            }
        }
    }

    useEffect(() => {
        InsuranceActions.getInsurancePlans(1000, 0, null, {planName: filter, active: true})(dispatch)
    }, [filter])


    useEffect(() => {
        let d = insurancePlans.map(p => {
            return mapPlanName(p)
        })
        setData(d);
    }, [insurancePlans])


    return (
        <MultiColumnComboBox columns={columns}
                             data={data}
                             textField={"name"}
                             style={props.style}
                             required={required}
                             disabled={props.disabled}
                             value={internalValue}
                             filterable={true}
                             onFilterChange={filterChange}
                             onChange={handleChange}
        />

    )

}