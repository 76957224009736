import * as React from "react";
import {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import ShowForPermissionComponent from './shared/ShowForPermissionComponent';
import PermissionGroupConfigurationComponent from './PermissionGroupConfigurationComponent';
import TelehealthConfigurationComponent from './TelehealthConfigurationComponent';
import {PhixPermissions} from "../config/GlobalConfig";
import {useForm} from "react-hook-form";
import {Field, FieldDropDown, FieldNumberInput, FieldRow, FieldSection} from "./InputControls/FormFields";
import {SecuritySettingsAction} from "../action/SecuritySettingsAction";
import {LayoutAction} from "../action/LayoutAction";
import {Col, Container} from "../layout/GridLayout";
import {Slide} from "@progress/kendo-react-animation";
import {Divider} from "primereact/divider";


const TenantConfigurationComponent = (props) => {

    const {
        register,
        control,
        setValue,
        watch,
        formState: {errors},
    } = useForm({defaultValues: {active: true}});
    const permissions = useSelector(state => state.auth.session.permissions)
    const [editable, setEditable] = useState(false)
    useEffect(() => setEditable(permissions.includes(PhixPermissions.AH_ADMIN_EDIT_TENANT_CONFIGURATION)), [editable])
    const [showPasswordSettings, setShowPasswordSettings] = useState(false)
    const [showPermissionGroupConfig, setShowPermissionGroupConfig] = useState(false)
    const [showTelehealthConfig, setShowTelehealthConfig] = useState(false)


    useEffect(() => {
        const payload = {tenantId: props.tenant.tenantId, userRoleId: props.loggedInUser.userRoleId};
        props.SecuritySettingsActions.getSecuritySetting(payload);
    }, []);

    useEffect(() => {
        props.LayoutActions.getLayout();
        props.LayoutActions.getSavedPageLayout();
    }, [])

    const uniquePasswordsList = [0, 1, 2, 3, 4, 5];

    const keyNames = {
        defaultPasswordExpiration: "days",
        uniquePasswords: "uniquePasswords",
        maxFailedLoginAttempts: "loginAttempts",
        periodOfTime: "minutes"
    }
    const fieldDefaultValues = {
        defaultPasswordExpiration: 90,
        uniquePasswords: 5,
        maxFailedLoginAttempts: 3,
        periodOfTime: 60
    }

    useEffect(() => {
        if (props?.securitySettings?.length > 0) {
            props.securitySettings.forEach(v => {
                if (keyNames[v.key] && v.value) {
                    setValue(v.key, v.value[keyNames[v.key]]);
                }
            })
        }
    }, [props?.securitySettings]);

    const insertOrUpdate = (payload) => {
        props.SecuritySettingsActions.insertOrUpdateSecuritySettings(payload);
    }

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            const data = {};
            data[keyNames[name]] = parseInt(value[name]);
            insertOrUpdate({key: name, value: data});
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    const childFactory = (child) => {
        if (!child.props.in) {
            return null;
        }
        return child;
    };

    const slideElements = (section) => {
        switch (section) {
            case "Password_settings":
                setShowPasswordSettings(!showPasswordSettings);
                break;
            case "Permission_group_configuration":
                setShowPermissionGroupConfig(!showPermissionGroupConfig);
                break;
            case "telehealth_configuration":
                setShowTelehealthConfig(!showTelehealthConfig);
                break;
            default:

        }
    };


    return <ShowForPermissionComponent component permissions={[PhixPermissions.AH_ADMIN_VIEW_TENANT_CONFIGURATION]}>
        <Container gutter style={{margin: 10, height: "100%", overflowY: "auto"}}>
            <Col col={12} style={{paddingTop: 10}}>
                <h6> Tenant Configuration </h6>
            </Col>
            <Col col={12}>
                <span>{"Security settings"}&nbsp;&nbsp;</span>
                <span></span>
                {
                    showPasswordSettings ? <span className="k-icon k-i-arrow-60-down"
                                                 onClick={() => slideElements("Password_settings")}></span> :
                        <span className="k-icon k-i-arrow-60-right"
                              onClick={() => slideElements("Password_settings")}></span>
                }
            </Col>
            <Slide childFactory={childFactory} transitionExitDuration={1500}>
                {showPasswordSettings ? <div style={{pointerEvents: editable ? "block" : "none"}}>
                    <FieldSection style={{paddingLeft: 20}}>
                        <FieldRow>
                            <Field col={6} label={"Default Password Expiration "}
                                   field={"defaultPasswordExpiration"}
                                   error={errors} register={register}
                                   options={{required: "Default Password Expiration is required."}}>
                                <FieldNumberInput/>
                            </Field>
                            <Field label={" "} field={""} error={errors} register={register}>
                                <span>Passwords will expire after specified number of days. Users will be notified to reset passwords prior to expiration.</span>
                            </Field>
                        </FieldRow>
                        <FieldRow>
                            <Field col={6} label={"Unique Passwords"} field={"uniquePasswords"} error={errors}
                                   register={register}
                                   control={control}>
                                <FieldDropDown data={uniquePasswordsList}/>
                            </Field>
                            <Field label={" "} field={""} error={errors} register={register}>
                                <span>Select the number of password resets before a prior password may be reused. Select "0" for no restriction.</span>
                            </Field>
                        </FieldRow>
                        <FieldRow>
                            <Field col={3} label={"Max Failed Login Attempts"} field={"maxFailedLoginAttempts"}
                                   error={errors} register={register}
                                   control={control}>
                                <FieldNumberInput/>
                            </Field>
                            <Field col={3} label={"Period (minutes)"} field={"periodOfTime"} error={errors}
                                   register={register}
                                   control={control}>
                                <FieldNumberInput/>
                            </Field>
                            <Field label={" "} field={""} error={errors} register={register}>
                                <span>The number of failed log in attempts within a given time period before the user account is locked.</span>
                            </Field>
                        </FieldRow>
                    </FieldSection></div> : <></>}
            </Slide>

            <Divider/>
            <Col col={12}>
                <span>{"Permission Group Configuration"}&nbsp;&nbsp;</span>
                <span></span>
                {
                    showPermissionGroupConfig ? <span className="k-icon k-i-arrow-60-down"
                                                      onClick={() => slideElements("Permission_group_configuration")}></span> :
                        <span className="k-icon k-i-arrow-60-right"
                              onClick={() => slideElements("Permission_group_configuration")}></span>
                }
            </Col>

            <Slide childFactory={childFactory} style={{width: "100%"}} transitionExitDuration={1500}>
                {showPermissionGroupConfig ? <div style={{pointerEvents: editable ? "block" : "none"}}>
                    <br></br><PermissionGroupConfigurationComponent/>
                </div> : <></>}
            </Slide>
            <Divider/>

            <Col>
                <span>{"Telehealth"}&nbsp;&nbsp;</span>
                <span></span>
                {
                    showTelehealthConfig ? <span className="k-icon k-i-arrow-60-down"
                                                 onClick={() => slideElements("telehealth_configuration")}></span> :
                        <span className="k-icon k-i-arrow-60-right"
                              onClick={() => slideElements("telehealth_configuration")}></span>
                }
            </Col>
            <Col col={12}>
                <Slide childFactory={childFactory} style={{width: "100%"}} transitionExitDuration={1500}>
                    {showTelehealthConfig ? <div style={{pointerEvents: editable ? "block" : "none"}}>
                        <br></br><TelehealthConfigurationComponent/></div> : <></>}
                </Slide>
            </Col>

        </Container>
    </ShowForPermissionComponent>
};


function mapStateToProps(state) {
    return {
        securitySettings: state.securitySettings.securitySettings,
        tenant: state.Configuration.tenant.tenant,
        loggedInUser: state.user.loggedinUser,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        SecuritySettingsActions: bindActionCreators(SecuritySettingsAction, dispatch),
        LayoutActions: bindActionCreators(LayoutAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantConfigurationComponent)
