import * as React from "react";
import {UserActions} from "../action/UserAction";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Components} from "./shared/ComponentRegistry";
import TileHeader from "../layout/TileHeader";
import GlobalStyles from "../config/GlobalStyles";
import AssessmentsTabComponent from "./PatientPanelComponents/AssessmentsTab/AssessmentsTabComponent";
import {Col, Container, Row} from "react-grid-system";

const AssessmentComponent = (props) => {

    return (

        <div style={GlobalStyles.tileWrapper}>
            <TileHeader title="Assessments" expanded={Components.AssessmentComponent} style={{fontSize: "15px"}}/>
            <Container style={{height: "100%", width: "100%"}}>
                <Row style={{height: "100%", width: "100%"}}>
                        <AssessmentsTabComponent/>
                </Row>
            </Container>

        </div>

    );
};

AssessmentComponent.propTypes =
    {}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        UserActions: bindActionCreators(UserActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentComponent)
