import {Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {InsuranceActions} from "../../action/InsuranceAction";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {
    Field,
    FieldCheckboxInput,
    FieldDictionaryDropDown,
    FieldRow,
    FieldSection,
    FieldTextInput
} from "../InputControls/FormFields";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {PhixPermissions} from "../../config/GlobalConfig";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";

const InsurancePlanWindow = (props) => {
    const permissions = useSelector(state => state.auth.session.permissions)
    const [editable, setEditable] = useState(false)
    useEffect(() => setEditable(permissions.includes(PhixPermissions.AH_ADMIN_EDIT_INSURANCE_PLAN)), [editable])
    const dispatch = useDispatch()
    const {
        handleSubmit,
        register,
        reset,
        clearErrors,
        control,
        setValue,
        formState: {errors},
    } = useForm({defaultValues: {description: ""}});

    const insurancePlanContext = useSelector(state => state.insurance?.insurancePlanContext)
    const popupState = useSelector(state => state.popupWindow.state)
    const showWindow = useSelector(state => state.popupWindow.showWindow)

    React.useEffect(() => {
        setValue("active", true)
        if (insurancePlanContext?.id) {
            setValue("planName", insurancePlanContext?.planName)
            setValue("dictInsuranceProviderId", insurancePlanContext?.dictInsuranceProviderId)
            setValue("payerId", insurancePlanContext?.payerId)
            setValue("active", insurancePlanContext?.active)
            setValue("contactName", insurancePlanContext?.contactName)
            setValue("phoneNumber", insurancePlanContext?.contactPhone)
            setValue("address1", insurancePlanContext?.street1)
            setValue("address2", insurancePlanContext?.street2)
            setValue("city", insurancePlanContext?.city)
            setValue("state", insurancePlanContext?.dictStateId)
            setValue("zip", insurancePlanContext?.zip)
            setValue("country", insurancePlanContext?.dictCountryId)
        }
    }, [insurancePlanContext])

    const hideDialog = async () => {
        await InsuranceActions.setInsurancePlanContext(null)(dispatch)
        await PopupWindowActions.clearWindowState(PopupWindows.INSURANCE_PLAN_WINDOW)(dispatch)
        await reset()
        setValue("planName", null)
        setValue("dictInsuranceProviderId", null)
        setValue("payerId", null)
        setValue("active", true)
        setValue("contactName", null)
        setValue("phoneNumber", null)
        setValue("address1", null)
        setValue("address2", null)
        setValue("city", null)
        setValue("state", null)
        setValue("zip", null)
        setValue("country", null)
        await PopupWindowActions.hideWindow(PopupWindows.INSURANCE_PLAN_WINDOW)(dispatch)
    };

    const onSave = async (form) => {
        const payload = {
            id: insurancePlanContext?.id,
            planName: form.planName,
            dictInsuranceProviderId: form?.dictInsuranceProviderId?.id,
            payerId: form?.payerId,
            address1: form?.address1,
            address2: form?.address2,
            city: form?.city,
            dictStateId: form?.state?.id,
            dictCountryId: form?.country?.id,
            zip: form?.zip,
            phone: form?.phoneNumber,
            contactName: form?.contactName,
            active: form?.active
        }
        await InsuranceActions.saveInsurancePlans(payload)(dispatch)
        const showAllFlag = popupState[PopupWindows.INSURANCE_PLAN_WINDOW]?.showAllFlag;
        const searchState = popupState[PopupWindows.INSURANCE_PLAN_WINDOW]?.searchState;
        await InsuranceActions.getInsurancePlans(1000, 0, null, showAllFlag === true ? {planName: searchState} : {
            active: true,
            planName: searchState
        })(dispatch);
        await hideDialog();

    }

    if (showWindow[PopupWindows.INSURANCE_PLAN_WINDOW]) {
        return (
            <Window
                title={"Insurance Plan"}
                modal={true}
                onClose={hideDialog}
                initialHeight={650}
                initialWidth={800}>
                <FieldSection disabled={!editable}>
                    <FieldRow>
                        <Field col={12} label={"Insurance Provider"} field={"dictInsuranceProviderId"} error={errors}
                               register={register}
                               control={control}>
                            <FieldDictionaryDropDown code={Dictionary.AH_INSURANCE_PROVIDER}
                                                     dataItem={insurancePlanContext}/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field col={12} label={"Plan Name"} field={"planName"} error={errors}
                               control={control}
                               register={register} options={{required: "Plan Name is required."}}>
                            <FieldTextInput/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field col={6} label={"Payer Id"} field={"payerId"} error={errors}
                               control={control}
                               register={register}>
                            <FieldTextInput/>
                        </Field>
                        <Field col={2} label={"Active"} field={"active"} error={errors}
                               control={control}
                               register={register}>
                            <FieldCheckboxInput/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field col={6} label={"Contact Name"} field={"contactName"} error={errors}
                               control={control}
                               register={register}>
                            <FieldTextInput/>
                        </Field>
                        <Field col={6} label={"Phone No"} field={"phoneNumber"} error={errors}
                               control={control}
                               register={register}>
                            <FieldTextInput/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field col={12} label={"Address 1"} field={"address1"} error={errors} register={register}
                               control={control} options={{required: "Address1 is required."}}>
                            <FieldTextInput/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field col={12} label={"Address 2"} field={"address2"} error={errors} register={register}
                               control={control}>
                            <FieldTextInput/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field col={12} label={"City"} field={"city"} error={errors}
                               control={control}
                               register={register} options={{required: "City is required."}}>
                            <FieldTextInput/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field col={4} label={"State"} field={"state"} error={errors}
                               control={control}
                               register={register} options={{required: "State is required."}}>
                            <FieldDictionaryDropDown code={Dictionary.STATES} dataItem={insurancePlanContext}/>
                        </Field>
                        <Field col={4} label={"Zip"} field={"zip"} error={errors}
                               control={control}
                               register={register} options={{required: "Zip is required."}}>
                            <FieldTextInput/>
                        </Field>
                        <Field col={4} label={"Country"} field={"country"} error={errors}
                               control={control}
                               register={register} options={{required: "Country is required."}}>
                            <FieldDictionaryDropDown defaultItemCode={"US"} code={Dictionary.COUNTRIES}
                                                     dataItem={insurancePlanContext}/>
                        </Field>
                    </FieldRow>
                </FieldSection>
                <WindowActionsBar>
                    <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_INSURANCE_PLAN]}>
                        <Button onClick={handleSubmit(onSave)}>Save</Button>
                    </ShowForPermissionComponent>
                    <Button onClick={hideDialog}>Cancel</Button>
                </WindowActionsBar>
            </Window>

        );
    } else {
        return null;
    }
}

export default InsurancePlanWindow;
