import React, {useEffect} from 'react';
import TileHeader from '../../layout/TileHeader';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {PatientAction} from "../../action/PatientAction";
import {Col, Container, Row} from "react-grid-system";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {ProgressBar} from "@progress/kendo-react-progressbars";
import {Colors} from "../../config/GlobalConfig";


const RiskPatientCareTeam = (props) => {

    const {PatientActions, bloodPressureDistribution, user, PatientContext} = props;
    const [patientCount, setPatientCount] = React.useState(114)

    return (
        <TileContainer>
            <TileHeader
                        title={<div style={{width: "100%"}}><span className="k-icon k-i-warning" style={{
                            fontSize: '21px',
                            color: '#CF2927',
                            margin: '0px 5px 2px'
                        }}/> Clinical Complexity</div>}>
            </TileHeader>
            <TileBody>
                <Container>
                    <Row style={{padding: 0, height: 23}}>
                        <Col sm={4}>
                            <span style={{fontSize: "12px"}}><b>Severe Frailty</b></span>
                        </Col>
                        <Col sm={4}>
                            <ProgressBar value={17} max={patientCount} progressStyle={{backgroundColor: Colors.RED}}
                                         style={{backgroundColor: "white"}}/>
                        </Col>
                    </Row>
                    <Row style={{padding: 0, height: 23}}>
                        <Col sm={4}>
                            <span style={{fontSize: "12px"}}><b>Moderate Frailty</b></span>
                        </Col>
                        <Col sm={4}>
                            <ProgressBar value={28} max={patientCount} progressStyle={{backgroundColor: Colors.ORANGE}}
                                         style={{backgroundColor: "white"}}/>

                        </Col>
                    </Row>
                    <Row style={{padding: 0, height: 23}}>
                        <Col sm={4}>
                            <span style={{fontSize: "12px"}}><b>Mild Frailty</b></span>
                        </Col>
                        <Col sm={4}>
                            <ProgressBar value={46} max={patientCount} progressStyle={{backgroundColor: Colors.YELLOW}}
                                         style={{backgroundColor: "white"}}/>
                        </Col>
                    </Row>
                    <Row style={{padding: 0, height: 23}}>
                        <Col sm={4}>
                            <span style={{fontSize: "12px"}}><b>Fit</b></span>
                        </Col>
                        <Col sm={4}>
                            <ProgressBar value={23} max={patientCount} progressStyle={{backgroundColor: Colors.GREEN}}
                                         style={{backgroundColor: "white"}}/>
                        </Col>
                    </Row>
                    <Row style={{padding: 0}}>
                        <Col sm={6}>

                        </Col>
                        <Col sm={6}>
                            <span style={{fontSize: "12px"}}>Total cohort size: {patientCount}</span>

                        </Col>
                    </Row>
                </Container>

            </TileBody>

        </TileContainer>
    )
}

function mapStateToProps(state) {
    return {
        PatientContext: state.patient.patientContext,
        bloodPressureDistribution: state.patient.bloodPressureDistribution,
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskPatientCareTeam);