import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem} from '@progress/kendo-react-charts';
import {PatientAction} from "../../action/PatientAction";
import {UserActions} from "../../action/UserAction";
import {Col, Container, Row} from "react-grid-system";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import TileHeader from "../../layout/TileHeader";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {TileRow} from "../../layout/TileRow";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {ObservationThreshold, PhixPermissions} from "../../config/GlobalConfig";
import moment from "moment";
import {ObservationsThresholdAction} from "../../action/ObservationsThresholdAction";
import AeriosLoadingIndicator from "../AeriosLoadingIndicator";

const BloodPressureCareTeamComponent = (props) => {
    const dispatch = useDispatch()
    const bloodPressureDistribution = useSelector(state => state.patient.bloodPressureDistribution)
    const observationThresholdSystolic = useSelector(state => state.observationThreshold.componentThresholds?.Systolic)
    const observationThresholdDiastolic = useSelector(state => state.observationThreshold.componentThresholds?.Diastolic)
    const user = useSelector(state => state.user.loggedinUser)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    const [latest, setLatest] = useState()

    const [observationData, setObservationData] = useState({
        Systolic: {
            High: {}, Normal: {}, Elevated: {}
        }, Diastolic: {
            High: {}, Normal: {}, Elevated: {}
        }
    });

    const [bp, setBp] = useState({
        High: {
            color: ObservationThreshold.color, text: "", patient: []
        }, Normal: {
            color: ObservationThreshold.color, text: "", patient: []
        }, Elevated: {
            color: ObservationThreshold.color, text: "", patient: []
        }
    });


    useEffect(() => {
        const endDate = moment().format("YYYY-MM-DD");
        const startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
        const payload = {
            userRoleId: user.userRoleId,
            codes: ["AH_DIASTOLIC_BP", "AH_SYSTOLIC_BP"],
            startDate: startDate,
            endDate: endDate
        }
        const fetchData = async () => {
            await PatientAction.getPatientBloodPressureDistribution(payload, "BloodPressureCareTeamComponent")(dispatch)
            await ObservationsThresholdAction.getObservationThresholdsByObservationCode("AH_SYSTOLIC_BP", "Systolic")(dispatch)
            await ObservationsThresholdAction.getObservationThresholdsByObservationCode("AH_DIASTOLIC_BP", "Diastolic")(dispatch)
        }
        fetchData().then(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (observationThresholdSystolic && observationThresholdDiastolic) {
            const val = observationThresholdSystolic;
            let observationData = {
                Systolic: {
                    High: {}, Normal: {}, Elevated: {}
                }, Diastolic: {
                    High: {}, Normal: {}, Elevated: {}
                }
            }
            for (let i = 0; i < val.length; i++) {
                if (val[i].description === "High") {
                    observationData.Systolic.High.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Systolic.High.value = val[i].value
                    observationData.Systolic.High.rangeText = val[i].rangeText
                    observationData.Systolic.High.rangeColor = val[i].rangeColor
                } else if (val[i].description === "Normal") {
                    observationData.Systolic.Normal.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Systolic.Normal.value = val[i].value
                    observationData.Systolic.Normal.rangeText = val[i].rangeText
                    observationData.Systolic.Normal.rangeColor = val[i].rangeColor
                } else if (val[i].description === "Elevated") {
                    observationData.Systolic.Elevated.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Systolic.Elevated.value = val[i].value
                    observationData.Systolic.Elevated.rangeText = val[i].rangeText
                    observationData.Systolic.Elevated.rangeColor = val[i].rangeColor
                }
            }

            const valDia = observationThresholdDiastolic;
            for (let i = 0; i < valDia.length; i++) {
                if (valDia[i].description === "High") {
                    observationData.Diastolic.High.dictOperatorDisplayName = valDia[i].dictOperatorDisplayName
                    observationData.Diastolic.High.value = valDia[i].value
                    observationData.Diastolic.High.rangeText = valDia[i].rangeText
                    observationData.Diastolic.High.rangeColor = valDia[i].rangeColor
                } else if (valDia[i].description === "Normal") {
                    observationData.Diastolic.Normal.dictOperatorDisplayName = valDia[i].dictOperatorDisplayName
                    observationData.Diastolic.Normal.value = valDia[i].value
                    observationData.Diastolic.Normal.rangeText = valDia[i].rangeText
                    observationData.Diastolic.Normal.rangeColor = valDia[i].rangeColor
                } else if (valDia[i].description === "Elevated") {
                    observationData.Diastolic.Elevated.dictOperatorDisplayName = valDia[i].dictOperatorDisplayName
                    observationData.Diastolic.Elevated.value = valDia[i].value
                    observationData.Diastolic.Elevated.rangeText = valDia[i].rangeText
                    observationData.Diastolic.Elevated.rangeColor = valDia[i].rangeColor
                }
            }
            console.log(observationData)
            setObservationData(observationData)
        }
    }, [observationThresholdSystolic, observationThresholdDiastolic])

    useEffect(() => {
        setData(bloodPressureDistribution ? bloodPressureDistribution : [])
    }, [bloodPressureDistribution])


    useEffect(() => {
        if (data?.length > 0) {
            let bloodPressure = {High: {}, Elevated: {}, Normal: {}}
            bloodPressure.High.patient = [];
            bloodPressure.Elevated.patient = [];
            bloodPressure.Normal.patient = [];
            bloodPressure.High.color = observationData.Systolic.High.rangeColor;
            bloodPressure.High.text = observationData.Systolic.High.rangeText;
            bloodPressure.Elevated.color = observationData.Systolic.Elevated.rangeColor;
            bloodPressure.Elevated.text = observationData.Systolic.Elevated.rangeText;
            bloodPressure.Normal.color = observationData.Systolic.Normal.rangeColor;
            bloodPressure.Normal.text = observationData.Systolic.Normal.rangeText;
            data.map(item => {
                if ((observationData.Systolic?.High && observationData.Systolic?.High?.value?.value) || (observationData.Diastolic?.High && observationData.Diastolic?.High?.value?.value)) {
                    if ((observationData.Systolic.High.dictOperatorDisplayName === ">=" && observationData.Diastolic.High.dictOperatorDisplayName === ">=") && (item.systolic >= parseInt(observationData.Systolic.High?.value?.value) || item.diastolic >= parseInt(observationData.Diastolic.High?.value?.value))) {
                        bloodPressure.High.patient.push(item.patientRoleId);
                        return;
                    } else if ((observationData.Systolic.High.dictOperatorDisplayName === "<=" && observationData.Diastolic.High.dictOperatorDisplayName === "<=") && (item.systolic >= parseInt(observationData.Systolic.High?.value?.value) || item.diastolic <= parseInt(observationData.Diastolic.High?.value?.value))) {
                        bloodPressure.High.patient.push(item.patientRoleId);
                        return;
                    } else if ((observationData.Systolic.High.dictOperatorDisplayName === ">" && observationData.Diastolic.High.dictOperatorDisplayName === ">") && (item.systolic > parseInt(observationData.Systolic.High?.value?.value) || item.diastolic > parseInt(observationData.Diastolic.High?.value?.value))) {
                        bloodPressure.High.patient.push(item.patientRoleId);
                        return;
                    } else if ((observationData.Systolic.High.dictOperatorDisplayName === "<" && observationData.Diastolic.High.dictOperatorDisplayName === "<") && (item.systolic < parseInt(observationData.Systolic.High?.value?.value) || item.diastolic < parseInt(observationData.Diastolic.High?.value?.value))) {
                        bloodPressure.High.patient.push(item.patientRoleId);
                        return;
                    } else if ((observationData.Systolic.High.dictOperatorDisplayName === "==" && observationData.Diastolic.High.dictOperatorDisplayName === "==") && (item.systolic == parseInt(observationData.Systolic.High?.value?.value) || item.diastolic == parseInt(observationData.Diastolic.High?.value?.value))) {
                        bloodPressure.High.patient.push(item.patientRoleId);
                        return;
                    }
                } else if ((observationData.Systolic?.High && observationData.Systolic.High?.value?.min) || (observationData.Diastolic.High?.value?.min && observationData.Diastolic.High?.value?.max)) {
                    if ((item.value > parseInt(observationData.Systolic.High?.value?.min) && item.value < parseInt(observationData.Systolic.High?.value?.max)) || (item.systolic > parseInt(observationData.Diastolic.High?.value?.min) && item.diastolic < parseInt(observationData.Diastolic.High?.value?.max))) {
                        bloodPressure.High.patient.push(item.patientRoleId);
                        return;
                    }
                }

                if (observationData.Systolic?.Elevated?.value?.value) {
                    if (observationData.Systolic.Elevated.dictOperatorDisplayName === ">=" && item.systolic >= parseInt(observationData.Systolic.Elevated?.value?.value)) {
                        bloodPressure.Elevated.patient.push(item.patientRoleId);
                        return;
                    } else if (observationData.Systolic.Elevated.dictOperatorDisplayName === "<=" && item.systolic <= parseInt(observationData.Systolic.Elevated?.value?.value)) {
                        bloodPressure.Elevated.patient.push(item.patientRoleId);
                        return;
                    } else if (observationData.Systolic.Elevated.dictOperatorDisplayName === ">" && item.systolic > parseInt(observationData.Systolic.Elevated?.value.value)) {
                        bloodPressure.Elevated.patient.push(item.patientRoleId);
                        return;
                    } else if (observationData.Systolic.Elevated.dictOperatorDisplayName === "<" && item.systolic < parseInt(observationData.Systolic.Elevated.value.value)) {
                        bloodPressure.Elevated.patient.push(item.patientRoleId);
                        return;
                    } else if (observationData.Systolic.Elevated.dictOperatorDisplayName === "==" && item.systolic === parseInt(observationData.Systolic.Elevated.value.value)) {
                        bloodPressure.Elevated.patient.push(item.patientRoleId);
                        return;
                    }
                } else if (observationData.Systolic?.Elevated?.value?.max && observationData.Systolic.Elevated?.value?.min) {
                    if (item.systolic > parseInt(observationData.Systolic.Elevated?.value?.min) && item.systolic < parseInt(observationData.Systolic?.Elevated?.value?.max)) {
                        bloodPressure.Elevated.patient.push(item.patientRoleId);
                        return;
                    }
                }

                if ((observationData.Systolic?.Normal && observationData.Systolic?.Normal?.value?.value) || (observationData.Diastolic?.Normal && observationData.Diastolic?.Normal?.value?.value)) {
                    if ((observationData.Systolic.Normal.dictOperatorDisplayName == ">=" && observationData.Diastolic.Normal.dictOperatorDisplayName == ">=") && (item.systolic >= parseInt(observationData.Systolic.Normal?.value?.value) || item.diastolic >= parseInt(observationData.Diastolic.Normal?.value?.value))) {
                        bloodPressure.Normal.patient.push(item.patientRoleId);
                        return;
                    } else if ((observationData.Systolic.Normal.dictOperatorDisplayName == "<=" && observationData.Diastolic.Normal.dictOperatorDisplayName == "<=") && (item.systolic >= parseInt(observationData.Systolic.Normal?.value?.value) || item.diastolic <= parseInt(observationData.Diastolic.Normal?.value?.value))) {
                        bloodPressure.Normal.patient.push(item.patientRoleId);
                        return;
                    } else if ((observationData.Systolic.Normal.dictOperatorDisplayName == ">" && observationData.Diastolic.Normal.dictOperatorDisplayName == ">") && (item.systolic > parseInt(observationData.Systolic.Normal?.value?.value) || item.diastolic > parseInt(observationData.Diastolic.Normal?.value?.value))) {
                        bloodPressure.Normal.patient.push(item.patientRoleId);
                        return;
                    } else if ((observationData.Systolic.Normal.dictOperatorDisplayName == "<" && observationData.Diastolic.Normal.dictOperatorDisplayName == "<") && (item.systolic < parseInt(observationData.Systolic.Normal?.value?.value) || item.diastolic < parseInt(observationData.Diastolic.Normal?.value?.value))) {
                        bloodPressure.Normal.patient.push(item.patientRoleId);
                        return;
                    } else if ((observationData.Systolic.Normal.dictOperatorDisplayName == "==" && observationData.Diastolic.Normal.dictOperatorDisplayName == "==") && (item.systolic == parseInt(observationData.Systolic.Normal?.value?.value) || item.diastolic == parseInt(observationData.Diastolic.Normal?.value?.value))) {
                        bloodPressure.Normal.patient.push(item.patientRoleId);
                        return;
                    }
                } else if ((observationData.Systolic?.Normal && observationData.Systolic.Normal?.value?.min) || (observationData.Diastolic.Normal?.value?.min && observationData.Diastolic.Normal?.value?.max)) {
                    if ((item.systolic > parseInt(observationData.Systolic.Normal?.value?.min) && item.systolic < parseInt(observationData.Systolic.Normal?.value?.max)) || (item.diastolic > parseInt(observationData.Diastolic.Normal?.value?.min) && item.diastolic < parseInt(observationData.Diastolic.Normal?.value?.max))) {
                        bloodPressure.Normal.patient.push(item.patientRoleId);
                        return;
                    }
                }
            });
            setBp(bloodPressure)
            setLatest([{
                value: bloodPressure.Normal.patient.length,
                patient: bloodPressure.Normal.patient,
                color: bloodPressure.Normal.color
            }, {
                value: bloodPressure.Elevated.patient.length,
                patient: bloodPressure.Elevated.patient,
                color: bloodPressure.Elevated.color
            }, {
                value: bloodPressure.High.patient.length,
                patient: bloodPressure.High.patient,
                color: bloodPressure.High.color
            }]);
        }

    }, [data, observationData])


    const handleClick = (e) => {
        UserActions.getPatientsList({patientRoleId: e.dataItem.patient})(dispatch).then(() => {
            PopupWindowActions.showWindow(PopupWindows.PATIENT_LIST_FOR_PIE)(dispatch)
        })

    }
    return (<React.Fragment>
        <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
            <TileContainer>
                <TileHeader className="boxHeader ah-draggable-header"
                            title={"BLOOD PRESSURE"} image={"../images/blood.png"}>
                </TileHeader>
                <TileBody fluid style={{padding: 0, backgroundColor: '#FFF'}}
                          differentStyleTitle={"updatedTitleWrapperGrayBg"}>
                    <AeriosLoadingIndicator loading={loading}>
                        {data.length === 0 ? <span style={{
                                textAlign: 'center', width: '100%', float: 'left',

                            }}>No data to display</span>
                            : <TileRow style={{height: "100%"}}>
                                <Col lg={6} style={{
                                    width: '65%', flex: '0 0 65%', maxWidth: '65%',
                                }}>
                                    <Container fluid style={{padding: 0}}>
                                        <Row>
                                            <Col lg={3} style={{
                                                flex: '0 0 20%', maxWidth: '20%',
                                            }}>
                                                <b style={{
                                                    padding: '2px',
                                                    position: 'relative',
                                                    top: '0px',
                                                    fontSize: '21px',
                                                    color: bp.High.color
                                                }}>
                                                    {bp.High.patient.length}
                                                </b>
                                            </Col>
                                            <Col lg={9} style={{
                                                height: "20%",
                                                paddingTop: "2px",
                                                paddingLeft: "1px",
                                                paddingRight: "1px",
                                                lineHeight: '1',
                                                width: '80%',
                                                flex: '0 0 80%',
                                                maxWidth: '80%',
                                            }}>
                                        <span style={{fontSize: "16px"}}>
                                            {bp.High.text}
                                        </span>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col lg={3} style={{
                                                flex: '0 0 20%', maxWidth: '20%',
                                            }}>
                                                <b style={{
                                                    padding: '2px',
                                                    position: 'relative',
                                                    top: '2px',
                                                    fontSize: '21px',
                                                    color: bp.Elevated.color
                                                }}>
                                                    {bp.Elevated.patient.length}
                                                </b>
                                            </Col>
                                            <Col lg={9} style={{
                                                height: "20%",
                                                paddingTop: "4px",
                                                paddingLeft: "1px",
                                                paddingRight: "1px",
                                                lineHeight: '1',
                                                width: '80%',
                                                flex: '0 0 80%',
                                                maxWidth: '80%',
                                            }}>
                                        <span style={{fontSize: "16px"}}>
                                            {bp.Elevated.text}
                                        </span>
                                            </Col>

                                        </Row>
                                    </Container>
                                </Col>
                                <Col lg={6}
                                     style={{
                                         marginTop: '-48px',
                                         width: '35%',
                                         flex: '0 0 35%',
                                         maxWidth: '35%',
                                         paddingRight: '60px'
                                     }}>
                                    <Chart onSeriesClick={handleClick}
                                           style={{width: '100%', height: '100%'}}>
                                        <ChartArea background="#FFF"
                                                   height="200" width="150"/>
                                        {/* <ChartTitle text='Sleep Quality' /> */}
                                        <ChartLegend visible={false}/>
                                        <ChartSeries>
                                            <ChartSeriesItem
                                                type="donut" data={latest} field="value" categoryField="category"
                                                labels={{
                                                    visible: false, content: ''
                                                }}/>
                                        </ChartSeries>

                                    </Chart>
                                </Col>
                            </TileRow>
                        }
                    </AeriosLoadingIndicator>
                </TileBody>
            </TileContainer>
        </ShowForPermissionComponent>
    </React.Fragment>)
}

export default BloodPressureCareTeamComponent;