import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Dropdown} from "primereact/dropdown";
import {PaginatedEndpoint} from "../../util/AuthWrapper";


const ExternalVendorDropdownList = (props) => {
    const {value, fullObject = false, inputRef, dictVendorTypeId, onChange} = props
    const [data, setData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null)
    const [filter, setFilter] = useState("");

    const filterChange = (event) => {
        setFilter(event.filter)
        //setData(filterData(event.filter));
    };

    const getData = PaginatedEndpoint("/vendor/get/type/id", {id: dictVendorTypeId})

    const handleChange = (e) => {
        setSelectedValue(e.value)
        if (onChange)
            onChange(e)
    }

    useEffect(() => {
        let e = {target: {value: selectedValue}}
        if (e.target.value?.userRoleId) {
            if (props.onChange) {
                if (fullObject)
                    props.onChange(e)
                else {

                    e.target.value = e.target.value?.id
                    props.onChange(e)
                }
            }
        }
    }, [selectedValue])

    useEffect(() => {
            getData(0, 100, {sorts: []}, {filter: filter}).then(response => {
                    setData(response?.payload || [])
                }
            )
        }, [filter]
    )


    return (
        <>
            <Dropdown style={{...props.style}}
                      panelStyle={{overflow: "scroll", width: "20%"}}
                      autoWidth={false}
                      className={props.className}
                      focusInputRef={inputRef}
                      required={props.required}
                      resetFilterOnHide
                      showFilterClear
                      disabled={props.disabled}
                      options={data}
                      value={selectedValue}
                      optionLabel={"displayName"}
                      filter
                      onFilter={filterChange}
                      onChange={handleChange}
            />
        </>

    );
};


export default ExternalVendorDropdownList
