import React, {useEffect, useState} from 'react';
import TileHeader from '../../layout/TileHeader';
import {useDispatch, useSelector} from 'react-redux';
import {ObservationCodes, PhixPermissions} from "../../config/GlobalConfig";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {PatientAction} from "../../action/PatientAction";
import moment from "moment/moment";
import {CalculateCohortObservationThresholds, ThresholdChart, ThresholdList} from "../../util/ObservationThresholds";
import {AeriosNoDataLoadingIndicator} from "../AeriosLoadingIndicator";
import {TileRow} from "../../layout/TileRow";


const WeightManagementComponent = (props) => {
    const dispatch = useDispatch()
    const latestCohortObservation = useSelector(state => state.patient.latestCohortObservation)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [thresholds, setThresholds] = useState([])

    const startDate = moment().endOf('day').subtract(1, 'month').format('YYYY-MM-DD');

    useEffect(() => {
        const fetchData = async () => {
            await PatientAction.getPatientCohortLatestObservationWithThreshold(ObservationCodes.WEIGHT.AH_BODY_MASS_INDEX, startDate, "WeightManagementComponent")(dispatch)
        }
        fetchData().then(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (data?.length) {
            let c = CalculateCohortObservationThresholds(data, latestCohortObservation?.WeightManagementComponent?.thresholds)
            setThresholds(c)
        }
    }, [data])

    useEffect(() => {
        if (latestCohortObservation?.WeightManagementComponent?.observations) {
            setData(latestCohortObservation.WeightManagementComponent.observations)
        }
    }, [latestCohortObservation?.WeightManagementComponent]);


    // function BmiRow(props) {
    //     return (<div>
    //     <span style={{
    //         width: 'auto',
    //         float: 'left',
    //         margin: '5px 0 0 10px',
    //         fontSize: '16px',
    //     }} onClick={() => handleClick(weightCategory.underWeight.Patient)}>
    //                         <span style={{
    //                             width: '18px',
    //                             height: '18px',
    //                             borderRadius: '50%',
    //                             float: 'left',
    //                             margin: '4px 5px',
    //                             background: '#E06666'
    //                         }}>
    //                             <span style={{
    //                                 width: '8px',
    //                                 height: '8px',
    //                                 float: 'left',
    //                                 margin: '5px 5px',
    //                                 background: 'white'
    //                             }}>
    //                             </span>
    //                         </span>
    //                         <strong> {weightCategory.underWeight.count + weightCategory.obesity.count} </strong> Patient(s) have a BMI in the critical zone for obesity or underweight </span>
    //
    //         <span style={{
    //             width: 'auto',
    //             float: 'left',
    //             margin: '5px 0 0 10px',
    //             fontSize: '16px',
    //         }} onClick={() => handleClick(weightCategory.overWeight.Patient)}>
    //                         <span style={{
    //                             width: '18px',
    //                             height: '18px',
    //                             borderRadius: '50%',
    //                             float: 'left',
    //                             margin: '4px 5px',
    //                             background: '#f9ca24'
    //                         }}>
    //
    //                         </span>
    //                         <strong> {weightCategory.overWeight.count} </strong> Patient(s) have a BMI indicating overweight </span>
    //
    //         <span style={{
    //             width: 'auto',
    //             float: 'left',
    //             margin: '5px 0 0 10px',
    //             fontSize: '16px',
    //         }} onClick={() => handleClick(weightCategory.normalWeight.Patient)}>
    //                         <span style={{
    //                             width: '18px',
    //                             height: '18px',
    //                             borderRadius: '50%',
    //                             float: 'left',
    //                             margin: '4px 5px',
    //                             background: '#6AA84F'
    //                         }}>
    //                             <span style={{
    //                                 width: '12px',
    //                                 height: '12px',
    //                                 float: 'left',
    //                                 margin: '6px 3px',
    //                                 background: 'white',
    //                                 borderRadius: '50%',
    //                                 marginTop: '3px'
    //                             }}>
    //                             </span>
    //                         </span>
    //                         <strong> {weightCategory.normalWeight.count} </strong> Patient(s) have normal weight, based on BMI </span>
    //     </div>)
    // }

    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                <TileContainer>
                    <TileHeader title={"Weight Management"} image={"../images/weight.png"}/>
                    <TileBody fluid style={{padding: 0, backgroundColor: '#FFF'}}
                              differentStyleTitle={"updatedTitleWrapperGrayBg"}>
                        <AeriosNoDataLoadingIndicator loading={loading} data={thresholds}>
                            <TileRow>
                                <ThresholdList thresholds={thresholds}/>
                                <ThresholdChart thresholds={thresholds}/>
                            </TileRow>
                        </AeriosNoDataLoadingIndicator>
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}

export default WeightManagementComponent