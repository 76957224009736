import React from 'react';
import {Form, FormElement} from "@progress/kendo-react-form";
import {Button} from "primereact/button";
import {Avatar} from "@progress/kendo-react-layout";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {UserActions} from "../../action/UserAction";
import {PersonActions} from "../../action/PersonAction";
import {OpenEmrActions} from "../../action/OpenEmrAction";
import {VeradigmSsoComponent, VeradigmRenderer} from "../shared/VeradigmRenderer";
import {PhixPermissions} from "../../config/GlobalConfig";
import {PermissionsTool, NoPermissions} from "../../util/PermissionsTool";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";


let kendokaAvatar = 'https://www.telerik.com/kendo-react-ui-develop/images/kendoka-react.png';
const styles = {

    formElement: {
        width: 480,
        height: 510,
        position: "",
        // fontsize: 60,
        paddingLeft: 50,
        // border : "1px solid black",
        // size: 30,
        fontFamily: "verdana",
    },
    formField: {
        width: 200,
        // fontsize:80,
        padding: "1rem"
    },
    header: {
        // width: 200,
        height: 50,
        fontWeight: "bolder",
        marginTop: 20,
        fontSize: "large",
    },
    label: {
        fontWeight: "bold",
        // fontSize : "medium",
    },
    value: {
        fontWeight: "bold",
        // fontSize : "large",
    },
    checkbox: {
        marginLeft: "5rem",
        fontSize: "largest",
    }
}
const ViewProfileComponent = (props) => {
    const {PopupActions, showWindow, users, UserActions, loggedinUser, auth} = props;
    const [eprescribeFlag, setEprescribeFlag] = React.useState(false)
    const [viewProfilePermissions, setViewProfilePermissions] = React.useState({isEdit:false, isView: false})
    const [viewAdminProfilePermissions, setViewAdminProfilePermissions] = React.useState({isEdit:false, isView: false})
    const handleSubmit = (dataItem) => {
        // props.authActions.edit(dataItem.username, dataItem.password)
    }
    var luser = props.loggedinUser;
    const user = {
        name: luser.firstName + " " + luser.lastName,
        credentials: luser.credentials,
        officePhone: luser.phone,
        mobilePhone: luser.phone,
        workEmail: luser.email
    }

    React.useEffect(() => {
        setViewProfilePermissions(PermissionsTool({permissionInfo: props.permissionInfo, viewPermissions: ["AH_VIEW_USER"], editPermissions:["AH_EDIT_USER"]}));
        setViewAdminProfilePermissions(PermissionsTool({permissionInfo: props.permissionInfo, viewPermissions: ["AH_ADMIN_VIEW_USERS"], editPermissions:["AH_ADMIN_EDIT_USERS"]}));
    },[props?.permissionInfo]);


    React.useEffect(() => {
        var payload = {
            "userRoleId": props.userRoleId
        }
        props.PersonActions.getContactPhone(props.loggedinUser.personId)
        props.UserActions.getUser(payload)

    }, []);

    const toggleDialogEdit = (user) => {
        props.close();
        UserActions.setUserContext(loggedinUser)
        PopupActions.showWindow(PopupWindows.EDIT_USER_PROFILE_WINDOW);
    };

    async function submitEprescribe() {
        await props.UserActions.getSAML();
        //props.OpenEmrActions.goToEPrescribe();
        setEprescribeFlag(true)
    }

    return <>{(viewProfilePermissions.isEdit || viewProfilePermissions.isView || viewAdminProfilePermissions.isEdit || viewAdminProfilePermissions.isView) ? <div>
        <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
                <FormElement style={styles.formElement}>
                    <div>
                        <legend style={styles.header}>
                            Profile
                            <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_USER]}>
                                <Button type={"submit"} primary={true} style={{float: "right", marginRight: "0.2rem"}}
                                        onClick={() => toggleDialogEdit(props.dataItem)}>Edit Profile</Button>
                            </ShowForPermissionComponent>
                        </legend>
                    </div>
                    <div>
                        <label style={styles.label}>Name:&nbsp;</label>
                        <label style={styles.value}>{user.name}</label>
                    </div>
                    <br/>
                    <div>
                        <label style={styles.label}>Credentials:&nbsp;</label>
                        <label style={styles.value}>{user.credentials}</label>

                    </div>
                    <br/>
                    <div>
                        <label style={styles.label}>Contact Info:</label>
                        <br/>

                        <div style={{paddingLeft: "2rem", paddingTop: "1rem"}}>
                            <label style={styles.label}>Work Phone:</label>
                            <label style={styles.value}>
                                {props.phones.map((item, i) => {
                                    return item.dictionaryDisplayName == 'Work Phone' && item.isPrimary ?
                                        <span> {item.phone} &nbsp;&nbsp;&nbsp;</span> : "";
                                })}
                            </label>
                        </div>
                        <div style={{paddingLeft: "2rem", paddingTop: "1rem"}}>
                            <label style={styles.label}>Home Phone:</label>
                            <label style={styles.value}>
                                {props.phones.map((item, i) => {
                                    return item.dictionaryDisplayName != 'Work Phone' && item.isPrimary ?
                                        <span>{item.phone}&nbsp;&nbsp;&nbsp;</span> : "";
                                })}
                            </label>
                        </div>
                        <div className='row'>
                            <div className="col-md-6" style={{paddingLeft: "1.2rem", paddingTop: "1rem"}}>
                                <label style={styles.label}>Work Email:</label>
                                <label style={styles.value}>{user.workEmail}</label>
                            </div>
                            <div className="col-md-6" style={{paddingTop: "0.8rem", float: "right"}}>
                                {auth.eprescribeUser && (
                                    <Button
                                        type={"submit"}
                                        primary={true}
                                        onClick={submitEprescribe}
                                    >Register ePrescibe</Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <br/>
                    <hr/>
                    <br/>
                    <div>
                        <Avatar style={{float: "left", height: "100px", width: "100px"}}>
                            <span className="k-icon k-i-user"/>
                        </Avatar>
                        <div style={{float: "right", marginTop: "1rem", marginRight: "10rem"}}>
                            {((viewProfilePermissions.isEdit && viewProfilePermissions.isView) || (viewAdminProfilePermissions.isEdit && viewAdminProfilePermissions.isView)) && <Button
                                type={"submit"}
                                primary={true}
                            >Edit Image</Button>}
                            <p>No Image Uploaded </p>
                        </div>
                        <div className="k-hbox"/>
                    </div>

                </FormElement>
            )}
        />
        {eprescribeFlag && (
            <VeradigmRenderer>
                <VeradigmSsoComponent value={props.getSAML?.samlResponse ? props.getSAML?.samlResponse : null}
                                      setEprescribeFlag={setEprescribeFlag}/>
            </VeradigmRenderer>
        )}
    </div> : <NoPermissions/>}</>;
};

// export default ViewProfileComponent;

ViewProfileComponent.propTypes = {
    showWindow: PropTypes.object,
    createPatientContext: PropTypes.object,
    loggedinUser: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        createPatientContext: state.patient.createPatientContext,
        loggedinUser: state.user.loggedinUser,
        phones: state.person.personContextPhone,
        userRoleId: state.auth.session.userRoleId,
        auth: state.auth.session,
        getUsersInfo: state.user.getUsersInfo,
        getSAML: state.user.getSAML,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        UserActions: bindActionCreators(UserActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        OpenEmrActions: bindActionCreators(OpenEmrActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfileComponent);
