import React from "react";
import ChartViewerWindow from "./ChartViewerWindow";
import ExpandableWindow from "./ExpandableWindow";
import AddPatientWindow from "./AddPatientWindow";
// import AddUserWindow from "./AddUserWindow";
import EditUserWindow from "./EditUserWindow";
import EditUserProfileWindow from "./EditUserProfileWindow";
import CarePlanComponentWindow from "./CarePlanComponentWindow";
import NotificationDetailsWindow from "./NotificationDetailsWindow";
import AddMedicalProblemWindow from "./AddMedicalProblemWindow";
import SearchDiagnosisCodeWindow from "./SearchDiagnosisCodeWindow";
import SearchRxNormCodeWindow from "./SearchRxNormCodeWindow";
import SearchSurgicalProcedureCodeWindow from "./SearchSurgicalProcedureCodeWindow";
import AddImmunizationWindow from "./AddImmunizationWindow";
import SearchImmunizationWindow from "./SearchImmunizationCodeWindow";
import SearchMFImmunizationWindow from "./SearchImmunizationMFCodeWindow";
import AddNewRequestWindow from "./AddNewRequestWindow";
import EditFacilityWindow from "./EditFacilityWindow";
import AddClinicalOrderWindow from "./AddClinicalOrderWindow";
import AddCodeComponentWindow from "./AddDictionaryWindow";
import AddDictionaryComponentWindow from "./AddDictionaryTypeWindow";
import QualityComponentWindow from "./QualityComponentWindow";
import AddAllergyWindow from "./AddAllergyWindow";
import AddMedicationsWindow from "./AddMedicationsWindow";
import AddHospitalizationsSurgeriesWindow from "./AddHospitalizationsWindow";
import AddSurgeriesWindow from "./AddSurgeriesWindow";
import CareTeamWindow from "./CareTeamWindow";
import PatientListOnGraph from "../PatientPanelComponents/PatientListOnGraph";
import CreatePortalRegistrationWindow from "./CreatePortalRegistrationWindow";
import CreateNewUserWindow from "./EditUserEPrescribeWindow";
import ActivityLevelBarWindow from "./ActivityLevelBarWindow";
import EditCounterWindow from "./EncounterWindow";
import EncounterCodingWindow from "./EncounterCodingWindow";
import SignEncounterWindow from "./SignEncounterWindow";
import CreateDocumentWindow from './CreateDocumentWindow';
import CreateRequestDocumentWindow from './CreateRequestDocumentWindow ';
import WeightManagementBarWindow from "./WeightManagementBarWindow";
import HeartRateManagementBarWindow from "./HeartRateManagementBarWindow";
import HeartRateVariabilityManagementBarWindow from "./HeartRateVariabilityManagementBarWindow";
import VitalWindow from "./VitalWindow";
import SocialHistoryWindow from "./SocialHistoryWindow";
import FamilyHistoryWindow from "./FamilyHistoryWindow";
import ShowDocumentWindow from "./ShowDocumentWindow";
import AddGaolToConditionWindow from "./AddGoalToConditionWindow";
import AddStrategyToGoalWindow from "./AddStrategyToGoalWindow";
import InsurancePlanWindow from "./InsurancePlanWindow";
import CreateQueryPanelWindow from "./CreateQueryPanelWindow";
import FormReportsWindow from "./FormReportsWindow";
import AddLabVendorWindow from "./AddLabVendorWindow";
import AddLabRouteWindow from "./AddLabRouteWindow";
import PdfViewerWindow from "./PdfViewerWindow";
import AddPatientInstructionsWindow from "./AddPatientInstructionsWindow";
import SignatureWindow from "./SignatureWindow";
import PopupWindow from "../../typescript/component/popup/PopupWindow";

// Add new popups here
const PopupWindowsComponent = (props) => {
    return (
        <>
            <div className={"ah_popup_windows"}>
                <ChartViewerWindow/>
                <ExpandableWindow/>
                <AddPatientWindow/>
                {/*<AddUserWindow/>*/}
                <EditUserWindow/>
                <EditUserProfileWindow/>
                <CarePlanComponentWindow/>
                <NotificationDetailsWindow/>
                <AddMedicalProblemWindow/>
                <SearchDiagnosisCodeWindow/>
                <SearchRxNormCodeWindow/>
                <SearchSurgicalProcedureCodeWindow/>
                <AddImmunizationWindow/>
                <SearchImmunizationWindow/>
                <SearchMFImmunizationWindow/>
                <AddNewRequestWindow/>
                {/*<EditNewRequestWindow/>*/}
                <EditFacilityWindow/>
                <AddClinicalOrderWindow/>
                <AddCodeComponentWindow/>
                <AddDictionaryComponentWindow/>
                <QualityComponentWindow/>
                <AddAllergyWindow/>
                <AddMedicationsWindow/>
                <AddHospitalizationsSurgeriesWindow/>
                <AddSurgeriesWindow />
                <CareTeamWindow/>
                <PatientListOnGraph/>
                <CreatePortalRegistrationWindow/>
                <CreateNewUserWindow/>
                <ActivityLevelBarWindow/>
                <EditCounterWindow/>
                <SignEncounterWindow/>
                <CreateDocumentWindow/>
                <CreateRequestDocumentWindow/>
                {/*<WeightManagementBarWindow/>*/}
                {/*<HeartRateManagementBarWindow/>*/}
                {/*<HeartRateVariabilityManagementBarWindow/>*/}
                <VitalWindow />
                <SocialHistoryWindow />
                <EncounterCodingWindow />
                <FamilyHistoryWindow />
                <ShowDocumentWindow/>
                <AddGaolToConditionWindow/>
                <AddStrategyToGoalWindow/>
                <CreateQueryPanelWindow/>
                <InsurancePlanWindow/>
				<FormReportsWindow/>
                <AddLabVendorWindow/>
                <AddLabRouteWindow/>
                <PdfViewerWindow/>
                <AddPatientInstructionsWindow/>
                <SignatureWindow/>
                {/*<PopupWindow/>*/}
            </div>
        </>
    )


}

export default PopupWindowsComponent
