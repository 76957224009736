import {Button} from "primereact/button";
import {Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import PropTypes from "prop-types";
import * as React from "react";
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {PatientAction} from "../../action/PatientAction";
import {PersonActions} from "../../action/PersonAction";
import {EncounterAction} from "../../action/EncounterAction";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {adjustForTimezone} from "../../util/DateTools";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {
    Field,
    FieldDatePicker,
    FieldDictionaryDropDown,
    FieldDictionarySearchList,
    FieldMultiProblemSelector,
    FieldRow,
    FieldSection,
    FieldTextAreaInput,
    FieldTextInput
} from "../InputControls/FormFields";
import _ from "lodash";
import moment from "moment";
import {PermissionsTool} from "../../util/PermissionsTool";
import {Dialog} from "primereact/dialog";
import {Row} from "../../layout/GridLayout";
import {v4 as uuidv4} from "uuid";
import {ProgressSpinner} from 'primereact/progressspinner';
import {useComponentUpdate} from "../../typescript/context/ComponentUpdateProvider";
import {Components} from "../shared/ComponentRegistry";

const AddMedicationsWindow = (props) => {
    const {update} = useComponentUpdate()
    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        watch,
        control,
        getValues,
        setValue,
        formState: {errors, isDirty, isSubmitting, touchedFields, submitCount},
    } = useForm({defaultValues: {"note": ""}});
    const {patientContext, PatientActions, popupState} = props
    const [disableMedicationName, setDisableMedicationName] = React.useState(false)
    const [medicationContext, setMedicationContext] = React.useState(props.popupState[PopupWindows.ADD_MEDICATIONS_WINDOW]?.medicationContext)
    const [medicationPermission, setMedicationPermission] = React.useState({isEdit: false, isView: false})
    const [isReset, setIsReset] = React.useState(true)
    const [clinicalStatusChange, setClinicalStatusChange] = React.useState(null)
    const endDateClinicalStatus = ["AH_MEDICATION_CLINICAL_INACTIVE", "AH_MEDICATION_CLINICAL_DC_BY_PATIENT", "AH_MEDICATION_CLINICAL_DC_BY_PROVIDER"]

    const resetForm = () => {
        props.PopupActions.clearWindowState(PopupWindows.ADD_MEDICATIONS_WINDOW).then(() => {
            reset({
                dictClinicalStatusId: props?.clinicalStatus?.find(v => v.code === "AH_MEDICATION_CLINICAL_ACTIVE"),
                displayName: null,
                medicalProblems: [],
                dose: null,
                dictMedicationId: null,
                note: null,
                startDate: adjustForTimezone(new Date()),
                endDate: null,
                dictSourceOfInfoId: null,
                dictUnitId: null,
                dictFormulationId: null,
                dictRouteId: null,
                dictFrequencyId: null,

            });
        })
    }

    const hideDialog = () => {
        resetForm()
        props.PopupActions.hideWindow(PopupWindows.ADD_MEDICATIONS_WINDOW);

    };

    React.useEffect(() => {
        setMedicationPermission(PermissionsTool({
            permissionInfo: props.permissionInfo,
            viewPermissions: ["AH_VIEW_MEDICATION"],
            editPermissions: ["AH_EDIT_MEDICATION"]
        }));
    }, [props?.permissionInfo]);

    useEffect(() => {
        setMedicationContext(props.popupState[PopupWindows.ADD_MEDICATIONS_WINDOW]?.medicationContext)
    }, [popupState])


    useEffect(() => {
        if (medicationContext) {
            setValue("dictClinicalStatusId", medicationContext.dictClinicalStatusId)
            setValue("displayName", medicationContext.displayName)
            setValue("medicalProblems", medicationContext?.medicalProblems ? medicationContext.medicalProblems : [])
            setValue("dose", medicationContext?.doseAmount)
            setValue("dictMedicationId", medicationContext?.dictMedicationId)
            setValue("note", medicationContext?.note)
            if (medicationContext?.startDate) {
                setValue("startDate", adjustForTimezone(new Date(medicationContext?.startDate)))
            } else {
                setValue("startDate", adjustForTimezone(new Date()))
            }
            setValue("dictSourceOfInfoId", medicationContext.dictSourceOfInfoId)
            setValue("dictUnitId", medicationContext?.dictUnitId)
            setValue("dictFormulationId", medicationContext?.dictFormulationId)
            setValue("dictRouteId", medicationContext?.dictRouteId)
            setValue("dictFrequencyId", medicationContext?.dictFrequencyId)
            if (medicationContext?.endDate) setValue("endDate", adjustForTimezone(new Date(medicationContext?.endDate)))
        } else {
            setValue("startDate", adjustForTimezone(new Date()))
        }
    }, [medicationContext])

    useEffect(() => {
        if (!medicationContext?.endDate && clinicalStatusChange) {
            setValue("endDate", adjustForTimezone(new Date()))
        } else if (medicationContext?.endDate) {
            setValue("endDate", adjustForTimezone(new Date(medicationContext?.endDate)))
        }
    }, [clinicalStatusChange])

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'dictMedicationId') {
                let v = value.dictMedicationId
                if (v === null) {
                    setDisableMedicationName(false)
                } else {
                    if (!_.isNil(v?.displayName)) {
                        setValue("displayName", getValues("dictMedicationId")?.displayName)
                        setDisableMedicationName(true)
                    }
                }
            } else if (name === 'dictClinicalStatusId') {
                let v = value?.dictClinicalStatusId?.id || value?.dictClinicalStatusId;
                if (v) {
                    const find = props?.clinicalStatus?.find(f => f.id === v && endDateClinicalStatus.indexOf(f.code) > -1);
                    if (find) {
                        setClinicalStatusChange(find)
                    } else {
                        setClinicalStatusChange(null)
                    }
                } else {
                    setClinicalStatusChange(null)
                }
            }
        })
        return () => subscription.unsubscribe();
    }, [watch && props?.clinicalStatus]);

    const saveMedicationAndClear = async (form) => {
        let payload = {};
        payload.dictClinicalStatusId = form?.dictClinicalStatusId?.id ? form.dictClinicalStatusId.id : form.dictClinicalStatusId;
        payload.displayName = form.displayName;
        payload.description = form.displayName;
        payload.dictMedicationId = form?.dictMedicationId?.id ? form.dictMedicationId.id : form.dictMedicationId;
        payload.startDate = form?.startDate ? moment(form?.startDate).format("YYYY-MM-DD") : null
        payload.endDate = form?.endDate ? moment(form?.endDate).format("YYYY-MM-DD") : null
        payload.patientRoleId = patientContext.patientRoleId
        payload.dictSourceOfInfoId = form?.dictSourceOfInfoId?.id ? form.dictSourceOfInfoId.id : form.dictSourceOfInfoId;
        payload.doseAmount = form.dose;
        payload.dictUnitId = form?.dictUnitId?.id ? form.dictUnitId.id : form.dictUnitId;
        payload.dictFormulationId = form?.dictFormulationId?.id ? form.dictFormulationId.id : form.dictFormulationId;
        payload.dictRouteId = form?.dictRouteId?.id ? form.dictRouteId.id : form.dictRouteId;
        payload.dictFrequencyId = form?.dictFrequencyId?.id ? form.dictFrequencyId.id : form.dictFrequencyId;
        payload.note = form.note;
        payload.medicalProblems = form.medicalProblems?.length > 0 ? form.medicalProblems.map(p => {
            return p.id
        }) : []
        payload.medicationId = uuidv4();
        setIsReset(false);
        PatientActions.createMedications(payload).then(() => {
            update(Components.MedicationsComponent)
            if (props?.encounterContext?.id) {
                props.EncounterAction.saveEncounterMedications({
                    encounterId: props?.encounterContext?.id,
                    issueId: payload.medicationId
                })
            } else {
                props.EncounterAction.setMedicationId({id: payload.medicationId});
            }
            resetForm();
            setValue("dictClinicalStatusId", props?.clinicalStatus?.find(v => v.code === "AH_MEDICATION_CLINICAL_ACTIVE"),)
            setValue("displayName", null)
            setValue("medicalProblems", [])
            setValue("dose", null)
            setValue("dictMedicationId", null)
            setValue("note", null)
            setValue("startDate", adjustForTimezone(new Date()))
            setValue("endDate", null)
            setValue("dictSourceOfInfoId", null)
            setValue("dictUnitId", null)
            setValue("dictFormulationId", null)
            setValue("dictRouteId", null)
            setValue("dictFrequencyId", null)
            setIsReset(true)

        });
    }
    const saveMedication = async (form) => {
        let payload = {};
        payload.dictClinicalStatusId = form?.dictClinicalStatusId?.id ? form.dictClinicalStatusId.id : form.dictClinicalStatusId;
        payload.displayName = form.displayName;
        payload.description = form.displayName;
        payload.dictMedicationId = form?.dictMedicationId?.id ? form.dictMedicationId.id : form.dictMedicationId;
        payload.startDate = form?.startDate ? moment(form?.startDate).format("YYYY-MM-DD") : null
        payload.endDate = form?.endDate ? moment(form?.endDate).format("YYYY-MM-DD") : null
        payload.patientRoleId = patientContext.patientRoleId
        payload.dictSourceOfInfoId = form?.dictSourceOfInfoId?.id ? form.dictSourceOfInfoId.id : form.dictSourceOfInfoId;
        payload.doseAmount = form.dose;
        payload.dictUnitId = form?.dictUnitId?.id ? form.dictUnitId.id : form.dictUnitId;
        payload.dictFormulationId = form?.dictFormulationId?.id ? form.dictFormulationId.id : form.dictFormulationId;
        payload.dictRouteId = form?.dictRouteId?.id ? form.dictRouteId.id : form.dictRouteId;
        payload.dictFrequencyId = form?.dictFrequencyId?.id ? form.dictFrequencyId.id : form.dictFrequencyId;
        payload.note = form.note;
        payload.medicalProblems = form.medicalProblems?.length > 0 ? form.medicalProblems.map(p => {
            return p.id
        }) : []

        if (medicationContext) {
            payload.medicationId = medicationContext.medicationId
            payload.medicationPrescriptionId = medicationContext.medicationPrescriptionId
            PatientActions.updateMedications(payload).then(() => {
                update(Components.MedicationsComponent)
                if (!props?.medicationsSel?.find(v => v.medicationId === medicationContext.medicationId)) {
                    if (props?.encounterContext?.id) {
                        props.EncounterAction.saveEncounterMedications({
                            encounterId: props?.encounterContext?.id,
                            issueId: medicationContext.medicationId
                        })
                    } else {
                        props.EncounterAction.setMedicationId({id: medicationContext.medicationId});
                    }
                }
                hideDialog();
            })


        } else {
            payload.medicationId = uuidv4();
            PatientActions.createMedications(payload).then(() => {
                update(Components.MedicationsComponent)
                    if (props?.encounterContext?.id) {
                        props.EncounterAction.saveEncounterMedications({
                            encounterId: props?.encounterContext?.id,
                            issueId: payload.medicationId
                        })
                    } else {
                        props.EncounterAction.setMedicationId({id: payload.medicationId});
                    }
                    hideDialog();
                })

        }

    };
    const canEditable = () => {
        if (medicationPermission.isEdit) {
            return {pointerEvents: 'block'};
        } else if (medicationPermission.isView) {
            return {pointerEvents: 'none'};
        } else {
            return {};
        }
    }

    // if (props.showWindow[PopupWindows.ADD_MEDICATIONS_WINDOW])
    return (
        <Dialog
            visible={props.showWindow[PopupWindows.ADD_MEDICATIONS_WINDOW]}
            style={{width: "80%"}}
            header={medicationContext ? "Edit Medication" : "Add Medication"} onHide={hideDialog}
            footer={<Row className={"justify-content-end"}>
                {!medicationContext && <Button
                    onClick={handleSubmit(saveMedicationAndClear)}
                    label={"Save and Add New"}
                />}
                <Button
                    onClick={handleSubmit(saveMedication)}
                    label={medicationContext ? "Update" : "Save and Close"}
                    severity={"success"}
                />
                <Button onClick={hideDialog}
                        severity={"danger"}
                        label={"Cancel"}/>
            </Row>}
            initialWidth={800} initialHeight={850} modal={true}>
            <div style={canEditable()}>
                {isReset ? <FieldSection>
                    <FieldRow>
                        <Field control={control} col={6} label={"Clinical Status"} field={"dictClinicalStatusId"}
                               register={register}
                               error={errors} options={{required: "Clinical Status is required."}}>
                            <FieldDictionaryDropDown code={Dictionary.MEDICATION_PROBLEM_STATUS}
                                                     defaultItemCode={medicationContext ? Dictionary.PROBLEM_STATUS : "AH_MEDICATION_CLINICAL_ACTIVE"}/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field control={control} col={12} label={"Search Medication"} field={"dictMedicationId"}
                               register={register}

                               error={errors}>
                            <FieldDictionarySearchList code={Dictionary.AH_RXNORM}/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field control={control} col={12} label={"Medication Name"} field={"displayName"}
                               register={register}
                            //disabled={disableMedicationName}
                               error={errors} options={{required: "Medication Name is required"}}>
                            <FieldTextInput/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field control={control} col={12} label={"Link to Clinical Problems"} field={"medicalProblems"}
                               register={register} error={errors}>
                            <FieldMultiProblemSelector style={{height: "250px"}} height={"400px"}/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field control={control} col={6} label={"Start Date"} field={"startDate"} error={errors}

                               register={register}>
                            <FieldDatePicker/>
                        </Field>
                        <Field control={control} col={6} label={"End Date"} field={"endDate"} error={errors}

                               register={register}>
                            <FieldDatePicker/>
                        </Field>

                    </FieldRow>
                    <FieldRow>
                        <Field control={control} col={6} label={"Source of Info"} field={"dictSourceOfInfoId"}
                               register={register}
                               error={errors}>
                            <FieldDictionaryDropDown code={Dictionary.AH_CONDITION_SOURCE}/>
                        </Field>

                        <Field control={control} col={6} label={"Formulation"} field={"dictFormulationId"}
                               register={register}
                               error={errors}>
                            <FieldDictionaryDropDown code={Dictionary.AH_MEDICATION_FORMULATION}/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field control={control} col={6} label={"Dose Amount"} field={"dose"} register={register}
                               error={errors}>
                            <FieldTextInput/>
                        </Field>
                        <Field control={control} col={6} label={"Dose Units"} field={"dictUnitId"} error={errors}

                               options={{
                                   required: "",
                                   validate: (value) => {
                                       if (!value && getValues('dose'))
                                           return "Required when dose is provided";
                                       return true;
                                   }
                               }}
                               register={register}>
                            <FieldDictionaryDropDown code={Dictionary.AH_MEDICATION_UNITS}/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                    </FieldRow>
                    <FieldRow>
                        <Field control={control} col={6} label={"Route"} field={"dictRouteId"} register={register}
                               error={errors}>
                            <FieldDictionaryDropDown code={Dictionary.AH_MEDICATION_ROUTE}/>
                        </Field>
                        <Field control={control} col={6} label={"Frequency"} field={"dictFrequencyId"}
                               register={register}
                               error={errors}>
                            <FieldDictionaryDropDown code={Dictionary.AH_MEDICATION_FREQUENCY}/>
                        </Field>

                    </FieldRow>
                    <FieldRow>
                        <Field control={control} col={12} label={"Notes"} field={"note"} error={errors}
                               register={register}>
                            <FieldTextAreaInput/>
                        </Field>
                    </FieldRow>

                </FieldSection> : <ProgressSpinner/>}

            </div>
        </Dialog>)
}

AddMedicationsWindow.propTypes = {
    showWindow: PropTypes.object,
    PopupActions: PropTypes.object,
    PatientActions: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        patientContext: state.patient.patientContext,
        encounterContext: state.encounters.encounterContext,
        medicationsSel: state.encounters.medications,
        clinicalStatus: state?.dictionary?.dictionaries[Dictionary.MEDICATION_PROBLEM_STATUS],
        contactAddress: state.person.contactAddress,
        patient: state.patient.patientContext,
        popupState: state.popupWindow.state,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        EncounterAction: bindActionCreators(EncounterAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMedicationsWindow);
