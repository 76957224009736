import {PropTypes} from "prop-types";

function GutterProp(b) {
    return b ? "" : "grid-nogutter"
}

function AlignContent(c) {
    if (c)
        return `align-content-${c}`
    else
        return ''
}

function FlexRow(c) {
    if (c)
        return `flex-row-${c}`
    else
        return ''
}

export function GridLayout(props) {
    const {className, gutter = false, style = {}, children} = props;
    let c = `grid ${className ? `${className} ` : ''}${GutterProp(gutter)}`.trim();

    return (
        <div className={c} style={style} onClick={props.onClick}>
            {children}
        </div>
    );
}

GridLayout.propTypes = {
    gutter: PropTypes.bool,
};

function ComputeColumn(size, prefix) {
    if (size)
        return prefix ? `${prefix}:col-${size}` : `col-${size}`
    else
        return "col"
}

function ComputeOffset(size) {
    if (size)
        return `col-offset-${size}`
    else
        return ""
}

function ComputeClassName(props) {

    return `${props?.className ? `${props.className} ` : ''}${ComputeColumn(props.col)} ${GutterProp(props.gutter)} ${ComputeOffset(props.offset)} ${AlignContent(props.alignContext)}`
        .trim();
}

export function ColumnLayout(props) {
    const {
        style = {},
        children,
    } = props;

    return (
        <div className={ComputeClassName(props)} style={style}>
            {children}
        </div>
    );
}

ColumnLayout.defaultProps = {
    className: null,
    gutter: false,
    offset: null,
    alignContext: null,
    flexRow: null
}


export const Col = ColumnLayout

export const Row = (props) => {
    return GridLayout({
        ...props, style: {
            width: "100%", ...props?.style ? props.style : {}
        }
    })
}

export const Container = GridLayout
