import * as React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {PatientAction} from "../../action/PatientAction";
import {AuthAction} from "../../action/AuthAction";
import {EncounterAction} from "../../action/EncounterAction";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {Button} from "primereact/button";
import {Col, Container, Row} from "../../layout/GridLayout";
import {Dialog} from "primereact/dialog";
import {
    FormColumn,
    FormNumberInput,
    FormPasswordInput,
    FormRow,
    FormUserListDropdown,
    FormTextInput,
    usePhixForm, FormSeparator
} from "../InputControls/NewFormFields";
import {Label} from "@progress/kendo-react-labels";
import {Colors} from "../../config/GlobalConfig";

const SignEncounterWindow = (props) => {
    const defaultValues = {
        password: null,
        amendment: null,
        hours: null,
        minutes: null,
        supervisingProvider: null,
        username: props.loggedinUser.userName
    }
    const [{
        handleSubmit,
        setValue,
        reset,
    }, PhixForm] = usePhixForm({defaultValues: defaultValues});

    const [error, setError] = React.useState("");

    const [signEncounterContext, setSignEncounterContext] = React.useState(props.popupState[PopupWindows.SIGN_ENCOUNTER_WINDOW]?.signEncounterContext);

    React.useEffect(() => {
        props.DictionaryActions.getDictionaryByCode("AH_ENCOUNTER_STATUS");
    }, []);

    React.useEffect(() => {
        if (signEncounterContext?.id) {
            if (signEncounterContext?.elapsedTime) {
                const milliseconds = signEncounterContext?.elapsedTime;
                const minutes = parseInt((milliseconds / (1000 * 60)) % 60);
                const hours = parseInt((milliseconds / (1000 * 60 * 60)) % 24);
                setValue("hours", hours);
                setValue("minutes", minutes);
            }
        }
    }, [signEncounterContext]);

    React.useEffect(() => {
        if (props.encounterContext?.id) {
            console.log(props.encounterContext)
            setSignEncounterContext(props.encounterContext);
        }
    }, [props.popupState]);

    const hideDialog = async () => {
        setError("");
        setSignEncounterContext(undefined);
        await reset(defaultValues)
        props.PopupActions.hideWindow(PopupWindows.SIGN_ENCOUNTER_WINDOW);
    };

    const saveSignEncounters = (form) => {
        let userName = props.loggedinUser.userName;
        let password = form?.password;
        props.AuthActions.verifyPassword(userName, password).then(response => {
            if (response === true) {
                if (form?.supervisingProvider && form?.supervisingProvider === props.loggedinUser.userRoleId) {
                    setError("Supervising Provider may not be logged in user");
                } else {
                    let savePayload = {
                        encounterId: signEncounterContext.id,
                        superVisingProviderRoleId: form?.supervisingProvider,
                        addendum: form?.amendment,
                        timeSpent: parseInt(form.hours ? form.hours * 60 : 0) + parseInt(form.minutes ? form.minutes : 0),
                        dictEncounterStatusId: props.dictionaries['AH_ENCOUNTER_STATUS'].find(v => v.code === "Signed").id
                    };
                    props.EncounterAction.saveEncounterSignature(savePayload).then(() => {
                        let updates = {};
                        updates.isLocked = true;
                        updates.dictEncounterStatusId = savePayload.dictEncounterStatusId;
                        updates.supervisingProviderRoleId = savePayload.supervisingProviderRoleId;
                        updates.dictEncounterStatusCode = "Signed";
                        updates.dictEncounterStatusDisplayName = "Signed";
                        props.EncounterAction.setEncounterContext({...signEncounterContext, ...updates});
                        hideDialog();
                    });
                }
            } else {
                setError("Invalid password");
            }
        });
    }

    const EncounterUserHeader = () => {
        let encounterTypeDescription = signEncounterContext ? signEncounterContext.dictEncounterTypeDescription : "";
        let patientName = (props?.patient?.firstName ? props?.patient?.firstName + " " : "") +
            (props.patient.middleName ? props.patient.middleName + " " : "") +
            (props.patient.lastName ? props.patient.lastName : "");
        let dos = signEncounterContext && signEncounterContext.encounterDate ? new Date(signEncounterContext.encounterDate).toLocaleDateString('en-US') : new Date().toLocaleDateString('en-US');
        let provideName = (signEncounterContext && signEncounterContext.providerFirstName ? signEncounterContext.providerFirstName : props.loggedinUser.firstName) +
            " " + (signEncounterContext && signEncounterContext.providerLastName ? signEncounterContext.providerLastName : props.loggedinUser.lastName);

        return (<Container>
                <Row>
                    <Col col={12}>
                        <span style={{fontSize: "16px", fontWeight: "bold"}}>{encounterTypeDescription} </span>
                        <span style={{fontSize: "16px"}}>  for Patient:
                            <span style={{fontSize: "16px"}}> {patientName}</span>
                        </span>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col col={6}>
                <span style={{
                    fontSize: "16px",
                    fontWeight: "bold"
                }}> DOS: </span>
                        <span style={{fontSize: "16px"}}> {dos} </span>
                    </Col>
                    <Col col={6}>
                        <span style={{
                            fontSize: "16px",
                            fontWeight: "bold"
                        }}> &nbsp;&nbsp;&nbsp;&nbsp; Provider:  </span>
                        <span style={{fontSize: "16px"}}> {provideName} </span>
                    </Col>

                </Row>
                <FormSeparator/>
            </Container>
        )


    }

    const footer = () => {
        return (
            <Container className={"justify-content-end"}>
                <Row className={"justify-content-end"}>
                    <Button onClick={handleSubmit(saveSignEncounters)} label={"Sign and Complete"}
                            icon={"pi pi-pencil"}
                            severity={"success"}/>
                    <Button onClick={hideDialog} label={"Cancel"} severity={"danger"} icon={"pi pi-times"}/>

                    <Col col={12} style={{textAlign: "center", paddingTop: 10}}>
                        <b style={{fontSize: 15, color: Colors.RED}}><i>Signing a completed encounter will prevent
                            further edits.</i></b>
                    </Col>
                </Row>
                <Row>
                    {error !== "" && <div className="k-error-colored">
                        {error}
                    </div>}
                </Row>
            </Container>
        )
    }


    return (
        <Dialog header={"Sign Encounter"} className="sign-encounter-window"
                visible={props.showWindow[PopupWindows.SIGN_ENCOUNTER_WINDOW]}
                maximizable={false}
                resizable={false}
                footer={footer}
                style={{width: "60vw", height: "70vh"}}
                onHide={hideDialog}>
            <EncounterUserHeader/>
            <form>
                <PhixForm>

                    <FormColumn col={6}>
                        <input id="username" style={{display: "none"}} type="text" name="fakeusernameremembered"/>

                    </FormColumn>
                    <FormColumn col={6}>
                        <FormPasswordInput passwordFix
                                           label={"Enter your password to sign:"} field={"password"}
                                           rules={{required: "Password is required."}}/>

                    </FormColumn>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormTextInput textArea label={"Amendment (Optional)"} field={"amendment"}/>
                        </FormColumn>
                    </FormRow>
                    <FormSeparator/>
                    <FormRow>
                        <Col col={2}>
                            <Label>
                                Time Spent (Optional)
                            </Label>
                        </Col>
                        <FormColumn col={3}>
                            <FormNumberInput field={"hours"} label={"Hours Spent"}/>
                        </FormColumn>
                        <FormColumn col={3}>
                            <FormNumberInput field={"minutes"} label={"Minutes Spent"}/>
                        </FormColumn>
                    </FormRow>
                    <FormSeparator/>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormUserListDropdown field={"supervisingProvider"}
                                                  label={"Supervising Provider (Optional)"}/>
                        </FormColumn>

                    </FormRow>
                </PhixForm>
            </form>
            {/*<FieldSection>*/}
            {/*    <FieldRow>*/}
            {/*        <input type={"text"} hidden/>*/}
            {/*        <Field col={5} label={"Enter your password to sign:"} field={"password"} error={errors}*/}
            {/*               option={{required: "Password is required."}}*/}
            {/*               register={register}*/}
            {/*               control={control}>*/}
            {/*            <FieldPasswordInput/>*/}
            {/*        </Field>*/}
            {/*    </FieldRow>*/}
            {/*    <FieldRow>*/}
            {/*        <Field col={12} label={"Amendment (Optional)"} field={"amendment"} error={errors}*/}
            {/*               register={register}*/}
            {/*               control={control}>*/}
            {/*            <FieldTextAreaInput/>*/}
            {/*        </Field>*/}
            {/*    </FieldRow>*/}
            {/*    <FieldRow>*/}
            {/*        <Field col={4} label={"Time spent (Optional)"} field={"empty2"}*/}
            {/*               error={errors}*/}
            {/*               register={register}*/}
            {/*               control={control}>*/}
            {/*            <></>*/}
            {/*        </Field>*/}
            {/*        <Field col={2} label={"Hour(s)"} field={"hours"} error={errors}*/}
            {/*               register={register}*/}
            {/*               control={control}>*/}
            {/*            <FieldNumberInput field={"hours"}/>*/}
            {/*        </Field>*/}
            {/*        <Field col={2} label={"Minutes"} field={"minutes"} error={errors}*/}
            {/*               register={register}*/}
            {/*               control={control}>*/}
            {/*            <FieldNumberInput field={"minutes"}/>*/}
            {/*        </Field>*/}
            {/*    </FieldRow>*/}
            {/*    <br/>*/}
            {/*    <FieldRow>*/}
            {/*        <Field col={4} label={"Supervising Provider (Optional)"} field={"empty5"}*/}
            {/*               error={errors}*/}
            {/*               register={register}*/}
            {/*               control={control}>*/}
            {/*            <></>*/}
            {/*        </Field>*/}
            {/*        <Field col={4} label={""} field={"supervisingProvider"} error={errors}*/}
            {/*               register={register}*/}
            {/*               control={control}>*/}
            {/*            <FieldUserDropDown/>*/}
            {/*        </Field>*/}
            {/*    </FieldRow>*/}
            {/*</FieldSection>*/}
            <br/>

        </Dialog>

    )

}

function mapStateToProps(state) {
    return {
        loggedinUser: state.user.loggedinUser,
        showWindow: state.popupWindow.showWindow,
        patient: state.patient.patientContext,
        popupState: state.popupWindow.state,
        userList: state.user.userList,
        dictionaries: state.dictionary.dictionaries,
        encounterContext: state.encounters.encounterContext,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        PatientAction: bindActionCreators(PatientAction, dispatch),
        EncounterAction: bindActionCreators(EncounterAction, dispatch),
        AuthActions: bindActionCreators(AuthAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignEncounterWindow);