import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    ASSESSMENT_LIST_ASSESSMENTS: "ASSESSMENT_LIST_ASSESSMENTS "
}

// vitals list
function getAssessmentsForPatient(patientRoleId, limit, offset) {
    return WrapAuthentication("assessment/patient/get",
        {payload: {patientRoleId: patientRoleId}, "limit": limit, "offset": offset},
        ActionTypes.ASSESSMENT_LIST_ASSESSMENTS)
}


export const AssessmentAction =
    {
        getAssessmentsForPatient
    }