import {AuthAction} from "../action/AuthAction";
import {useDispatch, useSelector} from "react-redux";
import {Col, Container, Row} from "../layout/GridLayout";
import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Avatar} from "@progress/kendo-react-layout";
import {Form, FormElement} from "@progress/kendo-react-form";
import {Button} from "primereact/button";
import {BiReset} from "react-icons/bi";
import {GiCancel, GiReturnArrow} from "react-icons/gi";
import queryString from "query-string";
import {useForm} from "react-hook-form";
import {
    Field as AHField,
    FieldPasswordInput,
    FieldRow,
    FieldSection,
    FieldTextInput,
} from "../components/InputControls/FormFields";
import {Colors, TENANT_ID} from "../config/GlobalConfig";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {LoadingActions} from "../action/LoadingAction";
import {ConfigurationActions} from "../action/ConfigurationAction";
import {PatientAction} from "../action/PatientAction";
import moment from "moment";
import {UserActions} from "../action/UserAction";
import {useToast} from "../providers/toast/ToastProvider";
import {
    FormColumn,
    FormDateSelector,
    FormPasswordInput,
    FormRow,
    FormTextInput,
    usePhixForm,
} from "../components/InputControls/NewFormFields";

const styles = {
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 120px)",
        padding: 10,
        width: "100%",
    },
    container: {
        padding: 0,
        margin: "auto",
        border: "1px solid #75852C",
        borderRadius: "0.5rem",
        width: "80%",
    },
    row: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    col: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    colLogo: {
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        borderRadius: "2rem",

        // height: "100%",
        // paddingLeft: 5,
        // paddingBottom: 5,
        // paddingTop: 5,
        // paddingRight: 5,
    },
    notification: {
        right: 0,
        bottom: 0,
        alignItems: "flex-start",
        flexWrap: "wrap-reverse",
    },
    logo: {
        // maxWidth: "550px",
        width: "100%",
        height: "auto",
        padding: 25,
        // margin: 30,
        // paddingLeft: 20,
        // paddingBottom: 5,
        // paddingTop: 20,
        // paddingRight: 5,
    },
    avatar: {
        width: "100%",
        height: "100%",
        padding: "20px",
    },
    formElement: {
        //float: "right",
        height: "auto",
        //width: "90%",
        //maxWidth: "350px",
        //marginLeft: "2rem"
    },
    btnIcon: {
        paddingTop: "2px",
        height: "20px",
    },
    loginBtn: {
        width: "100%",
        color: "white",
        height: "45px",
        fontWeight: "bolder",
        marginTop: "1rem",
        fontSize: "1.5rem",
        textAlign: "center",
        // marginLeft: "9rem",
    },
    returnBtn: {
        width: "100%",
        maxWidth: "250px",
        height: "auto",
        fontWeight: "bolder",
        marginTop: "1rem",
        fontSize: "large",
        marginLeft: "5rem",
    },
    text: {
        width: "100%",
        maxWidth: "300px",
    },
    legend: {
        width: "100%",
        marginLeft: "1rem",
        fontWeight: "bolder",
        fontSize: "xx-large",
    },
};

const WORKFLOWS = {
    PORTAL_REGISTER: "PORTAL_REGISTER",
    FORGOT_PASSWORD_FORM: "forgotPassword",
    RESET_REQUEST_SENT: "resetRequest",
    LOGIN_FORM: "login",
    RESET: "reset",
    MFA: "mfacode",
    LOGIN_AFTER_RESET: "loginAfterReset",
    CHANGE_PASSWORD: "changePassword",
};

// let phixLogo = '../images/logo/Aerios_Logo_Stacked_2C_Reverse_RGB.png';
let phixLogo = "../images/phix/Phix logo-WhiteBlue.png";

const PasswordResetForm = (props) => {
    const dispatch = useDispatch();
    const resetNewPassword = useSelector((state) => state.auth.resetNewPassword);
    const {token, setWorkflow, isChangePassword, username} = props;
    const {
        register,
        handleSubmit,
        setError,
        formState: {errors},
    } = useForm();
    const history = useHistory();

    const [message, setMessage] = React.useState({error: false, message: ""});
    const [successfullyUpdate, setSuccessfullyUpdate] = React.useState(false);
    const gotoLogin = () => {
        history.push("/login");
        setWorkflow(WORKFLOWS.LOGIN_FORM);
    };
    const handleResponse = (res) => {
        let success = res.payload;
        if (res?.message) {
            setMessage({message: res?.message, error: !success});
            setSuccessfullyUpdate(success);
            if (success && isChangePassword) {
                gotoLogin();
            }
        } else {
            if (resetNewPassword) gotoLogin();
        }
    };
    const ResetPassword = async (form) => {
        if (form.password !== form.repassword) {
            setError("password", {
                type: "custom",
                message: "Passwords do not match",
            });
            setError("repassword", {
                type: "custom",
                message: "Passwords do not match",
            });
        } else {
            let {payload} = await AuthAction.checkPasswordExpression(form.password)(
                dispatch
            );
            if (payload === false) {
                let errorMessage =
                    "Please enter a valid password. Password must contain at least 8 characters and contain 1 lowercase character, 1 uppercase character, 1 number, and 1 special character (!@#$%^&)";
                setError("repassword", {type: "custom", message: errorMessage});
            } else {
                if (isChangePassword === true) {
                    username &&
                    AuthAction.changePasswordByUsername(
                        form.password,
                        username
                    )(dispatch).then((res) => res && handleResponse(res));
                } else {
                    if (token) {
                        AuthAction.forgotPasswordReset(
                            token,
                            form.password
                        )(dispatch).then((res) => res && handleResponse(res));
                    } else {
                        this.setState({isTokenValid: true});
                    }
                }
            }
        }
    };

    return (
        <FieldSection
            style={{width: "100%", marginRight: 10}}
            className={"flex align-items-center justify-content-center"}
        >
            <Col className={"flex-grow-1"}/>
            <FieldRow>
                <AHField
                    col={12}
                    label={"Password"}
                    field={"password"}
                    register={register}
                    error={errors}
                    options={{required: "This field is required"}}
                >
                    <FieldPasswordInput/>
                </AHField>
            </FieldRow>
            <FieldRow>
                <AHField
                    col={12}
                    label={"Confirm Password"}
                    field={"repassword"}
                    register={register}
                    error={errors}
                    options={{required: "This field is required"}}
                >
                    <FieldPasswordInput/>
                </AHField>
            </FieldRow>
            <FieldRow>
                <Col>
                    {message.error ? (
                        <b style={{color: "red"}}>{message.message}</b>
                    ) : (
                        <b style={{color: "green"}}>{message.message}</b>
                    )}
                </Col>
            </FieldRow>

            {successfullyUpdate ? (
                <FieldRow>
                    <Col sm={4}>
                        <Button
                            primary={true}
                            onClick={gotoLogin}
                            style={{width: "100%", height: 40}}
                        >
                            <GiReturnArrow style={{paddingBottom: 2, marginRight: 5}}/>
                            Return to Login
                        </Button>
                    </Col>
                </FieldRow>
            ) : (
                <FieldRow style={{padding: 0}}>
                    <Col sm={6} style={{padding: 0}}>
                        <Button
                            primary={true}
                            style={{width: "100%", height: 40}}
                            onClick={handleSubmit(ResetPassword)}
                        >
                            <GiReturnArrow style={{paddingBottom: 2, marginRight: 5}}/>
                            Reset Password
                        </Button>
                    </Col>
                    <Col sm={6}>
                        <Button
                            primary={true}
                            label={"Cancel"}
                            icon={GiCancel}
                            onClick={gotoLogin}
                            style={{width: "100%", backgroundColor: Colors.RED, height: 40}}
                        />
                    </Col>
                </FieldRow>
            )}
            <Col className={"flex-grow-1"}/>
        </FieldSection>
    );
};

const PortalRegisterForm = (props) => {
    const dispatch = useDispatch();
    const {token, setWorkflow} = props;
    const {addToast} = useToast();

    const [
        {
            register,
            handleSubmit,
            setError,
            setValue,
            watch,
            formState: {errors},
        },
        PhixForm,
    ] = usePhixForm();
    const history = useHistory();
    const [message, setMessage] = React.useState({error: false, message: ""});
    useEffect(() => {
        if (token) {
            try {
                let data = atob(token);
                let json = JSON.parse(data);
                setValue("token", json.token);
                setValue("firstName", json.firstName);
                setValue("lastName", json.lastName);
                setValue("dateOfBirth", moment(json.dob, "YYYY-MM-DD").toDate());
                setValue("email", json.email);
            } catch (error) {
                console.error(error);
            }
        }
    }, [token]);

    function gotoLogin() {
        history.push("/login");
        setWorkflow(WORKFLOWS.LOGIN_FORM);
    }

    async function RegisterUser(form) {
        if (form.password === form.password2) {
            let dob = moment(form.dateOfBirth).format("yyyy-MM-DD");
            form.dateOfBirth = dob;
            form.tenantId = TENANT_ID;
            let response = await UserActions.registerPortalUser(form)(dispatch);
            switch (response?.code) {
                case "E-106":
                    addToast({
                        severity: "error",
                        summary: "Registation Error",
                        detail:
                            "This email is already registered. Please either check to see if you already have an account or use another email.",
                    });
                    break;
                case "E-200":
                    addToast({
                        severity: "error",
                        summary: "Registation Error",
                        detail: response.message,
                    });

                    break;
                case "E-199":
                    addToast({
                        severity: "error",
                        summary: "Registation Error",
                        detail: response.message,
                    });
                    break;
                case undefined:
                    addToast({
                        severity: "success",
                        summary: "Registation Succeeded",
                        detail: "Registration successful. Please login.",
                    });
                    gotoLogin();
                    break;
            }
        } else {
            setError("password", {message: "Passwords do not match."});
            setError("password2", {message: "Passwords do not match."});
        }
    }

    return (
        <PhixForm
            style={{
                width: "100%",
                paddingRight: 40,
                paddingLeft: 40,
                paddingTop: 50,
                paddingBottom: 50,
            }}
        >
            <FormRow>
                <FormColumn col={12}>
                    <FormTextInput
                        label={"Registration token"}
                        field={"token"}
                        rules={{required: "This field is required"}}
                    />
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn col={12}>
                    <FormTextInput
                        label={"First Name"}
                        field={"firstName"}
                        rules={{required: "This field is required"}}
                    />
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn col={12}>
                    <FormTextInput
                        label={"Last Name"}
                        field={"lastName"}
                        rules={{required: "This field is required"}}
                    />
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn col={12}>
                    <FormDateSelector
                        label={"Date of Birth"}
                        field={"dateOfBirth"}
                        rules={{required: "This field is required"}}
                    />
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn col={12}>
                    <FormTextInput
                        label={"Email"}
                        field={"email"}
                        rules={{required: "This field is required"}}
                    />
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn col={12}>
                    <FormPasswordInput
                        label={"Password"}
                        field={"password"}
                        rules={{required: "This field is required"}}
                    />
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn col={12}>
                    <FormPasswordInput
                        label={"Confirm Password"}
                        field={"password2"}
                        rules={{required: "This field is required"}}
                    />
                </FormColumn>
            </FormRow>
            <FormRow>
                <Col>
                    {message.error ? (
                        <b style={{color: "red"}}>{message.message}</b>
                    ) : (
                        <b style={{color: "green"}}>{message.message}</b>
                    )}
                </Col>
            </FormRow>
            <Row gutter>
                <Col col={4}>
                    <Button
                        primary={true}
                        severity="success"
                        icon="fa fa-sign-in"
                        onClick={handleSubmit(RegisterUser)}
                        style={{
                            width: "100%",
                            padding: 15,
                            color: "white",
                            height: "45px",
                            fontWeight: "bolder",
                            marginTop: "1rem",
                            fontSize: "1.5rem",
                        }}
                        label="Register"
                    />
                </Col>
                <Col col={4}>
                    <Button
                        icon={GiCancel}
                        label="Cancel"
                        onClick={() => gotoLogin()}
                        style={{
                            width: "100%",
                            color: "black",
                            height: "45px",
                            fontWeight: "bolder",
                            marginTop: "1rem",
                            fontSize: "1.5rem",
                            backgroundColor: Colors.RED,
                        }}
                    ></Button>
                </Col>
            </Row>
        </PhixForm>
    );
};

const ResetRequestSuccessForm = (props) => {
    return (
        <>
            <Form
                render={(formRenderProps) => (
                    <FormElement>
                        <h3 style={{marginLeft: "1rem"}}>
                            An email has been sent with instructions on resetting your
                            password. If you do not receive an email, please contact your
                            system administrator.
                        </h3>

                        <br/>

                        <Button
                            type={"submit"}
                            primary={true}
                            onClick={props.onReturn}
                            style={styles.returnBtn}
                        >
                            <GiReturnArrow/>
                            Return to Login screen
                        </Button>
                    </FormElement>
                )}
            />
        </>
    );
};

const LoginForm = (props) => {
    const dispatch = useDispatch();

    const authError = useSelector((state) => state.auth.authError);
    const {setWorkflow, username, setUsername} = props;
    const [mfaEnabled, setMfaEnabled] = React.useState(false);
    const tenantName = useSelector(
        (state) => state.Configuration?.tenant?.tenant?.tenantName
    );

    useEffect(() => {
        document.title = tenantName || "Phix Health";
    }, [tenantName]);

    //We should never let the UI handle anything security wise. I have changed it so the backend locks the user.
    useEffect(() => {
        if (authError?.code === "E-224") {
            // AuthAction.lockUser(username)(dispatch).then(() =>
            //     setTimeout(() => {
            //         AuthAction.clearError()(dispatch);
            //     }, 2500));
            setTimeout(() => {
                AuthAction.clearError()(dispatch);
            }, 2500);
        }
    }, [authError]);
    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                loginBtnRef.current.click();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);
    const loginBtnRef = useRef();

    const [
        {
            register,
            handleSubmit,
            getValues,
            watch,
            formState: {errors},
        },
        PhixForm,
    ] = usePhixForm();

    const Login = async (form) => {
        setUsername(form?.username);
        let res = await AuthAction.getMfaSession(form.username)(dispatch);
        if (res?.payload === true && mfaEnabled === false) {
            setMfaEnabled(true);
            return;
        }
        if (form?.mfa)
            await AuthAction.login(form.username, form.password, form.mfa)(dispatch);
        else
            await AuthAction.login(form.username, form.password, form.mfa)(dispatch);
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        let form = getValues();
        let res = await AuthAction.getMfaSession(form.username)(dispatch);
        console.log(res);
        if (res?.payload === true && mfaEnabled === false) {
            setMfaEnabled(true);
            return;
        } else await handleSubmit(Login)();
    };

    let message = "";
    if (props.onLoad === "loginAfterReset") {
        message = <h3>Thank You. Your password has been reset. </h3>;
    }
    return (
        <form>
            <PhixForm
                style={{
                    width: "100%",
                    height: "100%",
                    paddingLeft: 20,
                    paddingTop: 20,
                }}
                className={"flex align-items-center justify-content-center"}
            >
                <Col col={12}>
                    <h3 style={styles.legend}>Login to {tenantName || "Phix Health"}</h3>
                    {message}
                </Col>
                <FormRow>
                    <FormColumn col={12}>
                        <FormTextInput
                            label={"Username"}
                            field={"username"}
                            rules={{required: "Username is required."}}
                        />
                    </FormColumn>
                </FormRow>
                <FormRow>
                    <FormColumn col={12}>
                        <FormPasswordInput
                            label={"Password"}
                            field={"password"}
                            style={{width: "100%"}}
                            rules={{required: "Password is required."}}
                        />
                    </FormColumn>
                </FormRow>
                {mfaEnabled && (
                    <FormRow>
                        <FormColumn>
                            <FormTextInput
                                mfa={mfaEnabled}
                                label={"MFA Token"}
                                field={"mfa"}
                                style={{width: "100%"}}
                                options={{required: "MFA is required."}}
                            />
                        </FormColumn>
                    </FormRow>
                )}
                <FormRow>
                    <Col col={12}>
                        <Button
                            ref={loginBtnRef}
                            icon="fa fa-sign-in"
                            label={"Login"}
                            onClick={handleLogin}
                            style={styles.loginBtn}
                        />
                    </Col>
                </FormRow>

                <Col>
                    <Button
                        primary={false}
                        text
                        label={"Forgot my password"}
                        tabIndex="100"
                        style={{
                            marginTop: "1rem",
                            marginLeft: "0rem",
                            marginBottom: "2rem",
                        }}
                        onClick={() => setWorkflow(WORKFLOWS.FORGOT_PASSWORD_FORM)}
                    ></Button>
                </Col>
            </PhixForm>
        </form>
    );
};

const ForgotPasswordForm = (props) => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState(null);
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();
    const {setWorkflow} = props;
    const SendPasswordReset = (form) => {
        AuthAction.forgotPassword(form.username)(dispatch).then((res) => {
            //"E-141"
            if (res?.code) {
                setErrorMessage(res?.message);
            } else {
                setErrorMessage(null);
                setWorkflow(WORKFLOWS.RESET_REQUEST_SENT);
            }
        });
    };
    return (
        <>
            <FieldSection
                style={{width: "100%", marginRight: 10, height: "100%"}}
                formStyle={{height: "100%"}}
                className={"flex align-items-center justify-content-center"}
            >
                <AHField
                    style={{height: 40}}
                    col={12}
                    label={"Please enter your username to reset your password."}
                    field={"username"}
                    register={register}
                    error={errors}
                    options={{required: "Username is required."}}
                >
                    <FieldTextInput style={{width: "100%"}}/>
                </AHField>

                <Col col={5}>
                    <Button
                        style={styles.loginBtn}
                        icon={BiReset}
                        label={"Reset"}
                        type={"submit"}
                        primary={true}
                        onClick={handleSubmit(SendPasswordReset)}
                    />
                </Col>
                <Col col={5}>
                    <Button
                        primary={true}
                        icon={GiCancel}
                        label={"Cancel"}
                        onClick={() => setWorkflow(WORKFLOWS.LOGIN_FORM)}
                        style={{...styles.loginBtn, backgroundColor: Colors.RED}}
                    />
                </Col>
                <br/>
                {errorMessage && (
                    <Col col={12}>
                        <b style={{color: "red"}}>{errorMessage}</b>
                    </Col>
                )}
            </FieldSection>
        </>
    );
};

const NewLoginPage = (props) => {
    const session = useSelector((state) => state.auth.session);
    const tenantConfig = useSelector((state) => state.Configuration.tenant);
    const passwordExpiresIn = useSelector(
        (state) => state.auth?.session?.passwordExpiresIn
    );
    const authError = useSelector((state) => state.auth.authError);
    const {resetNewPassword} = props;
    const authenticated = useSelector((state) => state.auth.isAuthenticated);
    const {search, pathname} = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const {token} = useParams();
    const [workflow, setWorkflow] = React.useState(WORKFLOWS.LOGIN_FORM);
    const [isLocked, setIsLocked] = React.useState(false);
    const [messageToDisplay, setMessageToDisplay] = React.useState("");
    const [isExpireNotify, setIsExpireNotify] = React.useState(false);
    const [isAlreadyExpired, setIsAlreadyExpired] = React.useState(false);
    const [layout, setLayout] = React.useState("stretched");
    const [isPopupCanceled, setIsPopupCanceled] = React.useState(false);
    const [username, setUsername] = React.useState(null);
    const {addToast} = useToast();

    const numbers = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
    ];

    useEffect(() => {
        if (
            localStorage.hasOwnProperty("token") &&
            localStorage.getItem("token") != null &&
            !authenticated
        )
            AuthAction.getExistingSession()(dispatch);
    }, []);

    useEffect(() => {
        if (pathname && pathname.includes("/passwordreset")) {
            setWorkflow(WORKFLOWS.RESET);
        }
        if (pathname && pathname.includes("/register")) {
            setWorkflow(WORKFLOWS.PORTAL_REGISTER);
        }
    }, [pathname]);
    useEffect(() => {
        if (authError?.code === "E-224") {
            addToast({
                summary: "Login Error",
                detail:
                    "You account is locked due to too many failed login attempts. Please contact the " +
                    tenantConfig?.tenant?.tenantName +
                    " administrator to unlock your account.",
            });
            setIsLocked(true);
        } else {
            authError?.message &&
            addToast({
                summary: "Login Error",
                detail: authError.message,
            });
            setIsLocked(false);
        }
        if (authError?.code === "E-228") {
            addToast({
                summary: "Login Error",
                detail:
                    "You may no longer log in to your accounts using the current password. A new password must be set.",
            });
            setIsAlreadyExpired(true);
        } else {
            authError?.message &&
            addToast({
                summary: "Login Error",
                detail: authError.message,
            });
            setIsAlreadyExpired(false);
        }
    }, [authError]);
    useEffect(() => {
        if (isPopupCanceled && authenticated === true) {
            AuthAction.setAuthSuccess()(dispatch);
            RedirectToPage();
        }
    }, [isPopupCanceled]);
    useEffect(() => {
        const SyncConfiguration = async () => {
            if (authenticated === true) {
                if (passwordExpiresIn && passwordExpiresIn > 7) {
                    await LoadingActions.setComponentLoading("MainApp")(dispatch);
                    await ConfigurationActions.getPermissionGroupConfiguration()(
                        dispatch
                    );
                    await AuthAction.setAuthSuccess()(dispatch);
                    RedirectToPage(session?.permissionGroup?.code === "AH_PORTAL_USER");
                } else {
                    setIsExpireNotify(true);
                }
            }
        };
        SyncConfiguration().then((r) => {
            LoadingActions.setComponentDoneLoading("MainApp")(dispatch);
        });
    }, [session]);

    const handleForgotPassword = () => {
        setWorkflow(WORKFLOWS.FORGOT_PASSWORD_FORM);
    };
    const handleReturn = () => {
        setWorkflow(WORKFLOWS.LOGIN_FORM);
    };

    function RedirectToPage(portalUser) {
        let searchParams = queryString.parse(search);
        let redirect = searchParams?.redirect;
        // console.log(`Seach Params ${JSON.stringify(searchParams)}`)
        delete searchParams.redirect;
        let passParams = queryString.stringify(searchParams);
        if (portalUser) {
            let params = {portalUser: true};
            PatientAction.getAssignedPortalPatient()(dispatch).then(() =>
                history.push(`/patientPanel?${queryString.stringify(params)}`)
            );
        } else {
            switch (redirect) {
                case "/patientPanel":

                    if (searchParams?.ah_pid || searchParams?.patient_id) {
                        history.push(`/patientPanel?${passParams}`);
                    } else {
                        history.push(`/?${passParams}`);
                    }
                    break;
                case "/patientList":
                    history.push(`/patientList?${passParams}`);
                    break;
                case "/admin":
                    history.push(`/admin?${passParams}`);
                    break;
                case "/":
                    history.push(`/?${passParams}`);
                    break;
                default:
                    history.push(`/?${passParams}`);
                    break;
            }
        }
    }

    function RenderSection(p) {
        switch (p.workflow) {
            case WORKFLOWS.LOGIN_FORM:
                return (
                    <LoginForm
                        onLoad={WORKFLOWS.LOGIN_FORM}
                        handleForgotPassword={handleForgotPassword}
                        username={username}
                        setUsername={setUsername}
                        setWorkflow={setWorkflow}
                        workflow={workflow}
                    />
                );
            case WORKFLOWS.FORGOT_PASSWORD_FORM:
                return <ForgotPasswordForm setWorkflow={setWorkflow}/>;
            case WORKFLOWS.RESET_REQUEST_SENT:
                return <ResetRequestSuccessForm onReturn={handleReturn}/>;
            case WORKFLOWS.RESET:
                return (
                    <PasswordResetForm
                        resetNewPassword={resetNewPassword}
                        token={token}
                        isChangePassword={false}
                        setWorkflow={setWorkflow}
                    />
                );
            case WORKFLOWS.CHANGE_PASSWORD:
                return (
                    <PasswordResetForm
                        resetNewPassword={resetNewPassword}
                        isChangePassword={true}
                        username={username}
                        setWorkflow={setWorkflow}
                    />
                );
            case WORKFLOWS.LOGIN_AFTER_RESET:
                return (
                    <LoginForm
                        onLoad={WORKFLOWS.LOGIN_AFTER_RESET}
                        handleForgotPassword={handleForgotPassword}
                        authError={authError}
                        username={username}
                        setUsername={setUsername}
                        setWorkflow={setWorkflow}
                        workflow={workflow}
                    />
                );
            case WORKFLOWS.PORTAL_REGISTER:
                return (
                    <PortalRegisterForm
                        resetNewPassword={resetNewPassword}
                        token={token}
                        setWorkflow={setWorkflow}
                    />
                );

            default:
                return (
                    <LoginForm
                        onLoad={WORKFLOWS.LOGIN_FORM}
                        handleForgotPassword={handleForgotPassword}
                        authError={authError}
                        username={username}
                        setUsername={setUsername}
                        setWorkflow={setWorkflow}
                        workflow={workflow}
                    />
                );
        }
    }

    const AppLogo = (props) => {
        if (tenantConfig?.LARGE_LOGO?.base64) {
            return (
                <img
                    style={styles.logo}
                    src={`data:image/png;base64,${tenantConfig?.LARGE_LOGO?.base64}`}
                />
            );
        } else {
            return <img style={styles.logo} src={phixLogo}/>;
        }
    };
    const cancel = () => {
        setIsExpireNotify(false);
        setIsPopupCanceled(true);
    };

    const changeItNow = () => {
        setIsExpireNotify(false);
        setWorkflow(WORKFLOWS.CHANGE_PASSWORD);
    };

    return (
        <>
            <div style={styles.main}>
                <Container
                    style={{width: "70%", border: "1px solid gray", borderRadius: 5}}
                >
                    <Col className={"sm:col-12 lg:col-6"} style={styles.colLogo}>
                        <Avatar
                            type="image"
                            rounded={"medium"}
                            size="larger"
                            style={styles.avatar}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                <AppLogo/>
                            </div>
                        </Avatar>
                    </Col>
                    <Col
                        className={"sm:col-12 lg:col-6 justify-content-center"}
                        style={{padding: 10}}
                    >
                        <RenderSection workflow={workflow}/>
                    </Col>
                </Container>
                {isLocked && (
                    <Dialog>
                        <p
                            style={{
                                margin: "25px",
                                textAlign: "center",
                            }}
                        >
                            {messageToDisplay}
                        </p>
                    </Dialog>
                )}
                {isAlreadyExpired && (
                    <Dialog>
                        <p
                            style={{
                                margin: "25px",
                                textAlign: "center",
                            }}
                        >
                            {messageToDisplay}
                        </p>
                        <DialogActionsBar>
                            <Button
                                label={"Ok"}
                                onClick={(e) => {
                                    AuthAction.clearError()(dispatch);
                                    setWorkflow(WORKFLOWS.CHANGE_PASSWORD);
                                }}
                            />
                        </DialogActionsBar>
                    </Dialog>
                )}
                {isExpireNotify && (
                    <Dialog>
                        <p
                            style={{
                                margin: "25px",
                                textAlign: "center",
                            }}
                        >
                            Your password will expire in {numbers[passwordExpiresIn]}(
                            {passwordExpiresIn}) days. Please update your password.
                        </p>
                        <DialogActionsBar layout={layout}>
                            <Button onClick={cancel}>Cancel</Button>
                            <Button onClick={changeItNow}>Change it Now</Button>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </div>
        </>
    );
};

export default NewLoginPage;
