import * as React from 'react';
import {useEffect, useState} from 'react';
import moment from "moment";
import {useDispatch, useSelector} from 'react-redux';
import 'hammerjs';
import {ObservationCodes, PhixPermissions} from "../../config/GlobalConfig"
import TileHeader from "../../layout/TileHeader";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {TileRow} from "../../layout/TileRow";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {AeriosNoDataLoadingIndicator} from "../AeriosLoadingIndicator";
import {PatientAction} from "../../action/PatientAction";
import {
    AverageObservationsByDay,
    CalculateCohortObservationThresholds,
    ThresholdChart,
    ThresholdList
} from "../../util/ObservationThresholds";


const ActivityLevelComponent = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([]);
    const [thresholds, setThresholds] = useState([])
    const latestCohortObservation = useSelector(state => state.patient.latestCohortObservation)


    useEffect(() => {
        let endDate = moment().subtract(1, "days").format("YYYY-MM-DD")
        let startDate = moment().subtract(ObservationCodes.DAY_PREVIOUS.ACTIVITY_LEVEL, "days").format("YYYY-MM-DD")
        const fetchData = async () => {
            await PatientAction.getPatientCohortObservationSumWithThreshold([ObservationCodes.STEP.STEP_COUNT], startDate, endDate, "ActivityLevelCareTeamComponent")(dispatch)
        }
        fetchData().then(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (latestCohortObservation?.ActivityLevelCareTeamComponent?.observations) {
            let data = AverageObservationsByDay(latestCohortObservation.ActivityLevelCareTeamComponent.observations)
            console.log(data)
            setData(data)
        }
    }, [latestCohortObservation?.ActivityLevelCareTeamComponent]);

    useEffect(() => {
        if (data?.length && latestCohortObservation?.ActivityLevelCareTeamComponent?.thresholds) {
            let c = CalculateCohortObservationThresholds(data, latestCohortObservation.ActivityLevelCareTeamComponent.thresholds)
            setThresholds(c)
        }
    }, [data])


    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                <TileContainer>
                    <TileHeader className="boxHeader ah-draggable-header"
                                title={"Activity Level"} image={"../images/activity.png"}>
                    </TileHeader>
                    <TileBody fluid style={{backgroundColor: '#FFF'}}
                              differentStyleTitle={"updatedTitleWrapperGrayBg"}>
                        <AeriosNoDataLoadingIndicator loading={loading} data={thresholds}>
                            <TileRow>
                                <ThresholdList thresholds={thresholds}/>
                                <ThresholdChart thresholds={thresholds}/>
                            </TileRow>
                        </AeriosNoDataLoadingIndicator>
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}

export default ActivityLevelComponent;
