import React, {useEffect} from 'react';
import {BaseRequestAction} from "../action/BaseRequestAction";
import {bindActionCreators} from "redux";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {connect} from "react-redux";
import {PopupWindowActions, PopupWindows} from "../action/PopupWindowAction";
import {Checkbox} from "@progress/kendo-react-inputs";
import moment from "moment";
import {adjustForTimezone} from "../util/DateTools";
import {PermissionsTool} from "../util/PermissionsTool";
import ErrorMessage from './shared/ErrorMessage';
import {Container} from "react-grid-system";
import ShowForPermissionComponent from './shared/ShowForPermissionComponent';
import {PhixPermissions} from "../config/GlobalConfig";

const RequestItem = (props) => {

    const handleChange = (e) => {
        props.completeRequest(props.dataItem.id);
    };

    function convertUTCDateToLocalDate(date) {
        let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        return newDate;
    }

    let RequestTypeIcon = "";
    if (props.dataItem.dictRequestTypeCode === 'AH_GENERAL_TASK') {
        RequestTypeIcon = "k-i-checkbox-checked"
    } else if (props.dataItem.dictRequestTypeCode === 'AH_ORDER') {
        RequestTypeIcon = "k-i-paste-plain-text"
    } else if (props.dataItem.dictRequestTypeCode === 'AH_REFERRAL') {
        RequestTypeIcon = "k-i-redo"
    }
    //const createdDate = moment.utc(props.dataItem.dueDate).local().startOf('day')
    const createdDate = moment(new Date(adjustForTimezone(new Date(props.dataItem.dueDate)))).local().startOf('day')
    const current = moment().startOf('day');
    const diffInDays = moment.duration(createdDate.diff(current)).asDays();
    const localtime = convertUTCDateToLocalDate(new Date(props.dataItem.createDate))

    return (
        <>
            <td onDoubleClick={() => props.openEditRequestWindow(props.dataItem)}>
                <div className="row">
                    <div className="d-flex col-1" style={{marginTop: "18px", width: "18px", marginLeft: 10}}>
                        <span role="presentation" class={RequestTypeIcon + " k-icon"}></span>
                    </div>
                    <div className="col-7" style={{wordWrap: "break-word"}}>
                        {/* <strong style={{fontSize: "12px"}}>{props.dataItem[props.field]}</strong> */}
                        <strong style={{fontSize: "16px", wordWrap: "break-word"}}>{props.dataItem.title}</strong>
                        <div className=" " style={{fontSize: "12px", wordWrap: "break-word"}}>
                            Sent
                            on {moment(new Date(localtime)).format("dddd, MMMM D, YYYY")} at {moment(new Date(localtime), "hmm").format("hh:mm A")} by {props.dataItem.firstName} {props.dataItem.lastName}
                        </div>
                    </div>

                    <div className="col-3 ">
                        <div style={{fontSize: "12px"}}>{props.dataItem.dictStatusDisplayName}</div>
                        {props.dataItem.dueDate && (<div
                            style={{fontSize: "12px"}}> Due: {moment(new Date(adjustForTimezone(new Date(props.dataItem.dueDate)))).local().format("MM/DD/YY")}

                            {diffInDays < 0 &&
                                <span role="presentation" style={{"position": "absolute", 'color': 'red','marginLeft':'5px',"marginTop":'2px'}}
                                      className={"k-i-warning k-icon"}></span>}
                        </div>)}
                        {props.dataItem.urgent && (<div
                            style={{fontSize: "12px"}}> <span role="presentation" style={{"position": "absolute", 'color': 'red',"marginTop":'2px'}}>Urgent</span>
                        </div>)}
                    </div>
                    <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_REQUEST, PhixPermissions.AH_EDIT_REFERRALS]}>
                        <div className="col-1 ">
                            {<Checkbox
                                title={props.dataItem.dictStatusCode !== "AH_COMPLETED" && props.dataItem.dictStatusCode !== "AH_CLOSED_NOT_COMPLETED" ? "Mark 'Complete'" : "Mark 'Completed'"}
                                checked={props.dataItem.dictStatusCode === "AH_COMPLETED" || props.dataItem.dictStatusCode === "AH_CLOSED_NOT_COMPLETED"}
                                name="active" type="checkbox" onChange={handleChange}/>}

                        </div>
                    </ShowForPermissionComponent>
                </div>

            </td>
        </>
    )

};


const RequestListComponent = (props) => {

    const openEditRequestWindow = (data) => {
        props.BaseRequestActions.setContext(data).then(() =>
            props.PopupActions.showWindow(PopupWindows.CREATE_REQUEST_WINDOW))
    }


    const [generalTaskPermissions, setGeneralTaskPermissions] = React.useState({isEdit:false, isView: false});
    const [referralPermissions, setReferralPermissions] = React.useState({isEdit:false, isView: false});
    const [RequestList, setRequestList] = React.useState([]);

    React.useEffect(() => {
        setGeneralTaskPermissions(PermissionsTool({permissionInfo: props.permissionInfo, viewPermissions: ["AH_VIEW_REQUEST"], editPermissions:["AH_EDIT_REQUEST"]}));
        setReferralPermissions(PermissionsTool({permissionInfo: props.permissionInfo, viewPermissions: ["AH_VIEW_REFERRALS"], editPermissions:["AH_EDIT_REFERRALS"]}));
    },[props?.permissionInfo]);

    React.useEffect(() => {
        var list = []
        if (referralPermissions.isView || referralPermissions.isEdit) {
            list = props.RequestList;
        } else if (generalTaskPermissions.isView || generalTaskPermissions.isEdit) {
            list = props.RequestList.filter(v=> v.dictRequestTypeCode === "AH_GENERAL_TASK");
        }
        setRequestList(list);
    },[props?.RequestList]);

    const callGetAllRequests = () => {
        let obj = {};
        obj["statusCodes"] = props.BaseRequest?.requestListContext?.requestListCategory?.codes;
        obj["assignedUserRoleId"] = props.BaseRequest?.requestListContext?.assignedUser;
        obj["requestedUserRoleId"] = props.BaseRequest?.requestListContext?.createdUser;
        let payload = {
            "payload": obj,
            "limit": 100,
            "offset": 0,
            "sort": {
                "sorts": [
                    {
                        "field": "string",
                        "dir": "string"
                    }
                ]
            }
        }
        props.BaseRequestActions.getAllRequests(payload);
    }

    const completeRequest = (id) => {
        props.BaseRequestActions.completeRequest(id).then(() => {
            callGetAllRequests();
        })
    };
    useEffect(() => {
        callGetAllRequests();
    }, [props.BaseRequest.requestListContext]);

    useEffect(() => {
        callGetAllRequests();
    }, [props.currentUserRoleId]);

    let parentProps = props;
    let CustomCell = (props) =>
        <RequestItem openEditRequestWindow={openEditRequestWindow}
                     completeRequest={completeRequest} {...props} />;
    return (
        <Container style={{margin: 0, padding: 0, width: "100%", height: "100%"}}>
            <ErrorMessage errors={[props.BaseRequest?.RequestListError?.message]}>
                <Grid style={{height: "100%"}} data={RequestList} headerCellRender={(props) =>  null}>
                    <GridColumn cell={CustomCell}/>
                </Grid>
            </ErrorMessage>
        </Container>
    );
};

RequestListComponent.propTypes = {};

function mapStateToProps(state) {
    return {
        RequestList: state.BaseRequest.RequestList,
        BaseRequest: state.BaseRequest,
        currentUserRoleId: state.user.loggedinUser.userRoleId,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        BaseRequestActions: bindActionCreators(BaseRequestAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestListComponent)
