import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {connect} from "react-redux";
import {Button} from "primereact/button";
import {Toolbar,ToolbarItem} from "@progress/kendo-react-buttons";
import {ToolbarSpacer} from "@progress/kendo-react-buttons";
import {Input} from "@progress/kendo-react-inputs";
import {useForm} from "react-hook-form";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../../../action/DictionaryDispatchAction";
import {DictionaryTypeAction} from "../../../../action/DictionaryTypeAction";
import {PatientAction} from "../../../../action/PatientAction";
import {PersonActions} from "../../../../action/PersonAction";
import {PopupWindowActions} from "../../../../action/PopupWindowAction";

const LongitudinalResultsComponent = (props) => {
    const styles = {
        row: {
            width: "100%",
        },
        col6: {
            width: "50%",
            float: 'left',
            margin: '0px',
            padding: '0px',
        },
        colP: {
            margin: '0px',
            padding: '2px 5px',
            fontSize: '12px',
        },
    }

    const locales = [
        {
            language: "en-US",
            locale: "en",
        },
        {
            language: "es-ES",
            locale: "es",
        },
    ];
    const [locale, setLocale] = React.useState(locales[0]);
    const value = new Date();

    const [dataState, setDataState] = React.useState({
        take: 5,
        skip: 0,
    });

    const [searchState, setSearchState] = React.useState("")
    const [filterState, setFilterState] = React.useState(false)
    const [visible, setVisible] = React.useState(false);
    const [visiblesearch, setVisiblesearch] = React.useState(false);


    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const AddMedicalProblem = () => {
        alert("Edit Not implemented yet");
       // props.PopupActions.showWindow(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW);
    };

    const SearchIcdCode = () => {
        setVisiblesearch(!visiblesearch);
    };

    React.useEffect(() => {
        setData(props.users)
    }, [props.users])


    const [data, setData] = React.useState(props.users);
    const [filter, setFilter] = React.useState();

    const disabled = !!props.createPatientContext
    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        formState: {errors, isDirty, isSubmitting, touchedFields, submitCount},
    } = useForm();
    return (

        <div style={{margin: 0, width: "100%", height: "100%"}}>
            <Toolbar>
                <Input
                    style={{width: "50%"}}
                    placeholder={"Search"}
                    id={"input-id"}
                    value={searchState}
                    onChange={(e) => setSearchState(e.value)}
                />

                <ToolbarItem>
                    <Button togglable={true} icon="filter" text="Filter Patients"
                            onClick={() => setFilterState(!filterState)}>Filter  </Button>
                </ToolbarItem>
                <ToolbarSpacer/>

                <ToolbarItem>
                    <Button icon="add" text="Image" onClick={AddMedicalProblem}> New Lab Order </Button>
                </ToolbarItem>


            </Toolbar>
            <Grid
                {...dataState}
                style={{height: "calc(100% - 50px)"}}
                data={props.patients}
                pageSize={dataState.limit}
                // style={{height: "calc(100vh - 100px)"}}
                total={props.totalCount}
                filterable={filterState}
                sortable={true}
                skip={dataState.skip}
                pageable={{
                    buttonCount: 20,
                    info: true,
                    type: "numeric",
                    pageSizes: true,
                    previousNext: true,
                }}

                onDataStateChange={dataStateChange}
            >
                <GridColumn field="OrderID" title="Order ID"/>
                <GridColumn field="OrderDate" title="Order Date"/>
                <GridColumn field="OrderingProvider" title="Ordering Provider"/>
                <GridColumn field="STAT" title="STAT"/>
                <GridColumn field="Observation" title="Observation"/> 
                <GridColumn field="Status" title="Status"/>
            </Grid>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        createPatientContext: state.patient.createPatientContext,
        contactAddress: state.person.contactAddress,
        patient: state.patient,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LongitudinalResultsComponent);
