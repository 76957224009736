import {url} from "../config/Url";
import {ParseResponse} from "../util/ParseResponse";
import {TENANT_ID} from "../config/GlobalConfig";
import {PostApi, WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    MFA_SUCCESS: "MFA_SUCCESS",
    SET_MFA: "SET_MFA",
    LOGIN_ERROR: "LOGIN_ERROR",
    CHECK_PASSWORD: "CHECK_PASSWORD",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
    RESET_NEW_PASSWORD_SUCCESS: "RESET_NEW_PASSWORD_SUCCESS",
    RESET_NEW_PASSWORD_ERROR: "RESET_NEW_PASSWORD_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",
    LOGOUT: "USER_LOGOUT",
    VERIFY_PASSWORD: "VERIFY_PASSWORD",
    SET_LOGIN_SUCCESS: "SET_LOGIN_SUCCESS",
}


function login(username, password, otpcode) {

    return async dispatch => {
        let response = await fetch(url('session/login'), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({username: username, password: password, otp: otpcode, tenantId: TENANT_ID})
        }).catch(error => {
            //TODO: Add error handling
            dispatch({type: "AH_ERROR", payload: error})
            // console.log.error(error)
        });

        if (response?.ok) {
            let json = await ParseResponse(response);
            localStorage.setItem('token', json.sessionToken ? json.sessionToken.token : null)
            localStorage.setItem('refreshToken', json.refreshToken ? json.refreshToken.token : null)
            localStorage.setItem('userRoleId', json.userRoleId ? json.userRoleId : null)

            // let emrResponse = await fetch(url('openemr/login/getToken'), {
            //     method: 'POST',
            //     headers: {
            //         'Accept': 'application/json',
            //         'X-AUTH-TOKEN': json.sessionToken.token,
            //         'TENANT-ID': TENANT_ID,
            //     },
            //     body: JSON.stringify({payload: {username: username, password: password}})
            // });
            // if (emrResponse.ok) {
            //     let json = await ParseResponse(emrResponse);
            //     localStorage.setItem('emrToken', json.payload.emrToken ? json.payload.emrToken : null)
            //     localStorage.setItem('emrRefreshToken', json.payload.refreshToken ? json.payload.refreshToken : null)
            // }


            dispatch({type: ActionTypes.LOGIN_SUCCESS, payload: json});
        } else {
            try {
                let json = await ParseResponse(response);
                dispatch({type: ActionTypes.LOGIN_ERROR, payload: json});
            } catch (error) {
                // console.log.error(response)
                dispatch({type: ActionTypes.LOGIN_ERROR, payload: response});
            } finally {
                localStorage.setItem('token', null)
            }
        }
    }

}

function verifyPassword(username, password) {
    return async dispatch => {
        return await fetch(url('session/validatePassword'), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({username: username, password: password, tenantId: TENANT_ID})
        })
            .then((response) => response.json())
            .catch(err => {
                return false;
            });
    }
}

async function clearSession() {
    await localStorage.removeItem('token')
    await localStorage.removeItem('refreshToken')
    await localStorage.removeItem('userRoleId')
}

function getExistingSession() {
    return async dispatch => {
        if (localStorage.hasOwnProperty("token") && localStorage.getItem("token") != null) {
            let response = await fetch(url('session/existing'), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    payload: {
                        token: localStorage.getItem("token"), tenantId: TENANT_ID
                    }
                })
            }).catch(error => {
                clearSession()
            });
            if (response?.ok) {
                let json = await ParseResponse(response);
                localStorage.setItem('token', json.sessionToken ? json.sessionToken.token : null)
                localStorage.setItem('refreshToken', json.refreshToken ? json.refreshToken.token : null)
                localStorage.setItem('userRoleId', json.userRoleId ? json.userRoleId : null)
                dispatch({type: ActionTypes.LOGIN_SUCCESS, payload: json});
            } else {
                try {
                    let json = await ParseResponse(response);
                    dispatch({type: ActionTypes.LOGIN_ERROR, payload: json});
                } catch (error) {
                    // console.log.error(response)
                    dispatch({type: ActionTypes.LOGIN_ERROR, payload: response});
                } finally {
                    dispatch({
                        type: ActionTypes.LOGIN_ERROR,
                        payload: {message: "Unknown Session Error. Please re-login."}
                    });
                    await clearSession()

                }
            }
        }
    }

}

function getMfaSession(username) {
    return async dispatch => {
        let response = await fetch(url('session/mfa'), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({payload: {username: username, tenantId: TENANT_ID}})
        }).catch(error => {
            //TODO: Add error handling
            dispatch({type: "AH_ERROR", payload: error})
            // console.log.error(error)
        })
        if (response?.ok) {
            let json = await ParseResponse(response);
            dispatch({type: ActionTypes.SET_MFA, payload: json.payload});
            return json;
        }
    }
}

function unlockUser(payload) {
    return WrapAuthentication("user/unlock", payload)
}

function forgotPassword(username) {
    return async dispatch => {
        let response = await PostApi('user/password/request',
            {payload: {username, tenantId: TENANT_ID}})
        if (response.payload) {
            dispatch({type: ActionTypes.RESET_PASSWORD_SUCCESS, payload: true});
        } else {
            dispatch({type: ActionTypes.RESET_PASSWORD_ERROR, payload: null});
        }

        return response
    }
}

function forgotPasswordReset(token, password) {
    return async dispatch => {
        let response = await fetch(url('user/password/reset'), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({payload: {token, password, tenantId: TENANT_ID}})
        });
        let json = await ParseResponse(response)
        if (json.payload) {
            dispatch({type: ActionTypes.RESET_NEW_PASSWORD_SUCCESS, payload: true});
        } else {
            dispatch({type: ActionTypes.RESET_NEW_PASSWORD_ERROR, payload: false});
        }

        return json
    }
}

function checkPasswordExpression(password) {
    return async dispatch => {
        let response = await fetch(url("user/check/password/expression"), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'X-AUTH-TOKEN': localStorage.getItem('token')
            },
            body: JSON.stringify({payload: {password}})
        });
        let json = await ParseResponse(response);
        if (json.payload) {
            dispatch({type: ActionTypes.CHECK_PASSWORD, payload: true});
        } else {
            dispatch({type: ActionTypes.CHECK_PASSWORD, payload: false});
        }

        return json
    }
}

function changePassword(password) {
    return WrapAuthentication('user/password/change', {payload: {password}}, ActionTypes.RESET_NEW_PASSWORD_SUCCESS, ActionTypes.RESET_NEW_PASSWORD_ERROR)
}

function changePasswordByUsername(password, username) {
    return WrapAuthentication('user/password/change/by/username', {
        payload: {
            password: password,
            username: username,
            tenantId: TENANT_ID
        }
    }, ActionTypes.RESET_NEW_PASSWORD_SUCCESS, ActionTypes.RESET_NEW_PASSWORD_ERROR)
}

function logout() {
    return async dispatch => {
        localStorage.clear()
        dispatch({type: ActionTypes.LOGOUT})

    }
}

function clearError() {
    return dispatch => {
        dispatch({type: ActionTypes.CLEAR_ERROR})
    }
}

function setAuthSuccess() {
    return dispatch => {
        dispatch({type: ActionTypes.SET_LOGIN_SUCCESS})
    }
}

function setMfa() {
    return async dispatch =>
        dispatch({type: ActionTypes.MFA_SUCCESS})
}


export const AuthAction =
    {
        login: login,
        setMfa: setMfa,
        forgotPassword: forgotPassword,
        logout: logout,
        clearError: clearError,
        forgotPasswordReset: forgotPasswordReset,
        changePassword: changePassword,
        changePasswordByUsername: changePasswordByUsername,
        getExistingSession: getExistingSession,
        getMfaSession: getMfaSession,
        checkPasswordExpression: checkPasswordExpression,
        verifyPassword: verifyPassword,
        setAuthSuccess: setAuthSuccess,
        unlockUser: unlockUser

    }