import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {useForm} from "react-hook-form";
import {Dialog} from "primereact/dialog";
import {
    FormCard,
    FormColumn,
    FormContainer,
    FormDateSelector,
    FormDictionaryDropdown,
    FormExternalVendorDropdown,
    FormMultiOrderCheckboxSelector,
    FormRow,
    FormUserListDropdown
} from "../InputControls/NewFormFields";
import {Container} from "../../layout/GridLayout";
import {Button} from "primereact/button";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import DictionaryTabSelector from "../InputControls/DictionaryTabSelector";
import {LabOrderActions} from "../../action/LabOrderAction";
import {SIMPLE_DATE_FORMAT} from "../../util/DateTools";


const defaultValues = {
    id: null,
    patientRoleId: null,
    orderNumber: null,
    dictOrderTypeId: null,
    // externalVendorId: null,
    dictOrderStatusId: null,
    orderingUserRoleId: null,
    additionalFields: null,
    dueDate: null
}

const AddClinicalOrderWindow = (props) => {


    const patientContext = useSelector(state => state.patient.patientContext)
    const showWindow = useSelector(state => state.popupWindow.showWindow)
    const popupState = useSelector(state => state.popupWindow.state)
    const orderContext = useSelector(state => state.popupWindow.state?.[PopupWindows.CREATE_ORDER_WINDOW]?.orderContext)
    const dispatch = useDispatch()
    const form = useForm({defaultValues: orderContext || defaultValues})
    const {
        handleSubmit,
        reset
    } = form

    useEffect(() => {
        if (orderContext?.id) {
            reset(orderContext)
        }
    }, [orderContext])
    const hideDialog = () => {
        reset(defaultValues)
        PopupWindowActions.hideWindow(PopupWindows.CREATE_ORDER_WINDOW)(dispatch)
    }
    const handleUpload = () => {
        // // console.log('Upload')
    }

    const saveLabOrder = (form) => {
        let payload
        if (form?.labOrders) {
            let orders = form.labOrders.map(o => {
                return {
                    patientRoleId: patientContext?.patientRoleId,
                    orderableItemId: o.id,
                    externalVendorId: o.externalVendorId,
                    dictOrderStatusId: form.dictOrderStatusId,
                    dictOrderTypeId: o.dictOrderTypeId,
                    orderingUserRoleId: form.orderingUserRoleId,
                    additionalFields: null,
                    eOrder: false,
                    encounterId: null,
                    eAcknowledged: false,
                    dueDate: form.dueDate,
                    problems: o?.problems ? o.problems.map(p => p.id) : [],

                }

            })
            payload = orders
            console.log(orders)
        } else {
            //TODO: Add update logic here
        }
        LabOrderActions.createLabOrders(payload)(dispatch)
        hideDialog()
    }

    // id: Option[UUID],
    //     patientRoleId: UUID,
    //     orderableItemId: UUID,
    //     externalVendorId: UUID,
    //     dictOrderStatusId: UUID,
    //     dictOrderTypeId: UUID,
    //     orderingUserRoleId: UUID,
    //     additionalFields: Option[String],
    //     eOrder: Boolean,
    //     eAcknowledged: Boolean,
    //     dueDate: LocalDate,
    //     problems: Seq[UUID]


    return (
        <Dialog visible={showWindow[PopupWindows.CREATE_ORDER_WINDOW]}
                onHide={hideDialog}
                maximizable
                header={"Clinical Order"}
                style={{width: "90vw", height: "70vh"}}
                footer={<Container className={"justify-content-end"}>
                    <Button primary onClick={handleSubmit(saveLabOrder)}
                            label={orderContext?.id ? "Update" : "Save"} severity={"success"}/>
                    <Button onClick={hideDialog} severity={"danger"} label={"Cancel"}/>
                </Container>}>
            <FormContainer form={form}>

                <FormCard>
                    <DictionaryTabSelector code={Dictionary.AH_ORDER_TYPE}/>
                    <FormRow>

                        <FormColumn col={6}>

                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Order Status"}
                                defaultItemCode={"AH_ORDER_IN_OPEN"}
                                field={"dictOrderStatusId"}
                                rules={{required: "Order Status is required"}}
                                code={Dictionary.ORDER_STATUS}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow>

                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormUserListDropdown defaultCurrentUser
                                                  label={"Ordering Provider"}
                                                  field={"orderingUserRoleId"}/>
                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDateSelector
                                defaultNow
                                dateFormat={SIMPLE_DATE_FORMAT}
                                field={"dueDate"}
                                label={"Due Date"}/>
                        </FormColumn>
                    </FormRow>
                </FormCard>
                {orderContext?.id ?
                    <FormCard style={{marginTop: 5}}>
                        <FormRow>
                            <FormColumn>
                                <FormExternalVendorDropdown field={"externalVendorId"} label={"External Vendor"}/>
                            </FormColumn>
                            <FormColumn>

                            </FormColumn>
                        </FormRow>
                    </FormCard>
                    :
                    <FormCard style={{marginTop: 5}}>
                        <FormMultiOrderCheckboxSelector
                            rules={{required: "At Least one order is required."}}
                            field={"labOrders"} label={"Orders"}/>
                    </FormCard>}
            </FormContainer>
        </Dialog>)


}
AddClinicalOrderWindow.propTypes = {
    showWindow: PropTypes.object,
    DictionaryActions: PropTypes.object,
    DictionaryTypeAction: PropTypes.object,
    PopupActions: PropTypes.object,
    PatientActions: PropTypes.object,
    createPatientContext: PropTypes.object,
    patient: PropTypes.object,
};


export default AddClinicalOrderWindow
