import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {Col, Container, Row} from "react-grid-system";
import PropTypes from "prop-types";


export function usePermission(p) {
    const permissions = useSelector(state => state.auth.session.permissions)
    return useMemo(() => {
        return (p.some((p1) => permissions.includes(p1)))
    }, [permissions])
}

const ShowForPermissionComponent = (props) => {
    const {
        permissions,
        component = false,
        isNonEditable = false
    } = props;
    const show = usePermission(permissions)

    if (show)
        return props.children ? props.children : null
    else if (component)
        return <Container>
            <Row style={{paddingTop: 30}}>
                <Col style={{alignContent: "center", alignItems: "center", width: "100%"}} sm={2}>
                    <span className={"k-icon k-i-lock"} style={{fontSize: 48}}/>
                </Col>
                <Col sm={10}>
                    <b>User Does not have permission to view. Please contact your system admin.</b>
                </Col>
            </Row>
        </Container>
    else {
        if (isNonEditable === true) {
            return <div style={{pointerEvents: 'none'}}>
                {props.children ? props.children : null}
            </div>
        } else return null
    }
};
ShowForPermissionComponent.propTypes = {
    permissions: PropTypes.array.isRequired,
    component: PropTypes.bool
}

export default ShowForPermissionComponent