import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    GET_SOCIAL_HISTORY: "GET_SOCIAL_HISTORY",
    GET_SOCIAL_HISTORY_DATA: "GET_SOCIAL_HISTORY_DATA"
}

function getSocialHistory(patientRoleId) {
    return WrapAuthentication("social/history/get", {
        payload: {patientRoleId: patientRoleId}
    }, ActionTypes.GET_SOCIAL_HISTORY)
}

function getSocialHistoryData(patientRoleId) {
    return WrapAuthentication("form/get/code/latest",{
        payload:{
            patientRoleId: patientRoleId,
            code:'ah_social_history'
        }
    }, ActionTypes.GET_SOCIAL_HISTORY_DATA)
}
function createSocialHistory(payload) {
    return WrapAuthentication("social/history/create",{
        payload:payload
    },ActionTypes.GET_SOCIAL_HISTORY_DATA)
}

function createFamilyHistory(payload){
    return WrapAuthentication("family/history/create",{
        payload:payload
    })
}

export const SocialHistoryActions = {
    getSocialHistory: getSocialHistory,
    getSocialHistoryData:getSocialHistoryData,
    createSocialHistory:createSocialHistory,
    createFamilyHistory:createFamilyHistory
}