import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "primereact/button";
import {Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {Checkbox} from "./InputControls/Checkbox";
import {Input} from "@progress/kendo-react-inputs";
import {FacilityAction} from "../action/FacilityAction";
import {PopupWindowActions, PopupWindows} from "../action/PopupWindowAction";
import ShowForPermissionComponent from "./shared/ShowForPermissionComponent";
import {PhixPermissions} from "../config/GlobalConfig";
import {Col, Container} from "../layout/GridLayout";

const FacilitiesListComponent = (props) => {
    const dispatch = useDispatch()

    const totalCount = useSelector(state => state.facility.facilities.totalCount)
    const facilityList = useSelector(state => state.facility.facilities.list)
    const showWindow = useSelector(state => state.popupWindow.showWindow)


    const styles = {
        grid: {
            height: "calc(100vh - 200px)",
            width: "100%",
        }
    }

    const [dataState, setDataState] = React.useState({take: 10, skip: 0,});
    const [searchState, setSearchState] = React.useState("")
    const [filterState, setFilterState] = React.useState(false)
    const {validationMessage, visited, label, id, valid, ...others} = props;
    const [data, setData] = React.useState();
    const [filter, setFilter] = React.useState({logic: "and", filters: []})
    const [sort, setSort] = React.useState([{field: "displayName", dir: "asc",}]);
    const [showAll, setShowAll] = React.useState(false)

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };


    const handleFacilityAdd = () => {
        PopupWindowActions.showWindow(PopupWindows.EDIT_FACILITY_WINDOW)(dispatch)
    };

    const handleFacilityEdit = async (facility) => {
        await FacilityAction.setFacilityContext(facility)(dispatch)
        await PopupWindowActions.showWindow(PopupWindows.EDIT_FACILITY_WINDOW)(dispatch)

    }
    const facilityActive = (props) => {
        return <td><Checkbox value={props.dataItem.active}/></td>
    }

    const isBillingLocation = (props) => {
        return <td><Checkbox value={props.dataItem.isBillingLocation}/></td>
    }

    const isServiceLocation = (props) => {
        return <td><Checkbox value={props.dataItem.isServiceLocation}/></td>
    }

    const facilityEdit = (props) => {
        return <td className="k-command-cell">
            <Button icon={"pi pi-pencil"} severity={"success"} onClick={() => handleFacilityEdit(props.dataItem)} text/>
        </td>;
    }


    const buildFilters = () => {
        let filters = {}
        if (!showAll)
            filters.active = true
        else
            delete filters.active
        if (searchState.length > 0) filters.searchAll = searchState
        if (filter?.filters) {
            if (filter.filters.length > 0) {
                filter.filters.forEach(x => {
                    filters[x.field] = x.value
                })
            }

        }
        return filters;
    }

    React.useEffect(() => {
            FacilityAction.getFacilityList(dataState.take, dataState.skip, sort, buildFilters())(dispatch)
        }, [dataState, sort, filter, showAll, searchState]
    )

    React.useEffect(() => {
            if (showWindow[PopupWindows.EDIT_FACILITY_WINDOW] === false) {
                setTimeout(() => {
                    FacilityAction.getFacilityList(dataState.take, dataState.skip, sort, buildFilters())(dispatch)
                }, 500);
            }
        }, [showWindow]
    )


    React.useEffect(() => {
        setData(facilityList)
    }, [facilityList])


    return (

        <ShowForPermissionComponent component permissions={[PhixPermissions.AH_ADMIN_VIEW_FACILITY]}>
            <Container style={{margin: 0, width: "100%"}}>
                <Col col={12}>
                    <Toolbar style={{width: "100%"}}>
                        <Input
                            style={{width: "50%"}}
                            placeholder={"Search"}
                            id={"input-id"}
                            value={searchState}
                            onChange={(e) => setSearchState(e.value)}
                        />

                        <ToolbarItem>
                            <Button className="rounded-button" togglable={true} icon="pi pi-filter"
                                    onClick={() => setFilterState(!filterState)}
                                    text/>
                        </ToolbarItem>
                        <ToolbarSpacer/>
                        <ToolbarItem>
                            <div className="flex align-items-center">
                                <Checkbox inputId="showAll"
                                          onChange={() => setShowAll(!showAll)}
                                          checked={showAll}/>
                                <label style={{color: "rgb(116, 132, 194)"}} htmlFor="showAll" className="ml-2">Show
                                    All</label>
                            </div>

                        </ToolbarItem>

                        <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_FACILITY]}>
                            <ToolbarItem>
                                <Button onClick={handleFacilityAdd}
                                        className="rounded-button"
                                        tooltip={"Add new facility"}
                                        tooltipOptions={{position: "top"}}
                                        icon="pi pi-plus"
                                        text/>
                            </ToolbarItem>
                        </ShowForPermissionComponent>

                    </Toolbar>

                </Col>


                <Grid
                    {...dataState}
                    style={styles.grid}
                    data={data}
                    pageSize={dataState.limit}
                    sortable={true}
                    sort={sort}
                    onDataStateChange={dataStateChange}
                    onSortChange={(e) => {
                        setSort(e.sort);
                    }}
                    total={totalCount}
                    filterable={filterState}
                    pageable={{
                        buttonCount: 20, info: true, type: "numeric", pageSizes: true, previousNext: true,
                    }}
                    filter={filter}
                    onFilterChange={(e) => setFilter(e.filter)}>

                    <GridColumn field="displayName" title="Facility Name"/>
                    <GridColumn field="street1" title="Address"/>
                    <GridColumn field="npi" title="NPI"/>
                    <GridColumn field="isServiceLocation" title="Service Location" filter={"boolean"}
                                cell={isServiceLocation}/>
                    <GridColumn field="isBillingLocation" title="Billing Location" filter={"boolean"}
                                cell={isBillingLocation}/>
                    <GridColumn field="active" title="Active" cell={facilityActive} width={80} filterable={false}/>
                    <GridColumn field="action" title="Action" cell={facilityEdit} filterable={false} width={80}/>
                </Grid>
            </Container>
        </ShowForPermissionComponent>
    )
};

FacilitiesListComponent.propTypes = {
    FacilityActions: PropTypes.object, DictionaryAction: PropTypes.object,
}


export default FacilitiesListComponent