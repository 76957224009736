import * as React from "react";
import {Drawer, DrawerContent} from "@progress/kendo-react-layout";
import {PhixPermissions} from "../config/GlobalConfig";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {ScheduleActions} from "../action/SchedulerAction";
import {FloatingActionButton} from "@progress/kendo-react-buttons";
import {connect, useSelector} from "react-redux";
import GlobalStyles from "../config/GlobalStyles";
import {useMediaQuery} from "react-responsive";
import {InterfaceActions} from "../action/InterfaceAction";
import PopupWindowsComponent from "./window/PopupWindowsComponent";
import {useHistory, useLocation} from "react-router-dom";
import {useTraceUpdate} from "../debug/Trace";

const items = [
    {
        text: "Care Team Workspace",
        icon: 'k-i-home',
        selected: true,
        route: "/",
        permission: [PhixPermissions.AH_VIEW_CARETEAM]
    },
    {
        text: "Patient List",
        id: "patient-list",
        icon: 'k-i-list-unordered',
        route: "/patientList",
        permission: [PhixPermissions.AH_VIEW_PATIENT, PhixPermissions.AH_VIEW_MY_PATIENTS]
    },
    {
        text: "Patient Panel",
        id: "patient-panel",
        icon: 'k-i-grid',
        route: "/patientPanel",
        permission: [PhixPermissions.AH_VIEW_PATIENT, PhixPermissions.AH_VIEW_MY_PATIENTS]
    },
    {
        text: "Cohort Analysis",
        id: "cohort-panel",
        icon: 'k-i-graph',
        route: "/cohort",
        permission: [PhixPermissions.AH_VIEW_ANALYTICS],
    },
    {
        text: "Admin",
        id: "admin",
        icon: 'k-i-settings',
        style: {position: "absolute", bottom: "4rem", width: "13rem"},
        route: "/admin",
        permission: [PhixPermissions.AH_PAGE_ADMIN],
    },
];
const AeriosSideMenu = (props) => {
    // useTraceUpdate(props, "Side bar")
    const location = useLocation()
    const history = useHistory()
    const {expanded, interfaceActions, authenticated, children} = props
    const permissionsCodes = useSelector(state => state.auth.session.permissions)
    const [menuItems, setMenuItems] = React.useState(items.filter(i => permissionsCodes.find(v=> i.permission.indexOf(v) > -1)))
    const [selectedId, setSelectedId] = React.useState("/");
    const handleClick = (e) => {
        interfaceActions.toggleSideBar()
    };

    const handleSelect = (ev) => {
        history.push(ev.itemTarget.props.route);
        setSelectedId(ev.itemTarget.props.id)
        isMobile && interfaceActions.toggleSideBar()
    };
    const isMobile = useMediaQuery({query: '(max-width: 1224px)'})


    const menuWidth = 220
    if (authenticated === true)
        return (
            <>
                {isMobile ?
                    <div style={{width: menuWidth}}>

                        <Drawer
                            expanded={expanded}
                            position={"start"}

                            mode={"overlay"}
                            animation={{
                                duration: 400,
                            }}
                            items={menuItems.map((item, index) => ({
                                ...item,
                                selected: item.route === location.pathname,
                                disabled: item.route === "/patientPanel" && !props.patientContext,
                            }))}
                            onOverlayClick={handleClick}
                            onSelect={handleSelect}>
                            <DrawerContent style={GlobalStyles.drawerContent}>{children}</DrawerContent>
                            <PopupWindowsComponent/>
                        </Drawer>
                        {props.showFloatingButton &&
                            <FloatingActionButton
                                icon={expanded ? "chevron-left" : "chevron-right"}

                                themeColor={"secondary"}
                                size={"small"}
                                align={{
                                    horizontal: "start",
                                    vertical: "bottom",
                                }}
                                alignOffset={{
                                    y: 50,
                                    x: expanded ? 200 : 25

                                }}
                                positionMode="fixed"
                                onClick={handleClick}
                            />}
                    </div> :
                    <div style={{width: menuWidth}}>

                        <Drawer
                            expanded={expanded}
                            position={"start"}
                            width={menuWidth}
                            mode={"push"}
                            mini={true}
                            miniWidth={45}
                            animation={{
                                duration: 400,
                            }}
                            items={menuItems.map((item, index) => ({
                                ...item,
                                selected: item.route === location.pathname,
                                disabled: item.route === "/patientPanel" && !props.patientContext,
                            }))}
                            onSelect={handleSelect}>
                            <DrawerContent style={GlobalStyles.drawerContent}>
                                {children}
                            </DrawerContent>
                            <PopupWindowsComponent/>
                        </Drawer>
                        {props.showFloatingButton &&
                            <FloatingActionButton
                                icon={expanded ? "chevron-left" : "chevron-right"}

                                themeColor={"secondary"}
                                size={"small"}
                                align={{
                                    horizontal: "start",
                                    vertical: "bottom",
                                }}
                                alignOffset={{
                                    y: 50,
                                    x: expanded ? 200 : 25

                                }}
                                positionMode="fixed"
                                onClick={handleClick}
                            />}
                    </div>
                }

            </>
        );
    else
        return (
            <div>
                {children}
            </div>)
};

AeriosSideMenu.propTypes =
    {
        scheduleActions: PropTypes.object,
    }

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        patientContext: state.patient.patientContext,
        showFloatingButton: !state.expandedWindow.expandableComponentVisible,
        expanded: state.interface.sidebar.expanded,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        scheduleActions: bindActionCreators(ScheduleActions, dispatch),
        interfaceActions: bindActionCreators(InterfaceActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AeriosSideMenu)
