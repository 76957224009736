import {Input} from "@progress/kendo-react-inputs";
import * as React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {Button} from "primereact/button";
import {filterBy} from "@progress/kendo-data-query";
import {DictionaryTypeAction} from "../../action/DictionaryTypeAction";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import moment from "moment";
import {Dialog} from "primereact/dialog";
import {Col, Container, Row} from "../../layout/GridLayout";
import {connect} from "react-redux";

const AddDictionaryTypeWindow = (props) => {
    const {DictionaryTypeActions, dictionaryTypes} = props
    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });
    const [fields, setFields] = React.useState({})
    const setValue = (field, value) => {
        setFields({...fields, [field]: value})
    }
    const [dictCodeContext, setDictCodeContext] = React.useState(props.popupState[PopupWindows.ADD_DICTIONARY_WINDOW]?.dictCodeContext)

    const reset = () => {
        setFields({})
        setDictCodeContext(null)
    }
    const hideDialog = () => {
        reset()
        props.PopupActions.hideWindow(PopupWindows.ADD_DICTIONARY_WINDOW);
        props.PopupActions.clearWindowState(PopupWindows.ADD_DICTIONARY_WINDOW);
    };
    const handleSubmit = () => {
        saveDictionaryCode(fields)
    }

    React.useEffect(() => {
        if (props.popupState[PopupWindows.ADD_DICTIONARY_WINDOW]?.dictCodeContext) {
            let dictValue = props.popupState[PopupWindows.ADD_DICTIONARY_WINDOW]?.dictCodeContext
            let newFields = {}
            if (dictValue?.id) newFields.id = dictValue.id
            if (dictValue?.code) newFields.code = dictValue.code
            if (dictValue?.codeType) newFields.codeType = dictValue.codeType
            if (dictValue?.description) newFields.description = dictValue.description
            if (dictValue?.displayName) newFields.displayName = dictValue.displayName
            if (dictValue?.active) newFields.active = dictValue.active

            setDictCodeContext(dictValue ? dictValue : {})
            setFields(newFields)
        }
    }, [props.popupState])

    const saveDictionaryCode = (form) => {
        //// console.log(form);return false;
        if (form.id) {
            let savePayload = {
                id: form.id,
                description: form?.description,
                code: form?.code,
                displayName: form?.displayName,
                inactivateDate: (form.active) ? null : moment(new Date()).local().format("YYYY-MM-DD")
            };
            DictionaryTypeActions.updateDictionaryCode(savePayload)
                .then(() => {
                    hideDialog()

                })
        } else {
            let savePayload = {
                code: form?.code,
                description: form?.description,
                displayName: form?.displayName,
                systemGenerated: true,
                createdDate: moment(new Date()).local().format("YYYY-MM-DD"),
            };

            DictionaryTypeActions.addDictionary(savePayload)
                .then(() => {
                    hideDialog()
                    DictionaryTypeActions.getDictionaryTypeList(dataState.take, dataState.skip)
                    //DictionaryActions.getDictionaryList(savePayload.dictionaryTypeId, dataState.take, dataState.skip)
                })
        }
    }

    React.useEffect(() => {
        if (props.popupState[PopupWindows.ADD_DICTIONARY_WINDOW]?.selectedDictionaryType) {
            let newFields = {}
            newFields.dictionaryTypeId = props.popupState[PopupWindows.ADD_DICTIONARY_WINDOW]?.selectedDictionaryType;
            newFields.active = true
            setFields(newFields)
        }
    }, [props.showWindow.ADD_DICTIONARY_WINDOW])

    const handleDescription = (e) => {
        setFields({...fields, description: e.target.value, displayName: e.target.value});
    }


    return (
        <Dialog
            header={"Add Dictionary"}
            onHide={hideDialog}
            visible={props.showWindow[PopupWindows.ADD_DICTIONARY_WINDOW]}
            style={{height: 300, width: 550}}
            >
            <Container gutter={true}>

                <Col col={12} className="k-form-field"> Code
                    <Input
                        name="code"
                        placeholder=""
                        value={fields?.code}
                        onChange={(e) => setValue('code', e.target.value)}
                        disabled={dictCodeContext?.id ? true : false}
                    />
                </Col>

                <Col col={12} className="k-form-field"> Description
                    <Input
                        name="code"
                        placeholder=""
                        value={fields?.description}
                        //onChange={(e) => setValue('description', e.target.value)}
                        onChange={(e) => handleDescription(e)}
                    />
                </Col>

                <Col col={12}>
                    <Row gutter className="flex-row-reverse">
                        <Col className={"flex-grow-0"}>
                            <Button label={dictCodeContext?.id ? "Update" : "Save"}
                                    severity={"success"}
                                    onClick={handleSubmit}
                                    size={"small"}/>
                        </Col>
                        <Col className={"flex-grow-0"}>
                            <Button onClick={hideDialog}
                                    label={"Cancel"}
                                    severity={"danger"}
                                    size={"small"}/>
                        </Col>

                    </Row>
                </Col>
            </Container>

        </Dialog>
    )
        ;
};

AddDictionaryTypeWindow.propTypes = {
    showWindow: PropTypes.object,
    PopupActions: PropTypes.object,
    DictionaryActions: PropTypes.object,
}


function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        popupState: state.popupWindow.state,
        dictionaryTypes: state.dictionaryType.dictionaryTypeList,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDictionaryTypeWindow);
