import * as React from "react";
import {useEffect, useState} from "react";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "primereact/button";
import {Field, FieldDictionaryDropDown, FieldRow, FieldSection, FieldTextInput} from "../InputControls/FormFields";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {PatientAction} from "../../action/PatientAction"
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../config/GlobalConfig";
import {Dialog} from "primereact/dialog";
import {useToast} from "../../providers/toast/ToastProvider";
import DocumentUpload from "../shared/DocumentUpload";
import {Row} from "../../layout/GridLayout";


const CreateDocumentWindow = (props) => {
    const {addToast} = useToast()
    const dispatch = useDispatch()
    const [fileValue, setFileValue] = React.useState("");
    const [errMessage, setErrMessage] = React.useState(null);
    const showWindow = useSelector(state => state.popupWindow.showWindow)
    const userContext = useSelector(state => state.user.userContext)
    const PatientContext = useSelector(state => state.patient.patientContext)
    const clinicalDocument = useSelector(state => state.patient.clinicalDocument)
    const getUsersInfo = useSelector(state => state.user.loggedinUser)
    const clinicalDocumentContext = useSelector(state => state.patient.clinicalDocumentContext)
    const editClinicalDocument = useSelector(state => state.patient.editClinicalDocument)
    const encounterContext = useSelector(state => state.encounters.encounterContext)
    const windowState = useSelector(state => state.popupWindow.state)
    const [defaultDocumentCategory, setDefaultDocumentCategory] = useState({displayName: "Select document category"})

    useEffect(() => {
        if (windowState[PopupWindows.CREATE_DOCUMENT_WINDOW]?.documentTypeCode) {
            setDefaultDocumentCategory(windowState[PopupWindows.CREATE_DOCUMENT_WINDOW].documentTypeCode)
        }
    }, [windowState])

    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        formState: {errors,},
    } = useForm({defaultValues: {active: true, status: true}});

    useEffect(() => {
        if (errMessage)
            addToast({
                summary: "Document Error",
                detail: errMessage
            })
    }, [errMessage])


    useEffect(() => {
        if (clinicalDocumentContext?.edit) {
            setValue("active", clinicalDocumentContext.active)
            setValue("status", clinicalDocumentContext.status)
            setValue("title", clinicalDocumentContext.title)
            setValue("displayName", clinicalDocumentContext.displayName)
            setValue("dictDocumentCategoryId", clinicalDocumentContext.dictDocumentCategoryId)
        }

    }, [clinicalDocumentContext])


    useEffect(() => {
        if (clinicalDocument?.payload) {
            addToast({
                severity: "success",
                summary: "Document Uploaded",
                detail: "Document Uploaded Successfully."
            })
            const payload = {
                patientRoleId: PatientContext?.patientRoleId,
                userRoleId: getUsersInfo.userRoleId ? PatientContext?.patientRoleId : localStorage.getItem("userRoleId")
            }
            if (encounterContext?.id) {
                let encounterPayload = {};
                encounterPayload["encounterId"] = encounterContext.id;
                PatientAction.getClinicalDocument(encounterPayload)(dispatch)
            } else {
                PatientAction.getClinicalDocument(payload)(dispatch)
            }

            hideDialog();
        }
    }, [clinicalDocument])

    useEffect(() => {
        if (editClinicalDocument?.payload) {
            clinicalDocumentContext?.setIsToast(true);
            clinicalDocumentContext?.setMessage(editClinicalDocument?.message ? editClinicalDocument?.message : "Document Updated successfully...");
            const payload = {
                patientRoleId: PatientContext?.patientRoleId,
                userRoleId: getUsersInfo.userRoleId ? PatientContext?.patientRoleId : localStorage.getItem("userRoleId")
            }
            if (encounterContext?.id) {
                let encounterPayload = {};
                encounterPayload["encounterId"] = encounterContext.id;
                PatientAction.getClinicalDocument(encounterPayload)(dispatch)
            } else {
                PatientAction.getClinicalDocument(payload)(dispatch)
            }

        }
        hideDialog();
    }, [editClinicalDocument])

    const hideDialog = () => {


        PatientAction.setClinicalDocumentContext({
            ...clinicalDocumentContext,
            edit: false
        })(dispatch)
        setFileValue(null)
        PopupWindowActions.hideWindow(PopupWindows.CREATE_DOCUMENT_WINDOW)(dispatch)
        setDefaultDocumentCategory({displayName: "Select document category"})
        reset({
            active: true,
            status: true,
            title: null,
            displayName: null,
            dictDocumentCategoryId: null
        });
    };


    const saveDocument = (form) => {
        let patientRoleId = PatientContext?.patientRoleId || windowState[PopupWindows.CREATE_DOCUMENT_WINDOW]?.patientRoleId
        if (clinicalDocumentContext?.edit) {
            const payload = {
                clinicalDocumentId: clinicalDocumentContext?.clinicalDocumentId,
                status: form?.status ? form?.status : true,
                active: form?.active,
                title: form?.title,
                displayName: form?.displayName,
                dictDocumentCategoryId: form?.dictDocumentCategoryId?.id ? form?.dictDocumentCategoryId.id : form?.dictDocumentCategoryId
            }
            if (encounterContext?.id) {
                payload["encounterId"] = encounterContext.id;
            }
            PatientAction.editClinicalDocument(payload)(dispatch)
        } else {
            let formData = new FormData();
            if (patientRoleId) {
                formData.append("patientRoleId", patientRoleId);
                formData.append("active", true);
                formData.append("status", form?.status ? form?.status : true);
                formData.append("title", form?.title);
                formData.append("displayName", form?.displayName);
                formData.append("fileName", fileValue);
                formData.append('dictDocumentCategoryId', form?.dictDocumentCategoryId?.id ? form?.dictDocumentCategoryId.id : form?.dictDocumentCategoryId)
                if (windowState[PopupWindows.CREATE_DOCUMENT_WINDOW]?.requestId) {
                    formData.append("requestId", windowState[PopupWindows.CREATE_DOCUMENT_WINDOW].requestId)
                }
                if (windowState[PopupWindows.CREATE_DOCUMENT_WINDOW]?.encounterId) {
                    formData.append("encounterId", windowState[PopupWindows.CREATE_DOCUMENT_WINDOW].encounterId);
                }
                if (windowState[PopupWindows.CREATE_DOCUMENT_WINDOW]?.hospitalizationId) {
                    formData.append("hospitalizationId", windowState[PopupWindows.CREATE_DOCUMENT_WINDOW].hospitalizationId);
                }
                PatientAction.clinicalDocument(formData)(dispatch);
            } else addToast({severity: "error", content: "Must have a patient selected to upload a document."})
        }

    }


    return (
        <Dialog
            visible={showWindow[PopupWindows.CREATE_DOCUMENT_WINDOW]}
            header={clinicalDocumentContext?.edit ? "Edit Document" : "Create Document"}
            footer={<Row className={"justify-content-end"}>
                <Button onClick={handleSubmit(saveDocument)}
                        disabled={!fileValue}
                        tooltip={!fileValue ? "Please upload a document" : "Save and upload document"}
                        label={clinicalDocumentContext?.edit ? "Update" : "Save"}/>


                <Button severity={"danger"} onClick={hideDialog} size={"small"} label={"Cancel"}/>
            </Row>}
            onHide={hideDialog}
            style={{width: 600}}
            initialHeight={400}
            initialWidth={600}>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_EDIT_DOCUMENT]}>
                {clinicalDocumentContext?.edit ? (
                    <FieldSection style={{width: "100%"}}>
                        <FieldRow>
                            <Field col={6} label={"Display Name"} field={"displayName"} error={errors}
                                   control={control} register={register}>
                                <FieldTextInput style={{border: "2px solid lightgray"}}/>
                            </Field>
                            <Field col={6} label={"Document category"} field={"dictDocumentCategoryId"} error={errors}
                                   control={control}
                                   register={register}
                                   options={{required: "Document category is required."}}>
                                <FieldDictionaryDropDown code={Dictionary.AH_DOCUMENT_CATEGORIES}
                                                         defaultItemCode={defaultDocumentCategory}
                                                         dataItem={userContext}
                                />
                            </Field>
                        </FieldRow>
                    </FieldSection>
                ) : (
                    <FieldSection style={{width: "100%"}}>
                        <FieldRow>
                            <Field className={"col-6"} label={"Title"} field={"title"} error={errors}
                                   control={control} register={register}>
                                <FieldTextInput style={{border: "2px solid lightgray"}}/>
                            </Field>
                            <Field className={"col-6"} label={"Display Name"} field={"displayName"} error={errors}
                                   control={control} register={register}>
                                <FieldTextInput style={{border: "2px solid lightgray"}}/>
                            </Field>

                        </FieldRow>
                        <FieldRow>
                            <Field col={12} label={"Document category"} field={"dictDocumentCategoryId"} error={errors}
                                   control={control}
                                   register={register}
                                   options={{required: "Document category is required."}}>
                                <FieldDictionaryDropDown code={Dictionary.AH_DOCUMENT_CATEGORIES}
                                                         defaultItemCode={defaultDocumentCategory}
                                                         disabled={encounterContext?.dictDocumentCategoryId ? true : false}
                                                         dataItem={userContext}
                                />
                            </Field>

                            <Field col={12} label={"File Name"} field={"fileName"} error={errors}
                                   control={control} register={register} options={{required: "File name is required."}}>
                                <DocumentUpload onChange={setFileValue}/>
                                {/*<DocumentUpload onChange={setFileValue} setErrorMessage={(m) => {*/}
                                {/*    setErrMessage(m)*/}
                                {/*}}/>*/}
                            </Field>
                        </FieldRow>
                    </FieldSection>
                )}
            </ShowForPermissionComponent>
        </Dialog>
    );

}

CreateDocumentWindow.propTypes = {
    showWindow: PropTypes.object,
    PopupActions: PropTypes.object,
    UserActions: PropTypes.object,
    authActions: PropTypes.object,
    PatientActions: PropTypes.object,
    PatientContext: PropTypes.object,
    popupState: PropTypes.object
}


export default CreateDocumentWindow