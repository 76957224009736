import React, {useEffect} from 'react';
import {PatientAction} from "../../../action/PatientAction";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";

import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {DictionaryDispatchAction} from "../../../action/DictionaryDispatchAction";
import {Dictionary} from "../../InputControls/DictionaryDropDownList";
import {PopupWindowActions, PopupWindows} from "../../../action/PopupWindowAction";
import {Button} from "primereact/button"
import {Col, Container, Row} from "../../../layout/GridLayout";
import ShowForPermissionComponent from '../../shared/ShowForPermissionComponent';
import {PhixPermissions} from "../../../config/GlobalConfig";
import {IoCaretForwardCircleOutline, IoCaretBackCircleOutline} from "react-icons/io5";
import {ToolbarSpacer} from "@progress/kendo-react-buttons";


const _ = require('lodash');

const VitalsTabComponent = (props) => {
    const dispatch = useDispatch()
    const patientContext = useSelector(state => state.patient.patientContext)
    const dictionaries = useSelector(state => state.dictionary.dictionaries)
    const patientObservationVitals = useSelector(state => state.patient.patientObservationVitals)
    const lineChartTitle = useSelector(state => state.popupWindow.lineChart.title)


    const [dynamicColumns, setDynamicColumns] = React.useState([])
    const [dataState, setDataState] = React.useState({});
    const [data, setData] = React.useState([])

    const [offset, setOffset] = React.useState(0);

    const [categoryId, setCategoryId] = React.useState(null)

    useEffect(() => {
        DictionaryDispatchAction.getDictionaryByCode(Dictionary.OBSERVATION_CATEGORY)(dispatch)
    }, [])

    useEffect(() => {
        if (categoryId) {
            PatientAction.patientObservationVitals(patientContext.patientRoleId, categoryId, offset, 9)(dispatch)
        }
    }, [offset])

    React.useEffect(() => {
        if (dictionaries[Dictionary.OBSERVATION_CATEGORY]) {
            let vital = dictionaries[Dictionary.OBSERVATION_CATEGORY].find(e => e.code === "vital-signs")
            setCategoryId(vital.id)
        }
    }, [dictionaries])

    React.useEffect(() => {
        if (categoryId) {
            PatientAction.patientObservationVitals(patientContext.patientRoleId, categoryId, offset, 9)(dispatch)
        }
    }, [categoryId])

    React.useEffect(() => {
        let values = _.groupBy(patientObservationVitals, "dictObservationTypeCode")
        let mappedValues = Object.keys(values).map(item => {
            return {itemKey: item, name: values[item][0].description, data: values[item]}
        })
        let d = _.sortBy(mappedValues, "name");
        setData(d)
        if (lineChartTitle) {
            let dataItem = d.find(v=> v.name === lineChartTitle)
            if (dataItem) {
                let dataItemData = dataItem.data;
                let title = dataItem.name
                let values = _.groupBy(dataItemData, "effectiveStartDate")
                let xAxis = Object.keys(values).map(h=> moment(h).format('MM/DD/YYYY'))
                let yAxis = []
                for (let k in values) {
                    yAxis.push(values[k].reduce((a,v) => { return a+ parseInt(v?.value);}, 0) / values[k].length)
                }
                PopupWindowActions.updateLineChart(title, xAxis.reverse(), yAxis.reverse(), offset, setOffset)(dispatch)
            }
        } else {
            PopupWindowActions.updateLineChart("",[] , [])(dispatch)
        }
    }, [patientObservationVitals])


    React.useEffect(() => {
        if (patientObservationVitals && patientObservationVitals?.length > 0) {
            let columns = _.sortedUniq(patientObservationVitals.map(e => {
                return e.effectiveStartDate
            }))
            setDynamicColumns(columns)
        } else {
            setDynamicColumns([])
        }
    }, [data])

    const DynamicGridColumn = (props) => {
        const {date, field, value, dataItem} = props
        let result = dataItem.data.filter(i => i.effectiveStartDate === date)
        const val = result && result.length > 1 ? result.reduce((a,v) => { return a+ parseInt(v?.value);}, 0) / result.length + "*": result[0]?.value;
        return (
            <td colSpan={props.colSpan} style={props.style}>
                {result && result.length > 0 ? `${val} ${result[0].units ? result[0].units : ""}` : "N/A"}
            </td>
        )
    }

    const detailhead = (ee) => {
        return <td><b>{ee.dataItem.name}</b></td>
    }

    const editHandler = () => {
        PopupWindowActions.showWindow(PopupWindows.EDIT_VITAL, {encounterContext: null})(dispatch)
    }

    const DatePager = () => {
        return <div className={"k-pager k-pager-md k-grid-pager"}>
                   <IoCaretBackCircleOutline style={{height: 45, width:45}} onClick={() => setOffset(offset+9)}/>
                   <ToolbarSpacer/>
                   <span>* indicates an average of multiple readings</span>
                   <ToolbarSpacer/>
                   <IoCaretForwardCircleOutline style={{height: 45, width:45}} onClick={() => {
                        if (offset > 0)
                            setOffset(offset-9)
                   }}/>
               </div>
    }

    return (
        <Container style={{height: "100%", width: "100%"}}>
            <Col col={12}>
                <Row className={"justify-content-end"} style={{height: "50px", width: "100%"}}>
                    <Col className={"flex-grow-0"}>
                        <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                            <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_OBSERVATION]}>
                                <Button text
                                        tooltip={"Add Vitals"}
                                        icon={"pi pi-plus"}
                                        size={"small"}
                                        onClick={editHandler}/>
                            </ShowForPermissionComponent>
                        </ShowForPermissionComponent>
                    </Col>
                </Row>
            </Col>
            <Row style={{height: "calc(100% - 50px)"}}>
                    <Grid
                        {...dataState}
                        style={{height: "100%", width: "98%"}}
                        onRowClick={(e) => {
                            let data = e.dataItem.data
                            let title = e.dataItem.name
                            let values = _.groupBy(data, "effectiveStartDate")
                            let xAxis = []
                            let yAxis = []
                            for (let k in values) {
                                if (values[k].length > 1) {
                                    xAxis.push(moment(k).format('MM/DD/YYYY') + "*");
                                } else {
                                    xAxis.push(moment(k).format('MM/DD/YYYY'));
                                }
                                yAxis.push(values[k].reduce((a,v) => { return a+ parseInt(v?.value);}, 0)/values[k].length)
                            }
                            PopupWindowActions.showLineChart(title, xAxis.reverse(), yAxis.reverse(), offset, setOffset)(dispatch)
                        }}
                        data={data}
                        sortable={true}
                        resizable={true}
                        pageable={true}
                        pager={DatePager}
                        expandField="expanded">
                        <GridColumn title={<b>Finding</b>} locked={true} width={"150px"} cell={(e) => detailhead(e)}/>
                        <DynamicGridColumn/>
                        {dynamicColumns.map((h, index) => {
                            return <GridColumn field={"description"}
                                               title={<b>{moment(h).format('MM/DD/YYYY')}</b>}
                                               date={h}
                                               width={"120px"}
                                               cell={(props) => <DynamicGridColumn date={h} {...props} />}
                                               key={h}/>
                        })}
                    </Grid>
            </Row>
        </Container>
    )
}

export default VitalsTabComponent