import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    REQUEST_SET_CREATE_CONTEXT: "REQUEST_SET_CREATE_CONTEXT",
    CLEAR_REQUEST_CONTEXT: "CLEAR_REQUEST_CONTEXT",
    REQUEST_SET_CREATE_CONTEXT_ERROR: "REQUEST_SET_CREATE_CONTEXT_ERROR",
    REQUEST_LIST_SUCCESS: "REQUEST_LIST_SUCCESS",
    REQUEST_LIST_SUCCESS_ERROR: "REQUEST_LIST_SUCCESS_ERROR",
    REQUEST_SET_CONTEXT: "REQUEST_SET_CONTEXT",
    GET_REQUEST_CONTEXT: "GET_REQUEST_CONTEXT",
    UPDATED_REQUEST_CONTEXT: "UPDATED_REQUEST_CONTEXT",
    UPDATED_REQUEST_CONTEXT_ERROR: "UPDATED_REQUEST_CONTEXT_ERROR",
    BY_USER: "BY_USER",
    ONLY_COMPLETED: "ONLY_COMPLETED",
    COMPLETE_REQUEST: "COMPLETE_REQUEST",
    GET_ESIGN: "GET_ESIGN",
    GET_ESIGN_ERROR: "GET_ESIGN_ERROR",
    CREATE_ESIGN: "CREATE_ESIGN",
    CREATE_ESIGN_ERROR: "CREATE_ESIGN_ERROR",
    CLEAR_ESIGN: "CLEAR_ESIGN",
    GET_PATIENT_MODEL: "GET_PATIENT_MODEL",
    CLEAR_PATIENT_MODEL: "CLEAR_PATIENT_MODEL",
    GET_PATIENT_MODEL_ERROR: "GET_PATIENT_MODEL_ERROR",
    GET_PATIENT_DEMOGRAPHIC: "GET_PATIENT_DEMOGRAPHIC",
    CLEAR_PATIENT_DEMOGRAPHIC: "CLEAR_PATIENT_DEMOGRAPHIC",
    GET_PATIENT_DEMOGRAPHIC_ERROR: "GET_PATIENT_DEMOGRAPHIC_ERROR",
    SET_REQUEST_LIST_CONTEXT: "SET_REQUEST_LIST_CONTEXT",
}


function createRequest(Request) {
    return WrapAuthentication("baserequest/create",
        {"payload": Request},
        ActionTypes.REQUEST_SET_CREATE_CONTEXT)
}

function getRequestById(Request) {
    return WrapAuthentication("baserequest/get",
        {"payload": Request},
        ActionTypes.GET_REQUEST_CONTEXT)
}

function completeRequest(RequestID) {
    return WrapAuthentication("baserequest/update/status",
        {
            "payload": {
                "baseRequestId": RequestID
            }
        },
        ActionTypes.COMPLETE_REQUEST)
}

function updateRequest(Request) {
    return WrapAuthentication("baserequest/update",
        {"payload": Request},
        ActionTypes.UPDATED_REQUEST_CONTEXT, ActionTypes.UPDATED_REQUEST_CONTEXT_ERROR)
}

function clearContext() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_REQUEST_CONTEXT});
        dispatch({type: ActionTypes.CLEAR_ESIGN})
        dispatch({type: ActionTypes.CLEAR_PATIENT_MODEL})
        dispatch({type: ActionTypes.CLEAR_PATIENT_DEMOGRAPHIC})
    }
}

function setContext(request) {
    return async dispatch =>
        dispatch({type: ActionTypes.REQUEST_SET_CONTEXT, payload: request})
}

function setByUser(flag) {
    return async dispatch =>
        dispatch({type: ActionTypes.BY_USER, payload: flag})
}

function onlyCompleted(flag) {
    return async dispatch =>
        dispatch({type: ActionTypes.ONLY_COMPLETED, payload: flag})
}
function requestListContext(v) {
    return async dispatch =>
        dispatch({type: ActionTypes.SET_REQUEST_LIST_CONTEXT, payload: v})
}

function getAllRequests(Request) {
    return WrapAuthentication("baserequest/getall",
        Request,
        ActionTypes.REQUEST_LIST_SUCCESS, ActionTypes.REQUEST_LIST_SUCCESS_ERROR)
}

function createeSign(data) {
    return WrapAuthentication("referral/esign/create",
        {payload: data},
        ActionTypes.CREATE_ESIGN, ActionTypes.CREATE_ESIGN_ERROR)
}

function geteSign(data) {
    return WrapAuthentication("referral/esign/get",
        {payload: data},
        ActionTypes.GET_ESIGN, ActionTypes.GET_ESIGN_ERROR)
}

function getpatientModel(patientRoleId) {
    return WrapAuthentication("patient/model/get",
        {
            payload:
                {
                    "patientRoleId": patientRoleId,
                    "active": true
                }
        },
        ActionTypes.GET_PATIENT_MODEL)
}

function getpatientDemographics(patientRoleId) {
    return WrapAuthentication("patient/model/demographics",
        {
            payload:
                {
                    "patientRoleId": patientRoleId
                }
        },
        ActionTypes.GET_PATIENT_DEMOGRAPHIC)
}

export const BaseRequestAction = {
    createRequest: createRequest,
    getAllRequests,
    clearContext,
    setContext,
    updateRequest,
    setByUser: setByUser,
    completeRequest,
    onlyCompleted: onlyCompleted,
    createeSign,
    geteSign,
    getpatientModel,
    getpatientDemographics,
    getRequestById,
    requestListContext
}
