import {
    AgendaView,
    DayView,
    MonthView,
    Scheduler,
    useSchedulerDateContext, useSchedulerViewsContext,
    WeekView
} from "@progress/kendo-react-scheduler";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Menu} from "primereact/menu";
import PropTypes from "prop-types";
import * as React from "react";
import {connect, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {EncounterAction} from "../../action/EncounterAction";
import {OpenEmrActions} from "../../action/OpenEmrAction";
import {PatientAction} from "../../action/PatientAction";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {ScheduleActions} from "../../action/SchedulerAction";
import {SecuritySettingsAction} from "../../action/SecuritySettingsAction";
import {UserActions} from "../../action/UserAction";
import {WindowActions} from "../../action/WindowAction";
import {PhixPermissions} from "../../config/GlobalConfig";
import GlobalStyles from "../../config/GlobalStyles";
import {Col, Row} from "../../layout/GridLayout";
import {TileBody} from "../../layout/TileBody";
import {TileContainer} from "../../layout/TileContainer";
import TileHeader from "../../layout/TileHeader";
import {useToast} from "../../providers/toast/ToastProvider";
import {FormWithCustomEditor} from "../FormWithCustomEditor";
import {Dictionary} from '../InputControls/DictionaryDropDownList';
import FacilityDropDownList from "../InputControls/FacilityDropDownList";
import PatientDropDownList from "../InputControls/PatientDropDownList";
import UserDropDownList from "../InputControls/UserDropDownList";
import {Components} from "../shared/ComponentRegistry";
import ShowForPermissionComponent, {usePermission} from '../shared/ShowForPermissionComponent';
import {useCallback, useEffect, useState} from "react";
import moment from "moment";

const currentYear = new Date().getFullYear();
// const parseAdjust = eventDate => {
//     const date = new Date(eventDate);
//     date.setFullYear(currentYear);
//     return date;
// };

const currentDate = () => {
    const date = new Date();
    date.setFullYear(currentYear);
    return date;
};
const customModelFields = {
    id: 'Id',
    title: 'Title',
    description: 'Description',
    patient: 'Patient',
    participants: 'Participants',
    meetingFormat: 'MeetingFormat',
    meetingType: 'MeetingType',
    start: 'Start',
    end: 'End',
    recurrenceRule: 'RecurrenceRule',
    recurrenceId: 'RecurrenceID',
    recurrenceExceptions: 'RecurrenceExceptions'
};

const SchedulerComponent = (props) => {
    const {scheduleData} = props;
    const [data, setData] = React.useState([]);
    const history = useHistory()
    const [teleURL, setTeleURL] = React.useState([]);
    const showAllEvents = useSelector(state => state.schedule.schedulerFilters?.showAllEvents)
    const [showAllEventsLoc, setShowAllEventsLoc] = React.useState(showAllEvents)
    const defaultDate = useSelector(state => state.schedule.schedulerFilters?.defaultDate)
    const defaultView = useSelector(state => state.schedule.schedulerFilters?.defaultView)
    const [showFilters, setShowFilters] = React.useState(false);
    const filterFacility = useSelector(state => state.schedule.schedulerFilters?.filterFacility)
    const filterProvider = useSelector(state => state.schedule.schedulerFilters?.filterProvider)
    const filterPatient = useSelector(state => state.schedule.schedulerFilters?.filterPatient)
    const isFilterApplied = useSelector(state => state.schedule.schedulerFilters?.isFilterApplied)
    const filterByText = useSelector(state => state.schedule.schedulerFilters?.filterByText)
    const isClear = useSelector(state => state.schedule.schedulerFilters?.isClear)
    const menu = React.useRef(null)
    let showAllPatients = usePermission([PhixPermissions.AH_VIEW_PATIENT])
    const [dateRange, setDateRange] = useState({
        startDate: defaultDate ? moment(defaultDate).startOf('month').toDate() : moment().startOf('month').toDate(),
        endDate: defaultDate ? moment(defaultDate).endOf('month').toDate() : moment().endOf('month').toDate()
    })

    const clearFilters = () => {
        props.scheduleActions.setSchedulerFilters("filterFacility", null);
        props.scheduleActions.setSchedulerFilters("filterProvider", null);
        props.scheduleActions.setSchedulerFilters("filterPatient", null);
        props.scheduleActions.setSchedulerFilters("isClear", true);
        setShowFilters(false)
    }

    const ActionMenuItems = (showAllPatients) ? [{
            label: "Filter Schedule",
            items: [
                {
                    label: "Show All My Events",
                    icon: showAllEventsLoc === "show_all_my_events" ? "pi pi-check" : null,
                    command: () => {
                        props.scheduleActions.setSchedulerFilters("showAllEvents", "show_all_my_events")
                    }
                },
                {
                    label: "Show All Cohort Events",
                    icon: showAllEventsLoc === "show_all_cohort_events" ? "pi pi-check" : null,
                    command: () => {
                        props.scheduleActions.setSchedulerFilters("showAllEvents", "show_all_cohort_events")
                    }
                },
                {
                    label: "Show All Events",
                    icon: showAllEventsLoc === "show_all_events" ? "pi pi-check" : null,
                    command: () => {
                        props.scheduleActions.setSchedulerFilters("showAllEvents", "show_all_events")
                    }
                },
                {
                    label: "Custom Filter",
                    icon: isFilterApplied ? "pi pi-check" : null,
                    style: {color: isFilterApplied ? "red" : "black"},
                    command: () => {
                        setShowFilters(true)

                    }
                },
                {
                    label: "Clear Filter",
                    command: clearFilters
                }

            ]
        }] :
        [
            {
                label: "Filter Schedule",
                items: [
                    {
                        label: "Show All My Events",
                        icon: showAllEventsLoc === "show_all_my_events" ? "pi pi-check" : null,
                        command: () => {
                            props.scheduleActions.setSchedulerFilters("showAllEvents", "show_all_my_events")
                        }
                    },
                    {
                        label: "Show All Cohort Events",
                        icon: showAllEventsLoc === "show_all_cohort_events" ? "pi pi-check" : null,
                        command: () => {
                            props.scheduleActions.setSchedulerFilters("showAllEvents", "show_all_cohort_events")
                        }
                    },
                    {
                        label: "Custom Filter",
                        icon: isFilterApplied ? "pi pi-check" : null,
                        style: {color: isFilterApplied ? "red" : "black"},
                        command: () => {
                            setShowFilters(true)

                        }
                    },
                    {
                        label: "Clear Filter",
                        command: clearFilters
                    }

                ]
            }]
    React.useEffect(() => {
        handleEventDataChange();
        props.DictionaryActions.getDictionaryByCode(Dictionary.MEETING_FORMAT)
        const payload = {tenantId: props.tenant.tenantId, userRoleId: props.loggedInUser.userRoleId};
        props.SecuritySettingsActions.getSecuritySetting(payload);
    }, [])

    React.useEffect(() => {
        handleEventDataChange();
        props.scheduleActions.setSchedulerFilters("isClear", false);
    }, [isClear === true && filterPatient === null && filterProvider === null && filterFacility === null])


    const FilterFooterContent = () => {
        return <div>
            <Button label="Apply" icon="pi pi-check" onClick={() => {
                handleEventDataChange();
                setShowFilters(false)
            }} autoFocus/>
            <Button label="Clear" icon="pi pi-times" onClick={clearFilters} className="p-button-text"/>
        </div>
    };

    const GetDateRange = (scheduleType) => {
        switch (scheduleType) {
            case 'month':
                return {
                    startDate: defaultDate ? moment(defaultDate).startOf('month').utc(true).toDate() : moment().startOf('month').utc(true).toDate(),
                    endDate: defaultDate ? moment(defaultDate).endOf('month').utc(true).toDate() : moment().endOf('month').utc(true).toDate()
                }
            case 'day':
                return {
                    startDate: defaultDate ? moment(defaultDate).startOf('day').utc(true).toDate() : moment().startOf('day').utc(true).toDate(),
                    endDate: defaultDate ? moment(defaultDate).endOf('day').utc(true).toDate() : moment().endOf('day').utc(true).toDate()
                }
            // case 'agenda':
            //     return {
            //         startDate: defaultDate ? moment(defaultDate).startOf('day').utc(true).toDate() : moment().startOf('day').utc(true).toDate(),
            //         endDate: defaultDate ? moment(defaultDate).endOf('day').utc(true).toDate() : moment().endOf('day').utc(true).toDate()
            //     }
            default:
                return {
                    startDate: defaultDate ? moment(defaultDate).startOf('week').utc(true).toDate() : moment().startOf('week').utc(true).toDate(),
                    endDate: defaultDate ? moment(defaultDate).endOf('week').utc(true).toDate() : moment().endOf('week').utc(true).toDate()
                }
        }

    }


    useEffect(() => {
        setDateRange(GetDateRange(defaultView))
    }, [defaultView, defaultDate])

    useEffect(() => {
        handleEventDataChange()
    }, [dateRange])

    const handleEventDataChange = () => {
            let sampleData;

            const payload = {
                "userRoleId": localStorage.getItem('userRoleId'),
                facility: filterFacility?.id,
                provider: filterProvider?.id,
                patient: filterPatient?.patientRoleId,
                startDate: dateRange.startDate.toISOString(),
                endDate: dateRange.endDate.toISOString()
            }

            if (filterFacility?.id || filterProvider?.id || filterPatient?.patientRoleId) {
                const text = <>
                    <div>*Filtered
                        by {[filterFacility?.id ? "Facility" : null, filterProvider?.id ? "Participant" : null, filterPatient?.patientRoleId ? "Patient" : null].filter(v => v).join(", ")}</div>
                </>;
                props.scheduleActions.setSchedulerFilters("isFilterApplied", true);
                props.scheduleActions.setSchedulerFilters("filterByText", text);
            } else {
                props.scheduleActions.setSchedulerFilters("isFilterApplied", false);
                props.scheduleActions.setSchedulerFilters("filterByText", null);
            }
            if (showAllEvents === "show_all_cohort_events") {
                props.scheduleActions.getAllParticipantByUserRoleID(payload);
                // sampleData = prepareData();
                // if (scheduleData !== true) {
                //     setData(sampleData)
                // }
            } else if (showAllEvents === "show_all_events") {
                props.scheduleActions.getScheduleAll(payload);
                // sampleData = prepareData();
                // if (scheduleData !== true) {
                //     setData(sampleData)
                // }
            } else {
                props.scheduleActions.getAllSchedulesByUSerRoleAsParticipants(payload);
                // sampleData = prepareData();
                // if (scheduleData !== true) {
                //     setData(sampleData)
                // }
            }
        }
    ;

    React.useEffect(() => {
        handleEventDataChange();
    }, [showAllEventsLoc])

    React.useEffect(() => {
        setShowAllEventsLoc(showAllEvents);
    }, [showAllEvents])

    const prepareData = () => {
        let str_array = [];
        if (scheduleData?.length > 0) {
            scheduleData?.forEach(element => {
                if (element.recurrenceException) {
                    if (element.recurrenceException.indexOf(',') > -1) {
                        str_array = element.recurrenceException.split(',');
                    } else {
                        str_array.push(element.recurrenceException)
                    }
                }
            });

            let strArry = str_array.map(function (elm) {
                return new Date(elm)
            })

            return scheduleData.map(dataItem => ({
                Id: dataItem.id,
                Start: moment(dataItem.startDate).toDate(),//new Date(dataItem.startDate),
                End: moment(dataItem.endDate).toDate(),//new Date(dataItem.endDate),
                //start:new Date("2021-10-24T08:30:00.000Z"),
                //end: new Date("2021-10-24T09:00:00.000Z"),
                isAllDay: dataItem.isAllDay,
                Title: dataItem.title,
                Description: dataItem.description,
                Patient: dataItem.patientRoleId,
                patientFirstName: dataItem.patientFirstName,
                patientLastName: dataItem.patientLastName,
                patientMiddleName: dataItem.patientMiddleName,
                Participants: (dataItem.participants || []).map(item => ({
                    ...item,
                    displayName: `${item.firstName} ${item.middleName || ""} ${item.lastName} - ${item.userRoles}`
                })),
                patientExternalId: dataItem.patientExternalId,
                MeetingFormat: dataItem.meetingFormats,
                MeetingType: dataItem.encounterType,
                RecurrenceRule: dataItem?.recurrenceRule?.replaceAll(/BYSETPOS=\d;/g, ""),
                RecurrenceExceptions: (dataItem.recurrenceException) ? strArry : '',//dataItem.recurrenceExceptions//[new Date('2021-12-07T03:00:00.000Z')]//
                telehealthAddressUserRoleId: dataItem?.telehealthAddress?.userRoleId
            }));
        } else
            return []
    }
    useEffect(() => {
            let newSchedule = prepareData();
            // console.log(newSchedule)
            if (newSchedule !== true) {
                setData(newSchedule)
            }
        }, [scheduleData]
    )
    ;

    React.useEffect(() => {
        props.userActions.getUser({userRoleId: props.userRoleId})
    }, [props.authenticated])

    React.useEffect(() => {
        const url = props?.securitySettings?.find(v => v.key && v.value && v.key === "telehealthAddress")
        if (url) {
            setTeleURL(url.value["telehealthAddress"])
        }
    }, [props?.securitySettings])

    const handleDataChange = ({created, updated, deleted}) => {
        if (created.length > 0) {
            addSchedule(created);
        }
        if (updated.length > 0) {
            editSchedule(updated)
        }
        if (deleted.length > 0) {
            deletedSchedule(deleted)
        }
    }
    const handleDateChange = async (e) => {
        await props.scheduleActions.setSchedulerFilters("defaultDate", e.value);
    }

    const handleViewChange = async (e) => {
        await props.scheduleActions.setSchedulerFilters("defaultView", e.value);
    }
    const addSchedule = (created) => {
        const dataValueSet = created.map((value) => {
            let userRoleIds = [];
            if (value.Participants) {
                userRoleIds = value.Participants.map((value) => {
                    return {
                        "userRoleId": value.userRoleId
                    }
                })
            }
            const selectedUserIds = userRoleIds?.map(v => v.userRoleId);
            const add = props?.allUsers?.filter(v => selectedUserIds.indexOf(v.userRoleId) > -1 && v.telehealthAddress)
            var telehealthAddressUserRoleId = null;
            if (add?.length === 1) {
                telehealthAddressUserRoleId = add[0].userRoleId;
            } else {
                telehealthAddressUserRoleId = value?.telehealthAddressUserRoleId;
            }
            let meetingFormatIds = [];
            if (value.MeetingFormat) {
                meetingFormatIds = value.MeetingFormat.map((value) => {
                    return {
                        "dictionaryId": value.id
                    }
                })
            }
            let recValAddArr = ''
            if (value.RecurrenceExceptions) {
                recValAddArr = value.RecurrenceExceptions.map(function (element) {
                    return new Date(element.toString()).toISOString()
                })
            }
            return {
                "patientRoleId": (value?.Patient?.patientRoleId) ? value.Patient.patientRoleId : null,// PatientRec.patientRoleId,
                "participants": userRoleIds,//value.Participants[0].userRoleId,
                "meetingFormats": meetingFormatIds,//value.MeetingFormat[0].id,
                "dictEncounterTypeId": (value.MeetingType) ? value.MeetingType.id : null,
                "title": value.Title,
                "description": (value.Description) ? value.Description : '',
                "startDate": new Date(value.Start),// moment(new Date(value.Start)).format('YYYY-MM-DDTHH:MM:SS'),
                "endDate": new Date(value.End),//moment(new Date(value.End)).format('YYYY-MM-DDTHH:MM:SS'),
                "recurrenceRule": value.RecurrenceRule,
                "recurrenceId": value.RecurrenceID,//"string",
                //"recurrenceException": value.RecurrenceExceptions,//"string",
                "recurrenceException": recValAddArr.toString(),
                "isAllDay": value.isAllDay,
                "telehealthAddressUserRoleId": telehealthAddressUserRoleId
            }
        })
        dataValueSet.map((value) => {
            const payload = value
            props.scheduleActions.createSchedule(payload)
                .then(() => handleEventDataChange())
        })
    }
    const editSchedule = (updated) => {
        const dataValueSet = updated.map((value) => {
            const PatientRec = value.Patient;//props.patients.find(p => p.firstName === value.Patient);
            let userRoleIds = [];
            if (value.Participants) {
                userRoleIds = value.Participants.map((value) => {
                    return {
                        "userRoleId": value.userRoleId
                    }
                })
            }

            const selectedUserIds = userRoleIds?.map(v => v.userRoleId);
            const add = props?.allUsers?.filter(v => selectedUserIds.indexOf(v.userRoleId) > -1 && v.telehealthAddress)
            var telehealthAddressUserRoleId = null;
            if (add?.length === 1) {
                telehealthAddressUserRoleId = add[0].userRoleId;
            } else {
                telehealthAddressUserRoleId = value?.telehealthAddressUserRoleId;
            }
            let meetingFormatIds = [];
            if (value.MeetingFormat) {
                meetingFormatIds = value.MeetingFormat.map((value) => {
                    return {
                        "dictionaryId": value.id
                    }
                })
            }
            let recValArr;
            if (value?.RecurrenceExceptions?.length > 0) {
                recValArr = value.RecurrenceExceptions.map(function (element) {
                    return new Date(element.toString()).toISOString()
                })
            } else {
                recValArr = value.RecurrenceExceptions
            }
            return {
                "id": value.Id,
                "userRoleId": localStorage.getItem('userRoleId'),
                "patientRoleId": (value.Patient?.patientRoleId) ? value.Patient?.patientRoleId : PatientRec,//PatientRec,//PatientRec.patientRoleId,
                "participants": userRoleIds,//value.Participants[0].userRoleId,
                "meetingFormats": meetingFormatIds,//value.MeetingFormat[0].id,
                "dictEncounterTypeId": (value.MeetingType) ? value.MeetingType.id : null,
                "title": value.Title,
                "description": (value.Description) ? value.Description : '',
                "startDate": new Date(value.Start),// moment(new Date(value.Start)).format('YYYY-MM-DDTHH:MM:SS'),
                "endDate": new Date(value.End),//moment(new Date(value.End)).format('YYYY-MM-DDTHH:MM:SS'),
                "recurrenceRule": value.RecurrenceRule,
                "recurrenceId": value.RecurrenceID,
                //"recurrenceException": new Date(value.RecurrenceExceptions.toString()).toISOString(),//JSON.stringify([new Date(value.RecurrenceExceptions)]),//new Date(value.RecurrenceExceptions.toString()).toISOString(),
                "recurrenceException": recValArr?.toString(),
                "isAllDay": value.isAllDay,
                "telehealthAddressUserRoleId": telehealthAddressUserRoleId
            }
        })
        const payloadGetSchedule = {
            "userRoleId": localStorage.getItem('userRoleId')
        }
        dataValueSet.map((value) => {
            const payload = value
            props.scheduleActions.updateSchedule(payload)
                .then(() => handleEventDataChange())
        })
    }
    const deletedSchedule = (deleted) => {
        const payload = {scheduleId: deleted[0].Id}
        props.scheduleActions.deleteSchedule(payload)
            .then(() => handleEventDataChange())
    }
    const CustomAgendaView = (props) => {
        let participantsText;
        if (props.dataItem.Participants !== '' || props.dataItem.Participants !== undefined) {
            if (props.dataItem.Participants.length >= 2) {
                participantsText = "With multiple participants";
            } else if (props.dataItem.Participants.length === 1) {
                let name = props.dataItem.Participants.map((val) => {
                    return val.firstName + ' ' + ((val.lastName) ? val.lastName : '')
                })
                participantsText = "With " + name.toString();
            } else if (props.dataItem.Participants.length === 0) {
                participantsText = "Meeting alone";
            } else {
                participantsText = '';
            }
        }
        let formatText = '';
        let isVideo = false;
        if (props.dataItem.MeetingFormat !== undefined) {
            props.dataItem.MeetingFormat.map((val) => {
                if (val.displayName === 'Audio') {
                    formatText = ", audio only,"
                } else if (val.displayName === 'Group Text') {
                    formatText = " using group text"
                } else if (val.displayName === 'Video') {
                    formatText = " over video"
                    isVideo = true;
                } else {
                    formatText = " in person" // In person
                }
            })
            if (props.dataItem.MeetingFormat.length > 1) {
                formatText = " over multiple meeting formats";
            }
        }
        let patientText = '';
        if (props.dataItem.Patient) {
            patientText = "regarding " + props.dataItem.patientFirstName + ' ' + props.dataItem.patientLastName
        }

        return (
            <div style={{width: '100%', position: 'relative', paddingLeft: '35px', whiteSpace: 'normal',}}>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}>
                    {isVideo ? teleURL ?
                        <a href={teleURL} target="_blank"><span class="fa fa-video-camera"
                                                                style={{
                                                                    position: 'absolute', left: '0px', top: '10px',
                                                                    color: '#990001', fontSize: '25px',
                                                                }}
                        /></a> : <a target="_blank"><span class="fa fa-video-camera"
                                                          style={{
                                                              position: 'absolute', left: '0px', top: '10px',
                                                              color: '#990001', fontSize: '25px',
                                                          }}
                        /></a> : <></>

                    }
                </ShowForPermissionComponent>

                <div style={{fontSize: '18px', lineHeight: '27px', marginBottom: '5px'}}>
                    {props.title}
                </div>

                <div>
                    {participantsText}{formatText} {patientText}
                </div>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}>
                    {props.dataItem?.MeetingType?.displayName &&
                        <Button
                            onClick={() => handleEncounter(props.dataItem)}
                            style={{
                                height: 20,
                                position: 'absolute',
                                right: '0', top: '0',
                            }}>
                            {props.dataItem ? props.dataItem?.MeetingType?.displayName : ""}
                        </Button>}
                </ShowForPermissionComponent>

            </div>
        )

    }
    const FilterStatus = (props) => {
        switch (showAllEventsLoc) {
            case "show_all_my_events":
                return <i style={{paddingLeft: 5, color: "red"}}>All My Events</i>
            case "show_all_cohort_events":
                return <i style={{paddingLeft: 5, color: "red"}}>All My Cohort Events</i>
            case "show_all_events":
                return <i style={{paddingLeft: 5, color: "red"}}>All Events</i>
                break;
        }

    }

    const handleEncounter = (dataItem) => {
        props.PatientActions.getPatientByIdAndSetContext(dataItem.Patient).then(() => {
            history.push(`/patientPanel`);
            props.WindowActions.setWindowState("PatientPanelTabComponent", {selectedTab: 6});
            let inputProps = {
                dictEncounterTypeId: dataItem?.MeetingType?.id
            }
            props.EncounterAction.setEncounterContext(inputProps);
            props.PopupActions.showWindow(PopupWindows.ENCOUNTER_WINDOW, inputProps);
        });
    }

    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_SCHEDULE]}>
                <TileContainer>
                    <TileHeader title="Schedule" expanded={Components.SchedulerComponent}
                                style={GlobalStyles.tileHeaderBlueWithBlack}/>
                    <TileBody>
                        <Row style={{width: "100%", height: 30}}>
                            <Col col={6} style={{padding: "0.3%"}}>
                                <FilterStatus/>
                            </Col>
                            <Col style={{padding: "0.3%"}} col={5}>
                                {filterByText &&
                                    <div className="flex align-items-center">
                                        <div className="flex flex-wrap gap-3">
                                            <div className="flex align-items-center">
                                                {filterByText}
                                            </div>
                                        </div>
                                    </div>}
                            </Col>
                            <Col>
                                <div style={{marginLeft: "50%"}} className="flex align-items-center">
                                    <div className="flex flex-wrap gap-3">
                                        <div className="flex align-items-center">
                                            <Menu style={{fontSize: 12}} model={ActionMenuItems} popup ref={menu}/>
                                            <Button text
                                                    tooltipOptions={{position: "top"}}
                                                    tooltip={"Filter events"}
                                                    onClick={(e) => menu.current.toggle(e)}
                                                // onClick={(e) => setShowFilters(true)}
                                                    style={{color: isFilterApplied ? "red" : "black"}}
                                                    icon={isFilterApplied === true ? "pi pi-filter-fill" : "pi pi-filter"}/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{margin: 0, padding: 0, width: "100%", height: "calc(100% - 30px)"}}>
                            <Col xs={12} style={{margin: 0, padding: 0, height: "100%", width: "100%"}}>

                                <Scheduler
                                    modelFields={customModelFields}
                                    data={data}
                                    height={"100%"}
                                    style={{width: "100%"}}
                                    editable={true}
                                    onDataChange={handleDataChange}
                                    onDateChange={handleDateChange}
                                    onViewChange={handleViewChange}

                                    // defaultDate={defaultDate || currentDate()}
                                    defaultView={defaultView || "agenda"}
                                    form={FormWithCustomEditor}>
                                    <AgendaView
                                        //task={CustomTask}
                                        editTask={CustomAgendaView}
                                    />
                                    <DayView/>
                                    <WeekView/>
                                    <MonthView/>
                                </Scheduler>
                            </Col>
                        </Row>
                    </TileBody>
                </TileContainer>
                <Dialog
                    visible={showFilters}
                    header={"Filters"}
                    footer={FilterFooterContent}
                    onHide={() => {
                        if (!isFilterApplied) {
                            props.scheduleActions.setSchedulerFilters("filterFacility", null)
                            props.scheduleActions.setSchedulerFilters("filterProvider", null)
                            props.scheduleActions.setSchedulerFilters("filterPatient", null)
                            props.scheduleActions.setSchedulerFilters("isClear", false);
                        }
                        setShowFilters(false)
                    }}>
                    <div className="flex align-items-center">
                        Facility
                    </div>
                    <div className="flex align-items-center">
                        <FacilityDropDownList
                            style={{width: "250px"}}
                            defaultValue={filterFacility}
                            onChange={(e) => props.scheduleActions.setSchedulerFilters("filterFacility", e.value)}
                            labelName={null}
                        />
                    </div>
                    <br></br>
                    <div className="flex align-items-center">
                        Participant
                    </div>
                    <div className="flex align-items-center">
                        <UserDropDownList
                            value={filterProvider}
                            onChange={(e) => props.scheduleActions.setSchedulerFilters("filterProvider", e.value)}
                            style={{width: "250px"}}/>
                    </div>
                    <br></br>
                    <div className="flex align-items-center">
                        Patient
                    </div>
                    <div className="flex align-items-center">
                        <PatientDropDownList
                            value={filterPatient}
                            onChange={(e) => props.scheduleActions.setSchedulerFilters("filterPatient", e.value)}
                            style={{width: "250px"}}/>
                    </div>
                </Dialog>
            </ShowForPermissionComponent>
        </React.Fragment>

    );
};


SchedulerComponent.propTypes =
    {
        scheduleActions: PropTypes.object,
        PatientActions: PropTypes.object,
    }

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        patients: state.patient.patientList.patients,
        scheduleData: state.schedule.scheduleData,
        schedulerFilters: state.schedule.schedulerFilters,
        windowState: state.windowState,
        tenant: state.Configuration.tenant.tenant,
        loggedInUser: state.user.loggedinUser,
        userTelUrl: state.user.loggedinUser?.telehealthAddress,
        userRoleId: state.auth?.session?.userRoleId,
        allUsers: state.user.allUsers,
        securitySettings: state.securitySettings.securitySettings,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        scheduleActions: bindActionCreators(ScheduleActions, dispatch),
        PatientActions: bindActionCreators(PatientAction, dispatch),
        OpenEmrActions: bindActionCreators(OpenEmrActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        WindowActions: bindActionCreators(WindowActions, dispatch),
        EncounterAction: bindActionCreators(EncounterAction, dispatch),
        userActions: bindActionCreators(UserActions, dispatch),
        SecuritySettingsActions: bindActionCreators(SecuritySettingsAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerComponent)
