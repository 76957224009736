import {url} from "../config/Url";
import {ParseResponse} from "../util/ParseResponse";
import {ActionTypes} from "../action/AuthAction";
import {push} from "connected-react-router";
import {TENANT_ID} from "../config/GlobalConfig";
import axios from "axios";

const ErrorCodes = {
    REFRESH_SESSION: "E-112",
    MFA_CRED: "E-187",
    NO_ACCESS: "E-189",
}

//https://demo.popdash.aerioshealth.com/patientPanel?ah_pid=49
function refreshToken() {
    return async dispatch => {
        // console.log("trying to refresh")
        let response = await fetch(url('session/refreshToken'), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({payload: {token: localStorage.getItem("refreshToken"), tenantId: TENANT_ID}})
        });
        if (response.ok) {
            let json = await ParseResponse(response);
            localStorage.setItem('token', json.sessionToken ? json.sessionToken.token : null)
            localStorage.setItem('refreshToken', json.refreshToken ? json.refreshToken.token : null)
            localStorage.setItem('userRoleId', json.userRoleId ? json.userRoleId : null)
            dispatch({type: ActionTypes.LOGIN_SUCCESS, payload: json});
        } else {
            try {
                let json = await ParseResponse(response);
                dispatch({type: ActionTypes.LOGIN_ERROR, payload: json});
            } catch (error) {
                // console.log.error(response)
                dispatch({type: ActionTypes.LOGIN_ERROR, payload: response});
            } finally {
                localStorage.setItem('token', null)
                localStorage.setItem('refreshToken', null)
                localStorage.setItem('userRoleId', null)
            }
        }
    }

}


export function WrapAuthentication(partialUrl, params, dispatchSuccess, otherParams = {}, updateComponents = []) {
    return async dispatch => {

        try {
            let response = await axios.post(url(partialUrl), params)
            if (response.status === 200) {
                dispatch({...otherParams, type: dispatchSuccess, payload: response.data})
                if (updateComponents.length > 0) {
                    updateComponents.forEach(u => {
                        console.log(`Sending Update: ${u}`)
                        dispatch({type: u})
                    })
                }
                return response.data;
            } else {
                dispatch({...otherParams, type: "AH_ERROR", payload: response.data})
                console.error(response.data)
                return response.data
            }

        } catch (e) {
            if (e?.response?.data)
                return e.response.data
            else
                return e.response
        }

    }
}

export function PaginatedEndpoint(partialUrl, params) {
    return async (offset, limit, sort, filter) => {
        try {
            let p = {offset, limit, sort, filter, payload: params}
            let response = await axios.post(url(partialUrl), p)
            if (response.status === 200) {

                return response.data;
            } else {
                console.error(response.data)
                return response.data
            }

        } catch (e) {
            if (e?.response?.data)
                return e.response.data
            else
                return e.response
        }

    }
}

export async function PostApi(partialUrl, params) {
    try {
        let response = await axios.post(url(partialUrl), params)
        if (response.status === 200) {
            return response.data;
        } else {
            return response.data
        }
    } catch (e) {

        if (e?.response?.data)
            return e.response.data
        else
            return e.response
    }

}

export function WrapAuthenticationForm(partialUrl, params, dispatchSuccess, dispatchError, otherParams = {}, updateComponents = []) {
    return async dispatch => {
        const fetchData = async () => fetch(url(partialUrl), {
            method: 'POST',
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem('token'),
                'TENANT-ID': TENANT_ID,
            },
            body: params
        });
        let response = await fetchData()
        if (response.ok) {
            // // console.log("Response OK")
            try {
                let json = await ParseResponse(response)
                dispatch({...otherParams, type: dispatchSuccess, payload: json}).then(() => {
                    if (updateComponents.length > 0) {
                        updateComponents.forEach(u => {
                            dispatch({type: u})
                        })
                    }
                })
                return json;
            } catch (e) {
                // console.log.error(response.statusText)
            }
        } else if (response.status === 400) {
            // console.log("400 Error - Authentication")
            let json = await ParseResponse(response)
            if (json.code === ErrorCodes.REFRESH_SESSION) {
                // console.log("Session expired trying refresh")
                const d = await refreshToken()(dispatch)
                const refreshFetchData = async () => fetch(url(partialUrl), {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'X-AUTH-TOKEN': localStorage.getItem('token'),
                        'TENANT-ID': TENANT_ID,
                    },
                    body: JSON.stringify(params)
                });
                let response = await refreshFetchData()
                if (response.ok) {
                    try {
                        let json = await response.json()
                        dispatch({...otherParams, type: dispatchSuccess, payload: json})
                    } catch (e) {
                        // console.log.error(response.statusText)
                    }
                } else {
                    // we have a failure somewhere have the user re-login
                    // console.log.error("Failure to get new session token. Relogin.")
                    dispatch(push('/'))
                    dispatch({type: "USER_LOGOUT"})

                }
            } else if (json.code === ErrorCodes.MFA_CRED) {
                throw new Error(json.message)
            } else if (json.code === ErrorCodes.NO_ACCESS) {
                try {
                    dispatch({...otherParams, type: dispatchError, payload: json})
                    return json
                } catch (e) {
                    // console.log.error("error", e)
                }
            }
        } else {
            // console.log("Other Error")
            let json = await ParseResponse(response)
            dispatch({type: "AH_ERROR", payload: json})
        }
    }
}
