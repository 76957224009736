import {Button} from "primereact/button";
import {WindowActionsBar} from "@progress/kendo-react-dialogs";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PopupWindowActions as PopupActions, PopupWindows} from "../../action/PopupWindowAction";
import {LaboratoryAction} from "../../action/LaboratoryAction";
import {Container} from "react-grid-system";
import {Fade} from "@progress/kendo-react-animation";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import AeriosLoadingIndicator from "../AeriosLoadingIndicator";
import {useForm} from "react-hook-form";
import {
    Field,
    FieldDictionarySearchList,
    FieldDropDown,
    FieldFacilityDropDown,
    FieldRow,
    FieldTextInput
} from "../InputControls/FormFields";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {Dialog} from "primereact/dialog";

const AddLabRouteWindow = (props) => {

    const dispatch = useDispatch();
    const showWindow = useSelector(state => state.popupWindow.showWindow,)
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [loading, setLoading] = useState(false)
    const hideDialog = () => {

        PopupActions.hideWindow(PopupWindows.ADD_LAB_ROUTE_WINDOW)(dispatch);

    };
    useEffect(() => {
        if (showWindow[PopupWindows.ADD_LAB_ROUTE_WINDOW])
            LaboratoryAction.getInstallRoutes().then((data) => {
                    dispatch(data)
                }
            )
    }, [showWindow]);

    const {
        register,
        handleSubmit,
        getValues,
        reset,
        control,
        watch,
        formState: {errors},
    } = useForm({defaultValues: {routeType: {text: "Tenant Level Route", id: "Tenant"}}});
    const routeType = watch("routeType", {})
    const Notify = () => {
        setSuccess(true)
        setTimeout(() => setSuccess(false), 5000)
    }

    async function createRoute(form) {
        setLoading(true)
        let payload = {
            dictLabVendorId: form.dictLabVendorId.id,
            accountNumber: form.accountNumber,
            routeType: form.routeType.id,
            facilityId: form.routeType.id === "Facility" ? form.facilityId.id : null
        }
        setLoading(false)

        let response = await LaboratoryAction.createRoute(payload)
        let data = response.data
        console.log(JSON.stringify(response, null, 4))
        if (data?.code) {
            setErrorMessage({message: data?.modelState ? data.modelState : "An unknown error has occurred."})
            setTimeout(() => setErrorMessage(null), 5000)
        } else {
            Notify()
            dispatch(await LaboratoryAction.getInstallRoutes())
            reset()
            hideDialog()
        }
    }


    return (
        <Dialog header={"Create Lab Route"} onHide={hideDialog}
                visible={showWindow[PopupWindows.ADD_LAB_ROUTE_WINDOW]}
                style={{width: 600}}>
            <AeriosLoadingIndicator loading={loading} message={"Creating Route. This may take some time..."}>
                <Container fluid style={{width: "100%", padding: 20, height: "100%"}}>
                    <NotificationGroup style={{
                        top: 0,
                        left: "50%",
                        transform: "translateX(-50%)",
                    }}>
                        <Fade>
                            {success && (
                                <Notification
                                    type={{
                                        style: "success",
                                        icon: true,
                                    }}
                                    closable={true}
                                    onClose={() => setSuccess(false)}
                                >
                                    <span>Lab Route has been created.</span>
                                </Notification>
                            )}
                        </Fade>
                        <Fade>
                            {errorMessage?.message && (
                                <Notification
                                    type={{
                                        style: "error",
                                        icon: true,
                                    }}
                                    closable={true}
                                    onClose={() => setErrorMessage(null)}
                                >
                                    <span>{errorMessage?.message}</span>
                                </Notification>
                            )}
                        </Fade>
                    </NotificationGroup>
                    <FieldRow>
                        <Field col={12} label={"Laboratory"} field={"dictLabVendorId"} error={errors}
                               register={register}
                               control={control}
                               options={{required: "Laboratory is required."}}>
                            <FieldDictionarySearchList
                                code={Dictionary.AH_LAB_VENDOR}
                            />
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field col={12} label={"Account Number"} field={"accountNumber"} error={errors}
                               register={register}
                               control={control}
                               options={{required: "Account Number is required."}}>
                            <FieldTextInput/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field col={12} label={"Route Type"} field={"routeType"} error={errors}
                               register={register}
                               control={control}
                               options={{required: "Route Type is required."}}>
                            <FieldDropDown textField="text"
                                           dataItemKey="id"

                                           data={[
                                               {text: "Tenant Level Route", id: "Tenant"},
                                               {text: "Facility Level Route", id: "Facility"},
                                               {text: "User Level Route", id: "User"}]}/>
                        </Field>
                    </FieldRow>

                    {(routeType.id === "Facility") &&
                        <FieldRow>
                            <Field col={12} label={"Facility"} field={"facilityId"} error={errors}
                                   register={register}
                                   control={control}
                                   options={{required: "Facility Level route requires a facility to be selected"}}>
                                <FieldFacilityDropDown/>
                            </Field>
                        </FieldRow>}

                </Container>
            </AeriosLoadingIndicator>

            <WindowActionsBar>
                <Button themeColor={"primary"} onClick={handleSubmit(createRoute)}>
                    Create
                </Button>
            </WindowActionsBar>
        </Dialog>)
};


export default AddLabRouteWindow;
