import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {Dialog} from "primereact/dialog";

const PdfViewerWindow = (props) => {
    const dispatch = useDispatch()
    const popupState = useSelector(state => state.popupWindow.state[PopupWindows.PDF_VIEWER_WINDOW])
    const showWindow = useSelector(state => state.popupWindow.showWindow[PopupWindows.PDF_VIEWER_WINDOW])
    const [document, setDocument] = React.useState([])

    const hideDialog = async () => {
        await PopupWindowActions.hideWindow(PopupWindows.PDF_VIEWER_WINDOW)(dispatch)
    };

    return (
        <Dialog
            visible={showWindow}
            header={`Document Viewer ${popupState?.fileName}`}
            onHide={hideDialog}
            maximizable
            style={{height: 800, width: 1000}}
            component={true}>
            <DocViewer documents={document} pluginRenderers={DocViewerRenderers}/>
        </Dialog>
    )
}

export default PdfViewerWindow;