import {Loader} from "@progress/kendo-react-indicators";
import {TileRow} from "../layout/TileRow";
import {Col} from "../layout/GridLayout";


export default function AeriosLoadingIndicator(props) {
    const {loading, children, data, message} = props
    if (loading) {
        return (<TileRow style={{width: "100%", display: "flex", alignContent: "center", alignItems: "center"}}>
            <Col sm={12} style={{color: "gray", textAlign: "center", marginTop: 10, fontSize: "20px"}}>
                <h5>{message}</h5>
                <Loader style={{paddingTop: 5}} type="converging-spinner"/>
            </Col>
        </TileRow>)
    } else return (<>{children}</>)
}

export function AeriosNoDataLoadingIndicator(props) {
    const {loading, children, data, message} = props
    let msg = message || "No data to display."
    if (loading)
        return <AeriosLoadingIndicator {...props}/>
    else if (!loading && data.length === 0)
        return (<TileRow style={{width: "100%", display: "flex", alignContent: "center", alignItems: "center"}}>
            <Col sm={12}
                 style={{
                     color: "green",
                     padding: 0,
                     textAlign: "center",
                     marginTop: 10,
                     fontSize: "18px"
                 }}><i>{msg}</i></Col>
        </TileRow>)
    else
        return <AeriosLoadingIndicator {...props}/>


}