import * as React from "react";
import {useEffect, useState} from "react";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "primereact/button";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import _ from "lodash";
import {UserActions} from "../../action/UserAction";
import moment from 'moment';
import {
    Field,
    FieldCheckboxInput,
    FieldDictionaryDropDown,
    FieldRow,
    FieldSection,
    FieldTextInput
} from "../InputControls/FormFields";
import {VERADIGM_CONFIG} from "../../config/GlobalConfig";
import {Col, Row} from "react-grid-system";
import {Dialog} from "primereact/dialog";
import {useToast} from "../../providers/toast/ToastProvider";

const EditUserEPrescribeWindow = (props) => {
    const dispatch = useDispatch()
    const [hasDea, setHasDea] = useState(false)
    const [prescriber, setPrescriber] = useState(false)
    const [licenseData, setLicenseData] = React.useState([]);

    const showWindow = useSelector(state => state.popupWindow.showWindow)
    const userContext = useSelector(state => state.user.userContext)
    const createNewUser = useSelector(state => state.user.createNewUser)
    const userLicense = useSelector(state => state.user.userLicenseContext)
    const show = useSelector(state => state.popupWindow.showWindow?.[PopupWindows.CREATE_EPRESCRIBE_USER_WINDOW])
    const {addToast} = useToast()
    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: {errors,},
    } = useForm({
        defaultValues: {
            isAdmin: false,
            schedule2: false,
            schedule3: false,
            schedule4: false,
            schedule5: false,
        }
    });


    useEffect(() => {
        userContext?.userRoleId && UserActions.getLicensesForUser(userContext?.userRoleId)
    }, [])
    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'deaLicenseNumber' && value?.deaLicenseNumber && !_.isEmpty(value.deaLicenseNumber) && value.deaLicenseNumber !== "") {
                setHasDea(true)
            } else if (name === 'deaLicenseNumber' && !_.isEmpty(value?.deaLicenseNumber)) setHasDea(false)
            if (name === "dictPrescriberRole") {
                if (["PASUPERVISED", "PROVIDER", "PA"].includes(value?.dictPrescriberRole?.code)) {
                    setPrescriber(true)
                } else {
                    setPrescriber(false)

                }
            }

        })
        return () => subscription.unsubscribe();
    }, [watch]);

    React.useEffect(() => {
        if (userContext && showWindow[PopupWindows.CREATE_EPRESCRIBE_USER_WINDOW]) {
            setValue("licenseId", VERADIGM_CONFIG.LICENSE_ID)
            setValue("partnerId", VERADIGM_CONFIG.PARTNER_ID)
            setValue("npi", userContext?.npi)
            setValue("firstName", userContext?.firstName)
            setValue("lastName", userContext?.lastName)
            setValue("state", userContext?.state)
            setValue("expirationDate", userContext?.expirationDate)
            setValue("middleName", userContext?.middleName)
            setValue("specialtyCode", userContext?.primaryUserRoleSpecialtyCodeId)
            setValue("email", userContext?.email)


            const deaLicense = userLicense?.length ? userLicense.filter((e) => e?.dictLicenseTypeCode === "DEA") : userContext?.licenseDetails?.filter((e) => e?.dictLicenseTypeCode === "AH_LICENSE_DEA");
            const stateLicense = userLicense?.length ? userLicense.filter((e) => e?.dictLicenseTypeCode === "StateLicense") : userContext?.licenseDetails?.filter((e) => e?.dictLicenseTypeCode === "AH_LICENSE_STATE");

            if (deaLicense) {
                if (deaLicense.length > 1) {
                    let filter = deaLicense?.sort(function (left, right) {
                        return moment.utc(right.startDate).diff(moment.utc(left.startDate))
                    });
                    setValue("deaLicenseNumber", filter[0]?.licenseNumber);
                    setValue("deaState", filter[0]?.dictStateCode);
                    setValue("deaExpirationDate", filter[0]?.endDate);
                    setValue("licenseType", filter[0]?.dictLicenseTypeCode);
                    setLicenseData(filter[0]);
                } else {
                    setValue("deaLicenseNumber", deaLicense[0]?.licenseNumber)
                    setValue("deaState", deaLicense[0]?.dictStateCode)
                    setValue("deaExpirationDate", deaLicense[0]?.endDate)
                    setValue("licenseType", deaLicense[0]?.dictLicenseTypeCode)
                    setLicenseData(deaLicense[0]);
                }
            }
            if (stateLicense) {
                if (stateLicense.length > 1) {
                    let filter = stateLicense?.sort(function (left, right) {
                        return moment.utc(right.startDate).diff(moment.utc(left.startDate))
                    });
                    setValue("licenseNumber", filter[0]?.licenseNumber)
                    setValue("state", filter[0]?.dictStateCode)
                    setValue("expirationDate", filter[0]?.endDate)
                    setValue("licenseType", filter[0]?.dictLicenseTypeCode);
                    setLicenseData(filter[0]);
                } else {
                    setValue("licenseNumber", stateLicense[0]?.licenseNumber)
                    setValue("state", stateLicense[0]?.dictStateCode)
                    setValue("expirationDate", stateLicense[0]?.endDate)
                    setValue("licenseType", stateLicense[0]?.dictLicenseTypeCode)
                    setLicenseData(stateLicense[0]);
                }
            }

        }
    }, [userContext, showWindow])

    const hideDialog = () => {
        PopupWindowActions.hideWindow(PopupWindows.CREATE_EPRESCRIBE_USER_WINDOW)(dispatch)
        reset({
            licenseId: null,
            partnerId: null,
            npi: null,
            firstName: null,
            lastName: null,
            state: null,
            expirationDate: null,
            middleName: null,
            specialtyCode: null,
            email: null,
            licenseNumber: null,
            licenseData: null
        });
    };

    const createUser = (payload) => {
        UserActions.createNewUser(payload)(dispatch).then(r => {
            if (r?.payload) {
                addToast({severity: "success", summary: "ePrescribe User has been created"})
                hideDialog()
            } else {
                addToast({severity: "error", detail: r, summary: "Failed to create ePrescribe User"})

            }
        })
    }

    const saveUser = (form) => {

        let payload = {
            userRoleId: userContext?.userRoleId,
            isAdmin: form.isAdmin,
            userType: form.dictPrescriberRole.code,
            drugSchedules: {
                schedule2: form.schedule2 || false,
                schedule3: form.schedule3 || false,
                schedule4: form.schedule4 || false,
                schedule5: form.schedule5 || false,
            }
        }
        // console.log(payload)
        createUser(payload)
        // hideDialog();
    }


    return (
        <Dialog
            visible={show}
            header={"Create ePrescribe User"}
            maximizable
            style={{width: "60vw", height: "60vh"}}

            onHide={hideDialog}
            footer={<div className={"w-full flex flex-row-reverse"}>
                <Button severity={"danger"} onClick={hideDialog}>
                    Cancel
                </Button>
                <Button severity={"success"} onClick={handleSubmit(saveUser)}>
                    Save
                </Button>
            </div>}
        >

            <div style={{"font-weight": "bold", "padding-bottom": "25px"}}> Please verify information below. If
                incorrect, please cancel and correct information in user profile
            </div>
            <FieldSection>
                <FieldRow>
                    <Field className={"col-4"} label={"First Name"} field={"firstName"} error={errors}
                           register={register} disabled={true}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>
                    <Field className={"col-2"} label={"M.I."} field={"middleName"} error={errors}
                           register={register} disabled={true}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>
                    <Field className={"col-6"} label={"Last Name"} field={"lastName"} error={errors}
                           register={register} disabled={true}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>
                </FieldRow>
                <FieldRow>
                    <Field col={6} label={"Provider NPI"} field={"npi"}
                           options={{
                               validate:
                                   {
                                       required: value => {
                                           if (!value && prescriber) return "Provider NPI is required for this user role."
                                           return true
                                       }
                                   }
                           }}
                           error={errors} register={register}
                           disabled={true}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>
                    <Field col={6} label={"Provider Email"} field={"email"}
                           options={{required: "Email is required to enroll."}}
                           error={errors} register={register}
                           disabled={true}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>

                </FieldRow>

                <FieldRow>
                    <Field label={"State License No."} field={"licenseNumber"} error={errors}
                           options={{
                               validate:
                                   {
                                       required: value => {
                                           if (!value && prescriber) return "State License is required for this user role."
                                           return true
                                       }
                                   }
                           }}
                           register={register} disabled={true}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>
                    <Field col={4} label={"Expiration Date"} field={"expirationDate"} error={errors}
                           register={register} disabled={true}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>
                    <Field col={4} label={"State"} field={"state"} error={errors} register={register}
                           disabled={true}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>
                </FieldRow>


                <FieldRow>
                    <Field label={"DEA License"} field={"deaLicenseNumber"} error={errors}
                           register={register} disabled={true}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>
                    <Field col={4} label={"Expiration Date"} field={"deaExpirationDate"} error={errors}
                           register={register} disabled={true}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>
                    <Field col={4} label={"State"} field={"deaState"} error={errors} register={register}
                           disabled={true}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>
                </FieldRow>

                <FieldRow>

                </FieldRow>


                <FieldRow>

                    <Field col={6} label={"Select role"} field={"dictPrescriberRole"} error={errors}
                           control={control}
                           options={{required: "ePrescribe Role is required."}}
                           register={register}>
                        <FieldDictionaryDropDown code={Dictionary.AH_EPRESCRIBE_ROLES}
                                                 setDefaultValue={{displayName: "Select Prescriber Role"}}
                                                 dataItem={userContext}
                        />
                    </Field>


                    <Field className={"col-6"} field={"isAdmin"} error={errors}
                           control={control}
                           register={register} disabled={false}>
                        <FieldCheckboxInput label={"Administrator"}/>
                    </Field>
                </FieldRow>


            </FieldSection>
            {hasDea && prescriber &&
                <FieldSection>
                    <Row><Col><b>Controlled Substances</b></Col></Row>
                    <FieldRow>
                        <Field className={"col-6"} field={"schedule2"} error={errors}
                               control={control}
                               register={register} disabled={false}>
                            <FieldCheckboxInput label={"Schedule 2"}/>
                        </Field>
                        <Field className={"col-6"} field={"schedule3"} error={errors}
                               control={control}
                               register={register} disabled={false}>
                            <FieldCheckboxInput label={"Schedule 3"}/>
                        </Field>
                        <Field className={"col-6"} field={"schedule4"} error={errors}
                               control={control}
                               register={register} disabled={false}>
                            <FieldCheckboxInput label={"Schedule 4"}/>
                        </Field>
                        <Field className={"col-6"} field={"schedule5"} error={errors}
                               control={control}
                               register={register} disabled={false}>
                            <FieldCheckboxInput label={"Schedule 5"}/>
                        </Field>

                    </FieldRow>
                </FieldSection>}


        </Dialog>

    )
}


export default EditUserEPrescribeWindow
