import {Redirect, Route, useLocation} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {AuthAction} from "../action/AuthAction";
import {connect} from "react-redux";
import queryString from 'query-string'

const ProtectedRoute = (props) => {
    const {authenticated, children, ...rest} = props

    const {search, pathname} = useLocation()

    let parsed = {...queryString.parse(search), redirect: pathname}

    const params = queryString.stringify(parsed);

    return authenticated ? <Route {...props}/>: <Redirect to={`/login/?${params}`}/>;

};


function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated
    };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)