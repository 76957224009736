import {DateTime, Settings} from "luxon";

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const DATE_TIME_FORMAT = "MM/dd/yyyy hh:mm"
export const SIMPLE_DATE_FORMAT = "yyyy-MM-dd"
Settings.defaultZone = tz

export const adjustForTimezone = (date) => {
    let timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date
}


export function OffsetUTCDateStringToJsDate(dateString) {
    try {
        let d = DateTime.fromISO(dateString, {zone: "utc", setZone: true, keepLocalTime: true}).toJSDate()
        return d
    } catch (e) {
        console.error(e)
        return null
    }
}

export function ParseStringToJsDateWithFormat(dateString, format) {
    try {
        let d = DateTime.fromFormat(dateString, format ? format : "yyyy-MM-dd").toUTC().toJSDate()
        return d
    } catch (e) {
        console.error(e)
        return null
    }
}


export function JsDateToUtcIso(jsDate) {
    try {
        let d = DateTime.fromJSDate(jsDate).toUTC().toISO()

        return d
    } catch (e) {
        console.error(e)
        return null
    }
}

export function JsDateToStringFormat(jsDate, format) {
    try {
        let d = DateTime.fromJSDate(jsDate).toFormat(format)
        return d
    } catch (e) {
        console.error(e)
        return null
    }
}

export function FormatUtcDateTimeToLocalZoneStringWithFormat(dateString, format = DATE_TIME_FORMAT) {
    try {
        let d = DateTime.fromISO(dateString, {
            zone: "utc",
            setZone: true,
            keepLocalTime: true
        }).toLocal().toFormat(format)
        return d
    } catch (e) {
        console.error(e)
        return null
    }
}

export function FormatUtcDateToLocalZoneStringWithFormat(dateString, format = DATE_TIME_FORMAT) {
    try {
        let d = DateTime.fromISO(dateString, {
            zone: "utc",
            setZone: true,
            keepLocalTime: true
        }).toFormat(format)
        return d
    } catch (e) {
        console.error(e)
        return null
    }

}

export function DateTimeGridCell(props) {
    let dateFormat = FormatUtcDateTimeToLocalZoneStringWithFormat(props.dataItem?.[props.field])
    if (dateFormat !== "Invalid DateTime")
        return <td>{dateFormat}</td>
    else
        return <td></td>
}


export function DateTimeGridCellWithFormat(format) {
    return (props) => {
        let dateFormat = FormatUtcDateTimeToLocalZoneStringWithFormat(props.dataItem?.[props.field], format)
        if (dateFormat !== "Invalid DateTime")
            return <td>{dateFormat}</td>
        else
            return <td></td>
    }
}

export function DateGridCellWithFormat(format) {
    return (props) => {
        let dateFormat = FormatUtcDateToLocalZoneStringWithFormat(props.dataItem?.[props.field], format)
        if (dateFormat !== "Invalid DateTime")
            return <td>{dateFormat}</td>
        else
            return <td></td>
    }
}

export function NewDateGridCellWithFormat(format) {
    return (props) => {
        let dateFormat = FormatUtcDateToLocalZoneStringWithFormat(props.row?.[props.field], format)
        if (dateFormat !== "Invalid DateTime")
            return dateFormat
        else
            return null
    }
}

export function UtcNowIsoString() {
    return DateTime.now().toUTC().toISO()
}