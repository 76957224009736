export default {
    drawerContent: {
        // height: "100%",
        width: "100%",
        // scrollBehavior: "smooth",
        // overflowAnchor: "none",
    },
    blah: {
        height: "35px",
        margin: '0',
        width: "100%",
        padding: 0,
        background: '#DDDDDD',
        fontSize: "20px",
        fontWeight: '600',
        color: "#085394"
    },
    tileHeader: {
        background: "#DDDDDD",
        fontSize: "16px",
        fontWeight: '600',
        color: "#085394",
        height: "35px",
        width: "100%",
        margin: "0",
        padding: 0,
        top: '0',
        textTransform: "uppercase",
        lineHeight: "30px",
    },
    tileHeaderBlueWithBlack: {
        background: "rgb(116, 132, 194)",
        color: "black",
        height: "35px",
        width: "100%",
    },
    tileWindow: {
        // boxShadow: "2px 2px 8px #888888",
        display: "flex",
        height: "100%",
        flexGrow: 1,
        border: '1px solid #ece7e7',
        // overflowY: "auto",
        // width: 0,
        borderRadius: '5px',
        // height: "calc(100% - 35px)",
    },
    tileContainer: {
        width: "100%",
        padding: 0,
        margin: 0,
        height: "calc(100% - 35px)",
    },

    tileWrapper: {
        width: "100%",
        display: "flex",
        //position: 'relative',
        marginTop: "40px",
        height: "calc(100% - 35px)",
        overflowY: "auto",

    },
    titleWrapperGrayBg: {
        width: "100%",
        backgroundColor: '#EEEEEE',
        height: "calc(100%)",
        overflowY: "auto",
        overflowX: "hidden",
        padding: 0,
        margin: 0,
    },
    updatedTitleWrapperGrayBg: {
        width: "100%",
        backgroundColor: '#EEEEEE',
        height: "calc(100%)",
        overflowY: "auto",
        overflowX: "hidden",
        padding: 0,
        margin: 0,
        border: '4px solid rgb(221, 221, 221)'
    },
    panelContainer: {
        width: "100%",
        // height: "calc(100% - 32px)",
    },
    panelRowFillHeight: {
        padding: 0,
        height: "calc(100% - 32px)"
    },
    panelCol: {
        padding: 0,
        height: "100%",

    },

    newHeader: {
        color: '#7585C2',
        backgroundColor: '#FAFAFA',
        position: 'static',
        fontSize: "15px",
        height: "35px"
    },
    newHeader2: {
        background: ' rgb(116, 132, 194)',
        color: 'rgb(255, 255, 255)',
        height: '35px',
        width: '100%',
        margin: ' 0px',
        padding: '3px 15px 2px',
        fontSize: '15px',
        top: '0px',
        textTransform: 'uppercase',
        fontWeight: '700',
        lineHeight: '30px',
    },
    newHeaderFont: {
        fontSize: "15px",
    },
    headerBackForwardButtons: {
        background: "none",
        color: "black",
        border: "white",
        height: "25px",
        // position: "relative",
        left: "14px",
        top: "0px",
    },

    newHeader2ActivityLevelComp: {
        background: ' rgb(116, 132, 194)',
        color: 'rgb(255, 255, 255)',
        height: '35px',
        width: '100%',
        margin: ' 0px',
        padding: '3px 15px 2px',
        fontSize: '15px',
        Zindex: '1',
        top: '0px',
        textTransform: 'uppercase',
        fontWeight: '700',
        lineHeight: '30px',
        borderBottom: 'solid',
    },
    tabContainer: {
        width: "100%",
        background: "#BAC1E0",
        padding: "5px",
        textAlign: "center",
        color: "#FFF"
    },
    tabContainerSelected: {
        width: "100%",
        background: "#7280c0",
        padding: "5px",
        textAlign: "center",
        color: "#FFF"
    },
    newTitleWrapperSleepQualityPie: {
        width: "100%",
        backgroundColor: '#EEEEEE',
        height: "calc(100% - 1px)",
        overflowY: "auto",
        overflowX: "hidden"
    },
    nextNoWrapEllipsis: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    }
}