export const ActionTypes = {
    COMPONENT_START_LOADING: "COMPONENT_START_LOADING",
    COMPONENT_DONE_LOADING: "COMPONENT_DONE_LOADING",
}

function setComponentLoading(component) {
    return async dispatch =>
        dispatch({type: ActionTypes.COMPONENT_START_LOADING, component})
}

function setComponentDoneLoading(component) {
    return async dispatch =>
        dispatch({type: ActionTypes.COMPONENT_DONE_LOADING, component})
}

export const LoadingActions = {
    setComponentLoading: setComponentLoading,
    setComponentDoneLoading: setComponentDoneLoading
}