import React from 'react';
import moment from "moment"
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {PatientAction} from "../../action/PatientAction";
import TileHeader from '../../layout/TileHeader';
import "hammerjs";
import {PhixPermissions, ObservationThreshold} from "../../config/GlobalConfig";
import {Col, Row} from 'react-grid-system';
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {ObservationsThresholdAction} from "../../action/ObservationsThresholdAction";

const categories = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const crosshair = {
    visible: true,
    tooltip: {
        visible: true,
        format: "#.##",
    },
};
const valuePlotBands = [
    {
        from: 18.5,
        to: 24.9,
        color: "#B6D7A8",
        opacity: 0.8,
    },
];
const [initCurrentWeek] = [[]];
const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const HeartRatePatientPanelComponent = (props) => {
    const {PatientActions, PatientContext} = props

    const [heartRate, setHearRateData] = React.useState({});
    const [heartRateCurDay, setHeartRateCurDay] = React.useState({});
    const [restingHeartRate, setRestingHeartRate] = React.useState({});
    const [walkingHeartRate, setwalkingHeartRate] = React.useState({});
    const [observationData, setObservationData] = React.useState({
                        Low:{},
                        High:{},
                        Normal:{},
    });

    React.useEffect(() => {
        if (PatientContext?.patientRoleId) {
            let patientRoleId = PatientContext.patientRoleId;
            props.PatientActions.getLatestObservation(patientRoleId, "AH_HEART_RATE", "HeartRatePatientPanelComponent");
            const todayDate = moment().startOf('day').format('YYYY-MM-DD');
            props.PatientActions.getPatientObservationsByPeriodForHeartRate(patientRoleId, todayDate, todayDate, ["AH_HEART_RATE"], "HeartRatePatientPanelComponent")
            const startDate = moment().startOf('week').format('YYYY-MM-DD');
            const endDate = moment().endOf('week').format('YYYY-MM-DD');
            props.PatientActions.getPatientObservationsByPeriodForHeartRate(patientRoleId, startDate, endDate, ["AH_RESTING_HR", "AH_WALKING_HEART_RATE"], "HeartRatePatientPanelComponentRestingAndWalking")
            PatientActions.getPatientObservationsByPeriod(PatientContext.patientRoleId,
                startDate,
                endDate,
                ["AH_RESTING_HR"],
                "HeartRateManagementData")
        }
        props.DictionaryActions.getDictionaryByCode("AH_OBSERVATION_TYPE");
    }, [])

    React.useEffect(() => {
         
         if(props?.dictionaries?.AH_OBSERVATION_TYPE){
            const observationType = props.dictionaries.AH_OBSERVATION_TYPE;
            observationType.forEach(item =>{
                if(item.code=="AH_RESTING_HR"){
                    props.ObservationsThresholdActions.getObservationThresholdsById(item.id);
                }
            });
         }
    }, [props.dictionaries]);

    React.useEffect(() => {
        if(props?.observationThresholdContext){
            const compObservation = props.observationThresholdContext;
            Object.values(compObservation).forEach(val => {
                for(let i = 0; i < val.length; i++){
                    if(val[i].description=="Low"){
                        observationData.Low.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                        observationData.Low.value = val[i]?.value
                        observationData.Low.rangeText = val[i]?.rangeText
                        observationData.Low.rangeColor = val[i]?.rangeColor
                    } else if(val[i].description=="High"){
                        observationData.High.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                        observationData.High.value = val[i]?.value
                        observationData.High.rangeText = val[i]?.rangeText
                        observationData.High.rangeColor = val[i]?.rangeColor
                    } else if(val[i].description=="Normal Range"){
                        observationData.Normal.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                        observationData.Normal.value = val[i]?.value
                        observationData.Normal.rangeText = val[i]?.rangeText
                        observationData.Normal.rangeColor = val[i]?.rangeColor
                    }
                }
            });
        }
    }, [props?.observationThresholdContext]);


    React.useEffect(() => {

        if (props?.getLatestObservation) {
            let hrdata = props.getLatestObservation;
            let heartRateVal = Math.round(hrdata.value);
            setHearRateData({value: heartRateVal, sourceName: hrdata.sourceName, startDate: hrdata.effectiveStartDate});
        }

        let countRHR = 0;
        let totalRHR = 0;
        let countWHR = 0;
        let totalWHR = 0;
        if (props?.getPatientObservationsByPeriodForRestingAndWalkingHeartRate) {
            let latestRWHR = props.getPatientObservationsByPeriodForRestingAndWalkingHeartRate;
            for (let i = 0; i < latestRWHR.length; i++) {
                if (latestRWHR[i].dictObservationTypeCode == "AH_RESTING_HR" && latestRWHR[i].value != "") {
                    countRHR = countRHR + 1;
                    totalRHR = totalRHR + parseInt(latestRWHR[i].value);
                } else if (latestRWHR[i].dictObservationTypeCode == "AH_WALKING_HEART_RATE" && latestRWHR[i].value != "") {
                    countWHR = countWHR + 1;
                    totalWHR = totalWHR + parseInt(latestRWHR[i].value);
                }
            }
            let averageRHRNum = totalRHR / countRHR;
            let averageRHR = Math.round(averageRHRNum);

            let colorRHR = ObservationThreshold.color;
            let textRHR = "";
            /*if (averageRHR >= 100) {
                colorRHR = "#E06666";
                textRHR = "";
            } else if (averageRHR > 60 && averageRHR <= 100) {
                colorRHR = "#93C47D";
                textRHR = "Below provider target but above critical range.";
            } else if (averageRHR <= 60) {
                colorRHR = "#8E7CC3";
                textRHR = "";
            }*/
            if(observationData?.Normal && observationData.Normal?.value?.value){
                if(observationData.Normal.dictOperatorDisplayName==">=" && averageRHR >= observationData.Normal.value.value) colorRHR = observationData.Normal.rangeColor? observationData.Normal.rangeColor:ObservationThreshold.color;
                else if(observationData.Normal.dictOperatorDisplayName=="<=" && averageRHR <= observationData.Normal.value.value) colorRHR = observationData.Normal.rangeColor? observationData.Normal.rangeColor:ObservationThreshold.color;
                else if(observationData.Normal.dictOperatorDisplayName==">" && averageRHR > observationData.Normal.value.value) colorRHR = observationData.Normal.rangeColor? observationData.Normal.rangeColor:ObservationThreshold.color;
                else if(observationData.Normal.dictOperatorDisplayName=="<" && averageRHR < observationData.Normal.value.value) colorRHR = observationData.Normal.rangeColor? observationData.Normal.rangeColor:ObservationThreshold.color;
                else if(observationData.Normal.dictOperatorDisplayName=="==" && averageRHR == observationData.Normal.value.value) colorRHR = observationData.Normal.rangeColor? observationData.Normal.rangeColor:ObservationThreshold.color;
            } else if(observationData?.Normal && observationData.Normal?.value?.min && observationData.Normal?.value?.max){
                if(averageRHR >= observationData.Normal.value.min && averageRHR <= observationData.Normal.value.max){
                    colorRHR = observationData.Normal.rangeColor? observationData.Normal.rangeColor:ObservationThreshold.color;
                }
            }

            if(observationData?.Low && observationData.Low?.value?.value){
                if(observationData.Low.dictOperatorDisplayName==">=" && averageRHR >= observationData.Low.value.value) colorRHR = observationData.Low.rangeColor? observationData.Low.rangeColor:ObservationThreshold.color;
                else if(observationData.Low.dictOperatorDisplayName=="<=" && averageRHR <= observationData.Low.value.value) colorRHR = observationData.Low.rangeColor? observationData.Low.rangeColor:ObservationThreshold.color;
                else if(observationData.Low.dictOperatorDisplayName==">" && averageRHR > observationData.Low.value.value) colorRHR = observationData.Low.rangeColor? observationData.Low.rangeColor:ObservationThreshold.color;
                else if(observationData.Low.dictOperatorDisplayName=="<" && averageRHR < observationData.Low.value.value) colorRHR = observationData.Low.rangeColor? observationData.Low.rangeColor:ObservationThreshold.color;
                else if(observationData.Low.dictOperatorDisplayName=="==" && averageRHR == observationData.Low.value.value) colorRHR = observationData.Low.rangeColor? observationData.Low.rangeColor:ObservationThreshold.color;
            } else if(observationData?.Low && observationData.Low?.value?.min && observationData.Low?.value?.max){
                if(averageRHR >= observationData.Low.value.min && averageRHR <= observationData.Low.value.max){
                    colorRHR = observationData.Low.rangeColor? observationData.Low.rangeColor:ObservationThreshold.color;
                }
            }

            if(observationData?.High && observationData.High?.value?.value){
                if(observationData.High.dictOperatorDisplayName==">=" && averageRHR >= observationData.High.value.value) colorRHR = observationData.High.rangeColor? observationData.High.rangeColor:ObservationThreshold.color;
                else if(observationData.High.dictOperatorDisplayName=="<=" && averageRHR <= observationData.High.value.value) colorRHR = observationData.High.rangeColor? observationData.High.rangeColor:ObservationThreshold.color;
                else if(observationData.High.dictOperatorDisplayName==">" && averageRHR > observationData.High.value.value) colorRHR = observationData.High.rangeColor? observationData.High.rangeColor:ObservationThreshold.color;
                else if(observationData.High.dictOperatorDisplayName=="<" && averageRHR < observationData.High.value.value) colorRHR = observationData.High.rangeColor? observationData.High.rangeColor:ObservationThreshold.color;
                else if(observationData.High.dictOperatorDisplayName=="==" && averageRHR == observationData.High.value.value) colorRHR = observationData.High.rangeColor? observationData.High.rangeColor:ObservationThreshold.color;
            } else if(observationData?.High && observationData.High?.value?.min && observationData.High?.value?.max){
                if(averageRHR >= observationData.High.value.min && averageRHR <= observationData.High.value.max){
                    colorRHR = observationData.High.rangeColor? observationData.High.rangeColor:ObservationThreshold.color;
                }
            }
            setRestingHeartRate({averageRHR: averageRHR, color: colorRHR, text: textRHR});

            let averageWHRNum = totalWHR / countWHR;
            let averageWHR = Math.round(averageWHRNum);
            let colorWHR = "";
            let textWHR = "";
            if (averageWHR >= 100) {
                colorWHR = "#085394";
                textWHR = "";
            } else if (averageWHR > 60 && averageWHR <= 100) {
                colorWHR = "#085394";
                textWHR = "Below provider target but above critical range.";
            } else if (averageWHR <= 60) {
                colorWHR = "#085394";
                textWHR = "";
            }
            setwalkingHeartRate({averageWHR: averageWHR, color: colorWHR, text: textWHR});
        }

        if (props?.getPatientObservationsByPeriodForHeartRate && props?.getPatientObservationsByPeriodForHeartRate?.length > 0) {
            //setHeartRateCurDay({value: props?.getPatientObservationsByPeriodForHeartRate.value});
            let latestHeartRateCurDayArray = [];
            let latestHeartRateCurDay = props.getPatientObservationsByPeriodForHeartRate;
            for (let i = 0; i < latestHeartRateCurDay.length; i++) {
                let intvalHearrate = parseInt(latestHeartRateCurDay[i].value)
                latestHeartRateCurDayArray.push(intvalHearrate);
            }
            let minHeartRateCurDay = Math.min.apply(null, latestHeartRateCurDayArray);
            let maxHeartRateCurDay = Math.max.apply(null, latestHeartRateCurDayArray);
            setHeartRateCurDay({mincurrentDay: minHeartRateCurDay, maxcurrentDay: maxHeartRateCurDay});
        }
    }, [props?.getLatestObservation, props?.getPatientObservationsByPeriodForHeartRate, props?.getPatientObservationsByPeriodForRestingAndWalkingHeartRate])


    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                <TileContainer>
                    <TileHeader className="boxHeader ah-draggable-header"
                                title={"Heart Rate"} image={"../images/hospitalHeart.png"}/>
                    <TileBody>
                        <Row style={{margin: '0', height: "100%"}} onClick={() => {
                            PatientActions.setSavePatientContext({...PatientContext, selectedValue: 'week'});
                            PatientActions.setHearRateDataForBar(props.HeartRateManagementData);
                            props.PopupActions.showWindow(PopupWindows.HEART_RATE_MANAGEMENT_BAR_WINDOW);
                        }}>
                            <Col style={{borderRight: "solid 2px #DDDDDD", margin: '10px', padding: '0px 0px'}}>
                        <span style={{
                            width: '100%',
                            float: "left",
                            color: "#666666",
                            fontSize: '12px',
                            fontWeight: '600'
                        }}>{heartRate?.startDate ? moment(heartRate?.startDate).format('MM/DD/YYYY') : ''}</span>
                                {heartRate && Object.keys(heartRate).length > 0 ? <div>
                            <span style={{
                                width: '100%',
                                float: "left",
                                lineHeight: '35px',
                                textAlign: "left",
                                fontSize: "28px",
                                fontWeight: '600',
                                color: "#085394"
                            }}>{heartRate?.value ? heartRate?.value + " BPM" : "-"} </span>
                                    <span style={{
                                        width: '100%',
                                        float: "left", fontSize: '12px'
                                    }}>Source : {heartRate.sourceName}</span>
                                </div> : ''}
                            </Col>
                            <Col style={{borderRight: "solid 2px #DDDDDD", margin: '10px', padding: '0px 0px'}}>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    color: "#666666",
                                    fontSize: '12px',
                                    fontWeight: '600'
                                }}>Today's Range </span>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    lineHeight: '35px',
                                    textAlign: "left",
                                    fontSize: "23px",
                                    fontWeight: '600',
                                    color: "#085394"
                                }}>{heartRateCurDay?.mincurrentDay && heartRateCurDay?.maxcurrentDay ? heartRateCurDay?.mincurrentDay + " - " + heartRateCurDay?.maxcurrentDay + " BPM" : "-"}
                                </span>
                            </Col>
                            <Col style={{margin: '10px', padding: '0px 0px'}}>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    color: "#666666",
                                    fontSize: '12px',
                                    fontWeight: '600'
                                }}> Resting </span>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    lineHeight: '35px',
                                    textAlign: "left",
                                    fontSize: "23px",
                                    fontWeight: '600',
                                    color: restingHeartRate.color
                                }}> {restingHeartRate?.averageRHR ? restingHeartRate.averageRHR + " BPM" : "-"} </span>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    color: "#666666",
                                    fontSize: '12px',
                                    fontWeight: '600'
                                }}> Walking </span>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    lineHeight: '35px',
                                    textAlign: "left",
                                    fontSize: "23px",
                                    fontWeight: '600',
                                    color: walkingHeartRate.color
                                }}> {walkingHeartRate?.averageWHR ? walkingHeartRate.averageWHR + " BPM" : "-"} </span>
                            </Col>
                        </Row>
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        PatientContext: state.patient.patientContext,
        getLatestObservation: state.patient.getLatestObservation?.HeartRatePatientPanelComponent,
        getPatientObservationsByPeriodForHeartRate: state.patient.getPatientObservationsByPeriodForHeartRate?.HeartRatePatientPanelComponent,
        getPatientObservationsByPeriodForRestingAndWalkingHeartRate: state.patient.getPatientObservationsByPeriodForHeartRate?.HeartRatePatientPanelComponentRestingAndWalking,
        HeartRateManagementData: state.patient.observations.HeartRateManagementData,
        dictionaries: state.dictionary.dictionaries,
        observationThresholdContext: state.observationThreshold.observationThresholdContext,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        ObservationsThresholdActions: bindActionCreators(ObservationsThresholdAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeartRatePatientPanelComponent);