import * as React from 'react';
import {useDispatch} from 'react-redux';
import {SchedulerForm} from '@progress/kendo-react-scheduler';
import CustomFormEditor from './CustomFormEditor';
import {CustomDialog} from './CustomDialog';
import {UserActions} from "../action/UserAction";
import { ScheduleActions } from "../action/SchedulerAction";

export const FormWithCustomEditor = props => {
    const requiredValidator = React.useCallback(value => value === undefined || value === null || value === '' ? 'Field is required.' : undefined, []);
    const dispatch = useDispatch();
    const formValidator = (_dataItem, formValueGetter) => {
        let result = {};
        result.title = [requiredValidator(formValueGetter('Title'))].filter(Boolean).reduce((current, acc) => current || acc, '');
        return result;
    };

    UserActions.clearProviderUser()(dispatch).then(()=> {
        ScheduleActions.setSchedulerContext(props?.dataItem)(dispatch)
    })

    return <SchedulerForm {...props} editor={CustomFormEditor} dialog={CustomDialog} validator={formValidator} />;
};