import {Window} from "@progress/kendo-react-dialogs";
import React, {useState} from 'react';
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle
} from '@progress/kendo-react-charts';
import 'hammerjs';
import PropTypes from "prop-types";
import GlobalStyles from '../../config/GlobalStyles';
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {Button} from "primereact/button";
import {bindActionCreators} from "redux";
import {PatientAction} from "../../action/PatientAction";
import {connect} from 'react-redux';
import moment from 'moment';
import {RadioButton} from "@progress/kendo-react-inputs";
import {Col, Row} from "react-grid-system";
import {Grid, GridColumn} from "@progress/kendo-react-grid";


const categories = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const categoriesInMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const [initCurrentWeek] = [[]];
const series = [5000, 5000, 5000, 5000, 5000, 5000, 5000,];

const HeartRateManagementBarWindow = (props) => {
    const { HeartRateManagementData, PatientActions, PatientContext, HeartRateManagementBarWindowData, selectActivityType } = props
    const [selectedValue, setSelectedValue] = React.useState(selectActivityType?.selectedValue ? selectActivityType?.selectedValue : "week");
    
    const [weeksPrevious, setWeeksPrevious] = React.useState(0)
    const [isDisable, setIsDisable] = React.useState(false);
    const [weeksPreviousFlag, setWeekPrevousFlag] = React.useState(true);
    const [datePeriod, setDatePeriod] = useState({
        startDate: moment().startOf('week').format('YYYY-MM-DD'),
        endDate: moment().endOf('week').format('YYYY-MM-DD')
    })

    const [xAxis, setXAxis] = React.useState("");
    const [commonChart, setCommonChartData] = React.useState([]);
    const [gridTitle, setGridTitle] = React.useState("Date");
    const [gridBPMTitle, setGridBPMTitle] = React.useState("BPM");
    const [gridData, setGridData] = React.useState([]);
    //const [currentComponentData, setCurrentComponentData] = React.useState("false");
    const [enterManuallyCount, setEnterManuallyCount] = React.useState(true);


    React.useEffect(() => {
        PatientActions.getPatientObservationsByPeriod(PatientContext?.patientRoleId,
            datePeriod.startDate,
            datePeriod.endDate,
            ["AH_RESTING_HR"],
            "HeartRateManagementBarWindow")

    }, [datePeriod]);
    React.useEffect(() => {
        if(selectedValue==="week"){
            let startDate = moment().startOf('week').subtract(weeksPrevious, 'weeks').format('YYYY-MM-DD')
            let endDate = moment().endOf('week').subtract(weeksPrevious, 'weeks').format('YYYY-MM-DD')
            setDatePeriod({ startDate: startDate, endDate: endDate })
        } else if(selectedValue==="month"){
            let startDate = moment().startOf('year').subtract(weeksPrevious, 'years').format('YYYY-MM-DD')
            let endDate = moment().endOf('year').subtract(weeksPrevious, 'years').format('YYYY-MM-DD')
            setDatePeriod({
                    startDate: startDate,
                    endDate: endDate
            });
        }
    },[weeksPrevious]);

    const handleClickRight = (e) =>{
        if(weeksPrevious==0){
            setWeeksPrevious(0);
        } else {
            setWeeksPrevious(weeksPrevious-1);
        }  
    }
    const handleClickLeft = (e) =>{
        setWeeksPrevious(weeksPrevious+1);
    }
    
    React.useEffect(() => {
        if(HeartRateManagementBarWindowData?.length > 0){
            prepareData(HeartRateManagementBarWindowData)
        } else if(HeartRateManagementData?.length > 0){
            prepareData(HeartRateManagementData)
            //setCurrentComponentData("true");
        } else {
            prepareData([])
        }
    }, [HeartRateManagementBarWindowData,HeartRateManagementData])

    function prepareData(HearRateData){
        if(selectedValue=="week" && HearRateData?.length > 0){
            const weekDataArr = [0,0,0,0,0,0,0];
            const weekdata = HearRateData;
            const gridataWeekNonFiltered = [];
            for(let i =0; i < weekdata.length; i++){
                const getDay = moment(weekdata[i].effectiveStartDate).format('ddd');
                const indexForWeek = categories.indexOf(getDay);
                weekDataArr[indexForWeek] =  Math.round(weekdata[i].value);
                gridataWeekNonFiltered.push({
                    date: moment(weekdata[i].effectiveStartDate).format('M/D/YY'),
                    bpm: Math.round(weekdata[i].value)                    
                });
            }
            
            let gridataWeek = gridataWeekNonFiltered.filter((gridataWeekNonFiltered, index, self) =>
                                    index== self.findLastIndex((t) =>(t.date == gridataWeekNonFiltered.date))
            );
            setCommonChartData(weekDataArr);
            setGridData(gridataWeek);
        } else if(selectedValue=="month" && HearRateData?.length > 0){
            const yearDataArr = [0,0,0,0,0,0,0,0,0,0,0,0];
            let yearObjec = {};
            const yeardata = HearRateData;
            const gridataYear = [];
            for(let i =0; i < yeardata.length; i++){
                const getMonth = moment(yeardata[i].effectiveStartDate).format('MMM');
                //const indexForWeek = categories.indexOf(getDay);
                //weekDataArr[indexForWeek] =  weekdata[i].value;
                //yearObjec.keys(getMonth);
                if(yearObjec.hasOwnProperty(getMonth)){
                    yearObjec[getMonth].push(parseInt(yeardata[i].value));
                } else {
                    yearObjec[getMonth] = [parseInt(yeardata[i].value)];
                }
            }
            Object.entries(yearObjec).forEach(entry => {
              const [key, value] = entry;
              let count =0;
              let average = 0;
              let total = 0;
              for(let i =0; i<value.length;i++){
                total = total+value[i];
                count = count+1; 
              }
              average = total/count;
              const indexForYear = categoriesInMonth.indexOf(key);
              yearDataArr[indexForYear] =  Math.round(average);
              gridataYear.push({
                    date: key+" "+moment().format('YY'),
                    bpm: Math.round(average)                    
              });

            });
            setCommonChartData(yearDataArr);
            setGridData(gridataYear);
        } else if(selectedValue=="week" && HearRateData?.length == 0){
            const weekDataArr = [0,0,0,0,0,0,0];
            const gridataWeekNonFiltered = [];
            let gridataWeek = gridataWeekNonFiltered.filter((gridataWeekNonFiltered, index, self) =>
                                    index== self.findLastIndex((t) =>(t.date == gridataWeekNonFiltered.date))
            );
            setCommonChartData(weekDataArr);
            setGridData(gridataWeek);
        } else if(selectedValue=="month" && HearRateData?.length == 0){
            const yearDataArr = [0,0,0,0,0,0,0,0,0,0,0,0];
            const gridataYear = [];
            setCommonChartData(yearDataArr);
            setGridData(gridataYear);
        }
    }

    const hideDialog = () => {
        let startDate = moment().startOf('week').format('YYYY-MM-DD')
        let endDate = moment().endOf('week').format('YYYY-MM-DD')
        setDatePeriod({ startDate: startDate, endDate: endDate })
        setGridTitle("Date");
        setSelectedValue("week");
        setWeeksPrevious(0);
        setEnterManuallyCount(true);
        props.PopupActions.hideWindow(PopupWindows.HEART_RATE_MANAGEMENT_BAR_WINDOW)
    }

    const handleChange = React.useCallback(
        (e) => {
            if (e.value === "week") {
                setDatePeriod({
                    startDate: moment().startOf('week').format('YYYY-MM-DD'),
                    endDate: moment().endOf('week').format('YYYY-MM-DD')
                });
                setGridTitle("Date");
                setSelectedValue("week");
                setWeeksPrevious(0);
                setEnterManuallyCount(true);
            } else {
                setDatePeriod({
                    startDate: moment().startOf('year').format('YYYY-MM-DD'),
                    endDate: moment().endOf('year').format('YYYY-MM-DD')
                });
                setGridTitle("Month");
                setSelectedValue("month");
                setWeeksPrevious(0);
                setEnterManuallyCount(true);
            }
        },[selectedValue])

    
    if (props.showWindow[PopupWindows.HEART_RATE_MANAGEMENT_BAR_WINDOW]) {
        const GlobStyle = {
            ...GlobalStyles.newTitleWrapper,
            overflow: 'hidden', width: '100%',
        }
        const customCell = (props) => {
            const isShowAvg = props.dataItem.showAvg;
             
            const icon = (isShowAvg === 'Yes') ? (
                <span className="" >*</span>
              ) : (
                <span className="" ></span>
              );
              return(
                  <td>{props.dataItem.field}{icon}</td>
              )
        }
        return (
            <Window
                title={"Resting Heart Rate"}
                modal={true}
                onClose={hideDialog}
                initialHeight={650}
                initialWidth={650}>

                <div style={GlobStyle}>
                    <Row style={{ textAlign: 'center' }}>
                        <Col>
                            <RadioButton
                                name="rhr1"
                                value="week"
                                label="Week"
                                checked={selectedValue === "week"}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col>
                            <RadioButton
                                name="rhr1"
                                value="month"
                                label="Year"
                                checked={selectedValue === "month"}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col>
                            <Button className="k-button k-button-icon" style={{ border: "none", background: "none" }} disabled={isDisable} onClick={handleClickLeft} >
                                <span style={{ fontSize: "30px", color: '#7585C2' }} role="presentation" className="k-icon k-i-arrow-60-left" />
                            </Button>

                            <Button className="k-button k-button-icon" style={{ border: "none", background: "none" }} disabled={isDisable} onClick={handleClickRight}>
                                <span style={{ fontSize: "30px", color: '#7585C2' }} role="presentation" className="k-icon k-i-arrow-60-right" />
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "10px" }}>
                        <Col>
                            {selectedValue === "week" && (
                                <>
                                    <span style={{ paddingLeft: "10px", color: "#888888", fontSize: '14px' }}>
                                        {moment(datePeriod.startDate).format('MM/DD/YY')} - {moment(datePeriod.endDate).format('MM/DD/YY')}{' '}
                                    </span>
                                </>
                            )}
                            {selectedValue === "month" && (
                                <>
                                    <span style={{ paddingLeft: "10px", color: "#888888", fontSize: '14px' }}>
                                        {moment(datePeriod.endDate).format('YYYY')}{' '}
                                    </span>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Chart style={{ height: "250px", width: "auto", paddingTop: 10 }}>
                        <ChartTitle text="" />
                        <ChartCategoryAxis>
                            {selectedValue === 'week' && (
                                <ChartCategoryAxisItem categories={categories}>
                                    <ChartCategoryAxisTitle text="" />
                                </ChartCategoryAxisItem>
                            )}
                            {selectedValue === 'month' && (
                                <ChartCategoryAxisItem categories={categoriesInMonth}>
                                    <ChartCategoryAxisTitle text="" />
                                </ChartCategoryAxisItem>
                            )}
                        </ChartCategoryAxis>
                        <ChartSeries>
                            <ChartSeriesItem
                                type="column"
                                color='#6AA84F'
                                gap={0.6} spacing={0.4}
                                data={commonChart}
                                tooltip={{
                                    visible: true,
                                    background: "white",
                                    render: (v) => {
                                        return <div>{Math.round(v.point.value)} BPM</div>
                                    }

                                }}
                            />
                        </ChartSeries>
                    </Chart>

                    <Grid
                        style={{
                            maxHeight: "auto", marginTop: "15px",
                            fontWeight: "bold", overflowY: "scroll !important", height: "230px"
                        }}
                        data={gridData}
                        className="grid">
                        <GridColumn field={"date"} title={gridTitle} />
                        <GridColumn field={"bpm"} title={gridBPMTitle} />
                    </Grid>

                </div>
            </Window>
        )
    } else {
        return null
    }

}
HeartRateManagementBarWindow.propTypes = {
    showWindow: PropTypes.object,
    PopupActions: PropTypes.object,
    PatientActions: PropTypes.object,
};
function mapStateToProps(state) {
    return {
        HeartRateManagementData: state.patient.HeartRateManagementData,
        PatientContext: state.patient.patientContext,

        HeartRateManagementBarWindowData: state.patient.observations.HeartRateManagementBarWindow,
        popupState: state.popupWindow.state,
        showWindow: state.popupWindow.showWindow,
        selectActivityType: state.patient.createPatientContext
        
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HeartRateManagementBarWindow);