import {connect} from "react-redux";
import {DrawerContent, TileLayout} from "@progress/kendo-react-layout";
import ManagePermissionComponent from "./ManagePermissionComponent";
import React from "react";
import './rolebase.css';

const userListTile = [
    {
        defaultPosition: {
            col: 1,
            colSpan: 4,
            rowSpan: 6,
        },
        item: <ManagePermissionComponent/>,
        className: "role-base-access"
    }
];


function AdminManagePermissionPage(props) {
    return <DrawerContent style={{width: "100%"}}>
        <TileLayout style={{
            width: "96%",
            height: "calc(100vh - 90px)"
        }} ignoreDrag={(e) => {
            return !(e.target.classList.contains("k-card-title"));
        }} className={"main-screen user-header-component"} items={userListTile}/>
    </DrawerContent>

}


function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminManagePermissionPage);