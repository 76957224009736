import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react';
import axios from "axios";
import {url} from "../../config/Url";
import {DictionaryAction} from "../../action/DictionaryDispatchAction";
import _ from "lodash";
import {TabPanel, TabView} from "primereact/tabview";
import {childrenWithProps} from "../shared/Helpers";


const DictionaryTabSelector = (props) => {
    const {
        required,
        value,
        defaultItemCode,
        inputRef,
        code,
        form,
        limit = 100,
        showCode = false,
        setValue = true,
        fullObject = false
    } = props
    const [data, setData] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const [filter, setFilter] = React.useState("")
    const [internalValue, setInternalValue] = useState(null)

    const filterChange = (event) => {
        setFilter(event.filter)
    };

    const onSetDefaultValue = (v) => {
        if (props.onChange) {
            if (fullObject)
                props.onChange({value: v})
            else
                props.onChange({value: v?.id})
        }
    };

    const handleChange = (e) => {
        console.log(e.value)
        if (e.value?.id) {
            if (setValue)
                setInternalValue(e.value)
            if (props.onChange) {
                if (fullObject)
                    props.onChange(e)
                else {
                    e.value = e.value?.id
                    props.onChange(e)
                }
            }
        }
    }

    useEffect(() => {
        if ((!_.isNil(value) && _.isNil(internalValue))) {
            if (value) {
                DictionaryAction.getDictionaryByIdOrCode(code, value, null).then(response => {
                    let item = response?.payload
                    if (!_.isNil(item)) {
                        item = {...item, filterDisplay: `${item.code} - ${item.displayName}`}
                        setInternalValue(item)
                    }
                })
            }
        } else if (!_.isNil(defaultItemCode) && _.isNil(internalValue)) {
            DictionaryAction.getDictionaryByIdOrCode(code, null, defaultItemCode).then(response => {
                let item = response?.payload
                if (!_.isNil(item)) {
                    onSetDefaultValue(item)
                    item = {...item, filterDisplay: `${item.code} - ${item.displayName}`}
                    setInternalValue(item)

                }
            })
        }


    }, [defaultItemCode, data, value])

    const refreshData = () => {
        axios.post(url("dictionary/search/index"), {
            payload: {

                active: true,
                dictionaryTypeCode: code,
                searchString: filter
            },
            sort: null,
            limit: 50,
            offset: 0
        }).then(resp => {
            if (resp.data.payload?.length > 0) {
                let newData = resp.data.payload.map(item => {
                    return {...item, filterDisplay: `${item.code} - ${item.displayName}`}
                })
                if (internalValue?.id) {
                    newData = [...data, internalValue]
                }
                setData(newData)
            }
        })
    }

    useEffect(() => {
        // we need to make sure the value is in the list of options
        if (!_.isNil(internalValue?.id)) {
            let exists = data.find(d => d?.id === internalValue?.id)

            if (!exists?.id) {
                let newData = [...data, internalValue]
                setData(newData)
            }
        }


    }, [internalValue, data])


    useEffect(() => {
        refreshData()
    }, [filter])

    // useEffect(() => {
    //     refreshData()
    // }, [])


    return (<>
        <div>
            <TabView style={{width: "100%"}} scrollable>
                {data.map((element, idx) => {
                    return <TabPanel header={element.displayName}/>
                })}
            </TabView>
            {childrenWithProps(props.children, {
                ...props,
                dictTabObject: internalValue
            })}

        </div>


    </>)
}


DictionaryTabSelector.propTypes = {
    style: PropTypes.object,
    code: PropTypes.string.isRequired,
    defaultItemCode: PropTypes.string,
    onChange: PropTypes.func,
    register: PropTypes.func,
}


export default DictionaryTabSelector