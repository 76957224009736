import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react';
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {useDispatch, useSelector} from "react-redux";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import _ from "lodash";

export const Dictionary = {
    ORDER_STATUS: "AH_ORDER_STATUS",
    AH_FORM_CATEGORY: "AH_FORM_CATEGORY",
    ADVANCE_DIRECTIVE: "AH_ADVANCE_DIRECTIVE",
    ASSESSMENT: "AH_ASSESSMENT",
    CARE_PLAN_DETERMINANTS: "AH_CARE_PLAN_DETERMINANTS",
    CARE_PLAN_GOALS: "AH_CARE_PLAN_GOAL_CATEGORY",
    CARE_PLAN_GOAL_STATUS: "AH_CARE_PLAN_GOAL_STATUS",
    CARE_PLAN_STRATEGIES: "AH_CARE_PLAN_STRATEGIES",
    CARE_TEAM: "AH_CARE_TEAM",
    CHANNEL_TYPE: "AH_CHANNEL_TYPE",
    CLINICAL_COMPLEXITY_ALG: "AH_CLINICAL_COMPLEXITY_ALGORITHM",
    COMPONENT: "AH_COMPONENT",
    ADDRESS_CONTACT: "AH_ADDRESS",
    EMAIL_CONTACT: "AH_CONTACT_EMAIL",
    PHONE_CONTACT: "AH_CONTACT_PHONE",
    COUNTRIES: "AH_COUNTRIES",
    ENCOUNTER_FORM: "AH_ENCOUNTER_FORM",
    ENCOUNTER_TYPE: "AH_ENCOUNTER_TYPE",
    FACILITIES: "AH_FACILITY",
    GENDER: "AH_GENDER",
    RACE: "AH_RACE",
    ETHNICITY: "AH_ETHNICITY",
    MARITAL_STATUS: "AH_MARITAL_STATUS",
    MEETING_FORMAT: "AH_MEETING_FORMAT",
    NOTIFICATION_TYPE: "AH_NOTIFICATION",
    NOTIFICATION_ACTION_TYPE: "AH_NOTIFICATION_ACTION_TYPE",
    PERMISSION: "AH_PERMISSION",
    PERMISSION_GROUP: "AH_PERMISSION_GROUP",
    PLACE_OF_SERVICE: "AH_POS",
    PROBLEM_CATEGORY: "AH_PROBLEM_CATEGORY",
    PROBLEM_STATUS: "AH_PROBLEM_STATUS",
    MEDICATION_PROBLEM_STATUS: "AH_MEDICATION_CLINICAL_STATUS",
    USER_ROLE: "AH_ROLE",
    SOURCE_SYSTEM: "AH_SOURCE_SYSTEM",
    STATES: "AH_STATES",
    SYSTEM_FORM: "AH_SYSTEM_FORM",
    TAXONOMY: "AH_TAXONOMY",
    AH_CPT: "AH_CPT",
    CARE_PLAN_INTENT: "FHIR_CARE_PLAN_INTENT",
    CARE_PLAN_STATUS: "FHIR_CARE_PLAN_STATUS",
    OBSERVATION_CATEGORY: "OBSERVATION_CATEGORY",
    OBSERVATION_STATUS: "FHIR_OBSERVATION_STATUS",
    HCC: "HCC",
    ICD10: "ICD10CM",
    ICF: "ICF",
    LOINC: "LOINC",
    CVX: "VACCINE_CVX",
    VACCINE_INFORMATION_SOURCE: "VACCINE_INFORMATION_SOURCE",
    VACCINE_LOCATION_ADMINISTERED: "VACCINE_LOCATION_ADMINISTERED",
    VACCINE_MVX: "VACCINE_MVX",
    VACCINE_ROUTE: "VACCINE_ROUTE",
    VACCINE_STATUS: "VACCINE_STATUS",
    ROLE: "AH_ROLE",
    PROVIDER_TYPE: "AH_PROVIDER_TYPE",
    LICENSE_TYPE: "AH_LICENSE_TYPE",
    AH_REQUEST_TYPE: "AH_REQUEST_TYPE",
    AH_REQUEST_STATUS: "AH_REQUEST_STATUS",
    AH_EXTERNAL_VENDOR: "AH_EXTERNAL_VENDOR",
    AH_LAB_ORDER_STATUS: "AH_LAB_ORDER_STATUS",
    AH_MFA_METHOD: "AH_MFA_METHOD",
    AH_ORDER_TYPE: "AH_ORDER_TYPE",
    AH_QUALITY_MEASURES: "AH_QUALITY_MEASURES",
    AH_GEOGRAPHIC_REGION: "AH_GEOGRAPHIC_REGION",
    AH_SERVICE_LOCATION: "AH_SERVICE_LOCATION",
    AH_COHORT: "AH_COHORT",
    AH_MY_PATIENTS: "AH_MY_PATIENTS",
    AH_ALLERGY_OCCURRENCE: "AH_ALLERGY_OCCURRENCE",
    AH_ALLERGY_SEVERITY: "AH_ALLERGY_SEVERITY",
    AH_ALLERGY_REACTION: "AH_ALLERGY_REACTION",
    AH_CONDITION_SOURCE: "AH_CONDITION_SOURCE",
    AH_MEDICATION_UNITS: "AH_MEDICATION_UNITS",
    AH_MEDICATION_FORMULATION: "AH_MEDICATION_FORMULATION",
    AH_MEDICATION_ROUTE: "AH_MEDICATION_ROUTE",
    AH_MEDICATION_FREQUENCY: "AH_MEDICATION_FREQUENCY",
    AH_OUTCOMES: "AH_OUTCOMES",
    AH_RXNORM: "AH_RXNORM",
    AH_REFERRALS: "AH_REFERRALS",
    AH_EDUCATION_LEVEL: "AH_EDUCATION_LEVEL",
    AH_CONTACT_PREFERENCE: "AH_CONTACT_PREFERENCE",
    AH_EPRESCRIBE_ROLES: "AH_EPRESCRIBE_ROLES",
    AH_EPRESCRIBE_SPECIALTYCODE: "AH_EPRESCRIBE_SPECIALTYCODE",
    AH_LICENSE_EPRESCRIBE_STATE: "AH_LICENSE_EPRESCRIBE_STATE",
    AH_CARETEAM_ROLE: "AH_CARETEAM_ROLE",
    AH_LANGUAGES: "AH_LANGUAGES",
    AH_DOCUMENT_CATEGORIES: "AH_DOCUMENT_CATEGORIES",
    AH_REQUESTS: "AH_REQUESTS",
    AH_REFERRAL_REPORT_DELIVERY: "AH_REFERRAL_REPORT_DELIVERY",
    AH_ALLERGY_CLASS: "AH_ALLERGY_CLASS",
    AH_PERSON_RELATIONSHIP: "AH_PERSON_RELATIONSHIP",
    AH_HOSPITAL_ADMISSION_DOCUMENTS: "AH_HOSPITAL_ADMISSION_DOCUMENTS",
    AH_INSURANCE_PROVIDER: "AH_INSURANCE_PROVIDER",
    AH_LAB_VENDOR: "AH_LAB_VENDOR",
    AH_RELATIONSHIP_ROLE: "AH_RELATIONSHIP_ROLE",
    AH_RELATIONSHIP_TYPE: "AH_RELATIONSHIP_TYPE",
}

const DictionaryDropDownList = (props) => {
    const {required, value, defaultItemCode, inputRef, code} = props
    const [data, setData] = useState([])
    const [filter, setFilter] = React.useState("")
    const [internalValue, setInternalValue] = useState(null)

    const dispatch = useDispatch()
    const dictionaries = useSelector(s => s.dictionary.dictionaries)

    const filterChange = (event) => {
        setFilter(event.filter.value)
        //setData(filterData(event.filter));
    };

    const onSetDefaultValue = (value) => {
        if (props.onChange) {
            props.onChange({target: {value: value}});
        }
    };

    const handleChange = (e) => {
        if (e.value.id) {
            setInternalValue(e.target.value)
            if (props.onChange) {
                props.onChange(e)
            }
        }
    }
    useEffect(() => {
        setData(dictionaries[code]);
    }, [dictionaries])

    useEffect(() => {
        if (data?.length > 0) {
            if (value?.id && internalValue?.id !== value?.id) {
                setInternalValue(value)
            } else if (!_.isNil(value) && _.isNil(internalValue)) {
                let v = data ? data.find((c) => c.id === value) : undefined

                if (!_.isNil(v)) {
                    onSetDefaultValue(v)
                    setInternalValue(v)
                }

            } else if (!internalValue?.id && !_.isNil(defaultItemCode)) {
                let v = data ? data.find((c) => c.code === defaultItemCode) : undefined
                if (!_.isNil(v)) {
                    onSetDefaultValue(v)
                    setInternalValue(v)
                }
            }
        }


    }, [defaultItemCode, data, value])


    React.useEffect(() => {
        let filterJson = {};
        filterJson.displayName = filter;
        //Setting it to true because by default active dictionary values are needed in dropdownlist
        filterJson["active"] = true;

        if (props?.active) {
            if (props.active === false)
                delete filterJson.active
        }
        DictionaryDispatchAction.searchDictionaryList(code, 500, 0, null, filterJson)(dispatch)
    }, [code, filter])


    return (<>
        <DropDownList style={props.style}
                      ref={inputRef}
                      required={required}
                      disabled={props.disabled}
                      data={data}
                      value={internalValue}
                      dataItemKey={"id"}
                      textField={"displayName"}
                      filterable={true}
                      onFilterChange={filterChange}
                      onChange={handleChange}
        />

    </>)
}


DictionaryDropDownList.propTypes = {
    style: PropTypes.object,
    code: PropTypes.string.isRequired,
    defaultItemCode: PropTypes.string,
    onChange: PropTypes.func,
    register: PropTypes.func,
}


export default DictionaryDropDownList