export const RxIcon = (props) => {
    const {height = 20, width = 20} = props
    return <svg width={width} height={height} version="1.1" viewBox="0 0 700 700"
                xmlns="http://www.w3.org/2000/svg">
        <g fill-rule="evenodd">
            <path
                d="m215.83 93.332c0-9.6641 7.8359-17.5 17.5-17.5h70.59c46.484 0 84.168 37.684 84.168 84.168s-37.684 84.168-84.168 84.168h-5.0703l181.1 211.11c6.293 7.3359 5.4453 18.383-1.8906 24.676-7.3359 6.293-18.383 5.4453-24.672-1.8906l-200.65-233.89h-1.9062v115.83c0 9.6641-7.832 17.5-17.5 17.5-9.6641 0-17.5-7.8359-17.5-17.5zm35 115.84v-98.336h53.09c27.156 0 49.168 22.016 49.168 49.168s-22.012 49.168-49.168 49.168z"/>
            <path
                d="m449.91 239.48c7.6484 5.9062 9.0625 16.898 3.1562 24.547l-164.71 213.33c-5.9062 7.6523-16.895 9.0664-24.543 3.1602-7.6523-5.9062-9.0664-16.898-3.1602-24.547l164.71-213.34c5.9062-7.6484 16.898-9.0625 24.547-3.1562z"/>
        </g>
    </svg>
}
