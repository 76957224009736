export async function ParseResponse(response) {
    if (response.ok) {
        try {
            return await response.json()
        } catch (e) {
            return response
        }
    } else if (response.status === 400) {
        try {
            return await response.json()
        } catch (e) {
            return response
        }
    } else {
        return response.statusText
    }
}