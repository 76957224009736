import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import _, {isObject} from "lodash";
import {Dropdown} from "primereact/dropdown";
import axios from "axios";
import {url} from "../../config/Url";


const UserDropDownList = (props) => {
    const dispatch = useDispatch()
    const initialSort = [
        {
            field: "firstName",
            dir: "asc",
        },
    ];
    const {value, fullObject = false, inputRef} = props
    const [data, setData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null)
    const [filter, setFilter] = useState("");
    const {defaultCurrentUser = false} = props
    const userRoleId = useSelector(state => state.auth.session.userRoleId)
    const filterChange = (event) => {
        setFilter(event.filter)
        //setData(filterData(event.filter));
    };

    const onSetDefaultValue = (v) => {
        if (props.onChange) {
            props.onChange({value: v});
        }
    };

    const handleChange = (e) => {
        setSelectedValue(e.value)
    }

    useEffect(() => {
        // console.log(selectedValue)
        let e = {target: {value: selectedValue}}
        if (e.target.value?.userRoleId) {
            if (props.onChange) {
                if (fullObject)
                    props.onChange(e)
                else {

                    e.target.value = e.target.value.userRoleId
                    props.onChange(e)
                }
            }
        } else {
            props.onChange(null)
        }
    }, [selectedValue])


    useEffect(() => {
            axios.post(url('user/filter/all'), {
                payload: {
                    activeOnly: true,
                    portalUsers: false,

                },
                limit: 1000,
                offset: 0,
                filter: {searchAll: filter},
                sort: null,
            }).then(response => {
                    // console.log(response.data.payload)
                    let d = [{displayName: "Please Select ..."}];
                    response?.data?.payload?.forEach(itm => {
                        d.push( {
                            ...itm,
                            displayName: itm?.firstName + " " + itm?.lastName
                        })
                    });
                    setData(d)
                }
            )
        }, [filter]
    )
    const getUser = async (userRoleId) => {
        if (!_.isNull(userRoleId))
            return await axios.post(url("user/id"), {payload: {userRoleId}})
        else
            return null
    }

    const setDefaultUser = async () => {
        if (!_.isNull(defaultCurrentUser) && !selectedValue?.userRoleId && !value) {
            let response = await getUser(userRoleId)
            let d = response?.data?.payload
            setSelectedValue({...d, displayName: d?.firstName + " " + d?.lastName, id: d.id})
        }
    }

    useEffect(() => {
        if (defaultCurrentUser && !selectedValue?.userRoleId && !value) {
            setDefaultUser()
            return
        }
        if (isObject(value)) {
            if (selectedValue?.userRoleId !== value?.userRoleId)
                setSelectedValue(value)
            return;
        }
        if (!selectedValue?.userRoleId && value) {
            let v = data ? data.find((c) => c.userRoleId === value) : null
            if (v) {
                setSelectedValue({...v, displayName: v?.firstName + " " + v?.lastName})
            } else {
                getUser(value).then(response => {
                    let d = response?.data?.payload
                    // console.log(d)
                    setSelectedValue({...d, displayName: d?.firstName + " " + d?.lastName})
                })
            }
        }

    }, [value, userRoleId])

    // useEffect(() => {
    //     setData(users);
    // }, [users])


    return (
        <>
            <Dropdown style={{...props.style}}
                      panelStyle={{overflow: "scroll", width: "20%"}}
                      autoWidth={false}
                      className={props.className}
                      focusInputRef={inputRef}
                      required={props.required}
                      resetFilterOnHide
                      showFilterClear
                      disabled={props.disabled}
                      options={data}
                      value={selectedValue}
                      optionLabel={"displayName"}
                      filter
                // itemTemplate={v => {
                //     if (props.showCode)
                //         return (<div>{`(${v?.code}) ${v?.displayName}`}</div>)
                //     else
                //         return (<div>{`${v?.displayName}`}</div>)
                // }}
                // valueTemplate={(v) => {
                //     if (props.showCode)
                //         return (<div>{`(${v?.code}) ${v?.displayName}`}</div>)
                //     else
                //         return (<div>{`${v?.displayName}`}</div>)
                // }}
                      onFilter={filterChange}
                      onChange={handleChange}
            />
        </>

    );
};


export default UserDropDownList