import React, {useState} from 'react';
import moment from "moment"
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {PatientAction} from "../../action/PatientAction";
import TileHeader from '../../layout/TileHeader';
import "hammerjs";
import {ObservationCodes, PhixPermissions} from "../../config/GlobalConfig";
import {Col, Row} from 'react-grid-system';
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";

const [initCurrentWeek] = [[]];
const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const WeightManagementPatientPanelComponent = (props) => {
    const {PatientActions, PatientContext} = props
    const [prevWeekChartData, setPrevWeekChartData] = React.useState([]);


    const [currentWeek, setCurrentWeek] = React.useState(initCurrentWeek);
    const [weeksPrevious, setWeeksPrevious] = React.useState(0);
    const [averageLine, setAverageLine] = React.useState([])

    const [datePeriod, setDatePeriod] = useState({
        //startDate: moment().startOf('week').format('YYYY-MM-DD'),
        startDate: moment().utc().local().startOf('week').subtract(1, "week").format('YYYY-MM-DD'),
        endDate: moment().endOf('week').format('YYYY-MM-DD')
    })
    const [weightChart, setWeightChart] = React.useState([]);
    const [BMIChart, setBMIChart] = React.useState([]);


    const [wgtData, setWgtData] = React.useState({});
    const [BMIData, setBMIData] = React.useState({});
    // React.useEffect(() => {
    //     console.log(" BMIData ",BMIData,"wgtData wgtData ",wgtData)
    // },[wgtData,BMIData])
    React.useEffect(() => {
        let startDate = moment().startOf('week').subtract(weeksPrevious, "week").format('YYYY-MM-DD')
        let endDate = moment().endOf('week').subtract(weeksPrevious, "week").format('YYYY-MM-DD')
        setDatePeriod({startDate: startDate, endDate: endDate})
    }, [weeksPrevious]);

    React.useEffect(() => {
        const payload = {
            patientRoleId: PatientContext.patientRoleId,
            startDate: moment(datePeriod.startDate).format("YYYY-MM-DD"),
            endDate: moment(datePeriod.endDate).format("YYYY-MM-DD"),
            codes: [ObservationCodes.WEIGHT.AH_BODY_WEIGHT_MEASURED],
        }
        PatientActions.weightMeasured(payload)
        const payload1 = {
            patientRoleId: PatientContext.patientRoleId,
            code: ObservationCodes.WEIGHT.AH_BODY_WEIGHT_MEASURED,
        }
        PatientActions.weightMeasuredLatest(payload1)
        const payloadBMI = {
            patientRoleId: PatientContext.patientRoleId,
            code: ObservationCodes.WEIGHT.AH_BODY_MASS_INDEX,
        }
        PatientActions.bmiMeasuredLatest(payloadBMI)
    }, [datePeriod.startDate]);

    React.useEffect(() => {
        const payload = {
            patientRoleId: PatientContext.patientRoleId,
            startDate: moment(datePeriod.startDate).format("YYYY-MM-DD"),
            endDate: moment(datePeriod.endDate).format("YYYY-MM-DD"),
            codes: [ObservationCodes.WEIGHT.AH_BODY_MASS_INDEX],
        }
        PatientActions.weightMassIndex(payload)
    }, [datePeriod.startDate]);

    React.useEffect(() => {
        //if (props.massIndex?.length > 0) {
        if (props?.bmiMeasuredLatest) {
            let data = [];
            //props.massIndex.forEach((element) => data.push(element.value));
            let sum = getAverageForEachWeek(data)
            setCurrentWeek(sum);
            if (props.massIndex?.length > 0) {
                let chartData = prepareData("massIndex")
                setPrevWeekChartData(chartData)
                let avgArr = getAverageLineData();
                setAverageLine(avgArr)
                setBMIChart(chartData);
            }
            //let _BMIData = getMostRecentWeight("massIndex")
            let _BMIData = observationTypeLatestForBMI()

            let textColor;
            let displayText;
            if (parseFloat(parseFloat(_BMIData.value).toFixed(2)) < parseFloat(parseFloat(18.5).toFixed(2))) {
                textColor = "#E69138";
                displayText = "BMI indicates underweight."
            } else if (parseFloat(parseFloat(_BMIData.value).toFixed(2)) >= parseFloat(parseFloat(18.5).toFixed(2)) && parseFloat(parseFloat(_BMIData.value).toFixed(2)) <= parseFloat(parseFloat(24.9).toFixed(2))) {
                textColor = "#6AA84F";
                displayText = "BMI indicates normal weight"
            } else if (parseFloat(parseFloat(_BMIData.value).toFixed(2)) >= parseFloat(parseFloat(25.0).toFixed(2)) && parseFloat(parseFloat(_BMIData.value).toFixed(2)) <= parseFloat(parseFloat(29.9).toFixed(2))) {
                textColor = "#E69138";
                displayText = "BMI indicates overweight.";
            } else if (parseFloat(parseFloat(_BMIData.value).toFixed(2)) >= parseFloat(parseFloat(30).toFixed(2))) {
                textColor = "#E06666";
                displayText = "BMI indicates obesity."
            }
            // //else 
            // if(parseFloat(parseFloat(_BMIData.value).toFixed(2)) > parseFloat(parseFloat(30).toFixed(2))){
            //     textColor = "#E06666";
            //     displayText = "BMI indicates obesity."
            // }

            setBMIData({..._BMIData, 'displayText': displayText, 'textColor': textColor})

        } else {

            setCurrentWeek([]);
            setPrevWeekChartData([]);
            setBMIChart([]);
            setWeightChart([]);
            setBMIData({});
        }

    }, [props.massIndex, props.bmiMeasuredLatest])

    React.useEffect(() => {
        if (props.weightMeasured?.length > 0) {
            let data = [];
            props.weightMeasured.forEach((element) => data.push(element.value));
            let sum = getAverageForEachWeek(data)

            setCurrentWeek(sum);

            let chartData = prepareData("weightMeasured");
            setPrevWeekChartData(chartData)
            let avgArr = getAverageLineData();
            setAverageLine(avgArr)
            setWeightChart(chartData);
            let _weightData = getMostRecentWeight("weightMeasured")
            let latestWeightRecord = overvationTypeLatestForWeight()
            setWgtData(latestWeightRecord)
        } else {
            let latestWeightRecord = overvationTypeLatestForWeight()
            if (latestWeightRecord) setWgtData(latestWeightRecord);
            else setWgtData({});
            setCurrentWeek([]);
            setPrevWeekChartData([]);
            setBMIChart([]);
            setWeightChart([]);
        }
    }, [props.weightMeasured])

    React.useEffect(() => {
        getAverageForEachWeek()
    }, [currentWeek])

    function getAverageLineData() {
        let sumPrev = prevWeekChartData.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        let avgArr = Array(7).fill(sumPrev / 3.5);
        return avgArr
    }

    function getAverageForEachWeek(data = null) {
        let sum
        if (data !== null) {
            sum = data.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        } else {
            sum = currentWeek.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        }
        return (Array(7).fill(sum / 3.5))
    }

    function prepareData(stateName) {
        const response = props[stateName];
        const filterBarData = [];
        const date = response.map((i) => moment(i.effectiveStartDate).utc().local().format('YYYY-MM-DD'));

        const setSerialized = new Set(date);
        const uniqueArrSerialized = [...setSerialized];
        uniqueArrSerialized.forEach((item) => {
            const data = response.filter((i) => item === moment(i.effectiveStartDate).utc().local().format('YYYY-MM-DD'));
            if (data.length >= 2) {
                const patientRoleId = data.map((i) => i.patientRoleId);
                const set = new Set(patientRoleId);
                const unique = [...set];
                unique.forEach((i) => {
                    const sameReading = data.filter((e) => e.patientRoleId === i);
                    const valueArray = sameReading.map((i) => parseFloat(i.value));
                    const finalValue = valueArray.reduce((x, y) => x + y / sameReading.length, 0);
                    filterBarData.push({value: finalValue, effectiveStartDate: item});
                })
            } else {
                const valueArray = data.map((i) => parseFloat(i.value));
                const finalValue = valueArray.reduce((x, y) => x + y, 0);
                filterBarData.push({value: finalValue, effectiveStartDate: item});
            }
        })

        const arr = [];
        filterBarData.map((item) => {
            arr.push({
                field: parseInt(item.value),
                categoryField: moment(item.effectiveStartDate).utc().local().format('ddd'),

            })
        });
        const week = prepareWeekData(arr)
        const chart = prepareGraphData(week, 'curr');
        return chart;
    }

    function prepareGraphData(arrayVal, weekName) {
        let objArray = []
        weekDays.forEach((item) => {
            objArray.push(arrayVal[item] ? arrayVal[item] : 0)
        }, [])
        return objArray
    }

    function prepareWeekData(wArray) {
        let b = wArray.reduce((acc, item) => {
            acc[item.categoryField] = acc[item.categoryField] ? acc[item.categoryField] : 0 + item.field;
            return acc
        }, {})
        return b
    }


    function getMostRecentWeight(stateName) {
        const response = props[stateName];
        const filterData = []

        if (response != '' && response != null) {
            const date = response.map((i) => moment(i.effectiveStartDate).utc().local().format('YYYY-MM-DD'));

            const setSerialized = new Set(date);
            const uniqueArrSerialized = [...setSerialized];
            const recentDate = uniqueArrSerialized.reduce((a, b) => {
                return a > b ? a : b
            })

            const data = response.filter((i) => recentDate === moment(i.effectiveStartDate).utc().local().format('YYYY-MM-DD'));

            const recentTime = moment(data[0].effectiveStartDate).utc().local().format("HH:MM A")
            if (data.length >= 2) {
                // if multiple reading for the day display the avarage.
                const patientRoleId = data.map((i) => i.patientRoleId);
                const set = new Set(patientRoleId);
                const unique = [...set];
                unique.forEach((i) => {
                    const sameReading = data.filter((e) => e.patientRoleId === i);
                    const valueArray = sameReading.map((i) => parseFloat(i.value));
                    const finalValue = valueArray.reduce((x, y) => x + y / sameReading.length, 0);
                    const recentDate = uniqueArrSerialized.reduce((a, b) => {
                        return a > b ? a : b
                    })


                    filterData.push({
                        value: finalValue,
                        date: recentDate,
                        time: recentTime,
                        sourceName: data[0].sourceName
                    });
                })
            } else {
                // take the date and time and value.
                const valueArray = data.map((i) => parseFloat(i.value));

                const finalValue = valueArray.reduce((x, y) => x + y, 0);
                filterData.push({
                    value: finalValue,
                    date: recentDate,
                    time: recentTime,
                    sourceName: data[0].sourceName
                });
            }

            return filterData[0];

        }
    }

    function observationTypeLatestForBMI() {
        if (props?.bmiMeasuredLatest) {
            return {
                value: Number(props?.bmiMeasuredLatest?.value),
                date: moment(props?.bmiMeasuredLatest?.effectiveStartDate).utc().local().format('YYYY-MM-DD'),
                time: moment(props?.bmiMeasuredLatest?.effectiveStartDate).utc().local().format("HH:MM A"),
                sourceName: props?.bmiMeasuredLatest?.sourceName
            }
        }
    }

    function overvationTypeLatestForWeight() {

        if (props?.weightMeasuredLatest) {
            return {
                value: props?.weightMeasuredLatest?.value,
                date: props?.weightMeasuredLatest?.effectiveStartDate,
                startDate: moment(props?.weightMeasuredLatest?.effectiveStartDate).utc().local().format('YYYY-MM-DD'),
                starTime: moment(props?.weightMeasuredLatest?.effectiveStartDate).utc().local().format("HH:MM A"),
                sourceName: props?.weightMeasuredLatest?.sourceName
            }
        }
    }

    function overvationTypeLatestForWeight() {
        if (props?.weightMeasuredLatest) {
            return {
                value: props?.weightMeasuredLatest?.value,
                date: props?.weightMeasuredLatest?.effectiveStartDate,
                startDate: moment(props?.weightMeasuredLatest?.effectiveStartDate).utc().local().format('YYYY-MM-DD'),
                starTime: moment(props?.weightMeasuredLatest?.effectiveStartDate).utc().local().format("HH:MM A"),
                sourceName: props?.weightMeasuredLatest?.sourceName
            }
        }
    }

    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                <TileContainer>
                    <TileHeader
                        title={<Row><span className="k-icon k-i-set-column-position" style={{
                            fontSize: '21px',
                            color: '#69A84F',
                            margin: '2px 15px',
                            width: 'max-content'
                        }}/> WEIGHT MANAGEMENT </Row>}
                    />
                    <TileBody>
                        <Row style={{margin: '0', height: "100%"}} onClick={() => {
                            PatientActions.setSavePatientContext({...PatientContext, selectedValue: 'day'})
                            props.PopupActions.showWindow(PopupWindows.WEIGHT_MANAGEMENT_BAR_WINDOW);
                        }}>
                            <Col style={{borderRight: "solid 2px #DDDDDD", margin: '10px', padding: '5px 20px'}}>
                        <span style={{
                            width: '100%',
                            float: "left",
                            color: "#666666",
                            fontSize: '12px'
                        }}>{moment(wgtData?.date).format('MM/DD/YYYY')} <b> {wgtData?.time}</b></span>
                                {wgtData && Object.keys(wgtData).length > 0 ? <div>
                            <span style={{
                                width: '100%',
                                float: "left",
                                lineHeight: '35px',
                                textAlign: "left",
                                fontSize: "30px",
                                fontWeight: '600',
                                color: "#085394"
                            }}>{parseFloat(wgtData.value).toFixed(1)} lbs</span>
                                    <span style={{
                                        width: '100%',
                                        float: "left", fontSize: '12px'
                                    }}>Source : {wgtData.sourceName}</span>
                                </div> : ''}
                            </Col>
                            <Col style={{margin: '10px', padding: '5px 20px'}}>
                                <span style={{width: '100%', float: "left", color: "#666666", height: '15px'}}>  </span>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    lineHeight: '35px',
                                    textAlign: "left",
                                    fontSize: "30px",
                                    fontWeight: '600',
                                    color: BMIData?.textColor
                                }}> BMI {
                                    (BMIData && Object.keys(BMIData).length !== 0 && BMIData?.date==wgtData?.startDate && BMIData?.time==wgtData?.starTime) ? parseFloat(BMIData.value).toFixed(2) : '--'
                                }</span>

                                <span style={{
                                    width: '100%',
                                    float: "left", fontSize: '12px'
                                }}> {BMIData?.displayText} </span>
                            </Col>
                        </Row>
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        ActivityLevelData: state.patient.activityLevelBar,
        PatientContext: state.patient.patientContext,
        weightMeasured: state.patient.weightMeasured,
        massIndex: state.patient.massIndex,
        weightMeasuredLatest: state.patient.weightMeasuredLatest,
        bmiMeasuredLatest: state.patient.bmiMeasuredLatest
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WeightManagementPatientPanelComponent);