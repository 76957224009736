import * as React from "react";
import {useEffect, useState} from "react";
import {Grid, GridColumn, GridNoRecords} from "@progress/kendo-react-grid";
import DictionaryDropDownList, {Dictionary,} from "../InputControls/DictionaryDropDownList";
import {Label} from "@progress/kendo-react-labels";
import {Input} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import {useForm} from "react-hook-form";
import {useQRCode} from "react-qrcodes";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {UserActions} from "../../action/UserAction";
import {debounce} from "lodash";
import {FieldWrapper,} from "@progress/kendo-react-form";
import QRCode from "react-qr-code";
const QRcodeCustom = (props) => {
    const [qrRef] = useQRCode({
        text: props.txt,
        options: {
            level: "H",
            margin: 10,
            scale: 8,
            width: 180,
            color: {
                dark: "#000", //'#5868bfff',
                light: "#ffffffff",
            },
        },
    });
    return <canvas ref={qrRef}/>;
};
const Step1 = (props) => {
    return <div>{props.children}</div>;
};
const Step2 = (props) => {
    return <div>{props.children}</div>;
};
const Steper = (props) => {
    const [step1, setStep1] = useState(false);

    return (
        <div>
            {step1 && props.children.find(({type}) => type === Step1)}
            {!step1 && props.children.find(({type}) => type === Step2)}
        </div>
    );
};

const MFASettingsComponent = (props) => {
    const [step1, setQRcodeVisibility] = useState(false);
    const [mfaobj, setTemp1] = useState({});
    const [methodlist, setMethodList] = useState([]);
    const [pass, setPasswordVisibility] = useState(false);
    const [validotp, setValidotp] = useState(false);
    const [error, setError] = useState("");
    const password = React.useRef(null);
    const totp = React.useRef(null);
    const [methodvalue, setMethodvalue] = React.useState("");
    const [secret, setSecret] = useState("");
    const {id, UserActions, close, ...others} = props;

    const styles = {
        formElement: {
            width: 580,
            height: 510,
            position: "",
            // border : "1px solid black",
            // size: 30,
            fontFamily: "verdana",
        },
        formField: {
            width: 200,
            // fontsize:80,
            padding: "1rem",
        },
        header: {
            // width: 200,
            height: 50,
            fontWeight: "bolder",
            lineHeight: "50px",
            color: "#fff",
            marginTop: 0,
            fontSize: "large",
            background: "#7684c2",
        },
        label: {
            fontWeight: "bold",
            // fontSize : "medium",
        },
        value: {
            fontWeight: "bold",
            // fontSize : "large",
        },
        checkbox: {
            marginLeft: "5rem",
            fontSize: "largest",
        },
        btnstyle: {
            background: "#7684c2", color: "#fff", borderRadius: "0px", padding: "8px 14px"
        },
        btnstyledelete: {
            background: "#7684c2", color: "#fff", padding: "8px"
        }

    };

    const setMethod = (t) => {
        setMethodvalue(t.target.value.displayName)
        setPasswordVisibility(true);
        // console.log(t);
    };

    const onContinue = (e) => {
        let error = "";
        e.stopPropagation();
        setValidotp(false);
        if (methodvalue) {
            if (password.current.value !== "") {
                UserActions.requestMfaSecretKey(props.loggedinUser.userName, password.current.value, methodvalue).then((payload) => {
                    setTemp1(payload);
                    setQRcodeVisibility(true);
                    setSecret(payload.url);

                }).catch((error) => {
                    setError(error.message);
                    // console.log(error.message)
                });
            } else {
                error = "please enter password";
            }
        } else {
            error = "Select Method";
        }
        setError(error);
    };
    const resetMfa = async () => {
        var payload = {
            "userRoleId": props.userRoleId,
            "MFAMethod": "totp"
        }
        await UserActions.resetMfaSecret(payload);
        setMethodList([]);
        var payload2 = {
            "userRoleId": props.userRoleId
        }
        UserActions.getUser(payload2)
    };
    const onCheckOtp = debounce((e) => {
        let error = "";
        if (totp.current.value !== "") {
            let payload = {
                "mfaSecret": mfaobj.secretKey,
                "otp": totp.current.value,
            }
            UserActions.validateMFATOTP(payload).then((payload) => {
                if (payload.payload) {
                    setValidotp(true);
                } else {
                    setValidotp(false);
                    setError(payload.message);
                }
                // console.log(payload)
            })
        } else {
            error = "Please Enter OTP";
        }

        setError(error);
    }, 1000);

    const ActionCell = (props) => {
        const {dataItem} = props
        return (

            <td>
                <Button style={styles.btnstyledelete} look={"outline"}
                        onClick={resetMfa}
                        icon={"k-i-delete k-i-trash"}>DELETE</Button>
            </td>

        )
    }
    const onRegister = (e) => {
        e.stopPropagation();
        setError("");
        setMethodvalue("");
        UserActions.saveMfaSecret({
                "mfaSecret": mfaobj.secretKey,
                "qrURL": mfaobj.url,
                "mfaMethod": "totp"
            }
        ).then((res) => {
            var payload = {
                "userRoleId": props.userRoleId
            }
            UserActions.getUser(payload)
            setPasswordVisibility(false);
            setQRcodeVisibility(false);
        }).catch((error) => {
            setError(error.message);
            // console.log(error.message)
        });


    };
    useEffect(() => {
        if (props.loggedinUser.mfaStatus === true) {
            setMethodList([{
                method: props.loggedinUser.mfaMethods,
                keyname: "App Based 2FA",
                action: props.loggedinUser.mfaMethods
            }])
        }
    }, [props.loggedinUser])


    return (
        <div>
            <div style={styles.formElement}>
                <div>
                    <legend
                        className="boxHeader ah-draggable-header"
                        style={styles.header}
                    >
                        Multi Factor Authentication
                    </legend>
                </div>
                <div style={{marginTop: "20px", padding: "20px", fontWeight: "bold"}}>
                    {!step1 && (
                        <div>
                            <Grid data={methodlist}
                                  style={{
                                      width: 540,
                                  }}
                            >
                                <GridNoRecords className="mfa-label">No Method Enabled</GridNoRecords>

                                <GridColumn field="method" title="Method"/>
                                <GridColumn field="keyname" title="Keyname"/>
                                <GridColumn field="action" title="Action" cell={ActionCell}/>
                            </Grid>
                            <br/>
                            <form className="k-form k-form-horizontal">
                                <FieldWrapper>
                                    <Label style={{width: "50%"}} className="mfa-label" editorId={id}>Add Authentication
                                        Method: </Label>
                                    <div className="k-form-field-wrap">
                                        <DictionaryDropDownList
                                            onChange={setMethod}
                                            style={{height: "30px"}}
                                            code={Dictionary.AH_MFA_METHOD}
                                            idField={"dictRequestTypeId"}
                                            className={"k-checkbox"}
                                        />
                                    </div>
                                </FieldWrapper>

                                {pass && (
                                    <FieldWrapper>

                                        <Label style={{width: "50%"}} className="mfa-label" editorId={id}>Enter Password
                                            to continue: </Label>

                                        <div className=" k-form-field-wrap">
                                            <Input ref={password} type="password"
                                                   id="password"
                                            />
                                        </div>
                                    </FieldWrapper>

                                )}

                                <div className="row">
                                    {error != "" && <div className="k-error-colored">
                                        {error}
                                    </div>}
                                </div>
                            </form>
                            <div className="row">
                                <div className="col-12 center">
                                    <br/><br/>
                                    <Button type="button" className="k-button-custom" onClick={onContinue}
                                            style={styles.btnstyle}>
                                        Continue
                                    </Button>

                                    <Button type="button" className="k-button-custom" onClick={close}
                                            style={styles.btnstyle}>Close</Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {step1 && (
                        <div>
                            <div className="row">
                                <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={secret}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                                <div className="col-8 mfa-label">
                                    <div>
                                        <br/> <br/>
                                    </div>
                                    Scan QR code with your authenticator app to complete
                                    registration.
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mfa-label">
                                    <Label className="mfa-label" editorId={id}>Please enter 6 digit code from
                                        authenticator app: {validotp &&
                                            <span style={{color: "green"}}>Valid OTP</span>}</Label>

                                    <div className="col-6"></div>
                                    <div className={"col-5"}>
                                        <Input ref={totp}
                                               type={"text"}
                                               id="totp"
                                               onChange={onCheckOtp}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {error !== "" && <div className="k-error-colored">
                                    {error}
                                </div>}
                            </div>
                            <div className="row">
                                <div className="col-12">

                                    {/* <Button type="button" onClick={onCheckOtp} style={styles.btnstyle}>
                    Check totp
                  </Button> */}


                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 center">
                                    <Button type="button" className="k-button-custom" onClick={onRegister}
                                            style={styles.btnstyle}>
                                        Register
                                    </Button>
                                    <Button type="button" className="k-button-custom" onClick={close}
                                            style={styles.btnstyle}>Cancel</Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        loggedinUser: state.user.loggedinUser,
        userRoleId: state.auth.session.userRoleId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        UserActions: bindActionCreators(UserActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MFASettingsComponent);
