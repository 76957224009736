import {connect} from "react-redux";
import React from "react";
import {Button} from "primereact/button";
import TileHeader from "../../layout/TileHeader";
import Radium from 'radium'
import {PatientAction} from "../../action/PatientAction";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../config/GlobalConfig";
const styles = {
    titleh5: {
        margin: '10px 10px',
        padding: '0',
        color: '#888a8e',
        fontSize: '22px',
        float: 'left',
    }
}

const AdvanceDirectiveComponent = (props) => {
    const {advanceDirective, PatientActions, patientContext} = props

    let lastUpdateDate = advanceDirective[0]?.startDate

    React.useEffect(() => {
        if (patientContext?.patientRoleId)
            PatientActions.getAdvanceDirective(patientContext.patientRoleId)
    }, [])

    return (
        // AH_VIEW_ADVANCE_DIRECTIVE
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_ADVANCE_DIRECTIVE]}>
                <TileContainer>
                    <TileHeader title={"Advance Directive"}/>
                    <TileBody>
                        {(lastUpdateDate !== undefined && lastUpdateDate.length > 0) ?
                            <React.Fragment>
                                <div>
                                    {advanceDirective.map(d => <Button key={d.id}
                                                                       style={styles.textBtn}>{d.dictDirectiveTypeDisplayName}</Button>)}
                                </div>
                                <p style={{
                                    textAlign: 'right',
                                    padding: '0 5px',
                                    margin: '0 15px',
                                    fontSize: '12px'
                                }}> Last
                                    Updated - {lastUpdateDate} </p>
                            </React.Fragment>
                            :
                            <span style={{...styles.titleh5}}> No Advance Directive </span>

                        }

                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}

AdvanceDirectiveComponent.propTypes = {
    PatientActions: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        patientContext: state.patient.patientContext,
        advanceDirective: state.patient.advanceDirective,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(AdvanceDirectiveComponent));
//export default connect()(Radium(AdvanceDirectiveComponent))