import {ActionTypes} from "../action/SchedulerAction";
import {baseData} from "../components/mock/events-utc";


const initialState = {
    schedule: baseData,
    scheduleData:[],
    scheduleDataByUser:[],
    patientScheduleData:[],
    scheduleContext: null,
    schedulerFilters: {
        showAllEvents: "show_all_events",
        filterFacility: null,
        filterProvider: null,
        filterPatient : null,
        isFilterApplied : false,
        filterByText : null,
        isClear : false
    }
}

export const scheduleReducer = (state = initialState, action) =>
{
    switch (action.type) {
        case ActionTypes.GET_SCHEDULE:
            return {
                ...state,
                scheduleData: action.payload.payload
            }
        case ActionTypes.GET_SCHEDULE_BY_USER:
            return {
                ...state,
                scheduleData:action.payload.payload
            }
        case ActionTypes.GET_PATIENT_SCHEDULE:
            return{
                ...state,
                patientScheduleData:action.payload.payload
            }
        case ActionTypes.SET_SCHEDULER_FILTERS:
            return {...state, schedulerFilters: {...state.schedulerFilters, [action.payload.key]: action.payload.value}}
        case ActionTypes.CREATE_SCHEDULE:
            return {...state, scheduleData: action.payload.payload}
        case ActionTypes.UPDATE_SCHEDULE:
            return {...state, scheduleData: action.payload.payload}
        case ActionTypes.UPDATE_SCHEDULE:
            return {...state, scheduleData: action.payload.payload}
        case ActionTypes.SET_SCHEDULER_CONTEXT:
            return {...state, scheduleContext: action.payload}
        default:
            return state;
    }

}

