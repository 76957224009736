import React, {useEffect} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {AuthAction} from "./action/AuthAction";
import PropTypes from "prop-types";
import MainPage from "./pages/MainPage";
import 'react-grid-layout/css/styles.css'
import Radium from 'radium'
import LockScreen from "react-lock-screen";
import {Button} from "primereact/button";
import Loading from 'react-fullscreen-loading';
import {Colors} from "./config/GlobalConfig";
import {ConfigurationActions} from "./action/ConfigurationAction";
import {LoadingActions} from "./action/LoadingAction";

function App(props) {
    const [seconds, setSeconds] = React.useState(-1)
    const [loading, setLoading] = React.useState(true)
    const dispatch = useDispatch()
    const loader = useSelector(state => state.loading)
    const authenticated = useSelector(state => state.auth.authenticated)

    useEffect(() => {
        if (loader["MainApp"] === true && loading === false) setLoading(true)
        if (loader["MainApp"] === false && loading === true) setLoading(false)
    }, [loader])
    React.useEffect(() => {
        ConfigurationActions.getTenantConfiguration()(dispatch).then(r => {
            LoadingActions.setComponentDoneLoading("MainApp")(dispatch)
            // setLoading(false)
        })
    }, [])

    React.useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                clearInterval(myInterval)
                setSeconds(-1)
                AuthAction.logout()(dispatch)
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    const getLockScreenUi = setLock => {
        return (
            <div className="react-lock-screen__ui">
                <img
                    width="32"
                    src="https://cdn3.iconfinder.com/data/icons/wpzoom-developer-icon-set/500/102-256.png"
                    alt="lock"
                />
                <p>User is idle. If you are still working please click unlock. Otherwise you will be logged out
                    in {seconds} seconds.</p>
                <Button icon={"unlock"} onClick={() => setLock(false)}>Unlock</Button>
            </div>
        );
    };

    if (loading) return <Loading loading
                                 background={Colors.LIGHT_GRAY}
                                 loaderColor={Colors.RED}/>
    else return (
        <div>

            {/*TODO: Make this configurable*/}
            {authenticated === true ?
                <LockScreen onScreenUnlocked={() => setSeconds(-1)} onScreenLocked={() => setSeconds(60)}
                            timeout={1800000}
                            ui={getLockScreenUi}>
                    <MainPage/>
                </LockScreen> :
                <MainPage/>
            }


        </div>
    );

}

App.propTypes = {
    authActions: PropTypes.object,
}


export default Radium(App)
