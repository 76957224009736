import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Col, Container, Row} from "react-grid-system";
import {Button} from "primereact/button";
import {DictionaryTypeAction} from "../../action/DictionaryTypeAction";
import {PermissionAction} from "../../action/PermissionAction";
import {Grid, GridColumn,} from "@progress/kendo-react-grid";
import {InlineTextInput} from "../InputControls/InlineFields";
import PermissionsDropDownList from "../InputControls/PermissionsDropdownList";
import DictionaryDropDownList, {Dictionary,} from "../InputControls/DictionaryDropDownList";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {Checkbox} from "@progress/kendo-react-inputs";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../config/GlobalConfig";
import {Slide} from "@progress/kendo-react-animation";

/*const CareTeamDropDown = (props) =>
        <InlineDictionaryDropDown
            {...props}
            dataItem={props.dataItem}
            idField={"id"}
            field={"careTeam"}
            displayField={"displayName"}
            code={""} />

const PatientPanelDropDown = (props) =>
        <InlineDictionaryDropDown
            {...props}
            dataItem={props.dataItem}
            idField={"id"}
            field={"patientPanel"}
            displayField={"displayName"}
            code={""} />

const PatientListDropDown = (props) =>
        <InlineDictionaryDropDown
            {...props}
            dataItem={props.dataItem}
            idField={"id"}
            field={"patientList"}
            displayField={"displayName"}
            code={""} />*/

const EditCommandCell = (props) => {
    const {dataItem} = props;
    const inEdit = dataItem[props.editField];
    return inEdit ? (
        <td className="k-command-cell">
            <Button icon={"pi pi-ban"}
                    text
                    severity={"danger"}
                    onClick={() => props.cancel(dataItem)}/>
        </td>
    ) : (
        <td className="k-command-cell">
            <Button icon={"pi pi-pencil"} onClick={() => props.edit(dataItem)} text severity={"success"}/>
        </td>
    );
};
const UserPermissionComponent = (props) => {
    const dispatch = useDispatch()

    const [dataItem, setDataItem] = React.useState(null);
    const [permissions, setPermissions] = React.useState(null);
    const [category, setCategory] = React.useState(null);
    const [permissionType, setPermissionType] = React.useState(null);
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        setCategory(props.category)
    }, [props.category]);

    React.useEffect(() => {
         setDataItem(props.dataItem?.permissions.sort())
    }, [props.dataItem]);

    React.useEffect(() => {
        if (category?.id && dataItem)
            setPermissions(dataItem?.filter(v=> v.permissionCategoryId && v.permissionCategoryId === category.id).sort())
    }, [category && dataItem]);

    const onChangeCohortDictionary = (obj) => {
        setPermissionType(obj.value);
    }

    const addNewPermission = async () => {
        if (permissionType) {
            let payload = {
                permissionId: permissionType.id,
                permissionGroupId: props.dataItem.permissionGroupId,
            }
            await PermissionAction.assignPermissionToPermissionGroup(payload)(dispatch)
            await PermissionAction.getAllPermissionGroups()(dispatch)
        }
    }
    const removePermission = async (obj) => {
        await PermissionAction.removePermissionFromPermissionGroup(obj.permissionId, obj.permissionGroupId)(dispatch)
        await PermissionAction.getAllPermissionGroups()(dispatch);
    }

    const childFactory = (child) => {
        if (!child.props.in) {
            return null;
        }
        return child;
    };

    const slideElements = () => {
        setShow(!show);
    };

    return <div>
            <br></br>
            <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_PERMISSION]}>
                <Row>
                    <Col style={{marginLeft:"1%"}} sm={0.5}>
                    {
                        show ?  <span onClick={slideElements} className="k-icon k-i-arrow-60-down" style={{fontSize: 25, paddingRight: 10}}/>
                            : <span onClick={slideElements} className="k-icon k-i-arrow-60-right"
                                  style={{fontSize: 25, paddingRight: 10}}/>
                    }
                    </Col>
                    <Col sm={1}>
                        <span>{category?.displayName}</span>
                    </Col>
                    <Col style={{textAlign: "left"}}>
                        <PermissionsDropDownList
                            style={{width: "50%"}}
                            value={permissionType}
                            textField={"displayName"}
                            code={category?.code}
                            onChange={onChangeCohortDictionary}/>
                        <span style={{marginLeft: "3%"}} class="k-icon k-i-plus-circle" onClick={addNewPermission}></span>
                    </Col>
                </Row>
            </ShowForPermissionComponent>
            <br></br>
            <Row>
                <Col style={{textAlign: "left", marginLeft: "2%"}}>
                    <Slide childFactory={childFactory} transitionExitDuration={1500}>
                        <div>
                        {
                            show && permissions && permissions.map((o, i) =>
                                <Button obj={o}
                                        title={o?.permissionDescription}
                                        style={{backgroundColor: "#B4A7D6", marginBottom: 5, marginRight: 5}}
                                        className="buttons-container-button"
                                        onClick={() => removePermission(o)}
                                        icon="pi pi-ban"
                                        label={o?.permissionDisplayName}/>
                            )
                        }
                        </div>
                    </Slide>
                </Col>
            </Row>
    </div>;
}
const ManagePermissionComponent = (props) => {
    const adminPermission = useSelector(state => state.permission.adminPermissions)
    const dictionaryTypes = useSelector(state => state.dictionaryType.dictionaryTypeList)
    const dispatch = useDispatch()
    const [data, setData] = React.useState([]);
    const permissionCategories = useSelector(state => state.permission.permissionCategories)
    const editField = "inEdit";
    const expandedField = "expanded";

    React.useEffect(() => {
        PermissionAction.getAllPermissionGroups()(dispatch)
        DictionaryTypeAction.getDictionaryTypeList(1000, 0)(dispatch)
        PermissionAction.getPermissionCategoriesByCode("AH_PERMISSION_CATEGORY")(dispatch);
    }, []);

    React.useEffect(() => {
        if (adminPermission?.length > 0) {
            setData(adminPermission.map(v => {
                const isExist = data.find(d => d.id === v.permissionGroupId);
                if (isExist) {
                    return {...v, id: v.permissionGroupId, expanded: isExist.expanded, inEdit: isExist.inEdit}
                } else {
                    return {...v, id: v.permissionGroupId, expanded: false, inEdit: false};
                }
            }));
        }
    }, [adminPermission]);

    const enterEdit = (dataItem) => {
        let newData = data.map((item) =>
            item.id === dataItem.id ? {...item, inEdit: true, expanded: true} : item
        );
        setData(newData);
    };

    const itemChange = (event) => {
        const field = event.field || "";
        const newData = data.map((item) => {
            if (item.id === event.dataItem.id) {
                if (item.errors && item.errors[field]) {
                    delete item.errors[field];
                }
                return {...item, [field]: event.value};
            } else {
                return item;
            }
        });
        setData(newData);
    };

    const cancel = (dataItem) => {
        let newData = data.map((item) =>
            item.id === dataItem.id ? {...item, inEdit: false, expanded: false} : item
        );
        setData(newData);
    }

    const CommandCell = (props) => {
        return (
            <EditCommandCell
                {...props}
                editField={editField}
                cancel={cancel}
                // remove={remove}
                // update={update}
                // add={add}
                edit={enterEdit}
            />
        );
    };

    const columnTitle = (title) => {
        return <span style={{fontSize: "15px"}}>{title}</span>
    }

    const AddCodeWindow = async () => {
        const permissionGroupType = dictionaryTypes.find(v => v.code === "AH_PERMISSION_GROUP");
        if (permissionGroupType) {
            await PopupWindowActions.setWindowState(PopupWindows.ADD_CODE_WINDOW, {selectedDictionaryType: permissionGroupType})(dispatch)
            await PopupWindowActions.showWindow(PopupWindows.ADD_CODE_WINDOW)(dispatch)
        }
    };

    const togglePermissionGroup = async (e, p) => {
        await PermissionAction.toggleActivePermissionGroup(p.dataItem.permissionGroupId, e.target.value)
        await PermissionAction.getAllPermissionGroups();
    }

    const isPermissionGroupActive = (props) => {
        return <td>
            <Checkbox
                id={props.dataItem.active}
                disabled={!props.dataItem.inEdit}
                onChange={(e) => {
                    togglePermissionGroup(e, props)
                }}
                value={props.dataItem.active}/>
        </td>
    }

    return <ShowForPermissionComponent component permissions={[PhixPermissions.AH_ADMIN_VIEW_PERMISSION]}>
        <Container style={{height: "100%", width: "100%"}}>
            <Row style={{padding: '1%'}}>
                <Col className={"flex-grow-1"}>
                    <span style={{fontSize: "18px"}}>User Role Management</span>
                </Col>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_PERMISSION]}>
                    <Col col={1} className={"flex-grow-0"}>

                        <Button icon={"pi pi-plus"} onClick={AddCodeWindow} tooltip={"Add new permission group"} text>
                        </Button>

                    </Col>
                </ShowForPermissionComponent>
            </Row>
            <div style={{margin: 0, overflow: "auto", height: "calc(100vh - 200px)"}}>
                {data && <Grid
                    className="admin-user-management"
                    style={{height: "100%"}}
                    expandField={expandedField}
                    editField={editField}
                    onItemChange={itemChange}
                    rowRender={(trElement, rowData) => {
                        return [trElement,
                            rowData.dataItem.expanded &&
                            <tr>
                                <td colSpan={rowData.children.length} style={{paddingLeft: 0}}>
                                    {
                                        permissionCategories && permissionCategories.map(cat=> {
                                            return <UserPermissionComponent category={cat} dataItem={rowData.dataItem}/>
                                        })
                                    }
                                </td>
                            </tr>];
                    }}
                    sortable={true}
                    data={data}>
                    <GridColumn field="permissionGroupDisplayName" title={columnTitle("Permission Group")}
                                cell={InlineTextInput}/>
                    {/*<GridColumn title={columnTitle("Care Team Workspace")} cell={CareTeamDropDown} />
                        <GridColumn title={columnTitle("Patient Panel")} cell={PatientPanelDropDown} />
                        <GridColumn title={columnTitle("Patient List")} cell={PatientListDropDown} />*/}
                    <GridColumn title={columnTitle("Active")} field={"active"} cell={isPermissionGroupActive}
                                width={80}/>
                    <GridColumn title={columnTitle("Actions")} cell={CommandCell} width={80}/>
                </Grid>}
            </div>
        </Container>
    </ShowForPermissionComponent>

}


export default ManagePermissionComponent
