import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import {PopupWindowActions as PopupActions, PopupWindows} from "../../action/PopupWindowAction";
import {
    Field,
    FieldDatePicker,
    FieldDictionaryDropDown,
    FieldMultiDictionarySelect,
    FieldNumberInput,
    FieldRow,
    FieldSection,
    FieldTextAreaInput,
    FieldTextInput
} from "../InputControls/FormFields";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";

const CreateQueryPanelWindow = (props) => {
    const dispatch = useDispatch();
    const showWindow = useSelector(state => state.popupWindow.showWindow,)

    const hideDialog = async () => {
        await PopupActions.hideWindow(PopupWindows.CREATE_QUERY_PANEL_WINDOW)(dispatch)
    }
    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        watch,
        formState: {errors},
    } = useForm();


    if (showWindow[PopupWindows.CREATE_QUERY_PANEL_WINDOW])
        return (
            <Window title={"Query Panel Editor"} onClose={hideDialog} initialWidth={800} initialHeight={1000}
                    modal={true}>
                <FieldSection>
                    <FieldRow>
                        <Field col={12} label={"Query Title"} field={"title"} error={errors}
                               register={register}
                               control={control}>
                            <FieldTextInput/>
                        </Field>
                    </FieldRow>

                    <FieldRow style={{marginTop: 10}}>
                        <Field col={12} label={"Description"} field={"description"} error={errors}
                               register={register}
                               control={control}>
                            <FieldTextAreaInput/>
                        </Field>
                    </FieldRow>

                    <FieldRow style={{marginTop: 10}}>
                        <Field col={6} label={"Trial Start Date"} field={"trialStart"} error={errors}
                               register={register}
                               control={control}>
                            <FieldDatePicker/>
                        </Field>
                        <Field col={6} label={"Trial Phase"} field={"trialPhase"} error={errors}
                               register={register}
                               control={control}>
                            <FieldDictionaryDropDown code={"AH_TRIAL_PHASE"}/>
                        </Field>
                    </FieldRow>
                    <FieldRow style={{marginTop: 10}}>
                        <Field col={6} label={"Gender"} field={"gender"} error={errors}
                               register={register}
                               control={control}>
                            <FieldDictionaryDropDown code={"AH_GENDER"}/>
                        </Field>
                        <Field col={3} label={"Min Age (years)"} field={"minAge"} error={errors}
                               register={register}
                               control={control}>
                            <FieldNumberInput/>
                        </Field>
                        <Field col={3} label={"Max Age (years)"} field={"maxAge"} error={errors}
                               register={register}
                               control={control}>
                            <FieldNumberInput/>
                        </Field>
                    </FieldRow>
                    <br/>
                    <h6>Conditions</h6>
                    <FieldRow style={{marginTop: 10}}>
                        <Field col={6} label={"Condition (Include)"} field={"hasConditions"} error={errors}
                               register={register}
                               control={control}>
                            <FieldMultiDictionarySelect code={"ICD10CM"}/>
                        </Field>
                        <Field col={6} label={"Condition (Exclude)"} field={"doesNotHaveConditions"} error={errors}
                               register={register}
                               control={control}>
                            <FieldMultiDictionarySelect code={"ICD10CM"}/>
                        </Field>
                    </FieldRow>
                    <FieldRow style={{marginTop: 10}}>
                        <Field col={6} label={"Medication (Include)"} field={"hasMedications"} error={errors}
                               register={register}
                               control={control}>
                            <FieldMultiDictionarySelect code={"AH_RXNORM"}/>
                        </Field>
                        <Field col={6} label={"Medication (Exclude)"} field={"doesNotHaveMedications"} error={errors}
                               register={register}
                               control={control}>
                            <FieldMultiDictionarySelect code={"AH_RXNORM"}/>
                        </Field>
                    </FieldRow>

                </FieldSection>
                <WindowActionsBar>
                    <Button>
                        Save
                    </Button>
                    <Button onClick={hideDialog}>
                        Cancel
                    </Button>

                </WindowActionsBar>
            </Window>
        );
    else
        return null;
}


export default CreateQueryPanelWindow;