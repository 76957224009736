import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {PatientAction} from "../../action/PatientAction";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {Button} from "primereact/button";
import {Col} from "react-grid-system";
import {Dialog} from "primereact/dialog";
import {PermissionsTool} from "../../util/PermissionsTool";
import {
    FormColumn,
    FormContainer,
    FormDateSelector,
    FormDictionaryDropdown,
    FormMultiDictionaryDropdown,
    FormRow,
    FormTextInput
} from "../InputControls/NewFormFields";
import {Row} from "../../layout/GridLayout";
import {useForm} from "react-hook-form";

const defaultValues = {
    id: null,
    dictProblemStatusId: null,
    admissionDate: null,
    dischargeDate: null,
    description: null,
    surgicalDisplayName: null,
    surgicalCode: [],
    displayName: null,
    diagnosisCode: [],
    dictSourceOfInfoId: null,
    note: null
}
const AddSurgeriesWindow = (props) => {
    const dispatch = useDispatch()
    const surgeryContext = useSelector(state => state.popupWindow.state?.[PopupWindows.ADD_SURGERIES_WINDOW])
    const showWindow = useSelector(state => state.popupWindow.showWindow)
    const permissionInfo = useSelector(state => state.auth.session.permissions)
    const patientContext = useSelector((state) => state.patient.patientContext)

    const form = useForm({defaultValues: defaultValues});
    const {
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch,
    } = form
    const [hospitalizationPermission, setHospitalizationPermission] = React.useState({isEdit: false, isView: false})

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'diagnosisCode') {
                let v = value?.diagnosisCode?.map(d => d.code).join("; ")
                let des = value?.diagnosisCode?.map(d => d.displayName).join("; ")
                if (v && v.length > 0) setValue("displayName", v)
                if (des && des.length > 0) setValue("description", des)
            }
            if (name === 'surgicalCode') {
                let v = value?.surgicalCode?.map(d => d.description).join("; ")
                if (v && v.length > 0) setValue("surgicalDisplayName", v)
            }
        })
        return () => subscription.unsubscribe();
    }, [watch]);

    React.useEffect(() => {
        setHospitalizationPermission(PermissionsTool({
            permissionInfo: permissionInfo,
            viewPermissions: ["AH_VIEW_HOSPITALIZATION"],
            editPermissions: ["AH_EDIT_HOSPITALIZATION"]
        }));
    }, [permissionInfo]);

    const hideDialog = async () => {
        await PopupWindowActions.hideWindow(PopupWindows.ADD_SURGERIES_WINDOW)(dispatch)
        await PatientAction.setSurgeryContext({})(dispatch)
        reset(defaultValues)
    };
    React.useEffect(() => {
        if (surgeryContext) {
            reset(surgeryContext)
        }
    }, [surgeryContext])

    async function saveSurgery(form) {
        if (form?.id) {
            let savePayload = {
                surgeryId: form.id,
                clinicalStatus: form?.dictProblemStatusId?.id ? form?.dictProblemStatusId?.id : form?.dictProblemStatusId,
                displayName: form?.displayName,
                surgicalDisplayName: form?.surgicalDisplayName,
                description: form?.description,
                admissionDate: form?.admissionDate,
                dischargeDate: form?.dischargeDate,
                createdByUserRoleId: localStorage.getItem('userRoleId'),
                patientRoleId: patientContext.patientRoleId,
                dictSourceOfInfoId: form?.dictSourceOfInfoId?.id ? form?.dictSourceOfInfoId?.id : form?.dictSourceOfInfoId,
                outcome: form?.outcome?.id ? form?.outcome?.id : form?.outcome,
                note: form?.note ? form?.note : null,
                diagnosisCode: form?.diagnosisCode ? form?.diagnosisCode : [],
                surgicalCode: form?.surgicalCode ? form.surgicalCode : []
            };
            if (!form?.diagnosisCode?.length > 0) {
                delete savePayload.diagnosisCode;
                delete savePayload.description;
                delete savePayload.displayName
            }
            if (form.dischargeDate === null) {
                delete savePayload.dischargeDate
            }
            if (form.note === null) {
                delete savePayload.note
            }
            //console.log(savePayload)
            await PatientAction.updateSurgery(savePayload)(dispatch)
            hideDialog()
        } else {
            let savePayload = {
                clinicalStatus: form?.dictProblemStatusId?.id,
                displayName: form?.displayName,
                surgicalDisplayName: form?.surgicalDisplayName,
                description: form?.description,
                admissionDate: form?.admissionDate ? form?.admissionDate : null,
                dischargeDate: form?.dischargeDate ? form?.dischargeDate : null,
                createdByUserRoleId: localStorage.getItem('userRoleId'),
                patientRoleId: patientContext.patientRoleId,
                dictSourceOfInfoId: form?.dictSourceOfInfoId?.id,
                outcome: form?.outcome?.id,
                note: form?.note,
                diagnosisCode: form?.diagnosisCode,//codes,
                surgicalCode: form?.surgicalCode
            };
            if (!form?.diagnosisCode?.length > 0) {
                delete savePayload.diagnosisCode;
            }
            await PatientAction.createSurgery(savePayload)(dispatch)
            hideDialog()
        }

    }

    const canEditable = () => {
        if (hospitalizationPermission.isEdit) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <Dialog
            header={surgeryContext?.id ? "Edit Surgery" : "Add Surgery"}
            onHide={hideDialog}
            maximizable
            footer={<Row className={"justify-content-end"}>
                <Button severity={"success"}
                        onClick={handleSubmit(saveSurgery)}>
                    {surgeryContext?.id ? "Update" : "Save"}
                </Button>
                <Button onClick={hideDialog} severity={"danger"}>
                    Cancel
                </Button>
            </Row>}
            style={{width: 800, height: 800}}
            visible={showWindow[PopupWindows.ADD_SURGERIES_WINDOW]}>
            <div style={{pointerEvents: canEditable() ? 'block' : 'none'}}>
                <FormContainer form={form}>
                    <FormRow>
                        <Col sm={6}/>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Clinical Status"} field={"dictProblemStatusId"}
                                rules={{required: "Clinical Status is required."}}
                                code={Dictionary.PROBLEM_STATUS}
                                defaultItemCode={"FHIR_PROBLEM_ACTIVE"}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormMultiDictionaryDropdown
                                showCode={true}
                                fullObject
                                label={"Diagnosis Codes"} field={"diagnosisCode"}
                                code={Dictionary.ICD10} useIndex/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormMultiDictionaryDropdown
                                fullObject
                                showCode={true}
                                label={"Surgical Procedure"}
                                field={"surgicalCode"}
                                code={Dictionary.AH_CPT} useIndex
                                rules={{required: "Surgical Procedure is required."}}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDateSelector showTime
                                              label={"Admission Date"}
                                              dateFormat={null}
                                              field={"admissionDate"}/>
                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDateSelector showTime
                                              label={"Discharge Date"}
                                              dateFormat={null}
                                              field={"dischargeDate"}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Source of Info"} field={"dictSourceOfInfoId"}
                                code={Dictionary.AH_CONDITION_SOURCE}
                                defaultItemCode={"AH_CONDITION_SOURCE"}
                            />
                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Outcome"} field={"outcome"}
                                code={Dictionary.AH_OUTCOMES}
                                defaultItemCode={"AH_OUTCOMES"}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow style={{marginTop: 10}}>
                        <FormColumn col={12}>
                            <FormTextInput label={"Notes"} field={"note"}/>
                        </FormColumn>
                    </FormRow>
                </FormContainer>
            </div>
        </Dialog>);
};

// AddSurgeriesWindow.propTypes = {
//     showWindow: PropTypes.object,
//     DictionaryActions: PropTypes.object,
//     DictionaryTypeAction: PropTypes.object,
//     PopupActions: PropTypes.object,
//     PatientActions: PropTypes.object,
//     createPatientContext: PropTypes.object,
//     patient: PropTypes.object,
// };
//
// function mapStateToProps(state) {
//     return {
//         showWindow: state.popupWindow.showWindow,
//         createPatientContext: state.patient.createPatientContext,
//         contactAddress: state.person.contactAddress,
//         patient: state.patient.patientContext,
//         surgeriesContext: state.patient.surgeryContext,
//         popupState: state.popupWindow.state,
//         permissionInfo: state.auth.session.permissions
//     };
// }
//
// function mapDispatchToProps(dispatch) {
//     return {
//         PatientActions: bindActionCreators(PatientAction, dispatch),
//         PersonActions: bindActionCreators(PersonActions, dispatch),
//         PopupActions: bindActionCreators(PopupWindowActions, dispatch),
//     };
// }

export default AddSurgeriesWindow
