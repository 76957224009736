import {useDispatch, useSelector} from "react-redux";
import * as React from "react";
import {useEffect, useState} from "react";
import {LaboratoryAction} from "../../../action/LaboratoryAction";
import {PhixPermissions} from "../../../config/GlobalConfig";
import {Grid, GridColumn, GridToolbar} from "@progress/kendo-react-grid";
import {filterBy} from "@progress/kendo-data-query";
import ShowForPermissionComponent from "../../shared/ShowForPermissionComponent";
import {Button} from "primereact/button";
import {PopupWindowActions as PopupActions, PopupWindows} from "../../../action/PopupWindowAction";

const VendorAccountComponent = (props) => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.laboratory.installedRoutes);
    const [filter, setFilter] = useState({logic: "and", filters: []})
    const [gridData, setGridData] = useState([])

    useEffect(() => {
        if (data?.length)
            setGridData(data)
    }, [data])

    useEffect(() => {
            LaboratoryAction.getInstallRoutes().then((resp) => {
                dispatch(resp)
            })
        }, []
    )

    return (
        <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_ADMIN_VIEW_LABS]}>
            <Grid style={{height: "100%"}}
                  data={filterBy(gridData, filter)}
                  filterable
                  filter={filter}
                  onFilterChange={(e) => setFilter(e.filter)}
                  sortable={true}>
                <GridToolbar>
                    <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_LABS]}>
                        <Button icon={"plus"}
                                onClick={() => PopupActions.showWindow(PopupWindows.ADD_LAB_ROUTE_WINDOW)(dispatch)}>Create New Route</Button>
                    </ShowForPermissionComponent>
                    {/*<Input*/}
                    {/*    style={{width: "50%"}}*/}
                    {/*    placeholder={"Search"}*/}
                    {/*    id={"input-id"}*/}
                    {/*    // value={searchState}*/}
                    {/*    // onChange={(e) => setSearchState(e.value)}*/}
                    {/*/>*/}
                </GridToolbar>
                <GridColumn field={"dictLabVendorDisplayName"} title={"Lab Name"}/>
                <GridColumn field={"accountNumber"} title={"Account Number"}/>
                <GridColumn field={"routeType"} title={"Route Type"}/>
            </Grid>
        </ShowForPermissionComponent>
    )
}


export default VendorAccountComponent