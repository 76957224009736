import PropTypes from "prop-types";
import React from 'react';

const ErrorMessage = (props) => {
    const {
        errors=[],
        style={padding: "5px",
               textAlign: "center"
               }
    }= props;
    const [hasError, sethasError] = React.useState(false);

    React.useEffect(() => {
        let occured=false
    for(let i=0;i<errors.length;i++){
        if(errors[i] && errors[i]!=""){
            occured=true;
        }}
        sethasError(occured)
      }, props.errors);
   if(hasError)
   return <div style={style}>{
        errors.map((error)=>
        <div>{error}</div>)
   }</div>
   else
    return props.children;
    }

ErrorMessage.propTypes = {
    errors: PropTypes.array,
    style: PropTypes.object
};
    

export default ErrorMessage;    