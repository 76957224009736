import {ActionTypes} from "../action/MessageAction";


const initialState = {
    channelContext: {},
    messages: [],
    subscribedChannels: [],
}

export const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.MESSAGE_GET_SUBSCRIBED_CHANNELS:
            return {...state, subscribedChannels: action.payload.payload}
        case ActionTypes.MESSAGE_SET_CHANNEL_CONTEXT:
            return {...state, channelContext: action.payload?.payload ? action.payload.payload : action.payload}
        case ActionTypes.MESSAGE_CLEAR_CHANNEL_CONTEXT:
            return {...state, channelContext: {}}
        case ActionTypes.MESSAGE_GET_MESSAGE:
            return {...state, messages: action.payload.payload}
        default:
            return state
    }

}

