import {Container} from "./GridLayout";

export const TileContainer = (props) => {

    return (
        <Container style={{height: "calc(100% - 35px)", width: "100%", margin: 0, padding: 0}}>
            {props.children}
        </Container>
    )


}