import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {AuthAction} from "../action/AuthAction";
import {LayoutAction} from "../action/LayoutAction";
import {connect, useSelector} from "react-redux";
import {MessageActions} from "../action/MessageAction";
import React, {useEffect, useRef, useState} from "react";
import {Badge} from "primereact/badge";
import {Avatar} from "@progress/kendo-react-layout";
import {AutoComplete} from "@progress/kendo-react-dropdowns";
import {MdSend} from "react-icons/md";
import moment from 'moment';
import TileHeader from "../layout/TileHeader";
import {Components} from "./shared/ComponentRegistry";
import {Col, ColumnLayout, Container, Row} from "../layout/GridLayout";
import ShowForPermissionComponent from './shared/ShowForPermissionComponent';
import {TileContainer} from "../layout/TileContainer";
import {TileBody} from "../layout/TileBody";
import GlobalStyles from "../config/GlobalStyles";
import {PhixPermissions} from "../config/GlobalConfig";
import {Menu} from "primereact/menu";
import {Button} from "primereact/button";


// ['Show All', 'Patient Messages Only', 'My Mentions only', 'Sort Earliest to Latest', 'Sort Latest to Earliest'];

const getRandomColorCode = () => {
    // const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    // return "#" + randomColor;
    return "#FF4500";
}

const styles = {

    chatWrapper: {
        width: "100%",
        position: 'relative',
        // height: "calc(100% - 177px)",
        paddingTop: '35px',
        paddingBottom: '115px'
    },
    chatBody: {
        minHeight: '30vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(100% - 110px)',
        margin: '0',
    },
    chatFooter: {
        position: "absolute",
        bottom: '0',
        width: '100%',
        background: '#FFFFFF',
    },
    hidden: {
        display: 'none'
    },
    messageBlock: {
        borderBottom: '1px solid #ece7e7',
        paddingBottom: '0',
        paddingTop: '0px',
    },
    messageAvatar: {
        border: '2px solid #ffd966',
        width: '25px', height: '25px',
        flexBasis: "25px",
        backgroundColor: "#fff",
        marginRight: "5px"
    },
    messageBlockDate: {
        backgroundColor: 'rgb(220, 224, 238)',
        padding: '4px 5px',
        width: '100%',
        height: "40px",
        float: 'left',
    }
}

// const {
//     Bold,
//     Italic,
//     Underline,
//     Strikethrough,
//     OrderedList,
//     Undo,
//     InsertImage,
//
// } = EditorTools;

const renderTags = (element1, mainMessage) => {
    let tempMessage = mainMessage;
    const {message, mentions} = element1;
    mentions && mentions.length > 0 && mentions.forEach(tag => {
        if (message.includes(tag.value)) {
            tempMessage = tempMessage.replace(tag.value, `<a href="#" style="color: #7585c2;
      fontWeight: 600;
      textDecoration: underline">${tag.value}</a>`)
        }
    });

    return tempMessage;
};

const EditorControl = (props) => {
    const [editorVal, setEditorVal] = useState('');
    const [autocompleteVal, setAutoCompleteVal] = useState('');
    const [currentMention, setCurrentMention] = useState('');
    const [selectedMentions, setSelectedMentions] = useState([]);

    // const [isSendMsg, setSendMsg] = useState('');
    const autoCompleteRef = React.createRef();
    const editorRef = React.createRef();

    const onEditorChange = (event) => {
        setEditorVal(event.html);
        const pattern = /(@|#)([A-Za-z]*)/g
        const result = event.html.match(pattern)
        if (result && result.length > 0) {
            const selectedMentionsFlatList = selectedMentions.map(item => item.value);
            const found = result.find(item => !selectedMentionsFlatList.includes(item))
            setCurrentMention(found);
            setAutoCompleteVal(found)

            const updatedMentions = selectedMentions.filter(item => result.includes(item.value));
            setSelectedMentions(updatedMentions);
        }

    }

    const onEditorMouseDown = (value) => {
    }

    useEffect(() => {
        if (autoCompleteRef.current) {
            autoCompleteRef.current.focus();
        }
    }, [currentMention]);

    const handleChange = (event) => {
        setAutoCompleteVal(event.value);


        if (editorRef.current) {
            const view = editorRef.current.view;
            // if (view) {
            //     const editorHtml = EditorUtils.getHtml(view.state);
            //     setEditorVal(editorHtml.replace(currentMention, event.value));
            // }
        }

        setCurrentMention(event.value);
    };

    const handleAutoCompleteClose = (event) => {
        if (editorRef.current) {
            const view = editorRef.current.view;
            if (view) {
                // const editorHtml = EditorUtils.getHtml(view.state);
                // // const mentionPrefix = mention.startsWith('@') ? '@' : '#';
                // // <a href="#" style="color: #7585c2; fontWeight: 600; textDecoration: underline"> ${userMention}</a>
                // const userMention = event.value + ' '
                // const selectedMentionObj = props.mentions.find(item => item.value === event.value);
                // if (selectedMentionObj) {
                //     setSelectedMentions([...selectedMentions, selectedMentionObj]);
                // }
                //
                // const cursorPos = view.state.selection.ranges[0].$from.pos + 1;
                // const newEditorValue = editorHtml.replace(new RegExp(currentMention, "g"), (match, i, original) => {
                //     return cursorPos === i + currentMention.length - 1 ? userMention : match;
                // });
                // setEditorVal(newEditorValue);
                // setCurrentMention('');
                // setAutoCompleteVal('');
                // editorRef.current.focus();
            }
        }
    }

    const handleSendClick = async () => {
        if (editorRef.current) {
            const view = editorRef.current.view;
            // if (view) {
            //     const message = EditorUtils.getHtml(view.state);
            //     if (message.trim().length > 0) {
            //         const response = await props.sendMessageHandler(props.channelId, message, selectedMentions, props.replyToMessageId);
            //         setEditorVal("");
            //         setSelectedMentions([]);
            //     }
            // }
        }
    };

    const SendButton = () => {
        return <Button onClick={handleSendClick} style={{height: "2rem", width: "2rem"}}> <MdSend/></Button>;
    }

    const isPrivateMsg = selectedMentions.length === 1 && selectedMentions[0].value.startsWith('@');
    const isPublicUsersOnly = selectedMentions.length > 0 && selectedMentions.every(item => item.value.startsWith('@'));
    const isPublicChannelOnly = selectedMentions.length > 0 && selectedMentions.every(item => item.value.startsWith('#'));
    const isUserAndChannelMsg = selectedMentions.length > 0 && selectedMentions.some(item => item.value.startsWith('@')) && selectedMentions.some(item => item.value.startsWith('#'));
    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    };

    // const modules = {
    //     toolbar: {container: `#toolbar`},
    //     placeholder: {
    //         delimiters: ['{', '}'],                   // default
    //         placeholders
    //     },
    //     autocomplete: {
    //         getPlaceholders: () => placeholders       // factory
    //         container: '#completions',                // can also be return of `document.querySelector` or kept `undefined`
    //         triggerKey: '#',                          // default
    //         endKey: '#',                              // optional
    //         debounceTime: 0,                          // 0: disabled (default)
    //         onOpen: () => console.log('opened'),                                      // optional
    //         onClose: (placeholder) => console.log('user choosed:', placeholder),      // optional
    //         fetchPlaceholders: (query) => fetch(...).then(...)                        // optional
    //         onFetchStarted: (query) => console.log('user searching for:', query),     // optional
    //         onFetchFinished: (results) => console.log('possible results:', results),  // optional
    //     }
    // }
    const
        header = renderHeader();
    return (
        <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_MESSAGE]}>
            <React.Fragment>
                {/*<Editor*/}
                {/*    headerTemplate={header}*/}
                {/*    onChange={onEditorChange}*/}
                {/*    value={editorVal}*/}
                {/*    ref={editorRef}*/}
                {/*    onMouseDown={onEditorMouseDown}*/}
                {/*/>*/}
                {(isPrivateMsg || isPublicUsersOnly || isPublicChannelOnly || isUserAndChannelMsg) &&
                    (<span>
          {
              isPrivateMsg ?
                  "This message is private." :
                  isPublicUsersOnly ?
                      "This message is not private - all members of the tagged channels will be able to view it." :
                      isPublicChannelOnly ?
                          "This message is not private - this message will only display on the tagged patient panel(s)." :
                          // isUserAndChannelMsg ?
                          //   "This message is not private - all members of the tagged channels will be able to view it." :
                          "This message is not private - all members of the tagged channels will be able to view it."
          }
        </span>)
                }
                {autocompleteVal && (
                    <AutoComplete
                        data={props.mentions.map(item => item.value).filter(item => item.match(new RegExp(autocompleteVal, 'i')))}
                        value={autocompleteVal}
                        ref={autoCompleteRef}
                        onChange={handleChange}
                        onClose={handleAutoCompleteClose}
                        opened={true}
                    />
                )}
            </React.Fragment>
        </ShowForPermissionComponent>
    );
};

const MessageBlock = ({requestMessage, messageActions: {getReplies, sendMessage}, mentions}) => {
    const [replyMessage, setReplyMessage] = useState([]);
    const [isActive, setActive] = useState('');
    const [viewAll, setViewAll] = useState(false);
    const lastMsgRef = React.createRef();
    const [iconAvatarColors, setIconAvatarColors] = useState({})

    const toggleClass = (messageId) => {
        if (isActive !== 'replayBlock-' + messageId) {
            setActive('replayBlock-' + messageId);
            setViewAll(false)
            const {numberReplies, id, channelId} = requestMessage.find(item => item.id === messageId);
            if (numberReplies > 0) {
                const payloadId = channelId.value;
                getReplies({messageId: id})
                    .then((messageReplies) => {
                        let tempReplies = [];
                        const repliesPayload = messageReplies.payload;
                        repliesPayload.length > 0 && repliesPayload.forEach(reply => {
                            const replyMessage = reply.message;
                            const tempReplyMessage = renderTags(reply, replyMessage);
                            tempReplies.push({
                                ...reply,
                                message: tempReplyMessage,
                                payloadId: payloadId
                            });
                        });
                        setReplyMessage(tempReplies);
                    })
                    .catch(() => console.log.error("Failed Get Replies"));
            }
        } else {
            setActive('replayBlock');
            setReplyMessage([])
        }
    };

    const toggleViewAll = () => {
        setViewAll((previousViewAll) => !previousViewAll)
    }

    const createMarkup = (text) => ({
        __html: text
    });

    let previousDateHeading = '';

    let getFormattedDate = (latestUpdatedTime, created) => {
        const createdDate = moment.utc(latestUpdatedTime || created).local().startOf('day')
        const current = moment().startOf('day');
        const diffInDays = moment.duration(current.diff(createdDate)).asDays();
        if (diffInDays === 0) {
            return "Today";
        }
        if (diffInDays === 1) {
            return "Yesterday";
        }
        return createdDate.format("MMMM Do, YYYY")
    }

    const sendMessageReplyHandler = async (channelId, message, mentions, replyToMessageId) => {
        const response = await sendMessage(channelId, message, mentions, replyToMessageId);
        if (response && response.payload && response.payload.length === 1) {
            const newMessage = response.payload[0];
            setReplyMessage([...replyMessage, {...newMessage, message: renderTags(newMessage, newMessage.message)}])
        }

        return response;
    }
    useEffect(() => {
        let newAvatarColors = {}
        requestMessage.forEach(m => {
            if (iconAvatarColors[m.username] === undefined) newAvatarColors[m.username] = getRandomColorCode();
        })
        setIconAvatarColors(newAvatarColors)
    }, [requestMessage])

    function getAvatarColor(username) {
        let iconAvatarColor = iconAvatarColors[username];
        if (!iconAvatarColor) {
            iconAvatarColor = getRandomColorCode();
        }
        return iconAvatarColor
    }

    // const iconAvatarColors = {};
    return (
        requestMessage && requestMessage.length > 0 ? requestMessage.map((messageItem, index) => {
                let formattedDate = getFormattedDate(messageItem.latestUpdatedTime || messageItem.created)
                let dateHeading = previousDateHeading !== formattedDate &&
                    (<Row className="justify-content-center" style={styles.messageBlockDate}>{formattedDate}</Row>)
                previousDateHeading = formattedDate;
                let iconAvatarColor = getAvatarColor(messageItem.username);

                return (
                    <React.Fragment key={messageItem.id}>


                        {/*<div className="messageBlock" key={index}*/}
                        {/*     style={styles.messageBlock} ref={index === requestMessage.length - 1 ? lastMsgRef : null}>*/}
                        <Container style={{width: "100%"}} className="phixMessageList">
                            {dateHeading}
                            <Row className={"flex-grow-0"}>
                                <Col className={"flex-grow-0"} col={10} style={{padding: 5}}>
                                    <Row className="k-hbox" key={index}>
                                        <Avatar shape="circle" type="icon"
                                                style={{...styles.messageAvatar, borderColor: iconAvatarColor}}>
                                            <span className="k-icon k-i-user" style={{color: iconAvatarColor}}/>
                                        </Avatar>
                                        <div>
                                            <h2
                                                style={{
                                                    fontSize: "1em",
                                                    color: '#7585c2',
                                                    fontWeight: 600,
                                                    textDecoration: 'underline',
                                                    margin: 0,
                                                }}
                                            >
                                                @ {messageItem.userFirstName} {messageItem.userLastName}
                                            </h2>
                                            <p
                                                className="dateTime"
                                                style={{
                                                    margin: 0,
                                                    fontSize: "0.8em",
                                                    fontWeight: 400,
                                                    color: '#6c6c76'
                                                }}
                                            >
                                                {moment.utc(messageItem.created).local().format("h:mm a, M/D/YY")} {messageItem.numberReplies > 0 ? `(last reply ${moment.utc(messageItem.latestUpdatedTime).local().format("M/D/YY")})` : ""}
                                            </p>
                                            <p className="msgText"
                                               style={{
                                                   margin: 0,
                                                   fontSize: "1em",
                                                   fontWeight: 400,
                                                   color: '#6c6c76'
                                               }}

                                            >
                                                {/* <b style={{ textDecoration: 'underline', color: '#7585c2', }}>@Lorem ipsum</b> */}
                                                <div dangerouslySetInnerHTML={createMarkup(messageItem.message)}/>
                                            </p>
                                        </div>
                                    </Row>
                                </Col>
                                <Col Col={2}
                                     className={"justify-content-center"}
                                >

                                    <i className="fa fa-comment" style={{
                                        fontSize: '22px',
                                        width: "10px",
                                        color: messageItem.numberReplies > 0 ? '#71ba60' : '#cccccc',
                                    }}>
                                        {messageItem.numberReplies > 0 &&
                                            <Badge style={{
                                                backgroundColor: 'transparent',
                                                borderColor: 'transparent',
                                                top: "11px",
                                            }}
                                                   value={messageItem.numberReplies}
                                                   onClick={() => toggleClass(messageItem.id)}/>}
                                    </i>

                                </Col>
                            </Row>
                        </Container>
                        {
                            replyMessage.length > 0 && (
                                <div style={{
                                    marginLeft: '35px',
                                    display: "replayBlock-" + messageItem.id === isActive ? 'block' : 'none',
                                    paddingLeft: '5px',
                                    borderLeft: 'solid 1px #ccc',
                                }}>
                                    {
                                        replyMessage.map((item, index) => {
                                            let iconAvatarColor = getAvatarColor(item.username)
                                            return (
                                                item.payloadId === messageItem.channelId.value &&
                                                (viewAll || index === replyMessage.length - 1) &&
                                                (<div className="replayList" key={index}>
                                                        <div
                                                            className="row p-2 border-bottom align-middle"
                                                            key={index}
                                                            style={{
                                                                margin: 0,
                                                            }}
                                                        >
                                                            <div className="col-12">
                                                                <div className="k-hbox" key={index}>
                                                                    <Avatar shape="circle" type="icon" style={{
                                                                        ...styles.messageAvatar,
                                                                        borderColor: iconAvatarColor
                                                                    }}>
                                                                            <span class="k-icon k-i-user"
                                                                                  style={{color: iconAvatarColor}}/>
                                                                    </Avatar>
                                                                    <div>
                                                                        <h2 style={{
                                                                            fontSize: "1em",
                                                                            color: '#7585c2',
                                                                            fontWeight: 600,
                                                                            textDecoration: 'underline',
                                                                            margin: 0,
                                                                        }}
                                                                        >
                                                                            @{item.userFirstName}{item.userLastName}
                                                                        </h2>
                                                                        <p
                                                                            className="dateTime"
                                                                            style={{
                                                                                margin: 0,
                                                                                fontSize: "0.8em",
                                                                                fontWeight: 400,
                                                                                color: '#6c6c76'
                                                                            }}
                                                                        >
                                                                            {moment.utc(item.created).local().format("h:mm a, M/D/YY")}
                                                                        </p>
                                                                        <p className="msgText"
                                                                           style={{
                                                                               margin: 0,
                                                                               fontSize: "1em",
                                                                               fontWeight: 400,
                                                                               color: '#6c6c76'
                                                                           }}
                                                                        >
                                                                            {/* <b style={{ textDecoration: 'underline', color: '#7585c2', }}>@Lorem ipsum</b> */}
                                                                            <div
                                                                                dangerouslySetInnerHTML={createMarkup(item.message)}/>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                ))
                                        })
                                    }
                                </div>
                            )
                        }
                        <div className="editorControl"
                             style={{
                                 marginLeft: '35px',
                                 paddingLeft: '15px',
                                 width: '85%',
                                 borderLeft: '1px solid rgb(204, 204, 204)',
                                 display: "replayBlock-" + messageItem.id === isActive ? 'block' : 'none'
                             }}>
                            <EditorControl
                                channelId={messageItem.channelId}
                                replyToMessageId={messageItem.id}
                                sendMessageHandler={sendMessageReplyHandler}
                                mentions={mentions}
                            />
                            {replyMessage.length > 1 && <div onClick={toggleViewAll} style={{
                                textAlign: 'right',
                                padding: '5px'
                            }}>{viewAll ? 'View less' : 'View all'}</div>}
                        </div>
                        {/*</div>*/}
                    </React.Fragment>
                )
            })
            :
            <span style={{
                textAlign: 'center',
                width: '100%',
                float: 'left',
                margin: '30% 0',
            }}>No messages to display</span>
    )
}

const ActivityStreamComponent = (props) => {
    const {channelContext} = props
    const channelType = props.channelType || "userRole"
    const channelMessages = useSelector((state) => state.messages.messages)
    // const fetchEnabled = useSubscribePermission([PhixPermissions.AH_VIEW_MESSAGE])
    const fetchEnabled = true
    const [messageFilter, setMessageFilter] = useState("Show All");
    const [messageSort, setMessageSort] = useState("Desc");
    const FilterMenuItems = [
        {
            label: "Filter",
            items: [{
                label: "Show All",
                icon: messageFilter === "Show All" ? "pi pi-check" : "",
                command: () => setMessageFilter("Show All")
            },
                {
                    label: "Patient Messages Only",
                    icon: messageFilter === "Patient Messages Only" ? "pi pi-check" : "",
                    command: () => setMessageFilter("Patient Messages Only")
                },
                {
                    label: "My Mentions Only",
                    icon: messageFilter === "My Mentions Only" ? "pi pi-check" : "",
                    command: () => setMessageFilter("My Mentions Only")
                }]
        },
        {
            label: "Sort",
            items: [{
                label: "Sort Earliest to Latest",
                icon: messageSort === "Asc" ? "pi pi-check" : "",
                command: () => setMessageSort("Asc")
            },
                {
                    label: "Sort Latest to Earliest",
                    icon: messageSort === "Desc" ? "pi pi-check" : "",
                    command: () => setMessageSort("Desc")
                }]
        }
    ]

    const [requestMessage, setRequestMessage] = useState([]);
    const [mentions, setMentions] = useState([])
    const channelId = channelContext.id


    const fetchData = async () => {
        if (props.authenticated) {
            switch (channelType) {
                case "userRole":
                    await props.messageActions.subscribeUserChannel()
                    await props.messageActions.getMessagesForUser(props.session.userRoleId)
                    break;
                case "patientRole":
                    if (props.patientContext?.patientRoleId) {
                        await props.messageActions.subscribePatientChannel(props.patientContext.patientRoleId)
                        await props.messageActions.getMessagesForPatient(props.patientContext.patientRoleId)
                    }
                    break;
                default:
                    console.error("Activity stream has not been given a subscription type!")
            }
        }
    }

    useEffect(() => {

        console.log(`Fetch enables: ${fetchEnabled}`)
        if (fetchEnabled) {
            fetchData()
            const timer = setInterval(fetchData, 5000)
            return () => clearInterval(timer)
        }
        //  props.MessageActions.getMessage();
    }, [channelType, fetchEnabled]);

    useEffect(() => {
        setRequestMessage(applyFilter(channelMessages, messageFilter))
    }, [channelMessages, messageFilter])

    useEffect(() => {
        setMentions(props.channelContext.mentions)
    }, [props.channelContext])


    // TODO: move into the MessageActions / MessageStore
    const sendMessageHandler = async (channelId, message, mentions, replyToMessageId) => {
        if (message === "<p></p>") {
            alert("Please type a message before sending.")
            return
        }
        await props.messageActions.sendMessage(channelId, message, mentions, replyToMessageId);
        await fetchData()
    }

    const sendReplySuccessHandler = (newMessage) => {
        console.log("Send reply success handler")
        // const newMessages = requestMessage.map(item => {
        //     if (item.id === newMessage.parentMessageId) {
        //         return {...item, numberReplies: item.numberReplies + 1, latestUpdatedTime: newMessage.created}
        //     }
        //     return item;
        // });
        //
        // if (messageFilter === FilterMenuItems[4]) {
        //     // sort in descnending order if user has applied an explicit filter
        //     setRequestMessage(newMessages.sort((left, right) => new Date(right.latestUpdatedTime) - new Date(left.latestUpdatedTime)));
        // } else {
        //     // sort in ascending order otherwise
        //     setRequestMessage(newMessages.sort((left, right) => new Date(left.latestUpdatedTime) - new Date(right.latestUpdatedTime)));
        // }
    };

    const applyFilter = (requestMessages, messageFilter) => {
        switch (messageFilter) {
            case FilterMenuItems[1]:
                // Patient associated channels
                const pattern = mentions.filter(item => item.value.startsWith('#')).reduce((acc, item) => {
                    if (acc) {
                        return acc + '|' + item.value
                    }
                    return item.value
                }, '');

                const patientChannelRegEx = new RegExp(pattern, "g");
                return requestMessages.filter(item => item.message.match(patientChannelRegEx));
            // return requestMessage;//.filter(item => item.message.indexOf())

            case FilterMenuItems[2]:
                return requestMessages.filter(item => item.mentions && item.mentions.some(mention => mention.entityId === props.userId))

            case FilterMenuItems[3]:
                // Sort Earliest to Latest
                return requestMessages.sort((left, right) => new Date(left.latestUpdatedTime) - new Date(right.latestUpdatedTime));

            case FilterMenuItems[4]:
                // Sort Latest to Earliests
                return requestMessages.sort((left, right) => new Date(right.latestUpdatedTime) - new Date(left.latestUpdatedTime));

            default:
                return requestMessages;
        }
    }

    const filteredMessages = applyFilter(requestMessage, messageFilter);

    const menu = useRef(null)
    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={["AH_VIEW_MESSAGE"]}>
                <TileContainer>
                    <TileHeader style={GlobalStyles.tileHeaderBlueWithBlack}
                                expanded={Components.ActivityStreamComponent}
                                title={"Activity Stream"}>

                        <ColumnLayout className={"flex-grow-0"}>
                            <Menu style={{fontSize: 12}} model={FilterMenuItems} popup ref={menu}/>
                            <Button text
                                    tooltip={"Filter Messages"}
                                    tooltipOptions={{position: "top"}}
                                    onClick={(e) => menu.current.toggle(e)}
                                    size={"small"} style={{height: 25, width: 25, color: "white"}} rounded
                                    icon={"pi pi-bars"}/>
                        </ColumnLayout>

                    </TileHeader>
                    <TileBody>
                        <Row style={styles.chatBody}>

                        </Row>
                        <div className="chatFooter" style={styles.chatFooter}>
                            {/*<EditorControl*/}
                            {/*    channelId={channelId}*/}
                            {/*    sendMessageHandler={sendMessageHandler}*/}
                            {/*    mentions={mentions}*/}
                            {/*/>*/}
                        </div>
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )

}


ActivityStreamComponent.propTypes = {
    authActions: PropTypes.object,
    messageActions: PropTypes.object,
    channelType: PropTypes.string,
}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        userId: state.auth.session.userRoleId,
        subscribedChannels: state.messages.subscribedChannels,
        channelContext: state.messages.channelContext,
        channelMessages: state.messages.messages,
        patientContext: state.patient.patientContext,
        session: state.auth.session,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthAction, dispatch),
        layoutActions: bindActionCreators(LayoutAction, dispatch),
        messageActions: bindActionCreators(MessageActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityStreamComponent)