import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {UserActions} from "../action/UserAction";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Chip} from "@progress/kendo-react-buttons";
import {Components} from "./shared/ComponentRegistry";
import TileHeader from "../layout/TileHeader";
import GlobalStyles from "../config/GlobalStyles";
import ShowForPermissionComponent from "./shared/ShowForPermissionComponent";
import {Col, Container, Row} from "react-grid-system";

const ModelOfCareComponent = (props) => {
    const data = [
        {name: "ACP Measure", value: "100%", color: "success"},
        {name: "Annual Measure", value: "100%", color: "success"},
        {name: "Case Load", value: "28", color: "success"},
        {name: "ICE Measure", value: "27%", color: "error"},
        {name: "ICT Meeting Measure", value: "89%", color: "success"},
        {name: "IPOC Measure", value: "77%", color: "warning"},
    ]

    const performanceCell = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        return (
            <td style={{display: "flex", alignItems: "center", alignContent: "center"}}>
                <Chip  style={{margin: "auto"}}
                       text={value}
                       rounded={"full"}
                       themeColor={props.dataItem.color}/>
            </td>
        );
    }

    return (
        <ShowForPermissionComponent permissions={[Permissions.AH_MANAGE_FACILITIES]}>
            <div style={GlobalStyles.tileWrapper}>
                <TileHeader title="Model of Care Metrics" expanded={Components.ModelOfCareComponent}
                            style={{fontSize: "15px"}}/>
                <Container style={GlobalStyles.panelContainer}>
                    <Row style={{height: "100%"}}>
                        <Col xs={12} style={GlobalStyles.panelCol}>
                            <Grid
                                style={{height: "100%"}}
                                data={data}>
                                <GridColumn field={"name"} title={"Measure"}/>
                                <GridColumn field={"value"} title={"Current Performance"} cell={performanceCell}/>
                            </Grid>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ShowForPermissionComponent>
    );
};

ModelOfCareComponent.propTypes =
    {}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        UserActions: bindActionCreators(UserActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelOfCareComponent)
