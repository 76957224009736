import PropTypes from "prop-types";
import {PatientAction} from "../action/PatientAction";
import {useDispatch, useSelector} from "react-redux";
import React from "react";


import {Responsive, WidthProvider} from "react-grid-layout";
import {BreakPoints} from "../config/GlobalConfig";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Col, Container, Row} from "react-grid-system";
import {Button} from "primereact/button";
import queryString from "query-string";
import {ParseComponent} from "../components/shared/ComponentRegistry";

const ResponsiveGridLayout = WidthProvider(Responsive);

function PatientPanelPage(props) {
    const history = useHistory();
    const qParams = useParams();
    let params = new URLSearchParams(window.location.search);
    const {search, pathname} = useLocation()
    const patientContext = useSelector(state => state.patient.patientContext)
    const updatePatientContext = useSelector(state => state.ComponentUpdate.patientContext)
    const tenantLayout = useSelector(state => state.Configuration.tenant.workspaceLayout)
    const permissionLayout = useSelector(state => state.Configuration.config.workspaceLayout)
    const dispatch = useDispatch()
    const parsed = {...queryString.parse(search), redirect: pathname}

    function parseScreenLayout() {
        let layout = permissionLayout?.PatientPanelPage?.Components ? permissionLayout.PatientPanelPage.Components : tenantLayout?.PatientPanelPage?.Components ? tenantLayout?.PatientPanelPage?.Components : []
        let foundIndex = layout.findIndex(x => x.i === "NotificationComponent");
        if (foundIndex >= 0)
            layout[foundIndex].params = {type: "LOGGED_IN_USER"}
        return layout
    }

    const [screenLayout, setScreenLayout] = React.useState(parseScreenLayout())


    // HOOK to update patient list on editing the patient demographics
    React.useEffect(() => {
        if (patientContext?.patientRoleId) {
            PatientAction.getPatientByIdAndSetContext(patientContext.patientRoleId)(dispatch)
        }
    }, [updatePatientContext])

    React.useEffect(() => {
        if (!patientContext?.patientRoleId && parsed.patient_id) {
            PatientAction.getPatientByIdAndSetContext(parsed.patient_id)(dispatch)
        }
    }, [parsed])

    const [loading, setLoading] = React.useState(false)


    React.useEffect(() => {

        let externalId = parsed?.ah_pid
        if (externalId) {
            setLoading(true)
            PatientAction.setPatientContextByExternalId(externalId)(dispatch)
            history.replace({
                search: "",
            });
        }
    }, [search])

    if (patientContext)
        if (screenLayout)
            return <>
                <div>
                    <ResponsiveGridLayout
                        style={{height: "calc(100vh - 60px)", width: "100%", overflowY: "auto", overflowX: "hidden"}}
                        // onLayoutChange={(layout) => {
                        //
                        //     console.log(JSON.stringify(layout, null, 4))
                        // }}
                        resizeHandles={["se"]}
                        draggableHandle={".ah-draggable-header"}
                        className="layout"
                        layouts={{lg: screenLayout}}
                        breakpoints={BreakPoints}
                        cols={{lg: 12, md: 12, sm: 4, xs: 4, xxs: 4}}>
                        {screenLayout.map(c => ParseComponent(c))}

                    </ResponsiveGridLayout>
                </div>
            </>
        else return <>
            <div>
                <h4>Error: Tenant configuration is not present. Unable to load</h4>
            </div>
        </>
    else
        return <Container style={{height: "100%", width: "100%"}}>

            <Row style={{height: "100%", width: "100%", alignContent: "center", alignItems: "center"}}>
                <Col>

                    <h5 align={"center"} style={{margin: '15%'}}>
                        Patient not found please select a patient from the
                        <br></br> <br></br>
                        <Button primary={true} onClick={() => history.push("/patientList")}>
                            Patient List
                        </Button>
                    </h5>
                </Col>
            </Row>
        </Container>
}


PatientPanelPage.propTypes = {
    authActions: PropTypes.object,
    patientActions: PropTypes.object,
    PopupActions: PropTypes.object,
}


export default PatientPanelPage
