import React, {useState} from 'react';
import 'hammerjs';
import {bindActionCreators} from "redux";
import {PatientAction} from "../../action/PatientAction";
import {connect} from 'react-redux';
import moment from 'moment';
import {ObservationCodes, ObservationThreshold, PhixPermissions} from "../../config/GlobalConfig";
import {Col, Row} from "react-grid-system";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import TileHeader from "../../layout/TileHeader";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {ObservationsThresholdAction} from "../../action/ObservationsThresholdAction";


const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const ActivityLevelPatientPanel = (props) => {
    const {PatientActions, PatientContext, ActivityLevelPatientPanelData, ActivityLevelPatientPanelTodayData} = props
    const [prevWeekChartData, setPreviousWeekChartData] = React.useState([]);
    const [currWeekChartData, setCurrentWeekChartData] = React.useState([]);
    const [currentWeek, setCurrentWeek] = React.useState([[]]);
    const [datePeriod, setDatePeriod] = useState({
        startDate: moment().utc().local().startOf('week').subtract(1, "week").format('YYYY-MM-DD'),
        endDate: moment().utc().local().endOf('week').format('YYYY-MM-DD'),
        today: moment().startOf('day').format('YYYY-MM-DD')
    })
    const [maxDate, setMaxDate] = React.useState(moment())
    const [averageLine, setAverageLine] = React.useState([])
    const [weeksPrevious, setWeeksPrevious] = React.useState(0)
    //const [currWeekCount, setCurrWeekCount] = React.useState();

    const [currWeekData, setCurrWeekData] = React.useState({
        count: "",
        color: ObservationThreshold.color,
        text: ""
    });

    const [sourceCount, setSourceCount] = React.useState("");
    const [sourceName, setSourceName] = React.useState("");
    const [colorCode, setColorCode] = React.useState();
    const [observationData, setObservationData] = React.useState({
        Critical: {},
        Improve: {},
        Target: {},
    });

    React.useEffect(() => {
        let startDate = moment().startOf('week').subtract(weeksPrevious, "week").format('YYYY-MM-DD')
        let endDate = moment().endOf('week').subtract(weeksPrevious, "week").format('YYYY-MM-DD')
        let currentDate = moment().startOf('day').format('YYYY-MM-DD');
        setDatePeriod({startDate: startDate, endDate: endDate, today: currentDate})
    }, [weeksPrevious]);

    React.useEffect(() => {
        if (PatientContext?.patientRoleId) {
            const startDate = moment().startOf('week').format('YYYY-MM-DD');
            const endDate = moment().endOf('week').format('YYYY-MM-DD');
            PatientActions.getPatientObservationsByPeriod(PatientContext.patientRoleId,
                startDate,
                endDate,
                [ObservationCodes.STEP.STEP_COUNT],
                "ActivityLevelPatientPanelData")
            //ActivityLevelPatientPanelTodayData
            PatientActions.getPatientObservationsByPeriod(PatientContext.patientRoleId,
                moment().startOf("day").format('YYYY-MM-DD'),
                moment().endOf("day").format('YYYY-MM-DD'),
                [ObservationCodes.STEP.STEP_COUNT],
                "ActivityLevelPatientPanelTodayData")
            props.ObservationsThresholdActions.getObservationThresholdsByObservationCode("AH_STEP_COUNT", "ActivityLevelPatientPanel");
            return () => {
                PatientActions.clearObservationsByComponent("ActivityLevelPatientPanelData")
                PatientActions.clearObservationsByComponent("ActivityLevelPatientPanelTodayData")
            }
        }

    }, [PatientContext]);

    React.useEffect(() => {
        if (props?.observationThreshold) {
            const val = props.observationThreshold;
            for (let i = 0; i < val.length; i++) {
                if (val[i].description === "Critical") {
                    observationData.Critical.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Critical.value = val[i].value
                    observationData.Critical.rangeText = val[i].rangeText
                    observationData.Critical.rangeColor = val[i].rangeColor
                } else if (val[i].description === "Improve") {
                    observationData.Improve.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Improve.value = val[i].value
                    observationData.Improve.rangeText = val[i].rangeText
                    observationData.Improve.rangeColor = val[i].rangeColor
                } else if (val[i].description === "Target") {
                    observationData.Target.dictOperatorDisplayName = val[i].dictOperatorDisplayName
                    observationData.Target.value = val[i].value
                    observationData.Target.rangeText = val[i].rangeText
                    observationData.Target.rangeColor = val[i].rangeColor
                }
            }
        }
    }, [props?.observationThreshold])

    React.useEffect(() => {
        if (ActivityLevelPatientPanelData?.length > 0) {
            // Currrent week Average
            const filterActivityBarData = [];
            const date = ActivityLevelPatientPanelData.map((i) => moment(i.effectiveStartDate).format('YYYY-MM-DD'));
            const setSerialized = new Set(date);
            const uniqueArrSerialized = [...setSerialized];

            uniqueArrSerialized.forEach((item) => {
                const data = ActivityLevelPatientPanelData.filter((i) => item === moment(i.effectiveStartDate).format('YYYY-MM-DD'));
                const valueArray = data.map((i) => parseFloat(i.value));
                const finalValue = valueArray.reduce((x, y) => x + y, 0);
                filterActivityBarData.push({value: finalValue, effectiveStartDate: item});
            })

            const value = filterActivityBarData.map((i) => Number(i.value));
            const totalCount = value.reduce((a, b) => a + b, 0);
            const finalAverage = Math.round(totalCount / value.length)
            //setCurrWeekCount(finalAverage);

            // Color code
            let colorCurrWeekSteps = ObservationThreshold.color;
            let textCurrWeekSteps = "";
            /*if (finalAverage >= 5000) setColorCode("#6AA84F");
            if (finalAverage <= 5000 && finalAverage >= 1999) setColorCode("#E69138");
            if (finalAverage <= 2000) setColorCode("#E06666");*/
            if (observationData?.Target && observationData.Target?.value?.value) {
                if (observationData.Target.dictOperatorDisplayName === ">=" && finalAverage >= observationData.Target.value.value) {
                    colorCurrWeekSteps = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Target.rangeText;
                } else if (observationData.Target.dictOperatorDisplayName === "<=" && finalAverage <= observationData.Target.value.value) {
                    colorCurrWeekSteps = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Target.rangeText;
                } else if (observationData.Target.dictOperatorDisplayName === ">" && finalAverage > observationData.Target.value.value) {
                    colorCurrWeekSteps = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Target.rangeText;
                } else if (observationData.Target.dictOperatorDisplayName === "<" && finalAverage < observationData.Target.value.value) {
                    colorCurrWeekSteps = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Target.rangeText;
                } else if (observationData.Target.dictOperatorDisplayName === "==" && finalAverage === observationData.Target.value.value) {
                    colorCurrWeekSteps = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Target.rangeText;
                }
            } else if (observationData?.Target && observationData.Target?.value?.min && observationData.Target?.value?.max) {
                if (finalAverage > observationData.Target.value.min && finalAverage < observationData.Target.value.max) {
                    colorCurrWeekSteps = observationData.Target.rangeColor ? observationData.Target.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Target.rangeText;
                }
            }

            if (observationData?.Critical && observationData.Critical?.value?.value) {
                if (observationData.Critical.dictOperatorDisplayName === ">=" && finalAverage >= observationData.Critical.value.value) {
                    colorCurrWeekSteps = observationData.Critical.rangeColor ? observationData.Critical.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Critical.rangeText;
                } else if (observationData.Critical.dictOperatorDisplayName === "<=" && finalAverage <= observationData.Critical.value.value) {
                    colorCurrWeekSteps = observationData.Critical.rangeColor ? observationData.Critical.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Critical.rangeText;
                } else if (observationData.Critical.dictOperatorDisplayName === ">" && finalAverage > observationData.Critical.value.value) {
                    colorCurrWeekSteps = observationData.Critical.rangeColor ? observationData.Critical.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Critical.rangeText;
                } else if (observationData.Critical.dictOperatorDisplayName === "<" && finalAverage < observationData.Critical.value.value) {
                    colorCurrWeekSteps = observationData.Critical.rangeColor ? observationData.Critical.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Critical.rangeText;
                } else if (observationData.Critical.dictOperatorDisplayName === "==" && finalAverage === observationData.Critical.value.value) {
                    colorCurrWeekSteps = observationData.Critical.rangeColor ? observationData.Critical.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Critical.rangeText;
                }
            } else if (observationData?.Critical && observationData.Critical?.value?.min && observationData.Critical?.value?.max) {
                if (finalAverage > observationData.Critical.value.min && finalAverage < observationData.Critical.value.max) {
                    colorCurrWeekSteps = observationData.Critical.rangeColor ? observationData.Critical.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Critical.rangeText;
                }
            }

            if (observationData?.Improve && observationData.Improve?.value?.value) {
                if (observationData.Improve.dictOperatorDisplayName === ">=" && finalAverage >= observationData.Improve.value.value) {
                    colorCurrWeekSteps = observationData.Improve.rangeColor ? observationData.Improve.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Improve.rangeText;
                } else if (observationData.Improve.dictOperatorDisplayName === "<=" && finalAverage <= observationData.Improve.value.value) {
                    colorCurrWeekSteps = observationData.Improve.rangeColor ? observationData.Improve.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Improve.rangeText;
                } else if (observationData.Improve.dictOperatorDisplayName === ">" && finalAverage > observationData.Improve.value.value) {
                    colorCurrWeekSteps = observationData.Improve.rangeColor ? observationData.Improve.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Improve.rangeText;
                } else if (observationData.Improve.dictOperatorDisplayName === "<" && finalAverage < observationData.Improve.value.value) {
                    colorCurrWeekSteps = observationData.Improve.rangeColor ? observationData.Improve.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Improve.rangeText;
                } else if (observationData.Improve.dictOperatorDisplayName === "==" && finalAverage === observationData.Improve.value.value) {
                    colorCurrWeekSteps = observationData.Improve.rangeColor ? observationData.Improve.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Improve.rangeText;
                }
            } else if (observationData?.Improve && observationData.Improve?.value?.min && observationData.Improve?.value?.max) {
                if (finalAverage > observationData.Improve.value.min && finalAverage < observationData.Improve.value.max) {
                    colorCurrWeekSteps = observationData.Improve.rangeColor ? observationData.Improve.rangeColor : ObservationThreshold.color;
                    textCurrWeekSteps = observationData.Improve.rangeText;
                }
            }

            currWeekData.count = finalAverage;
            currWeekData.color = colorCurrWeekSteps;
            currWeekData.text = textCurrWeekSteps;

            let data = [];
            ActivityLevelPatientPanelData.forEach((element) => data.push(element.value));
            let sum = getAverageForEachWeek(data);

            setCurrentWeek(sum);
            let chartData = prepareData()

            setPreviousWeekChartData(chartData.prevChart)
            setCurrentWeekChartData(chartData.currChart)
            let avgArr = getAverageLineData();
            setAverageLine(avgArr)
        } else {
            setCurrentWeek([]);
            setPreviousWeekChartData([]);
            setCurrentWeekChartData([]);
            //setCurrWeekCount("");
            setCurrWeekData({
                count: "",
                color: "",
                text: ""
            });
        }


    }, [ActivityLevelPatientPanelData, observationData])


    React.useEffect(() => {
        getAverageForEachWeek()
    }, [currentWeek])

    React.useEffect(() => {
        let avgArr = getAverageLineData();
        setAverageLine(avgArr)
    }, [prevWeekChartData, currWeekChartData])

    React.useEffect(() => {
        if (ActivityLevelPatientPanelTodayData && ActivityLevelPatientPanelTodayData?.length > 0) {
            const s = ActivityLevelPatientPanelTodayData.map((i) => i.sourceName);

            if (s?.length > 0) {
                const sources = new Set(s)
                setSourceName([...sources].join(", "))
            }
            const values = ActivityLevelPatientPanelTodayData.map((i) => i.value);
            const total = values.reduce((x, y) => Number(x) + Number(y), 0);
            const dates = ActivityLevelPatientPanelTodayData.map((e) => moment(e.effectiveStartDate).toDate())
            const m = new Date(Math.max.apply(null, dates));
            setMaxDate(moment(m))
            setSourceCount(Math.round(total));
        }


        // return () => {
        //     PatientActions.clearObservationsByComponent("ActivityLevelPatientPanelTodayData")
        // }


    }, [ActivityLevelPatientPanelTodayData])

    function getAverageLineData() {
        let sumPrev = prevWeekChartData.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        let sumCurr = currWeekChartData.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        let avgArr = Array(7).fill(sumPrev / 7);
        let avgArrC = Array(7).fill(sumCurr / 7);
        avgArr.push(...avgArrC)
        return avgArr
    }

    function getAverageForEachWeek(data = null) {
        let sum
        if (data !== null) {
            sum = data.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        } else {
            sum = currentWeek.reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        }
        return (Array(7).fill(sum / 7))
    }


    function prepareData() {
        if (ActivityLevelPatientPanelData?.length > 0) {
            let currentWeekArr = []
            let prevWeekArr = []

            const filterActivityBarData = [];
            const date = ActivityLevelPatientPanelData.map((i) => moment(i.effectiveStartDate).format('YYYY-MM-DD'));
            const setSerialized = new Set(date);
            const uniqueArrSerialized = [...setSerialized];

            uniqueArrSerialized.forEach((item) => {
                const data = ActivityLevelPatientPanelData.filter((i) => item === moment(i.effectiveStartDate).utc().local().format('YYYY-MM-DD'));
                const valueArray = data.map((i) => parseFloat(i.value));
                const finalValue = valueArray.reduce((x, y) => x + y, 0);
                filterActivityBarData.push({value: finalValue, effectiveStartDate: item});
            })
            let preWR = prepareWeekData(prevWeekArr)
            let curWR = prepareWeekData(currentWeekArr)
            let chartData = prepareGraphData(preWR, 'prev')
            let chartData1 = prepareGraphData(curWR, 'curr')
            return {
                'prevChart': chartData,
                'currChart': chartData1
            }
        }
    }

    function prepareGraphData(arrayVal, weekName) {
        let objArray = []
        weekDays.forEach((item) => {
            objArray.push(arrayVal[item] ? arrayVal[item] : 0)
        }, [])
        var nullArray = [null, null, null, null, null, null, null]
        if (weekName === 'curr') {
            objArray.unshift(...nullArray)
        } else {
            objArray.push(...nullArray)
        }
        return objArray
    }

    function prepareWeekData(wArray) {
        let b = wArray.reduce((acc, item) => {
            acc[item.categoryField] = acc[item.categoryField] ? acc[item.categoryField] : 0 + item.field;
            return acc
        }, {})
        return b
    }


    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                <TileContainer>
                    <TileHeader
                        title={<div><span className="k-icon k-i-blur" style={{
                            fontSize: '21px',
                            color: '#CF2927',
                            borderBottom: '#CF2927 4px solid',
                            margin: '2px 5px'
                        }}/> Activity Level</div>}
                    />

                    <TileBody>
                        <Row style={{margin: '0', height: "100%"}} onClick={() => {
                            PatientActions.setSavePatientContext({...PatientContext, selectedValue: 'day'})
                            props.PopupActions.showWindow(PopupWindows.ACTIVITY_LEVEL_BAR_WINDOW);
                        }}>

                            <Col style={{borderRight: "solid 2px #DDDDDD", margin: '10px', padding: '5px 20px'}}>
                                {ActivityLevelPatientPanelTodayData && ActivityLevelPatientPanelTodayData?.length > 0 ?
                                    <span style={{width: '100%', float: "left", color: "#666666"}}>
                        {maxDate.format('MM/DD/YY')}
                                        <b style={{color: "#666666", paddingLeft: "10px"}}>
                            {maxDate.format('h:mm a')}
                        </b>
                    </span> : null}
                                {
                                    (sourceCount) ? (
                                        <>
                                <span style={{
                                    width: '100%',
                                    float: "left",
                                    lineHeight: '35px',
                                    textAlign: "left",
                                    fontSize: "30px",
                                    fontWeight: '600',
                                    color: "#085394"
                                }}> {sourceCount} Steps </span>
                                            <span style={{
                                                width: '100%',
                                                float: "left",
                                                color: "#666666"
                                            }}>  {sourceName ? ` Source: ${sourceName}` : ""} </span>
                                        </>
                                    ) : ("")
                                }
                            </Col>
                            <Col style={{margin: 0, padding: '0px 20px'}}>
                                <span style={{width: '100%', float: "left"}}> Current Week Average </span>
                                {(currWeekData.count && currWeekData.count !== 1) ? (
                                    <span style={{
                                        width: '100%',
                                        float: "left",
                                        lineHeight: '35px',
                                        textAlign: "left",
                                        fontSize: "30px",
                                        fontWeight: '600',
                                        color: currWeekData.color
                                    }}> {currWeekData.count ? `${currWeekData.count} Steps` : ""} </span>
                                ) : ("")}
                                {(currWeekData.text) ? (
                                    <span style={{
                                        width: '100%',
                                        float: "left"
                                    }}> {currWeekData.text ? `${currWeekData.text}` : ""} </span>
                                ) : ("")}
                            </Col>
                        </Row>
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        ActivityLevelData: state.patient.activityLevelBar,
        PatientContext: state.patient.patientContext,
        ActivityLevelPatientPanelData: state.patient.observations.ActivityLevelPatientPanelData,
        ActivityLevelPatientPanelTodayData: state.patient.observations.ActivityLevelPatientPanelTodayData,
        popupState: state.popupWindow.state,
        observationThreshold: state.observationThreshold.componentThresholds?.ActivityLevelComponent,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        ObservationsThresholdActions: bindActionCreators(ObservationsThresholdAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLevelPatientPanel);
