import {connect} from "react-redux";
import {DrawerContent, TileLayout} from "@progress/kendo-react-layout";
import UserListComponent from "../UserListComponent";
import React from "react";

const userListTile = [
    {
        defaultPosition: {
            col: 1,
            colSpan: 8,
            rowSpan: 6,
        },
        body: <UserListComponent />,
        header: "USER LIST",
        className: "user-list-header"
    }
];

function UserPanelPage(props) {
    return <>
        <DrawerContent>
            <TileLayout style={{
                width: "96%",
                height: "calc(100vh - 90px)"
            }} ignoreDrag={(e) => { return !(e.target.classList.contains("k-card-title")); }} className={"main-screen user-header-component"} items={userListTile} />
        </DrawerContent>
    </>
}


function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPanelPage)