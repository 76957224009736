import * as React from "react";
import {useEffect} from "react";
import {PDFExport} from "@progress/kendo-react-pdf";
import {Label} from "@progress/kendo-react-labels";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {Dictionary,} from "../InputControls/DictionaryDropDownList";
import {connect} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {WindowActions} from "../../action/WindowAction";
import {useForm} from "react-hook-form";
import {BaseRequestAction} from "../../action/BaseRequestAction";
import {PatientAction} from "../../action/PatientAction";
import {EncounterAction} from "../../action/EncounterAction";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {CareTeamAction} from "../../action/CareTeamAction";
import {UserActions} from "../../action/UserAction";
import {useHistory} from "react-router-dom";
import {Button} from "primereact/button";
import {PermissionsTool} from "../../util/PermissionsTool";

import moment from "moment";
import {
    Column,
    Field,
    FieldCheckboxInput,
    FieldDatePicker,
    FieldDateTimePicker,
    FieldDictionaryDropDown,
    FieldMultiProblemSelector,
    FieldPatientDropdown,
    FieldRadioInput,
    FieldRow,
    FieldSection,
    FieldTextAreaInput,
    FieldTextInput,
    FieldUserDropDown
} from "../InputControls/FormFields";
import {debounce} from "lodash";
import {PersonActions} from "../../action/PersonAction";
import {Dialog} from "primereact/dialog";
import {useToast} from "../../providers/toast/ToastProvider";


const AddNewRequestWindow = (props) => {
    const history = useHistory()

    const {
        id,
        PopupActions,
        createRequestContext,
        patientContext,
        BaseRequestActions,
        RequestUpdated,
        requestContext,
        requestDocumentId,
        clinicalDocument,
        careTeamContext,
        ...others
    } = props;
    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        control,
        setError,
        getValues,
        setValue,
        watch,
        formState: {errors, isDirty, isSubmitting, touchedFields, submitCount},
    } = useForm({
        defaultValues: {
            'additional.priority': 'Routine',
            'title': '',
            'patientRoleId': null,
            'userRoleId': null,
            'dueBy': null,
            'dictRequestTypeId': null,
            'description': '',
            'urgent': false,
        }
    });
    const {addToast} = useToast()
    const {windowState, WindowActions} = props

    const [windowStage, setWindowStage] = React.useState("DEFAULT");
    const [primaryCareUser, setPrimaryCareUser] = React.useState("");
    const [orderingProvider, setOrderingProvider] = React.useState(null);

    const initialFilter = {
        logic: "and",
        filters: [
            {
                field: "dictClinicalStatusDisplayName",
                operator: "contains",
                value: "Active",
            },
        ],
    };
    const [generalTaskPermissions, setGeneralTaskPermissions] = React.useState({isEdit: false, isView: false});
    const [referralPermissions, setReferralPermissions] = React.useState({isEdit: false, isView: false});

    React.useEffect(() => {
        props.UserActions.getAllUsers()
    }, []);

    React.useEffect(() => {
        setGeneralTaskPermissions(PermissionsTool({
            permissionInfo: props.permissionInfo,
            viewPermissions: ["AH_VIEW_REQUEST"],
            editPermissions: ["AH_EDIT_REQUEST"]
        }));
        setReferralPermissions(PermissionsTool({
            permissionInfo: props.permissionInfo,
            viewPermissions: ["AH_VIEW_REFERRALS"],
            editPermissions: ["AH_EDIT_REFERRALS"]
        }));
    }, [props?.permissionInfo]);

    React.useEffect(() => {
            const careTeam = props?.careTeamList?.find(v => v.careTeamRoleCode === "AH_ATTRIBUTED_PROVIDER" && v.careTeamRoleActive === true)
            setPrimaryCareUser(careTeam)
        }, [props?.careTeamList]
    )

    React.useEffect(() => {
            const orderingProvider = props?.allUsers?.find(v => v.userRoleId === primaryCareUser?.userRoleId);
            setOrderingProvider(orderingProvider);
        }, [primaryCareUser && props?.allUsers]
    )
    React.useEffect(() => {
            if (careTeamContext?.careTeamId) {
                props.CareTeamActions.getCareTeamList(careTeamContext.careTeamId)
            }
        }, [careTeamContext]
    )
    // PDF Page Template
    const PDFPageTemplate = (props) => {
        return (
            <div className="container">
                <div className="row">
                    <div
                        style={{
                            top: "10px",
                            textAlign: 'right',
                        }}
                    >
                        <span style={{
                            width: '100%',
                            float: 'left',
                            textAlign: 'right'
                        }}>Printed on {moment().format('YYYY-MM-DD')}</span>
                    </div>
                </div>
            </div>
        );
    };
    // PDF Page Template END

    const [filter, setFilter] = React.useState(initialFilter);

    const [printdata, setPrintdata] = React.useState(null)
    const pdfExportComponent = React.useRef(null);
    const eSignPass = React.useRef(null);
    window.s = getValues;
    const hideDialog = () => {
        setRequestId(null);
        reset();
        BaseRequestActions.clearContext();
        if (props?.encounterContext?.id) {
            props.EncounterAction.getEncounter({id: props?.encounterContext?.id});
        }
        PopupActions.hideWindow(PopupWindows.CREATE_REQUEST_WINDOW);
    };
    const [isExporting, setIsExporting] = React.useState(false);

    const [showReferral, setShowReferral] = React.useState(false);

    const [isESign, setIsESign] = React.useState(false);

    const [showESign, setShowESign] = React.useState(false);

    const [isNewDocument, setIsNewDocument] = React.useState(false);

    const [showAdditionalOther, setshowAdditionalOther] = React.useState(false);

    const [patientRoleProp, setPatientRoleProp] = React.useState(null);

    const [relatedDiagnosis, setRelatedDiagnosis] = React.useState([]);

    const [patientData, setPatientData] = React.useState([]);

    const [documentList, setDocumentList] = React.useState([]);

    const [fileValue, setFileValue] = React.useState("");

    const [clinicalDocumentId, setClinicalDocumentId] = React.useState("");

    const [documentId, setDocumentId] = React.useState("");

    const [requestId, setRequestId] = React.useState(null);

    const [errMessage, setErrMessage] = React.useState();

    const [showUploadDocument, setShowUploadDocument] = React.useState(false);

    const [documentContextCheck, setDocumentContextCheck] = React.useState(false);

    const [isToast, setIsToast] = React.useState(false);
    const [message, setMessage] = React.useState("")

    const exportPDFWithComponent = () => {

        setIsExporting(true);
    }

    React.useEffect(() => {
        if (isExporting) {
            pdfExportComponent.current.save(() => {
                setIsExporting(false);
            })
        }
    }, [isExporting])

    const handleStageChange = (e) => {
        setWindowStage(e.state);
    };

    const setMinimize = () => {
        setWindowStage("MINIMIZED");
    };

    const setFullscreen = () => {
        setWindowStage("FULLSCREEN");
    };

    const setDefault = () => {
        setWindowStage("DEFAULT");
    };

    //setValue("additional.priority", "Routine")

    useEffect(() => {

        if (requestContext) {

            let additional_form = (requestContext?.form) ? JSON.parse(requestContext?.form) : null

            let apt_date = (additional_form && additional_form.appointment_date) ? moment(moment.utc(moment(additional_form.appointment_date)), "YYYY-MM-DD hh:mm a").toDate() : null

            let related_diagnosis = (additional_form && additional_form.RelatedDiagnoses) ? additional_form.RelatedDiagnoses : [];

            let additional_provider_other = (additional_form && additional_form.other) ? additional_form.other : null
            setValue("requestId", requestContext?.id)
            setValue("title", requestContext?.title)
            setValue("dictStatusId", requestContext?.dictStatusId)
            setValue("patientRoleId", requestContext?.patientRoleId)
            setValue("userRoleId", requestContext?.assignedUserRoleId)
            if (requestContext?.dueDate)
                setValue("dueBy", moment(requestContext?.dueDate, "YYYY-MM-DD").toDate())
            setValue("dictRequestTypeId", requestContext?.dictRequestTypeId)
            setValue("description", requestContext?.description)
            setValue("additional", additional_form)
            setValue("additional.appointment_date", apt_date)
            setValue("additional.other", additional_provider_other)
            setValue("urgent", requestContext?.urgent)
            setPatientRoleProp(requestContext?.patientRoleId)

            if ((additional_form && additional_form.RelatedDiagnoses)) {
                setRelatedDiagnosis({
                    gridData: additional_form.RelatedDiagnoses.map(item => {

                        if (!item.diagnosisCode.length) {
                            item.diagnosisCode.code = ""
                            item.diagnosisCode.displayName = item.displayName
                        } else {
                            item.diagnosisCode = item.diagnosisCode[0]
                        }
                        return item
                    })
                })
            }

            setIsESign(false)
            setIsNewDocument(false)

            setShowUploadDocument(false)
            setDocumentContextCheck(false)
            setFileValue("")
            setClinicalDocumentId('')

            // Getting document list for the requestId
            setRequestId(requestContext?.id)

            const payload = {
                requestId: requestContext?.id,
            }


            if (Object.keys(payload).length > 0) {
                props.PatientActions.getClinicalDocument(payload);
            }

            props.CareTeamActions.getCareTeamContext(requestContext?.patientRoleId)


            //getEsign();

        } else {

            setValue("requestId", 0)
            setValue("title", '')
            setValue("patientRoleId", null)
            setValue("userRoleId", null)
            setValue("dueBy", null)
            setValue("dictRequestTypeId", null)
            setValue("description", '')
            setPatientRoleProp(null)
            setShowUploadDocument(false)
            setDocumentContextCheck(false)
            setDocumentList([])
            setFileValue("")
            setClinicalDocumentId('')

            setIsESign(false)
            setIsNewDocument(false)

            setRequestId(null)

            //getEsign();

        }
    }, [requestContext])

    function getEsign() {
        let payload =
            {
                "userRoleId": props.loggedinUser.userRoleId,
                "patientRoleId": getValues("patientRoleId"),
                "referralId": getValues("requestId")
            }
        props.BaseRequestActions.geteSign(payload);
    }

    function getCreateEsign(baseRequestId) {
        let payload =
            {
                "userRoleId": props.loggedinUser.userRoleId,
                "patientRoleId": (getValues("patientRoleId")) ? getValues("patientRoleId").patientRoleId : null,
                "referralId": baseRequestId
            }
        props.BaseRequestActions.geteSign(payload);
    }

    React.useEffect(() => {

        setShowESign(true)

    }, [props.BaseRequest.eSign]);

    // For Referral Additional Field
    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {

            if (name === "dictRequestTypeId") {
                if (value?.dictRequestTypeId?.code === 'AH_REFERRAL') {
                    setShowReferral(true)
                    setFullscreen()
                    return true;
                } else {
                    setShowReferral(false)
                    setDefault()
                    return true;
                }
            } else if (name === 'urgent' && showReferral) {
                setFullscreen();
                setShowReferral(true);
            } else if (name === "additional.other") {
                if (value?.additional.other === true)
                    setshowAdditionalOther(true)
                else
                    setshowAdditionalOther(false)
                return true;
            } else if (name === 'patientRoleId') {
                setPatientData(value.patientRoleId)
                if (!requestContext) {
                    setPatientRoleProp(value?.patientRoleId?.patientRoleId)
                    setRelatedDiagnosis([]);
                    return true;
                }
            } else if (name === 'title' || name === 'description' || name == 'dueBy' || name === 'dictStatusId' || name === 'userRoleId'
                || name === 'additional.orderReferralType' || name === 'additional.orderReferralConsultDecs' || name === 'additional.orderReferralImagingStudy'
                || name === 'additional.orderReferralDiagnosticDesc' || name === 'additional.orderReferralBehaviorDesc' || name === 'additional.orderReferralTherapyDesc'
                || name === 'additional.orderReferralOtherDecs' || name === 'additional.RelatedDiagnoses' || name === 'additional.orderReferral' || name === 'additional.priority'
                || name === 'additional.provider' || name === 'additional.other' || name === 'additional.otherinput' || name === 'additional.deliverreport' || name === 'additional.appointment_date'
                || name === 'additional.appt' || name === 'additional.orderReferralCaseManagement') {
                return true;
            } else if (typeof requestContext?.patientRoleId === "undefined") {
                setPatientRoleProp(null)
                if (typeof requestContext?.dictRequestTypeId === "undefined") {
                    setShowReferral(false)
                    setDefault()
                }
            } else {
                setPatientRoleProp(requestContext?.patientRoleId)
            }

            return () => subscription.unsubscribe();
        }, [watch])
    });

    const callGetAllRequests = () => {
        let obj = {};
        obj["statusCodes"] = props.BaseRequest?.requestListContext?.requestListCategory?.codes;
        obj["assignedUserRoleId"] = props.BaseRequest?.requestListContext?.assignedUser;
        obj["requestedUserRoleId"] = props.BaseRequest?.requestListContext?.createdUser;
        let payload = {
            "payload": obj,
            "limit": 100,
            "offset": 0,
            "sort": {
                "sorts": [
                    {
                        "field": "string",
                        "dir": "string"
                    }
                ]
            }
        }
        props.BaseRequestActions.getAllRequests(payload);
    }
    const saveRequest = (form) => {
        setIsNewDocument(false)

        setRequestId(null)

        let requestPayload = {}
        requestPayload.title = form?.title
        requestPayload.patientRoleId = form.patientRoleId?.patientRoleId ? form.patientRoleId?.patientRoleId : form.patientRoleId
        requestPayload.description = form.description
        requestPayload.assignedUserRoleId = form.userRoleId?.userRoleId ? form.userRoleId?.userRoleId : form.userRoleId
        requestPayload.dictStatusId = form.dictStatusId?.id ? form.dictStatusId?.id : form.dictStatusId
        requestPayload.dictRequestTypeId = form.dictRequestTypeId?.id ? form.dictRequestTypeId.id : form.dictRequestTypeId
        requestPayload.dueDate = form?.dueBy ? moment(form.dueBy).format("YYYY-MM-DD") : null
        requestPayload.form = form.dictRequestTypeId?.code === "AH_REFERRAL" ? JSON.stringify(form.additional) : ''
        requestPayload.encounterId = props?.encounterContext?.id;
        requestPayload.documentRef = fileValue ? fileValue : ''
        requestPayload.urgent = form?.urgent
        if (requestContext || isNewDocument) {
            requestPayload.id = (isNewDocument) ? createRequestContext.baseRequestId : requestContext.id
            BaseRequestActions.updateRequest(requestPayload).then(() => {
                callGetAllRequests()
                if (props?.encounterContext?.id)
                    props.EncounterAction.getEncounter({id: props.encounterContext?.id});
                hideDialog()
            })
        } else {
            BaseRequestActions.createRequest(requestPayload).then(() => {
                callGetAllRequests()
                if (props?.encounterContext?.id)
                    props.EncounterAction.getEncounter({id: props.encounterContext?.id});
                hideDialog()
            })
        }
    }

    const saveRequestBeforeAddDocument = (form) => {

        let patientRoleId = form.patientRoleId?.patientRoleId ? form.patientRoleId?.patientRoleId : form.patientRoleId
        if (patientRoleId) {
            if (requestContext) {
                setIsNewDocument(false)
                props.PopupActions.showWindow(PopupWindows.CREATE_DOCUMENT_WINDOW, {
                    documentTypeCode: "AH_REQUESTS",
                    requestId: requestContext.id,
                    patientRoleId
                });
            } else {

                if (requestId) {
                    setIsNewDocument(true)
                    props.PopupActions.showWindow(PopupWindows.CREATE_DOCUMENT_WINDOW, {
                        requestId,
                        documentTypeCode: "AH_REQUESTS",
                        patientRoleId
                    });
                } else {
                    setIsNewDocument(true)
                    let requestPayload = {}
                    requestPayload.title = form?.title
                    requestPayload.patientRoleId = patientRoleId
                    requestPayload.description = form.description
                    requestPayload.assignedUserRoleId = form.userRoleId?.userRoleId ? form.userRoleId?.userRoleId : form.userRoleId
                    requestPayload.dictStatusId = form.dictStatusId?.id ? form.dictStatusId?.id : form.dictStatusId
                    requestPayload.dictRequestTypeId = form.dictRequestTypeId?.id ? form.dictRequestTypeId.id : form.dictRequestTypeId
                    requestPayload.dueDate = form?.dueBy ? moment(form.dueBy).format("YYYY-MM-DD") : null
                    requestPayload.form = form.dictRequestTypeId?.code === "AH_REFERRAL" ? JSON.stringify(form.additional) : ''
                    requestPayload.urgent = form?.urgent
                    //requestPayload.documentRef = fileValue ? fileValue.name : ''
                    //requestPayload.documentRef = fileValue ? fileValue : ''

                    BaseRequestActions.createRequest(requestPayload).then((resp) => {
                        //setIsNewDocument(false)
                        props.PopupActions.showWindow(PopupWindows.CREATE_DOCUMENT_WINDOW, {
                            patientRoleId,
                            documentTypeCode: "AH_REQUESTS",
                            requestId: resp?.payload?.baseRequestId
                        });

                    })
                }
            }
        } else addToast({severity: "error", content: "Must choose a patient selected to upload a document."})

    }


    React.useEffect(() => {
        //if (createRequestContext && isESign) {
        if (createRequestContext && isNewDocument) {
            setRequestId(createRequestContext.baseRequestId)
            //console.log("requst id.....",requestId,"....createRequestContext...",createRequestContext)
        } else {
            //console.log("inside else.....")
            setRequestId(null)
        }

    }, [createRequestContext])


    React.useEffect(() => {
        //console.log("context......")
        //if(clinicalDocument && documentContextCheck){
        if (clinicalDocument) {
            //console.log("..clinicalDocument payload..",clinicalDocument.payload[0].fileName)
            setFileValue(clinicalDocument.payload[0].fileName)
            setClinicalDocumentId(clinicalDocument.payload[0].clinicalDocumentId)
            //setDocumentList(props.getClinicalDocument)

            // const payload = {
            //     patientRoleId: patientRoleProp,
            //     userRoleId: props.loggedinUser.userRoleId,
            //     dictDocumentCategoryId: documentId
            // }
            if (requestId) {

                const payload = {
                    requestId: requestId,
                }

                //console.log("CD ...requestId...",requestId,"...payload...",payload)

                if (Object.keys(payload).length > 0) {
                    setTimeout(() => {
                        props.PatientActions.getClinicalDocument(payload);
                    }, 500);
                }
            }
        } else {
            setFileValue("")
            setClinicalDocumentId('')
        }
    }, [clinicalDocument])


    function convertUTCDateToLocalDate(date) {
        let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        return newDate;
    }

    React.useEffect(() => {
        hideDialog();
    }, [RequestUpdated]);

    function printPage(id) {
        exportPDFWithComponent();
    }

    const printNewPage = (form) => {
        // console.log("...form...")
        // console.log(form.additional);
        // console.log(form.additional.RelatedDiagnoses);
        //let related_diagnosis = (form.additional && form.additional.RelatedDiagnoses) ? form.additional.RelatedDiagnoses : [];
        if (form.additional && form.additional.RelatedDiagnoses) {
            setRelatedDiagnosis({
                gridData: form.additional.RelatedDiagnoses.map(item => {

                    if (!item.diagnosisCode.length) {
                        item.diagnosisCode.code = ""
                        item.diagnosisCode.displayName = item.displayName
                    } else {
                        item.diagnosisCode = item.diagnosisCode[0]
                    }
                    return item
                })
            })
        }
        exportPDFWithComponent();
    }

    const Switch = props => {
        const {value, children} = props
        return children.find(child => {
            return child.props.case === value
        })
        return null;
    }


    const eSignDocument = debounce((e) => {

        if (eSignPass.current && eSignPass.current.value !== "") {

            let payload =
                {
                    "password": eSignPass.current.value,
                    "fullName": `${props.loggedinUser?.firstName ? props.loggedinUser?.firstName : ""}${props.loggedinUser?.middleName ? " " + props.loggedinUser?.middleName : ""}${props.loggedinUser?.lastName ? " " + props.loggedinUser?.lastName : ""}`,
                    "title": getValues("title"),
                    "userRoleId": props.loggedinUser.userRoleId,
                    "patientRoleId": getValues("patientRoleId"),
                    "eSignStatus": true,
                    "referralId": getValues("requestId")
                }

            if (getValues("title") && props.loggedinUser.userRoleId && getValues("patientRoleId")) {

                props.BaseRequestActions.createeSign(payload).then((payload) => {

                    // if (payload.payload) {
                    //     // setValidotp(true);
                    // } else {
                    //     // setValidotp(false);
                    //     // setError(payload.message);
                    // }

                    getEsign()

                })
            } else {
                // error = "Please Enter OTP";
            }
        }

        // setError(error);
    }, 1000);


    React.useEffect(() => {

        props.PersonActions.getContactPhone(props.loggedinUser.personId)
        // props.UserActions.getUser(payload)

    }, []);


    React.useEffect(() => {

        props.BaseRequestActions.getpatientModel(patientRoleProp)
        props.DictionaryActions.searchDictionaryList(Dictionary.AH_DOCUMENT_CATEGORIES, 1, 0, null, {code: Dictionary.AH_REQUESTS})
        if (patientRoleProp) {
            setErrMessage("")
            setShowUploadDocument(true)
            //setDocumentList(props.getClinicalDocument)
        } else {
            setErrMessage("")
            setShowUploadDocument(false)
        }

    }, [patientRoleProp]);


    React.useEffect(() => {
        if (requestId) {
            setDocumentList(props.getClinicalDocument)
        }

    }, [props.getClinicalDocument]);

    React.useEffect(() => {
        setPrintdata(
            props.BaseRequest.patientModel
        )
        // props.UserActions.getUser(payload)

    }, [props.BaseRequest.patientModel]);

    React.useEffect(() => {
        if (typeof props.dictionaries['AH_DOCUMENT_CATEGORIES'] !== "undefined") {
            // setDocumentId(
            //     props.dictionaries['AH_DOCUMENT_CATEGORIES'][0].id
            // )
            props.dictionaries['AH_DOCUMENT_CATEGORIES'].map(item => {

                if (item.code == Dictionary.AH_REQUESTS) {
                    setDocumentId(item.id)
                }

            });

        }


    }, [props.dictionaries['AH_DOCUMENT_CATEGORIES']]);


    const viewDocument = dataItem => {

        props.PatientActions.clearPatientContext().then(() => {
                props.PatientActions.setPatientContext(patientData).then(() => {
                    history.push(`/patientPanel`)

                    setTimeout(() => {
                        WindowActions.setWindowState("PatientPanelTabComponent", {selectedTab: 7})

                        props.DictionaryActions.getDictionaryByCode('AH_DOCUMENT_CATEGORIES', 500, 0);
                        props.PatientActions.setClinicalDocumentContext({
                            ...dataItem,
                            setMessage: setMessage,
                            setIsToast: setIsToast
                        })

                        // download document
                        const payload = {
                            fileReferenceId: dataItem.dataItem.fileReferenceId
                        }
                        props.PatientActions.clinicalDocumentDownload(payload)

                    }, 1000);

                    hideDialog();
                })
                //Lets use the context pattern here.

            }
        )
    }


    const isDisable = () => {
        if (referralPermissions.isEdit) {
            return false
        } else if (referralPermissions.isView || generalTaskPermissions.isView || generalTaskPermissions.isEdit) {
            return true;
        }
        return false;
    }

    const isEditableRequest = () => {
        if (referralPermissions.isEdit) {
            return {};
        } else if (generalTaskPermissions.isEdit) {
            if (!requestContext || requestContext.dictRequestTypeCode === "AH_GENERAL_TASK") {
                return {};
            }
        }

        return {pointerEvents: 'none'};
    }

    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'urgent') {
                if (value.urgent) {
                    setValue("dueBy", moment().toDate())
                } else if (requestContext && requestContext?.dueDate) {
                    setValue("dueBy", moment(requestContext?.dueDate, "YYYY-MM-DD").toDate());
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const localtime = props.BaseRequest?.eSign?.eSignStatus ? convertUTCDateToLocalDate(new Date(props.BaseRequest?.eSign?.timeStamp)) : null;
    return (
        <Dialog
            visible={props.showWindow[PopupWindows.CREATE_REQUEST_WINDOW]}
            header={requestContext ? "Edit Task" : "New Task"}
            footer={
                <div>
                    <Button
                        type="button"
                        outlined={false}
                        onClick={handleSubmit(saveRequest)}
                        label={requestContext?.id ? "Update" : "Save"}/>
                    <Button
                        severity={"danger"}

                        onClick={hideDialog}
                        label={"Cancel"}/>
                </div>}
            onHide={hideDialog}
            style={{height: 500, width: 800}}
            maximizable>
            <div style={isEditableRequest()}>
                <form id="base-request-form"
                      onSubmit={handleSubmit(saveRequest)}
                      className="k-form k-form-vertical base-request-form">
                    <FieldSection>
                        <FieldRow>
                            <Field col={8}
                                   label={<Label>Title <span style={{color: "red"}} className="star">*</span></Label>}
                                   field={"title"} error={errors} register={register}
                                   control={control}
                                   options={{required: "Title is required."}}>
                                <FieldTextInput/>
                            </Field>
                            <Field col={3} label={"Status"} field={"dictStatusId"} error={errors}
                                   register={register}
                                   control={control}
                                   options={{required: "Status is required."}}>
                                <FieldDictionaryDropDown
                                    code={Dictionary.AH_REQUEST_STATUS}
                                    defaultItemCode={"AH_NOT_STARTED"}
                                />
                            </Field>
                            <Field col={1} label={"Urgent"} field={"urgent"} error={errors} register={register}
                                   control={control} value="true">
                                <FieldCheckboxInput/>
                            </Field>
                        </FieldRow>

                        <FieldRow>
                            <Field col={6} label={"Patient"} field={"patientRoleId"} error={errors}
                                   register={register}
                                   control={control}>
                                <FieldPatientDropdown
                                    defaultItemCode={props?.encounterContext?.id ? props.encounterContext.patientRoleId : null}/>
                            </Field>
                            <Field col={6}
                                   label={<Label>Assigned to user <span style={{color: "red"}} className="star">*</span></Label>}
                                   field={"userRoleId"} error={errors}
                                   register={register}
                                   control={control}
                                   options={{required: "User assignment is required."}}>
                                <FieldUserDropDown/>
                            </Field>
                        </FieldRow>
                        <FieldRow>
                            <Field col={6} label={"Due By"} field={"dueBy"} error={errors} register={register}
                                   control={control}>
                                <FieldDatePicker/>
                            </Field>
                            <Field col={6} label={"Task Type"} field={"dictRequestTypeId"} error={errors}
                                   register={register}
                                   control={control}
                                   disabled={isDisable()}
                                   options={{required: "Request Type is Required."}}>
                                <FieldDictionaryDropDown
                                    code={Dictionary.AH_REQUEST_TYPE}
                                    active={true}
                                    defaultItemCode={"AH_GENERAL_TASK"}
                                />
                            </Field>
                        </FieldRow>
                        <FieldRow>
                            <Field col={12} label={"Description"} field={"description"} error={errors}
                                   register={register}
                                   control={control}>
                                <FieldTextAreaInput/>
                            </Field>

                        </FieldRow>

                        <FieldRow>
                            {/* {showUploadDocument &&
                                    <Field col={6} label={""} field={"fileName"} error={errors}
                                           control={control} register={register}>
                                        <input
                                            onChange={async (e) => {
                                                const inputImage = e?.target?.files[0];
                                                let image = await getBase64(inputImage)
                                                const validate = fileValidation(inputImage);
                                                setFileValue(inputImage);
                                            }}
                                            accept="image/*"
                                            id="file"
                                            className="hidden"
                                            type="file"
                                            name="documentRef"
                                            ref={inputFileRef}
                                        />
                                    </Field>} */}
                            {/* <i class="fa fa-upload"></i> */}

                            {/* {!showUploadDocument && <Column col={4}>
                                    <Label><i class="fa fa-upload" onClick={(e) => {
                                        setShowUploadDocument(true);
                                        setTimeout(() => {
                                            inputFileRef.current.click()
                                        }, 500);
                                    }} style={{fontSize: "2rem", cursor: "pointer"}}
                                              aria-hidden="true"></i>&nbsp;&nbsp;</Label>
                                </Column>} */}

                            <Column col={4}>
                                <Button
                                    type="button"
                                    size={"small"}
                                    onClick={handleSubmit(saveRequestBeforeAddDocument)}
                                    className="add-dictionary-button"
                                    iconClass={"k-icon k-i-plus"}
                                >
                                    Add Document
                                </Button>
                                <br/>
                                <span style={{color: "red"}}>{errMessage}</span>
                            </Column>

                            <Column col={8}>

                                <Grid
                                    // style={{height: "calc(100% - 50px)"}}
                                    style={{width: "100%"}}
                                    //data={printdata?.medicalProblemHasDiagnosisCode}
                                    data={documentList}
                                >
                                    <GridColumn field="displayName" title="Documents" filterable={false}/>
                                    {/* <GridColumn cell={CommandCell} width="80px" filterable={false}/> */}
                                </Grid>
                            </Column>

                        </FieldRow>

                    </FieldSection>


                    {/*TODO: This needs some serious cleanup work!!*/}
                    {/* @BITTU This isn't how you use watch*/}
                    {showReferral && (
                        <FieldSection>
                            <input type='hidden' value='prayer'/>
                            <hr/>
                            <div className="row">
                                <div className=" dev-bdr">
                                    <b><u>Additional details</u></b>:
                                </div>
                            </div>
                            <br/>
                            <Column col={12}>
                                <Label className="w-100 bold" editorId={id}>Order/Referral Type: <span
                                    style={{color: "red"}} className="star">*</span></Label>
                            </Column>

                            <FieldRow>
                                <Field col={4} label={"Speciality Consultation"}
                                       name={"additional.orderReferralType"}
                                       field={"additional.orderReferralType"} value="Specify Consultation"
                                       error={errors}
                                       control={control}
                                       options={{required: "Order/Referral Type is required."}}
                                       position="right"
                                       hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>
                                <Field col={2} label={""} field={"additional.orderReferralConsultDecs"}
                                       name={"additional.orderReferralConsultDecs"} error={errors}
                                       placeholder="Specify"
                                       register={register}>
                                    <FieldTextInput/>
                                </Field>
                                <Field col={4} label={"Therapy (Specify PT, OT, Speech)"}
                                       name={"additional.orderReferralType"} field={"additional.orderReferralType"}
                                       value="Therapy (Sepcity PT, OT, Speech)" error={errors} position="right"
                                       control={control}
                                       options={{required: "Order/Referral Type is required."}}
                                       hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>
                                <Field col={2} label={""} field={"additional.orderReferralTherapyDesc"}
                                       name={"additional.orderReferralTherapyDesc"} error={errors}
                                       placeholder="Specify"
                                       register={register}>
                                    <FieldTextInput/>
                                </Field>
                            </FieldRow>

                            <FieldRow>
                                <Field col={4} label={"Imaging Study"} name={"additional.orderReferralType"}
                                       field={"additional.orderReferralType"} value="Imaging Study" error={errors}
                                       control={control}
                                       options={{required: "Order/Referral Type is required."}}
                                       position="right" hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>
                                <Field col={2} label={""} field={"additional.orderReferralImagingStudy"}
                                       name={"additional.orderReferralImagingStudy"} error={errors}
                                       placeholder="Specify"
                                       register={register}>
                                    <FieldTextInput/>
                                </Field>
                                <Field col={4} label={"Home Health Care"} name={"additional.orderReferralType"}
                                       field={"additional.orderReferralType"} value="Home Health Care"
                                       error={errors}
                                       control={control}
                                       options={{required: "Order/Referral Type is required."}}
                                       position="right" hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>
                                <Column col={2}>

                                </Column>
                            </FieldRow>

                            <FieldRow>
                                <Field col={4} label={"Diagnostic Testing"} name={"additional.orderReferralType"}
                                       field={"additional.orderReferralType"} value="Diagnostic Testing"
                                       error={errors}
                                       control={control}
                                       options={{required: "Order/Referral Type is required."}}
                                       position="right" hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>
                                <Field col={2} label={""} field={"additional.orderReferralDiagnosticDesc"}
                                       name={"additional.orderReferralDiagnosticDesc"} error={errors}
                                       placeholder="Specify"
                                       register={register}>
                                    <FieldTextInput/>
                                </Field>
                                <Field col={4} label={"Wound Care"} name={"additional.orderReferralType"}
                                       field={"additional.orderReferralType"} value="Wound Care" error={errors}
                                       control={control}
                                       options={{required: "Order/Referral Type is required."}}
                                       position="right" hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>
                                <Column col={2}>

                                </Column>
                            </FieldRow>

                            <FieldRow>
                                <Field col={4} label={"Behavioral Health"} name={"additional.orderReferralType"}
                                       field={"additional.orderReferralType"} value="Behaviaral Health"
                                       error={errors}
                                       control={control}
                                       options={{required: "Order/Referral Type is required."}}
                                       position="right" hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>
                                <Field col={2} label={""} field={"additional.orderReferralBehaviorDesc"}
                                       name={"additional.orderReferralBehaviorDesc"} error={errors}
                                       placeholder="Specify"
                                       register={register}>
                                    <FieldTextInput/>
                                </Field>
                                <Field col={4} label={"Palliative Care"} name={"additional.orderReferralType"}
                                       field={"additional.orderReferralType"} value="Polliative Care" error={errors}
                                       control={control}
                                       options={{required: "Order/Referral Type is required."}}
                                       position="right" hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>

                                <Column col={2}>

                                </Column>
                            </FieldRow>

                            <FieldRow>
                                <Field col={4} label={"Case Management"} name={"additional.orderReferralType"}
                                       field={"additional.orderReferralType"} value="Case Management" error={errors}
                                       control={control}
                                       options={{required: "Order/Referral Type is required."}}
                                       position="right" hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>
                                <Field col={2} label={""} field={"additional.orderReferralCaseManagement"}
                                       name={"additional.orderReferralCaseManagement"} error={errors}
                                       placeholder="Specify"
                                       register={register}>
                                    <FieldTextInput/>
                                </Field>

                                <Field col={4} label={"Other"} name={"additional.orderReferralType"}
                                       field={"additional.orderReferralType"} value="Other" error={errors}
                                       control={control}
                                       options={{required: "Order/Referral Type is required."}}
                                       position="right" hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>
                                <Field col={2} label={""} field={"additional.orderReferralOtherDecs"}
                                       name={"additional.orderReferralOtherDecs"} error={errors}
                                       placeholder="Specify"
                                       form
                                       register={register}>
                                    <FieldTextInput autoComplete={"new-password"}/>
                                </Field>
                            </FieldRow>

                            {/* <FieldRow>
                                    <Column col={6}></Column>
                                    <Field col={4} label={"Other"} name={"additional.orderReferralType"}
                                           field={"additional.orderReferralType"} value="Other" error={errors}
                                           control={control}
                                           options={{required: "Order/Referral Type is required."}}
                                           position="right" hideError={true}
                                           register={register}>
                                        <FieldRadioInput/>
                                    </Field>
                                    <Field col={2} label={""} field={"additional.orderReferralOtherDecs"}
                                           name={"additional.orderReferralOtherDecs"} error={errors}
                                           placeholder="Specify"
                                           form
                                           register={register}>
                                        <FieldTextInput autoComplete={"new-password"}/>
                                    </Field>
                                </FieldRow> */}

                            <FieldRow>
                                <Field col={12} label={"Related Diagnoses-?"} field={"additional.RelatedDiagnoses"}
                                       error={errors}
                                       control={control}

                                       register={register}>
                                    <FieldMultiProblemSelector isActive={false} patientRoleIDprop={patientRoleProp}
                                                               propReferralCheck="Yes" style={{height: "250px"}}
                                                               height={"400px"}/>
                                </Field>
                                {/* <Field col={12} label={"Related Diagnoses *"}
                                           field={"additional.RelatedDiagnoses"} error={errors}
                                           options={{required: "Related Diagnoses is required."}}
                                           control={control}
                                           register={register}>
                                        <FieldTextAreaInput/>
                                    </Field> */}
                            </FieldRow>

                            <FieldRow>
                                <Field col={12} label={<Label className="w-100
" editorId={id}>Reason for Order/Referral<span style={{color: "red"}}/></Label>}
                                       field={"additional.orderReferral"} error={errors}
                                       control={control}
                                       register={register}>
                                    <FieldTextAreaInput/>
                                </Field>
                            </FieldRow>

                            <FieldRow>

                                <Column col={1}>
                                    <Label editorId={id}>Priority:</Label>
                                </Column>
                                <Field col={2} label={"Routine"} name={"additional.priority"}
                                       field={"additional.priority"} value="Routine" error={errors}

                                       position="right" hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>
                                <Field col={2} label={"Urgent"} name={"additional.priority"}
                                       field={"additional.priority"} value="Urgent" error={errors}

                                       position="right" hideError={true}
                                       register={register}>
                                    <FieldRadioInput/>
                                </Field>
                                {/* <Field col={2} label={"Routine"} name={"additional.priority"}
                                           field={"additional.priority"} value="Routine" error={errors} position="right"
                                           register={register}>
                                        <FieldRadio value='Routine' defaultChecked='Routine'/>
                                    </Field>
                                    <Field col={2} label={"Urgent"} name={"additional.priority"}
                                           field={"additional.priority"} value="Urgent" error={errors} position="right"
                                           register={register}>
                                        <FieldRadio value='Urgent'/>
                                    </Field> */}
                            </FieldRow>

                            {/* <FieldRow>
                                    <Column col={1}>
                                        {requestContext ?
                                            <Button className="m-0" type="button" onClick={eSignDocument}>eSign
                                                <span
                                                    role="presentation"
                                                    className="k-icon k-i-arrow-chevron-right"

                                                ></span>
                                            </Button> :

                                            <Button className="m-0" type="button"
                                                    onClick={handleSubmit(saveRequestBeforeEsign)}>eSign
                                                <span
                                                    role="presentation"
                                                    className="k-icon k-i-arrow-chevron-right"

                                                ></span>
                                            </Button>
                                        }

                                    </Column>
                                    <Column col={1}>
                                    </Column>
                                    {showESign &&
                                        <Column col={4}>
                                            {props.BaseRequest?.eSign?.eSignStatus ? <span style={{"paddingTop": "7px"}}>
                                            {props.BaseRequest?.eSign?.fullName} {moment(new Date(localtime)).format("dddd, MMMM D, YYYY hh:mm A")}
                                        </span> :
                                                <form>
                                                    <input ref={eSignPass}
                                                           type={"password"}
                                                           placeholder="Enter Password"
                                                           id="eSignPass"
                                                           autoComplete={"new-password"}
                                                        //onChange={eSignDocument}
                                                    />
                                                </form>
                                            }
                                        </Column>
                                    }

                                </FieldRow> */}

                            <hr/>

                            <FieldRow>
                                <Column col={1}>
                                    <Label className="w-100 bold" editorId={id}>Provider<span style={{color: "red"}}
                                                                                              className="star">*</span></Label>
                                </Column>

                                <Field col={4} label={""} field={"additional.provider"} error={errors}
                                       options={{required: "Provider is required."}}
                                       control={control}
                                       register={register}>
                                    <FieldTextAreaInput rows="5"/>
                                </Field>

                                <Field col={4} label={"Other"} field={"additional.other"} position="right"
                                       error={errors} control={control}
                                       register={register}>
                                    <FieldCheckboxInput/>
                                </Field>

                            </FieldRow>

                            {showAdditionalOther && (<FieldRow>
                                <Column col={1}>

                                </Column>
                                <Field col={11} label={""} field={"additional.otherinput"} error={errors}
                                       register={register}
                                       control={control}
                                       placeholder={"Enter Provider Details"}
                                >
                                    <FieldTextAreaInput/>
                                </Field>
                            </FieldRow>)}

                            <FieldRow>
                                <Column col={5}>
                                    <Label editorId={id} style={{"fontWeight": "bold"}}>Please deliver
                                        report/recommendations via <span style={{color: "red"}}
                                                                         className="star">*</span></Label>

                                </Column>
                                <Column col={7}>
                                    <FieldRow>
                                        <Field col={12} label={""} field={"additional.deliverreport"} error={errors}
                                               control={control}
                                               options={{required: "Report delivery is required."}}
                                               register={register}>
                                            {/* <FieldDropDown
                                                    data={["Secure email: secure@newco.com", "Portal Link:https:\/\/portal.new.co"]}/> */}
                                            <FieldDictionaryDropDown
                                                code={Dictionary.AH_REFERRAL_REPORT_DELIVERY}
                                                defaultItemCode={"AH_FAX"}
                                            />
                                        </Field>

                                    </FieldRow>
                                </Column>
                            </FieldRow>

                            <FieldRow>
                                <Field col={6} label={"Appointment Date/Time"} field={"additional.appointment_date"}
                                       error={errors} control={control}
                                       register={register}>
                                    <FieldDateTimePicker/>

                                </Field>

                                <Field col={6} className="appt" label={"Appt Coordinated with patient?"}
                                       field={"additional.appt"} name={"additional.appt"} error={errors}
                                       position="right" value="true" control={control}
                                       register={register}>
                                    <FieldCheckboxInput/>

                                </Field>
                            </FieldRow>

                            <div className="row">
                                <div className="">
                                    {requestContext ?
                                        <Button className="m-0" type="button"
                                                icon="k-i-print k-i-printer"
                                                onClick={() => printPage("")}
                                        >Print
                                            <span
                                                role="presentation"
                                                className="k-icon k-i-arrow-chevron-right"
                                            ></span>
                                        </Button>

                                        :

                                        <Button className="m-0" type="button"
                                                icon="k-i-print k-i-printer"
                                            //onClick={() => printPage("")}
                                                onClick={handleSubmit(printNewPage)}
                                        >Print
                                            <span
                                                role="presentation"
                                                className="k-icon k-i-arrow-chevron-right"
                                            ></span>
                                        </Button>
                                    }
                                </div>
                            </div>
                            <br/>

                        </FieldSection>)}

                    <PDFExport
                        ref={pdfExportComponent}
                        pageTemplate={PDFPageTemplate}
                        // paperSize="auto"
                        // margin={40}
                        paperSize="A4"
                        margin="0.5cm"
                        scale={0.6}
                        fileName={`Report for ${new Date().getFullYear()}`}
                        author="KendoReact Team"
                    >
                        {isExporting && <div>
                            <div id="adform">
                                <div className="container" style={{width: "90% !important"}}>
                                    <div className="row">
                                        <h1 className="w-100 bold company-name"

                                        >
                                            {""}
                                        </h1>
                                    </div>
                                    <div className="row">
                                        <h4 className="w-100 bold">
                                            Order/Referral Type: {watch("additional.orderReferralType")}
                                            {(showReferral && watch("additional.orderReferralType")) &&
                                                <Switch value={watch("additional.orderReferralType")}>
                                                        <span
                                                            case={"Specify Consultation"}>&nbsp;&nbsp;&nbsp;{" " + watch("additional.orderReferralConsultDecs")}</span>
                                                    <span
                                                        case={"Therapy (Sepcity PT, OT, Speech)"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" " + watch("additional.orderReferralTherapyDesc")}</span>
                                                    <span
                                                        case={"Imaging Study"}>&nbsp;&nbsp;&nbsp;{" " + watch("additional.orderReferralImagingStudy")}</span>
                                                    <span
                                                        case={"Home Health Care"}>&nbsp;&nbsp;&nbsp;{" "}</span>
                                                    <span
                                                        case={"Diagnostic Testing"}>&nbsp;&nbsp;&nbsp;{" " + watch("additional.orderReferralDiagnosticDesc")}</span>
                                                    <span
                                                        case={"Wound Care"}>&nbsp;&nbsp;&nbsp;{" "}</span>
                                                    <span
                                                        case={"Behaviaral Health"}>&nbsp;&nbsp;&nbsp;{" " + watch("additional.orderReferralBehaviorDesc")}</span>
                                                    <span
                                                        case={"Polliative Care"}>&nbsp;&nbsp;&nbsp;{" "}</span>
                                                    <span
                                                        case={"Case Management"}>&nbsp;&nbsp;&nbsp;{" " + watch("additional.orderReferralCaseManagement")}</span>
                                                    <span
                                                        case={"Other"}>&nbsp;&nbsp;&nbsp;{" " + watch("additional.orderReferralOtherDecs")}</span>
                                                    <span></span>
                                                </Switch>}
                                        </h4>
                                        <h5 className="w-100 bold">
                                            Priority: {watch("additional.priority")}
                                        </h5>
                                    </div>
                                    <br/>

                                    <div className="row">
                                        <div className="w-50">
                                            <h4 className="w-100 bold">{printdata?.patientInfo?.lastName}, {printdata?.patientInfo?.firstName} {printdata?.patientInfo?.middleName}</h4>
                                            <div className="w-50">Date of
                                                Birth:{printdata?.patientInfo?.dateOfBirth} {"    "} </div>
                                            <div
                                                className="w-50">Sex:&nbsp;&nbsp;{printdata?.patientInfo?.genderName}  </div>
                                            <br/>
                                            <div className="w-100">Phone: {printdata?.patientInfo?.phone}</div>
                                            <div className="w-100">Email: {printdata?.patientInfo?.email}</div>
                                        </div>
                                        <div className="w-50">
                                            <h5 className="w-100 bold" style={{fontWeight: "bold"}}>Ordering
                                                Provider</h5>
                                            <div
                                                className="w-100">Name: {orderingProvider?.lastName || ""}, {orderingProvider?.firstName || ""} {orderingProvider?.middileName || ""}</div>
                                            <div
                                                className="w-100">{orderingProvider?.dictProviderTypeDisplayName}</div>

                                            <div className="w-100">Contact
                                                Phone: {primaryCareUser?.phone}</div>
                                        </div>
                                    </div>
                                    {/* <br/> */}
                                    <hr/>
                                    <div className="row">
                                        <div className="w-100">Reason for order/referral:</div>
                                        <span className="w-100 ">
                                                {watch("additional.orderReferral")}
                                            </span>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="w-50" style={{paddingRight: "0px !important"}}>
                                            <span>Related Diagnoses---</span>
                                            <Grid
                                                // style={{height: "calc(100% - 50px)"}}
                                                style={{width: "100%"}}
                                                className={"relatedDiagnosisGrid"}
                                                //data={printdata?.medicalProblemHasDiagnosisCode}
                                                data={relatedDiagnosis.gridData}
                                            >
                                                <GridColumn field="diagnosisCode.displayName" title="Diagnosis.."/>
                                                <GridColumn field="diagnosisCode.code" title="Code."/>
                                            </Grid>
                                        </div>

                                        <div className="w-50" style={{verticalAlign: "top"}}>
                                            <span>Known Allergies {(printdata?.allergyProblem.length) ? '' : ': None Known'}</span>


                                            <Grid
                                                // style={{height: "calc(100% - 50px)"}}
                                                style={(printdata?.allergyProblem.length) ? {width: "100%"} : {
                                                    width: "100%",
                                                    display: "none"
                                                }}
                                                data={printdata?.allergyProblem}
                                                filter={filter}
                                                onFilterChange={(e) => setFilter(e.filter)}
                                            >
                                                <GridColumn field="displayName" title="Conditions.."/>
                                            </Grid>

                                        </div>
                                    </div>
                                    {/* <br/> */}
                                    <br/>
                                    <div className="row">
                                        <div className="w-100" style={{fontWeight: "bold"}}>Referred to Provider
                                        </div>
                                        <div className="w-100">

                                            <Field col={12}
                                                   field={"additional.provider"} error={errors}
                                                   control={control}
                                                   register={register}>
                                                <FieldTextAreaInput style={{border: 'none'}} rows="3"/>
                                            </Field>
                                        </div>
                                        <div
                                            className="w-100">{watch("additional.other") && watch("additional.otherinput")} </div>
                                    </div>
                                    <br/>
                                    {watch("additional.deliverreport") && <div className="row">
                                        <div className="w-100 bold">Please deliver report/recommendations
                                            via: {watch("additional.deliverreport.displayName")}</div>
                                    </div>}
                                    {watch("additional.appointment_date") && <div className="row">
                                        <div className="w-100 bold">An Appointment has already been scheduled
                                            for: {moment(new Date(watch("additional.appointment_date"))).format("MM, DD, YYYY hh:mm A")}</div>
                                    </div>}
                                    {/* <div className="row">
                                            <div>

                                                <div style={{padding: '20px 0 0 0'}}>
                                                    <br/><br/><br/><br/>
                                                    <span style={{width: '100%', float: 'left', textAlign: 'right'}}>
                              Signature {props.BaseRequest?.eSign?.eSignStatus ? <span style={{"paddingTop": "7px"}}>
                                {props.BaseRequest?.eSign?.fullName}&nbsp;&nbsp;&nbsp;&nbsp;{moment(new Date(localtime)).format("dddd, MMMM D, YYYY hh:mm A")}</span> : ""}
                            </span>
                                                    <hr/>
                                                </div>

                                            </div>
                                        </div> */}
                                    <br/>
                                </div>
                            </div>
                        </div>}
                    </PDFExport>

                </form>
            </div>
        </Dialog>
    );
};

AddNewRequestWindow.propTypes = {
    PopupActions: PropTypes.object,
    PatientActions: PropTypes.object,
    patient: PropTypes.object,
    patientList: PropTypes.array,
    userList: PropTypes.array,
    loggedinUser: PropTypes.object,
    createRequestContext: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        patient: state.patient,
        careTeamList: state.patient.careTeam.careTeamList,
        careTeamContext: state.patient.careTeam.careTeamContext,
        patientList: state.patient.patientList,
        userList: state.user.userList,
        allUsers: state.user.allUsers,
        loggedinUser: state.user.loggedinUser,
        RequestUpdated: state.BaseRequest.RequestUpdated,
        BaseRequest: state.BaseRequest,
        requestContext: state.BaseRequest.RequestContext,
        userRoleId: state.auth.session.userRoleId,
        phones: state.person.personContextPhone,
        dictionaries: state.dictionary.dictionaries,
        getClinicalDocument: state.patient.getClinicalDocument,
        clinicalDocument: state.patient.clinicalDocument,
        clinicalDocumentContext: state.patient.clinicalDocumentContext,
        patientContext: state.patient.patientContext,
        createRequestContext: state.BaseRequest.createRequestContext,
        encounterContext: state.encounters.encounterContext,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        BaseRequestActions: bindActionCreators(BaseRequestAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PatientActions: bindActionCreators(PatientAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        WindowActions: bindActionCreators(WindowActions, dispatch),
        EncounterAction: bindActionCreators(EncounterAction, dispatch),
        CareTeamActions: bindActionCreators(CareTeamAction, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddNewRequestWindow);
