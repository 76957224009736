import React, {PropsWithChildren, useContext, useState} from "react";


interface UpdateState {
    components: {
        [key: string]: number
    };
    update: (componentName: string) => void;
    register: (componentName: string) => void;
}

const defaultContext = {
    components: {},
    update: (componentName: string) => null,
    register: (componentName: string) => null
}
const ComponentUpdateContext = React.createContext<UpdateState>(defaultContext)


export function useComponentUpdate() {
    return useContext(ComponentUpdateContext)
}

export function ComponentUpdateProvider(props: PropsWithChildren) {
    const [components, setComponents] = useState<{
        [key: string]: number
    }>({})

    function update(componentName: string) {
        let num = (components?.[componentName] || 0) + 1
        setComponents({...components, [componentName]: num})
        console.log(`Updating: ${componentName} ${JSON.stringify(components)}`)

    }

    function register(key: string) {
        console.log(`Registering: ${key} ${JSON.stringify(components)}`)
        setComponents({...components, [key]: 0})
    }

    return (
        <ComponentUpdateContext.Provider value={{components, update, register}}>
            {props.children}
        </ComponentUpdateContext.Provider>
    )

}