import * as React from 'react';
import {Field, FieldWrapper, Form, FormElement} from "@progress/kendo-react-form";
import {requiredValidator} from "../FormValidators";
import {FormInput} from "../FormComponents";
import {Button} from "@progress/kendo-react-buttons";
import {bindActionCreators} from 'redux';
import {AuthAction} from '../../action/AuthAction';
import {connect} from "react-redux";
import {Input} from "@progress/kendo-react-inputs";
import {Error, Label} from "@progress/kendo-react-labels";

const styles = {

    formElement: {
        width: 280,
        height: "50%",
        position: "",
        fontsize: 80,
        paddingLeft: 50,
    },
    formField: {
        width: 200,
        fontsize: 80,
        padding: "0.5rem"
    },
    header: {
        fontWeight: "bolder",
        marginTop: "1.5rem",
        fontSize: "large",
    }
}
const PasswordInput = (fieldRenderProps) => {
    const { validationMessage, label, id, valid, disabled, optional, visited, ...others } = fieldRenderProps;
    return (
        <FieldWrapper>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>{label}</Label>
        <div className={'k-form-field'}>
            <Input {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
        </FieldWrapper>
    );
};
const ChangePassword = (props) => {
    const [currentPassword, setCurrentPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [passValidationMessage, setPassValidationMessage] = React.useState("");
    const [passSuccessMessage, setPassSuccessMessage] = React.useState("");
    const [isPasswordValid, setIsPasswordValid] = React.useState(false);

    const handleSubmit = () => {
        if (newPassword !== confirmPassword) {
            // alert("Passwords don't match"); 
            setPassValidationMessage("Passwords does not match!")
        } else if (newPassword === confirmPassword) {
            setPassValidationMessage("");
            props.authActions.changePassword(newPassword);
            setPassSuccessMessage("Successfully");
        }

    }
    const handleNewPassword = (event) => {
        props.authActions.checkPasswordExpression(event.target.value).then((messageResponse) => {
            let temp = messageResponse.payload;
            setIsPasswordValid(temp)
            if(temp== true){
                setNewPassword(event.value)
            }
        })

    }

    const requiredPasswordValidator = (value) =>{
        return (isPasswordValid) ? "" : "Please enter a valid password. Password must contain at least 8 characters and contain 1 lowercase character, 1 uppercase character, 1 number, and 1 special character (!@#$%^&)";
    }

    
    return <div>
        <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
                <FormElement style={styles.formElement}>
                    <legend style={styles.header}>
                        Change Password {passSuccessMessage}
                    </legend>
                    <Field
                        id={"currentPassword"}
                        name={"currentPassword"}
                        validator={requiredValidator}
                        label={"Enter Current Password:"}
                        component={FormInput}
                        type={"password"}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        style={styles.formField}
                    />
                    <Field
                        id={"newPassword"}
                        name={"newPassword"}
                        //validator={requiredValidator}
                        validator={requiredPasswordValidator}
                        label={"Enter New Password:"}
                        //component={FormInput}
                        component = {PasswordInput}
                        type={"password"}
                        //onChange={(e) => setNewPassword(e.target.value)}
                        onChange={handleNewPassword}
                        style={styles.formField}
                    />
                    <Field
                        id={"confirmPassword"}
                        name={"confirmPassword"}
                        label={"Confirm Password:"}
                        validator={requiredValidator}
                        type={"password"}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        component={FormInput}
                        style={styles.formField}
                    />
                    <span style={{ color: 'red', }}> {passValidationMessage} </span>
                    <div className="k-form-buttons">
                        <Button
                            type={"submit"}
                            disabled={!formRenderProps.allowSubmit}
                            primary={true}
                            // onClick={handleSubmit}
                            style={{ margin: "2rem" }}
                        >Reset Password</Button>
                    </div>
                </FormElement>
            )}
        />
    </div>;
};
function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)