import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {PatientAction} from "../action/PatientAction";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button} from "primereact/button";
import {DropDownButton, Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {Input} from "@progress/kendo-react-inputs";
import {orderBy} from "@progress/kendo-data-query";
import TileHeader from "../layout/TileHeader";
import {Components} from "./shared/ComponentRegistry";
import {PopupWindowActions, PopupWindows} from "../action/PopupWindowAction";
import {useHistory} from "react-router-dom";
import {TileContainer} from "../layout/TileContainer";
import {TileBody} from "../layout/TileBody";
import {TileRow} from "../layout/TileRow";
import {Col} from "react-grid-system";
import GlobalStyles from "../config/GlobalStyles";

const PatientListComponent = (props) => {
    const history = useHistory()
    const styles = {
        grid: {
            height: "100%",
        }

    }
    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });


    const [searchState, setSearchState] = React.useState("")


    const [filterState, setFilterState] = React.useState(false)

    const [filterPatientData, setFilterPatientData] = React.useState({
        logic: "and", filters: []

    })

    const filterChangeState = (filter) => {
        setFilterPatientData(filter)
    }

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };
    const initialSort = [
        {
            field: "lastName",
            dir: "asc",
        },
    ];
    const [sort, setPatientSort] = React.useState(initialSort);
    let filteredPatientList = props.patients ? props.patients : []
    const [displayDom, setDisplayDom] = React.useState(true);
    const [perMessage, setPerMessage] = React.useState('');

    React.useEffect(() => {
            let filters = {}
            if (searchState.length > 0) filters.searchAll = searchState
            if (filterPatientData?.filters) {
                if (filterPatientData.filters.length > 0) {
                    filterPatientData.filters.forEach(x => {
                        filters[x.field] = x.value
                    })
                }
            }
            const patientListPer = props.PatientActions.getPatientList(dataState.take, dataState.skip, sort, filters, false)
            patientListPer
                .then(val => {
                    if (val?.code === 'E-189') {
                        setDisplayDom(false)
                        setPerMessage(val.message)
                    }
                })
        }, [dataState, sort, filterPatientData, searchState]
    )

    const patientAddress = (props) => {
        const {street1 = "", street2 = "", city = "", state = "", zipCode = ""} = props.dataItem;
        const completeAddress = `${street1}${street2 ? ` ${street2}.` : ''}${city ? ` ${city}` : ''}${state ? ` ${state}` : ''}${zipCode ? ` ${zipCode}` : ''}`;
        if (completeAddress !== 'null' && completeAddress !== null) {
            return <td><span>{(completeAddress)}</span></td>
        } else {
            return <td><span></span></td>
        }
    }
    const patientDateAge = (props) => {
        const {dateOfBirth = ""} = props.dataItem;
        if (dateOfBirth === "")
            return "(N/A)"
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        const completeDate = `${dateOfBirth} (${age}y)`;
        return <td><span>{completeDate}</span></td>
    }


    const imgHeader = (props) => {
        return (
            <span
                style={{
                    display: "none",
                }}
            >
              {props.title}
            </span>
        );
    };

    const imgCell = (props) => {
        return (
            <td>
                <img src={props.dataItem.patientImage} style={{width: 30, height: 30}}/>
            </td>
        )
    }
    const CommandCell = (props) => {
        return (
            <td>
                <Button className="rounded-button"
                        style={{maxHeight: 25, minWidth: 30}}>{props.dataItem.notificationCount}</Button>
                <Button className="rounded-button" style={{maxHeight: 25, minWidth: 30}}><i className="fa fa-phone"
                                                                                            aria-hidden="true"></i></Button>
                <Button className="rounded-button" style={{maxHeight: 25, minWidth: 30}}><i className="fa fa-comments-o"
                                                                                            aria-hidden="true"></i></Button>
            </td>
        )
    }
    return (
        <TileContainer>
            <TileHeader className="boxHeader"
                        style={GlobalStyles.tileHeaderBlueWithBlack}
                        expanded={Components.PatientGridComponent}
                        title={"Patient List"}>
            <span style={{float: "right", fontSize: "15px"}}>
              <DropDownButton className={"ah-white-button"} fillMode={"flat"} iconClass="k-icon k-i-rows"/>
            </span>
            </TileHeader>

            {displayDom ?
                <TileBody>
                    <TileRow style={{height: 60}}>
                        <Col xs={12} style={{padding: 0, margin: 0}}>
                            <Toolbar>
                                <Input
                                    style={{width: "50%"}}
                                    placeholder={"Search"}
                                    id={"input-id"}
                                    value={searchState}
                                    onChange={(e) => setSearchState(e.value)}
                                />
                                <Button icon={"clear"} style={{margin: 0}} onClick={() => setSearchState("")}/>
                                <ToolbarItem>
                                    <Button className="rounded-button" togglable={true} icon="filter"
                                            text="Filter Patients"
                                            onClick={() => setFilterState(!filterState)}>Filter</Button>
                                </ToolbarItem>
                                <ToolbarSpacer/>
                                <ToolbarItem>
                                    <Button className="rounded-button" icon="add" text="Image"
                                            onClick={(e) => props.PopupActions.showWindow(PopupWindows.USER_CREATE_PORTAL_REGISTRATION)}>Invite
                                        Patient</Button>
                                    <Button className="rounded-button" icon="add" text="Image"
                                            onClick={(e) => props.PopupActions.showWindow(PopupWindows.CREATE_PATIENT_WINDOW)}>Create
                                        Patient</Button>
                                </ToolbarItem>

                            </Toolbar></Col>
                    </TileRow>
                    <TileRow style={{height: "calc(100% - 60px)"}}>
                        <Grid
                            {...dataState}
                            style={styles.grid}

                            data={orderBy(filteredPatientList, sort)}
                            pageSize={dataState.limit}
                            // style={{height: "calc(100vh - 100px)"}}
                            total={props.totalCount}
                            filterable={filterState}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                setPatientSort(e.sort);
                            }}
                            skip={dataState.skip}
                            onRowClick={(e) => {

                                props.PatientActions.clearPatientContext().then(() => {
                                        props.PatientActions.setPatientContext(e.dataItem).then(() => {
                                            history.push(`/patientPanel`)
                                        })
                                    }
                                )
                            }}
                            pageable={{
                                buttonCount: 10,
                                info: true,
                                type: "numeric",
                                pageSizes: [50, 100, 150],
                                previousNext: true,
                            }}
                            onFilterChange={(e) => filterChangeState(e.filter)}
                            onDataStateChange={dataStateChange}
                            filter={filterPatientData}
                        >
                            <GridColumn field="img" title="." headerCell={imgHeader} cell={imgCell} width={60}
                                        filterable={false}/>
                            <GridColumn field="patientName" title="Patient Name"/>
                            {/* <GridColumn field="firstName" title="Patient Name" cell={name} /> */}
                            {/*<GridColumn field="lastName" value={filterPatientData} title="Last Name" />*/}
                            {/*<GridColumn field="firstName" value={filterPatientData} title="First Name" />*/}
                            {/* <GridColumn field="genderName" value={filterPatientData} title="Gender" /> */}


                            <GridColumn field="dateOfBirth" title="DOB" cell={patientDateAge}/>
                            <GridColumn field="street1" title="Address" cell={patientAddress}/>
                            <GridColumn field="hraCompleted" title="HRA Completed"/>
                            <GridColumn cell={CommandCell} width="200px" filterable={false}/>

                        </Grid>

                        {/*<PatientListLoaderComponent dataState={dataState} onDataReceived={dataReceived}/>*/}
                    </TileRow>

                </TileBody> : <div> <span style={{
                    textAlign: 'center',
                    width: '100%',
                    float: 'left',
                    margin: '30% 0',
                }}>{perMessage}</span></div>}


        </TileContainer>
    );
};

PatientListComponent.propTypes =
    {
        PatientActions: PropTypes.object,
        PopupActions: PropTypes.object,
    }

function mapStateToProps(state) {
    return {
        patients: state.patient.patientList.patients,
        totalCount: state.patient.patientList.totalCount,
        updatePatientContext: state.ComponentUpdate.patientList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientListComponent)
