import {ActionTypes} from "../action/PersonAction";


const initialState = {
    patientList: [],
    patientContext: null,
    createPatientContext: null,
    personContextPhone: [],
    personContextAddress: [],
    personContextEmail: [],
    contactAddress: null,
    contactPreferences: null
}

export const personReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PERSON_GET_PERSON_MODEL:
            return {...state, personModel: action.payload.payload}
        case ActionTypes.PERSON_GET_CONTACT_PHONE:
            return {...state, personContextPhone: action.payload.payload}
        case ActionTypes.PERSON_GET_CONTACT_ADDRESS:
            return {...state, personContextAddress: action.payload.payload}
        case ActionTypes.GET_CONTACT_ADDRESS:
            return {...state, contactAddress: action.payload.payload}
        case ActionTypes.PERSON_GET_CONTACT_EMAIL:
            return {...state, personContextEmail: action.payload.payload}
        case ActionTypes.GET_CONTACT_PREFERENCES:
            console.log(action)
            return {...state, contactPreferences: action?.payload ? action.payload.payload : null }
        case ActionTypes.CLEAR_CONTACT_PREFERENCES:
            return {...state, contactPreferences: null}
        default:
            return state
    }

}

