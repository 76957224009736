import {connect} from "react-redux";
import React from "react";
import TileHeader from "../../layout/TileHeader";
import {PatientAction} from "../../action/PatientAction";
import {bindActionCreators} from "redux";
import {Col, Row} from "react-grid-system";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
//import { Tooltip } from '@progress/kendo-react-tooltip';

const styles = {
    container: {
        width: "100%",
        padding: 0,
        margin: 0,
        height: "calc(100% - 35px)",
    },
    titleh5: {
        margin: '0',
        padding: '0',
        color: '#888a8e',
        fontSize: '22px',
    },
    row: {
        height: "100%",
        padding: 0,
        margin: 0,

    },
}


const UtilizationMetricsComponent = (props) => {


    return (
        <TileContainer>
            <TileHeader title={"Utilization Metrics"}
                        startComponent={<span className="k-icon k-i-heart" style={{
                            fontSize: '20px',
                            color: '#CF2927',

                            marginRight: 10,
                            paddingBottom: 3
                        }}/>
                        }/>
            <TileBody>


                <Row style={styles.row}>
                    <Col sm={12}
                         style={{padding: '12px 0px 15px 25px', height: "100%"}}>
                        <span style={{...styles.titleh5}}>No Data Available</span>
                    </Col>
                </Row>

            </TileBody>
        </TileContainer>

    )

}


function mapStateToProps(state) {
    return {
        patientContext: state.patient.patientContext,
        advanceDirective: state.patient.advanceDirective,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UtilizationMetricsComponent);
//export default connect()(Radium(AdvanceDirectiveComponent))