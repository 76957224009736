import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Responsive, WidthProvider} from 'react-grid-layout';
import {UserActions} from "../action/UserAction";
import {ParseComponent} from "../components/shared/ComponentRegistry";

const ResponsiveGridLayout = WidthProvider(Responsive);

function CareTeamWorkspacePage(props) {
    const tenantLayout = useSelector(state => state.Configuration.tenant.workspaceLayout)
    const permissionLayout = useSelector(state => state.Configuration.config.workspaceLayout)
    const dispatch = useDispatch()
    const userRoleId = useSelector(state => state.auth.session.userRoleId)

    function parseScreenLayout() {
        let layout = permissionLayout?.CareTeamWorkspace?.Components ? permissionLayout.CareTeamWorkspace.Components : tenantLayout?.CareTeamWorkspace?.Components ? tenantLayout?.CareTeamWorkspace?.Components : []
        let foundIndex = layout.findIndex(x => x.i === "NotificationComponent");
        console.log(foundIndex)
        if (foundIndex >= 0)
            layout[foundIndex].params = {type: "LOGGED_IN_USER"}
        return layout
    }

    const [screenLayout, setScreenLayout] = React.useState(parseScreenLayout())

    React.useEffect(() => {
        let payload = {
            "userRoleId": userRoleId
        }
        UserActions.getUser(payload)(dispatch)
    }, [userRoleId]);
    if (screenLayout)
        return <>


            <ResponsiveGridLayout
                style={{
                    minHeight: "calc(100vh - 60px)",
                    height: "auto",
                    maxHeight: "auto",
                    width: "calc(100% + 5px)",
                    overflow: "auto"
                }}
                // onLayoutChange={(layout) => console.log(JSON.stringify(layout))}
                resizeHandles={["se"]}
                draggableHandle={".ah-draggable-header"}
                layouts={{lg: screenLayout}}
                breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 240}}
                cols={{lg: 12, md: 12, sm: 4, xs: 4, xxs: 4}}>
                {screenLayout.map(c => ParseComponent(c))}
            </ResponsiveGridLayout>


        </>
    else return <>
        <div>
            <h4>Error: Tenant configuration is not present. Unable to load</h4>
        </div>
    </>
}


CareTeamWorkspacePage.propTypes = {
    authActions: PropTypes.object,
    layoutActions: PropTypes.object,
}

export default CareTeamWorkspacePage