import {ActionTypes as PatientActionTypes} from "../action/PatientAction";
import {ActionTypes as CareTeamActionTypes} from "../action/CareTeamAction";
import {ActionTypes as CarePlanActionTypes} from "../action/CarePlanAction";
import {ActionTypes as AssessmentActions} from "../action/AssessmentAction";
import {ActionTypes as ImmunizationAction} from '../action/ImmunizationAction';
import {ActionTypes as LabOrderAction} from '../action/LabOrderAction';
import moment from "moment";
import {ActionTypes as SocialHistoryAction} from "../action/SocialHistoryAction";
import {ActionTypes as FamilyHistoryAction} from "../action/FamilyHistoryAction";

const initialState = {
    patientList: [],
    patientContext: null,
    createPatientContext: null,
    clinicalDocumentContext: null,
    patientObservationVitals: [],
    careTeam: {
        careTeamList: [],
        patientCareGetList: [],
        careTeamContext: null,
    },
    encounters: {list: [], totalCount: 0},
    assessments: {data: [], totalCount: null},
    carePlan: {},
    carePlanEdit: {},
    patientUpdated: false,
    patientModel: {},
    medicalProblems: [],
    immunizationData: [],
    labOrderData: [],
    advanceDirective: [],
    allergies: [],
    medications: [],
    hospitalization: [],
    hospitalizationID: [],
    hospitalizationRecord: [],
    surgery: [],
    surgeryList: [],
    socialHistory: [],
    socialHistoryData: {},
    familyHistoryData: {},
    observations: {},
    activityLevelBar: [],
    heartRateBar: [],
    insuranceInformation: [],
    latestCohortObservation: {},
    AnalyticProfileDetails: {
        PatientPanel: [],
        CareTeam: []
    },
    AnalyticProfileForPatient: {},
    patientRelationshipContext: [],
    allPatientRelationshipContext: [],
    encounterObservations: [],
    patientEditTab: false,
    patientMedications: {},
    patientRoleAttributes: [],
}

export const patientReducer = (state = initialState, action) => {
    switch (action.type) {
        case PatientActionTypes.GET_OBSERVATION_PERIOD:
            return {...state, observations: {...state.observations, [action.component]: action.payload.payload}}
        case PatientActionTypes.CLEAR_OBSERVATIONS_BY_COMPONENT:
            return {...state, observations: {...state.observations, [action.component]: []}}
        case PatientActionTypes.PATIENT_LIST_SUCCESS:
            return {
                ...state,
                patientList: {
                    patients: action.payload.payload,
                    totalCount: action.payload.totalCount,
                    hasNextPage: action.payload.hasNextPage
                }
            };

        case PatientActionTypes.PATIENT_LIST_ERROR:
            return {...state, patientListError: action.payload};
        case PatientActionTypes.PATIENT_UPDATE:
            return {...state, patientUpdated: true};
        case PatientActionTypes.PATIENT_CLEAR_UPDATE:
            return {...state, patientUpdated: false};
        case PatientActionTypes.PATIENT_GET_PATIENT_MODEL:
            return {...state, patientModel: action.payload.payload}

        case PatientActionTypes.PATIENT_SET_CONTEXT:
            return {...initialState, patientContext: action.payload}
        case PatientActionTypes.PATIENT_SET_CONTEXT_BY_ID:
            return {...state, patientContext: action.payload.payload}
        case PatientActionTypes.PATIENT_REMOTE_SET_CONTEXT:
            return {...state, patientContext: action.payload.payload}
        case PatientActionTypes.PATIENT_CLEAR_CONTEXT:
            return {
                ...state, patientContext: null, careTeam: {
                    careTeamList: [],
                    patientCareGetList: [],
                    careTeamContext: null,
                }
            }

        case PatientActionTypes.PATIENT_SET_CREATE_PATIENT_CONTEXT: {
            return {...state, createPatientContext: action.payload.payload}
        }

        case PatientActionTypes.PATIENT_CLEAR_CREATE_PATIENT_CONTEXT:
            return {...state, createPatientContext: null}
        case PatientActionTypes.PATIENT_CLEAR_MODEL_CONTEXT:
            return {...state, patientModel: {}}
        case PatientActionTypes.PATIENT_LIST_VITALS:

            return {
                ...state, patientObservationVitals: action.payload.payload.map(e => {
                    return {
                        ...e,
                        effectiveStartDate: (e.effectiveStartDate != null) ? moment.utc(e.effectiveStartDate).local().format("YYYY-MM-DD") : moment.utc(new Date()).local().format("YYYY-MM-DD")
                    }
                }) // .sort((a, b) => moment(b.effectiveStartDate).diff(a.effectiveStartDate))
            }

        case CareTeamActionTypes.CARE_TEAM_MEMBER_LIST_SUCCESS:
            return {...state, careTeam: {...state.careTeam, careTeamList: action.payload.payload}}

        case CareTeamActionTypes.CARE_TEAM_CONTEXT_SET:
            return {...state, careTeam: {...state.careTeam, careTeamContext: action.payload.payload}}
        case CareTeamActionTypes.CARE_TEAM_CONTEXT_CLEAR:
            return {...state, careTeamContext: null}


        case CareTeamActionTypes.CARE_TEAM_MEMBER_LIST_ERROR:
            return {...state, careTeam: {...state.careTeam, patientListError: action.payload.payload}}

        case CareTeamActionTypes.SET_CARE_TEAM_MEMBER_CONTEXT:
            return {...state, patientContext: action.payload}

        case CareTeamActionTypes.CLEAR_CARE_TEAM_MEMBER_CONTEXT:
            return {...state, patientContext: null}
        case PatientActionTypes.PATIENT_FACILITY_ADDRESS_LIST:
            return {...state, facilityaddress: action.payload.payload};
        case PatientActionTypes.PATIENT_LIST_MEDICAL_PROBLEMS:
            return {...state, medicalProblems: action.payload.payload};
        case PatientActionTypes.PATIENT_LIST_ALLERGIES:
            return {...state, allergies: action.payload.payload};
        case PatientActionTypes.PATIENT_LIST_MEDICATIONS:
            return {...state, medications: action.payload.payload};
        case PatientActionTypes.PATIENT_LIST_HOSPITALIZATION:
            return {...state, hospitalization: action.payload.payload};
        case PatientActionTypes.GET_PATIENT_HOSPITALIZATION_ID:
            return {
                ...state,
                hospitalizationID: action.payload.payload,
                hospitalizationRecord: action.payload.payload[0],
                hospitalizationContext: action.payload.payload[0]
            };
        case PatientActionTypes.HOSPITAL_CLEAR_RECORDS:
            return {...state, hospitalizationRecord: []}
        case PatientActionTypes.PATIENT_LIST_SURGERY:
            return {...state, surgery: action.payload.payload}
        case PatientActionTypes.PATIENT_SURGERY_LIST:
            return {...state, surgeryList: action.payload.payload}
        case PatientActionTypes.PATIENT_ENCOUNTER_LIST_SUCCESS:
            return {...state, encounters: {list: action.payload.payload, totalCount: action.payload.totalCount}};
        //Assessments
        case AssessmentActions.ASSESSMENT_LIST_ASSESSMENTS:
            return {
                ...state, assessments: {
                    data: action.payload.payload.map(e => {
                        return {
                            ...e,
                            assessmentDate: moment.utc(e.assessmentDate).local().format("YYYY-MM-DD")
                        }
                    }),
                    count: action.payload.totalCount
                }
            } // .sort((a, b) => moment(b.effectiveStartDate).diff(a.effectiveStartDate))

        // return {...state, assessments: {data: action.payload.payload, count: action.payload.totalCount}}
        // Care Plan
        case CarePlanActionTypes.CARE_PLAN_GET_SUCCESS
        :
            return {...state, carePlan: action.payload.payload}
        case ImmunizationAction.CREATE_IMMUNIZATION:
            return {...state, immunizationData: action.payload.payload}
        case ImmunizationAction.GET_IMMUNIZATION:
            return {...state, immunizationData: action.payload.payload}
        case LabOrderAction.CREATE_LAB_ORDER:
            return {...state}
        case LabOrderAction.GET_LAB_ORDER:
            return {...state, labOrderData: action.payload.payload}
        case PatientActionTypes.PATIENT_FAMILYHISTORY_LIST_SUCCESS:
            return {...state, familyHistoryList: action.payload.payload};
        case PatientActionTypes.PATIENT_FAMILYHISTORY_LIST_ERROR:
            return {...state, familyHistoryListError: action.payload};
        case PatientActionTypes.PATIENT_QUALITYMEASURE_LIST_SUCCESS:
            return {...state, QualityMeasureList: action.payload.payload};
        case PatientActionTypes.PATIENT_QUALITYMEASURE_LIST_ERROR:
            return {...state, QualityMeasureListError: action.payload};
        case PatientActionTypes.PATIENT_GET_ADVANCE_DIRECTIVE:
            return {...state, advanceDirective: action.payload.payload}
        // CARE PLAN EDIT
        case CarePlanActionTypes.CARE_PLAN_EDIT_SET_CONDITION:
            return {...state, carePlanEdit: {...state.carePlanEdit, condition: action.payload.payload}}
        case CarePlanActionTypes.CARE_PLAN_EDIT_SET_DETERMINANTS:
            return {...state, carePlanEdit: {...state.carePlanEdit, determinants: action.payload.payload}}
        case CarePlanActionTypes.CARE_PLAN_EDIT_SET_GOALS:
            return {...state, carePlanEdit: {...state.carePlanEdit, goals: action.payload.payload}}
        case CarePlanActionTypes.CARE_PLAN_EDIT_CLEAR:
            return {...state, carePlanEdit: {}}
        case PatientActionTypes.GET_LOGIN_TOKEN:
            localStorage.setItem('emrToken', action.payload.payload ? action.payload.payload.emrToken : null)
            return {...state}
        case PatientActionTypes.GET_PATIENT_BLOOD_PRESSURE:
            return {...state, bloodPressure: action.payload.payload}
        case PatientActionTypes.ACTIVITY_LEVEL:

            return {...state, activityLevelBar: action.payload.payload}
        case SocialHistoryAction.GET_SOCIAL_HISTORY:
            return {...state, socialHistory: action.payload.payload}
        case SocialHistoryAction.GET_SOCIAL_HISTORY_DATA:
            return {...state, socialHistoryData: action.payload.payload}
        case FamilyHistoryAction.GET_FAMILY_HISTORY_DATA:
            return {...state, familyHistoryData: action.payload.payload}
        case PatientActionTypes.AH_BODY_WEIGHT_MEASURED:
            return {...state, weightMeasured: action.payload.payload}
        case PatientActionTypes.GET_OBSERVATION_ENCOUNTER:
            return {...state, encounterObservations: action.payload.payload}
        case PatientActionTypes.AH_BODY_WEIGHT_MEASURED_LATEST:
            return {...state, weightMeasuredLatest: action.payload.payload}
        case PatientActionTypes.AH_BODY_MASS_INDEX_LATEST:
            return {...state, bmiMeasuredLatest: action.payload.payload}
        case  PatientActionTypes.AH_BODY_MASS_INDEX:
            return {...state, massIndex: action.payload.payload}
        case PatientActionTypes.GET_PATIENT_BLOOD_PRESSURE_DISTRIBUTION:
            return {...state, bloodPressureDistribution: action.payload.payload}
        case PatientActionTypes.CRETAE_PATIENT:
            return {...state, createPatient: action.payload.payload}
        case PatientActionTypes.CLINICAL_DOCUMENT :
            return {...state, clinicalDocument: action.payload}
        case PatientActionTypes.CREATE_CLINICAL_DOCUMENT:
            return {...state, createClinicalDOcuement: action.payload.payload}
        case PatientActionTypes.GET_CLINICAL_DOCUMENT:
            return {...state, getClinicalDocument: action.payload.payload}
        case PatientActionTypes.CLEAR_GET_CLINICAL_DOCUMENT:
            return {...state, getClinicalDocument: []}
        case PatientActionTypes.CLEAR_HOSPITALIZATION_CONTEXT:
            return {...state, hospitalizationContext: null}
        case PatientActionTypes.SET_HOSPITALIZATION_CONTEXT:
            return {...state, hospitalizationContext: action.payload}
        case PatientActionTypes.CLEAR_SURGERY_CONTEXT:
            return {...state, surgeryContext: null}
        case PatientActionTypes.SET_SURGERY_CONTEXT:
            return {...state, surgeryContext: action.payload}
        case PatientActionTypes.EDIT_CLINICAL_DOCUMENT:
            return {...state, editClinicalDocument: action.payload}
        case PatientActionTypes.CLINICAL_DOCUMENT_DOWNLOAD:
            return {...state, clinicalDocumentDownload: action.payload.payload}
        case PatientActionTypes.SET_CLINICAL_DOCUMENT_CONTEXT: {
            return {...state, clinicalDocumentContext: action.payload.payload}
        }

        case PatientActionTypes.CLEAR_CLINICAL_DOCUMENT_CONTEXT:
            return {...state, clinicalDocumentContext: null}
        case PatientActionTypes.HEART_VARIABILITY:
            return {...state, getHeartVariability: action.payload.payload}
        case PatientActionTypes.GET_USER_PATIENT_OBSERVATION_PERIOD:
            return {...state, getUserPatientObservationsByPeriod: action.payload.payload}
        case PatientActionTypes.GET_USER_PATIENT_OBSERVATION_LATEST:
            return {
                ...state,
                getLatestObservation: {...state.getLatestObservation, [action.component]: action.payload.payload}
            }
        case PatientActionTypes.GET_PATIENT_OBSERVATION_BY_PERIOD_HEART_RATE:
            return {
                ...state,
                getPatientObservationsByPeriodForHeartRate: {
                    ...state.getPatientObservationsByPeriodForHeartRate,
                    [action.component]: action.payload.payload
                }
            }
        case PatientActionTypes.HEART_RATE_SET_TO_DATA_WEEK:
            return {...state, HeartRateManagementData: action.payload.payload}
        case PatientActionTypes.ADD_PATIENT_INSURANCE_INFORMATION:
            return {...state, insuranceInformation: action.payload.payload}
        case PatientActionTypes.UPDATE_PATIENT_INSURANCE_INFORMATION:
            return {...state, insuranceInformation: action.payload.payload}
        case PatientActionTypes.GET_PATIENT_INSURANCE_INFORMATION:
            return {...state, insuranceInformation: action.payload.payload}
        case PatientActionTypes.CLEAR_PATIENT_INSURANCE_INFORMATION:
            return {...state, insuranceInformation: []}
        case PatientActionTypes.GET_PATIENT_COHORT_OBSERVATION_WITH_THRESHOLD:
            return {
                ...state,
                latestCohortObservation: {...state.latestCohortObservation, [action.component]: action.payload.payload}
            }
        case PatientActionTypes.GET_ANALYTICAL_PROFILE_DETAILS_CARE_TEAM:
            return {
                ...state,
                AnalyticProfileDetails: {...state.AnalyticProfileDetails, [action.component]: action.payload.payload}
            }
        case PatientActionTypes.GET_ANALYTICAL_PROFILE_For_PATIENT:
            return {
                ...state,
                AnalyticProfileDetails: {...state.AnalyticProfileDetails, [action.component]: action.payload.payload}
            }
        case PatientActionTypes.GET_ALL_PATIENT_RELATIONSHIPS:
            return {...state, allPatientRelationshipContext: action?.payload?.payload || []}
        case PatientActionTypes.GET_PATIENT_RELATIONSHIPS:
            return {...state, patientRelationshipContext: action?.payload?.payload || []}
        case PatientActionTypes.CLEAR_PATIENT_RELATIONSHIPS:
            return {...state, patientRelationshipContext: [], allPatientRelationshipContext: []}
        case PatientActionTypes.SET_EDIT_PATIENT_TAB:
            return {...state, patientEditTab: action.payload}
        case PatientActionTypes.GET_PATIENT_MEDICATIONS:
            return {...state, patientMedications: action.payload}
        case PatientActionTypes.GET_PATIENT_ROLE_ATTRIBUTES:
            return {...state, patientRoleAttributes: action.payload.payload}
        default:
            return state
    }

}

