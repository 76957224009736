import GlobalStyles from "../config/GlobalStyles";
import React from "react";
import PropTypes from "prop-types";
import { ExpandableAction } from "../action/ExpandableAction";
import { useDispatch, useSelector } from "react-redux";
import { ColumnLayout, GridLayout } from "./GridLayout";
import { Button } from "primereact/button";

const TileHeader = (props) => {
    const style = props.style || {}
    const headerColor = props?.headerColor || "white"
    const dispatch = useDispatch()
    const visible = useSelector(state => state.expandedWindow.expandableComponentVisible)
    const component = useSelector(state => state.expandedWindow.expandableComponent)
    return (
        <ColumnLayout col={12} style={{}}>
            <GridLayout className={"w-full flex align-context-center ah-draggable-header "}
                style={{ ...GlobalStyles.tileHeader, ...style }}>
                {props?.image && <ColumnLayout className={"flex-grow-0 ah-draggable-header"}>
                    <img src={props?.image} style={{
                        color: headerColor,
                        width: '30px',
                        height: '30px',
                        paddingBottom: 5
                    }} />
                    {props?.span ? <span class={props?.span}
                        style={{ marginTop: '10px', color: '#085394', marginLeft: '5px' }}></span> : ''}
                </ColumnLayout>}
                <ColumnLayout col={props?.colSize || 6}>
                    {props?.title && typeof props?.title === "string" ?
                        <div
                            style={{
                                paddingLeft: 10,
                                whiteSpace: "nowrap",
                                width: "100%",
                                color: headerColor,
                                height: "100%",
                                overflow: "hidden"
                            }}>{props?.title}</div> :
                        <div>{props.title}</div>}
                </ColumnLayout>
                <ColumnLayout
                    className={"flex-grow-1"}
                    col={props?.colSize ? props?.image ? 11 - props.colSize : 12 - props.colSize : props.image ? 5 : 6}>
                    <GridLayout className={"flex-row-reverse align-context-center"}
                        style={{ paddingRight: 5, height: 35 }}>

                        {props.refresh &&
                            <ColumnLayout style={{ height: 35 }}
                                className={"flex-grow-0 flex-row-reverse align-context-center"}>
                                <Button
                                    text
                                    size={"small"}
                                    icon={"pi pi-refresh"}
                                    style={{ color: "white", height: 25, width: 25 }}
                                    onClick={(e) => props.refresh()}
                                />
                            </ColumnLayout>}

                        {props.expanded &&
                            <ColumnLayout style={{ height: 35 }}
                                className={"flex-grow-0 flex-row-reverse align-context-center"}>
                                <Button
                                    icon={component === props.expanded ? "pi pi-window-minimize" : "pi pi-window-maximize"}
                                    text
                                    tooltip={component === props.expanded ? "Minimize Window" : "Maximize Window"}
                                    tooltipOptions={{ position: "top" }}
                                    style={{ color: headerColor, height: 25, width: 25 }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (component === props.expanded)
                                            ExpandableAction.setExpandableComponent(null)(dispatch)
                                        else
                                            ExpandableAction.setExpandableComponent(props.expanded)(dispatch)
                                        ExpandableAction.setExpandedComponentProps(props.expandedProps ? props.expandedProps : {})(dispatch)
                                        ExpandableAction.toggleExpandableComponent(!(visible && props.expanded === component))(dispatch)
                                    }} />
                            </ColumnLayout>}
                        <ColumnLayout col={10} style={{ height: 35 }} className={"flex-grow-0"}>
                            <GridLayout className={"flex-row-reverse"}>
                                {props.children}
                            </GridLayout>
                        </ColumnLayout>

                    </GridLayout>
                </ColumnLayout>
            </GridLayout>
        </ColumnLayout>
    )
};

TileHeader.propTypes = {
    title: PropTypes.string || PropTypes.element,
    expanded: PropTypes.string,
    startComponent: PropTypes.element,
    expandedProps: PropTypes.object,
    expandedWindow: PropTypes.object,
    refresh: PropTypes.func,

}

export default (TileHeader)