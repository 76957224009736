import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    NOTIFICATION_RECEIVE: "NOTIFICATION_RECEIVE",
    NOTIFICATION_DISMISS: "NOTIFICATION_DISMISS",
    NOTIFICATION_SET_CONTEXT: "NOTIFICATION_SET_CONTEXT",
    NOTIFICATION_CLEAR_CONTEXT: "NOTIFICATION_CLEAR_CONTEXT",
}

//TODO: This should be going to a redux store.
function getNotifications({type, userId, limit, offset}) {
    // console.log(limit, offset)
    let endpoint = '';
    let requestObject = {
        limit: limit || 100,
        offset: offset,
        payload: userId
    }

    if (type === 'LOGGED_IN_USER') {
        endpoint = 'notification/user/get'
    } else if (type === 'PATIENT_PANEL') {
        endpoint = 'notification/patient/get'
    }

    return WrapAuthentication(endpoint,
        requestObject,
        ActionTypes.NOTIFICATION_RECEIVE)
}

function dismissNotification(notificationId) {

}

function setNotificationContext(notification) {
    return async dispatch =>
        dispatch({type: ActionTypes.NOTIFICATION_SET_CONTEXT, payload: notification})

}

function clearNotificationContext() {
    return async dispatch =>
        dispatch({type: ActionTypes.NOTIFICATION_CLEAR_CONTEXT})

}

export const NotificationAction =
    {
        dismissNotification: dismissNotification,
        getNotifications: getNotifications,
        setNotificationContext: setNotificationContext,
        clearNotificationContext: clearNotificationContext,

    }