import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {AssessmentAction} from "../../../action/AssessmentAction";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {PopupWindowActions, PopupWindows} from "../../../action/PopupWindowAction";
import {FormAction} from "../../../action/FormAction";

const _ = require('lodash');


const AssessmentTabComponent = (props) => {

    const {patientContext, AssessmentActions, assessments, totalCount} = props
    const [dynamicColumns, setDynamicColumns] = React.useState([])
    const [dataState, setDataState] = React.useState({take: 0, skip: 0})
    const [showDialog, setShowDialog] = React.useState(false);


    const [data, setData] = React.useState([])

    React.useEffect(() => {
            if (patientContext) {
                AssessmentActions.getAssessmentsForPatient(patientContext.patientRoleId, dataState.take, dataState.skip)
            }
        }, [patientContext]
    )
    //assessmentTypeName
    React.useEffect(() => {
        let values = _.groupBy(props.assessments, "assessmentTypeName")
        let mappedValues = Object.keys(values).map(item => {
            return {itemKey: item, name: values[item][0].assessmentTypeName, data: values[item]}
        })
        setData(_.sortBy(mappedValues, "assessmentTypeName"))

    }, [props.assessments])

    React.useEffect(() => {
        let columns = _.sortedUniq(props.assessments.map(e => e.assessmentDate))
        setDynamicColumns(columns.sort().reverse())
    }, [data])
    
    const handleFormDocClick = (data) => {
        props.FormActions.setFormReportInputData(data?.assesForm);
        props.PopupActions.showWindow(PopupWindows.FORM_REPORTS)
    };

    const FormDocument = (data) => {
        return (
            <span class="k-icon k-i-file-txt k-color-secondary" onClick={() => handleFormDocClick(data)}></span>
        );
    }
    //assessmentResult
    const DynamicGridColumn = (props) => {
        const {date, field, value, dataItem} = props
        let result = dataItem.data.find(i => i.assessmentDate === date)

        return (
            <td colSpan={props.colSpan} style={result?.assessmentValue ? {} : {color: "gainsboro"}}
                title={result?.assessmentResult}>
                {result?.assessmentValue ? `${result.assessmentValue}` : "N/A"} {result?.formHeaderId ? <FormDocument assesForm={result} /> :""}
            </td>
        )
    }

    const detailhead = (ee) => {
        return <div><b>{ee.dataItem.name}</b></div>
    }

    return (

            <div style={{margin: 0, width: "100%", height: "100%"}}>
                <Grid
                    {...dataState}
                    style={{height: "100%", width: "100%"}}
                    onRowClick={(e) => {
                        let data = e.dataItem.data
                        let title = e.dataItem.name
                        props.PopupActions.showLineChart(title, data.length > 0 ? data.map(e => e.assessmentDate) : [], data.length > 0 ? data.map(e => e.assessmentValue) : [])
                    }}
                    data={data}
                    sortable={true}
                    resizable={true}
                    // onExpandChange={onExpandChange}
                    expandField="expanded">
                    <GridColumn title={"Type"} locked={true} width={"140px"} cell={(e) => detailhead(e)}/>
                    <DynamicGridColumn/>
                    {dynamicColumns.map((h, index) => {
                            return <GridColumn field={"description"}
                                               title={<b>{h}</b>}
                                               date={h}
                                               width={"100px"}
                                               cell={(props) => <DynamicGridColumn date={h} {...props}/>}
                                               key={h}/>
                        }
                    )

                    }
                    <Tooltip openDelay={10}
                             position="top"
                             anchorElement="element"

                             style={{background: "dimgray", color: "white"}}
                             tooltipStyle={{background: "white", color: "white"}}/>
                </Grid>

            </div>

    );
};

AssessmentTabComponent.propTypes =
    {
        AssessmentActions: PropTypes.object,
        PopupActions: PropTypes.object,
    }

function mapStateToProps(state) {
    return {
        patientContext: state.patient.patientContext,
        assessments: state.patient.assessments.data,
        totalCount: state.patient.assessments.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        AssessmentActions: bindActionCreators(AssessmentAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        FormActions: bindActionCreators(FormAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentTabComponent)
