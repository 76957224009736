

export const ActionTypes ={
    INTERFACE_TOGGLE_SIDEBAR : "INTERFACE_TOGGLE_SIDEBAR",
}


function toggleSideBar() {
    return async dispatch => {
        dispatch({type: ActionTypes.INTERFACE_TOGGLE_SIDEBAR})
    }
}


export const InterfaceActions = {
    toggleSideBar
}