export const BreakPoints = {lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0};
export const passwordRegex =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/;
export const DATE_FORMAT = "YYYY-MM-DD";

export const API_DATE_FORMAT = "yyyy-MM-dd";
export const API_DATETIME_FORMAT = "yy-mm-ddThh:mm:ss.sssZ"
export const Colors = {
    RED: "#E06666",
    ORANGE: "#e69038",
    YELLOW: "#f0c131",
    GREEN: "#68a74f",
    BLUE: "#4d79ff",
    DISABLED: " #999999",
    LIGHT_GRAY: "#f2f2f2",
};

const {
    REACT_APP_OPENEMR_URL,
    REACT_APP_APP_DOMAIN,
    REACT_APP_TENANT_ID,
    REACT_APP_VERADIGM_URL,
    REACT_APP_VERADIGM_LICENSE_ID,
    REACT_APP_VERADIGM_PARTNER_ID,
} = process.env;

export const OPENEMR_URL = REACT_APP_OPENEMR_URL || "demo.aerioshealth.com";

export const TENANT_ID =
    window?.TENANT_ID === "REACT_APP_TENANT_ID"
        ? REACT_APP_TENANT_ID || "d853915f-5671-4c84-a347-46b0128eb3e3"
        : window.TENANT_ID;

export const VERADIGM_URL =
    window?.VERADIGM_URL === "REACT_APP_VERADIGM_URL"
        ? REACT_APP_VERADIGM_URL ||
        "https://eprescribeqa.allscripts.com/SAML/SSO.aspx"
        : window.VERADIGM_URL;

export const VERADIGM_CONFIG = {
    PARTNER_ID:
        window?.VERADIGM_PARTNER_ID === "REACT_APP_VERADIGM_PARTNER_ID"
            ? REACT_APP_VERADIGM_PARTNER_ID || "3ad935ce-0e5b-4944-b587-d933515b62cf"
            : window?.VERADIGM_PARTNER_ID,
    LICENSE_ID:
        window?.VERADIGM_LICENSE_ID === "REACT_APP_VERADIGM_LICENSE_ID"
            ? REACT_APP_VERADIGM_LICENSE_ID || "A6868E57-C674-4B3B-9B97-90A58EB41C3D"
            : window?.VERADIGM_LICENSE_ID,
};

export const APP_DOMAIN = REACT_APP_APP_DOMAIN || "localhost";

export const ObservationCodes = {
    SLEEP: {
        AWAKE: "AH_AWAKE",
        ASLEEP: "AH_ASLEEP",
        IN_BED: "AH_INBED",
    },
    HEART_RATE: {
        RESTING_HEART_RATE: "AH_RESTING_HR",
    },
    STEP: {
        STEP_COUNT: "AH_STEP_COUNT",
    },
    WEIGHT: {
        AH_BODY_WEIGHT_MEASURED: "AH_BODY_WEIGHT_MEASURED",
        AH_BODY_MASS_INDEX: "AH_BODY_MASS_INDEX",
    },
    DAY_PREVIOUS: {
        SLEEP_QUALITY: 7,
        ACTIVITY_LEVEL: 5,
    },
};

export const ObservationThreshold = {
    color: "#085394",
};

const Scopes = [
    {code: "AH_MANAGE_MOC", description: "User can manage Measures of Care"},
    {code: "AH_MANAGE_USERS", description: "User can manage users"},
    {
        code: "AH_REQUIRED_ELEMENTS",
        description: "User can manage Required Elements",
    },
    {code: "AH_MANAGE_FORMS", description: "User can manage Forms"},
    {code: "AH_MANAGE_FORM", description: "User can manage Form"},
    {code: "AH_MANAGE_DICTIONARY", description: "User can manage Dictionaries"},
    {
        code: "AH_MANAGE_THRESHOLDS",
        description: "User can manage CC Thresholds",
    },
    {code: "AH_ADD_PATIENT", description: "User can add patients"},
    {code: "AH_EDIT_PATIENT", description: "User can edit patients"},
    {code: "AH_MANAGE_CARE_TEAM", description: "User can manage care team"},
    {
        code: "AH_MANAGE_OBSERVATIONS",
        description: "User can manage observations",
    },
    {code: "AH_MANAGE_CONDITIONS", description: "User can manage conditions"},
    {code: "AH_MANAGE_ASSESSMENTS", description: "User can manage assessments"},
    {code: "AH_SCHEDULE", description: "User has a schedule"},
    {code: "AH_MODIFY_SCHEDULE", description: "User can modify schedules"},
    {code: "AH_MANAGE_MESSAGES", description: "User can manage messages"},
    {code: "AH_MANAGE_CHANNELS", description: "User can manage channels"},
    {
        code: "AH_ADVANCE_DIRECTIVE",
        description: "User can manage advance directive",
    },
    {code: "AH_MANAGE_REQUEST", description: "User can manage request"},
    {code: "AH_REQUEST", description: "User can manage request"},
    {code: "AH_MANAGE_CAREPLAN", description: "User can manage care plan"},
    {code: "AH_CAREPLAN", description: "User can manage care plan"},
    {code: "AH_DICTIONARY", description: "User can manage dictionary"},
    {code: "AH_ENCOUNTERS", description: "User can manage encounter"},
    {code: "AH_FACILITIES", description: "User can manage facilities"},
    {code: "AH_MANAGE_FACILITIES", description: "User can manage facilities"},
    {code: "AH_FAMILYHISTORY", description: "User can manage family history"},
    {code: "AH_FORM", description: "User can manage form"},
    {code: "AH_HCC", description: "User can manage hcc"},
    {code: "AH_NOTIFICATIONS", description: "User can manage notification"},
    {code: "AH_OBSERVATION", description: "User can manage observation"},
    {code: "AH_PATIENT", description: "User can manage patient"},
    {code: "AH_ORDER", description: "User can manage order"},
    {code: "AH_MANAGE_ORDER", description: "User can manage orders"},
    {
        code: "AH_MANAGE_PERMISSION",
        description: "User can manage dict_permissions",
    },
    {code: "AH_PERMISSION", description: "User can manage dict_permission"},
    {code: "AH_PERSON", description: "User can manage person"},
    {code: "AH_MANAGE_PERSON", description: "User can manage persons"},
    {code: "AH_COHORT", description: "User can manage cohort"},
    {code: "AH_SOCIAL_HISTORY", description: "User can manage social history"},
    {code: "AH_MANAGE_OBSERVATION", description: "User can manage observation"},
    {
        code: "AH_PORTAL_ACCESS",
        description:
            "User can access their own data through the portal mobile/web.",
    },
    {
        code: "AH_DOCUMENT_CATEGORIES",
        description: "User can update, create and delete document.",
    },
];

export const PhixPermissions = {
    AH_ADMIN_EDIT_DICTIONARIES: "AH_ADMIN_EDIT_DICTIONARIES",
    AH_ADMIN_EDIT_TENANT_CONFIGURATION: "AH_ADMIN_EDIT_TENANT_CONFIGURATION",
    AH_ADMIN_VIEW_TENANT_CONFIGURATION: "AH_ADMIN_VIEW_TENANT_CONFIGURATION",
    AH_ADMIN_EDIT_USERS: "AH_ADMIN_EDIT_USERS",
    AH_ADMIN_VIEW_DICTIONARIES: "AH_ADMIN_VIEW_DICTIONARIES",
    AH_ADMIN_VIEW_USERS: "AH_ADMIN_VIEW_USERS",
    AH_EDIT_ADVANCE_DIRECTIVE: "AH_EDIT_ADVANCE_DIRECTIVE",
    AH_EDIT_ALLERGY: "AH_EDIT_ALLERGY",
    AH_EDIT_ASSESSMENT: "AH_EDIT_ASSESSMENT",
    AH_EDIT_CAREPLAN: "AH_EDIT_CAREPLAN",
    AH_EDIT_CARETEAM: "AH_EDIT_CARETEAM",
    AH_EDIT_DOCUMENT: "AH_EDIT_DOCUMENT",
    AH_EDIT_ENCOUNTER: "AH_EDIT_ENCOUNTER",
    AH_EDIT_EPRESCRIBE: "AH_EDIT_EPRESCRIBE",
    AH_EDIT_FAMILY_HISTORY: "AH_EDIT_FAMILY_HISTORY",
    AH_EDIT_HOSPITALIZATION: "AH_EDIT_HOSPITALIZATION",
    AH_EDIT_IMMUNIZATION: "AH_EDIT_IMMUNIZATION",
    AH_EDIT_MEDICAL_PROBLEM: "AH_EDIT_MEDICAL_PROBLEM",
    AH_EDIT_MEDICATION: "AH_EDIT_MEDICATION",
    AH_EDIT_MESSAGE: "AH_EDIT_MESSAGE",
    AH_EDIT_NOTIFICATION: "AH_EDIT_NOTIFICATION",
    AH_EDIT_OBSERVATION: "AH_EDIT_OBSERVATION",
    AH_EDIT_ORDER: "AH_EDIT_ORDER",
    AH_EDIT_PATIENT: "AH_EDIT_PATIENT",
    AH_ADMIN_EDIT_PERMISSION: "AH_ADMIN_EDIT_PERMISSION",
    AH_EDIT_PORTAL_USER: "AH_EDIT_PORTAL_USER",
    AH_EDIT_QUALITY_MEASURES: "AH_EDIT_QUALITY_MEASURES",
    AH_EDIT_REFERRALS: "AH_EDIT_REFERRALS",
    AH_EDIT_REQUEST: "AH_EDIT_REQUEST",
    AH_EDIT_SCHEDULE: "AH_EDIT_SCHEDULE",
    AH_EDIT_SOCIAL_HISTORY: "AH_EDIT_SOCIAL_HISTORY",
    AH_EDIT_THRESHOLDS: "AH_EDIT_THRESHOLDS",
    AH_EDIT_USER: "AH_EDIT_USER",
    AH_PAGE_ADMIN: "AH_PAGE_ADMIN",
    AH_VIEW_ADVANCE_DIRECTIVE: "AH_VIEW_ADVANCE_DIRECTIVE",
    AH_VIEW_ALLERGY: "AH_VIEW_ALLERGY",
    AH_VIEW_ASSESSMENT: "AH_VIEW_ASSESSMENT",
    AH_VIEW_CAREPLAN: "AH_VIEW_CAREPLAN",
    AH_VIEW_CARETEAM: "AH_VIEW_CARETEAM",
    AH_VIEW_DICTIONARY: "AH_VIEW_DICTIONARY",
    AH_VIEW_DOCUMENT: "AH_VIEW_DOCUMENT",
    AH_VIEW_ENCOUNTER: "AH_VIEW_ENCOUNTER",
    AH_VIEW_EPRESCRIBE: "AH_VIEW_EPRESCRIBE",
    AH_VIEW_FACILITY: "AH_VIEW_FACILITY",
    AH_VIEW_FAMILY_HISTORY: "AH_VIEW_FAMILY_HISTORY",
    AH_VIEW_HOSPITALIZATION: "AH_VIEW_HOSPITALIZATION",
    AH_VIEW_SURGERIE: "AH_VIEW_SURGERIE",
    AH_VIEW_IMMUNIZATION: "AH_VIEW_IMMUNIZATION",
    AH_VIEW_MEDICAL_PROBLEM: "AH_VIEW_MEDICAL_PROBLEM",
    AH_VIEW_MEDICATION: "AH_VIEW_MEDICATION",
    AH_VIEW_MESSAGE: "AH_VIEW_MESSAGE",
    AH_VIEW_MY_MESSAGES: "AH_VIEW_MY_MESSAGES",
    AH_VIEW_NOTIFICATION: "AH_VIEW_NOTIFICATION",
    AH_VIEW_OBSERVATION: "AH_VIEW_OBSERVATION",
    AH_VIEW_ORDER: "AH_VIEW_ORDER",
    AH_VIEW_PATIENT: "AH_VIEW_PATIENT",
    AH_ADMIN_VIEW_PERMISSION: "AH_ADMIN_VIEW_PERMISSION",
    AH_VIEW_PORTAL_USER: "AH_VIEW_PORTAL_USER",
    AH_VIEW_QUALITY_MEASURES: "AH_VIEW_QUALITY_MEASURES",
    AH_VIEW_REFERRALS: "AH_VIEW_REFERRALS",
    AH_VIEW_REQUEST: "AH_VIEW_REQUEST",
    AH_VIEW_SCHEDULE: "AH_VIEW_SCHEDULE",
    AH_VIEW_SOCIAL_HISTORY: "AH_VIEW_SOCIAL_HISTORY",
    AH_VIEW_THRESHOLDS: "AH_VIEW_THRESHOLDS",
    AH_VIEW_USER: "AH_VIEW_USER",
    AH_VIEW_ANALYTICS: "AH_VIEW_ANALYTICS",
    AH_EDIT_ANALYTICS: "AH_EDIT_ANALYTICS",
    AH_ADMIN_VIEW_LABS: "AH_ADMIN_VIEW_LABS",
    AH_ADMIN_EDIT_LABS: "AH_ADMIN_EDIT_LABS",
    // These need to be added
    AH_ADMIN_VIEW_FACILITY: "AH_ADMIN_VIEW_FACILITY",
    AH_ADMIN_EDIT_FACILITY: "AH_ADMIN_EDIT_FACILITY",
    AH_ADMIN_VIEW_OBSERVATION_THRESHOLD: "AH_ADMIN_VIEW_OBSERVATION_THRESHOLD",
    AH_ADMIN_EDIT_OBSERVATION_THRESHOLD: "AH_ADMIN_EDIT_OBSERVATION_THRESHOLD",
    AH_ADMIN_VIEW_ENCOUNTER: "AH_ADMIN_VIEW_ENCOUNTER",
    AH_ADMIN_EDIT_ENCOUNTER: "AH_ADMIN_EDIT_ENCOUNTER",
    AH_ADMIN_VIEW_ENCOUNTER_CODING: "AH_ADMIN_VIEW_ENCOUNTER_CODING",
    AH_ADMIN_EDIT_ENCOUNTER_CODING: "AH_ADMIN_EDIT_ENCOUNTER_CODING",
    AH_ADMIN_VIEW_FORM: "AH_ADMIN_VIEW_FORM",
    AH_ADMIN_EDIT_FORM: "AH_ADMIN_EDIT_FORM",
    AH_ADMIN_VIEW_INSURANCE_PLAN: "AH_ADMIN_VIEW_INSURANCE_PLAN",
    AH_ADMIN_EDIT_INSURANCE_PLAN: "AH_ADMIN_EDIT_INSURANCE_PLAN",
    AH_ADMIN_VIEW_SECURITY: "AH_ADMIN_VIEW_SECURITY",
    AH_ADMIN_EDIT_SECURITY: "AH_ADMIN_EDIT_SECURITY",
    AH_VIEW_MY_PATIENTS: "AH_VIEW_MY_PATIENTS",
    AH_EDIT_MY_PATIENTS: "AH_EDIT_MY_PATIENTS",
    AH_VIEW_ENCOUNTER_CODING: "AH_VIEW_ENCOUNTER_CODING",
    AH_EDIT_ENCOUNTER_CODING: "AH_EDIT_ENCOUNTER_CODING",
    AH_VIEW_ENCOUNTER_SUMMARY: "AH_VIEW_ENCOUNTER_SUMMARY",
    AH_EDIT_MY_MESSAGES: "AH_EDIT_MY_MESSAGES",
    AH_VIEW_ENCOUNTER_LOCKED: "AH_VIEW_ENCOUNTER_LOCKED",
    AH_VIEW_PATIENT_METRICS: "AH_VIEW_PATIENT_METRICS",
};
