import {PostApi} from "../util/AuthWrapper";

export enum CallPoint {
    BILLING_ADAPTER = "BILLING_ADAPTER", FTP_ADAPTER = 'FTP_ADAPTER'
}

export enum MessageType {
    EncounterCreateDftP03Message = "EncounterCreateDftP03Message"
}

export type ExternalAdapterMessage = {
    callPoint: CallPoint,
    messageType: MessageType
    payload: Object | Array<Object>
}


export async function SendToExternalAdapter(callPoint: CallPoint, messageType: MessageType, message: Object | Array<Object>) {
    return PostApi("external/adapter/send/message", {callPoint, messageType, message})
}

