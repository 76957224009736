import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    CREATE_IMMUNIZATION: "CREATE_IMMUNIZATION",
    UPDATE_IMMUNIZATION: "UPDATE_IMMUNIZATION",
    GET_IMMUNIZATION: "GET_IMMUNIZATION"
}

function createImmunization(payload) {
    return WrapAuthentication("patient/immunization/create",
        {"payload": payload},
        ActionTypes.CREATE_IMMUNIZATION)
}

function updateImmunization(payload) {
    return WrapAuthentication("patient/immunization/update",
        {"payload": payload},
        ActionTypes.UPDATE_IMMUNIZATION)
}

function getImmunization(payload) {
    return WrapAuthentication("patient/immunization/get",
        {"payload": payload},
        ActionTypes.GET_IMMUNIZATION)
}

export const ImmunizationActions = {
    createImmunization: createImmunization,
    updateImmunization: updateImmunization,
    getImmunization: getImmunization
}