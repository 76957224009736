import React, {useEffect} from "react";
import {connect} from 'react-redux';
import {Components} from "../../shared/ComponentRegistry";
import GlobalStyles from "../../../config/GlobalStyles";
import TileHeader from "../../../layout/TileHeader";
import {PatientAction} from "../../../action/PatientAction";
import {UserActions} from "../../../action/UserAction"
import {bindActionCreators} from "redux";
import {TileContainer} from "../../../layout/TileContainer";
import {PhixPermissions} from "../../../config/GlobalConfig";
import ShowForPermissionComponent from "../../shared/ShowForPermissionComponent";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";

const DocumentViewerComponent = (props) => {
    const {selectedDocument} = props;
    const [document, setDocument] = React.useState([])

    React.useEffect(() => {
        const payload = {
            fileReferenceId: selectedDocument?.fileReferenceId
        }
        props.PatientActions.clinicalDocumentDownload(payload)
    }, [selectedDocument])
    useEffect(() => {
        console.log(document)
    }, [document])

    React.useEffect(() => {
        const fileMetaData = props?.clinicalDocumentDownload?.fileMetaData;
        const meta = `data:${fileMetaData};base64,`;
        if (fileMetaData && props?.clinicalDocumentDownload?.fileBase64) {
            setDocument([{uri: meta + props?.clinicalDocumentDownload?.fileBase64, fileName : `${props?.clinicalDocumentContext?.displayName} - ${props?.clinicalDocumentContext?.fileName}`}]);
        } else {
            setDocument([]);
        }
    }, [props.clinicalDocumentDownload])
    const style = {
        ...GlobalStyles.tileHeaderBlueWithBlack,
    }
    return (
        <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_DOCUMENT]}>
            <TileContainer style={{margin: "0"}}>

                <TileHeader title={"Document Viewer"} style={style}
                            expanded={Components.DocumentViewerComponent}>
                </TileHeader>
                <DocViewer documents={document} pluginRenderers={DocViewerRenderers}/>
                {/*{props?.clinicalDocumentDownload?.fileBase64 === "" ? (*/}
                {/*    <iframe*/}
                {/*        id="document"*/}
                {/*        title="View document"*/}
                {/*        width="100%"*/}
                {/*        height="auto"*/}
                {/*        srcdoc="Unable to preview"*/}
                {/*    />*/}
                {/*) : (*/}
                {/*    <iframe*/}
                {/*        id="document"*/}
                {/*        title="View document"*/}
                {/*        width="100%"*/}
                {/*        height="100%"*/}
                {/*        src={document}*/}
                {/*    />*/}
                {/*)}*/}

            </TileContainer>
        </ShowForPermissionComponent>
    )
}

function mapStateToProps(state) {
    return {
        clinicalDocumentDownload: state.patient.clinicalDocumentDownload,
        clinicalDocumentContext: state.patient.clinicalDocumentContext,
        patientContext: state.patient.patientContext,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentViewerComponent);