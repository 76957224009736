import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react';
import {PermissionAction} from "../../action/PermissionAction";
import {useDispatch, useSelector} from "react-redux";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import _ from "lodash";

const PermissionsDropDownList = (props) => {
    const {required, value, defaultItemCode, inputRef, code} = props
    const [data, setData] = useState([])
    const [filter, setFilter] = React.useState("")
    const [internalValue, setInternalValue] = useState(null)

    const dispatch = useDispatch()
    const dictionaries = useSelector(s => s?.permission?.adminPermissionsCategories)

    const filterChange = (event) => {
        setFilter(event.filter.value)
        //setData(filterData(event.filter));
    };

    const onSetDefaultValue = (value) => {
        if (props.onChange) {
            props.onChange({target: {value: value}});
        }
    };

    const handleChange = (e) => {
        if (e.value.id) {
            setInternalValue(e.target.value)
            if (props.onChange) {
                props.onChange(e)
            }
        }
    }
    useEffect(() => {
        console.log(dictionaries, code)
        if (dictionaries && dictionaries[code])
            setData(dictionaries[code]);
    }, [dictionaries])

    useEffect(() => {
        if (data?.length > 0) {
            if (value?.id && internalValue?.id !== value?.id) {
                setInternalValue(value)
            } else if (!_.isNil(value) && _.isNil(internalValue)) {
                let v = data ? data.find((c) => c.id === value) : undefined

                if (!_.isNil(v)) {
                    onSetDefaultValue(v)
                    setInternalValue(v)
                }

            } else if (!internalValue?.id && !_.isNil(defaultItemCode)) {
                let v = data ? data.find((c) => c.code === defaultItemCode) : undefined
                if (!_.isNil(v)) {
                    onSetDefaultValue(v)
                    setInternalValue(v)
                }
            }
        }


    }, [defaultItemCode, data, value])


    React.useEffect(() => {
        let filterJson = {};
        filterJson.displayName = filter;
        //Setting it to true because by default active dictionary values are needed in dropdownlist
        filterJson["active"] = true;

        if (props?.active) {
            if (props.active === false)
                delete filterJson.active
        }
        const req = {
                payload: {
                    active: true,
                    categoryCode: code
                },
                filter: filterJson,
                sort: null,
                limit: 50,
                offset: 0
            }
        PermissionAction.getDictionaryByCategory(req, code)(dispatch)
    }, [code, filter])


    return (<>
        <DropDownList style={props.style}
                      ref={inputRef}
                      required={required}
                      disabled={props.disabled}
                      data={data}
                      value={internalValue}
                      dataItemKey={"id"}
                      textField={"displayName"}
                      filterable={true}
                      onFilterChange={filterChange}
                      onChange={handleChange}
        />

    </>)
}


PermissionsDropDownList.propTypes = {
    style: PropTypes.object,
    code: PropTypes.string.isRequired,
    defaultItemCode: PropTypes.string,
    onChange: PropTypes.func,
    register: PropTypes.func,
}


export default PermissionsDropDownList