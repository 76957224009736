import * as React from "react";
import {useEffect} from "react";
import {TabStrip, TabStripTab} from "@progress/kendo-react-layout";
import {useDispatch, useSelector} from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import ConditionsTabComponent from "./ConditionsTab/ConditionsTabComponent";
import MedicalProblemsComponent from "./ConditionsTab/MedicalProblems/MedicalProblemsComponent";
import MedicationsComponent from "./ConditionsTab/Medications/MedicationsComponentV2";
import VitalsTabComponent from "./VitalsTab/VitalsTabComponent";
import TileHeader from "../../layout/TileHeader";
import {Components} from "../shared/ComponentRegistry";
import CarePlanTabComponent from "./CarePlanTab/CarePlanTabComponent";
import EncountersTabComponent from "./EncountersTab/EncountersTabComponent";
import LabsTestsTabComponent from "./LabsTestsTab/ClinicalOrderTabComponent";
import {WindowActions} from "../../action/WindowAction";
import QualityMeasuresComponent from "../CareTeamComponents/QualityMeasuresComponent";
import DocumentsTabComponent from "./DocumentsTab/DocumentsTabComponent"
import {Col, Container, Row} from "react-grid-system";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {TileRow} from "../../layout/TileRow";
import {PhixPermissions} from "../../config/GlobalConfig";
import {NoPermissions} from "../../util/PermissionsTool";

const initialTabs = [
    {
        title: "Medical Problems",
        content: <MedicalProblemsComponent/>,
        visible: true,
        icon: "fa-plus-square",
        permission: [PhixPermissions.AH_VIEW_MEDICAL_PROBLEM]
    },
    {
        title: "Medications",
        content: <MedicationsComponent/>,
        visible: true,
        icon: "fa-file-text-o",
        permission: [PhixPermissions.AH_VIEW_MEDICATION]
    },
    {
        title: "    Vitals   ",
        content: <VitalsTabComponent/>,
        visible: true,
        icon: "fa-thermometer-half",
        permission: [PhixPermissions.AH_VIEW_OBSERVATION]

    },
    {
        title: "  Clinical Orders ",
        content: <LabsTestsTabComponent/>,
        visible: true,
        icon: "fa-flask",
        permission: [PhixPermissions.AH_VIEW_OBSERVATION]

    },
    {
        title: " Documents ",
        content: <DocumentsTabComponent/>,
        visible: true,
        icon: "fa-folder",
        permission: [PhixPermissions.AH_VIEW_DOCUMENT]
    },
    {
        title: "  Past History ",
        content: <ConditionsTabComponent/>,
        visible: true,
        icon: "fa-user-md",
        permission: [PhixPermissions.AH_VIEW_MEDICAL_PROBLEM]

    },
    {
        title: "  Encounters ",
        content: <EncountersTabComponent/>,
        visible: true,
        icon: "fa-list",
        permission: [PhixPermissions.AH_VIEW_ENCOUNTER, PhixPermissions.AH_VIEW_ENCOUNTER_SUMMARY, PhixPermissions.AH_VIEW_ENCOUNTER_LOCKED]
    },
    {
        title: "  Care Plan  ",
        content: <CarePlanTabComponent/>,
        visible: true,
        icon: "fa-clipboard",
        permission: [PhixPermissions.AH_VIEW_CAREPLAN]
    },
    // {
    //     title: "Immunizations",
    //     content: <ImmunizationsTabComponent/>,
    //     visible: true,
    //     icon: "fa-eyedropper",
    // },
    // {
    //     title: "     HCC     ",
    //     content: " HCC ",
    //     visible: true,
    //     icon: "fa-hospital-o",
    // },
    {
        title: "       QM    ",
        content: <QualityMeasuresComponent/>,
        visible: true,
        icon: "fa-star",
        permission: [PhixPermissions.AH_VIEW_QUALITY_MEASURES]

    },
    // {
    //     title: "Programs",
    //     content: " Programs ",
    //     visible: true,
    //     icon: "fa-paint-brush",
    // },
    // {
    //     title: "Utilizations",
    //     content: " Utilizations ",
    //     visible: true,
    //     icon: "fa-star-half-o",
    // },
];

const styles = {
    tabContainer: {
        width: "100%",
        minWidth: 100,
        background: "#BAC1E0",
        padding: "5px",
        textAlign: "center",
        color: "#FFF"
    },
    tabContainerSelected: {
        width: "100%",
        minWidth: 100,
        background: "#7280c0",
        padding: "5px",
        textAlign: "center",
        color: "#FFF"
    },
    tabIcon: {
        width: "100%",
        padding: "5px 0",
        fontSize: "1.5rem",
        color: "#FFF"
    },
    tabTitle: {
        width: "100%",
        color: "#FFF"
    },
}

const Title = (props) => {
    return (
        <Container style={props.selected === props.index ? styles.tabContainerSelected : styles.tabContainer}>
            <Row>
                <Col>
                    <span className={`fa ${props.icon}`} style={styles.tabIcon}/>
                </Col>

            </Row>
            <Row>
                <Col>
                    <span style={styles.tabTitle} className="tabTitle">{props.content}</span>
                </Col>
            </Row>
        </Container>
    );
};

const PatientPanelTabComponent = (props) => {
    const dispatch = useDispatch()
    const windowState = useSelector(state => state.windowState)
    const handleSelect = (e) => {
        WindowActions.setWindowState("PatientPanelTabComponent", {selectedTab: e.selected})(dispatch)
    };
    const permissions = useSelector(state => state.auth.session.permissions)
    const [tabs, setTabs] = React.useState([]);

    useEffect(() => {
        setTabs(initialTabs.filter(i =>  i.permission.some(r => permissions.includes(r))))
    }, [permissions])


    const TabContent = (permissions, item) => {
        if (permissions.isEdit === true) {
            return <>{item.content}</>;
        } else if (permissions.isView === true) {
            return <div style={{pointerEvents: 'none'}}> {item.content} </div>;
        } else {
            return <NoPermissions/>;
        }
    }

    return (

        <TileContainer>
            <TileHeader title={"Clinical Panel"}
                        headerColor={"black"}
                        style={{backgroundColor: "#d4d9e8", color: "#7585C2", fontSize: "15px"}}
                        expanded={Components.PatientPanelTabComponent}>
            </TileHeader>

            <TileBody>
                <TileRow style={{height: "100%"}}>
                    <TabStrip
                        selected={windowState?.PatientPanelTabComponent?.selectedTab || 0}
                        onSelect={handleSelect}
                        animation={false}

                        className={"clinicalTabBar"}
                        tabPosition={"top"}
                        tabContentStyle={{height: "100%", width: "100%"}}

                    >

                        {tabs.map((item, index) => {
                            return (
                                item.visible && (
                                    <TabStripTab
                                        className={"ah-tab-container"}
                                        contentClassName={"ah-tab"}
                                        title={<Title content={item.title} icon={item.icon} index={index}
                                                      selected={windowState?.PatientPanelTabComponent?.selectedTab || 0}/>}
                                        key={index}>
                                        {item.content}
                                    </TabStripTab>
                                )
                            );
                        })}
                    </TabStrip>
                </TileRow>
            </TileBody>
        </TileContainer>
    );
};


export default PatientPanelTabComponent;
