import React from "react";
import { connect } from 'react-redux';
import { handleTreeViewCheckChange, processTreeViewItems, TreeView, } from "@progress/kendo-react-treeview";
import { bindActionCreators } from "redux";
import { DictionaryDispatchAction } from "../../../action/DictionaryDispatchAction";
import { DictionaryTypeAction } from "../../../action/DictionaryTypeAction";
import { PatientAction } from "../../../action/PatientAction";
import { UserActions } from "../../../action/UserAction"


const DocumentTreeViewComponent = (props) => {
  const { setFlag, setSelectedDocument } = props;
  const [data, setData] = React.useState();
  const [check, setCheck] = React.useState([]);
  const [expand, setExpand] = React.useState({
    ids: [],
    idField: "text",
  });
  const [select, setSelect] = React.useState([""]);
  const [selectedFolder, setSelectedFolder] = React.useState("")


  React.useEffect(() => {
    props.DictionaryActions.getDictionaryByCode('AH_DOCUMENT_CATEGORIES', 500, 0);
    const payload = {
      patientRoleId: props.patientContext.patientRoleId,
      userRoleId: props.getUsersInfo.userRoleId ? props.patientContext.patientRoleId : localStorage.getItem("userRoleId")
    }
    props.PatientActions.getClinicalDocument(payload);
  }, [])

  React.useEffect(() => {
    const dict = props?.dictionaries?.AH_DOCUMENT_CATEGORIES
    if (dict) {
      const data = [];
      dict.forEach((i) => {
        data.push({ text: i?.displayName ? i?.displayName : "", displayName: i?.displayName ? i?.displayName + ".folder" : "", items: [] })
      })
      props?.getClinicalDocument?.forEach((i) => {
        const findDict = dict?.find((a) => a.id === i.dictDocumentCategoryId);
        const sameData = data.find((i) => i?.displayName === findDict?.displayName)
        const sameDataIndex = data.findIndex((i) => i?.displayName === findDict?.displayName)
        if (sameData) {
          
          data.splice(sameDataIndex, 1);
          data.push({
            ...sameData, displayName: sameData.displayName + ".folder", items: [
              ...sameData.items,
              { ...i, text: i?.fileName, displayName: i?.displayName }
            ]
          })
        } else {
          data.push({
            ...i, text: findDict?.displayName ? findDict?.displayName : "", displayName: findDict?.displayName ? findDict?.displayName : "", items: [
              { ...i, text: i?.fileName, displayName: i?.displayName }
            ]
          })
        }
      })
      const newArr = [];
      const uniquesText = data.map((t) => t.text);
      const setSerialized = new Set(uniquesText);
      const uniqueArrSerialized = [...setSerialized];
      uniqueArrSerialized.forEach((i) => {
        
        const filterData = data.filter((x) => x.text === i);
        
        if (filterData.length >= 2) {

          const itemArray = [];

          filterData.forEach((filterObj) => {
            if(filterObj && filterObj.items.length){
              filterObj.items.forEach((filItem) => {
                itemArray.push(filItem)
              })
              
              //newArr.push(filterObj);
            }
          })
          
          const rid = filterData.find((c) => c.items.length !== 0)
          rid.items = itemArray
          
          newArr.push(rid);
        } else {
          newArr.push(filterData[0]);
        }
      })

      setData(newArr)
    }

  }, [props?.getClinicalDocument])

  React.useEffect(() => {
    if(selectedFolder && selectedFolder.length) {
      selectedFolder.forEach((i) => {
        const idx = data.find((a) => a.text === i && a.items.length !== 0);
        if(idx) {
          setSelectedFolder(selectedFolder.filter((x) => x !== i));
        }
      })
    }
  }, [props.editClinicalDocument])

  const onItemClick = (event) => {
    console.log(event)
    if (event.item.displayName.includes('.folder') || event.item.displayName.includes('.folder-open') || !event.itemHierarchicalIndex.includes('_')) {
      if (selectedFolder.includes(event.item.text)) {
        const removeById = selectedFolder.findIndex((i) => i === event.item.text)
        if (removeById !== -1) {
          const arr = selectedFolder;
          arr.splice(removeById, 1);
          const ids = selectedFolder.filter((i) => i !== event.item.text)
          setExpand({
            ids,
            idField: "text",
          });
          setSelectedFolder(arr);
        }
      } else {
        if (event.item.items.length !== 0) {
          const folderText = [...selectedFolder, event.item.text];
          const ids = folderText;
          setExpand({
            ids,
            idField: "text",
          });
          setSelectedFolder(folderText);
        }
      }
    } else {
      const selectedDocument = props?.getClinicalDocument.find((i) => i.clinicalDocumentId === event?.item?.clinicalDocumentId)
      setFlag(true);
      setSelectedDocument(selectedDocument)

      props.PatientActions.setClinicalDocumentContext({ ...props.clinicalDocumentContext, ...selectedDocument })
      setSelect([event.itemHierarchicalIndex]);
    }
  };

  // const onExpandChange = (event) => {
  //   let ids = expand.ids.slice();
  //   const index = ids.indexOf(event.item.text);
  //   index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
  //   setExpand({
  //     ids,
  //     idField: "text",
  //   });
  // };

  const onCheckChange = (event) => {
    const settings = {
      singleMode: false,
      checkChildren: false,
      checkParents: false,
    };
    setCheck(handleTreeViewCheckChange(event, check, settings));
  };

  const expandAll = () => {
    setData(
      data.map((item) =>
        Object.assign({}, item, {
          expanded: true,
        })
      )
    );
  };

  const collapseAll = () => {
    setData(
      data.map((item) =>
        Object.assign({}, item, {
          expanded: false,
        })
      )
    );
  };

  const onExpandChange = (event) => {
    // const ext = {
    //   expanded: true,
    // }
    // setData([ ...data, { ...event.item, text: event.item.text.split(".folder").join(".folder-open"), ...ext}])

    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.text);
    index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
    setExpand({
      ids,
      idField: "text",
    });


    const updatedData = data.slice();
    const itemIndex = updatedData.indexOf(event.item);
    updatedData[itemIndex] = { ...event.item };
    updatedData[itemIndex].expanded = !updatedData[itemIndex].expanded;
    setData(updatedData);
  };

  const MyItem = (props) => {
    return (
      <>
        <span className={iconClassName(props.item)} key="0" onClick={() => {
          // props.PatientAction.setClinicalDocumentContext({ payload: props.item })
        }} /> {props.item.text.length > 20 ? `${props.item.text.substring(0, 20)} ...` : props.item.text}
      </>
    );
  };

  const is = (fileName, ext) => new RegExp(`.${ext}\$`).test(fileName);

  function iconClassName({ text, items, displayName }) {
    if (is(displayName, 'folder') && !selectedFolder.includes(text)) {
      return "k-icon k-i-folder";
    } else if (selectedFolder.includes(text)) {
      return "k-icon k-i-folder-open";
    } else if (is(text, "pdf")) {
      return "k-icon k-i-file-pdf";
    } else if (is(text, "html")) {
      return "k-icon k-i-html";
    } else if (is(text, "jpg|png|bmp")) {
      return "k-icon k-i-image";
    } else if (is(text, "bmp")) {
      return "k-icon k-i-file";
    } else {
      return "k-icon k-i-folder";
    }
  }

  return (
    <div style={{ margin: "10px -15px" }}>
      {/* <TreeView
        data={processTreeViewItems(tree, {
          expand: expand,
        })}
        expandIcons={true}
        onExpandChange={onExpandChange}
        aria-multiselectable={true}
        onItemClick={onItemClick}
        checkboxes={true}
        onCheckChange={onCheckChange}
      /> */}

      <TreeView
        data={processTreeViewItems(data, {
          expand: expand,
        })}
        item={MyItem}
        expandIcons={false}
        onExpandChange={onExpandChange}
        onItemClick={onItemClick}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    dictionaries: state.dictionary.dictionaries,
    pagination: state.dictionary.pagination,
    getClinicalDocument: state.patient.getClinicalDocument,
    patientContext: state.patient.patientContext,
    getUsersInfo: state.user.loggedinUser,
    userContext: state.user,
    clinicalDocumentContext: state.patient.clinicalDocumentContext,
    editClinicalDocument: state.patient.editClinicalDocument,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
    PatientActions: bindActionCreators(PatientAction, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTreeViewComponent);