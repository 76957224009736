import {ActionTypes} from "../action/FormAction";
import {IPayload} from "../Interfaces/Payload";


const initialState = {
    formList: {},
    adminFormList: {},
    formElements: [],
}




export const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FORMS_LIST_FORMS:
            return {...state, formList: action.payload}
        case ActionTypes.ADMIN_FORMS_LIST_FORMS:
            return {...state, adminFormList: action.payload}

        case ActionTypes.FORMS_SET_EDIT_FORM:
            return {...state, editFormContext: action.payload}
        case ActionTypes.CLEAR_ENCOUNTER_FORMS:
            return {...state, formElements: action.payload}
        case ActionTypes.GET_ENCOUNTER_FORMS:
            return {...state, formElements: action.payload.payload}
        case ActionTypes.GET_FORM_BY_ID:
            return {...state, formReportById: action.payload.payload}
        case ActionTypes.FORM_REPORT_INPUT_DATA:
            return {...state, FormReportInputData: action.payload.payload}
        default:
            return state
    }

}

