import {Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import * as React from "react";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from "primereact/button";
import _ from "lodash";
import {UserActions} from "../../action/UserAction";
import ContactPhoneComponent from "../shared/ContactPhoneComponent";
import ContactEmailComponent from "../shared/ContactEmailComponent";
import {Field, FieldRow, FieldSection, FieldTextInput} from "../InputControls/FormFields";
import {PermissionsTool} from "../../util/PermissionsTool";
import {GridToolbar} from "@progress/kendo-react-grid";
import {ToolbarSpacer} from "@progress/kendo-react-buttons";

const EditUserProfileWindow = (props) => {
    const {
        userContext, PopupActions, UserActions, ...others
    } = props;
    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        getValues,
        setValue,
        formState,
        setError,
        formState: {errors, isDirty, isSubmitting, touchedFields, submitCount},
    } = useForm({defaultValues: {active: true}});
    const [viewProfilePermissions, setViewProfilePermissions] = React.useState({isEdit:false, isView: false})
    const [viewAdminProfilePermissions, setViewAdminProfilePermissions] = React.useState({isEdit:false, isView: false})
    const [imageURL, setImageURL] = React.useState(null);
    const [showSign, setShowSign] = React.useState(false);
    const hideDialog = () => {
        setImageURL(null)
        PopupActions.hideWindow(PopupWindows.EDIT_USER_PROFILE_WINDOW)
        UserActions.clearUserContext()
        UserActions.clearLicenseContext()
        UserActions.clearSignature()
        reset();
    };

    React.useEffect(() => {
        setViewProfilePermissions(PermissionsTool({permissionInfo: props.permissionInfo, viewPermissions: ["AH_VIEW_USER"], editPermissions:["AH_EDIT_USER"]}));
        setViewAdminProfilePermissions(PermissionsTool({permissionInfo: props.permissionInfo, viewPermissions: ["AH_ADMIN_VIEW_USERS"], editPermissions:["AH_ADMIN_EDIT_USERS"]}));
    },[props?.permissionInfo]);

    React.useEffect(() => {
        if(props.signature && userContext?.id === props.signature.userRoleId) {
            setImageURL(props.signature.fileBase64?.startsWith("data:image/png;base64,") ? props.signature.fileBase64 : "data:image/png;base64,"+props.signature.fileBase64 )
        }
    },[props?.signature]);

    const disabled = !!userContext;

    const saveUser = async (form) => {

        let payload = {}
        payload.userRoleId = form.userRoleId
        payload.personId = form.personId
        payload.userName = form.userName
        payload.active = form?.active
        payload.firstName = form?.firstName
        payload.middleName = form?.middleName
        payload.lastName = form?.lastName
        payload.suffix = form?.suffix
        payload.credentials = form?.credentials
        payload.npi = form?.npi
        payload.dictPermissionGroupId = form?.dictPermissionGroupId?.id ? form.dictPermissionGroupId.id : form?.dictPermissionGroupId
        payload.dictProviderTypeId = form?.dictProviderTypeId?.id ? form.dictProviderTypeId.id : form?.dictProviderTypeId
        payload.managerUserRoleId = form?.managerUserRoleId?.userRoleId ? form?.managerUserRoleId.userRoleId : form?.managerUserRoleId
        payload.supervisorUserRoleId = form?.supervisorUserRoleId?.userRoleId ? form?.supervisorUserRoleId.userRoleId : form?.supervisorUserRoleId
        if (form.userRoleId) {
            payload.userRoleId = form.userRoleId
            UserActions.updateUser(payload).then(
                hideDialog()
            )
        } else {
            payload.secure = form?.secure
            UserActions.createUser(payload)
        }

    };


    React.useEffect(() => {
        if (!_.isEmpty(userContext) && props.showWindow[PopupWindows.EDIT_USER_PROFILE_WINDOW]) {
            setValue("userRoleId", userContext?.userRoleId)
            setValue("credentials", userContext?.credentials)
            setValue("personId", userContext?.personId)
            setValue("userName", userContext?.userName)
            setValue("active", userContext?.active)
            setValue("firstName", userContext?.firstName)
            setValue("middleName", userContext?.middleName)
            setValue("lastName", userContext?.lastName)
            setValue("suffix", userContext?.suffix)
            setValue("npi", userContext?.npi)
            setValue("dictPermissionGroupId", userContext?.dictPermissionGroupId)
            setValue("dictProviderTypeId", userContext?.dictProviderTypeId)
            setValue("managerUserRoleId", userContext?.managerUserRoleId)
            setValue("supervisorUserRoleId", userContext?.supervisorUserRoleId)
        }
    }, [userContext])
    const UsernameIsUnique = async (username) => {
        if (userContext && userContext?.userName == username) {
            return true;
        }
        let user = await UserActions.checkUsername(username);
        try {
            // console.log("user", user.payload && user.payload.userName);
            return user.payload && user.payload.userName !== username;
        } catch (e) {
        }
        return true;
    };
    const viewPerm = () => {
        if (viewProfilePermissions.isView && viewProfilePermissions.isEdit) {
            return true;
        }  else if (viewProfilePermissions.isEdit) {
            return true;
        } else if (viewAdminProfilePermissions.isView && viewAdminProfilePermissions.isEdit) {
            return true;
        } else if (viewAdminProfilePermissions.isEdit) {
            return true;
        } else {
            return false;
        }
    }
    if (props.showWindow[PopupWindows.EDIT_USER_PROFILE_WINDOW])
    return (<Window
        title={!_.isEmpty(userContext) ? `Update User` : "Add New User"}
        modal={true}
        onClose={hideDialog}
        initialHeight={800}
        initialWidth={1000}>
        <div style={{pointerEvents: viewPerm() ? undefined : 'none' }}>
            <div className={"sub-title"}>User Information</div>
            <FieldSection>
                <FieldRow>

                    <Field col={6} label={"First Name"} field={"firstName"} error={errors}
                           register={register} options={{required: "First Name is required."}}>
                        <FieldTextInput/>
                    </Field>

                    <Field col={6} label={"Last Name"} field={"lastName"} error={errors} register={register}
                           options={{required: "Last Name is required."}}>
                        <FieldTextInput/>

                    </Field>
                </FieldRow>
                <FieldRow>
                    <Field col={12} label={"Credentials"} field={"credentials"} error={errors}
                           register={register}>
                        <FieldTextInput/>
                    </Field>
                </FieldRow>
            </FieldSection>


            {disabled && (
                <div>
                    <div className="sub-title">Contact Phone</div>
                    <ContactPhoneComponent
                        personId={userContext?.personId}
                    />
                </div>
            )}
            {disabled && (
                <div>
                    <div className="sub-title">Contact Email</div>
                    <ContactEmailComponent
                        personId={userContext?.personId}
                    />
                </div>
            )}
            {disabled && (
                <div>
                    <div className="sub-title">Signature</div>
                    <GridToolbar>
                    <ToolbarSpacer/>
                        <Button
                            icon={"pi pi-pencil"}
                            text
                            severity={"success"}
                            onClick={(e) => {
                                const payload = {userRoleId: userContext?.userRoleId};
                                props.UserActions.downloadSignature(payload)
                                props.PopupActions.showWindow(PopupWindows.SIGNATURE_WINDOW)
                                }}></Button>
                        <Button
                            onClick={(e) => {
                                    const payload = {userRoleId: userContext?.userRoleId};
                                    props.UserActions.downloadSignature(payload).then(()=> {
                                        setShowSign(!showSign)
                                    })
                                }}> {showSign ? "Hide" : "Show"} </Button>
                    </GridToolbar>
                    {
                        showSign && imageURL && <img
                          src={imageURL}
                          height={200}
                          width={200}
                          title="Uploaded signature"
                          draggable="false"
                        />
                    }

                </div>
            )}
            <WindowActionsBar>
                <Button primary onClick={handleSubmit(saveUser)}>
                    {userContext?.userRoleId ? "Update" : "Save"}
                </Button>
                <Button onClick={hideDialog}>
                    Cancel
                </Button>
            </WindowActionsBar>
        </div>
    </Window>); else return null;
};

EditUserProfileWindow.propTypes = {
    showWindow: PropTypes.object, PopupActions: PropTypes.object, UserActions: PropTypes.object,
}


function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        userContext: state.user.userContext,
        signature: state.user.signature,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {

        UserActions: bindActionCreators(UserActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserProfileWindow);
