import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../action/DictionaryDispatchAction";
import {FormAction} from "../action/FormAction";
import {EncounterAction} from "../action/EncounterAction";
import {Col, Container, Row} from "react-grid-system";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ListBox, processListBoxData } from "@progress/kendo-react-listbox";
import {Button} from "primereact/button";
import ShowForPermissionComponent from './shared/ShowForPermissionComponent';
import {PhixPermissions} from "../config/GlobalConfig";

const SELECTED_FIELD = "selected";
const EncounterTypeListBox = (props) => {

  const lastSelectedIndex = React.useRef(0);

  const handleItemClick = (event, data, connectedData) => {
    let last = lastSelectedIndex.current;
    const newData = [...props.state[data]];
    const current = newData.findIndex(
      (dataItem) => dataItem === event.dataItem
    );

    if (!event.nativeEvent.shiftKey) {
      lastSelectedIndex.current = last = current;
    }

    if (!event.nativeEvent.ctrlKey) {
      newData.forEach((item) => (item.selected = false));
    }

    const select = !event.dataItem.selected;

    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      newData[i].selected = select;
    }

    props.setState({
      ...props.state,
      [data]: newData,
      [connectedData]: props.state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleToolBarClick = (e) => {
    let toolName = e.toolName || "";
    let result = processListBoxData(
      props.state.availableForms,
      props.state.selectedForms,
      toolName,
      SELECTED_FIELD
    );
    props.setState({
      ...props.state,
      availableForms: result.listBoxOneData.map((item) => {
        item.selected = false;
        return item;
      }),
      selectedForms: result.listBoxTwoData.map((item) => {
        item.selected = false;
        return item;
      }),
    });
  };

  const saveOrUpdate = (input) => {
    if(props.dictEncounterType){
        const idsToSave = input.selectedForms.map(v=> v.id);
        const oppIdsToSave = props.oppState.selectedForms.map(v=> v.id);
        var payload = {
            dictEncounterTypeId : props.dictEncounterType.id,
        };
        if(props.formType === "recommended") {
            payload["recommendedForms"] = idsToSave;
            payload["optionalForms"] = oppIdsToSave;
        } else {
            payload["optionalForms"] = idsToSave;
            payload["recommendedForms"] = oppIdsToSave;
        }
        props.props.EncounterActions.addEncounterFormConfig(payload).then(()=>{
            props.props.EncounterActions.getAllEncounterFormConfig({dictEncounterTypeId: props.dictEncounterType.id})
        });
    }
  }


  const moveRight = () => {
    const selectedListValues = props.state.availableForms.filter(v=> v.selected);
    if(selectedListValues && selectedListValues.length > 0){
        const addableForms = [...props.state.selectedForms, selectedListValues];
        const input = {
                         ...props.state,
                         availableForms: props.state.availableForms.filter(v=> !v.selected),
                         selectedForms: [...props.state.selectedForms, ...selectedListValues],
                       };
        saveOrUpdate(input);
    }
  };

  const moveLeft = () => {
    const selectedListValues = props.state.selectedForms.filter(v=> v.selected);
    if(selectedListValues && selectedListValues.length > 0){
        const addableForms = [...props.state.selectedForms, selectedListValues]
        const input = {
          ...props.state,
          availableForms: [...props.state.availableForms, ...selectedListValues],
          selectedForms: props.state.selectedForms.filter(v=> !v.selected),
        };
        saveOrUpdate(input);
    }
  };

  const moveAllRight = () => {
     const selectedListValues = props.state.availableForms;
      const input = {
        ...props.state,
        availableForms: [],
        selectedForms: [...props.state.selectedForms, ...selectedListValues],
      };
      saveOrUpdate(input);
  };
  const moveAllLeft = () => {
      const selectedListValues = props.state.selectedForms;
      const input = {
        ...props.state,
        availableForms: [...props.state.availableForms, ...selectedListValues],
        selectedForms: [],
      };
      saveOrUpdate(input);
  };

  const move = (isDown) => {
    const moveSelectedForms = isDown ? [...props.state.selectedForms.reverse()] : [...props.state.selectedForms];
    const selectedListValues = moveSelectedForms.filter(v=> v.selected).map(v=> v.id);
    if(selectedListValues.length > 0){
        moveSelectedForms.forEach((ele, i) => {
            if(selectedListValues.indexOf(ele.id) > -1 && i > 0){
                const temp = moveSelectedForms[i-1];
                moveSelectedForms[i-1] = moveSelectedForms[i];
                moveSelectedForms[i] = temp;
            }
        });
        const finalSelectedForms = isDown ? moveSelectedForms.reverse() : moveSelectedForms;
        const input = {
           ...props.state,
           availableForms: props.state.availableForms,
           selectedForms: finalSelectedForms,
        };
        saveOrUpdate(input);
    }
  }

  const CustomFormItem = (props) => {
    let { dataItem, selected, ...others } = props;
    return (
      <li {...others}>
        <div>
          <span>{props.dataItem.displayName}</span>
        </div>
      </li>
    );
  };

  return (
      <Row>
        <Col>
          <h6>Available Forms:</h6>
          <ListBox
            style={{
              height: 400,
              width: "100%",
            }}
            item={CustomFormItem}
            data={props.state.availableForms}
            textField="displayName"
            selectedField={SELECTED_FIELD}
            onItemClick={(e) => handleItemClick(e, "availableForms", "selectedForms")}
          />
        </Col>
        <Col sm={1}>
            <div style={{marginTop:"30%"}}>
              <Button style={{width:"110%", marginBottom:"5%"}} onClick={() => moveRight()}>{"Add ->"}</Button>
              <Button style={{width:"110%"}} onClick={() => moveLeft()}>{"<- Remove"}</Button>
              <Button style={{width:"110%", marginTop:"300%", marginBottom:"5%"}} onClick={() => moveAllRight()}>{"Add All"}</Button>
              <Button  style={{width:"110%"}} onClick={() => moveAllLeft() }>{"Remove All"}</Button>
            </div>
        </Col>
        <Col>
          <h6>Selected Forms:</h6>
          <ListBox
            style={{
              height: 400,
              width: "100%",
            }}
            data={props.state.selectedForms}
            item={CustomFormItem}
            textField="displayName"
            selectedField={SELECTED_FIELD}
            onItemClick={(e) => handleItemClick(e, "selectedForms", "availableForms")}
          />
        </Col>
        <Col sm={1}>
            <div style={{height:100, marginTop:"400%"}}>
              <Button style={{width:"110%", marginBottom:"5%"}} onClick={() => move(false)}>{"Move Up"}</Button>
              <Button style={{width:"110%"}} onClick={() => move(true)}>{"Move Down"}</Button>
            </div>
        </Col>
        <Col sm={1}>
        </Col>
      </Row>
  );
};


const EncounterConfigurationComponent = (props) => {

    const [filter, setFilter] = React.useState({})
    const [encounterType, setEncounterType] = React.useState(null)

    const [recommendedState, setRecommendedState] = React.useState({
                                                                   availableForms: [],
                                                                   selectedForms: [],
                                                                 });
    const [optionalState, setOptionalState] = React.useState({
                                                               availableForms: [],
                                                               selectedForms: [],
                                                             });

    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });
    const [sort, setSort] = React.useState([{
        field: "displayName",
        dir: "asc",
    }]);

    React.useEffect(() => {
      props.EncounterActions.getDictionaryByCode("AH_ENCOUNTER_TYPE");
    }, []);

    const itemRender = (li, itemProps) => {
        var itemChildren = React.Children.map(li.props.children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, (itemProps.dataItem.active == true) ? {} : {style:{color: 'red'}});
            }
            return child;
        });
        return React.cloneElement(li, li.props, itemChildren);
    };

    React.useEffect(()=>{
        if(encounterType){
            props.EncounterActions.getAllEncounterFormConfig({dictEncounterTypeId: encounterType.id})
        } else {
            props.EncounterActions.clearEncounterFormConfig();
        }
    },[encounterType])

    React.useEffect(() => {
        props.FormActions.getFormSchemaList(dataState.take, dataState.skip, sort, filter)
    }, [dataState, sort, filter])

    React.useEffect(() => {
         if(props.formList){
            var formList = props.formList;
            if(props.encounterFormConfig.length > 0){
                const recommendedForms = props.encounterFormConfig[0]?.recommendedForms?.map(v=> v.id)
                const optionalForms = props.encounterFormConfig[0]?.optionalForms?.map(v=> v.id)
                formList = props.formList.filter(v => recommendedForms.indexOf(v.id) <= -1 && optionalForms.indexOf(v.id) <= -1 )
            }
            setRecommendedState({
               ...recommendedState,
               availableForms: JSON.parse(JSON.stringify(formList)),
               selectedForms: props.encounterFormConfig[0]?.recommendedForms ? props.encounterFormConfig[0]?.recommendedForms : [],
            });
             setOptionalState({
               ...optionalState,
               availableForms: JSON.parse(JSON.stringify(formList)),
               selectedForms: props.encounterFormConfig[0]?.optionalForms ? props.encounterFormConfig[0]?.optionalForms : [],
            });
         }
    }, [props.encounterFormConfig])

    function handleChange(event){
        setEncounterType(event.target.value)
        props.EncounterActions.getAllEncounterFormConfig({dictEncounterTypeId: event.target.value.id});
    }


    return (
        <React.Fragment>
          <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_ADMIN_VIEW_ENCOUNTER]}>
            <Container style={{height: "100%", width: "100%"}}>
                <div style={{margin: 0, overflow: "auto", height:"100%"}}>
                    <Row>
                       <Col sm={2}>
                           <span style={{fontWeight:"bold"}}> Encounter Type </span>
                       </Col>
                       <Col sm={4}>
                           <DropDownList
                             style={{
                               width: "300px",
                             }}
                             data={props.encounterTypes}
                             itemRender={itemRender}
                             textField="displayName"
                             dataItemKey="id"
                             onChange={handleChange}
                           />
                       </Col>
                    </Row>
                    <br></br>
                    {
                        encounterType ? <>
                            <Row>
                               <Col sm={2}>
                                   <span style={{fontWeight:"bold"}}> Recommended Forms </span>
                               </Col>
                            </Row>
                            <br></br>
                            {
                                props?.formList ? <EncounterTypeListBox formType={"recommended"}
                                                                        state={recommendedState}
                                                                        setState={(v) => setRecommendedState(v)}
                                                                        dictEncounterType={encounterType}
                                                                        oppState={optionalState}
                                                                        props={props} /> : <></>
                            }
                            <hr></hr>
                            <Row>
                               <Col sm={2}>
                                   <span style={{fontWeight:"bold"}}> Optional Forms </span>
                               </Col>
                            </Row>
                            <br></br>
                            {
                                props?.formList ? <EncounterTypeListBox formType={"optional"}
                                                                        state={optionalState}
                                                                        setState={(v) => setOptionalState(v)}
                                                                        dictEncounterType={encounterType}
                                                                        oppState={recommendedState}
                                                                        props={props} /> : <></>
                            }
                            <br></br> </> : <></>
                    }
                </div>
            </Container>
          </ShowForPermissionComponent>
        </React.Fragment>
    );
};

function mapStateToProps(state) {
    return {
        encounterTypes: state.encounters.dictionaries.AH_ENCOUNTER_TYPE,
        formList: state.form.formList.payload,
        encounterFormConfig: state.encounters.encounterFormConfig,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryAction: bindActionCreators(DictionaryDispatchAction, dispatch),
        FormActions: bindActionCreators(FormAction, dispatch),
        EncounterActions: bindActionCreators(EncounterAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EncounterConfigurationComponent)
