import {ActionTypes} from "../action/InterfaceAction";


const initialState = {
    sidebar: {expanded: false}
}

export const interfaceReducer = (state = initialState, action) => {
    switch (action.type) {

        case ActionTypes.INTERFACE_TOGGLE_SIDEBAR:
            return {...state, sidebar: {...state.sidebar, expanded: !state.sidebar.expanded}};

        default:
            return state
    }

}

