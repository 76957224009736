import {Button} from "primereact/button";
import PropTypes from "prop-types";
import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PatientAction} from "../../action/PatientAction";
import {EncounterAction} from "../../action/EncounterAction";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Column} from "../InputControls/FormFields";
import {PermissionsTool} from "../../util/PermissionsTool";
import {Dialog} from "primereact/dialog";
import {Container} from "../../layout/GridLayout";
import {useToast} from "../../providers/toast/ToastProvider";
import {
    FormColumn,
    FormDateSelector,
    FormDictionaryDropdown,
    FormMultiDictionaryDropdown,
    FormRow,
    FormTextInput,
    usePhixForm
} from "../InputControls/NewFormFields";
import {Col} from "react-grid-system";
import {FilterDefaultValuesFromForm} from "../../util/MiscTools";


const defaultValues = {
    id: null,
    dictClinicalStatusId: null,
    diagnosisCode: null,
    surgicalCode: null,
    admissionDate: null,
    dischargeDate: null,
    dictSourceOfInfoId: null,
    outcome: null,
    documentDisplayName: null,
    description: null,
    surgeryDisplayName: null,
    displayName: null,
    note: null,
}
const AddHospitalizationsWindow = (props) => {
    const dispatch = useDispatch()
    const hospitalizationContext = useSelector(state => state.patient.hospitalizationContext)
    const getClinicalDocument = useSelector(state => state.patient.getClinicalDocument)
    const showWindow = useSelector(state => state.popupWindow.showWindow)
    const permissionInfo = useSelector(state => state.auth.session.permissions)
    const patientContext = useSelector((state) => state.patient.patientContext)
    const hospitalization = useSelector(state => state.patient.hospitalizationID)
    const userContext = useSelector(state => state.user.loggedInUser)
    const hospitalizationSel = useSelector(state => state.encounters.hospitalizations)
    const encounterContext = useSelector(state => state.encounters.encounterContext)
    const hospital = useSelector(state => state.patient.hospitalizationRecord)
    const [{
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch,
    }, PhixForm] = usePhixForm(defaultValues);
    const {addToast} = useToast()
    const [documentList, setDocumentList] = React.useState([]);
    const [errMessage, setErrMessage] = React.useState();
    const [docButton, setDocButton] = React.useState(true);
    const [hospitalId, setHospitalId] = React.useState(null);
    const [hospitalizationPermission, setHospitalizationPermission] = React.useState({isEdit: false, isView: false})

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'diagnosisCode') {
                let description = value?.diagnosisCode?.map(d => d.displayName).join("; ")
                let displayName = value?.diagnosisCode?.map(d => d.code).join("; ")
                if (description && description.length > 0) setValue("description", description)
                if (displayName && displayName.length > 0) setValue("displayName", displayName)
            }
            if (name === 'surgicalCode') {
                let v = value?.surgicalCode?.map(d => d.displayName).join("; ")
                if (v && v.length > 0) setValue("surgeryDisplayName", v)
            }
        })
        return () => subscription.unsubscribe();
    }, [watch]);

    React.useEffect(() => {
        setHospitalizationPermission(PermissionsTool({
            permissionInfo: permissionInfo,
            viewPermissions: ["AH_VIEW_HOSPITALIZATION"],
            editPermissions: ["AH_EDIT_HOSPITALIZATION"]
        }));
    }, [permissionInfo]);

    React.useEffect(() => {
        if (hospitalizationContext) {
            reset(hospitalizationContext)
            if (hospitalizationContext?.id) {
                PatientAction.getClinicalDocument({
                    hospitalizationId: hospitalizationContext.id,
                })(dispatch)
            }
        }
    }, [hospitalizationContext])

    React.useEffect(() => {
        if (getClinicalDocument) {
            setDocumentList(getClinicalDocument?.filter(v => v.hospitalizationId && v.hospitalizationId === hospitalizationContext?.id))
        }
    }, [getClinicalDocument]);

    const hideDialog = async () => {
        if (patientContext?.patientRoleId)
            await PatientAction.getHospitalization(patientContext?.patientRoleId)(dispatch)
        await PopupWindowActions.hideWindow(PopupWindows.ADD_HOSPITALIZATIONS_SURGERIES_WINDOW)(dispatch);
        await PatientAction.clearHospitalization()(dispatch)
        await PatientAction.clearHospitalContext()(dispatch)
        await PatientAction.clearGetClinicalDocument()(dispatch)
        setDocButton(true)
        setDocumentList([])
        reset(defaultValues)
    };

    const saveForm = async form => {
        let id = form?.id
        form = FilterDefaultValuesFromForm(form, defaultValues)
        form.patientRoleId = patientContext.patientRoleId
        form.saveFlag = (docButton !== false)
        if (form?.id) {
            await PatientAction.updateHospitalization(form)(dispatch)
            await PatientAction.getHospitalization(patientContext.patientRoleId)(dispatch)
            await EncounterAction.setHospitalizationId({id: form.id})(dispatch)
            if(!hospitalizationSel?.find(v=> v.id === form.id))
                 EncounterAction.saveEncounterHospitalization({encounterId: encounterContext?.id, issueId:form.id})(dispatch)
        } else {
            let res = await PatientAction.createHospitalization(form)(dispatch)
            setDocButton(false);

            id = res?.createdId
            if (hospitalization.length > 0) {
                let hospId = hospital ? hospital?.id : null;
                setHospitalId(hospId);
            }
            if (id) {
                await PatientAction.getHospitalization(patientContext.patientRoleId)(dispatch)
                await EncounterAction.setHospitalizationId({id: id})(dispatch);
                await EncounterAction.saveEncounterHospitalization({encounterId: encounterContext?.id, issueId:id})(dispatch)
            } else {
                addToast({
                    severity: "error",
                    content: "There was an error saving this hospitalization entry."
                })
            }


        }
        return id
    };

    async function saveHospitalization(form) {

        await saveForm(form)
        await hideDialog()
    }

    const saveRequestBeforeAddDocument = async (form) => {
        let hospitalizationId = await saveForm(form)
        await PopupWindowActions.showWindow(PopupWindows.CREATE_DOCUMENT_WINDOW, {
            documentTypeCode: "AH_HOSPITAL_ADMISSION_DOCUMENTS",
            hospitalizationId
        })(dispatch)
    }
    const canEditable = () => {
        if (hospitalizationPermission.isEdit) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <Dialog visible={showWindow[PopupWindows.ADD_HOSPITALIZATIONS_SURGERIES_WINDOW]}
                header={hospitalizationContext?.id ? "Edit Hospitalization" : "Add  Hospitalization"}
                footer={
                    <Container className={"justify-content-end"}>
                        <Button label={hospitalizationContext?.id ? "Update and Exit" : "Save and Exit"}
                                severity={"success"}
                                onClick={handleSubmit(saveHospitalization)}/>
                        <Button onClick={hideDialog}
                                label={"Cancel"}
                                severity={"danger"}/>

                    </Container>}
                onHide={hideDialog}
                style={{width: 550, height: 700}}>
            <div style={{pointerEvents: canEditable() ? 'block' : 'none'}}>
                <PhixForm>
                    <FormRow>
                        <Col sm={6}/>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Clinical Status"} field={"dictClinicalStatusId"}
                                rules={{required: "Clinical Status is required."}}
                                code={Dictionary.PROBLEM_STATUS}
                                defaultItemCode={"FHIR_PROBLEM_ACTIVE"}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormMultiDictionaryDropdown
                                fullObject
                                showCode
                                rules={{required: "At least one diagnosis code is required."}}
                                label={"Diagnosis Codes"} field={"diagnosisCode"}
                                code={Dictionary.ICD10} useIndex/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormMultiDictionaryDropdown
                                fullObject
                                showCode
                                label={"Surgical Procedure"}
                                field={"surgicalCode"}
                                code={Dictionary.AH_CPT}
                                useIndex/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDateSelector showTime
                                              label={"Admission Date"}
                                              field={"admissionDate"}/>
                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDateSelector showTime
                                              label={"Discharge Date"}
                                              field={"dischargeDate"}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Source of Info"} field={"dictSourceOfInfoId"}
                                code={Dictionary.AH_CONDITION_SOURCE}
                                defaultItemCode={"AH_CONDITION_SOURCE"}
                            />
                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Outcome"} field={"outcome"}
                                code={Dictionary.AH_OUTCOMES}
                                defaultItemCode={"AH_OUTCOMES"}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow style={{marginTop: 10}}>
                        <FormColumn col={12}>
                            <FormTextInput label={"Notes"} field={"note"}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <Column col={2}>
                            <Button
                                text
                                onClick={handleSubmit(saveRequestBeforeAddDocument)}
                                className="add-dictionary-button"
                                icon={"pi pi-plus"}
                            >

                            </Button>
                            <br/>
                            <span style={{color: "red"}}>{errMessage}</span>
                        </Column>
                        <Column col={10}>
                            <Grid
                                style={{width: "100%"}}
                                data={documentList}>
                                <GridColumn field="displayName" title="Documents" filterable={false}/>
                            </Grid>
                        </Column>
                    </FormRow>
                </PhixForm>
            </div>
        </Dialog>)
};

AddHospitalizationsWindow.propTypes = {
    showWindow: PropTypes.object,
    DictionaryActions: PropTypes.object,
    DictionaryTypeAction: PropTypes.object,
    PopupActions: PropTypes.object,
    PatientActions: PropTypes.object,
    createPatientContext: PropTypes.object,
    patient: PropTypes.object,
};


export default AddHospitalizationsWindow
