import {bindActionCreators} from "redux";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {connect} from "react-redux";
import {Window} from "@progress/kendo-react-dialogs";
import * as React from "react";
import {Button} from "primereact/button";
import {PatientAction} from "../../action/PatientAction";

const ShowDocumentWindow = (props) => {
    const [fileReferenceId, setFileReferenceId] = React.useState(props.popupState[PopupWindows.SHOW_DOCUMENT_WINDOW]?.documentFileId)
    const [document, setDocument] = React.useState("")

    const hideDialog = () => {
        props.PopupActions.hideWindow(PopupWindows.SHOW_DOCUMENT_WINDOW)
    }
    React.useEffect(() => {
        if (props.popupState[PopupWindows.SHOW_DOCUMENT_WINDOW]?.documentFileId) {
            let documentFileId = props.popupState[PopupWindows.SHOW_DOCUMENT_WINDOW]?.documentFileId
            setFileReferenceId(documentFileId ? documentFileId : '');
        }
    }, [props.popupState]);

    React.useEffect(() => {
        if (props.popupState[PopupWindows.SHOW_DOCUMENT_WINDOW]?.documentFileId) {
            const payload = {
                fileReferenceId: props.popupState[PopupWindows.SHOW_DOCUMENT_WINDOW]?.documentFileId
            }
            props.PatientActions.clinicalDocumentDownload(payload)
        }
    }, [fileReferenceId])

    React.useEffect(() => {
        const fileMetaData = props?.clinicalDocumentDownload?.fileMetaData;
        const meta = `data:${fileMetaData};base64,`;
        if (fileMetaData && props?.clinicalDocumentDownload?.fileBase64) {
            setDocument(meta + props?.clinicalDocumentDownload?.fileBase64);
        } else {
            setDocument("");
        }
    }, [props.clinicalDocumentDownload])

    return (
        props.showWindow[PopupWindows.SHOW_DOCUMENT_WINDOW] ?
            <Window draggable={true}
                modal={true}
                onClose={hideDialog}
                initialWidth={950}
                initialHeight={500}
                resizable={true}
                title={"Documents"}
                closeButton={() => <Button fillMode={"flat"} onClick={hideDialog} icon={"close"} />}>
                {props?.clinicalDocumentDownload?.fileBase64 === "" ? (
                    <iframe
                        id="document"
                        title="View document"
                        width="100%"
                        height="auto"
                        srcdoc="Unable to preview"
                    />
                ) : (
                    <iframe
                        id="document"
                        title="View document"
                        width="100%"
                        height="100%"
                        src={document}
                    />
                )}
            </Window> : null
    );
}

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        patientContext: state.patient.patientContext,
        popupState: state.popupWindow.state,
        clinicalDocumentDownload: state.patient.clinicalDocumentDownload,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowDocumentWindow)