import {Checkbox} from "primereact/checkbox"
import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {connect} from "react-redux";
import {Button} from "primereact/button"
import {bindActionCreators} from "redux";
import {PatientAction} from "../../../../action/PatientAction";
import {PopupWindowActions, PopupWindows} from "../../../../action/PopupWindowAction";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import ShowForPermissionComponent from "../../../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../../../config/GlobalConfig";
import {Col, Container, Row} from "../../../../layout/GridLayout";
import {DateGridCellWithFormat, DateTimeGridCellWithFormat} from "../../../../util/DateTools";

const MedicalProblemsComponent = (props) => {
    const {PatientActions, patientContext} = props

    const [active, setActive] = React.useState(true)

    const initialSort = [
        {
            field: "condition",
            dir: "asc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);
    const [filter, setFilter] = React.useState([]);
    React.useEffect(() => {
        if (patientContext?.patientRoleId) PatientActions.getMedicalProblems(patientContext?.patientRoleId)
    }, [patientContext])
    React.useEffect(() => {
        // console.log(filter)
    }, [filter])
    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });

    const [filterState, setFilterState] = React.useState(false)

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const AddMedicalProblem = () => {
        props.PopupActions.showWindow(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW);
    };

    const filterActive = (data) => {
        if (!active) {
            return data
        } else {
            return data.filter(r => {
                if (r.dictProblemStatusCode !== "FHIR_PROBLEM_INACTIVE") return r
            })
        }
    }

    return (
        <Container style={{height: "100%", width: "100%"}}>
            <Col col={12} style={{height: "50px"}}>
                <Row gutter style={{height: "50px"}} className={"justify-content-center"}>
                    <Col col={3} className={"flex-grow-0"}>
                        <Button togglable={true}
                                text
                                tooltip={"Toggle filtering"}
                                size={"small"}
                                onClick={() => setFilterState(!filterState)}
                                icon={(filterState) ? "pi pi-filter-fill" : "pi pi-filter"}/>

                    </Col>
                    <Col className={"flex-grow-1"}/>
                    <Col col={2} className="flex align-items-center flex-grow-0">
                        <Checkbox inputId="viewAll"
                                  onChange={(e) => setActive(!active)}
                                  checked={active}/>
                        <label style={{fontSize: 14}} htmlFor="viewAll" className="ml-2">Active only</label>
                    </Col>
                    <Col col={1}>
                        <Row>
                            <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_MEDICAL_PROBLEM]}>
                                <Col className={"flex-grow-0"}>
                                    <Button text="Image" onClick={AddMedicalProblem}
                                            tooltip={"Add medical problem"}
                                            className="add-dictionary-button"
                                            size={"small"}
                                            icon={"pi pi-plus"}/>
                                </Col>
                            </ShowForPermissionComponent>
                        </Row>
                    </Col>

                </Row>
            </Col>
            <Row style={{width: "100%", height: "calc(100% - 50px)", padding: 0}}>
                    <Grid
                        style={{height: "100%",width: "100%"}}
                        data={filterBy(orderBy(filterActive(props.medicalProblems), sort), filter)}
                        pageSize={dataState.limit}
                        total={props.totalCount}
                        filterable={filterState}
                        filter={filter}
                        onFilterChange={(e) => setFilter(e.filter)}
                        sortable={true}
                        sort={sort}
                        resizable
                        onRowClick={(item) => {
                            props.PopupActions.setWindowState(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW, {problemContext: item.dataItem})
                            props.PopupActions.showWindow(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW)
                        }}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}

                        onDataStateChange={dataStateChange}
                    >
                        <GridColumn field="description" title="Condition"/>
                        <GridColumn field="codes" title="Diagnostic Code" width={150}/>
                        <GridColumn field="dictProblemCategoryDisplayName" title="Category" width={110}/>
                        <GridColumn field="startDate" title="Onset" width={110} cell={DateGridCellWithFormat("MM/dd/yyyy")}/>
                        <GridColumn field="dictProblemStatusDisplayName" title="Status" width={90}/>
                        <GridColumn field="note" title="Notes"/>
                    </Grid>
            </Row>
        </Container>
    )
};

function mapStateToProps(state) {
    return {
        patientContext: state.patient.patientContext,
        medicalProblems: state.patient.medicalProblems,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),

        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalProblemsComponent);
