import {ActionTypes} from "../action/NotificationAction";


const initialState = {
    notifications: {data: [], totalCount: null},
    notificationContext: {}

}

export const notificationReducer = (state = initialState, action) =>
{
    switch (action.type) {
        case ActionTypes.NOTIFICATION_RECEIVE:
            return {...state, notifications: {data: action.payload.payload, totalCount: action.payload.totalCount} }
        case ActionTypes.NOTIFICATION_SET_CONTEXT:
            return {...state, notificationContext: action.payload}
        case ActionTypes.NOTIFICATION_CLEAR_CONTEXT:
            return {...state, notificationContext: {}}
        case ActionTypes.NOTIFICATION_DISMISS:
            // inactivates a notification
            return state;
        default:
            return state;
    }

}

