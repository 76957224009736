import {DrawerContent, TileLayout} from "@progress/kendo-react-layout";
import React from "react";
import TenantConfigurationComponent from "../TenantConfigurationComponent";

const userListTile = [
    {
        defaultPosition: {
            col: 1,
            colSpan: 8,
            rowSpan: 6,
        },
        item: <TenantConfigurationComponent/>
    }
];

export default function TenantConfigurationPage(props) {
    return <>
        <DrawerContent>
            <TileLayout style={{
                width: "96%",
                height: "calc(100vh - 90px)"
            }} ignoreDrag={(e) => {
                return !(e.target.classList.contains("k-card-title"));
            }} items={userListTile}/>
        </DrawerContent>
    </>
};