import {ActionTypes} from "../action/DictionaryDispatchAction";


const initialState = {
    dictionaryList: [],
    countryList:[],
    stateList:[],
    dictionaryContext: null,
    dictionaries: {},
    pagination: {},
    relationshipContext: null
}

export const dictionaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DICTIONARY_LIST_SUCCESS:
            return {...state, dictionaryList: action.payload.payload, totalCount: action.payload.totalCount};
        case ActionTypes.DICTIONARY_LIST_ERROR:
            return {...state, dictionaryListError: action.payload};

        case ActionTypes.SET_DICTIONARY_CONTEXT:
            return {...state, dictionaryContext: action.payload}

        case ActionTypes.CLEAR_DICTIONARY_CONTEXT:
            return {...state, dictionaryContext: null}
        case ActionTypes.GET_RELATIONSHIP_TO_DICTIONARY:
            return {...state, relationshipContext: action.payload.payload}
        case ActionTypes.CLEAR_RELATIONSHIP_TO_DICTIONARY:
            return {...state, relationshipContext: null}
        case ActionTypes.GET_DICTIONARY_BY_CODE:
            return {...state, dictionaries: {...state.dictionaries, [action.code]: action.payload.payload}, pagination: {...state.pagination, [action.code]: {totalCount: action.payload.totalCount}} }
        default:
            return state
    }

}

