import * as React from 'react';
import {SchedulerFormEditor} from '@progress/kendo-react-scheduler';
import SchedulerCustomForm from './SchedulerCustomForm';


const CustomFormEditor = props => {

    return <SchedulerFormEditor
        {...props}
        titleLabel={() => <div/>}
        // titleEditor={customForm}
        titleEditor={SchedulerCustomForm}
        startTimezoneCheckedEditor={() => <></>}
        startTimezoneCheckedLabel={() => <></>}
    />
};
export default CustomFormEditor;
