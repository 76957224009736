import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PatientAction} from "../../action/PatientAction";
import TileHeader from "../../layout/TileHeader";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {TileRow} from "../../layout/TileRow";
import moment from "moment";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {ObservationCodes, PhixPermissions} from "../../config/GlobalConfig";
import {AeriosNoDataLoadingIndicator} from "../AeriosLoadingIndicator";
import {CalculateCohortObservationThresholds, ThresholdChart, ThresholdList} from "../../util/ObservationThresholds";


const RestingHeartRateCareTeamComponent = (props) => {
    const dispatch = useDispatch()
    const latestCohortObservation = useSelector(state => state.patient.latestCohortObservation)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const [thresholds, setThresholds] = useState([])

    const endDate = moment().endOf('day').subtract(7, 'days').format('YYYY-MM-DD');
    useEffect(() => {
        const fetchData = async () => {
            await PatientAction.getPatientCohortLatestObservationWithThreshold(ObservationCodes.HEART_RATE.RESTING_HEART_RATE, endDate, "RestingHeartRateCareTeamComponent")(dispatch)
        }
        fetchData().then(() => setLoading(false))
    }, [])


    useEffect(() => {
        if (latestCohortObservation?.RestingHeartRateCareTeamComponent?.observations) {
            setData(latestCohortObservation.RestingHeartRateCareTeamComponent.observations)
        }
    }, [latestCohortObservation?.RestingHeartRateCareTeamComponent]);


    useEffect(() => {
        if (data?.length) {
            let c = CalculateCohortObservationThresholds(data, latestCohortObservation?.RestingHeartRateCareTeamComponent?.thresholds)
            setThresholds(c)
        }
    }, [data])


    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                <TileContainer>
                    <TileHeader className="boxHeader ah-draggable-header"
                                title={"Resting Heart Rate"} image={"../images/hospitalHeart.png"}>
                    </TileHeader>
                    <TileBody fluid style={{padding: 0, backgroundColor: '#FFF'}}
                              differentStyleTitle={"updatedTitleWrapperGrayBg"}>
                        <AeriosNoDataLoadingIndicator loading={loading} data={thresholds}>
                            <TileRow>
                                <ThresholdList thresholds={thresholds}/>
                                <ThresholdChart thresholds={thresholds}/>
                            </TileRow>
                        </AeriosNoDataLoadingIndicator>
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}

export default RestingHeartRateCareTeamComponent
