import {connect} from "react-redux";
import {DrawerContent, TileLayout} from "@progress/kendo-react-layout";
import AdminInsuranceComponent from "../AdminInsuranceComponent";
import React from "react";

const adminInsuranceTile = [
    {
        defaultPosition: {
            col:1,
            colSpan: 4,
            rowSpan: 6,
        },
        item: <AdminInsuranceComponent/>,
    }
];


const styles = {
    layout:
        {
            width: "95%",
            height: "calc(100vh - 90px)"
        }
}

function AdminInsurancePage(props) {
    return <>
        <DrawerContent style={{width: "100%"}}>
            <TileLayout
                style={styles.layout}
                ignoreDrag={(e) => {
                    return !(e.target.classList.contains("k-card-title"));
                }} items={adminInsuranceTile}>
            </TileLayout>
        </DrawerContent>
    </>
}


export default AdminInsurancePage