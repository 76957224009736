import * as React from "react";
import {useEffect} from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {connect, useSelector} from "react-redux";
import {Button} from "primereact/button";
import {Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {DictionaryTypeAction} from "../../action/DictionaryTypeAction";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Checkbox} from "@progress/kendo-react-inputs";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {FaNetworkWired} from "react-icons/fa";
import {BiArrowToRight} from "react-icons/bi";
import {BsArrowsCollapse} from "react-icons/bs";
import {Col, Container} from '../../layout/GridLayout';
import {PhixPermissions} from "../../config/GlobalConfig";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";

const initialSort = [
    {
        field: "code",
        dir: "asc",
    },
];

const DictionaryListComponent = (props) => {
    const {DictionaryTypeActions, dictionaries, DictionaryActions, dictionaryTypes} = props
    const [filterState, setFilterState] = React.useState(false)
    const [dictionaryTypeData, setDictionaryTypeData] = React.useState([])
    const [displayDom, setDisplayDom] = React.useState(true);
    const [perMessage, setPerMessage] = React.useState('');
    const [data, setData] = React.useState([])
    const [sort, setSort] = React.useState(initialSort);
    const permissions = useSelector(state => state.auth.session.permissions)

    const [typeState, setTypeState] = React.useState({
        selectedDictionaryType: {
            code: null,
            id: null,
        },
    });


    const styles = {
        grid: {
            height: "calc(100vh - 310px)"
        }
    }

    const [filterDictionaryData, setFilterDictionaryData] = React.useState({
        logic: "and", filters: []

    })

    const filterChangeState = (filter) => {
        setFilterDictionaryData(filter)
    }


    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };
    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });


    const handleTypeChange = (event) => {
        setTypeState({
            selectedDictionaryType: event.target.value,
        });
    };

    const CommandCell = (item) => (
        <td className="k-command-cell flex flex-align-items-center">
            <Button
                icon={"pi pi-pencil"}
                text
                disabled={!permissions.includes(PhixPermissions.AH_ADMIN_EDIT_DICTIONARIES)}
                onClick={() => {
                    props.PopupActions.setWindowState(PopupWindows.ADD_CODE_WINDOW, {
                        dictCodeContext: item.dataItem,
                        parentSelectedDictionaryType: typeState.selectedDictionaryType
                    })
                    props.PopupActions.showWindow(PopupWindows.ADD_CODE_WINDOW)
                }}/>

        </td>
    );

    const filterData = (filter) => {
        return filterBy(dictionaryTypes.slice(), filter);
    };

    const filterChange = (event) => {
        setDictionaryTypeData(filterData(event.filter));
    };

    React.useEffect(() => {
        setDictionaryTypeData(dictionaryTypes)
    }, [dictionaryTypes])

    useEffect(() => {
        DictionaryTypeActions.getDictionaryTypeList(1000, 0)
    }, [])

    React.useEffect(() => {
            if (typeState.selectedDictionaryType?.code) {
                let filters = {
                    "dictionaryTypeCode": typeState.selectedDictionaryType.code,
                    "active": true
                }
                //if (searchState.length > 0) filters.searchAll = searchState
                if (filterDictionaryData?.filters) {
                    if (filterDictionaryData.filters.length > 0) {
                        filterDictionaryData.filters.forEach(x => {
                            filters[x.field] = x.value
                        })
                    }
                }

                const dictionaryListPer = DictionaryActions.getDictionaryListFilter(typeState.selectedDictionaryType.code, dataState.take, dataState.skip, sort, filters)
                dictionaryListPer
                    .then(val => {
                        if (val?.code === 'E-189') {
                            setDisplayDom(false)
                            setPerMessage(val.message)
                        }
                    })
            }
        }, [dataState, sort, filterDictionaryData]
    )

    const dictionaryActive = (props) => {
        return <td><Checkbox id={props.dataItem.active} value={props.dataItem.active}/></td>
    }

    const AddCodeWindow = () => {
        props.PopupActions.setWindowState(PopupWindows.ADD_CODE_WINDOW, {selectedDictionaryType: typeState.selectedDictionaryType})
        props.PopupActions.showWindow(PopupWindows.ADD_CODE_WINDOW);
    };

    const AddDictionaryWindow = () => {
        props.PopupActions.setWindowState(PopupWindows.ADD_DICTIONARY_WINDOW)
        props.PopupActions.showWindow(PopupWindows.ADD_DICTIONARY_WINDOW);
    }


    React.useEffect(() => {
        if (typeState.selectedDictionaryType?.id)
            DictionaryActions.getDictionaryList(typeState.selectedDictionaryType.id, dataState.take, dataState.skip)
    }, [dataState, typeState])

    React.useEffect(() => {
        if (typeState.selectedDictionaryType.id) {
            setData(dictionaries)
        }
    }, [dictionaries])

    const RelationshipInnerCellIcon = (p) => {
        if (p.relationshipDisplayName === "Parent of") {
            return <><FaNetworkWired style={{marginLeft: "2%", height: '5%', width: '8%'}}/></>;
        } else if (p.relationshipDisplayName === "Is About") {
            return <BiArrowToRight style={{marginLeft: "2%", height: '5%', width: '8%'}}/>;
        } else {
            return <BsArrowsCollapse style={{marginLeft: "2%", height: '5%', width: '8%'}}/>;
        }
    }

    const RelationshipInnerCell = (p) => {
        const dc = p.dc;
        if (dc.length > 0) {
            return <div className="custom-tooltip">
            <span>
            {
                dc[0]?.toDictionTypeDisplayName
            }
                <span style={{marginRight: "1%"}}>:</span>
                {
                    dc[0]?.toDisplayName
                }
                {
                    RelationshipInnerCellIcon(dc[0])
                }
            </span>
                <span class="tooltiptext">
            <div>
            {
                dc.map(v => <><span>{v?.toDictionTypeDisplayName}</span><span> :</span>
                    <span> {v?.toDisplayName}</span>{RelationshipInnerCellIcon(v)}<br></br></>)
            }
            </div>
            </span>
            </div>
        } else {
            return <></>
        }
    }

    const relationshipCell = (p) => {
        const dataItem = p.dataItem;
        const relationshipToDictionary = dataItem?.relationshipToDictionary ? dataItem.relationshipToDictionary : [];
        if (dataItem) {
            return <td><RelationshipInnerCell dc={relationshipToDictionary}/>
            </td>
        } else {
            return <td></td>;
        }

    }

    return (
        <React.Fragment>
            <ShowForPermissionComponent component permissions={[PhixPermissions.AH_ADMIN_VIEW_DICTIONARIES]}>
                <div style={{margin: 0, width: "100%"}}>
                    <div style={{margin: 0, width: "100%"}}>
                        <Toolbar>
                            <label>Manage Dictionaries:</label>
                            <DropDownList
                                filterable={true}
                                onFilterChange={filterChange}
                                dataItemKey="id"
                                textField="displayName"
                                style={{width: 400}}
                                value={typeState.selectedDictionaryType}
                                onChange={handleTypeChange}
                                data={dictionaryTypeData}
                                defaultValue="Select Dictionary Type"/>
                            <ToolbarItem>
                                <Button togglable={true} icon="filter" text="Filter Codes"
                                        onClick={() => setFilterState(!filterState)}>Filter</Button>
                            </ToolbarItem>
                            <ToolbarSpacer/>
                            <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_DICTIONARIES]}>
                                <ToolbarItem>
                                    <Button className="add-dictionary-button" icon="add" text="Image"
                                            onClick={AddDictionaryWindow}>Create
                                        Dictionary</Button>
                                </ToolbarItem>
                            </ShowForPermissionComponent>
                            <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_DICTIONARIES]}>
                                <ToolbarItem>
                                    <Button className="add-dictionary-button" icon="add" text="Image"
                                            disabled={!typeState.selectedDictionaryType.id}
                                            onClick={AddCodeWindow}>Create
                                        Code</Button>
                                </ToolbarItem>
                            </ShowForPermissionComponent>
                        </Toolbar>
                    </div>
                    <Grid
                        {...dataState}
                        style={styles.grid}
                        data={orderBy(data, sort)}
                        pageSize={dataState.take}
                        resizable={true}
                        // style={{height: "calc(100vh - 100px)"}}
                        total={props.totalCount}
                        filterable={filterState}
                        sortable={true}
                        skip={dataState.skip}
                        pageable={{
                            buttonCount: 10,
                            info: true,
                            type: "numeric",
                            pageSizes: [50, 100, 150],
                            previousNext: true,
                        }}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                        onFilterChange={(e) => filterChangeState(e.filter)}
                        onDataStateChange={dataStateChange}
                        filter={filterDictionaryData}
                    >
                        <GridColumn field="code" value={filterDictionaryData} title="Code"/>
                        <GridColumn field="description" value={filterDictionaryData} title="Description"/>
                        <GridColumn field="displayName" value={filterDictionaryData} title="Display Name"/>
                        <GridColumn field="dictTypeCode" value={filterDictionaryData} cell={relationshipCell}
                                    title="Related"/>
                        <GridColumn width={80} field="active" title="Active" cell={dictionaryActive}/>
                        {/* <GridColumn width={70} field="dictTypeStartDate" title="Action" cell={dictionaryEdit}/> */}

                        <GridColumn width={80} field="Action" title="Action" cell={CommandCell}/>
                    </Grid>
                    <br></br>
                    <Container style={{height: 20}}>
                        <Col col={1}><span><b>Key:</b></span></Col>
                        <Col col={1} style={{marginLeft: "-4%"}}><FaNetworkWired
                            style={{height: "66%", width: "20%"}}/></Col>
                        <Col col={1} style={{marginLeft: "-6%"}}><span>Parent of</span></Col>
                        <Col col={1}><BiArrowToRight style={{height: "90%", width: "25%"}}/></Col>
                        <Col col={2}
                             style={{
                                 marginLeft: "-6%",
                                 marginRight: "-1%"
                             }}><span>About (characteristics of)</span></Col>
                        <Col col={1}><BsArrowsCollapse style={{height: "90%", width: "25%"}}/></Col>
                        <Col col={2} style={{marginLeft: "-6%"}}><span>Is a (member of a group)</span></Col>
                    </Container>
                </div>
            </ShowForPermissionComponent>
        </React.Fragment>
    );
};


DictionaryListComponent.propTypes =
    {
        DictionaryActions: PropTypes.object,
    }


function mapStateToProps(state) {
    return {
        dictionaryTypes: state.dictionaryType.dictionaryTypeList,
        dictionaries: state.dictionary.dictionaryList,
        totalCount: state.dictionary.totalCount,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryListComponent)
