import * as React from "react";
import TileHeader from "../layout/TileHeader";
import GlobalStyles from "../config/GlobalStyles";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Button} from "primereact/button";
import {Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {PopupWindowActions as PopupActions, PopupWindows} from "../action/PopupWindowAction";
import {useDispatch} from "react-redux";

const CohortAnalysisComponent = (props) => {
    const dispatch = useDispatch()
    let mockData = [
        {
            displayName: "New immunization trial",
            description: "A new covid immunization for patients with co-morbidities",
            dictCompanyDisplayName: "Pfizer",
            candidates: 12,
            contacted: 2
        },
        {
            displayName: "New weight loss trial",
            description: "An experimental drug for weight loss",
            dictCompanyDisplayName: "Moderna",
            candidates: 24,
            contacted: 0
        },
        {
            displayName: "Diabetic Insulin",
            description: "A new insulin to treat resistant patients",
            dictCompanyDisplayName: "Pfizer",
            candidates: 5,
            contacted: 3
        },
        {
            displayName: "Migraine Suppressant",
            description: "Experimental medication to help patients with chronic migraines.",
            dictCompanyDisplayName: "Moderna",
            candidates: 40,
            contacted: 17
        },
    ]
    return (
        <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
            <TileHeader title="Cohort Analysis" style={GlobalStyles.tileHeaderBlueWithBlack}/>
            <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
                <Toolbar>
                    <ToolbarSpacer/>
                    <ToolbarItem>
                        <Button className="rounded-button" icon="add" text="Image"
                                onClick={(e) => PopupActions.showWindow(PopupWindows.CREATE_QUERY_PANEL_WINDOW)(dispatch)}>
                            New Query Panel
                        </Button>
                    </ToolbarItem>

                </Toolbar>
                <Grid
                    style={{width: "100%", height: "100%"}}
                    sortable={true}
                    data={mockData}
                    pageable={{
                        buttonCount: 10,
                        info: true,
                        type: "numeric",
                        pageSizes: [50, 100, 150],
                        previousNext: true,
                    }}>
                    <GridColumn field="displayName" title="Panel Name"/>
                    <GridColumn field="description" title="Description"/>
                    <GridColumn field="dictCompanyDisplayName" title="Company"/>
                    <GridColumn field="candidates" title="Candidate Patients"/>
                    <GridColumn field="contacted" title="Patients Contacted"/>

                </Grid>
            </div>


        </div>
    );
};


export default CohortAnalysisComponent
