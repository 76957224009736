import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {useDispatch, useSelector} from "react-redux";
import * as React from "react";
import PatientCareTeamListComponent from "../PatientCareTeamListComponent";
import {Dialog} from "primereact/dialog";


const CareTeamWindow = (props) => {
    const dispatch = useDispatch()
    const showWindow = useSelector(state => state.popupWindow.showWindow)
    const hideDialog = () => {
        PopupWindowActions.hideWindow(PopupWindows.CARE_TEAM_MENU)(dispatch)
    }
    return (
        <Dialog header={"Care Team"}
                style={{width: 800}}
                maximizable
                visible={showWindow[PopupWindows.CARE_TEAM_MENU]}
                onHide={hideDialog}>
            <PatientCareTeamListComponent/>
        </Dialog>
    );
}

export default CareTeamWindow