import React, {useEffect} from "react";
import {Label} from "@progress/kendo-react-labels";
import {filterBy} from "@progress/kendo-data-query";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {PatientAction} from "../../action/PatientAction";
import {Colors} from "../../config/GlobalConfig";
import _, {isObject} from "lodash";

const MedicalProblemDropDownList = (props) => {
    const {
        patientContext,
        PatientActions,
        medicalProblems,
        value,
        type,
        labelName = "Select Condition",
        defaultValue,
        filterCarePlan
    } = props


    const [data, setData] = React.useState(medicalProblems);
    const [loading, setLoading] = React.useState(false);
    const [internalValue, setInternalValue] = React.useState(defaultValue ? defaultValue : {})

    const handleChange = (e) => {
        setInternalValue(e.target.value)
        if (props.onChange) {
            props.onChange(e)
        }
    }

    const filterData = (filter) => {
        const data = medicalProblems.slice();
        return filterBy(data, filter);
    };

    useEffect(() => {
        if (isObject(value)) {
            if (internalValue !== value)
                setInternalValue(value)
            return;
        }
        if (!internalValue?.id && value) {
            let d = data ? data.find((c) => c.id === value) : null
            //
            // if (!d) {
            //     setFilter({id: value, value: null})
            // }
            if (d?.id !== value?.id) {
                setInternalValue(d)
            }
        }


    }, [value, data])

    //

    function cleanProblems(problems) {
        return problems.map((p) => {
            p.displayName = p?.diagnosisCode ? p.diagnosisCode?.map(v=> v.displayName).join(", ") : "No Description"
            return p
        })
    }

    const filterChange = (event) => {
        setData(filterData(event.filter));
    };
    useEffect(() => {
        PatientActions.getMedicalProblems(patientContext.patientRoleId)
    }, [])


    useEffect(() => {
        if (filterCarePlan) {
            setData(cleanProblems(medicalProblems.filter(d => d.carePlan === false)))
            setInternalValue(internalValue)
        } else {
            setData(cleanProblems(medicalProblems))
            setInternalValue(internalValue)
        }

    }, [medicalProblems])


    const dropdownRef = React.createRef()
    const onSetValue = (value) => {
        if (props.onChange) {
            const data = {
                target: dropdownRef.current,
                value: value,
            }
            props.onChange(data);
        }
        setInternalValue(value)
    };

    const itemRender = (li, itemProps) => {
        const carePlan = itemProps.dataItem.carePlan
        const dataItem = itemProps.dataItem

        let color = ""
        let text = ""
        if (itemProps.dataItem.dictProblemStatusDisplayName === "Active") {
            color = Colors.GREEN
            text = dataItem.displayName + " - " + dataItem.dictProblemStatusDisplayName
        } else {
            color = Colors.RED
            text = dataItem.displayName + " - " + dataItem.dictProblemStatusDisplayName
        }

        if (carePlan) {
            color = Colors.BLUE
            text = dataItem.displayName + "- Edit Existing"
        }


        const itemChildren = (
            <span
                style={{
                    color: color,
                }}
            >
        {text}

      </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };

    const listNoDataRender = (element) => {
        const noData = (
            <h4
                style={{
                    fontSize: "1em",
                }}
            >
        <span
            className="k-icon k-i-warning"
            style={{
                fontSize: "2.5em",
            }}
        />
                <br/>
                <br/>
                No conditions found. Please add a condition.
            </h4>
        );
        return React.cloneElement(element, {...element.props}, noData);
    };
    const Element = type === "checkbox" ? ComboBox : DropDownList;
    return (
        <>
            <Element
                ref={dropdownRef}
                filterable={true}
                onFilterChange={filterChange}
                value={internalValue}
                groupField={"dictProblemStatusDisplayName"}
                data={data}
                placeholder="Select Condition"
                dataItemKey={"id"}
                textField="displayName"
                listNoDataRender={listNoDataRender}
                itemRender={itemRender}
                loading={loading}
                {...props}
                onChange={handleChange}
            />
        </>
    );
};
MedicalProblemDropDownList.propTypes = {
    PatientActions: PropTypes.object,
    medicalProblems: PropTypes.array,
    idField: PropTypes.string.isRequired,
    dataItem: PropTypes.object.isRequired,

}

function mapStateToProps(state) {
    return {
        patientContext: state.patient.patientContext,
        medicalProblems: state.patient.medicalProblems,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalProblemDropDownList)