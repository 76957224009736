import * as React from "react";
import {Grid, GridColumn as Column,} from "@progress/kendo-react-grid";
import {getter} from "@progress/kendo-react-common";
import {Input} from "@progress/kendo-react-inputs";
import {Col, Container, Row} from "../../../layout/GridLayout";
import {Button} from "primereact/button"
import {useDispatch, useSelector} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../../action/PopupWindowAction";
import {PatientAction} from "../../../action/PatientAction";
import {EncounterAction} from "../../../action/EncounterAction";
import { Checkbox } from "@progress/kendo-react-inputs";
import { RadioButton } from "primereact/radiobutton";

export function ConditionsAssociatedEncounter(props) {
  const SELECTED_FIELD = "selected";
  const dispatch = useDispatch()
  const [searchState, setSearchState] = React.useState("")
  const encounterContext = useSelector(state => state.encounters.encounterContext)
  const serviceLines = useSelector(state => state.encounters.encounterCoding.serviceLines)
  const medicalProblems = useSelector(state => state.patient.medicalProblems)
  const medications = useSelector(state => state.patient.medications)
  const allergies = useSelector(state => state.patient.allergies)
  const hospitalization = useSelector(state => state.patient.hospitalization)
  const immunizations = useSelector(state => state.patient.immunizationData)
  const medicalProblemsSel = useSelector(state => state.encounters.medicalProblems)
  const medicationsSel = useSelector(state => state.encounters.medications)
  const allergiesSel = useSelector(state => state.encounters.allergies)
  const hospitalizationSel = useSelector(state => state.encounters.hospitalizations)
  const immunizationsSel = useSelector(state => state.encounters.immunizations)
  const [issuesType, setIssuesType] = React.useState("medicalProblem");
  const [gridData, setGridData] = React.useState([]);

  const radioData = [
      {
          label: "Medical Problems",
          value: "medicalProblem",
      },
      {
          label: "Medications",
          value: "medications",
      },
      {
          label: "Allergies",
          value: "allergyProblem",
      },
      {
          label: "Hospitalization",
          value: "hospitalization",
      },
      {
          label: "Immunizations",
          value: "immunizations",
      },
  ];

  const onSelectionChange = (p, event) => {
      if (encounterContext && encounterContext?.id) {
        saveProblem(p.dataItem)
      } else {
        var selectedStates = {...props?.selectedState}
        selectedStates[issuesType][p.dataItem?.id] = event.target.value;
        props?.setSelectedState(selectedStates);
        handleProblemChange();
      }
  }

  const handleProblemChange = () => {
      switch (issuesType) {
          case "medications":
              const medicationData = medications?.filter(v => medicationsSel?.find(m => m.medicationId === v.medicationId) || v?.dictClinicalStatusDisplayName === "Active")?.map(v => ({
                  ...v,
                  "selected": encounterContext?.id ? (medicationsSel?.find(m => m.medicationId === v.medicationId) ? true : false) : props?.selectedState[issuesType][v.medicationId],
                  "code": v.diagnosisCode ? v.diagnosisCode.map(d => d.code).join(", ") : "",
                  "name": v.diagnosisCode && v.diagnosisCode.length > 0 ? v.diagnosisCode.map(d => d.displayName).join(", ") : v.displayName,
                  "type": "medications",
                  "id": v.medicationId
              }));
              setGridData(medicationData);
              break;
          case "allergyProblem":
              const allergiesData = allergies?.filter(v => allergiesSel?.find(m => m.id === v.id) || v?.dictClinicalStatusDisplayName !== "Inactive")?.map(v => ({
                  ...v,
                  "selected": encounterContext?.id ? (allergiesSel?.find(m => m.id === v.id) ? true : false) : props?.selectedState[issuesType][v.id],
                  "code": v.diagnosisCode ? v.diagnosisCode.map(d => d.code).join(", ") : "",
                  "name": v.diagnosisCode && v.diagnosisCode.length > 0 ? v.diagnosisCode.map(d => d.displayName).join(", ") : v.displayName,
                  "type": "allergyProblem",
                  "id": v.id
              }));
              setGridData(allergiesData);
              break;
          case "hospitalization":
              const hospitalizationData = hospitalization?.filter(v => hospitalizationSel?.find(m => m.id === v.id) || v?.dictClinicalStatusDisplayName !== "Inactive")?.map(v => ({
                  ...v,
                  "selected": encounterContext?.id ? (hospitalizationSel?.find(m => m.id === v.id) ? true : false) : props?.selectedState[issuesType][v.id],
                  "code": v.diagnosisCode ? v.diagnosisCode.map(d => d.code).join(", ") : "",
                  "name": v.diagnosisCode && v.diagnosisCode.length > 0 ? v.diagnosisCode.map(d => d.displayName).join(", ") : v.description,
                  "type": "hospitalization",
                  "id": v.id
              }));
              setGridData(hospitalizationData);
              break;
          case "immunizations":
              const immunizationsData = immunizations?.map(v => ({
                  ...v,
                  "selected": encounterContext?.id ? (immunizationsSel?.find(m => m.id === v.id) ? true : false) : props?.selectedState[issuesType][v.id] ,
                  "code": v.dictVaccineCvxCode,
                  "name": v.dictVaccineCvxDisplayName,
                  "type": "immunizations",
                  "id": v.id
              }));
              setGridData(immunizationsData);
              break;
          case "medicalProblem":
              const serviceLinesCodes = serviceLines?.flatMap(d => d?.diagnoses.flatMap(v => {
                  if (typeof v === "object") {
                      return v.selected ? [v.code] : [];
                  } else {
                      return [v]
                  }
              }));
              const medicalProblemsData = medicalProblems?.filter(v => medicalProblemsSel?.find(m => m.id === v.id) || v?.dictProblemStatusDisplayName !== "Inactive")?.map(v => {
                  const medicalDiagnoses = v.diagnosisCode.map(d => d.code).join(", ")
                  return {
                      ...v,
                      "selected": encounterContext?.id ? (medicalProblemsSel?.find(m => m.id === v.id) ? true : false) || (serviceLinesCodes ? serviceLinesCodes.indexOf(medicalDiagnoses) > -1 : false) : props?.selectedState[issuesType][v.id],
                      "code": v.diagnosisCode ? v.diagnosisCode.map(d => d.code).join(", ") : "",
                      "name": v.diagnosisCode && v.diagnosisCode.length > 0 ? v.diagnosisCode.map(d => d.displayName).join(", ") : v.description,
                      "type": "medicalProblem",
                      "id": v.id
                    }
              });
              setGridData(medicalProblemsData);
              break;
      }
  };

  const saveProblem = (data) => {
      switch (issuesType) {
          case "medications":
              EncounterAction.saveEncounterMedications({encounterId: encounterContext.id, issueId: data.id})(dispatch)
              break;
          case "allergyProblem":
             EncounterAction.saveEncounterAllergy({encounterId: encounterContext.id, issueId: data.id})(dispatch)
              break;
          case "hospitalization":
              EncounterAction.saveEncounterHospitalization({encounterId: encounterContext.id, issueId: data.id})(dispatch)
              break;
          case "immunizations":
              EncounterAction.saveEncounterImmunization({encounterId: encounterContext.id, issueId: data.id})(dispatch)
              break;
          case "medicalProblem":
              EncounterAction.saveEncounterMedicalProblem({encounterId: encounterContext.id, issueId: data.id})(dispatch)
              break;
      }
  };

  React.useEffect(()=> {
      handleProblemChange();
  },[props?.selectedState])

  React.useEffect(()=> {
      handleProblemChange();
  },[medicalProblemsSel])

  React.useEffect(()=> {
      handleProblemChange();
  },[medicationsSel ])

  React.useEffect(()=> {
      handleProblemChange();
  },[allergiesSel])

  React.useEffect(()=> {
      handleProblemChange();
  },[hospitalizationSel ])

  React.useEffect(()=> {
      handleProblemChange();
  },[immunizationsSel])

  React.useEffect(()=> {
      handleProblemChange();
  },[issuesType])

  React.useEffect(()=> {
      handleProblemChange();
  },[medicalProblems])

  React.useEffect(()=> {
      handleProblemChange();
  },[medications ])

  React.useEffect(()=> {
      handleProblemChange();
  },[allergies])

  React.useEffect(()=> {
      handleProblemChange();
  },[hospitalization ])

  React.useEffect(()=> {
      handleProblemChange();
  },[immunizations])

  React.useEffect(()=> {
      if (encounterContext?.id) {
        EncounterAction.getEncounterMedicalProblems({encounterId: encounterContext.id})(dispatch)
        EncounterAction.getEncounterMedications({encounterId: encounterContext.id})(dispatch)
        EncounterAction.getEncounterAllergies({encounterId: encounterContext.id})(dispatch)
        EncounterAction.getEncounterHospitalizations({encounterId: encounterContext.id})(dispatch)
        EncounterAction.getEncounterImmunizations({encounterId: encounterContext.id})(dispatch)
      }
  },[encounterContext])

  const AddIssue = () => {
        switch(issuesType) {
            case "medications":
                PopupWindowActions.setWindowState(PopupWindows.ADD_MEDICATIONS_WINDOW, {medicationContext:  null})(dispatch)
                PopupWindowActions.showWindow(PopupWindows.ADD_MEDICATIONS_WINDOW)(dispatch);
                break;
            case "allergyProblem":
                PopupWindowActions.setWindowState(PopupWindows.ADD_ALLERGIES_WINDOW, {allergyContext: null})(dispatch)
                PopupWindowActions.showWindow(PopupWindows.ADD_ALLERGIES_WINDOW)(dispatch);
                break;
            case "hospitalization":
                PatientAction.setHospitalContext(null)(dispatch);
                PopupWindowActions.showWindow(PopupWindows.ADD_HOSPITALIZATIONS_SURGERIES_WINDOW)(dispatch)
                break;
            case "immunizations":
                PopupWindowActions.setWindowState(PopupWindows.ADD_IMMUNIZATION_WINDOW, {immunizationContext: null})(dispatch)
                PopupWindowActions.showWindow(PopupWindows.ADD_IMMUNIZATION_WINDOW)(dispatch);
                break;
            case "medicalProblem":
                PopupWindowActions.setWindowState(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW, {problemContext: null})(dispatch)
                PopupWindowActions.showWindow(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW)(dispatch)
                break;
        }

  };

  const EditIssues = (item) => {
        switch(issuesType) {
            case "medications":
                PopupWindowActions.setWindowState(PopupWindows.ADD_MEDICATIONS_WINDOW, {medicationContext: item.dataItem})(dispatch)
                PopupWindowActions.showWindow(PopupWindows.ADD_MEDICATIONS_WINDOW)(dispatch);
                break;
            case "allergyProblem":
                PopupWindowActions.setWindowState(PopupWindows.ADD_ALLERGIES_WINDOW, {allergyContext: item.dataItem})(dispatch)
                PopupWindowActions.showWindow(PopupWindows.ADD_ALLERGIES_WINDOW)(dispatch)
                break;
            case "hospitalization":
                const hospitalizationIssues = item.dataItem
                PatientAction.setHospitalContext(hospitalizationIssues)(dispatch);
                PopupWindowActions.showWindow(PopupWindows.ADD_HOSPITALIZATIONS_SURGERIES_WINDOW)(dispatch);
                break;
            case "immunizations":
                PopupWindowActions.setWindowState(PopupWindows.ADD_IMMUNIZATION_WINDOW, {immunizationContext: item.dataItem})(dispatch)
                PopupWindowActions.showWindow(PopupWindows.ADD_IMMUNIZATION_WINDOW)(dispatch)
                break;
            case "medicalProblem":
                PopupWindowActions.setWindowState(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW, {problemContext: item.dataItem})(dispatch)
                PopupWindowActions.showWindow(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW)(dispatch)
                break;
        }
  };

  const SelectColumCell = (p) => {
    return <td><div style={{pointerEvents: props?.disabled ? undefined : 'none'}}><Checkbox value={p.dataItem[SELECTED_FIELD]}  onChange = {(e)=> onSelectionChange(p, e)} disabled={false}/></div></td>
  }

  const EditCell = (p) => {
    return <td>
        <Button className="rounded-button"
                         icon={"pi pi-file-edit"}
                         tooltip={"Edit"}
                         style={{pointerEvents: props?.disabled ? undefined : 'none'}}
                         tooltipOptions={{position: "top"}}
                         text
                         onClick={()=> EditIssues(p)}/>
    </td>
  }

  return (
    <div style={{margin: 0, width: "calc(100% - 20x)", height: "calc(100% - 55px)"}}>
        <Row style={{marginBottom: "1%"}}>
            <div><strong>{"Manage conditions / orders linked to this encounter"}</strong></div>
        </Row>
        <Row style={{marginBottom: "1%", width: "100%"}}>
            <div className="flex">
                {radioData.map((btn, i) => {
                    return (
                        <div key={i} className="flex align-items-center mr-3">
                        <RadioButton
                            {...btn}
                                checked={issuesType === btn.value}
                                onChange={(e) => {
                                    setIssuesType(btn.value)
                                }}
                                />
                            <label htmlFor={btn.value} className="ml-1">
                            {btn.label}
                                </label>
                            </div>
                        );
                })}
            </div>
        </Row>
        <Row>
            <Col col={10}>
                <Input
                    style={{width: "50%"}}
                    placeholder={"Search"}
                    id={"input-id"}
                    value={searchState}
                    onChange={(e) => setSearchState(e.value)}
                />
            </Col>
            <Col>
                <Button className="rounded-button"
                 icon={"pi pi-plus"}
                 label={"Add"}
                 tooltip={"Add"}
                 style={{pointerEvents: props?.disabled ? undefined : 'none'}}
                 tooltipOptions={{position: "top"}}
                 text
                 onClick={AddIssue}/>
            </Col>
        </Row>
        <Row>
            <Col>
              <Grid
                data={gridData}
                style={{
                  minHeight: "110px",
                  height: "328px"
                }}
                fixedScroll={true}
              >
                <Column
                  field={SELECTED_FIELD}
                  width="35px"
                  headerSelectionValue={false}
                  cell = {SelectColumCell}
                  title="Link"
                />
                <Column field="code" title="Code" width="100px" />
                <Column field="name" title="Name"  />
                <Column title="" cell={EditCell} width={"50px"} />
              </Grid>
             </Col>
         </Row>
    </div>
  );
};