import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    CARE_TEAM_MEMBER_LIST_SUCCESS: "CARE_TEAM_MEMBER_LIST_SUCCESS",
    CARE_TEAM_MEMBER_LIST_ERROR: "CARE_TEAM_MEMBER_LIST_ERROR",

    CARE_TEAM_MEMBER_UPDATE_SUCCESS: "CARE_TEAM_MEMBER_UPDATE_SUCCESS",

    CARE_TEAM_CONTEXT_SET: "CARE_TEAM_CONTEXT_SET",
    CARE_TEAM_CONTEXT_CLEAR: "CARE_TEAM_CONTEXT_CLEAR",

    CARE_TEAM_MEMBER_GET_LIST_SUCCESS: "CARE_TEAM_MEMBER_GET_LIST_SUCCESS",
    CARE_TEAM_MEMBER_GET_LIST_ERROR: "CARE_TEAM_MEMBER_GET_LIST_ERROR",

    SET_CARE_TEAM_MEMBER_CONTEXT: "SET_CARE_TEAM_MEMBER_CONTEXT",
    CLEAR_CARE_TEAM_MEMBER_CONTEXT: "CLEAR_CARE_TEAM_MEMBER_CONTEXT",
}

function getCareTeamContext(patientRoleId) {
    return WrapAuthentication("care/team/patient", {
        "payload": {patientRoleId: patientRoleId},
    }, ActionTypes.CARE_TEAM_CONTEXT_SET, ActionTypes.CARE_TEAM_CONTEXT_CLEAR)
}

function getCareTeamList(careTeamId, filter = {}, sort = null, limit = 100, offset = 0) {
    return WrapAuthentication("care/team/member/get", {
        payload: {careTeamId},
        sort: sort ? {sorts: sort} : null,
        filter,
        limit,
        offset
    }, ActionTypes.CARE_TEAM_MEMBER_LIST_SUCCESS)
}

function createCareTeamMember(payload) {
    return WrapAuthentication("care/team/member/create",
        {payload},
    )
}

function updateCareTeamMember(payload) {
    return WrapAuthentication("care/team/member/update",
        {payload},
    )
}


export const CareTeamAction =
    {
        getCareTeamContext: getCareTeamContext,
        getCareTeamList: getCareTeamList,
        createCareTeamMember: createCareTeamMember,
        updateCareTeamMember: updateCareTeamMember,
    }