import {Button} from "primereact/button";
import * as React from "react";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {Card} from "primereact/card"
import {Col, Row} from "../../layout/GridLayout";
import DictionaryDropDownList from "./NewDictionaryDropDownList";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import axios from "axios";
import {url} from "../../config/Url";

const MultiDictionarySelect = (props) => {
    const {onChange, style, ref, code, value, fullObject = false, className} = props
    const [internalValue, setInternalValue] = useState([]);

    const handleRemove = (value) => {
        let d = internalValue.filter((d) => d.id !== value.id)
        setInternalValue(d)
        if (onChange)
            onChange(d)
    }

    const getValues = async () => {
        let v
        if (fullObject)
            v = value.map(i => i.id)
        else
            v = value
        let d = await axios.post(url("dictionary/get/ids"), {payload: {code, ids: v}})
        setInternalValue(d?.data ? d.data : [])
    }

    useEffect(() => {
        if (internalValue?.length === 0 && value?.length > 0) {
            getValues()
        }
    }, [value])

    const handleAdd = (value) => {
        if (value?.id) {
            const s = new Set(internalValue.map(d => d?.id))
            if (!s.has(value.id)) {
                let d = [...internalValue, value]
                setInternalValue(d)
                if (onChange) {
                    if (fullObject)
                        onChange(d)
                    else {
                        let ids = d.map(i => i.id)
                        onChange(ids)
                    }
                }

            }
        }
    }


    const itemRenderer = (item) => {
        return (
            <Row col={2}>
                <Button icon={"pi pi-trash"} text severity={"danger"} onClick={() => handleRemove(item)}/>
            </Row>)
    };


    return (
        <Card style={{padding: 10, ...style}}
            // title={props.label}
        >
            <Row gutter>
                <Col col={12} style={{fontSize: "1em",}}>
                    <div style={{fontSize: "1em",}}>
                        {props.label}
                    </div>
                </Col>
                <Col col={12}>
                    <DictionaryDropDownList
                        fullObject={fullObject}
                        className={props.className}
                        useIndex={props.useIndex}
                        showCode={props.showCode}
                        setValue={false}
                        onChange={(e) => handleAdd(e.target.value)}
                        code={code}
                        style={{width: "100%"}}/>
                </Col>
                <Col col={12}>
                    <DataTable
                        emptyMessage={"Add Codes above"}
                        size={"small"}
                        scrollable
                        scrollHeight={props?.height ? props.height : 200}
                        value={internalValue}
                        style={{height: props?.height ? props.height : 200}}>
                        {props.showCode && <Column header={"Code"} field={"code"}/>}
                        <Column header={"Name"} field={"displayName"}/>
                        <Column style={{width: 100}} header={"Actions"} body={itemRenderer}/>
                    </DataTable>
                </Col>
            </Row>


        </Card>
    );
};

function mapStateToProps(state) {
    return {
        dictionaries: state.dictionary.dictionaries,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiDictionarySelect);
