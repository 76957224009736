import * as React from "react";
import {useEffect} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {DictionaryTypeAction} from "../../action/DictionaryTypeAction";
import {ImmunizationActions} from "../../action/ImmunizationAction";
import {connect, useDispatch, useSelector} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {PatientAction} from "../../action/PatientAction";
import {PersonActions} from "../../action/PersonAction";
import {UserActions} from "../../action/UserAction";
import {EncounterAction} from "../../action/EncounterAction";
import {Button} from "primereact/button";
import {PermissionsTool} from "../../util/PermissionsTool";
import {Dialog} from "primereact/dialog";
import {Container} from "../../layout/GridLayout";
import {PhixPermissions} from "../../config/GlobalConfig";
import {
    FormColumn,
    FormContainer,
    FormDateSelector,
    FormDictionaryDropdown,
    FormDropdownList,
    FormRow,
    FormTextInput,
    FormUserListDropdown
} from "../InputControls/NewFormFields";
import {useForm} from "react-hook-form";
import {Dictionary} from "../InputControls/NewDictionaryDropDownList";
import {FilterDefaultValuesFromForm} from "../../util/MiscTools";
import { ProgressSpinner } from 'primereact/progressspinner';
import {v4 as uuidv4} from "uuid";


const defaultValues = {
    id: null,
    dictVaccineCvxId: null,
    dictVaccineMvxId: null,
    orderingUserRoleId: null,
    dictSourceOfInformationId: null,
    monthDate: null,
    // dayDate: null,
    yearDate: null,
    administeredDate: null,
    dictVaccineRouteId: null,
    dictVaccineStatusId: null,
    dictVaccineLocationAdministeredId: null,
    lotNumber: null,
    expirationDate: null,
}

const AddImmunizationWindow = (props) => {
    const dispatch = useDispatch()
    const [immunizationsPermission, setImmunizationsPermission] = React.useState({isEdit: false, isView: false})
    const showWindow = useSelector(state => state.popupWindow.showWindow)

    const immunizationContext = useSelector(state => state.popupWindow.state[PopupWindows.ADD_IMMUNIZATION_WINDOW]?.immunizationContext)
    const patient = useSelector(state => state.patient.patientContext)
    const permissionInfo = useSelector(state => state.auth.session.permissions)
    const immunizationsSel = useSelector(state => state.encounters.immunizations)
    const encounterContext = useSelector(state => state.encounters.encounterContext)
    const [isReset, setIsReset] = React.useState(true)

    const form = useForm({defaultValues: defaultValues});
    const {
        handleSubmit,
        reset,
    } = form

    useEffect(() => {
        if (immunizationContext?.id)
            reset(immunizationContext)
    }, [immunizationContext])

    React.useEffect(() => {
        setImmunizationsPermission(PermissionsTool({
            permissionInfo: permissionInfo,
            viewPermissions: [PhixPermissions.AH_VIEW_IMMUNIZATION],
            editPermissions: [PhixPermissions.AH_EDIT_IMMUNIZATION]
        }));
    }, [props?.permissionInfo]);

    function saveImmunizationAndClear(form) {
        form = FilterDefaultValuesFromForm(form, defaultValues)
        form.patientRoleId = patient.patientRoleId
        form.id = uuidv4();
        setIsReset(false)
        props.ImmunizationActions.createImmunization(form)
                       .then(()=> {
                            props.ImmunizationActions.getImmunization({"patientRoleId": patient.patientRoleId}).then(()=> {
                                props.EncounterAction.setImmunizationId({id: form.id});
                                props.EncounterAction.saveEncounterImmunization({encounterId: encounterContext.id, issueId: form.id}).then(()=>{
                                    reset(defaultValues)
                                    form.id = null;
                                    setIsReset(true)
                                })
                            })
                       })
    }
    function saveImmunization(form) {
        form = FilterDefaultValuesFromForm(form, defaultValues)
        form.patientRoleId = patient.patientRoleId
        if (form?.id) {
            props.ImmunizationActions.updateImmunization(form)
                .then(() => {
                    props.ImmunizationActions.getImmunization({"patientRoleId": patient.patientRoleId}).then(()=> {
                        props.EncounterAction.setImmunizationId({id: form.id})
                        if (!immunizationsSel?.find(v=> v.id === form.id))
                            props.EncounterAction.saveEncounterImmunization({encounterId: encounterContext.id, issueId: form.id})
                        hideDialog()
                    })
                })
        } else {
            form.id = uuidv4();
            props.ImmunizationActions.createImmunization(form)
                .then(()=> {
                    props.ImmunizationActions.getImmunization({"patientRoleId": patient.patientRoleId}).then(()=> {
                        props.EncounterAction.setImmunizationId({id: form.id})
                        props.EncounterAction.saveEncounterImmunization({encounterId: encounterContext.id, issueId: form.id})
                        hideDialog()
                    })
                })
        }

    }


    const hideDialog = () => {
        reset(defaultValues)
        props.PopupActions.hideWindow(PopupWindows.ADD_IMMUNIZATION_WINDOW);
    };

    let month = [];
    for (let i = 1; i <= 12; i++) {
        month.push(i.toString());
    }
    let day = [];
    for (let i = 1; i <= 31; i++) {
        day.push(i.toString());
    }
    let year = [];
    for (let i = 1980; i <= 2100; i++) {
        year.push(i.toString())
    }


    const canEditable = () => {
        if (immunizationsPermission.isEdit) {
            return {pointerEvents: 'block'};
        } else if (immunizationsPermission.isView) {
            return {pointerEvents: 'none'};
        } else {
            return {};
        }
    }


    return (
        <Dialog visible={showWindow[PopupWindows.ADD_IMMUNIZATION_WINDOW]}
                onHide={hideDialog}
                maximizable={true}
                header={immunizationContext?.id ? "Edit Immunization Record" : "Add Immunization Record"}
                footer={<Container className="justify-content-end" style={{marginTop: '15px', textAlign: 'center'}}>
                    {!immunizationContext?.id && <Button onClick={handleSubmit(saveImmunizationAndClear)}
                                                     label={"Save and Add New"}/>}
                    <Button onClick={handleSubmit(saveImmunization)}
                            label={immunizationContext?.id ? "Update" : "Save and Close"}
                            severity={"success"}/>
                    <Button onClick={hideDialog} label={"Cancel"} severity={"danger"}/>


                </Container>}
                style={{width: 550, height: 700}}>
            <div style={{pointerEvents: canEditable() ? 'block' : 'none'}}>
                {isReset ? <FormContainer form={form}>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormDictionaryDropdown showCode={true}
                                                    label={"Vaccine"}
                                                    rules={{required: "Vaccine is required."}}
                                                    field={"dictVaccineCvxId"}
                                                    code={Dictionary.CVX}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormUserListDropdown defaultCurrentUser
                                                  label={"Ordering Provider"}
                                                  field={"orderingUserRoleId"}/>
                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown label={"Information Source"}
                                                    field={"dictSourceOfInformationId"}
                                                    code={Dictionary.VACCINE_INFORMATION_SOURCE}/>
                        </FormColumn>

                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDropdownList options={month}
                                              label={"Administered Month"}
                                              field={"monthDate"}/>
                        </FormColumn>
                        {/*<FormColumn col={4}>*/}
                        {/*    <FormDropdownList options={day}*/}
                        {/*                      label={"Day"}*/}
                        {/*                      field={"dayDate"}/>*/}
                        {/*</FormColumn>*/}
                        <FormColumn col={6}>
                            <FormDropdownList options={year}
                                              label={"Administered Year"}
                                              field={"yearDate"}/>
                        </FormColumn>

                    </FormRow>
                    <FormRow>

                        <FormColumn col={12}>
                            <FormDateSelector options={year}
                                              label={"Administered Full Date (if known)"}
                                              field={"administeredDate"}/>
                        </FormColumn>

                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown label={"Route"}
                                                    field={"dictVaccineRouteId"}
                                                    code={Dictionary.VACCINE_ROUTE}/>
                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                rules={{required: "Completion status is required."}}
                                label={"Completion Status"} field={"dictVaccineStatusId"}
                                code={Dictionary.VACCINE_STATUS}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormDictionaryDropdown label={"Location Administered"}
                                                    field={"dictVaccineLocationAdministeredId"}
                                                    code={Dictionary.VACCINE_LOCATION_ADMINISTERED}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormDictionaryDropdown label={"Vaccine Manufacturer"}
                                                    field={"dictVaccineMvxId"}
                                                    code={Dictionary.VACCINE_MVX}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormTextInput label={"Lot number"}
                                           field={"lotNumber"}/>
                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDateSelector label={"Expiration Date"}
                                              field={"expirationDate"}/>
                        </FormColumn>
                    </FormRow>
                </FormContainer> : <ProgressSpinner/>}
            </div>
        </Dialog>
    );

};

AddImmunizationWindow.propTypes = {
    showWindow: PropTypes.object,
    DictionaryActions: PropTypes.object,
    DictionaryTypeAction: PropTypes.object,
    PopupActions: PropTypes.object,
    PatientActions: PropTypes.object,
    createPatientContext: PropTypes.object,
    patient: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        popupState: state.popupWindow.state,
        patient: state.patient,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
        ImmunizationActions: bindActionCreators(ImmunizationActions, dispatch),
        EncounterAction: bindActionCreators(EncounterAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddImmunizationWindow);
