import * as React from "react";
import {GridToolbar} from "@progress/kendo-react-grid";
import {PatientAction} from "../action/PatientAction";
import {DictionaryDispatchAction} from "../action/DictionaryDispatchAction";
import {bindActionCreators} from "redux";
import {connect, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {Dictionary,} from "./InputControls/DictionaryDropDownList";
import {Field, FieldDictionaryDropDown, FieldRow, FieldSection, FieldTextAreaInput} from "./InputControls/FormFields";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Fade} from "@progress/kendo-react-animation";
import {PopupWindowActions} from "../action/PopupWindowAction";


const EditCommandCell = (props) => {
    const {data} = props;
    const inEdit = data[props.editField] || false;
    const isNewItem = data?.newItem || false;
    return inEdit ? (
        <div style={{marginTop: "5%"}}>
            <Button
                icon={isNewItem ? "pi pi-save" : "pi pi-save"}
                severity={"success"}
                text
                onClick={(e) => isNewItem ? props.add(e) : props.update(e)}> </Button>
            <Button
                text
                severity={"danger"}
                icon={isNewItem ? "pi pi-ban" : "pi pi-trash"}
                onClick={(e) =>
                    isNewItem ? props.cancel(e, data) : props.remove(e)}> </Button>
        </div>
    ) : (
        <div style={{marginTop: "5%"}}>
            <Button icon={"pi pi-pencil"} text onClick={(e) => props.edit(e)}> </Button>
        </div>
    );
};

const PatientAttributeDetails = (props) => {
    const [dataItem, setDataItem] = React.useState(null);
    const [validated, setValidated] = React.useState(true);
    const editField = "inEdit";
    const {
        handleSubmit,
        register,
        control,
        setValue,
        formState: {errors},
    } = useForm({defaultValues: {}});

    React.useEffect(() => {
        setDataItem(props?.dataItem);
        if (props?.dataItem?.dictId)
        {
            setValue("attribute", props?.dataItem?.dictId);
        }
        if (props?.dataItem?.note)
        {
            setValue("note", props?.dataItem?.note);
        }

    }, [props?.dataItem])

    const enterEdit = (e) => {
        e.preventDefault();
        setDataItem({...dataItem, inEdit: true, newItem: false});
    };

    const remove = (e) => {
        e.preventDefault();
        console.log(dataItem)
        let payload = {dictId: dataItem.dictId, patientRoleId: props.patientContext.patientRoleId};
        props.PatientActions.removePatientRoleAttribute(payload)
    }

    const add = (form) => {
        if (form?.attribute?.id || form?.attribute) {
            let payload = {dictId: form?.attribute?.id ? form.attribute.id : form.attribute, note: form?.note, patientRoleId : props.patientContext.patientRoleId}
            console.log(payload)
            props.PatientActions.addPatientRoleAttribute(payload)
        } else {
            setValidated(false);
        }
    }

    const EditButtons = () => {
        return (
            <EditCommandCell
                data={dataItem}
                editField={editField}
                cancel={props.cancel}
                remove={remove}
                update={handleSubmit(add)}
                add={handleSubmit(add)}
                edit={enterEdit}
            />
        );
    };

    return dataItem && <div style={{width: "100%"}}>
        <EditButtons/>
        <FieldSection >
            <div style={{pointerEvents: dataItem.inEdit ? undefined : 'none', width: '100%'}}>
                <FieldRow>
                    <Field col={12} label={"Attribute"} field={"attribute"} error={errors} register={register}
                           control={control}>
                        <FieldDictionaryDropDown code={"PH_PATIENT_ROLE_ATTRIBUTE"}/>
                    </Field>
                    <Field col={12} label={"Note"} field={"note"} error={errors} register={register} control={control}>
                        <FieldTextAreaInput/>
                    </Field>
                </FieldRow>
            </div>


        </FieldSection>
        <hr></hr>
        <NotificationGroup style={{
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
        }}>
            <Fade>
                {!validated && (
                    <Notification
                        type={{
                            style: "error",
                            icon: true,
                        }}
                        closable={true}
                        onClose={() => setValidated(true)}
                    >
                        <span>{"Mandatory fields are missing"}</span>
                    </Notification>
                )}
            </Fade>
        </NotificationGroup>
    </div>
}


const PatientAttributeComponent = (props) => {
    const [data, setData] = React.useState([]);

    const addNew = (e) => {
        e.preventDefault();
        let newDataItem = {
            newItem: true,
            inEdit: true
        };
        setData([...data, newDataItem]);
    };


    React.useEffect(() => {
        if (props?.patientRoleAttributes && Array.isArray(props?.patientRoleAttributes)) {
            const pr = props?.patientRoleAttributes?.map(v => ({...v, newItem: false, inEdit: false})) || [];
            setData([...pr])
        }
    }, [props?.patientRoleAttributes])

    React.useEffect(() => {
        if (props?.patientContext?.patientRoleId) {
            props.PatientActions.getPatientRoleAttributes({patientRoleId: props?.patientContext?.patientRoleId}).then(res => {
                const pr = res.payload?.map(v => ({...v, newItem: false, inEdit: false}));
                setData(Array.isArray(pr) ? [...pr] : [])
            })
        }
    }, [props?.patientContext?.patientRoleId]);


    const cancel = (e, dataItem) => {
        e.preventDefault();
        const data1 = [...data];
        const originalItem = data.find((p) => p.id === dataItem.id);
        if (!originalItem.newItem) {
            if (props?.patientContext?.patientRoleId) {
                props.PatientActions.getAllPatientRelationships({patientRoleId: props?.patientContext?.patientRoleId})
            }
        } else {
            data1.splice(data1.indexOf(originalItem), 1);
            setData(data1);
        }
    }


    return (
        <div>
            <GridToolbar>
                <Button icon={"pi pi-plus"} text severity={"success"} onClick={addNew}/>
                {/*<Button icon={"pi pi-refresh"} onClick={refresh}/>*/}
            </GridToolbar>
            {
                data.map((d, i) => {
                    return <div style={{width : "100%"}}>
                        <PatientAttributeDetails
                            key={i}
                            cancel={(e) => cancel(e, d)}
                            PatientActions={props.PatientActions}
                            patientContext={props.patientContext}
                            dataItem={{...d}}/>
                    </div>
                })
            }
        </div>
    )
};

function mapStateToProps(state) {
    return {
        patientRoleAttributes: state.patient.patientRoleAttributes,
        // patientContext: state.patient.patientContext,
        patientContext: state.patient.createPatientContext    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientAttributeComponent)
