import React from 'react';
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem
} from '@progress/kendo-react-charts';
import GlobalStyles from '../../config/GlobalStyles';
import TileHeader from '../../layout/TileHeader';
import {connect} from 'react-redux';
import {PatientAction} from "../../action/PatientAction";
import {bindActionCreators} from "redux";
import moment from 'moment';
import {Button} from "@progress/kendo-react-buttons";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {TileRow} from "../../layout/TileRow";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../config/GlobalConfig";
import {Col} from "react-grid-system";

const categories = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const sleepTargets = [7, 7, 7, 7, 7, 7, 7]

const SleepQualityPatientPanelComponent = (props) => {
    const {PatientActions, SleepQualityData, PatientContext} = props
    const [data, setData] = React.useState([])
    React.useEffect(() => {
        if (SleepQualityData) {
            let data = SleepQualityData.reduce((a, b) => {
                let key = moment(b.effectiveStartDate).format("ddd")
                a[key] = {
                    y: moment(b.effectiveStartDate).format("ddd"),
                    sleepValue: b.sleepValue,
                    awakeValue: b.awakeValue,
                    arousals: b.arousals,
                }
                return a
            }, {})

            let newData = [
                data?.Sun ? data.Sun : {y: "Sun", sleepValue: 0, awakeValue: 0},
                data?.Mon ? data.Mon : {y: "Mon", sleepValue: 0, awakeValue: 0},
                data?.Tue ? data.Tue : {y: "Tue", sleepValue: 0, awakeValue: 0},
                data?.Wed ? data.Wed : {y: "Wed", sleepValue: 0, awakeValue: 0},
                data?.Thu ? data.Thu : {y: "Thu", sleepValue: 0, awakeValue: 0},
                data?.Fri ? data.Fri : {y: "Fri", sleepValue: 0, awakeValue: 0},
                data?.Sat ? data.Sat : {y: "Sat", sleepValue: 0, awakeValue: 0},
            ]
            setData(newData)
        }
    }, [SleepQualityData])

    const [weeksPrevious, setWeeksPrevious] = React.useState(0)
    const [startDate, setStartDate] = React.useState(moment().startOf('week').format("YYYY-MM-DD"))
    const [endDate, setEndDate] = React.useState(moment().endOf('week').format("YYYY-MM-DD"))
    React.useEffect(() => {
        setStartDate(moment().startOf('week').subtract(weeksPrevious, 'week').format("YYYY-MM-DD"))
        setEndDate(moment().endOf('week').subtract(weeksPrevious, 'week').format("YYYY-MM-DD"))
    }, [weeksPrevious])


    React.useEffect(() => {
        PatientActions.getSleepObservationsByPeriod(PatientContext.patientRoleId,
            startDate,
            endDate,
            "SleepQualityComponent")
    }, [startDate])

    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_OBSERVATION]}>
                <TileContainer>
                    <TileHeader
                        colSize={9}
                        title={weeksPrevious === 0 ? "SLEEP QUALITY - Current week" : `SLEEP QUALITY - Week of ${startDate}`}
                        image={"../images/sleep.png"}>
                        <Button
                            style={GlobalStyles.headerBackForwardButtons}
                            icon={"k-icon k-i-arrow-60-left"}
                            onClick={(e) => {
                                setWeeksPrevious(weeksPrevious + 1)

                            }}/>
                        <Button
                            style={GlobalStyles.headerBackForwardButtons}
                            iconClass={"k-icon k-i-arrow-60-right"}
                            onClick={(e) => {
                                if (weeksPrevious > 0) {
                                    setWeeksPrevious(weeksPrevious - 1)
                                }
                            }}
                        />


                    </TileHeader>

                    <TileBody containerStyle={{backgroundColor: "white"}}>
                        <TileRow>
                            <Col sm={12}>
                                <Chart renderAs={"canvas"} style={{height: "calc(100% - 5px)", width: "100%"}}>
                                    <ChartLegend position="bottom" orientation="horizontal"/>
                                    <ChartSeries>
                                        <ChartSeriesItem color={'#6AA84F'}
                                                         type="column"
                                                         name="Sleep"
                                                         stack={true}
                                                         data={data}
                                                         field={"sleepValue"}
                                                         categoryField={'y'}
                                                         tooltip={{
                                                             visible: true,
                                                             background: "white",
                                                             render: (v) => {

                                                                 return <div>{v.point.value.toFixed(2)} Hours
                                                                     asleep</div>
                                                             }

                                                         }}/>
                                        <ChartSeriesItem color={'#F1C232'}
                                                         name="Awake"
                                                         type="column"

                                                         field={"awakeValue"}
                                                         categoryField={'y'}
                                                         data={data}
                                                         tooltip={{
                                                             visible: true,
                                                             background: "white",
                                                             render: (v) => {

                                                                 return <div>{v.point.value.toFixed(2)} Hours
                                                                     awake</div>
                                                             }

                                                         }}>
                                            {/*<ChartSeriesLabels position={"center"}/>*/}
                                        </ChartSeriesItem>
                                        <ChartSeriesItem color={'blue'}
                                                         type="line"
                                                         name="Target Sleep Hours"
                                                         data={sleepTargets}
                                                         markers={{
                                                             visible: true,
                                                         }}
                                                         tooltip={{
                                                             visible: true,
                                                             background: "white",
                                                             render: (v) => {

                                                                 return <div>{v.point.value} Hours Average Sleep
                                                                     Goal</div>
                                                             }

                                                         }}
                                        />
                                        <ChartSeriesItem color={'red'}
                                                         tooltip={{
                                                             visible: true,
                                                             background: "white",
                                                             render: (v) => {

                                                                 return <div>{v.point.value} - Arousals</div>
                                                             }

                                                         }}
                                                         type="line"
                                                         name="Arousals"
                                                         categoryField={'y'}
                                                         field={"arousals"}
                                                         data={data}
                                        />
                                    </ChartSeries>
                                    <ChartCategoryAxis>
                                        <ChartCategoryAxisItem categories={categories}/>
                                    </ChartCategoryAxis>
                                </Chart>
                            </Col>
                        </TileRow>
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        PatientContext: state.patient.patientContext,
        SleepQualityData: state.patient.observations.SleepQualityComponent // this is the component name we are using.
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SleepQualityPatientPanelComponent)