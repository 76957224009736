import * as React from "react";
import {useEffect} from "react";
import {bindActionCreators} from "redux";
import {connect, useSelector} from "react-redux";

import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {EncounterAction} from "../../action/EncounterAction";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {UserActions} from "../../action/UserAction";
import {Col, Container, Row} from "../../layout/GridLayout";
import {Checkbox, RadioGroup} from "@progress/kendo-react-inputs";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Button} from "primereact/button";
import {InlineNumberInput, InlineTextInput} from "../InputControls/InlineFields";
import {v4 as uuidv4} from "uuid";
import {PatientAction} from "../../action/PatientAction";
import _ from "lodash";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Fade} from "@progress/kendo-react-animation";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {PermissionsTool} from "../../util/PermissionsTool";
import {Dialog} from "primereact/dialog";
import FacilityDropDownList from "../InputControls/NewFacilityDropDownList";

const EditCommandCell = (props) => {
    const {dataItem} = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem?.newItem || false;
    return inEdit ? (
        <td className="k-command-cell">
            <Button
                primary={true}
                severity={"success"}
                label={"Save"}
                onClick={() => isNewItem ? props.add(dataItem) : props.update(dataItem)}/>
            <Button
                label={"Cancel"}
                severity={"danger"}
                style={{backgroundColor: "#E06666"}}
                onClick={() => props.cancel(dataItem)}/>
        </td>
    ) : (
        <td className="k-command-cell">
            <Button icon={"pi pi-pencil"}
                    text
                    severity={"success"}
                    onClick={() => props.edit(dataItem)}/>
            <Button
                icon={"pi pi-trash"}
                text
                severity={"danger"}
                onClick={() =>
                    props.remove(dataItem)
                }/>
        </td>
    );
};


const DictionaryDropDownList = (prop) => {
    const props = prop.props;
    const {required, value, defaultItemCode, inputRef, code} = prop
    const [data, setData] = React.useState(prop.data)
    const [filter, setFilter] = React.useState("")
    const [internalValue, setInternalValue] = React.useState(null)

    const filterChange = (event) => {
        setFilter(event.filter.value)
    };

    const onSetDefaultValue = (value) => {
        if (prop.onChange) {
            prop.onChange({target: {value: value}});
        }
    };
    const handleChange = (e) => {

        if (e.value.id) {
            setInternalValue(e.target.value)
            if (prop.onChange) {
                prop.onChange(e)
            }
        }
    }
    React.useEffect(() => {
        setData(prop.data);
    }, [prop.data])

    React.useEffect(() => {
        if (data?.length > 0) {
            if (value?.id && internalValue?.id !== value?.id) {
                setInternalValue(value)
            } else if (!_.isNil(value) && _.isNil(internalValue)) {
                let v = data ? data.find((c) => c.id === value) : undefined

                if (!_.isNil(v)) {
                    onSetDefaultValue(v)
                    setInternalValue(v)
                }

            } else if (!internalValue?.id && !_.isNil(defaultItemCode)) {
                let v = data ? data.find((c) => c.code === defaultItemCode) : undefined
                if (!_.isNil(v)) {
                    onSetDefaultValue(v)
                    setInternalValue(v)
                }
            }
        }
    }, [defaultItemCode, data, value])


    React.useEffect(() => {
        let filterJson = {};
        filterJson["code"] = filter;
        filterJson["description"] = filter;
        props.DictionaryActions.searchDictionaryList(code, 500, 0, null, filterJson)
    }, [code, filter])


    return (<>
        <DropDownList style={prop.style}
                      ref={inputRef}
                      required={required}
                      disabled={prop.disabled}
                      data={data}
                      value={internalValue}
                      dataItemKey={"id"}
                      textField={prop.displayField ? prop.displayField : "displayName"}
                      filterable={true}
                      onFilterChange={filterChange}
                      onChange={handleChange}
        />

    </>)
}


const EncounterCodingWindow = (props) => {
    const encounterContextState = useSelector(state => state.popupWindow.state?.[PopupWindows.ENCOUNTER_CODING_WINDOW])
    const {PatientActions, patient} = props;
    const [renderingUserLock, setRenderingUserLock] = React.useState(false);
    const [renderingUser, setRenderingUser] = React.useState(null);
    const [supervisingUserLock, setSupervisingUserLock] = React.useState(false);
    const [supervisingUser, setSupervisingUser] = React.useState(null);
    const [billingLocation, setBillingLocation] = React.useState(null);
    const [selectedDictionary, setSelectedDictionary] = React.useState("CPT4");
    const [selectedDiagnosesCategory, setSelectedDiagnosesCategory] = React.useState("encounter_only");
    const [data, setData] = React.useState([]);
    const [renderingUserData, setRenderingUserData] = React.useState([]);
    const [supervisingUserData, setSupervisingUserData] = React.useState([]);
    const [serviceLineContext, setServiceLineContext] = React.useState(null);
    const [diagnosesData, setDiagnosesData] = React.useState([]);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [errors, setErrors] = React.useState({})
    const [filter, setFilter] = React.useState({searchAll: ""});
    const [buildFilters, setBuildFilters] = React.useState({})
    const [encounterContext, setEncounterContext] = React.useState(props?.encounterContext || encounterContextState)
    const [encounterCodingPermission, setEncounterCodingPermission] = React.useState({isEdit: false, isView: false})

    useEffect(() => {
        console.log(encounterContextState)
        if (!encounterContext?.id)
            setEncounterContext(encounterContextState)
    }, [encounterContextState])

    const editField = "inEdit";
    const dictData = [
        {
            label: "CPT4",
            value: "CPT4",
        },
        {
            label: "HCSPCS",
            value: "HCSPCS",
        }
    ];

    const diagnosesDataFor = [
        {
            label: "Encounter Only",
            value: "encounter_only",
        },
        {
            label: "All Patient Diagnoses",
            value: "all_patient_diagnoses",
        }
    ];

    const initialSort = [
        {
            field: "firstName",
            dir: "asc",
        },
    ];
    const [dataState, setDataState] = React.useState({})
    const [sort, setSort] = React.useState(initialSort);
    const [enSort, setEnSort] = React.useState([{field: "encounterDate", dir: "desc",}]);


    React.useEffect(() => {
        if (props?.encounterContext.isAdmin === false)
            setEncounterCodingPermission(PermissionsTool({
                permissionInfo: props.permissionInfo,
                viewPermissions: ["AH_VIEW_ENCOUNTER_CODING"],
                editPermissions: ["AH_EDIT_ENCOUNTER_CODING"]
            }));
        else
            setEncounterCodingPermission(PermissionsTool({
                permissionInfo: props.permissionInfo,
                viewPermissions: ["AH_ADMIN_VIEW_ENCOUNTER_CODING"],
                editPermissions: ["AH_ADMIN_EDIT_ENCOUNTER_CODING"]
            }));
    }, [props?.permissionInfo && props?.encounterContext]);

    React.useEffect(() => {
        if (props.encounterContext?.patientRoleId) PatientActions.getMedicalProblems(props.encounterContext?.patientRoleId).then(() => setDiagnosesDataF());
    }, []);

    React.useEffect(() => {
        if (props.users) {
            setRenderingUserData(props.users.map(user => {
                user['textField'] = (user.firstName ? user.firstName + " " : "") + (user.lastName ? user.lastName : "") + (user.credentials ? ", " + user.credentials : "");
                return user;
            }));
            if (props.encounterContext?.userRoleId) {
                setRenderingUser(props.users.find(us => us.userRoleId === props.encounterContext?.userRoleId));
            }
            if (props.encounterContext?.billingFacilityId) {
                setBillingLocation(props.encounterContext?.billingFacilityId);
            } else {
                setBillingLocation(patient?.facilityId);
            }
            setSupervisingUserData(props.users);
            if (props.encounterContext?.ecRenderingProviderRoleId) {
                setRenderingUser(props.users.find(us => us.userRoleId === props.encounterContext?.ecRenderingProviderRoleId));
            }
            if (props.encounterContext?.ecSupervisingProviderRoleId) {
                setSupervisingUser(props.users.find(us => us.userRoleId === props.encounterContext?.ecSupervisingProviderRoleId));
            }
            if (props.encounterContext?.renderingProviderIsLocked) {
                setRenderingUserLock(props.encounterContext?.renderingProviderIsLocked);
            }
            if (props.encounterContext?.supervisingProviderIsLocked) {
                setSupervisingUserLock(props.encounterContext?.supervisingProviderIsLocked);
            }
        }
    }, [encounterContext]);

    function loadUsers() {
        let filters = {}
        filters.searchAll = filter?.value
        filters.active = true
        props.UserActions.getUserListFilter(initialSort, filters, true);
    }

    React.useEffect(() => {
        if (props.encounterContext?.id) {
            loadUsers();
            props.EncounterAction.getEncounterCodingByEncounterId({id: props.encounterContext?.id});
            props.EncounterAction.getEncounterCodingServiceLineByEncounterId({id: props.encounterContext?.id});
        }
    }, [encounterContext && props?.medicalProblems]);

    React.useEffect(() => {
        setEncounterContext(props.encounterContext);
        loadUsers();
    }, []);

    const filterChange = (event) => {
        setFilter({value: event.filter.value})
    };

    React.useEffect(() => {
        loadUsers();
        setEncounterContext(props?.encounterContext);
        if (props.popupState[PopupWindows.ENCOUNTER_CODING_WINDOW]?.buildFilters) {
            setBuildFilters(props.popupState[PopupWindows.ENCOUNTER_CODING_WINDOW]?.buildFilters)
        }
        if (props.popupState[PopupWindows.ENCOUNTER_CODING_WINDOW]?.dataState) {
            setDataState(props.popupState[PopupWindows.ENCOUNTER_CODING_WINDOW]?.dataState)
        }
    }, [props.encounterContext]);

    const getDiagnosesData = (dataItem) => {
        const selectedCodes = (dataItem?.diagnoses?.map(v => v.code)) || (diagnosesData?.filter(v => v.selected)?.map(v => v.code)) || [];
        const encounterProblemIds = props?.encounterMedicalProblems?.map(v => v?.id) || [];
        const data = props.medicalProblems.map(v => {
            return {
                "selected": selectedCodes?.indexOf(v.diagnosisCode.map(d => d.code).join(",")) > -1,
                "code": v.diagnosisCode.map(d => d.code).join(", "),
                "description": v.diagnosisCode.length > 0 ? v.diagnosisCode.map(d => d.description).join(", ") : v.description,
                "type": "medicalProblem",
                "id": v.id,
                "isOnlyEncounter": encounterProblemIds.indexOf(v.id) > -1
            }
        });
        return [...new Map(data.map(item => [item["code"], item])).values()];
    }

    React.useEffect(() => {
        if (props?.serviceLines && props?.serviceLines?.length > 0) {
            const encounterProblemIds = props?.encounterMedicalProblems?.map(v => v.id) || [];
            const dataF = [...props.serviceLines].map(sl => {
                const data = props.medicalProblems.map(v => {
                    return {
                        "selected": sl.diagnoses?.indexOf(v.diagnosisCode.map(d => d.code).join(",")) > -1,
                        "code": v.diagnosisCode.map(d => d.code).join(", "),
                        "description": v.diagnosisCode.length > 0 ? v.diagnosisCode.map(d => d.description).join(", ") : v.description,
                        "type": "medicalProblem",
                        "id": v.id,
                        "isOnlyEncounter": encounterProblemIds.indexOf(v.id) > -1
                    }
                });
                sl.diagnoses = [...new Map(data.map(item => [item["code"], item])).values()];
                sl.code = props.cpt.find(m => m.id == sl.service)?.code || props.cps.find(m => m.id == sl.service)?.code;
                sl.inEdit = false;
                sl.newItem = false;
                return sl;
            });
            setData([...dataF])
        } else {
            setData([])
        }
    }, [props.serviceLines]);


    const setDiagnosesDataF = (dataItem) => {
        setDiagnosesData(getDiagnosesData(dataItem ? dataItem : null))
    }

    const hideDialog = async () => {
        props.EncounterAction.getEncounter({id: props?.encounterContext?.id});
        if (encounterContext?.isAdmin) {
            props.PatientActions.getEncounterList(null, dataState.take, dataState.skip, enSort, buildFilters);
            props.EncounterAction.clearEncounterContext();
        }
        props.EncounterAction.clearEncounterCoding();
        setSupervisingUserLock(false)
        setRenderingUserLock(false)
        setBillingLocation(null)
        setRenderingUser(null)
        setSupervisingUser(null)
        setSupervisingUserData([])
        setRenderingUserData([])
        setData([])
        props.PopupActions.hideWindow(PopupWindows.ENCOUNTER_CODING_WINDOW);
    }

    React.useEffect(() => {
        props.DictionaryActions.searchDictionaryList("AH_CPT", 100, 0, null, {})
        props.DictionaryActions.searchDictionaryList("AH_HCPCS", 100, 0, null, {})
    }, []);


    React.useEffect(() => {
        if (props.encounterContext?.id) {
            setDiagnosesDataF();
        }
    }, [props.encounterContext]);

    React.useEffect(() => {
        if (props.encounterContext?.id) {
            setDiagnosesDataF();
        }
    }, [serviceLineContext]);

    React.useEffect(() => {
        if (props.encounterContext?.id) {
            setDiagnosesDataF();
        }
    }, [selectedDiagnosesCategory]);

    const PatientName = () => {
        let pFirstName = props.encounterContext.patientFirstName ? props.encounterContext.patientFirstName : "";
        let pLastName = props.encounterContext.patientLastName ? props.encounterContext.patientLastName : "";
        let pMiddleName = props.encounterContext.patientMiddleName ? props.encounterContext?.patientMiddleName : "";
        return <>
            <span style={{
                fontWeight: 'bold',
                fontSize: '16px'
            }}>Patient: </span>{pFirstName + " " + pMiddleName + " " + pLastName}
        </>;
    }

    const DateOfService = () => {
        let dos = props.encounterContext && props.encounterContext.encounterDate ? new Date(props.encounterContext.encounterDate).toLocaleDateString('en-US') : new Date().toLocaleDateString('en-US');
        return <>
            <b>Date of Service: </b>{dos}
        </>;
    }

    const handleRenderLockChange = () => {
        setRenderingUserLock(!renderingUserLock);
    }
    const handleRenderUserChange = (event) => {
        setRenderingUser(event.value);
    }
    const handleSupervisorUserChange = (event) => {
        setSupervisingUser(event.value);
    }
    const handleSupervisorLockChange = () => {
        setSupervisingUserLock(!supervisingUserLock);
    }


    const handleDictChange = (event) => {
        setDiagnosesDataF();
        setSelectedDictionary(event.value)
    }
    const handleDiagnosesChange = (event) => {
        setSelectedDiagnosesCategory(event.value)
    }
    const enterEdit = (dataItem) => {
        setDiagnosesDataF()
        let newData = data.map((item) =>
            item.id === dataItem.id ? {...item, inEdit: true} : item
        );
        setData(newData);
    };

    const edit = (props) => {
        return (
            <EditCommandCell
                {...props}
                editField={editField}
                cancel={cancel}
                update={add}
                remove={remove}
                add={add}
                edit={enterEdit}
            />
        );
    };
    const validateFields = (dataItem) => {
        let errors = {}
        const diagnosesExists = dataItem?.diagnoses?.filter(v => v.selected)
        if (!dataItem.service) errors = {...errors, description: "This field is required."}
        if (!dataItem.description) errors = {...errors, operation: "This field is required."}
        if (!dataItem.diagnoses || !diagnosesExists || diagnosesExists?.length <= 0) errors = {
            ...errors,
            operation: "This field is required."
        }
        if (_.isEmpty(errors)) {
            setSuccess(false);
            return true
        } else {
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
            setErrors(errors);
            return false
        }
    }


    const add = (dataItem) => {
        if (validateFields(dataItem)) {
            const payload = {
                id: dataItem.id,
                encounterId: props.encounterContext.id,
                service: dataItem.service,
                serviceType: selectedDictionary === "CPT4" ? props.cpt[0].dictionaryTypeId : props.cps[0].dictionaryTypeId,
                description: dataItem.description,
                modifiers: dataItem.modifiers,
                units: dataItem?.units,
                fee: dataItem?.fee
            }
            if (dataItem.diagnoses) {
                payload.diagnoses = dataItem?.diagnoses?.filter(v => v.selected).map(v => v?.code)
            }
            props.DictionaryActions.searchDictionaryList("AH_CPT", 1000, 0, null, {})
            props.DictionaryActions.searchDictionaryList("AH_HCPCS", 1000, 0, null, {})
            props.EncounterAction.saveEncounterCodingServiceLine(payload)
                .then(() => {
                    props.EncounterAction.getEncounterCodingServiceLineByEncounterId({id: props.encounterContext.id});
                })
            setErrors({})
        }
    };
    const saveEncounterCoding = () => {
        const payload = {
            encounterId: props.encounterContext.id,
            renderingProviderRoleId: renderingUser?.userRoleId,
            renderingProviderIsLocked: renderingUserLock,
            supervisingProviderIsLocked: supervisingUserLock,
            billingFacilityId: billingLocation,
            saved: false
        }
        if (supervisingUser) {
            payload.supervisingProviderRoleId = supervisingUser?.userRoleId
        }
        if (props.encounterContext?.encounterCodingId) {
            props.EncounterAction.updateEncounterCodingByEncounterId(payload).then(() => {
                hideDialog();
            });
        } else {
            props.EncounterAction.addEncounterCodingByEncounterId(payload)
                .then(() => {
                    hideDialog();
                });
        }
    }
    const remove = (dataItem) => {
        if (window.confirm("Are you sure you want to delete this service line ?")) {
            props.EncounterAction.deleteEncounterCodingServiceLineById({id: dataItem.id}).then(() => {
                props.EncounterAction.getEncounterCodingServiceLineByEncounterId({id: props.encounterContext.id});
            })
        }
    }

    const addNew = () => {
        let newDataItem = {
            id: uuidv4(),
            newItem: true,
            inEdit: true,
            diagnoses: []
        };
        newDataItem["diagnoses"] = getDiagnosesData(newDataItem);
        setData([...data, newDataItem]);
    };
    const cancel = (dataItem) => {
        const data1 = [...data];
        const originalItem = data.find((p) => p.id === dataItem.id);
        if (!originalItem.newItem) {
            props.EncounterAction.getEncounterCodingServiceLineByEncounterId({id: props.encounterContext.id});
        } else {
            data1.splice(data1.indexOf(originalItem), 1);
            setData(data1);
        }
    }

    const RenderingUserDropdown = () => {
        return <DropDownList style={{width: "100%"}}
                             disabled={renderingUserLock}
                             data={renderingUserData}
                             defaultItem={{textField: "Please Select ..."}}
                             value={renderingUser}
                             dataItemKey={"userRoleId"}
                             textField={"textField"}
                             onChange={handleRenderUserChange}/>
    }

    const SupervisingUserDropdown = () => {
        return <DropDownList style={{width: "100%"}}
                             disabled={supervisingUserLock}
                             data={supervisingUserData}
                             defaultItem={{displayName: "Please Select ..."}}
                             value={supervisingUser}
                             dataItemKey={"userRoleId"}
                             textField={"displayName"}
                             onChange={handleSupervisorUserChange}/>
    }

    const RenderingProvider = () => {
        return <Row className={"w-full"}>
            <Col col={5}><b>Rendering Provider:</b></Col>
            <Col col={5}><RenderingUserDropdown/></Col>
        </Row>
    }

    const SupervisingProvider = () => {
        return <Row className={"w-full"} style={{paddingTop: 5}}>
            <Col col={5}><b>Supervising Provider:</b></Col>
            <Col col={5}><SupervisingUserDropdown/></Col>
        </Row>
    }
    const BillingLocation = () => {
        return <Row className={"w-full"} style={{paddingTop: 5}}>
            <Col col={5} className={'align-content-center align-text-center'}><b>Billing Location: </b></Col>
            <Col col={5}><FacilityDropDownList labelName={""}
                                               dataItem={patient}
                // defaultValue={billingLocation ? billingLocation : patient?.facilityId}
                                               value={billingLocation}
                                               className={"w-full"}
                                               onChange={(e) => {
                                                   setBillingLocation(e.value)
                                               }}/>
            </Col>

        </Row>
    }
    const itemChange = (event) => {
        const field = event.field || "";
        const newData = data.map((item) => {
            if (item.id === event.dataItem.id) {
                if (item.errors && item.errors[field]) {
                    delete item.errors[field];
                }
                if (field === "service") {
                    const desc = selectedDictionary === "CPT4" ? props.cpt.find(d => d.id == event.value)?.description : props.cps.find(d => d.id == event.value)?.description;
                    return {...item, [field]: event.value, "description": desc};
                } else {
                    return {...item, [field]: event.value}
                }
            } else {
                return item;
            }
        });
        setData(newData);
    };

    const InlineDictionaryDropDown = (prop) => {
        const {dataItem} = prop
        const field = "service"
        const handleChange = (e) => {
            if (prop.onChange) {
                const data = {
                    dataIndex: prop.dataIndex,
                    dataItem: dataItem,
                    field: field,
                    syntheticEvent: e.syntheticEvent,
                    value: e.target.value.id,
                }
                prop.onChange(data);
            }
        };
        const data = (selectedDictionary === "CPT4" ? props.cpt : props.cps).map(v => {
            v.displayName = v.code + "  " + v.description;
            return v
        });
        return <>
            <td>
                {prop.dataItem.inEdit ? (
                    <DictionaryDropDownList
                        {...prop}
                        props={props}
                        displayField="displayName"
                        field="id"
                        style={{
                            width: "100%",
                        }}
                        code={selectedDictionary === "CPT4" ? "AH_CPT" : "AH_HCPCS"}
                        data={data}
                        onChange={handleChange}/>
                ) : (
                    prop.dataItem["code"] ? prop.dataItem["code"] : prop.dataItem["serviceCode"]
                )}
            </td>
        </>;
    }
    const ConditionalCellRender = (td, p) => {
        if (p.field === "service") {
            return InlineDictionaryDropDown(p);
        } else {
            return td;
        }
    };
    const DescriptionTab = (p) => {
        return <td><span>{p.dataItem?.description}</span></td>;
    }
    const toggleDialog = () => {
        setServiceLineContext(null);
        setSelectedDiagnosesCategory("encounter_only")
        setVisibleDialog(!visibleDialog);
    };

    const toggleDialogRow = (p) => {
        setServiceLineContext(p);
        const selectedCodes = (p && p?.dataItem?.diagnoses && p?.dataItem?.diagnoses?.filter(v => v.selected)?.map(v => v.code)) || [];
        setDiagnosesData(
            diagnosesData.map(v => {
                v.selected = selectedCodes.indexOf(v?.code) > -1;
                return v;
            })
        );
        setVisibleDialog(!visibleDialog);
    }
    const selectAllEncounterDiagnoses = (p) => {
        const selectedData = p.dataItem?.diagnoses?.map(v => {
            if (v.isOnlyEncounter) {
                return {
                    ...v, selected: true
                }
            } else {
                return v
            }
        })
        setDiagnosesData(selectedData);
        const data = {
            dataIndex: p.dataIndex,
            dataItem: p.dataItem,
            field: "diagnoses",
            value: selectedData?.filter(v => v.selected)
        }
        p.onChange(data);
    }

    const DiagnosesTab = (p) => {
        const isInEditMode = p.dataItem.hasOwnProperty(editField) ? p.dataItem[editField] : false;
        return <td>
            <Row>
                <Col col={8}>
                    {
                        p.dataItem?.diagnoses && p.dataItem?.diagnoses?.filter(v => v.selected)?.map(v => <tr><span
                            title={v.code}> {v.description} </span>
                            <hr></hr>
                        </tr>)
                    }
                </Col>
                <Col>
                    <Row className={"justify-content-end"} style={{pointerEvents: isInEditMode ? 'block' : 'none'}}>
                        <Button style={{height: 25}} text title={"Add all Linked Diagnoses"}
                                onClick={() => selectAllEncounterDiagnoses(p)} label={"A"}/>
                        <Button style={{height: 25}} text title={"Modify Related Diagnoses"}
                                onClick={() => toggleDialogRow(p)} icon={"pi pi-pencil"}/>
                    </Row>
                </Col>
            </Row>
        </td>;
    }
    const handleSelectDiagnoses = (e, p) => {
        const selectedData = diagnosesData.map(v => {
            if (v.id === p.dataItem.id) {
                v.selected = e.value;
            }
            return v;
        })
        setDiagnosesData(selectedData);
        const data = {
            dataIndex: serviceLineContext.dataIndex,
            dataItem: serviceLineContext.dataItem,
            field: "diagnoses",
            value: selectedData
        }
        serviceLineContext.onChange(data);
    }
    const DiagnosesSelect = (p) => {
        return <td>
            <Checkbox
                checked={p.dataItem.selected}
                onChange={(e) => handleSelectDiagnoses(e, p)}
            />
        </td>;
    }

    const canBeFreeze = () => {
        if (encounterCodingPermission.isView === true && encounterCodingPermission.isEdit === false) {
            return {pointerEvents: 'none', opacity: '0.7', height: "calc(100% - 120px)"};
        } else {
            return {height: "calc(100% - 120px)"};
        }
    }

    return <Dialog header={"Encounter Coding"}
                   visible={props.showWindow[PopupWindows.ENCOUNTER_CODING_WINDOW] && props.encounterContext?.id}
                   onHide={hideDialog}
                   style={{width: "70vw", height: "60vh"}}
                   maximizable
                   footer={<div style={canBeFreeze()}><Row className={"justify-content-end"}>
                       <Button onClick={() => saveEncounterCoding()} label={"Save and Exit"} severity={"success"}/>
                       <Button onClick={hideDialog} severity={"danger"} label={"Cancel"}/>
                   </Row></div>}
    >
        <Container style={canBeFreeze()}>
            <Col col={12}>
                <Row>
                    <Col col={3}>
                        <PatientName/>
                    </Col>
                    <Col col={3}>
                        <DateOfService/>
                    </Col>
                    <Col>
                        <RenderingProvider/>
                    </Col>
                </Row>
            </Col>
            <Col col={12}>
                <Row>
                    <Col col={3}>
                        <b>Encounter ID: </b>{props.encounterContext.encounterId}
                    </Col>
                    <Col col={3}>
                    </Col>
                    <Col>
                        <SupervisingProvider/>
                    </Col>
                </Row>
            </Col>
            <Col col={12}>
                <Row>
                    <Col col={3}/>
                    <Col col={3}/>
                    <Col>
                        <BillingLocation/>
                    </Col>
                </Row>
            </Col>
            <hr></hr>
            <Row className={"gap-2"} style={{paddingBottom: 5}}>
                <Col className={"flex-grow-0"}>
                    <Button onClick={addNew}
                            tooltipOptions={{position: "top"}}
                            tooltip={"Add Service Line"}
                            icon={"pi pi-plus"}
                            text
                            severity={"success"}/>
                </Col>
                <Col col={11} className={"flex-grow-1"}>
                    <RadioGroup data={dictData} defaultValue={"CPT4"} layout={"horizontal"}
                                onChange={handleDictChange}/>
                </Col>

            </Row>
            <Row style={{height: "100%"}}>
                <Grid
                    editField={editField}
                    data={data}
                    onItemChange={itemChange}
                    cellRender={ConditionalCellRender}
                    style={{height: "100%", width: "100%"}}
                    pageable={{
                        pageSizes: [50, 100, 150],
                        buttonCount: 10,
                        info: true,
                        type: "numeric",
                        previousNext: true,
                    }}
                >
                    <GridColumn field="service" title="Service" width={150}/>
                    <GridColumn field="description" title="Description" cell={DescriptionTab}/>
                    <GridColumn field="modifiers" title="Modifiers" cell={InlineTextInput} width={110}/>
                    <GridColumn field="diagnoses" title="Diagnoses" cell={DiagnosesTab} width={550}/>
                    <GridColumn field="units" title="Units" cell={InlineNumberInput} width={110}/>
                    <GridColumn field="Action" title=" " cell={edit} width={180}/>
                </Grid>
            </Row>
            <Row>

                <Dialog header={"Diagnoses"}
                        style={{width: "70vw", height: "50vh"}}
                        onHide={toggleDialog}
                        maximizable
                        footer={<div>
                            <Row className={"justify-content-end"}>
                                <Button onClick={() => toggleDialog()} label={"Done"}
                                        severity={"success"}/>
                            </Row></div>}
                        visible={visibleDialog}>
                    <Row>
                        <Col>
                            <RadioGroup value={selectedDiagnosesCategory} style={{marginLeft: "60%"}}
                                        data={diagnosesDataFor} defaultValue={"encounter_only"}
                                        layout={"horizontal"} onChange={handleDiagnosesChange}/>
                        </Col>
                    </Row>
                    <br></br>
                    <Row style={{height: "calc(100% - 60px)"}}>
                        <Grid style={{height: "100%"}}
                              data={selectedDiagnosesCategory === "encounter_only" ? diagnosesData.filter(v => v.isOnlyEncounter) : diagnosesData}>
                            <GridColumn field="code" title="Code" width={120}/>
                            <GridColumn field="description" title="Description"/>
                            <GridColumn field="select" title="Select" cell={DiagnosesSelect} width={90}/>
                        </Grid>
                    </Row>
                </Dialog>

            </Row>
            <Row>
                <NotificationGroup style={{
                    top: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                }}>
                    <Fade>
                        {success && (
                            <Notification
                                type={{
                                    style: _.isEmpty(errors) ? "success" : "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setSuccess(false)}
                            >
                                <span>{_.isEmpty(errors) ? "" : "Required mandatory fields"}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>
            </Row>

        </Container>
    </Dialog>;

}


function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        patient: state.patient.patientContext,
        popupState: state.popupWindow.state,
        encounterContext: state.encounters.encounterContext,
        codingContext: state.encounters.encounterCoding.codingContext,
        serviceLines: state.encounters.encounterCoding.serviceLines,
        cpt: state.dictionary.dictionaries.AH_CPT,
        cps: state.dictionary.dictionaries.AH_HCPCS,
        medicalProblems: state.patient.medicalProblems,
        encounterMedicalProblems: state.encounters.medicalProblems,
        users: state.user.userList,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        EncounterAction: bindActionCreators(EncounterAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EncounterCodingWindow)