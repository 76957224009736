import {ActionTypes} from "../action/DictionaryTypeAction";


const initialState = {
    dictionaryTypeList: [],
    dictionaryTypeContext: null,
}

export const dictionaryTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DICTIONARY_TYPE_LIST_SUCCESS:
            return {...state, dictionaryTypeList: action.payload.payload, totalCount: action.payload.totalCount};
        case ActionTypes.DICTIONARY_TYPE_LIST_ERROR:
            return {...state, dictionaryTypeListError: action.payload};

        case ActionTypes.SET_DICTIONARY_TYPE_CONTEXT:
            return {...state, dictionaryTypeContext: action.payload}

        case ActionTypes.CLEAR_DICTIONARY_TYPE_CONTEXT:
            return {...state, dictionaryTypeContext: null}
        
        case ActionTypes.CREATE_DICTIONARY_CODE:
            return {...state}
        case ActionTypes.ADD_DICTIONARY:
            return {...state}
        case ActionTypes.UPDATE_DICTIONARY_CODE:
            return {...state}
        default:
            return state
    }

}

