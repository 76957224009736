import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    GET_INSURANCE_PLAN_ALL: "GET_INSURANCE_PLAN_ALL",
    SAVE_INSURANCE_PLAN_ALL: "SAVE_INSURANCE_PLAN_ALL",
    UPDATE_INSURANCE_PLAN_ALL: "UPDATE_INSURANCE_PLAN_ALL",
    SET_INSURANCE_PLAN_CONTEXT: "SET_INSURANCE_PLAN_CONTEXT"
}

function getInsurancePlans(limit, offset, sort, filter) {
    return WrapAuthentication("insurance/plans/all",
        {
            payload: {
                allInsurancePlans: true,
            },
            sort: sort ? {sorts: sort} : null,
            filter: filter,
            limit: limit,
            offset: offset
        },
        ActionTypes.GET_INSURANCE_PLAN_ALL)
}

function saveInsurancePlans(payload) {
    return WrapAuthentication("insurance/plan/save",
        {
            payload: payload
        },
        ActionTypes.SAVE_INSURANCE_PLAN_ALL)
}

function setInsurancePlanContext(insurance) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_INSURANCE_PLAN_CONTEXT, payload: insurance})
    }
}


export const InsuranceActions = {

    getInsurancePlans: getInsurancePlans,
    saveInsurancePlans,
    setInsurancePlanContext

}