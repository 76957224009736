import React from "react";
import {Drawer, DrawerContent, DrawerItem} from "@progress/kendo-react-layout";
import {connect, useSelector} from "react-redux";
import PropTypes from "prop-types";
import AdminDictionaryPage from "../components/admin/AdminDictionaryPage";
import UserListPage from "../components/admin/UserListPage";
import AdminFacilityPage from "../components/admin/AdminFacilityPage";
import AdminManagePermissionPage from "../components/admin/AdminManagePermissionPage";
import AdminFormBuilderPage from "../components/admin/AdminFormBuilderPage";
import AdminFormTester from "../components/form/FormRendererComponent";
import ObservationThresholdsPage from "../components/admin/ObservationThresholds/ObservationThresholdsPage";
import EncounterConfigurationPage from "../components/admin/EncounterConfigurationPage";
import AdminEncounterCodingPage from "../components/admin/EncounterCodingWorklistPage";
import VendorAdminPage from "../components/admin/VendorAdmin/VendorAdminPage";
import AdminInsurancePage from "../components/admin/AdminInsurancePage";
import TenantConfigurationPage from "../components/admin/TenantConfigurationPage";
import {PhixPermissions} from "../config/GlobalConfig";
import {BsBuilding} from "react-icons/bs";
import {FaHeartbeat} from "react-icons/fa";
import {FiUsers} from "react-icons/fi";
import {VscBook} from "react-icons/vsc";
import {HiOutlineDocument, HiOutlineNewspaper} from "react-icons/hi2";
import {GrConfigure, GrFormFolder, GrPlan} from "react-icons/gr";
import {GoBeaker} from "react-icons/go";


function AdminSideMenuComponent(props) {
    const items = [
        {
            text: "Manage Dictionaries",
            id: "dict-list",
            icon: <VscBook/>,
            selected: true,
            permission: {
                viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_DICTIONARIES],
                editPermissions: [PhixPermissions.AH_ADMIN_EDIT_DICTIONARIES]
            }
        },
        {
            separator: true,
        },
        {
            text: "Manage Users",
            id: "users",
            icon: <FiUsers/>,
            permission: {
                viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_USERS],
                editPermissions: [PhixPermissions.AH_ADMIN_EDIT_USERS]
            }
        },
        {
            separator: true,
        },
        {
            text: "Manage Permissions",
            id: "permissions",
            icon: "k-i-lock",
            permission: {
                viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_PERMISSION],
                editPermissions: [PhixPermissions.AH_ADMIN_EDIT_PERMISSION]
            }
        },
        {
            separator: true,
        },
        {
            text: "Manage Facilities",
            id: "facilities",
            icon: <BsBuilding/>,
            permission: {
                viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_FACILITY],
                editPermissions: [PhixPermissions.AH_ADMIN_EDIT_FACILITY]
            }
        },
        {
            separator: true,
        },
        {
            text: "Observation Thresholds",
            id: "observation_thresholds",
            icon: <FaHeartbeat/>,
            permission: {
                viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_OBSERVATION_THRESHOLD],
                editPermissions: [PhixPermissions.AH_ADMIN_EDIT_OBSERVATION_THRESHOLD]
            }
        },
        {
            separator: true,
        },
        {
            text: "Encounter Configuration",
            id: "encounter_configuration",
            icon: <HiOutlineNewspaper/>,
            permission: {
                viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_ENCOUNTER],
                editPermissions: [PhixPermissions.AH_ADMIN_EDIT_ENCOUNTER]
            }
        },
        {
            separator: true,
        },
        {
            text: "Encounter Coding",
            id: "encounter_coding",
            icon: <HiOutlineDocument/>,
            permission: {
                viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_ENCOUNTER_CODING],
                editPermissions: [PhixPermissions.AH_ADMIN_EDIT_ENCOUNTER_CODING]
            }
        },
        {
            separator: true,
        },
        {
            text: "Form Admin",
            id: "form-admin",
            icon: <GrFormFolder/>,
            permission: {
                viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_FORM],
                editPermissions: [PhixPermissions.AH_ADMIN_EDIT_FORM]
            }
        },
        {
            separator: true,
        },
        {
            text: "Lab Admin",
            id: "lab-admin",
            icon: <GoBeaker/>,
            permission: {
                viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_LABS],
                editPermissions: [PhixPermissions.AH_ADMIN_EDIT_LABS]
            }
        },
        {
            separator: true,
        },
        {
            text: "Insurance Plans",
            id: "insurance-plans",
            icon: <GrPlan/>,
            permission: {
                viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_INSURANCE_PLAN],
                editPermissions: [PhixPermissions.AH_ADMIN_EDIT_INSURANCE_PLAN]
            }
        },
        {
            separator: true,
        },
        {
            text: "Tenant Configuration",
            id: "security-settings",
            icon: <GrConfigure/>,
            permission: {
                viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_TENANT_CONFIGURATION],
                editPermissions: [PhixPermissions.AH_ADMIN_EDIT_TENANT_CONFIGURATION]
            }
        },
        {
            separator: true,
        }
    ]
    const permissionsCodes = useSelector(state => state.auth.session?.permissionInfo ? state.auth.session?.permissionInfo : [])
    const [menuItems, setMenuItems] = React.useState(items)

    const handleSelect = (ev) => {
        setSelected(ev.itemTarget.props.id)
    };

    const [expanded, setExpanded] = React.useState(true);

    const [selected, setSelected] = React.useState(null)

    const renderPage = (route) => {
        switch (route) {
            case "dict-list":
                return (<AdminDictionaryPage/>)
            case "users":
                return (<UserListPage/>)
            case "permissions":
                return (<AdminManagePermissionPage/>)
            case "facilities":
                return (<AdminFacilityPage/>)
            case "form-admin":
                return (<AdminFormBuilderPage/>)
            case "form-test":
                return (<AdminFormTester/>)
            case "observation_thresholds":
                return (<ObservationThresholdsPage/>)
            case "encounter_configuration":
                return (<EncounterConfigurationPage/>)
            case "encounter_coding":
                return (<AdminEncounterCodingPage/>)
            case "lab-admin":
                return (<VendorAdminPage/>)
            case "insurance-plans":
                return (<AdminInsurancePage/>)
            case "security-settings":
                return (<TenantConfigurationPage/>)

            default:
                return <div></div>
        }
    }

    const CustomItem = (props) => {
        return (
            <DrawerItem {...props}>
                <span className={"k-icon"}>{props.icon}</span>
                <div className="item-descr-wrap">
                    <div>{props.text}</div>
                    {/*<span className="item-descr">Capital of {props.country}</span>*/}
                </div>
            </DrawerItem>
        );
    };

    return (
        <div>
            <Drawer
                expanded={true}
                position={"start"}
                width={220}
                mode={"push"}
                mini={true}
                miniWidth={45}
                item={CustomItem}
                items={menuItems.map((item, index) => ({
                    ...item,
                    selected: item.id === selected,
                }))}
                onSelect={handleSelect}>
                <DrawerContent style={{width: "100%"}}>
                    {selected ? renderPage(selected) : <></>}
                </DrawerContent>
            </Drawer>

        </div>
    )
}


const AdminSideMenu = connect(mapStateToProps, mapDispatchToProps)(AdminSideMenuComponent)

function AdminPage(props) {

    return <>
        <AdminSideMenu/>
    </>
}


AdminPage.propTypes = {

    adminActions: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        admin: state.admin
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage)