import React, {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {Dropdown} from "primereact/dropdown";
import {PaginatedEndpoint, PostApi} from "../../util/AuthWrapper";


const FacilityDropDownList = (props) => {
    const dispatch = useDispatch()
    const {type, value, defaultValue, required, inputRef, fullObject = false} = props
    const [data, setData] = React.useState([]);
    const [internalValue, setInternalValue] = React.useState(null)
    const [filter, setFilter] = React.useState("")

    const handleChange = (e) => {
        e.preventDefault()
        setInternalValue(e.value)
        if (props?.onChange) {
            if (fullObject)
                props?.onChange(e)
            else {
                e.value = e.value.id
                props?.onChange(e)
            }
        }

    }
    const getData = useCallback(PaginatedEndpoint("facility/get", ""), [filter])

    const getFacilityById = useCallback((facilityId) => PostApi("facility/get/id", {facilityId}), [defaultValue])
    //
    const filterChange = (event) => {
        setFilter(event.filter.value)
    };
    useEffect(() => {
        getData(0, 1000, {sorts: []}, {displayName: filter}).then(d => {
            if (d?.payload) setData(d.payload)
        })
    }, [filter])

    // useEffect(() => {
    //     console.log(internalValue)
    // }, [internalValue]);


    useEffect(() => {
        if (data?.length > 0) {
            if ((!internalValue?.id !== value || !internalValue) && value) {
                let v = data ? data.find((c) => c.id === value) : undefined
                if (v) {
                    setInternalValue(v)
                } else {
                    getFacilityById(value).then((d) => {
                        setInternalValue(d.payload)
                    })
                }

            }
        }
    }, [data, value])

    // useEffect(() => {
    //     console.log(defaultValue)
    //     if (data?.length > 0 && defaultValue) {
    //         if ((!internalValue?.id !== defaultValue  || !internalValue)) {
    //             console.log(data)
    //             let v = data ? data.find((c) => c.id === internalValue) : undefined
    //             console.log(v)
    //             if (v) {
    //                 console.log(`${props.fieldName} : Setting Internal Value: ${JSON.stringify(v)}`)
    //                 setInternalValue(v)
    //             }
    //         }
    //     }
    // }, [data, defaultValue])

    return (
        <Dropdown style={props.style}
                  focusInputRef={inputRef}
                  required={required}
                  resetFilterOnHide
                  showFilterClear
                  disabled={props.disabled}
                  options={data}
                  placeholder={"Choose Facility"}
                  value={internalValue}
                  optionLabel={"displayName"}
                  filter
                  className={props.className}
                  onFilter={filterChange}
                  onChange={handleChange}
                  showClear
        />
    );
};


export default FacilityDropDownList
