import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "primereact/button";
import {Checkbox} from "../../../InputControls/Checkbox";
import {PatientAction} from "../../../../action/PatientAction";
import {PopupWindowActions, PopupWindows} from "../../../../action/PopupWindowAction";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import {Col, Container, Row} from "../../../../layout/GridLayout";
import ShowForPermissionComponent from "../../../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../../../config/GlobalConfig";
import {PermissionsTool} from "../../../../util/PermissionsTool";

const AllergiesComponent = (props) => {
    const dispatch = useDispatch()

    const patientContext = useSelector((state) => state.patient.patientContext)
    // const shouldUpdate = useSelector((state) => state.ComponentUpdate.patientAllergy)
    const patientAllergies = useSelector((state) => state.patient.allergies)
    const permissionInfo = useSelector((state) => state.auth.session.permissions)

    const [active, setActive] = React.useState(true)
    const allergiesPermission = PermissionsTool({
        permissionInfo: permissionInfo,
        viewPermissions: [PhixPermissions.AH_VIEW_ALLERGY],
        editPermissions: [PhixPermissions.AH_EDIT_ALLERGY]
    });

    const initialSort = [
        {
            field: "condition",
            dir: "asc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);
    const [filter, setFilter] = React.useState([]);

    React.useEffect(() => {
        if (patientContext?.patientRoleId) PatientAction.getAllergies(patientContext?.patientRoleId)(dispatch)
    }, [patientContext])


    React.useEffect(() => {
        // console.log(filter)
    }, [filter])


    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });

    const [filterState, setFilterState] = React.useState(false)

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const AddAllergies = () => {
        PopupWindowActions.showWindow(PopupWindows.ADD_ALLERGIES_WINDOW)(dispatch);
    };


    const filterActive = (data) => {
        if (data?.length > 0) {
            if (!active) {
                return data
            } else {
                return data.filter(r => {
                    if (r.dictClinicalStatusCode !== "FHIR_PROBLEM_INACTIVE") return r
                })
            }
        } else return []
    }

    const reactionCell = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        const displayValue = value.map(i => i.displayName).join(", ")
        return (
            <td style={{display: "flex", alignItems: "center", alignContent: "center"}}>
                {displayValue}
            </td>
        );

    }

    return (
        <Container style={{height: "100%", padding: 0, margin: 0}}>
            <Row gutter style={{width: "100%"}}>
                <Col>
                    <Button text
                            tooltip={"Toggle filtering"}
                            size={"small"}
                            onClick={() => setFilterState(!filterState)}
                            icon={(filterState) ? "pi pi-filter-fill" : "pi pi-filter"}/>
                </Col>

                <Col className={"flex-grow-5"}/>
                <Col col={2} className="flex align-items-center flex-grow-0">
                    <Checkbox inputId="viewAll"
                              onChange={(e) => setActive(!active)}
                              checked={active}/>
                    <label style={{fontSize: 14}} htmlFor="viewAll" className="ml-2">Active only</label>
                </Col>

                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_MEDICATION]}>
                    <Col className={"flex-grow-0"}>
                        <Button text="Image" onClick={AddAllergies}
                                tooltip={"Add allergy"}
                                size={"small"}
                                icon={"pi pi-plus"}/>
                    </Col>

                </ShowForPermissionComponent>
            </Row>
            <Row style={{width: "100%", height: "calc(100% - 50px)", padding: 0}}>
                    <Grid
                        {...dataState}
                        style={{height: "100%"}}
                        //TODO: Allergy list here
                        data={filterBy(orderBy(filterActive(patientAllergies), sort), filter)}
                        pageSize={dataState.limit}
                        // style={{height: "calc(100vh - 100px)"}}
                        total={props.totalCount}
                        filterable={filterState}
                        filter={filter}
                        onFilterChange={(e) => setFilter(e.filter)}
                        sortable={true}
                        sort={sort}
                        skip={dataState.skip}
                        onRowClick={(item) => {
                            if (allergiesPermission.isEdit || allergiesPermission.isView) {
                                PopupWindowActions.setWindowState(PopupWindows.ADD_ALLERGIES_WINDOW, {allergyContext: item.dataItem})(dispatch)
                                PopupWindowActions.showWindow(PopupWindows.ADD_ALLERGIES_WINDOW)(dispatch)
                            }
                        }}
                        pageable={{
                            buttonCount: 20,
                            info: true,
                            type: "numeric",
                            pageSizes: true,
                            previousNext: true,
                        }}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}

                        onDataStateChange={dataStateChange}
                    >
                        <GridColumn field="displayName" title="Allergy"/>
                        <GridColumn field="allergyClassName" title="Class"/>
                        <GridColumn field="startDate" title="Start Date"/>
                        <GridColumn field="dictSeverityDisplayName" title="Severity"/>
                        {/* <GridColumn field="reactions" title="Reaction" cell={reactionCell}/> */}
                        <GridColumn field="reactionDisplayNames" title="Reaction"/>
                        <GridColumn field="dictClinicalStatusDisplayName" title="Clinical Status"/>
                    </Grid>
            </Row>

        </Container>
    );
};

export default AllergiesComponent;
