import {Window} from "@progress/kendo-react-dialogs";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import * as React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {QualityMeasureActions} from "../../action/QualityMeasureActions";
import {Button, ToolbarItem} from "@progress/kendo-react-buttons";
import {filterBy} from "@progress/kendo-data-query";
import {Checkbox} from "@progress/kendo-react-inputs";

let initialFilter = {
  logic: "and",
  filters: [
    {
      field: "qmStatus",
      operator: "contains",
      value: "Open",
    },
  ],
};
const QualityComponentWindow = (props) => {
  const [filter, setFilter] = React.useState(initialFilter);
  const [lastfilter, setlastFilter] = React.useState(initialFilter);
  const [filterState, setFilterState] = React.useState(false);
  const [showAllFlag, setshowAllFlag] = React.useState(false);
  const hideDialog = () => {
    props.PopupActions.hideWindow(PopupWindows.QUALITY_MEASURE_WINDOW);
    props.QualityMeasureActions.setMeasureSelection("");
    props.QualityMeasureActions.clearMeasureCohartDetails();
  };
  const { selectedMeasureDisplayName = "" } = props;

  const CustomCell = (props) => {
    const field = props.field || "";
    const status = props.dataItem[field];
    return <td>{status}</td>;
    };
  const showAll = (e)=>{
    let v=e.target.value;
   let u= lastfilter;
    if(v){
    setlastFilter(filter);
    setFilter({
      logic: "and",
      filters: [
        {
          field: "qmStatus",
          operator: "contains",
          value: "",
        },
      ],
    })
   
  }else{
    setFilter(lastfilter);
  }
    setshowAllFlag(v);
   // setFilterState(false);
  }
const listdata=props.qmcCohortDetailslist.map((obj)=>{
  let value = "NULL";
  if (obj.qmStatus == true) {
    value = "Up to Date";
  } else if (obj.qmStatus == false) {
    value = "Open";
  }
  return  {...obj,qmStatus:`${value}`}})

  if (props.showWindow[PopupWindows.QUALITY_MEASURE_WINDOW])
    return (
      <Window
        title={"Quality Measure by Patient:" + selectedMeasureDisplayName}
        onClose={hideDialog}
        initialWidth={950}
        initialHeight={450}
        modal={true}
      >
        <div className="col-12 mb-10" style={{ width: "100%", height: "100%" }}>
          <ToolbarItem
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "4px",
            }}
          >
              {/* <Button
              className="rounded-button"
             
          
              text="Filter Patients"
              onClick={showAll}
            >
              Show All
            </Button> */}
            <Checkbox 
                        label={"Show All"} 
                        onChange={showAll} 
                        value={showAllFlag} />

            <Button
              className="rounded-button"
              togglable={true}
              icon="filter"
              text="Filter Patients"
              onClick={() =>  {setFilterState(!filterState)}}
            >
              Filter List
            </Button>
          </ToolbarItem>

          <Grid
            data={filterBy(listdata, filter)}
            filterable={filterState}
            filter={filter}
            onFilterChange={(e) => {setshowAllFlag(false);setFilter(e.filter)}}
          >
            <GridColumn field="patientName" title="Patient Name" />
            <GridColumn field="providerName" title="Provider" />
            <GridColumn field="manager" title="Manager" />
            <GridColumn field="facility" title="Facility" />
            <GridColumn field="state" title="State" />
            <GridColumn field="qmStatus" title="Status" cell={CustomCell} />
          </Grid>
        </div>
      </Window>
    );
  else return null;
};

QualityComponentWindow.propTypes = {
  showWindow: PropTypes.object,
  PopupActions: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    showWindow: state.popupWindow.showWindow,
    popupState: state.popupWindow.state,
    qmcCohortDetailslist: state.QualityMeasure.MeasureCohortDetails,
    selectedMeasureDisplayName: state.QualityMeasure.selectedMeasureDisplayName,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    QualityMeasureActions: bindActionCreators(QualityMeasureActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualityComponentWindow);
