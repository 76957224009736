export const ActionTypes = {
    UPDATE_PATIENT_LIST: "UPDATE_PATIENT_LIST",
    UPDATE_PATIENT_CONTEXT: "UPDATE_PATIENT_CONTEXT",
    UPDATE_PATIENT_ALLERGIES: "UPDATE_PATIENT_ALLERGIES",
    UPDATE_PATIENT_ORDERS: "UPDATE_PATIENT_ORDERS",

}

function updatePatientList() {
    return async dispatch =>
        dispatch({type: ActionTypes.UPDATE_PATIENT_LIST})
}

function updatePatientContext() {
    return async dispatch =>
        dispatch({type: ActionTypes.UPDATE_PATIENT_CONTEXT})
}

export const ComponentUpdateAction =
    {
        updatePatientList: updatePatientList,
        updatePatientContext: updatePatientContext,

    }