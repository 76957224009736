import {bindActionCreators} from "redux";
import {PatientAction} from "../../../action/PatientAction";
import {CarePlanActions} from "../../../action/CarePlanAction";
import {connect} from "react-redux";
import React from "react";
import {useForm} from "react-hook-form";
import {Field, FieldMedicalProblemDropDown, FieldSection} from "../../InputControls/FormFields";
import {Button} from "primereact/button";
import {WindowActionsBar} from "@progress/kendo-react-dialogs";
import _ from "lodash";
import {Col, Row} from 'react-grid-system';

const ConditionStep = (props) => {
    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        getValues,
        setValue,
        control,
        watch,
        formState: {errors, isDirty, isSubmitting, touchedFields, submitCount},
    } = useForm();

    const {
        medicalProblems,
        patientContext,
        PatientActions,
        CarePlanActions,
        stepIndex,
        nextStep,
        previousStep,
        hideDialog,
        steps,
        setFormFromStep,
        carePlan,
        carePlanEdit,
    } = props

    const saveCondition = (f) => {
        //// console.log(f)
        // // console.log(carePlan)
        CarePlanActions.addProblemToCarePlan(carePlan.id, f.condition.id)
        CarePlanActions.getCarePlanForPatientContext(5, 0, [{field: "displayName", dir: "asc",},])
        //nextStep()
        hideDialog();
    }

    const selectedCondition = watch("condition", {})

    React.useEffect(() => {
        if (carePlanEdit?.condition) {
            let condition = carePlanEdit.condition
            setValue("condition", condition)
        }
    }, [carePlanEdit])


    const [conditionError, setConditionError] = React.useState("Condition is required.")

    const validateCondition = (item) => {
        if (_.isEmpty(item)) {
            if (conditionError !== "Condition is required...")
                setConditionError("Condition is required...")
            return false
        }
        // if (item.carePlan) {
        //     setConditionError("BLAH!")
        //     return false
        // } else {
        //     if (conditionError !== "Condition is required.")
        //         setConditionError("Condition is required.")
        //     return true
        // }


    }


    return <div>

        <FieldSection style={{height: "100%", width: "100%"}}>
            <div className="sub-title">Condition</div>
            <Field col={12} label={"Condition"} field={"condition"} error={errors} register={register}
                   options={{required: conditionError, validate: validateCondition}}
                   filterCarePlan={true}
                   control={control}>
                <FieldMedicalProblemDropDown/>
            </Field>
            {selectedCondition?.displayName &&
                <div>
                    <Row>
                        <Col sm={3}>
                            Display Name:
                        </Col>
                        <Col sm={9}>
                            {selectedCondition?.displayName}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            Description:
                        </Col>
                        <Col sm={9}>
                            {selectedCondition?.description}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            Category:
                        </Col>
                        <Col sm={9}>
                            {selectedCondition?.dictProblemCategoryDisplayName}
                        </Col>
                    </Row>
                </div>
            }
        </FieldSection>
        <WindowActionsBar>
            <Button style={{backgroundColor: "#E06666"}} onClick={hideDialog}>
                Cancel
            </Button>
            <Button themeColor={"primary"} onClick={handleSubmit(saveCondition)}>
                Add
            </Button>
        </WindowActionsBar>
    </div>

}

function mapStateToProps(state) {
    return {
        carePlan: state.patient.carePlan,
        carePlanEdit: state.patient.carePlanEdit,
        patientContext: state.patient.patientContext,
        medicalProblems: state.patient.medicalProblems,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        CarePlanActions: bindActionCreators(CarePlanActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConditionStep)