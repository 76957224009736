import {WrapAuthentication} from "../util/AuthWrapper";
import {ActionTypes as UpdateAction} from "./ComponentUpdateAction";

export const ActionTypes = {
    CREATE_LAB_ORDER: "CREATE_LAB_ORDER",
    GET_LAB_ORDER: "GET_LAB_ORDER",
    UPDATE_LAB_ORDER: "UPDATE_LAB_ORDER"
}

function createLabOrder(payload) {
    return WrapAuthentication("patient/order/create",
        {payload},
        ActionTypes.CREATE_LAB_ORDER,
        {},
        [UpdateAction.UPDATE_PATIENT_ORDERS])
}

function updateLabOrder(payload) {
    return WrapAuthentication("patient/order/update",
        {payload},
        ActionTypes.UPDATE_LAB_ORDER,
        {},
        [UpdateAction.UPDATE_PATIENT_ORDERS])
}

function getLabOrder(payload) {
    return WrapAuthentication("patient/order/get",
        {payload},
        ActionTypes.GET_LAB_ORDER
    )
}

function createLabOrders(payload) {
    return WrapAuthentication("patient/orders/create",
        {payload},
        ActionTypes.CREATE_LAB_ORDER,
        {},
        [UpdateAction.UPDATE_PATIENT_ORDERS])
}

export const LabOrderActions =
    {
        createLabOrders: createLabOrders,
        createLabOrder: createLabOrder,
        getLabOrder: getLabOrder,
        updateLabOrder: updateLabOrder
    }