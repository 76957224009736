import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {PatientAction} from "../../action/PatientAction";
import {EncounterAction} from "../../action/EncounterAction";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {Button} from "primereact/button";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {useForm} from "react-hook-form";
import {Col} from "react-grid-system";
import {PhixPermissions} from "../../config/GlobalConfig";
import {Dialog} from "primereact/dialog";
import {Container} from "../../layout/GridLayout";
import {v4 as uuidv4} from "uuid";
import {
    FormColumn,
    FormContainer,
    FormDateSelector,
    FormDictionaryDropdown,
    FormMultiProblemCheckboxSelector,
    FormRow,
    FormTextInput
} from "../InputControls/NewFormFields";
import {SIMPLE_DATE_FORMAT} from "../../util/DateTools";
import {FilterDefaultValuesFromForm} from "../../util/MiscTools";
import {ProgressSpinner} from 'primereact/progressspinner';


const defaultValues = {
    id: null,
    startDate: null,
    endDate: null,
    description: null,
    note: null,
    dictSourceCategoryId: null,
    dictProblemCategoryId: null,
    dictProblemStatusId: null,
    diagnosisCode: [],
}
const AddMedicalProblemWindow = (props) => {
    const form = useForm({defaultValues: defaultValues});
    const {
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch,
    } = form
    const dispatch = useDispatch()
    const showWindow = useSelector(state => state.popupWindow.showWindow)
    const patient = useSelector(state => state.patient.patientContext)
    const encounterContext = useSelector(state => state.encounters.encounterContext)
    const popupState = useSelector(state => state.popupWindow.state)
    const medicalProblemsSel = useSelector(state => state.encounters.medicalProblems)
    const problemContext = useSelector(state => state.popupWindow.state?.[PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW]?.problemContext)

    // const [problemContext, setProblemContext] = React.useState({})
    const [isReset, setIsReset] = React.useState(true)

    const resetForm = () => {
        reset(defaultValues)
    }
    const hideDialog = async () => {
        resetForm()
        await PopupWindowActions.hideWindow(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW, true)(dispatch)

    };

    React.useEffect(() => {
        if (popupState[PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW]?.problemContext && showWindow[PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW]) {
            if (problemContext?.id)
                reset(problemContext)
        }
    }, [popupState])


    async function saveMedicalProblemAndClear(form) {
        let payload = FilterDefaultValuesFromForm(form, defaultValues)
        payload.diagnosisCode = payload?.diagnosisCode.map(o => o?.id)
        payload.patientRoleId = patient.patientRoleId
        if (!form?.id) {
            const _id = uuidv4()
            payload.id = _id
            await reset(defaultValues);
            setIsReset(false)
            await PatientAction.createMedicalProblem(payload)(dispatch)
            await EncounterAction.setMedicalProblemId({id: _id})(dispatch)
            await EncounterAction.saveEncounterMedicalProblem({
                encounterId: encounterContext.id,
                issueId: _id
            })(dispatch)
            await PopupWindowActions.setWindowState(PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW, {problemContext: null})(dispatch)
            setIsReset(true)
        }
    }

    async function saveMedicalProblem(form) {
        let payload = FilterDefaultValuesFromForm(form, defaultValues)
        payload.diagnosisCode = payload?.diagnosisCode.map(o => o?.id)
        payload.patientRoleId = patient.patientRoleId
        if (form?.id) {
            await PatientAction.updateMedicalProblem(payload)(dispatch)
            await EncounterAction.setMedicalProblemId({id: form.id})(dispatch)
            if (!medicalProblemsSel?.find(v => v.id === form?.id))
                await EncounterAction.saveEncounterMedicalProblem({
                    encounterId: encounterContext.id,
                    issueId: form.id
                })(dispatch)
            await hideDialog()
        } else {
            const _id = uuidv4()
            payload.id = _id
            await PatientAction.createMedicalProblem(payload)(dispatch)
            await EncounterAction.setMedicalProblemId({id: _id})(dispatch)
            await EncounterAction.saveEncounterMedicalProblem({
                encounterId: encounterContext.id,
                issueId: _id
            })(dispatch)
            await hideDialog()
        }
    }

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'diagnosisCode') {
                let v = value?.diagnosisCode?.map(d => d?.displayName).join("; ")
                if (v && v.length > 0) setValue("description", v)
            }

        })
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <Dialog header={problemContext?.id ? "Edit Medical Problems" : "Add Medical Problems"}
                onHide={hideDialog}
                style={{width: 800}}
                maximizable
                footer={<Container className={"justify-content-end"}>
                    {!problemContext?.id ? <Button primary onClick={handleSubmit(saveMedicalProblemAndClear)}
                                                   label={"Save and Add New"}/> : <></>}
                    <Button primary onClick={handleSubmit(saveMedicalProblem)}
                            label={problemContext?.id ? "Update" : "Save and Close"} severity={"success"}/>
                    <Button onClick={hideDialog} severity={"danger"} label={"Cancel"}/>
                </Container>}
                visible={showWindow[PopupWindows.ADD_MEDICAL_PROBLEM_WINDOW]}>
            <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_MEDICAL_PROBLEM]} isNonEditable={true}>
                {isReset ? <FormContainer style={{width: "100%"}} form={form}>
                    <FormRow>
                        <Col col={6}/>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Clinical Status"}
                                field={"dictProblemStatusId"}
                                rules={{required: "Clinical Status is required."}}
                                code={Dictionary.PROBLEM_STATUS}
                                defaultItemCode={"FHIR_PROBLEM_ACTIVE"}
                            />
                        </FormColumn>
                    </FormRow>

                    <FormRow>
                        <Col col={6}/>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Problem Category"}
                                field={"dictProblemCategoryId"}
                                rules={{required: "Problem Category is required."}}
                                code={Dictionary.PROBLEM_CATEGORY}
                                defaultItemCode={"FHIR_CATEGORY_DIAGNOSIS"}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={12} style={{display: "flex", flexGrow: 1}}>
                            <FormMultiProblemCheckboxSelector
                                showCode={true}
                                fullObject
                                label={"Diagnosis Codes"}
                                field={"diagnosisCode"}
                                code={Dictionary.ICD10}
                                useIndex/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormTextInput
                                rules={{required: "Problem Description is required."}}
                                label={"Problem Description"}
                                field={"description"}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDateSelector showTime={false}
                                              dateFormat={SIMPLE_DATE_FORMAT}
                                              defaultNow
                                              label={"Onset Date"}
                                              rules={{required: "Onset Date is required."}}
                                              field={"startDate"}/>
                        </FormColumn>
                        <FormColumn col={6}>
                            <FormDateSelector showTime={false}
                                              dateFormat={SIMPLE_DATE_FORMAT}
                                              label={"Abatement Date"}
                                              field={"endDate"}/>
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={6}>
                            <FormDictionaryDropdown
                                label={"Source of Info"} field={"dictSourceCategoryId"}
                                code={Dictionary.AH_CONDITION_SOURCE}
                                defaultItemCode={"AH_CONDITION_SOURCE"}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn col={12}>
                            <FormTextInput
                                textArea
                                field={"note"}
                                label={"Notes"}/>

                        </FormColumn>
                    </FormRow>
                </FormContainer> : <ProgressSpinner/>}
            </ShowForPermissionComponent>
        </Dialog>
    )
};


export default AddMedicalProblemWindow
