import {ActionTypes} from "../action/ConfigurationAction";

const initialState = {
    config: {},
    tenant: {},
    eRx: {},

}

function configArrayToObject(payload) {
    let config = {}
    for (let i = 0; i < payload.length; ++i) {
        config[payload[i].key] = payload[i].value
    }
    return config
}

export const configurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_TENANT_CONFIGURATION:
            return {...state, tenant: configArrayToObject(action.payload.payload)}
        case ActionTypes.GET_PERMISSION_GROUP_CONFIG:
            return {...state, config: configArrayToObject(action.payload.payload)}
        default:
            return state
    }

}