import {connect} from "react-redux";
import React from "react";
import {bindActionCreators} from "redux";
import {ScheduleActions} from "../../action/SchedulerAction";
import {OpenEmrActions} from "../../action/OpenEmrAction";
import GlobalStyles from "../../config/GlobalStyles";
import TileHeader from "../../layout/TileHeader";
import {Col, Container, Row} from "react-grid-system";

const styles = {
    row: {
        width: "100%",
    },
    col4: {
        width: "30%",
        float: 'left',
        margin: '0 0.5%',
        padding: '12px 0px 15px 25px',
        background: ' #7585c2',
        color: '#FFFFFF',
        position: 'relative',
        minHeight: '45px',
    },
    col6: {
        width: "55%",
        float: 'left',
        margin: '0 0 0 6px',
        padding: '15px 0px 15px 25px',
        background: ' #d6dbe9',
        color: '#FFFFFF',
        position: 'relative',
        minHeight: '45px',
    },
    titleh3: {
        color: '#7485c2',
        fontSize: '12px',
        padding: '0',
        margin: '5px 0',
        textTransform: 'uppercase',
    }
}

const ClinicalProgramComponent = (props) => {

    return (
        <div style={{...GlobalStyles.tileWrapper}}>
            <TileHeader title="Clinical Programs"
                        style={{fontSize: "15px", background: "rgb(250, 250, 250)", color: "rgb(117, 133, 194)"}}/>
            <Container style={{height: "100%", paddingTop: 0, paddingRight: 10, paddingBottom: 10, paddingLeft: 0}} fluid={true}>
                <Row style={{margin: 0, height: "100%", width: "100%"}}>
                    <Col sm={1} >
                        <div style={{paddingLeft: 0, height: "100%", width: "20px", background: "rgb(117, 133, 194)"}}/>
                    </Col>
                    <Col sm={11} style={{background: "rgb(214, 219, 233)"}}>
                        <Container fluid style={{
                            height: "100%",
                            width: "100%",
                        }}>
                            <Row style={{paddingTop: 20}}>
                                <Col sm={8}>
                                    <span style={{color: "green", paddingBottom: 2, paddingRight: 5}} className={"k-icon k-i-checkbox-checked"}/>
                                    <b style={{color: "green", fontSize: 14}}>Enrolled in Loneliness Program</b>

                                </Col>
                                <Col sm={9}>
                                    <span style={{color: "red", paddingBottom: 2, paddingRight: 5}} className={"k-icon k-i-checkbox"}/>
                                    <b style={{color: "red" , fontSize: 14}}>Eligible for case management</b>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

function mapStateToProps(state) {
    return {
        patientScheduleData: state.schedule.patientScheduleData,
        patientContext: state.patient.patientContext,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ScheduleActions: bindActionCreators(ScheduleActions, dispatch),
        OpenEmrActions: bindActionCreators(OpenEmrActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalProgramComponent)
