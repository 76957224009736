import * as React from "react";
import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import {PatientAction} from "../action/PatientAction";
import {CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton} from "@mui/material";
import {EncounterAction, EncounterActionsPaginated} from "../action/EncounterAction";
import ShowForPermissionComponent from "./shared/ShowForPermissionComponent";
import {Colors, PhixPermissions} from "../config/GlobalConfig";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Unstable_Grid2';
import PhixDataGrid from "../typescript/component/data/PhixDataGrid";
import {CheckBoxOutlineBlank, CheckBoxOutlined} from "@mui/icons-material";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {CallPoint, MessageType, SendToExternalAdapter} from "../action/ExternalAdapterAction";
import FacilityDropDownList from "./InputControls/NewFacilityDropDownList";

const EncounterCodingListComponent = (props) => {
    const userContext = useSelector(state => state.user.userContext)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [update, setUpdate] = useState(0)
    const [selectedEncounters, setSelectedEncounters] = React.useState([])
    const [filterState, setFilterState] = React.useState({encounterCodingSaved: true, isLocked: true, encounterReady : null})
    const [ready, setReady] = React.useState(false);
    const getdata = useCallback(EncounterActionsPaginated.getEncountersPaginated(null), [userContext, filterState, update, loading])
    const [facilityFilter, setFacilityFilter] = React.useState(null)
    const [autoPoll, setAutoPoll] = React.useState(false)


    const CommandCell = (item) => {
        return <td className="k-command-cell">
            <IconButton
                onClick={() => {
                    const dataItem = item.dataItem ? item.dataItem : {};
                    EncounterAction.setEncounterContext({...dataItem, "isAdmin": true})(dispatch)
                    if (item?.dataItem?.patientRoleId) PatientAction.getMedicalProblems(item?.dataItem?.patientRoleId)(dispatch)
                }
                }
            ><InfoIcon/></IconButton>
        </td>;
    }

    let columns = [
        {
            field: "encounterDate",
            headerName: "Service Date",
            flex: 1,
            // valueFormatter: (e) => FormatUtcDateTimeToLocalZoneStringWithFormat(e.encounterDate)
        },
        {
            field: "patientLastName",
            headerName: "Patient",
            valueGetter: (params) => `${params.row.patientLastName || ''} ${params.row.patientFirstName || ''}`,
            flex: 1
        },
        {field: "facilityDisplayName", headerName: "Facility", flex: 1},
        {field: "encounterNumber", headerName: "Encounter Number", flex: 1},
        {
            field: "providerLastName",
            headerName: "Performed By",
            valueGetter: (params) => `${params.row.providerLastName || ''}, ${params.row.providerFirstName || ''}`,
            flex: 1
        },
        {
            flex: 1,
            field: "isLocked",
            headerName: "Locked Status",
            renderCell: (item) => {
                return <td>{(item.row.isLocked ? <LockIcon style={{color: Colors.GREEN}}/> :
                    <LockOpenIcon style={{color: Colors.RED}}/>)}
                </td>
            }

        },
        {
            flex: 1,
            field: "encounterCodingSaved",
            headerName: "Coding Saved",
            renderCell: (item) => {
                return <td>{(item.row.encounterCodingSaved ? <CheckBoxOutlined style={{color: Colors.GREEN}}/> :
                    <CheckBoxOutlineBlank style={{color: Colors.RED}}/>)}
                </td>
            }

        },
        {
            flex: 1,
            field: "dictEncounterBillingStatusDisplayName",
            headerName: "Billing Status",
            renderCell: (item) => {
                //                console.log(item)
                let row = item.row
                if (!row.dictEncounterBillingStatusDisplayName && row.encounterCodingSaved && row.isLocked)
                    return <td style={{color: Colors.GREEN}}>Ready to Bill</td>
                else if (!row.dictEncounterBillingStatusDisplayName && (!row.encounterCodingSaved && row.isLocked))
                    return <td style={{color: Colors.RED}}>Coding Required</td>
                else if (!row.dictEncounterBillingStatusDisplayName && (row.encounterCodingSaved && !row.isLocked))
                    return <td style={{color: Colors.RED}}>Lock Encounter</td>
                else if (!row.dictEncounterBillingStatusDisplayName && (!row.encounterCodingSaved && !row.isLocked))
                    return <td style={{color: Colors.RED}}>Not Ready</td>
                else
                    return <td>{row.dictEncounterBillingStatusDisplayName}</td>
            },
            // valueGetter: (params) => {
            //     if (params.value == null) {
            //         return "Not Started"
            //     } else
            //         return params.value
            // }
        },
        {
            field: "actions",
            headerName: "Actions",
            renderCell: CommandCell
        }


    ]

    function sendToBilling() {
        setLoading(true)
        SendToExternalAdapter(CallPoint.BILLING_ADAPTER, MessageType.EncounterCreateDftP03Message, selectedEncounters).then(data => {
            setUpdate(update + 1)
            setLoading(false)
        })
        setUpdate(update + 1)


    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer container>
                <GridToolbarColumnsButton/>
                {/*<GridToolbarFilterButton/>*/}
                <GridToolbarDensitySelector/>
                {/*<GridToolbarExport/>*/}
                <FormControlLabel control={<Checkbox checked={filterState.isLocked} onChange={e => setFilterState({
                    ...filterState,
                    isLocked: e.target.checked
                })}/>}
                                  label={<Typography variant={"button"} color={"primary"}>Locked</Typography>}/>
                <FormControlLabel label={<Typography variant={"button"}
                                                     color={"primary"}>Coded</Typography>}
                                  control={<Checkbox checked={filterState.encounterCodingSaved}
                                                     onChange={e => setFilterState({
                                                         ...filterState,
                                                         encounterCodingSaved: e.target.checked
                                                     })}/>}/>
                {selectedEncounters.length > 0 &&
                    <Button startIcon={<CreditCardIcon/>} onClick={sendToBilling}>Send to Billing</Button>}
                <FormControlLabel label={<Typography variant={"button"}
                                                     color={"primary"}>Ready</Typography>}
                                  control={<Checkbox checked={ready}
                                                     onChange={e => {
                                                         if (e.target.checked) {
                                                             setFilterState({...filterState, encounterReady: true})
                                                             setReady(true)
                                                         }
                                                         else {
                                                             setFilterState({...filterState, encounterReady: null})
                                                             setReady(false)
                                                         }


                                                     }}/>}/>
                <FormControlLabel label={<Typography variant={"button"}
                                                     color={"primary"}>Refresh</Typography>}
                                  control={<Checkbox checked={autoPoll}
                                                     onChange={e => setAutoPoll(e.target.checked)}/>}/>

                <FacilityDropDownList fullObject
                                      style={{width: "300px"}}
                                      value={facilityFilter}
                                      onChange={(e) => {
                                          if (e.value?.id) {
                                              setFilterState({...filterState, facilityId: e.value.id})
                                              setFacilityFilter(e.value.id)
                                          } else {
                                              setFilterState({...filterState, facilityId: null})
                                              setFacilityFilter(null)
                                          }
                                      }}
                />




                <GridToolbarQuickFilter style={{marginLeft: "auto"}} debounceMs={300}/>

            </GridToolbarContainer>
        );
    }

    return (<>
        <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_VIEW_ENCOUNTER_CODING]} component>
            <Dialog open={loading}
                    onClose={() => setLoading(false)}>
                <DialogTitle>Sending Encounters to Billing please wait...</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <CircularProgress color="success" style={{margin: "auto"}}/>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Grid container style={{height: "90vh", width: "82vw"}}>

                <PhixDataGrid
                    enablePolling={autoPoll}
                    rows={[]}
                    staticFilter={filterState}
                    style={{width: "80vw", padding: 30, marginLeft: 10, marginTop: 10}}
                    columns={columns}
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={m => setSelectedEncounters(m)}

                    // isRowSelectable={item => item.row.encounterCodingSaved && item.row.isLocked}
                    loadingIndicator
                    apiFunction={getdata}
                    checkboxSelection
                    slots={{toolbar: CustomToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: {debounceMs: 500},
                        },
                    }}
                />

            </Grid>
        </ShowForPermissionComponent>
    </>);
};
export default EncounterCodingListComponent