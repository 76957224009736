import {Col, Container, Row} from "react-grid-system";
import PropTypes from "prop-types";

export const PermissionsTool = (props) => {
    const viewPermissions = props?.viewPermissions || [];
    const editPermissions = props?.editPermissions || [];
    const userPermissions = props?.permissions || props.permissionInfo || [];
    return {
        isView: viewPermissions.some((p1) => userPermissions.includes(p1)),
        isEdit: editPermissions.some((p2) => userPermissions.includes(p2))
    }
};

PermissionsTool.propTypes = {permissions: PropTypes.array.isRequired}
export const NoPermissions = () => {
    return <Container>
        <Row style={{paddingTop: 30}}>
            <Col style={{alignContent: "center", alignItems: "center", width: "100%"}} sm={2}>
                <span className={"k-icon k-i-lock"} style={{fontSize: 48}}/>
            </Col>
            <Col sm={10}>
                <b>User Does not have permission to view. Please contact your system admin.</b>
            </Col>
        </Row>
    </Container>;
}