import {bindActionCreators} from "redux";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {connect} from "react-redux";
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle
} from "@progress/kendo-react-charts";
import * as React from "react";
import {Dialog} from "primereact/dialog";
import {IoCaretBackCircleOutline, IoCaretForwardCircleOutline} from "react-icons/io5";
import {ToolbarSpacer} from "@progress/kendo-react-buttons";


const ChartContainer = (props) => {
    const {data, categories, title} = props

    const hideDialog = () => {
        props.PopupActions.hideLineChart()
    }

    const DatePager = () => {
        return <div className={"k-pager k-pager-md k-grid-pager"}>
                   <IoCaretBackCircleOutline style={{height: 45, width:45}} onClick={() => props.setOffset(props.offset + 9)}/>
                   <ToolbarSpacer/>
                   <span>* indicates an average of multiple readings</span>
                   <ToolbarSpacer/>
                   <IoCaretForwardCircleOutline style={{height: 45, width:45}} onClick={() => {
                                console.log(props.offset)
                                if (props.offset > 0)
                                    props.setOffset(props.offset - 9)
                                }}/>
               </div>
    }

    return (
        <Dialog visible={props.showWindow[PopupWindows.LINE_CHART_WINDOW]}
                header={"Chart View"}
                onHide={hideDialog} maximized={true} maximizable>
            <Chart>
                <ChartTitle text={title}/>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem title={"Date"} categories={categories}/>
                </ChartCategoryAxis>
                <ChartSeries>
                    <ChartSeriesItem type="line" data={data}/>
                </ChartSeries>
            </Chart>
            <DatePager/>
        </Dialog>
    );
}


function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        data: state.popupWindow.lineChart.data,
        categories: state.popupWindow.lineChart.categories,
        title: state.popupWindow.lineChart.title,
        offset: state.popupWindow.lineChart.offset,
        setOffset: state.popupWindow.lineChart.setOffset,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartContainer)