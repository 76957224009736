import {useDispatch, useSelector} from "react-redux";
import Divider from '@mui/material/Divider';
import React, {useRef, useState} from "react";
import {Avatar} from "@progress/kendo-react-layout";
import {Label} from "@progress/kendo-react-labels";
import {Button} from "primereact/button";
import {PatientAction} from "../../action/PatientAction";
import {PersonActions} from "../../action/PersonAction";
import TileHeader from "../../layout/TileHeader";
import GlobalStyles from "../../config/GlobalStyles";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {Col, Container, Row} from '../../layout/GridLayout';
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {Colors, PhixPermissions} from "../../config/GlobalConfig";
import {useHistory} from "react-router-dom";
import {Dialog} from "@progress/kendo-react-dialogs";
import {Checkbox} from "@progress/kendo-react-inputs";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import ShowForPermissionComponent, {usePermission} from "../shared/ShowForPermissionComponent";
import {HiIdentification} from "react-icons/hi2";
import {stitchOptionalStrings} from "../../util/MiscTools";
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import {IconButton, List, ListItem, ListItemText} from "@mui/material";

const styles = {
    goBtn: {
        maxWidth: "150px",
        // height: "auto",
        fontWeight: "bolder",
        fontSize: "large",
    },
    messageAvatar: {
        minHeight: "60px",
        minWidth: "60px",
        maxWidth: "60px",
        maxHeight: "60px",
        width: "100%",
        background: "#FFF",
        padding: "0px",
        marginTop: "5px",
        border: " solid 1px #7385c1",
    },
    messageAvatarImg: {
        width: "100%",
    },
    patientDetails: {
        height: "100px"
    }
}


const PatientDemographicsComponent = (props) => {
    const dispatch = useDispatch()
    const patientContext = useSelector(state => state.patient.patientContext)
    const contactPreferences = useSelector(state => state.person.contactPreferences)
    const patientRelationshipContext = useSelector(state => state.patient.patientRelationshipContext)
    const history = useHistory()
    const menu = useRef(null)
    const patientAttributes = useSelector(state => state.patient.patientRoleAttributes)
    const viewMyPatients = usePermission([PhixPermissions.AH_VIEW_MY_PATIENTS])
    const viewAllPatients = usePermission([PhixPermissions.AH_VIEW_PATIENT])
    const viewCareTeam = usePermission([PhixPermissions.AH_VIEW_CARETEAM])
    const [menuItems, setMenuItems] = useState([])
    const [contactPref, setContactPref] = useState("");
    const [leaveMsg, setLeaveMsg] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [relationshipsData, setRelationshipsData] = useState([]);
    const [showAllRelationships, setShowAllRelationships] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    React.useEffect(() => {
        if (patientContext?.personId) {
            PatientAction.getPatientRelationships({
                patientRoleId: patientContext?.patientRoleId,
                showAll: showAllRelationships
            })(dispatch);
            PersonActions.getContactPreferences({personId: patientContext?.personId})(dispatch);
            if (patientContext?.patientRoleId)
                PatientAction.getPatientRoleAttributes({patientRoleId: patientContext?.patientRoleId})(dispatch)
        }
    }, [patientContext?.personId]);

    React.useEffect(() => {
        PatientAction.getPatientRelationships({
            patientRoleId: patientContext?.patientRoleId,
            showAll: showAllRelationships
        })(dispatch);
    }, [showAllRelationships]);

    React.useEffect(() => {
        if (showDialog) {
            PatientAction.getPatientRelationships({
                patientRoleId: patientContext?.patientRoleId,
                showAll: showAllRelationships
            })(dispatch);
        } else {
            PatientAction.clearPatientRelationships()(dispatch);
        }
    }, [showDialog]);

    React.useEffect(() => {
        setRelationshipsData(patientRelationshipContext);
    }, [patientRelationshipContext]);

    React.useEffect(() => {
        let cp = [];
        if (contactPreferences)
            for (let k in contactPreferences) {
                if (contactPreferences[k] === true) {
                    switch (k) {
                        case "text":
                            cp.push("Text");
                            break;
                        case "email":
                            cp.push("Email");
                            break;
                        case "phoneCall":
                            cp.push("Phone Call");
                            break;
                        case "leaveMsgViaPhone":
                            setLeaveMsg(true);
                            break;
                    }
                } else if (k === "leaveMsgViaPhone") {
                    setLeaveMsg(false);
                }
            }
        setContactPref(cp.join(", "));
    }, [contactPreferences])

    const toggleDialog = () => {
        setShowAllRelationships(false);
        setShowDialog(!showDialog);
    }

    const careTeamMenuItem = {
        label: "View Care Team",
        command: (e) => PopupWindowActions.showWindow(PopupWindows.CARE_TEAM_MENU)(dispatch)
    }

    const openEditDemographics = async () => {
        await PatientAction.setEditPatientContext(patientContext)(dispatch)
        await PopupWindowActions.showWindow(PopupWindows.CREATE_PATIENT_WINDOW, patientContext)(dispatch)
    }
    const editDemographicsMenuItem = {
        label: "Edit Patient Demographics",
        command: () => openEditDemographics()
    }


    React.useEffect(() => {
        if ((viewMyPatients || viewAllPatients) && viewCareTeam) {
            setMenuItems([careTeamMenuItem, editDemographicsMenuItem])
        } else if (viewMyPatients || viewAllPatients) {
            setMenuItems([editDemographicsMenuItem])
        } else if (viewCareTeam) {
            setMenuItems([careTeamMenuItem])
        }
    }, [viewMyPatients, viewCareTeam, viewAllPatients, patientContext]);

    const CustomTitleBar = () => {
        return (<Row style={{width: "100%"}}>
            <Col>
                Emergency Contact
            </Col>
            <Col className={"flex-grow-1"}/>
            <Col>
                <Checkbox value={showAllRelationships}
                          onChange={() => setShowAllRelationships(!showAllRelationships)}
                          label={"Show all relationships"}/>
            </Col>
        </Row>);
    };

    const RelationshipRoleCell = (p) => {
        if (p?.dataItem?.isPrimary === true) {
            return <td>
                <tr><span>{p?.dataItem?.role?.map(v => v?.displayName).join(", ")}</span></tr>
                <tr><Button title={"Primary"}
                            style={{width: "22%", height: "50%", background: "red"}}>P</Button></tr>
            </td>
        } else {
            return <td>
                <Row>
                    <Col><span>{p?.dataItem?.role?.map(v => v?.displayName).join(", ")}</span></Col>
                </Row>
            </td>
        }
    }


    const SharePHICell = (p) => {
        return <td>
            <Row>
                <Col><span>{p?.dataItem?.canWeDiscussMedicalConditions === true ? "Yes" : "No"}</span></Col>
            </Row>
        </td>;
    }

    const NameCell = (p) => {
        var names = [];
        if (p?.dataItem?.firstName) {
            names.push(p?.dataItem?.firstName)
        }
        if (p?.dataItem?.lastName) {
            names.push(p?.dataItem?.lastName)
        }
        return <td>
            <Row>
                <Col><span>{names.join(" ")}</span></Col>
            </Row>
        </td>;
    }

    const ShowRelationships = () => {
        return (
            <Dialog width={1000} title={<CustomTitleBar/>} onClose={toggleDialog}>
                <Grid
                    style={{height: "100%", width: "100%"}}
                    data={relationshipsData}>
                    <GridColumn title="Name" cell={NameCell}/>
                    <GridColumn title="Relationship Role" cell={RelationshipRoleCell}/>
                    <GridColumn field="relationshipTypeDisplayName" title="Relationship Type" width={150}/>
                    <GridColumn title="Phone" field="preferredPhoneNo"/>
                    <GridColumn title="Email" field="preferredEmailId"/>
                    <GridColumn title="Share PHI?" cell={SharePHICell} width={100}/>
                </Grid>

            </Dialog>
        );

    }
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    React.useEffect(() => {
        if (!patientContext?.patientRoleId) {
            history.push("/");
        }
    }, [patientContext])
    return (
        <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_PATIENT, PhixPermissions.AH_VIEW_MY_PATIENTS]}
                                    component>
            <TileContainer>
                <TileHeader style={GlobalStyles.tileHeaderBlueWithBlack}
                            title={<Container className={"align-items-center"} style={{width: "100%"}}>
                                <Col col={1} className={"flex-grow-0"} style={{paddingLeft: 5, paddingTop: 1}}>
                                    <HiIdentification size={"28"} style={{paddingBottom: 3, color: "white"}}/>
                                </Col>

                                <Col className={"flex-grow-1"} style={{paddingLeft: 0, color: "white"}}>
                                    {stitchOptionalStrings([patientContext?.firstName, patientContext?.lastName, patientContext?.medicalRecordNumber])}
                                </Col>

                            </Container>}>

                    <Col className={"flex-grow-0"}>
                        <Button text
                                tooltipOptions={{position: "top"}}
                                tooltip={"Edit Patient Info"}
                                onClick={openEditDemographics}
                                size={"colall"}
                                style={{height: 25, width: 25, color: "white"}}
                                rounded
                                icon={"pi pi-pencil"}/>
                    </Col>
                    {patientAttributes?.length > 0 ?
                        <Col col={1} className={"flex-grow-0"} style={{marginRight: 5}}>
                            <IconButton size="small"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                            >
                                <InfoIcon className={"fa-pulse"} style={{height: 25, width: 25, color: 'red'}}/>
                            </IconButton>
                            <Popover
                                id="mouse-over-popover"
                                sx={{
                                    pointerEvents: 'none',
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus>
                                <List>
                                    <>
                                        {patientAttributes.map((p) => {
                                            return (
                                                <>
                                                    <ListItem>
                                                        <ListItemText primary={p.displayName} key={p.displayName}
                                                                      secondary={p?.note}/>
                                                    </ListItem>
                                                </>
                                            )
                                        })}
                                    </>
                                </List>
                            </Popover>
                        </Col>
                        : null
                    }


                </TileHeader>
                <TileBody containerStyle={{width: "100%", height: "calc(100% -10px)"}}>
                    <Col className={"flex-grow-0 justify-content-center align-content-center"} style={{padding: 10}}>
                        <Avatar type="icon" style={styles.messageAvatar}>
                            <img src="../images/user-pic.jpg" style={styles.messageAvatarImg}/>
                        </Avatar>
                    </Col>
                    <Col className={"flex-grow-1"}
                         style={{fontSize: "12px", height: "calc(100% - 5px", paddingBottom: 0}}>
                        <Row>
                            <Col col={5}>
                                <Label><strong>Gender:</strong></Label>
                                {patientContext?.dictGenderName}
                            </Col>
                            <Col col={3} style={{marginBottom: "0",}}>
                                <Label><strong>DOB:</strong></Label>
                                {patientContext?.dateOfBirth}

                            </Col>
                            <Col col={4} style={{marginBottom: "0",}}>
                                <Label><strong>Contact Preferences:</strong></Label>
                                {contactPref}
                            </Col>
                        </Row>

                        <Row>
                            <Col col={5}>
                                <Label><strong>Address:</strong></Label><br></br>
                                {stitchOptionalStrings([patientContext?.street1 && patientContext?.street1, patientContext?.street2, patientContext?.city, patientContext?.dictStateDisplayName])}
                            </Col>
                            <Col col={3}>
                                <Label><strong>Phone:&nbsp;</strong></Label>
                                {patientContext?.phone}
                            </Col>
                            {leaveMsg && <Col col={4}>
                                <Label><i>Permission to leave message</i></Label>
                            </Col>}
                        </Row>
                        <Row style={{width: "100%"}}>
                            <Col col={5}>
                                <Label><strong>Email:&nbsp;</strong></Label>
                                {patientContext?.email}
                            </Col>
                            <Col col={5}>
                                <Label><strong>Facility:&nbsp;</strong></Label>
                                {patientContext?.facilityName}
                            </Col>


                            <Col col={1} className={"flex justify-content-end"}>
                                <Button onClick={toggleDialog}
                                        icon={"pi pi-bell"}
                                        style={{marginRight: 5}}
                                        severity={"danger"}
                                        label={"ICE"}
                                        text/>
                            </Col>

                        </Row>
                    </Col>
                </TileBody>
            </TileContainer>
            {showDialog && <ShowRelationships/>}
        </ShowForPermissionComponent>
    )

}

export default PatientDemographicsComponent
