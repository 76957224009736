import React, {useCallback, useContext, useRef} from "react";
import {Toast} from "primereact/toast";
const ToastContext = React.createContext(null);

const ToastProvider = ({children}) => {
    const toastRef = useRef(null)
//{ severity: 'warn', summary: 'Warn Message', detail: 'Message Content', life: 3000 }
    const addToast = useCallback(
        content => {
            toastRef.current.show({life: 3000, severity: "error", ...content});
        },
        []
    );

    return (
        <ToastContext.Provider
            value={{
                addToast,
            }}
        >
            <Toast ref={toastRef} position={"top-right"}/>
            {children}
        </ToastContext.Provider>
    );
};

const useToast = () => {
    const toastHelpers = useContext(ToastContext);

    return toastHelpers;
};

export {ToastContext, useToast};
export default ToastProvider;
