import * as GlobalConfig from "./GlobalConfig";
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {push} from "connected-react-router";


let store

export const injectStore = _store => {
    store = _store
}

const {REACT_APP_LOCAL_URL} = process.env

export function url(partial) {
    if (window?.API_URL === "REACT_APP_LOCAL_URL")
        switch (window.location.hostname) {
            case 'localhost':
                if (REACT_APP_LOCAL_URL) {
                    // return 'https://demo.api.phixhealth.com/' + partial
                    // return 'https://dev.api.aerioshealth.com/' + partial
                    // return 'https://lyssyhealth.api.phixhealth.com/' + partial
                    return REACT_APP_LOCAL_URL + partial
                } else {
                    return 'https://dev.api.aerioshealth.com/' + partial
                }
            // return 'https://demo.api.aerioshealth.com/' + partial
            case 'demo.popdash.aerioshealth.com':
                return 'https://demo.api.aerioshealth.com/' + partial
            case 'demo.phixhealth.aerioshealth.com':
                return 'https://demo.api.aerioshealth.com/' + partial

            case 'dev.phixhealth.com':
                return 'https://dev.api.phixhealth.com/' + partial
            case 'dev.phixhealth.aerioshealth.com':
                return 'https://dev.api.aerioshealth.com/' + partial
            case 'lyssy.phixhealth.aerioshealth.com':
                return 'https://lyssy.api.phixhealth.aerioshealth.com/' + partial
            case 'lyssyhealth.phixhealth.com':
                return 'https://lyssyhealth.api.phixhealth.com/' + partial
            case 'test-aersioshealth.ngrok.io' :
                return 'https://lyssyhealth.api.phixhealth.com/' + partial
            default:
                return 'http://localhost:9000/' + partial
        }

    else
        return window.API_URL + partial
}

function getRefreshToken() {
    return localStorage.getItem('refreshToken');
}

async function clearSession() {
    await localStorage.removeItem('token')
    await localStorage.removeItem('refreshToken')
    await localStorage.removeItem('userRoleId')
}

const refreshAuthLogic = (failedRequest) => {
    let refreshToken = getRefreshToken()
    if (refreshToken !== null) {
        console.log(refreshToken)
        axios.post(url("session/refreshToken"), {
            payload: {
                tenantId: GlobalConfig.TENANT_ID,
                token: getRefreshToken()
            }
        }).then((tokenRefreshResponse) => {
            if (tokenRefreshResponse.status === 200) {
                console.log(tokenRefreshResponse)
                localStorage.setItem("token", tokenRefreshResponse.data.sessionToken.token);
                localStorage.setItem('refreshToken', tokenRefreshResponse.data.refreshToken.token)
                localStorage.setItem('userRoleId', tokenRefreshResponse.data.userRoleId)
                failedRequest.response.config.headers["X-AUTH-TOKEN"] =
                    tokenRefreshResponse.data.sessionToken.token;
                return Promise.resolve();
            } else {
                store.dispatch(push('/'))
                store.dispatch({type: "USER_LOGOUT"})
                return Promise.reject()
            }

        }).catch((error) => {
            store.dispatch(push('/'))
            store.dispatch({type: "USER_LOGOUT"})
            clearSession().then(() => {
                return Promise.reject()
            })
        })
    } else {
        store.dispatch(push('/'))
        store.dispatch({type: "USER_LOGOUT"})
        clearSession().then(() => {
            return Promise.reject()
        })
    }
}

createAuthRefreshInterceptor(axios, refreshAuthLogic);

function getAccessToken() {
    return localStorage.getItem('token');
}

// Use interceptor to inject the token to requests
axios.interceptors.request.use((request) => {
    request.headers["X-AUTH-TOKEN"] = `${getAccessToken()}`;
    request.headers["TENANT-ID"] = `${GlobalConfig.TENANT_ID}`;
    return request;

});
