import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react';
import {Dropdown} from "primereact/dropdown";
import axios from "axios";
import {url} from "../../config/Url";
import {DictionaryAction} from "../../action/DictionaryDispatchAction";
import _ from "lodash";

export const Dictionary = {
    AH_FORM_CATEGORY: "AH_FORM_CATEGORY",
    ADVANCE_DIRECTIVE: "AH_ADVANCE_DIRECTIVE",
    ASSESSMENT: "AH_ASSESSMENT",
    CARE_PLAN_DETERMINANTS: "AH_CARE_PLAN_DETERMINANTS",
    CARE_PLAN_GOALS: "AH_CARE_PLAN_GOAL_CATEGORY",
    CARE_PLAN_GOAL_STATUS: "AH_CARE_PLAN_GOAL_STATUS",
    CARE_PLAN_STRATEGIES: "AH_CARE_PLAN_STRATEGIES",
    CARE_TEAM: "AH_CARE_TEAM",
    CHANNEL_TYPE: "AH_CHANNEL_TYPE",
    CLINICAL_COMPLEXITY_ALG: "AH_CLINICAL_COMPLEXITY_ALGORITHM",
    COMPONENT: "AH_COMPONENT",
    ADDRESS_CONTACT: "AH_ADDRESS",
    EMAIL_CONTACT: "AH_CONTACT_EMAIL",
    PHONE_CONTACT: "AH_CONTACT_PHONE",
    COUNTRIES: "AH_COUNTRIES",
    ENCOUNTER_FORM: "AH_ENCOUNTER_FORM",
    ENCOUNTER_TYPE: "AH_ENCOUNTER_TYPE",
    FACILITIES: "AH_FACILITY",
    GENDER: "AH_GENDER",
    RACE: "AH_RACE",
    ETHNICITY: "AH_ETHNICITY",
    MARITAL_STATUS: "AH_MARITAL_STATUS",
    MEETING_FORMAT: "AH_MEETING_FORMAT",
    NOTIFICATION_TYPE: "AH_NOTIFICATION",
    NOTIFICATION_ACTION_TYPE: "AH_NOTIFICATION_ACTION_TYPE",
    PERMISSION: "AH_PERMISSION",
    PERMISSION_GROUP: "AH_PERMISSION_GROUP",
    PLACE_OF_SERVICE: "AH_POS",
    PROBLEM_CATEGORY: "AH_PROBLEM_CATEGORY",
    PROBLEM_STATUS: "AH_PROBLEM_STATUS",
    USER_ROLE: "AH_ROLE",
    SOURCE_SYSTEM: "AH_SOURCE_SYSTEM",
    STATES: "AH_STATES",
    SYSTEM_FORM: "AH_SYSTEM_FORM",
    TAXONOMY: "AH_TAXONOMY",
    AH_CPT: "AH_CPT",
    CARE_PLAN_INTENT: "FHIR_CARE_PLAN_INTENT",
    CARE_PLAN_STATUS: "FHIR_CARE_PLAN_STATUS",
    OBSERVATION_CATEGORY: "OBSERVATION_CATEGORY",
    OBSERVATION_STATUS: "FHIR_OBSERVATION_STATUS",
    HCC: "HCC",
    ICD10: "ICD10CM",
    ICF: "ICF",
    LOINC: "LOINC",
    CVX: "VACCINE_CVX",
    VACCINE_INFORMATION_SOURCE: "VACCINE_INFORMATION_SOURCE",
    VACCINE_LOCATION_ADMINISTERED: "VACCINE_LOCATION_ADMINISTERED",
    VACCINE_MVX: "VACCINE_MVX",
    VACCINE_ROUTE: "VACCINE_ROUTE",
    VACCINE_STATUS: "VACCINE_STATUS",
    ROLE: "AH_ROLE",
    PROVIDER_TYPE: "AH_PROVIDER_TYPE",
    LICENSE_TYPE: "AH_LICENSE_TYPE",
    AH_REQUEST_TYPE: "AH_REQUEST_TYPE",
    AH_REQUEST_STATUS: "AH_REQUEST_STATUS",
    AH_EXTERNAL_VENDOR: "AH_EXTERNAL_VENDOR",
    AH_LAB_ORDER_STATUS: "AH_LAB_ORDER_STATUS",
    AH_MFA_METHOD: "AH_MFA_METHOD",
    AH_ORDER_TYPE: "AH_ORDER_TYPE",
    AH_QUALITY_MEASURES: "AH_QUALITY_MEASURES",
    AH_GEOGRAPHIC_REGION: "AH_GEOGRAPHIC_REGION",
    AH_SERVICE_LOCATION: "AH_SERVICE_LOCATION",
    AH_COHORT: "AH_COHORT",
    AH_MY_PATIENTS: "AH_MY_PATIENTS",
    AH_ALLERGY_OCCURRENCE: "AH_ALLERGY_OCCURRENCE",
    AH_ALLERGY_SEVERITY: "AH_ALLERGY_SEVERITY",
    AH_ALLERGY_REACTION: "AH_ALLERGY_REACTION",
    AH_CONDITION_SOURCE: "AH_CONDITION_SOURCE",
    AH_MEDICATION_UNITS: "AH_MEDICATION_UNITS",
    AH_MEDICATION_FORMULATION: "AH_MEDICATION_FORMULATION",
    AH_MEDICATION_ROUTE: "AH_MEDICATION_ROUTE",
    AH_MEDICATION_FREQUENCY: "AH_MEDICATION_FREQUENCY",
    AH_OUTCOMES: "AH_OUTCOMES",
    AH_RXNORM: "AH_RXNORM",
    AH_REFERRALS: "AH_REFERRALS",
    AH_EDUCATION_LEVEL: "AH_EDUCATION_LEVEL",
    AH_CONTACT_PREFERENCE: "AH_CONTACT_PREFERENCE",
    AH_EPRESCRIBE_ROLES: "AH_EPRESCRIBE_ROLES",
    AH_EPRESCRIBE_SPECIALTYCODE: "AH_EPRESCRIBE_SPECIALTYCODE",
    AH_LICENSE_EPRESCRIBE_STATE: "AH_LICENSE_EPRESCRIBE_STATE",
    AH_CARETEAM_ROLE: "AH_CARETEAM_ROLE",
    AH_LANGUAGES: "AH_LANGUAGES",
    AH_DOCUMENT_CATEGORIES: "AH_DOCUMENT_CATEGORIES",
    AH_REQUESTS: "AH_REQUESTS",
    AH_REFERRAL_REPORT_DELIVERY: "AH_REFERRAL_REPORT_DELIVERY",
    AH_ALLERGY_CLASS: "AH_ALLERGY_CLASS",
    AH_PERSON_RELATIONSHIP: "AH_PERSON_RELATIONSHIP",
    AH_HOSPITAL_ADMISSION_DOCUMENTS: "AH_HOSPITAL_ADMISSION_DOCUMENTS",
    AH_INSURANCE_PROVIDER: "AH_INSURANCE_PROVIDER",
    AH_LAB_VENDOR: "AH_LAB_VENDOR",
    AH_RELATIONSHIP_ROLE: "AH_RELATIONSHIP_ROLE",
    AH_RELATIONSHIP_TYPE: "AH_RELATIONSHIP_TYPE",
}

const DictionaryDropDownList = (props) => {
    const {
        required,
        value,
        defaultItemCode,
        inputRef,
        code,
        showCode = false,
        setValue = true,
        fullObject = false
    } = props
    const [data, setData] = useState([])
    const [filter, setFilter] = React.useState("")
    const [internalValue, setInternalValue] = useState(null)

    const filterChange = (event) => {
        setFilter(event.filter)
    };

    const onSetDefaultValue = (v) => {
        if (props.onChange) {
            if (fullObject)
                props.onChange({value: v})
            else
                props.onChange({value: v?.id})
        }
    };

    const handleChange = (e) => {
        if (e.value?.id) {
            if (setValue)
                setInternalValue(e.value)
            if (props.onChange) {
                if (fullObject)
                    props.onChange(e)
                else {
                    e.value = e.value?.id
                    props.onChange(e)
                }
            }
        }
    }

    useEffect(() => {
        if ((!_.isNil(value) && _.isNil(internalValue))) {
            if (value) {
                DictionaryAction.getDictionaryByIdOrCode(code, value, null).then(response => {
                    let item = response?.payload
                    if (!_.isNil(item)) {
                        item = {...item, filterDisplay: `${item.code} - ${item.displayName}`}
                        setInternalValue(item)
                    }
                })
            }
        } else if (!_.isNil(defaultItemCode) && _.isNil(internalValue)) {
            DictionaryAction.getDictionaryByIdOrCode(code, null, defaultItemCode).then(response => {
                let item = response?.payload
                if (!_.isNil(item)) {
                    onSetDefaultValue(item)
                    item = {...item, filterDisplay: `${item.code} - ${item.displayName}`}
                    setInternalValue(item)

                }
            })
        }


    }, [defaultItemCode, data, value])

    const refreshData = () => {
        axios.post(url("dictionary/search/index"), {
            payload: {

                active: true,
                dictionaryTypeCode: code,
                searchString: filter
            },
            sort: {sorts: [{field: "code", dir: "desc"}]},
            limit: 50,
            offset: 0
        }).then(resp => {
            if (resp.data.payload?.length > 0) {
                let newData = resp.data.payload.map(item => {
                    return {...item, filterDisplay: `${item.code} - ${item.displayName}`}
                })
                if (internalValue?.id) {
                    newData = [...data, internalValue]
                }
                setData(newData)
            }
        })
    }

    useEffect(() => {
        // we need to make sure the value is in the list of options
        if (!_.isNil(internalValue?.id)) {
            let exists = data.find(d => d?.id === internalValue?.id)

            if (!exists?.id) {
                let newData = [...data, internalValue]
                setData(newData)
            }
        }


    }, [internalValue, data])


    useEffect(() => {
        refreshData()
    }, [filter])

    // useEffect(() => {
    //     refreshData()
    // }, [])


    return (<>
        <Dropdown style={{...props.style}}
                  panelStyle={{overflow: "scroll", width: "20%"}}
                  className={props.className}
                  focusInputRef={inputRef}
                  required={required}
                  showFilterClear
                  disabled={props.disabled}
                  dataKey={"id"}
            // resetFilterOnHide
                  options={data}
                  value={internalValue}
                  optionLabel={"filterDisplay"}
                  filter
                  itemTemplate={v => {
                      if (showCode)
                          return (<div>{`(${v?.code}) ${v?.displayName}`}</div>)
                      else
                          return (<div>{`${v?.displayName}`}</div>)
                  }}
                  valueTemplate={(v) => {
                      if (showCode)
                          return (<div>{`(${v?.code}) ${v?.displayName}`}</div>)
                      else
                          return (<div>{`${v?.displayName}`}</div>)
                  }}
                  onFilter={filterChange}
                  onChange={handleChange}
        />

    </>)
}


DictionaryDropDownList.propTypes = {
    style: PropTypes.object,
    code: PropTypes.string.isRequired,
    defaultItemCode: PropTypes.string,
    onChange: PropTypes.func,
    register: PropTypes.func,
}


export default DictionaryDropDownList