import * as React from "react";
import {PDFExport} from "@progress/kendo-react-pdf";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {connect, useDispatch, useSelector} from "react-redux";
import {Button} from "primereact/button";
import {PopupWindowActions, PopupWindows} from "../../../action/PopupWindowAction";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../../action/DictionaryDispatchAction"
import {ImmunizationActions} from "../../../action/ImmunizationAction";
import moment from 'moment';
import {orderBy} from "@progress/kendo-data-query";
import ShowForPermissionComponent from "../../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../../config/GlobalConfig";
import {Col, Container, Row} from "../../../layout/GridLayout";
import {stitchOptionalStrings} from "../../../util/MiscTools";

const ImmunizationsTabComponent = (props) => {
    let gridPDFExport;
    const dispatch = useDispatch()
    const immunizationData = useSelector(state => state.patient.immunizationData)
    const patientContext = useSelector(state => state.patient.patientContext)



    const pdfExportComponent = React.useRef(null);

    const [dataState, setDataState] = React.useState({
        take: 5,
        skip: 0,
    });

    const [filterState, setFilterState] = React.useState(false)

    React.useEffect(() => {
        ImmunizationActions.getImmunization({"patientRoleId": patientContext.patientRoleId})(dispatch)
    }, [])
    React.useEffect(() => {
        if (patientContext?.patientRoleId)
            ImmunizationActions.getImmunization({"patientRoleId": patientContext.patientRoleId})(dispatch)
    }, [patientContext])
    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const AddImmunizations = () => {
        PopupWindowActions.showWindow(PopupWindows.ADD_IMMUNIZATION_WINDOW)(dispatch);
    };

    const [isExporting, setIsExporting] = React.useState(false);

    React.useEffect(() => {
        if (isExporting) {
            pdfExportComponent.current.save(() => {
                setIsExporting(false);
            })
        }
    }, [isExporting])

    const exportPDFWithComponent = () => {
        setIsExporting(true);

        // pdfExportRef.current.save(() => {
        //     setIsExporting(false);
        // });
    }

    const orderingUserRoleId = (prop) => {
        return (
            <td>{stitchOptionalStrings([prop.dataItem?.orderingUserRoleLastName, prop.dataItem?.orderingUserRoleFirstName, prop.dataItem?.orderingUserRoleCredentials], ",")}</td>)
    }

    const administeredDate = (prop) => {
        let adminDate = prop.dataItem.administeredDate?.split("T")?.[0] || [prop.dataItem.yearDate, prop.dataItem.monthDate,].filter(Boolean).join("-");
        return <td>{adminDate}</td>;
    }
    let fullAddress = [patientContext.city, patientContext.dictStateDisplayName, patientContext.zipCode].filter(Boolean).join(", ");

    const CommandCell = (item) => (
        <td className="k-command-cell">
            <Button
                icon={"pi pi-pencil"}
                text
                onClick={() => {

                    PopupWindowActions.showWindow(PopupWindows.ADD_IMMUNIZATION_WINDOW, {immunizationContext: item.dataItem})(dispatch)
                }}/>
        </td>
    );
    const initialSort = [
        {
            field: "informationSource",
            dir: "asc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);
    const grid = (<Grid
        {...dataState}
        style={{height: "100%"}}
        data={orderBy(immunizationData, sort)}
        pageSize={dataState.limit}
        editField="inEdit"
        total={props.totalCount}
        filterable={filterState}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
            setSort(e.sort);
        }}
        skip={dataState.skip}
        pageable={{
            buttonCount: 20,
            info: true,
            type: "numeric",
            pageSizes: true,
            previousNext: true,
        }}

        onDataStateChange={dataStateChange}
    >
        <GridColumn field="dictVaccineCvxDisplayName" title="Vaccine"/>
        <GridColumn field="dictSourceOfInformationDisplayName" title="Info Source"/>
        <GridColumn field="administeredDate" title="Administered Date" cell={administeredDate}/>
        <GridColumn field="orderingUserRoleId" title="Ordering Provider" cell={orderingUserRoleId}/>
        <GridColumn field="dictVaccineLocationAdministeredDisplayName" title="Location Administered"/>
        <GridColumn field="dictVaccineStatusDisplayName" title="Completion Status"/>
        <GridColumn field="Action" title="Action" cell={CommandCell}/>
    </Grid>);

    return (

        <Container style={{margin: 0, width: "100%", height: "100%"}}>
            <Row gutter className={"justify-content-center"} style={{width: "100%", height: 50}}>
                <Col col={3} className={"flex-grow-0"}>
                    <Button togglable={true}
                            text
                            tooltip={"Toggle filtering"}
                            size={"small"}
                            onClick={() => setFilterState(!filterState)}
                            icon={(filterState) ? "pi pi-filter-fill" : "pi pi-filter"}/>

                </Col>
                <Col className={"flex-grow-5"}/>
                <Col className={"flex-grow-0"}>
                    <Button text tooltip={"Print Immunizations"} onClick={exportPDFWithComponent} icon={"pi pi-print"}/>
                </Col>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_HOSPITALIZATION]}>
                    <Col className={"flex-grow-0"}>
                        <Button text
                                onClick={AddImmunizations}
                                tooltip={"Add immunizations"}
                                size={"small"}
                                icon={"pi pi-plus"}/>
                    </Col>

                </ShowForPermissionComponent>


            </Row>
            <Row style={{width: "100%", height: "calc(100% - 50px)", padding: 0}}>
                    {grid}
            </Row>
            <PDFExport
                ref={pdfExportComponent}
                paperSize="A4"
                margin="1cm"
                scale={0.6}
                fileName={`Report for ${new Date().getFullYear()}`}
                author="KendoReact Team"
            >
                {isExporting && <div style={{
                    marginBottom: "50px"
                }}>
                    <h3>Immunization record for:</h3>
                    <span>{patientContext.lastName}</span>, <span>{patientContext.firstName}</span>
                    <span> {(patientContext.middleName) ? patientContext.middleName.charAt(0).toUpperCase() : ' '} </span>
                    <span style={{width: '100%', float: 'left',}}>{patientContext.dateOfBirth} </span>
                    <span style={{width: '100%', float: 'left',}}>{patientContext.street1}</span>
                    <span style={{width: '100%', float: 'left',}}>{patientContext.street2}</span>
                    <span style={{width: '100%', float: 'left',}}>{fullAddress}</span>
                    <div style={{margin: '15px 0 150px 0', width: '100%', float: 'left',}}>
                        <table className="pdfTable">
                            <tr>
                                <th> Date Administered</th>
                                <th> Vaccine</th>
                                <th> CVX code</th>
                                <th> Manufacturer</th>
                                <th> Location Administered</th>
                                <th> Source of Information</th>
                                <th> Completion Status</th>
                            </tr>
                            {

                                immunizationData.map(element => {
                                    let adminDate = element.administeredDate || [element.yearDate, element.monthDate,].filter(Boolean).join("-");
                                    return (<tr>
                                        <td>{adminDate}</td>
                                        <td>{element.dictVaccineCvxDisplayName}</td>
                                        <td>{element.dictVaccineCvxCode}</td>
                                        <td>{element.dictVaccineMvxDisplayName}</td>
                                        <td>{element.dictVaccineLocationAdministeredDisplayName}</td>
                                        <td>{element.dictSourceOfInformationDisplayName}</td>
                                        <td>{element.dictVaccineStatusDisplayName}</td>
                                    </tr>)

                                })
                            }
                        </table>
                    </div>
                </div>}

                {isExporting && <div>

                    <div style={{padding: '250px 0 0 0'}}>
                        <br/><br/><br/><br/>
                        <span style={{width: '100%', float: 'left', textAlign: 'right'}}>Signature -----------------------------------</span>

                    </div>
                    <div>
                        <br/><br/>
                        <span style={{
                            width: '100%',
                            float: 'left',
                            textAlign: 'right'
                        }}>Printed on {moment().format('YYYY-MM-DD')}</span>
                    </div>
                </div>}
            </PDFExport>
            <div>
            </div>
        </Container>
    );
};

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        ImmunizationAction: bindActionCreators(ImmunizationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImmunizationsTabComponent)
