import {ActionTypes} from "../action/ObservationsThresholdAction";


const initialState = {
    observationThresholdContext: {},
    savedObservationThresholds: null
}

export const observationThresholdReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_OBSERVATIONS_THRESHOLD_FOR_COMPONENT:
            return { ...state, componentThresholds: { ...state.componentThresholds, [action.component]: action.payload.payload } }
        case ActionTypes.GET_OBSERVATIONS_THRESHOLD:
            return {...state, observationThresholdContext: {...state.observationThresholdContext, [action.payload.payload[0].dictObservationId]: action.payload.payload}}
        case ActionTypes.SET_OBSERVATIONS_THRESHOLD:
            return {...state, observationThresholdContext: {...state.observationThresholdContext, [action.payload.id]: []}}
        case ActionTypes.GET_SAVED_OBSERVATIONS_THRESHOLDS:
            return {...state, savedObservationThresholds: action.payload.payload}
        default:
            return state
    }

}

