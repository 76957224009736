import * as React from "react";
import {connect} from "react-redux";
import { RadioGroup } from "@progress/kendo-react-inputs";
import {bindActionCreators} from "redux";
import {Col, Container, Row} from "react-grid-system";
import {PersonActions} from "../../action/PersonAction";
import {PatientAction} from "../../action/PatientAction";

const ContactPreferencesComponent = (props) => {

    const [phoneCall, setPhoneCall] = React.useState();
    const [email, setEmail] = React.useState();
    const [text, setText] = React.useState();
    const [leaveMsgViaPhone, setLeaveMsgViaPhone] = React.useState();

    const radioGroup = [
      {
        value: "yes",
      },
      {
        value: "no",
      }
    ];

    React.useEffect(() => {
        props.PersonActions.getContactPreferences({personId: props?.patientContext?.personId});
    }, [props?.patientContext?.personId])

    React.useEffect(() => {
        setPhoneCall(props?.contactPreferences?.phoneCall === true ? "yes": "no")
        setEmail(props?.contactPreferences?.email === true ? "yes": "no")
        setText(props?.contactPreferences?.text === true ? "yes": "no")
        setLeaveMsgViaPhone(props?.contactPreferences?.leaveMsgViaPhone === true ? "yes": "no")
    }, [props?.contactPreferences?.personId])

    const onChangeUpdate = (e, key) => {
        switch(key) {
            case "phoneCall":
                setPhoneCall(e.value);
                break;
            case "email":
                setEmail(e.value);
                break;
            case "text":
                setText(e.value);
                break;
            case "leaveMsgViaPhone":
                setLeaveMsgViaPhone(e.value);
                break;
        }
        var payload = {personId: props?.patientContext?.personId}
        payload[key] = e.value === "yes" ? true : false;
        props.PersonActions.updateContactPreferences(payload).then(()=>{
            props.PersonActions.getContactPreferences({personId: props?.patientContext?.personId});
        });
    }

    return<div style={{marginLeft:"1%"}}>
        <Row>
        <Col>
            <Row>
                <Col>
                </Col>
                <Col style={{marginLeft:"-3%"}}>
                    <Row>
                        <span>Yes</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>No</span>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col><span> Phone Call </span></Col>
                <Col style={{marginLeft:"-5%"}}><RadioGroup data={radioGroup} value={phoneCall} onChange={(e)=> onChangeUpdate(e, "phoneCall")} layout={"horizontal"} /></Col>
            </Row>
            <Row>
                <Col><span> Email </span></Col>
                <Col style={{marginLeft:"-5%"}}><RadioGroup data={radioGroup} value={email} onChange={(e)=> onChangeUpdate(e, "email")} layout={"horizontal"} /></Col>
            </Row>
            <Row>
                <Col><span> Text </span></Col>
                <Col style={{marginLeft:"-5%"}}><RadioGroup data={radioGroup} value={text} onChange={(e)=> onChangeUpdate(e, "text")} layout={"horizontal"} /></Col>
            </Row>
        </Col>
        <Col>
            <Row>
                <Col>
                </Col>
                <Col style={{marginLeft:"-3%"}}>
                    <Row>
                        <span>Yes</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>No</span>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col><span> Can we leave a message via phone? </span></Col>
                <Col style={{marginLeft:"-5%"}}><RadioGroup data={radioGroup} value={leaveMsgViaPhone} onChange={(e)=> onChangeUpdate(e, "leaveMsgViaPhone")} layout={"horizontal"} /></Col>
            </Row>
        </Col>
        </Row>
    </div>;

}



function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        personModel: state.person.personModel,
        contactPreferences: state.person.contactPreferences,
        patientContext: state.patient.createPatientContext,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PatientActions: bindActionCreators(PatientAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPreferencesComponent)