import PropTypes from "prop-types";
import React from "react";
import {Responsive, WidthProvider} from "react-grid-layout";
import {ParseComponent} from "../components/shared/ComponentRegistry";
import {useSelector} from "react-redux";

const ResponsiveGridLayout = WidthProvider(Responsive);


function PatientListPage(props) {
    const tenantLayout = useSelector(state => state.Configuration.tenant.workspaceLayout)
    const permissionLayout = useSelector(state => state.Configuration.config.workspaceLayout)

    const [screenLayout, setScreenLayout] = React.useState(permissionLayout?.PatientListPage?.Components ? permissionLayout.PatientListPage.Components : tenantLayout?.PatientListPage?.Components ? tenantLayout?.PatientListPage?.Components : null)

    if (screenLayout)
        return <>
            <ResponsiveGridLayout
                style={{height: "calc(100vh - 60px)", width: "100%", overflowY: "auto", overflowX: "hidden"}}
                resizeHandles={["se"]}
                draggableHandle={".ah-draggable-header"}
                className="layout"
                layouts={{lg: screenLayout}}
                breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                cols={{lg: 12, md: 12, sm: 4, xs: 4, xxs: 4}}>
                {screenLayout.map(c => ParseComponent(c))}
            </ResponsiveGridLayout>
        </>
    else return <>
    <div>
        <h4>Error: Tenant configuration is not present. Unable to load</h4>
    </div>
    </>
}


PatientListPage.propTypes = {
    authActions: PropTypes.object,
    layoutActions: PropTypes.object,
}


export default PatientListPage