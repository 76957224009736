import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {DictionaryTypeAction} from "../../action/DictionaryTypeAction";
import {connect} from "react-redux";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {UserActions} from "../../action/UserAction";
import {isObject} from "lodash";


const UserDropDownList = (props) => {
    const initialSort = [
        {
            field: "firstName",
            dir: "asc",
        },
    ];
    const {UserActions, users, value} = props
    const dropdownRef = React.createRef()
    const [data, setData] = React.useState([]);
    const [internalValue, setInternalValue] = React.useState()

    const [sort, setSort] = React.useState(initialSort);
    const [filter, setFilter] = React.useState({searchAll: ""});


    const handleChange = (e) => {
        setInternalValue(e.target.value)
        if (props.onChange) {
            props.onChange(e)
        }
    }
    const filterChange = (event) => {
        setFilter({value: event.filter.value})
    };


    useEffect(() => {
            let filters = {}
            filters.searchAll = filter?.value
            if (!value?.patientRoleId) {
                if (filter?.id)
                    filters.id = filter.id
            }
            filters.active=true;
            UserActions.getUserListFilter(initialSort, filters,true)
        }
        ,
        [filter]
    )

    useEffect(() => {
        setData(users);
    }, [users])

    useEffect(() => {
        if (isObject(value)) {
            if (internalValue !== value)
                setInternalValue(value)
            return;
        }
        if (!internalValue?.userRoleId && value) {
            let d = data ? data.find((c) => c.userRoleId === value) : null

            if (!d) {
                setFilter({id: value, value: null})
            }
            if (d?.userRoleId !== value?.userRoleId) {
                setInternalValue(d)
            }
        }


    }, [value, data])

    let DefaultVal = {displayName: "Please Select ..."};


    return (

        <>
            <DropDownList style={props.style}
                          ref={dropdownRef}
                          disabled={props.disabled}
                          data={data}
                          defaultItem={DefaultVal}
                          value={internalValue}
                          dataItemKey={"userRoleId"}
                          textField={"displayName"}

                          filterable={true}
                          onFilterChange={filterChange}
                          onChange={handleChange}
            />
        </>

    );
};
UserDropDownList.propTypes = {
    UserActions: PropTypes.object,
    users: PropTypes.array,
}

function mapStateToProps(state) {
    return {
        users: state.user.userList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        UserActions: bindActionCreators(UserActions, dispatch),
        DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDropDownList)