import {PaginatedEndpoint, WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    UPDATE_ENCOUNTER: "UPDATE_ENCOUNTER",
    SAVE_ENCOUNTER: "SAVE_ENCOUNTER",
    SET_ENCOUNTER_CONTEXT: "SET_ENCOUNTER_CONTEXT",
    CLEAR_ENCOUNTER: "CLEAR_ENCOUNTER",
    SAVE_ENCOUNTER_SIGNATURE: "SAVE_ENCOUNTER_SIGNATURE",
    SAVE_ENCOUNTER_FORM: "SAVE_ENCOUNTER_FORM",
    GET_ENCOUNTER_FORM: "GET_ENCOUNTER_FORM",
    GET_ALL_ENCOUNTER_DOCUMENTS: "GET_ALL_ENCOUNTER_DOCUMENTS",
    GET_ALL_ENCOUNTER_FORM_CONFIGURATION: "GET_ALL_ENCOUNTER_FORM_CONFIGURATION",
    CLEAR_ALL_ENCOUNTER_FORM_CONFIGURATION: "CLEAR_ALL_ENCOUNTER_FORM_CONFIGURATION",
    ADD_ENCOUNTER_FORM_CONFIGURATION: "ADD_ENCOUNTER_FORM_CONFIGURATION",
    UPDATE_ENCOUNTER_LOCK: "UPDATE_ENCOUNTER_LOCK",
    ENCOUNTER_GET_DICTIONARY_BY_CODE: "ENCOUNTER_GET_DICTIONARY_BY_CODE",
    GET_ALL_ENCOUNTER_CODING_SERVICE_LINE: "GET_ALL_ENCOUNTER_CODING_SERVICE_LINE",
    GET_ENCOUNTER_CODING: "GET_ENCOUNTER_CODING",
    DELETE_ENCOUNTER_CODING_SERVICE_LINE: "DELETE_ENCOUNTER_CODING_SERVICE_LINE",
    SAVE_ENCOUNTER_CODING: "SAVE_ENCOUNTER_CODING",
    UPDATE_ENCOUNTER_CODING: "UPDATE_ENCOUNTER_CODING",
    CLEAR_ENCOUNTER_CODING: "CLEAR_ENCOUNTER_CODING",
    ASSESSMENT_LIST_ASSESSMENTS_ENCOUNTER: "ASSESSMENT_LIST_ASSESSMENTS_ENCOUNTER",
    GET_ALL_ENCOUNTER_ESIGN: "GET_ALL_ENCOUNTER_ESIGN",
    CLEAR_ALL_ENCOUNTER_CODING_SERVICE_LINE: "CLEAR_ALL_ENCOUNTER_CODING_SERVICE_LINE",
    ENCOUNTER_PDF_DATA: "ENCOUNTER_PDF_DATA",
    MEDICAL_PROBLEM_ID: "MEDICAL_PROBLEM_ID",
    MEDICATION_ID: "MEDICATION_ID",
    ALLERGY_ID: "ALLERGY_ID",
    HOSPITALIZATION_ID: "HOSPITALIZATION_ID",
    IMMUNIZATION_ID: "IMMUNIZATION_ID",
    CLEAR_CREATED_ISSUES: "CLEAR_CREATED_ISSUES",
    SAVE_ENCOUNTER_PATIENT_INSTRUCTIONS: "SAVE_ENCOUNTER_PATIENT_INSTRUCTIONS",
    SAVE_ENCOUNTER_MEDICAL_PROBLEM: "SAVE_ENCOUNTER_MEDICAL_PROBLEM",
    SAVE_ENCOUNTER_MEDICATION: "SAVE_ENCOUNTER_MEDICATION",
    SAVE_ENCOUNTER_ALLERGY: "SAVE_ENCOUNTER_ALLERGY",
    SAVE_ENCOUNTER_HOSPITALIZATION: "SAVE_ENCOUNTER_HOSPITALIZATION",
    SAVE_ENCOUNTER_IMMUNIZATION: "SAVE_ENCOUNTER_IMMUNIZATION",
    GET_ENCOUNTER_MEDICAL_PROBLEM: "GET_ENCOUNTER_MEDICAL_PROBLEM",
    GET_ENCOUNTER_MEDICATION: "GET_ENCOUNTER_MEDICATION",
    GET_ENCOUNTER_ALLERGY: "GET_ENCOUNTER_ALLERGY",
    GET_ENCOUNTER_HOSPITALIZATION: "GET_ENCOUNTER_HOSPITALIZATION",
    GET_ENCOUNTER_IMMUNIZATION: "GET_ENCOUNTER_IMMUNIZATION",
}


function updateEncounter(encounter) {
    return WrapAuthentication("encounter/update",
        {
            payload: encounter
        },
        ActionTypes.UPDATE_ENCOUNTER)
}

function saveEncounter(encounter) {
    return WrapAuthentication("encounter/create",
        {
            payload: encounter
        },
        ActionTypes.SAVE_ENCOUNTER)
}

function getEncounter(req) {
    return WrapAuthentication("encounter/get",
        {
            payload: req
        },
        ActionTypes.SAVE_ENCOUNTER)
}

function updateEncounterBaseRequest(req) {
    return WrapAuthentication("encounter/update/request",
        {
            payload: req
        },
        ActionTypes.SAVE_ENCOUNTER)
}

function saveEncounterSignature(signEncounter) {
    return WrapAuthentication("encounter/sign",
        {
            payload: signEncounter
        },
        ActionTypes.SAVE_ENCOUNTER_SIGNATURE)
}

function saveEncounterForm(form) {
    return WrapAuthentication("form/save",
        form,
        ActionTypes.SAVE_ENCOUNTER_FORM)
}

function getEncounterForms(id) {
    return WrapAuthentication("formschema/get/byEncounter",
        {
            payload: id
        },
        ActionTypes.GET_ENCOUNTER_FORM)
}

function getAllEncounterDocuments(payload) {
    return WrapAuthentication("clinical/document/getAll",
        {
            payload: payload
        },
        ActionTypes.GET_ALL_ENCOUNTER_DOCUMENTS)
}

function getAllEncounterFormConfig(payload) {
    return WrapAuthentication("encounter/form/configuration/get/all",
        {
            payload: payload
        },
        ActionTypes.GET_ALL_ENCOUNTER_FORM_CONFIGURATION)
}

function getAllEncounterEsignById(payload) {
    return WrapAuthentication("encounter/esign/list/get/",
        {
            payload: payload
        },
        ActionTypes.GET_ALL_ENCOUNTER_ESIGN)
}

function addEncounterFormConfig(payload) {
    return WrapAuthentication("encounter/form/configuration/add",
        {
            payload: payload
        },
        ActionTypes.ADD_ENCOUNTER_FORM_CONFIGURATION)
}

function getEncounterPDFData(payload) {
    return WrapAuthentication("encounter/pdf",
        {
            payload: payload
        },
        ActionTypes.ENCOUNTER_PDF_DATA)
}

function clearEncounterContext() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_ENCOUNTER})
    }
}

function clearEncounterFormConfig() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_ALL_ENCOUNTER_FORM_CONFIGURATION})
    }
}

function clearEncounterServiceLines() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_ALL_ENCOUNTER_CODING_SERVICE_LINE})
    }
}

function clearEncounterCoding() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_ENCOUNTER_CODING})
    }
}

function getDictionaryByCode(code, limit, offset) {
    let l = limit ? limit : 1000
    let o = offset ? offset : 0
    return WrapAuthentication('dictionary/code', {
            payload: {code: code, isAll: true},
            limit: l,
            offset: o,
        },
        ActionTypes.ENCOUNTER_GET_DICTIONARY_BY_CODE,
        {code}
    )
}

function saveEncounterCodingServiceLine(payload) {
    return WrapAuthentication('encounter/coding/service/line/add', {
            payload: payload
        },
        ActionTypes.SAVE_ENCOUNTER_CODING_SERVICE_LINE
    )
}

function getEncounterCodingServiceLineByEncounterId(payload) {
    return WrapAuthentication('encounter/coding/service/line/get/all', {
            payload: payload
        },
        ActionTypes.GET_ALL_ENCOUNTER_CODING_SERVICE_LINE
    )
}

function deleteEncounterCodingServiceLineById(payload) {
    return WrapAuthentication('encounter/coding/service/line/delete', {
            payload: payload
        },
        ActionTypes.DELETE_ENCOUNTER_CODING_SERVICE_LINE
    )
}

function addEncounterCodingByEncounterId(payload) {
    return WrapAuthentication('encounter/coding/add', {
            payload: payload
        },
        ActionTypes.ADD_ENCOUNTER_CODING
    )
}

function saveEncounterCodingByEncounterId(payload) {
    return WrapAuthentication('encounter/coding/save', {
            payload: payload
        },
        ActionTypes.SAVE_ENCOUNTER_CODING
    )
}

function updateEncounterCodingByEncounterId(payload) {
    return WrapAuthentication('encounter/coding/update', {
            payload: payload
        },
        ActionTypes.UPDATE_ENCOUNTER_CODING
    )
}

function getEncounterCodingByEncounterId(payload) {
    return WrapAuthentication('encounter/coding/get', {
            payload: payload
        },
        ActionTypes.GET_ENCOUNTER_CODING
    )
}

function setEncounterContext(encounter) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_ENCOUNTER_CONTEXT, payload: encounter})
    }
}

function setStartTimerEncounter(payload) {
    return WrapAuthentication('encounter/start/timer/add', {
            payload: payload
        },
        ActionTypes.UPDATE_ENCOUNTER
    )
}

function setEndTimerEncounter(payload) {
    return WrapAuthentication('encounter/end/timer/add', {
            payload: payload
        },
        ActionTypes.UPDATE_ENCOUNTER
    )
}

function updateEncounterLock(payload) {
    return WrapAuthentication("/encounter/lock/update", {
        payload: payload
    }, ActionTypes.UPDATE_ENCOUNTER_LOCK)
}

function saveEncounterInstructions(payload) {
    return WrapAuthentication("/encounter/patient/instructions/save", {
        payload: payload
    }, ActionTypes.SAVE_ENCOUNTER_PATIENT_INSTRUCTIONS)
}


function getAssessmentsForEncounter(encounterId) {
    return WrapAuthentication("assessment/encounter/get",
        {payload: {id: encounterId}},
        ActionTypes.ASSESSMENT_LIST_ASSESSMENTS_ENCOUNTER)
}


function setMedicalProblemId(medicalProblem) {
    return async dispatch => {
        dispatch({type: ActionTypes.MEDICAL_PROBLEM_ID, payload: medicalProblem})
    }
}

function setMedicationId(medication) {
    return async dispatch => {
        dispatch({type: ActionTypes.MEDICATION_ID, payload: medication})
    }
}

function setAllergyId(allergy) {
    return async dispatch => {
        dispatch({type: ActionTypes.ALLERGY_ID, payload: allergy})
    }
}

function setHospitalizationId(hospitalization) {
    return async dispatch => {
        dispatch({type: ActionTypes.HOSPITALIZATION_ID, payload: hospitalization})
    }
}

function setImmunizationId(immunization) {
    return async dispatch => {
        dispatch({type: ActionTypes.IMMUNIZATION_ID, payload: immunization})
    }
}

function clearCreatedIssues() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_CREATED_ISSUES})
    }
}


function saveEncounterMedicalProblem(payload) {
    return WrapAuthentication("encounter/medical/problem/save",
        {payload: payload},
        ActionTypes.SAVE_ENCOUNTER_MEDICAL_PROBLEM)
}
function saveEncounterMedications(payload) {
    return WrapAuthentication("encounter/medication/save",
        {payload: payload},
        ActionTypes.SAVE_ENCOUNTER_MEDICATION)
}
function saveEncounterAllergy(payload) {
    return WrapAuthentication("encounter/allergy/save",
        {payload: payload},
        ActionTypes.SAVE_ENCOUNTER_ALLERGY)
}
function saveEncounterHospitalization(payload) {
    return WrapAuthentication("encounter/hospitalization/save",
        {payload: payload},
        ActionTypes.SAVE_ENCOUNTER_HOSPITALIZATION)
}
function saveEncounterImmunization(payload) {
    return WrapAuthentication("encounter/immunization/save",
        {payload: payload},
        ActionTypes.SAVE_ENCOUNTER_IMMUNIZATION)
}
function getEncounterMedicalProblems(payload) {
    return WrapAuthentication("encounter/medical/problems/get",
        {payload: payload},
        ActionTypes.GET_ENCOUNTER_MEDICAL_PROBLEM)
}
function getEncounterMedications(payload) {
    return WrapAuthentication("encounter/medications/get",
        {payload: payload},
        ActionTypes.GET_ENCOUNTER_MEDICATION)
}
function getEncounterAllergies(payload) {
    return WrapAuthentication("encounter/allergies/get",
        {payload: payload},
        ActionTypes.GET_ENCOUNTER_ALLERGY)
}
function getEncounterHospitalizations(payload) {
    return WrapAuthentication("encounter/hospitalizations/get",
        {payload: payload},
        ActionTypes.GET_ENCOUNTER_HOSPITALIZATION)
}
function getEncounterImmunizations(payload) {
    return WrapAuthentication("encounter/immunizations/get",
        {payload: payload},
        ActionTypes.GET_ENCOUNTER_IMMUNIZATION)
}


export const EncounterAction = {
    updateEncounter,
    saveEncounter,
    saveEncounterSignature,
    saveEncounterForm,
    getEncounterForms,
    clearEncounterContext,
    getAllEncounterDocuments: getAllEncounterDocuments,
    setEncounterContext: setEncounterContext,
    getAllEncounterFormConfig: getAllEncounterFormConfig,
    clearEncounterFormConfig,
    addEncounterFormConfig,
    updateEncounterLock,
    getDictionaryByCode,
    getEncounterCodingServiceLineByEncounterId,
    saveEncounterCodingServiceLine,
    getEncounterCodingByEncounterId,
    deleteEncounterCodingServiceLineById,
    addEncounterCodingByEncounterId,
    saveEncounterCodingByEncounterId,
    updateEncounterCodingByEncounterId: updateEncounterCodingByEncounterId,
    getEncounter,
    updateEncounterBaseRequest,
    clearEncounterCoding,
    setStartTimerEncounter,
    setEndTimerEncounter,
    getAssessmentsForEncounter,
    getAllEncounterEsignById,
    clearEncounterServiceLines,
    getEncounterPDFData: getEncounterPDFData,
    setMedicalProblemId: setMedicalProblemId,
    setMedicationId: setMedicationId,
    setAllergyId: setAllergyId,
    setHospitalizationId: setHospitalizationId,
    setImmunizationId: setImmunizationId,
    clearCreatedIssues: clearCreatedIssues,
    saveEncounterInstructions: saveEncounterInstructions,
    saveEncounterMedicalProblem: saveEncounterMedicalProblem,
    saveEncounterMedications: saveEncounterMedications,
    saveEncounterAllergy: saveEncounterAllergy,
    saveEncounterHospitalization: saveEncounterHospitalization,
    saveEncounterImmunization: saveEncounterImmunization,
    getEncounterMedicalProblems: getEncounterMedicalProblems,
    getEncounterMedications: getEncounterMedications,
    getEncounterAllergies: getEncounterAllergies,
    getEncounterHospitalizations: getEncounterHospitalizations,
    getEncounterImmunizations: getEncounterImmunizations,
}


function getEncountersPaginated(patientRoleId) {
    return PaginatedEndpoint("encounter/list/get", {patientRoleId: patientRoleId})
}

export const EncounterActionsPaginated = {
    getEncountersPaginated: getEncountersPaginated
}