export const ActionTypes = {

    SET_WINDOW_STATE: "SET_WINDOW_STATE",
    CLEAR_WINDOW_STATE: "CLEAR_WINDOW_STATE",
}


function setWindowState(window, state) {
    return dispatch => dispatch({type: ActionTypes.SET_WINDOW_STATE, window, state})
}

function clearWindowState(window) {
    return dispatch => dispatch({type: ActionTypes.SET_WINDOW_STATE, window})
}

export const WindowActions = {
    setWindowState,
    clearWindowState,
}