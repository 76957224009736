import {WrapAuthentication} from "../util/AuthWrapper";
import {TENANT_ID} from "../config/GlobalConfig";

export const ActionTypes = {
    SAVE_LAYOUT: "SAVE_LAYOUT",
    GET_SAVED_LAYOUT: "GET_SAVED_LAYOUT",
    GET_LAYOUT: "GET_LAYOUT",
}

function saveLayout(permissionGroupId, workspaceLayoutId) {
    return WrapAuthentication('workspace/save', {payload: {tenantId: TENANT_ID, workspaceLayoutId:workspaceLayoutId, permissionGroupId: permissionGroupId}},
            ActionTypes.SAVE_LAYOUT
        )
}

function getLayout() {
     return WrapAuthentication('workspace/by/tenant/id', {payload: {tenantId: TENANT_ID}},
            ActionTypes.GET_LAYOUT
        )
}

function getSavedPageLayout(limit, offset, sort, filter) {
    let l = limit ? limit : 1000
    let o = offset ? offset : 0
    return WrapAuthentication('workspace/list/by/tenant/id', {
            payload: {tenantId: TENANT_ID},
            sort: sort ? {sorts: sort} : null,
            filter: filter ? filter : {},
            limit: l,
            offset: o,
        },
        ActionTypes.GET_SAVED_LAYOUT
    )
}

export const LayoutAction =
    {
        saveLayout: saveLayout,
        getLayout: getLayout,
        getSavedPageLayout: getSavedPageLayout,

    }