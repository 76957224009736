import React, {useState} from "react";
import {DataGrid, DataGridProps, GridFilterModel, GridPaginationModel, GridSortModel} from "@mui/x-data-grid";
import {useInterval} from "../../../hooks/PollingHook";


export type PhixDataGridProps = DataGridProps & {
    apiFunction?: Function,
    staticFilter?: Object,
    rows?: Array<any>,
    pollingInterval?: Number,
    enablePolling?: boolean,
    loadingIndicator?: boolean,
    dataFn?: Function
}


export default function PhixDataGrid(props: PhixDataGridProps) {
    const [filterState, setFilterState] = React.useState<GridFilterModel>({
        items: [],
        logicOperator: undefined,
        quickFilterExcludeHiddenColumns: false,
        quickFilterLogicOperator: undefined,
        quickFilterValues: [],
    })
    const [paginationState, setPaginationState] = React.useState<GridPaginationModel>({
        page: 0,
        pageSize: 25
    })
    const [loading, setLoading] = useState(false)
    const refreshData = () => {
        if (apiFunction) {
            let offset = paginationState.page * paginationState.pageSize
            let searchAll = (filterState.quickFilterValues && filterState.quickFilterValues.length > 0) ? filterState.quickFilterValues?.join(" ") : null
            setLoading(true)
            apiFunction(offset, paginationState.pageSize, {
                    sorts: sortModel.map(s => {
                        return {field: s.field, dir: s.sort}
                    })
                },
                {
                    ...props.staticFilter,
                    searchAll: searchAll
                }
            ).then((data: any) => {
                setLoading(false)
                let preData = data?.payload ? data.payload : []
                setRows(preData)
                setRowCount(data?.totalCount ? data.totalCount : 0)
            })

        }
    }

    useInterval(() => {
        if (props.enablePolling) {
            refreshData()
        }
    }, props.pollingInterval || 5000)

    const [rows, setRows] = useState([])
    const [rowCount, setRowCount] = useState(0)
    const [sortModel, setSortModel] = useState<GridSortModel>(props.sortModel || [])
    const {apiFunction} = props

    React.useEffect(() => {
        refreshData()
    }, [apiFunction, filterState, paginationState, sortModel, props.dataFn])


    const onFilterChange =
        React.useCallback((filterModel: GridFilterModel) => {
            // console.log(filterModel)
            setFilterState(filterModel)
        }, [])

    const onPaginationChange =
        React.useCallback((paginationModel: GridPaginationModel) => {
            setPaginationState(paginationModel)
        }, [])

    const onSortChange =
        React.useCallback((sortModel: GridSortModel) => {
            setSortModel(sortModel)
            // console.log(sortModel)
        }, [])

    return <DataGrid {...props}
                     onFilterModelChange={onFilterChange}
                     loading={props.loadingIndicator ? loading : false}
                     filterModel={filterState}
                     onPaginationModelChange={onPaginationChange}
                     paginationModel={paginationState}
                     paginationMode={"server"}
                     onSortModelChange={onSortChange}
                     sortingMode={"server"}
                     rows={rows}
                     filterMode="server"
                     rowCount={rowCount}

    />


}
