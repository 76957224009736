import React, {useEffect, useState} from 'react';
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {TileContainer} from "../../layout/TileContainer";
import TileHeader from "../../layout/TileHeader";
import {TileBody} from "../../layout/TileBody";
import {Col, Row} from "../../layout/GridLayout";
import {Chip} from "@progress/kendo-react-buttons";
import {PhixPermissions} from "../../config/GlobalConfig";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {useDispatch, useSelector} from "react-redux";
import {PatientAction} from "../../action/PatientAction";
import {DictionaryDispatchAction as DictionaryAction} from "../../action/DictionaryDispatchAction";

const AnalyticRow = (props) => {
    const {data} = props
    const [item1, item2] = data
    return (
        item2 ?
            <Row className={"justify-content-center align-content-center"}>
                <Tooltip openDelay={0}
                         position="top"
                         anchorElement="target"/>
                <Col col={4}>
                    <span style={{fontSize: 12, fontWeight: "bold"}}>{item1.label}</span>
                </Col>
                <Col col={2}>
                    <Chip
                        style={{height: 20, width: 'max-content', backgroundColor: item1.color}}
                        rounded={"full"}
                        title={item1.label}
                        themeColor={item1.color}
                        text={item1.value}>
                        <span title={item1.textType}>{item1.value}</span>
                    </Chip>
                </Col>
                <Col col={4}>
                    <span style={{fontSize: 12, fontWeight: "bold"}}>{item2.label}</span>
                </Col>
                <Col col={2}>
                    <Chip
                        title={item2.label}
                        style={{height: 20, width: 'max-content', backgroundColor: item2.color}}
                        rounded={"full"}
                        themeColor={item2.color}
                        text={item2.value}>
                        <span title={item2.textType}>{item2.value}</span>
                    </Chip>
                </Col>
            </Row>
            : <Row className={"justify-content-center align-content-center"}>
                <Tooltip openDelay={0}
                         position="top"
                         anchorElement="target"/>
                <Col col={4}>
                    <span style={{fontSize: 12, fontWeight: "bold"}}>{item1.label}</span>
                </Col>
                <Col col={2}>
                    <Chip
                        style={{height: 20, width: 'max-content', backgroundColor: item1.color}}
                        rounded={"full"}
                        title={item1.label}
                        themeColor={item1.color}
                        text={item1.value}>
                        <span title={item1?.textType}>{item1.value}</span>
                    </Chip>
                </Col>
            </Row>);

}


const AnalyticProfilePatientPanelComponent = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [analydata, setAnalyData] = useState([])
    const PatientContext = useSelector(state => state.patient.patientContext)
    const getAnalyticProfileForPatient = useSelector(state => state.patient.AnalyticProfileDetails)
    const getDictionaryList = useSelector(state => state.dictionary.dictionaries?.AH_PATIENT_AGGREGATE)

    useEffect(() => {
        const patientRoleId = {
            "patientRoleId": PatientContext?.patientRoleId
        };
        const fetchData = async () => {
            await PatientAction.getAnalyticProfileForPatient(patientRoleId, "PatientPanel")(dispatch)
        }
        fetchData().then(() => setLoading(false))
        const fetchDataDic = async () => {
            await DictionaryAction.searchDictionaryList("AH_PATIENT_AGGREGATE")(dispatch)
        }
        fetchDataDic().then(() => setLoading(false))
    }, [])

    useEffect(() => {
        setAnalyData([]);
        let dataAnalyticProfilePatientPanel = [];
        if (getDictionaryList?.length > 0 && getAnalyticProfileForPatient?.PatientPanel) {
            const activeListPatientAggs = getDictionaryList.filter(item => {
                return item.active === true;
            });

            activeListPatientAggs.map(activeListPatientAgg => {
                let childData = {
                    text: "",
                    textType: "",
                    color: "",
                    type: activeListPatientAgg?.code,
                    displayName: activeListPatientAgg?.displayName
                }
                const analyticProfilePatientPanelDataCategory = getAnalyticProfileForPatient?.PatientPanel?.filter(item => {
                    return item.attributeType === activeListPatientAgg.code;
                });

                if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "High Risk" && activeListPatientAgg?.code == "AH_LACE") {
                    childData.text = "High Risk";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#E06666";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "Low Risk" && activeListPatientAgg?.code == "AH_LACE") {
                    childData.text = "Low Risk";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#6AA84F";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.numericValue == 0 && activeListPatientAgg?.code === "AH_FRAILTY_INDEX") {
                    childData.text = "Fit";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#6AA84F";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "Fit" && activeListPatientAgg?.code === "AH_FRAILTY_INDEX") {
                    childData.text = "Fit";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#6AA84F";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "Mild" && activeListPatientAgg?.code === "AH_FRAILTY_INDEX") {
                    childData.text = "Mild";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#F1C232";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "Moderate" && activeListPatientAgg?.code === "AH_FRAILTY_INDEX") {
                    childData.text = "Moderate";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#E69138";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "Severe" && activeListPatientAgg?.code === "AH_FRAILTY_INDEX") {
                    childData.text = "Severe";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#E06666";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.numericValue == 0 && activeListPatientAgg?.code === "AH_CHARLSON") {
                    childData.text = "No Risk";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#6AA84F";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "Mild" && activeListPatientAgg?.code === "AH_CHARLSON") {
                    childData.text = "Mild";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#6AA84F";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "Moderate" && activeListPatientAgg?.code === "AH_CHARLSON") {
                    childData.text = "Moderate";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#E69138";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "Severe" && activeListPatientAgg?.code === "AH_CHARLSON") {
                    childData.text = "Severe";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#E06666";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "High Risk" && activeListPatientAgg?.code === "AH_FALL_RISK") {
                    childData.text = "High Risk";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#E06666";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "Low Risk" && activeListPatientAgg?.code === "AH_FALL_RISK") {
                    childData.text = "Low Risk";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#a3c37c";
                } else if (analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeText == "No Risk" && activeListPatientAgg?.code === "AH_FALL_RISK") {
                    childData.text = "No Risk";
                    childData.textType = analyticProfilePatientPanelDataCategory[0]?.attributeDetails[0]?.attributeTextType
                    childData.color = "#6AA84F";
                }

                dataAnalyticProfilePatientPanel.push(childData);
                childData = {
                    text: "",
                    textType: "",
                    color: "",
                    type: "",
                    displayName: ""
                }

            });
            setAnalyData(dataAnalyticProfilePatientPanel);
        }
    }, [getDictionaryList?.length, getAnalyticProfileForPatient?.PatientPanel])


    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_PATIENT_METRICS]}>
                <TileContainer>
                    <TileHeader className="boxHeader ah-draggable-header"
                                headerColor={"black"}
                                title="Analytic Profile"/>
                    <TileBody style={{padding: 10, height: "100%", width: "100%", backgroundColor: "white"}}>
                        {(() => {
                            let analyticRowData = [];
                            let dataForRow = [];
                            const lastIndex = analydata.length - 1;
                            let count = 1;
                            for (let i = 0; i < analydata.length; i++) {
                                let dataRowObj = {
                                    label: analydata[i].displayName,
                                    value: analydata[i].text,
                                    color: analydata[i].color,
                                    textType: analydata[i].textType
                                }
                                if (count == 2) {
                                    dataForRow.push(dataRowObj)
                                    analyticRowData.push(<AnalyticRow data={dataForRow}/>);
                                    count = 1;
                                    dataForRow = [];
                                } else if (count == 1 && i == lastIndex) {
                                    dataForRow.push(dataRowObj)
                                    analyticRowData.push(<AnalyticRow data={dataForRow}/>);
                                    count = 1;
                                    dataForRow = [];
                                } else if (count == 1) {
                                    dataForRow.push(dataRowObj)
                                    count = count + 1
                                }
                            }

                            return analyticRowData;
                        })()}
                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    );
};


export default AnalyticProfilePatientPanelComponent