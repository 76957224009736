import {Window} from "@progress/kendo-react-dialogs";
import {Input} from "@progress/kendo-react-inputs";
import * as React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {connect} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {Grid, GridColumn, GridToolbar} from "@progress/kendo-react-grid";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {Button, Chip, ChipList} from "@progress/kendo-react-buttons";
import _ from "lodash";


const SearchRxNormCodeWindow = (props) => {

    const {PopupActions, popupState} = props
    const [filterState, setFilterState] = React.useState(false)
    const [searchState, setSearchState] = React.useState("")
    const [dataState, setDataState] = React.useState({take: 10, skip: 0,});
    const [selectedCodes, setSelectedCodes] = React.useState([]);

    const hideDialog = () => {
        setSelectedCodes([])
        setSearchState("")
        setDataState({take: 10, skip: 0})
        PopupActions.hideWindow(PopupWindows.SEARCH_RxNorm_CODE_WINDOW);
    };
//popupState[PopupWindows.SEARCH_RxNorm_CODE_WINDOW] ? popupState[PopupWindows.SEARCH_RxNorm_CODE_WINDOW].codes :


    React.useEffect(() => {
        if (popupState[PopupWindows.SEARCH_RxNorm_CODE_WINDOW]?.codes) {
            setSelectedCodes(popupState[PopupWindows.SEARCH_RxNorm_CODE_WINDOW]?.codes)
        } else {
            setSelectedCodes([])
        }
    }, [props.popupState])

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const ActionCell = (props) => {
        const {dataItem} = props
        return (

            <td>
                <Button style={{color: "#00b344"}} look={"outline"}
                        onClick={(e) => addCode(dataItem)}
                        icon={"checkmark"}/>
            </td>

        )
    }


    const addCode = (code) => {
        setSelectedCodes(_.uniq([...selectedCodes, code]))
    };

    React.useEffect(() => {
        if (selectedCodes.length > 0)
            PopupActions.setWindowState(PopupWindows.SEARCH_RxNorm_CODE_WINDOW, {codes: selectedCodes})
    }, [selectedCodes])

    const removeCode = (e) => {
        const data1 = [...selectedCodes];
        const originalItem = selectedCodes.find((p) => p.id === e.target.props.id);
        data1.splice(data1.indexOf(originalItem), 1);
        setSelectedCodes(data1);
    }

    React.useEffect(() => {
        if (props.showWindow[PopupWindows.SEARCH_RxNorm_CODE_WINDOW] === true) {
            if (searchState && searchState.length > 1) {
                props.DictionaryActions.searchDictionaryListByIndex(Dictionary.AH_RXNORM, searchState, dataState.take, dataState.skip)
            } else {
                props.DictionaryActions.getDictionaryByCode(Dictionary.AH_RXNORM, dataState.take, dataState.skip)
            }

        }
    }, [props.showWindow, dataState])


    React.useEffect(() => {
        if (searchState.length > 0) {
            props.DictionaryActions.searchDictionaryListByIndex(Dictionary.AH_RXNORM, searchState, dataState.take, dataState.skip)
        }
    }, [searchState])

    if (props.showWindow[PopupWindows.SEARCH_RxNorm_CODE_WINDOW]) return (
        <Window title={"Medication Code Search"} onClose={hideDialog} initialWidth={850} modal={true}
                initialHeight={800}>

            <Grid
                {...dataState}
                style={{height: "100%"}}
                data={props.dictionaries[Dictionary.AH_RXNORM]}
                pageSize={dataState.limit}
                total={props.pagination[Dictionary.AH_RXNORM]?.totalCount}
                filterable={filterState}
                sortable={true}
                skip={dataState.skip}
                pageable={{
                    buttonCount: 5, info: true, type: "numeric", pageSizes: true, previousNext: true,
                }}

                onDataStateChange={dataStateChange}>
                <GridToolbar>
                    <ChipList
                        selection="single"
                        data={selectedCodes}
                        chip={(props) => <Chip removable={true} {...props} id={props.dataItem.id} onRemove={removeCode}
                                               text={props.dataItem.code}/>}
                    />
                    <Button style={{float: "right"}} icon={"check"} onClick={hideDialog}>Done</Button>
                    <Input placeholder="Search Medication Codes" onChange={(e) => setSearchState(e.target.value)}/>
                    {/*<Input placeholder="Search Diagnostic Codes"/>*/}
                </GridToolbar>
                <GridColumn field="code" title="Code" width={"150px"}/>
                <GridColumn field="displayName" title="Description"/>
                <GridColumn field="" title={""} width={"70px"} cell={ActionCell}/>

            </Grid>


        </Window>); else return null;
};

SearchRxNormCodeWindow.propTypes = {
    showWindow: PropTypes.object,
    dictionaries: PropTypes.object,
    patient: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        patient: state.patient,
        dictionaries: state.dictionary.dictionaries,
        pagination: state.dictionary.pagination,
        popupState: state.popupWindow.state,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),

        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchRxNormCodeWindow);
