import {ActionTypes} from "../action/SecuritySettingsAction";


const initialState = {
}

export const securitySettingsReducer = (state = initialState, action) =>
{
    switch (action.type) {
        case ActionTypes.GET_SECURITY_SETTINGS:
            return {...state, securitySettings: action.payload.payload }
        default:
            return state;
    }

}

