import React from "react";
import {Grid, GridColumn, GridToolbar} from "@progress/kendo-react-grid";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {Button} from "primereact/button";
import {EditCommandCell} from "./EditCommandCell";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {PersonActions} from "../../action/PersonAction";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {InlineDictionaryDropDown, InlinePrimaryCheckbox, InlineTextInput} from "../InputControls/InlineFields";
import _ from "lodash";
import {PatientAction} from "../../action/PatientAction";


const StateDropdown = (props) =>
    <InlineDictionaryDropDown
        {...props}
        idField={"dictStateId"}
        displayField={"dictStateDisplayName"}
        code={Dictionary.STATES}/>

const AddressTypeDropdown = (props) => <InlineDictionaryDropDown
    {...props}
    idField={"dictionaryId"}
    displayField={"dictionaryDisplayName"}
    code={Dictionary.ADDRESS_CONTACT}/>

const ContactAddressComponent = (props) => {
    const [data, setData] = React.useState(props?.contactAddress);
    const editField = "inEdit";
    const [errors, setErrors] = React.useState({})
    React.useEffect(() => {
        props.PersonActions.getContactAddress(props?.personId || props?.createPatientContext?.personId);
    }, [props?.personId, props?.createPatientContext?.personId])


    React.useEffect(() => {
        setData(props.contactAddress)
    }, [props.contactAddress])


    const addNew = () => {
        let primary = !props?.contactAddress?.find(e => e.isPrimary === true)
        let newDataItem = {
            id: uuidv4(),
            newItem: true,
            inEdit: true,
            isPrimary: primary,
            personId: props?.personId || props?.createPatientContext?.personId,
        };
        setData([...data, newDataItem]);
    };


    const enterEdit = (dataItem) => {
        let newData = data.map((item) =>
            item.id === dataItem.id ? {...item, inEdit: true} : item
        );
        setData(newData);
    };

    const itemChange = (event) => {
        const field = event.field || "";
        const newData = data.map((item) => {
            if (item.id === event.dataItem.id){
                if (field === "dictionaryId") {
                    const add = props?.address?.find(v=> v.id === event.value || v.id === event?.value?.id)
                    if (add && add.code === "AH_FACILITY_ADDRESS") {
                        return {...item,
                        [field]: event.value,
                        ["street1"]: props?.patientContext?.facilityStreet1 || props?.createPatientContext?.facilityStreet1,
                        ["street2"]: props?.patientContext?.facilityStreet2 || props?.createPatientContext?.facilityStreet2,
                        ["city"]: props?.patientContext?.facilityCity || props?.createPatientContext?.facilityCity,
                        ["dictStateId"]: props?.patientContext?.facilityDictStateId || props?.createPatientContext?.facilityDictStateId,
                        ["zipCode"]: props?.patientContext?.facilityZipCode || props?.createPatientContext?.facilityZipCode
                        }
                    }
                }
                return {...item, [field]: event.value};
            } else {
                return item;
            }
        });
        setData(newData);
    };

    const cancel = (dataItem) => {
        const data1 = [...data];
        const originalItem = data.find((p) => p.id === dataItem.id);
        data1.splice(data1.indexOf(originalItem), 1);
        setData(data1);
    }


    const remove = (dataItem) => {
        if (window.confirm("Are you sure you want to delete this address?")) {
            props.PersonActions.removeContactAddress(dataItem.id).then(() => props.PersonActions.getContactAddress(props?.personId || props?.createPatientContext?.personId))
        }
    }


    const validateFields = (dataItem) => {
        let errors = {}
        if (!dataItem.dictionaryId) errors = {...errors, dictionaryId: "This field is required."}
        if (!dataItem.street1) errors = {...errors, street1: "This field is required."}
        if (!dataItem.city) errors = {...errors, city: "This field is required."}
        if (!dataItem.dictStateId) errors = {...errors, dictStateId: "This field is required."}
        if (!dataItem.zipCode) errors = {...errors, zipCode: "This field is required."}
        if (_.isEmpty(errors))
            return true
        else {
            itemChange({
                dataIndex: 0,
                dataItem: dataItem,
                field: "errors",
                value: errors,
            })
            return false
        }

    }

    const add = (dataItem) => {
        if (validateFields(dataItem)) {
            props.PersonActions.addContactAddress(props?.personId || props?.createPatientContext?.personId, dataItem.dictionaryId, dataItem.street1, dataItem.street2, dataItem.city, dataItem.dictStateId, dataItem.zipCode, dataItem.country, dataItem.isPrimary)
                .then(() => {
                    props.PersonActions.getContactAddress(props?.personId || props?.createPatientContext?.personId)
                    if (props.patientContext?.patientRoleId && props.patientContext?.patientRoleId === props.createPatientContext?.patientRoleId) props.PatientActions.setPatientContextRemote(props.patientContext.patientRoleId)
                })
            setErrors({})
        }
    };

    const update = (dataItem) => {
        if (validateFields(dataItem)) {
            props.PersonActions.updateContactAddress(dataItem.id, dataItem.dictionaryId, dataItem.street1, dataItem.street2, dataItem.city, dataItem.dictStateId, dataItem.zipCode, dataItem.country, dataItem.isPrimary)
                .then(() => {
                    props.PersonActions.getContactAddress(props?.personId || props?.createPatientContext?.personId)
                    if (props.patientContext?.patientRoleId && props.patientContext?.patientRoleId === props.createPatientContext?.patientRoleId) props.PatientActions.setPatientContextRemote(props.patientContext.patientRoleId)
                })
            setErrors({})
        }
    };

    const edit = (props) => {
        return (
            <EditCommandCell
                {...props}
                editField={editField}
                cancel={cancel}
                remove={remove}
                update={update}
                add={add}
                edit={enterEdit}
            />
        );
    };


    return (
        <Grid
            editField={editField}
            data={data}
            onItemChange={itemChange}
        >
            <GridToolbar>
                <Button onClick={addNew} icon={"pi pi-plus"}
                        text/>

                <Button icon={"pi pi-refresh"}
                        text
                        onChange={() => props.PersonActions.getContactPhone(props?.personId || props?.createPatientContext?.personId)}/>
            </GridToolbar>
            <GridColumn field="dictionaryId" title="Type" cell={AddressTypeDropdown}/>
            <GridColumn field="street1" title="Address1" cell={InlineTextInput}
            />
            <GridColumn field="street2" title="Address2"/>
            <GridColumn field="city" title="City"/>
            <GridColumn field="dictStateId" title="State" cell={StateDropdown}/>
            <GridColumn field="zipCode" title="Zip"/>
            <GridColumn
                editor={"boolean"}
                field="isPrimary"
                title="Primary"
                cell={InlinePrimaryCheckbox}/>
            <GridColumn width={"120px"} field="Actions" title="Action" cell={edit}/>
        </Grid>
    );
};

function mapStateToProps(state) {
    return {
        patientContext: state.patient.patientContext,
        address: state?.dictionary?.dictionaries?.AH_ADDRESS,
        createPatientContext: state.patient.createPatientContext,
        contactAddress: state.person.personContextAddress,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PatientActions: bindActionCreators(PatientAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactAddressComponent)
