import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    FORMS_LIST_VITAL: "FORMS_LIST_VITAL",
}

function saveVitalFormSchema(payload) {
    return WrapAuthentication("observation/vital/save",{
        payload:payload
    })
}
function getVitalData(patientRoleId) {
    return WrapAuthentication("observation/get/latest/vital",{
        payload:{
            patientRoleId: patientRoleId,
        }
    }, ActionTypes.PATIENT_LIST_VITALS)
}
export const VitalAction =
    {
        saveVitalFormSchema: saveVitalFormSchema,
        getVitalData:getVitalData,
    }