import {bindActionCreators} from "redux";
import {PatientAction} from "../../../action/PatientAction";
import {DictionaryDispatchAction} from "../../../action/DictionaryDispatchAction";
import {DictionaryTypeAction} from "../../../action/DictionaryTypeAction";
import {PersonActions} from "../../../action/PersonAction";
import {PopupWindowActions} from "../../../action/PopupWindowAction";
import {CarePlanActions} from "../../../action/CarePlanAction";
import {connect} from "react-redux";
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import {WindowActionsBar} from "@progress/kendo-react-dialogs";
import React from "react";
import {useForm} from "react-hook-form";
import {Field, FieldDictionaryDropDown, FieldTextAreaInput} from "../../InputControls/FormFields";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Col, Container, Row} from 'react-grid-system';
import {Dictionary} from "../../InputControls/DictionaryDropDownList";
import {ListView, ListViewHeader} from "@progress/kendo-react-listview";
import {Colors} from "../../../config/GlobalConfig";
import {Grid, GridColumn} from "@progress/kendo-react-grid";

const ClinicalStrategyStep = (props) => {
    const {
        register,
        handleSubmit,
        clearErrors,
        reset,
        getValues,
        setValue,
        control,
        formState: { errors, isDirty, isSubmitting, touchedFields, submitCount },
    } = useForm();

    const {
        medicalProblems,
        patientContext,
        PatientActions,
        CarePlanActions,
        stepIndex,
        nextStep,
        previousStep,
        hideDialog,
        steps,
        setFormFromStep,
        form,
        carePlanEdit,
        carePlan,
    } = props

    const removeStrategy = (item) => {
        // console.log(item)
        CarePlanActions.deleteStrategyToGoal(item.id, carePlanEdit.condition.id)

    }

    const listHeader = () => {
        return (
            <ListViewHeader
                style={{
                    color: "rgb(160, 160, 160)",
                    fontSize: 14,
                }}
                className="pl-3 pb-2 pt-2"
            >
                {/* Goal Strategies: {carePlanEdit.condition.displayName} */}
                <Row justify={"start"} align={"center"} style={{ borderBottom: "1px solid rgb(242, 242, 242)", backgroundColor: Colors.LIGHT_GRAY, margin:'0' }}>
                    <Col>Clinical Strategy</Col>
                    <Col>Goal</Col>
                    <Col>Description</Col>
                    <Col>Action</Col>
                </Row>
            </ListViewHeader>
        );
    };


    const itemRenderer = (props) => {
        let item = props.dataItem;

        return (
             
                <div>
                    {item?.strategies && item.strategies.map((i) => {
                        return <Container  >
                            <Row justify={"start"} align={"center"}
                                style={{ borderBottom: "1px solid rgb(242, 242, 242)" }}>
                                <Col>
                                    {i.dictGoalStrategyDisplayName}
                                </Col>
                                <Col>
                                {item.description}
                                </Col>
                                <Col>
                                    {i.description}
                                </Col>
                                <Col>
                                    <ButtonGroup>
                                        <Button icon={"delete"} look={"flat"}
                                            onClick={() => removeStrategy(i)} />
                                        <Button icon={"edit"} look={"flat"} onClick={() => setCurrentStrategy(i)} />
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Container>

                    })}
                </div>
             
        );
    };


    const [goals, setGoals] = React.useState(carePlanEdit.goals ? carePlanEdit.goals : [])
    const [determinants, setDeterminants] = React.useState(carePlanEdit.determinants ? carePlanEdit.determinants : [])
    const [currentGoal, setCurrentGoal] = React.useState({})
    const [currentGoalError, setCurrentGoalError] = React.useState(null)
    const [currentStrategy, setCurrentStrategy] = React.useState({})


    React.useEffect(() => {
        setGoals(carePlanEdit.goals)
    }, [carePlanEdit.goals])


    React.useEffect(() => {
        if (currentStrategy?.id) {
            setValue("description", currentStrategy.description)
            setValue("dictGoalStrategyId", currentStrategy.dictGoalStrategyId)
            let currentGoal = goals.find(d => d.id === currentStrategy.carePlanGoalId)
            setCurrentGoal(currentGoal)
        }

    }, [currentStrategy])

    const addStrategyToGoal = (form) => {
        if (!currentGoal?.id) {
            setCurrentGoalError("Select a goal to add this strategy.")
        } else {
            if (currentStrategy?.id) {
                // console.log(form)
                CarePlanActions.updateStrategyToGoal(currentStrategy.id, form.dictGoalStrategyId?.id ? form.dictGoalStrategyId.id : form.dictGoalStrategyId, carePlanEdit.condition.id, currentGoal.id, form.description)

            } else {
                CarePlanActions.addStrategyToGoal(currentGoal.id, form.dictGoalStrategyId?.id ? form.dictGoalStrategyId.id : form.dictGoalStrategyId, carePlanEdit.condition.id, form.description)
            }
            setCurrentStrategy(null)
            reset({
                dictGoalStrategyId: null,
                description: null
            })
            setCurrentGoal(null)
            setCurrentGoalError(null)

        }
        //CarePlanActions.addStrategyToGoal()

    }

    return <div style={{ height: "calc(100% - 120px)" }}> 
        <Container fluid>
        <h5 style={{margin:'10px 0'}}> Clinical Strategies  </h5>
            <Row style={{ backgroundColor: Colors.LIGHT_GRAY, padding:'10px 0' }}>
                <Col sm={6}>
                    <label>Select Goal</label>
                    <DropDownList
                        style={{ width: "100%" }}
                        data={goals}
                        onChange={(e) => setCurrentGoal(e.target.value)}
                        dataItemKey={"id"}
                        value={currentGoal}
                        textField={"description"}
                    />
                    {currentGoalError && <span className="k-error-colored"> {currentGoalError}</span>}
                </Col>

                <Col col={6}> 
                    <Grid
                    style={{
                        height: "120px",
                      }}
                        data={determinants} 
        fixedScroll={true}
                    >
                        <GridColumn field="dictDeterminantDisplayName" title="Condition Determinants" />
                    </Grid>
                </Col>
            </Row> 
            <h5 style={{margin:'10px 0'}}> Add New Clinical Strategy for Selected Goal  </h5>
            <Row>
            
                <Field col={12} label={"Select Clinical Strategy"} field={"dictGoalStrategyId"} error={errors}
                    register={register}
                    options={{ required: "Goal strategy is required." }}
                    control={control}>
                    <FieldDictionaryDropDown code={Dictionary.CARE_PLAN_STRATEGIES}
                        dataItem={currentGoal}
                    // defaultItemCode={"AH_GOAL_STATUS_IN_PROGRESS"}
                    />
                </Field>

            </Row>
            <Row>
                <Field col={12}   field={"description"} error={errors}
                    register={register}
                    //options={{required: "Goal description is required."}}
                    control={control}>
                    <FieldTextAreaInput rows={4} />
                </Field>
                <Col sm={12} >
                    <Button icon={currentStrategy?.id ? "save" : "add"} primary={true}
                        onClick={handleSubmit(addStrategyToGoal)} style={{ float: 'right', margin:'0 0 10px 0', }} >

                            Add 
                            </Button>
                    {/*<Button icon={"add"} onClick={handleSubmit(addStrategyToGoal)}/>*/}
                </Col>

            </Row>
            <h5 style={{margin:'10px 0'}}> All Clinical Strategies for Condition  </h5>
            <Row>
                <Col col={12}>
                    <ListView
                        style={{ height: "200px" }}
                        data={goals}
                        header={listHeader}
                        item={itemRenderer}
                        textField={"displayName"}
                        selectedField={"selected"} />
                </Col>
            </Row>

        </Container>
        <br />
        <WindowActionsBar>
            {stepIndex > 0 && <Button icon={"arrow-left"} themeColor={"primary"} onClick={previousStep}>
                Back
            </Button>}
            <Button themeColor={"primary"} onClick={hideDialog}>
                Finish
            </Button>
        </WindowActionsBar>
    </div>

}

function mapStateToProps(state) {
    return {
        carePlan: state.patient.carePlan,
        showWindow: state.popupWindow.showWindow,
        patientContext: state.patient.patientContext,
        carePlanEdit: state.patient.carePlanEdit,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        CarePlanActions: bindActionCreators(CarePlanActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalStrategyStep)