import {Button} from "primereact/button"
import PropTypes from "prop-types";
import {Controller, useForm} from "react-hook-form";
import {Col, Container} from "../../layout/GridLayout";
import React, {useEffect, useMemo, useState} from "react";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {useToast} from "../../providers/toast/ToastProvider";
import {Calendar} from "primereact/calendar";
import {Checkbox} from "./Checkbox";
import DictionaryDropDownList from "./NewDictionaryDropDownList";
import FacilityDropDownList from "./NewFacilityDropDownList";
import _ from "lodash";
import MultiDictionarySelect from "./NewMultiDictionarySelect";
import UserDropDownList from "./NewUserDropDownList";
import {
    JsDateToStringFormat,
    JsDateToUtcIso,
    OffsetUTCDateStringToJsDate,
    ParseStringToJsDateWithFormat
} from "../../util/DateTools";
import {InputTextarea} from "primereact/inputtextarea";
import {childrenWithProps} from "../shared/Helpers";
import {Card} from "primereact/card";
import MultiOrderCheckboxSelector from "./MultiOrderCheckboxSelector";
import ExternalVendorDropdownList from "./ExternalVendorDropdownList";
import {InputNumber} from "primereact/inputnumber";
import NewMultiDictionarySelect from "./NewMultiDictionarySelect";
import MultiProblemCheckboxSelector from "./MultiProblemCheckboxSelector";


/**
 * Use this if you just need a simple form ie no watching etc.
 * @param props
 * @constructor
 */
export const FormContainer = (props) => {
    const {className = '', style = {}, form} = props
    return (<Container className={className} style={style}>
            {childrenWithProps(props.children, {control: form.control, errors: form.formState.errors})}
        </Container>
    )
}
FormContainer.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    form: PropTypes.object.isRequired
}


/**
 * @deprecated since version 2.0
 */
export const usePhixForm = (props) => {
    const form = useForm(props?.defaultValues ? {defaultValues: props.defaultValues} : {});


    return [form, (props) => <FormContainer {...props} form={form}/>]
}

export const FormRow = (props) => {
    const {className = '', style = {}} = props
    // const children = React.cloneElement(props.children, {control: props.control, errors: props.errors})

    return (
        <div className={className || "grid gap-0"} style={{width: "100%", ...style}}>
            {childrenWithProps(props.children, {control: props.control, errors: props.errors})}
        </div>
    )
}

export const FormCard = (props) => {
    const {className = '', style = {}} = props
    // const children = React.cloneElement(props.children, {control: props.control, errors: props.errors})

    return (
        <Card style={{width: "100%", ...style}}>
            {childrenWithProps(props.children, {control: props.control, errors: props.errors})}
        </Card>
    )
}

export const FormColumn = (props) => {
    return (
        <Col {...props} style={{paddingTop: 0, paddingBottom: 0, ...props.style}}>
            {childrenWithProps(props.children, {control: props.control, errors: props.errors})}
        </Col>
    )
}

const getFormErrorMessage = (error) => {
    return error?.message ?
        <div className="p-error" style={{width: "100%", marginBottom: 20}}>{error.message}</div> :
        <small className="p-error">&nbsp;</small>;
};

export const WrapFloatingLabel = (props) => {
    const {error, label} = props
    const {addToast} = useToast()
    // useTraceUpdate(props, 'Wrap label')
    useEffect(() => {
        if (error?.message) {
            addToast({severity: "error", summary: "Form Error", detail: error.message})
        }
    }, [error])
    return (
        <div style={{width: "100%"}}>
            <label htmlFor={label} className={classNames({'p-error': error?.message})}/>
            <div className="p-float-label" style={{width: "100%", fontSize: 12}}>
                {props.children}
                <label style={{width: "90%"}} htmlFor={label}>{label}</label>
            </div>
            {getFormErrorMessage(error)}
        </div>
    )

}

export const WrapNoLabel = (props) => {
    // useTraceUpdate(props, 'Wrap no label')
    const {error, label} = props
    const {addToast} = useToast()
    const [sendError, setSendError] = useState(true)

    useMemo(() => {
        if (error?.message) {
            setSendError(false)
            addToast({severity: "error", summary: "Form Error", detail: error?.message})
        } else
            setSendError(true)
    }, [error])
    return (
        <div style={{width: "100%"}}>
            {props.children}
            {getFormErrorMessage(error)}
        </div>
    )

}

export const FormTextInput = (props) => {
    const {control, errors, label, rules, field, disabled, textArea = false, rows = 4, cols = 30, hidden} = props
    // useTraceUpdate(props, 'Text Input')
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapFloatingLabel label={label} error={errors[field.name]} field={field}>
                    {textArea ? <InputTextarea id={field.name}
                                               value={field.value}
                                               rows={rows}
                                               cols={cols}
                                               width={"100%"}
                                               style={{width: "100%"}}
                                               disabled={disabled}
                                               className={classNames({'p-invalid': fieldState.error})}
                                               onChange={(e) => field.onChange(e.target.value)}/>
                        : <InputText id={field.name}
                                     value={field.value}
                                     width={"100%"}
                                     style={{width: "100%"}}
                                     disabled={disabled}
                                     className={classNames({'p-invalid': fieldState.error})}
                                     onChange={(e) => field.onChange(e.target.value)}/>
                    }

                </WrapFloatingLabel>
            )}
        />
    )
}

export const FormNumberInput = (props) => {
    const {control, errors, label, rules, field, disabled} = props
    // useTraceUpdate(props, 'Text Input')
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapFloatingLabel label={label} error={errors[field.name]} field={field}>
                    <InputNumber
                        inputStyle={{width: "100%"}}
                        showButtons
                        id={field.name}
                        value={field.value}
                        disabled={disabled}
                        className={classNames({'p-invalid': fieldState.error})}
                        onChange={(e) => field.onChange(e.value)}/>
                </WrapFloatingLabel>
            )}
        />
    )
}

export const FormSeparator = (props) => {
    return <FormRow>
        <FormColumn col={12}>
            <hr/>
        </FormColumn>
    </FormRow>

}


export const FormPasswordInput = (props) => {
    const {control, errors, label, rules, field, passwordFix = false, disabled} = props
    const [passwordShown, setPasswordShown] = useState(false);
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (

                <div className="p-inputgroup">
                    <WrapFloatingLabel label={label} error={errors[field.name]} field={field}>

                        <InputText id={field.name}
                                   disabled={disabled}
                                   type={passwordShown ? "text" : "password"}
                                   value={field.value}
                                   width={"100%"}
                                   style={{width: "100%"}}
                                   className={classNames({'p-invalid': fieldState.error})}
                                   onChange={(e) => field.onChange(e.target.value)}/>

                        <Button

                            onClick={(e) => {
                                e.preventDefault()
                                setPasswordShown(!passwordShown)
                            }}
                            severity={passwordShown ? "danger" : "success"}
                            icon={passwordShown ? "pi pi-eye" : "pi pi-eye-slash"}/>

                    </WrapFloatingLabel>
                </div>
            )}
        />
    )
}

export const FormDropDown = (props) => {
    const {control, errors, label, rules, field, options, optionLabel} = props
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapFloatingLabel label={label} error={errors[field.name]} field={field}>
                    <Dropdown
                        style={props.style || {width: "100%"}}
                        id={field.name}
                        value={field.value}
                        optionLabel={optionLabel ? optionLabel : false}
                        options={options}
                        focusInputRef={field.ref}
                        onChange={(e) => field.onChange(e.value)}
                        className={classNames({'p-invalid': fieldState.error})}/>
                </WrapFloatingLabel>
            )}
        />
    )
}

const DateSelector = (props) => {
    // // useTraceUpdate(props, "Date selector")
    const {value, onChange, name, dateFormat, error, showTime = false, defaultNow = false} = props
    const [internalValue, setInternalValue] = useState(null)
    const handleChange = (e) => {
        setInternalValue(e.value)
        if (onChange) {
            let d
            if (dateFormat)
                d = JsDateToStringFormat(e.value, dateFormat)
            else
                d = JsDateToUtcIso(e.value)
            // console.log(`Date: ${dateFormat} : ${d}`)
            e.value = d
            e.target.value = d
            onChange(e)
        }
    }
    useEffect(() => {
        if (defaultNow && _.isNil(value)) {
            handleChange({value: new Date(), target: {}})
        }
    }, [])

    useEffect(() => {
        if (!internalValue && _.isString(value)) {
            if (dateFormat) {
                // console.log(`Date: ${dateFormat} : ${value} : ${ParseStringToJsDateWithFormat(value, dateFormat)}`)
                setInternalValue(ParseStringToJsDateWithFormat(value, dateFormat))
            } else
                setInternalValue(OffsetUTCDateStringToJsDate(value))
        }
    }, [])
    return (
        <Calendar style={{width: "100%", ...props.style}}
            // touchUI
            // inputId={name}
                  hourFormat={"12"}
                  value={internalValue}
                  onChange={handleChange}
            // dateFormat={dateFormat ? dateFormat : null}
                  showTime={showTime}
                  className={classNames({'p-invalid': error})}/>
    )
}

export const FormDateSelector = (props) => {
    // useTraceUpdate(props, 'Date Selector')
    const {control, errors, label, rules, field, dateFormat, showTime, defaultNow} = props
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapFloatingLabel label={label} error={errors[field.name]} field={field}>
                    <DateSelector inputId={field.name}
                                  defaultNow={defaultNow}
                                  showTime={showTime}
                                  value={field.value}
                                  onChange={field.onChange}
                                  dateFormat={dateFormat}
                                  className={classNames({'p-invalid': fieldState.error})}/>

                </WrapFloatingLabel>
            )}
        />
    )
}

export const FormToggleButton = (props) => {
    const {control, errors, label, rules, field} = props
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <Checkbox inputId={field.name}
                          label={label}
                          checked={field.value}
                          inputRef={field.ref}
                          className={classNames({'p-invalid': fieldState.error})}
                          onChange={(e) => field.onChange(e.checked)}/>


            )}
        />
    )
}

export const FormDictionaryDropdown = (props) => {
    const {
        style,
        control,
        rules,
        field,
        code,
        defaultItemCode,
        disabled,
        active,
        label,
        errors,
        showCode = false
    } = props
    // useTraceUpdate(props, "Dictionary Dropdown")
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapFloatingLabel label={label} error={errors[field.name]} field={field}>
                    <DictionaryDropDownList
                        style={{width: "100%", height: "40px", ...style}}
                        defaultItemCode={defaultItemCode}
                        className={classNames({'p-invalid': fieldState.error})}
                        showCode={showCode}
                        onChange={(e) => {
                            field.onChange(e.value)
                        }}
                        fieldName={field.name}
                        inputRef={field.ref}
                        code={code}
                        value={field.value}
                        active={active}
                        disabled={disabled}
                    />
                </WrapFloatingLabel>


            )}
        />
    )
}


export const FormFacilitySelector = (props) => {
    const {style, control, rules, field, code, defaultItemCode, disabled, active, label, errors} = props
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapFloatingLabel label={label} error={errors[field.name]} field={field}>
                    <FacilityDropDownList
                        style={{width: "100%", height: "40px", ...style}}
                        defaultItemCode={defaultItemCode}
                        className={classNames({'p-invalid': fieldState.error})}
                        onChange={(e) => {
                            field.onChange(e.value)
                        }}
                        fieldName={field.name}
                        inputRef={field.ref}
                        code={code}
                        value={field.value}
                        active={active}
                        disabled={disabled}
                    />
                </WrapFloatingLabel>


            )}
        />
    )
}

export const FormMultiDictionaryDropdown = (props) => {
    const {style, control, rules, field, code, defaultItemCode, disabled, active, label, errors, showCode} = props
    // useTraceUpdate(props, 'Multi DropDown')
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapNoLabel label={label} error={errors[field.name]} field={field}>
                    <NewMultiDictionarySelect
                        label={label}
                        error={errors[field.name]}
                        fullObject={props.fullObject}
                        style={{width: "100%", marginBottom: 15, ...style}}
                        defaultItemCode={defaultItemCode}
                        className={classNames({'p-invalid': fieldState.error})}
                        onChange={(e) => {
                            field.onChange(e)
                        }}
                        showCode={showCode}
                        fieldName={field.name}
                        inputRef={field.ref}
                        code={code}
                        value={field.value}
                        active={active}
                        disabled={disabled}
                    />
                </WrapNoLabel>


            )}
        />
    )
}

export const FormUserListDropdown = (props) => {
    const {
        style,
        control,
        rules,
        field,
        code,
        disabled,
        active,
        label,
        errors,
        showCode,
        defaultCurrentUser = false
    } = props
    // useTraceUpdate(props, 'Multi DropDown')
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapFloatingLabel label={label} error={errors[field.name]} field={field}>
                    <UserDropDownList
                        error={errors[field.name]}
                        fullObject={props.fullObject}
                        style={{width: "100%", ...style}}
                        defaultCurrentUser={defaultCurrentUser}
                        className={classNames({'p-invalid': fieldState.error})}
                        onChange={(e) => {
                            field.onChange(e)
                        }}
                        showCode={showCode}
                        fieldName={field.name}
                        inputRef={field.ref}
                        value={field.value}
                        active={active}
                        disabled={disabled}
                    />
                </WrapFloatingLabel>


            )}
        />
    )
}
export const FormExternalVendorDropdown = (props) => {
    const {
        style,
        control,
        rules,
        field,
        options,
        disabled,
        active,
        label,
        errors,
        optionLabel,
    } = props
    // useTraceUpdate(props, 'Multi DropDown')
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapFloatingLabel label={label} error={errors[field.name]} field={field}>
                    <ExternalVendorDropdownList style={{width: "100%", ...style}}
                                                ref={field.ref}
                                                className={classNames({'p-invalid': fieldState.error})}
                                                resetFilterOnHide
                                                showFilterClear
                                                disabled={disabled}
                                                options={options}
                                                value={field.value}
                                                optionLabel={optionLabel}
                                                onChange={(e) => field.onChange(e.value)}
                    />
                </WrapFloatingLabel>


            )}
        />
    )
}


export const FormDropdownList = (props) => {
    const {
        style,
        control,
        rules,
        field,
        options,
        disabled,
        active,
        label,
        errors,
        optionLabel,
    } = props
    // useTraceUpdate(props, 'Multi DropDown')
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapFloatingLabel label={label} error={errors[field.name]} field={field}>
                    <Dropdown style={{width: "100%", ...style}}

                              ref={field.ref}
                              className={classNames({'p-invalid': fieldState.error})}
                              resetFilterOnHide
                              showFilterClear
                              disabled={disabled}
                              options={options}
                              value={field.value}
                              optionLabel={optionLabel}
                              onChange={(e) => field.onChange(e.value)}
                    />
                </WrapFloatingLabel>


            )}
        />
    )
}
export const FormMultiOrderCheckboxSelector = (props) => {
    const {
        style,
        control,
        rules,
        field,
        options,
        disabled,
        active,
        label,
        errors,
        optionLabel,
    } = props
    // useTraceUpdate(props, 'Multi DropDown')
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapNoLabel label={label} error={errors[field.name]} field={field}>
                    <MultiOrderCheckboxSelector style={{width: "100%", ...style}}
                                                label={label}
                                                ref={field.ref}
                                                className={classNames({'p-invalid': fieldState.error})}
                                                resetFilterOnHide
                                                showFilterClear
                                                disabled={disabled}
                                                options={options}
                                                value={field.value}
                                                optionLabel={optionLabel}
                                                onChange={(e) => field.onChange(e.value)}
                    />
                </WrapNoLabel>


            )}
        />
    )
}


export const FormMultiProblemCheckboxSelector = (props) => {
    const {
        style,
        control,
        rules,
        field,
        options,
        disabled,
        active,
        label,
        errors,
        optionLabel,
    } = props
    // useTraceUpdate(props, 'Multi DropDown')
    return (
        <Controller
            name={field}
            control={control}
            rules={rules}
            render={({field, fieldState}) => (
                <WrapNoLabel label={label} error={errors[field.name]} field={field}>
                    <MultiProblemCheckboxSelector style={{width: "100%", ...style}}
                                                  label={label}
                                                  ref={field.ref}
                                                  className={classNames({'p-invalid': fieldState.error})}
                                                  resetFilterOnHide
                                                  showFilterClear
                                                  disabled={disabled}
                                                  options={options}
                                                  value={field.value}
                                                  optionLabel={optionLabel}
                                                  onChange={(e) => field.onChange(e.value)}
                    />
                </WrapNoLabel>


            )}
        />
    )
}