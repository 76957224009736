import {ActionTypes} from "../action/ComponentUpdateAction";


const initialState = {
    patientList: 0,
    patientContext: 0,
    patientAllergy: 0,
    patientOrders: 0

}

export const ComponentUpdateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_PATIENT_LIST:
            return {...state, patientList: state.patientList + 1}
        case ActionTypes.UPDATE_PATIENT_CONTEXT:
            return {...state, patientContext: state.patientContext + 1}
        case ActionTypes.UPDATE_PATIENT_ALLERGIES:
            return {...state, patientContext: state.patientAllergy + 1}
        case ActionTypes.UPDATE_PATIENT_ORDERS:
            return {...state, patientOrders: state.patientOrders + 1}
        default:
            return state;
    }

}

