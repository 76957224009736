import {ActionTypes} from '../action/InsuranceAction';

const initialState = {}

export const insuranceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_INSURANCE_PLAN_ALL:
            return {...state, insurancePlans: action.payload.payload, totalCount: action.payload.totalCount};
        case ActionTypes.SET_INSURANCE_PLAN_CONTEXT:
            return {...state, insurancePlanContext: action.payload};
        default:
            return state;
    }
}