import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    INSERT_OR_UPDATE_SECURITY_SETTINGS : "INSERT_OR_UPDATE_SECURITY_SETTINGS",
    GET_SECURITY_SETTINGS : "GET_SECURITY_SETTINGS",
}

function insertOrUpdateSecuritySettings(payload) {
    return WrapAuthentication("configuration/tenant/insert",{
        payload:payload
    }, ActionTypes.INSERT_OR_UPDATE_SECURITY_SETTINGS)
}
function getSecuritySetting(payload) {
    return WrapAuthentication("configuration/tenant",{
        payload:payload
    }, ActionTypes.GET_SECURITY_SETTINGS)
}
export const SecuritySettingsAction =
    {
        insertOrUpdateSecuritySettings: insertOrUpdateSecuritySettings,
        getSecuritySetting:getSecuritySetting,
    }