import {ActionTypes} from '../action/LoadingAction';

const initialState = {}

export const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.COMPONENT_START_LOADING:
            return {...state, [action.component]: true}
        case ActionTypes.COMPONENT_DONE_LOADING:
            return {...state, [action.component]: false}
        default:
            return state;
    }
}