import {connect} from "react-redux";
import React from "react";
import {bindActionCreators} from "redux";
import {WindowActionsBar} from "@progress/kendo-react-dialogs";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {CarePlanActions} from "../../action/CarePlanAction";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {useForm} from "react-hook-form";
import {Dictionary} from "../../components/InputControls/DictionaryDropDownList";
import {Button} from "primereact/button";
import {Field, FieldDictionaryDropDown, FieldSection, FieldTextInput} from "../InputControls/FormFields";
import {Dialog} from "primereact/dialog"

const AddStrategyToGoalWindow = (props) => {

    const [problem, setProblem] = React.useState(props?.windowState?.problem);
    const [goal, setGoal] = React.useState(props?.windowState?.goal);

    React.useEffect(() => {
        setProblem(props?.windowState?.problem)
        setGoal(props?.windowState?.goal)
    }, [props.windowState])

    const {
        handleSubmit,
        register,
        control,
        reset,
        setValue,
        formState: {errors},
    } = useForm({defaultValues: {description: ""}});

    const [dataState, setDataState] = React.useState({
        take: 5, skip: 0,
    });

    const sort = [{
        field: "displayName", dir: "asc",
    },];

    const hideDialog = () => {
        setValue("strategy", null)
        setValue("description", null)
        reset();
        props.CarePlanActions.setEditCarePlanCondition({payload: {}});
        props.PopupActions.clearWindowState(PopupWindows.ADD_STRATEGY_TO_GOAL);
        props.PopupActions.hideWindow(PopupWindows.ADD_STRATEGY_TO_GOAL);
    }

    function onSubmit(form) {
        props.CarePlanActions.addStrategyToGoal(goal.id, form.strategy.id, problem.id, form.description).then(() => {
            props.CarePlanActions.getCarePlanForPatientContext(dataState.take, dataState.skip, sort);
            hideDialog();
        });
    }

    return (
        <Dialog
            visible={props.showWindow[PopupWindows.ADD_STRATEGY_TO_GOAL]}
            header={"Add Strategy"}
            onHide={hideDialog}
            style={{width: 500,}}
        >
            <FieldSection>
                <Field label={"Strategy"} field={"strategy"}
                       error={errors}
                       register={register}
                       control={control}
                       options={{required: "Strategy is required"}}>
                    <FieldDictionaryDropDown
                        code={Dictionary.CARE_PLAN_STRATEGIES}
                        active={true}
                        setDefaultValue={{displayName: "Select status"}}
                    />
                </Field>
                <Field label={"Description"} field={"description"}
                       error={errors}
                       register={register}
                       control={control}>
                    <FieldTextInput/>
                </Field>
            </FieldSection>
            <WindowActionsBar>
                <Button
                    
                    onClick={handleSubmit(onSubmit)}
                    size={"small"}>
                    Add </Button>
                <Button onClick={hideDialog}
                        size={"small"}>
                    Cancel
                </Button>

            </WindowActionsBar>
        </Dialog>
    );

}

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        windowState: state.popupWindow.state?.ADD_STRATEGY_TO_GOAL,
        dictionaries: state.dictionary.dictionaries,
        popupState: state.popupWindow.state,
        condition: state.patient.carePlanEdit?.condition,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        CarePlanActions: bindActionCreators(CarePlanActions, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddStrategyToGoalWindow);