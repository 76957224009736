import {InputText} from 'primereact/inputtext';
import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {PatientAction} from "../action/PatientAction";
import {PersonActions} from "../action/PersonAction";
import {UserActions} from "../action/UserAction";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";
import {Button} from "primereact/button"
import {Checkbox} from "@progress/kendo-react-inputs";
import {orderBy} from "@progress/kendo-data-query";
import TileHeader from "../layout/TileHeader";
import {PopupWindowActions as PopupActions, PopupWindowActions, PopupWindows} from "../action/PopupWindowAction";
import {useHistory} from "react-router-dom";
import {TileContainer} from "../layout/TileContainer";
import {TileBody} from "../layout/TileBody";
import GlobalStyles from "../config/GlobalStyles";
import {Colors, PhixPermissions} from "../config/GlobalConfig";
import {FaCheckCircle} from 'react-icons/fa'
import {PermissionsTool} from "../util/PermissionsTool";
import {ColumnLayout} from "../layout/GridLayout";
import {Menubar} from "primereact/menubar";
import ShowForPermissionComponent, {usePermission} from "./shared/ShowForPermissionComponent";

const PatientListComponent = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const styles = {
        grid: {
            height: "100%",
            width: "100%"
        }
    }
    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });
    const [searchState, setSearchState] = React.useState("")
    const [myPatients, setMyPatients] = React.useState(true)
    const [myInactivePatients, setInactiveMyPatients] = React.useState(false)

    const [filterState, setFilterState] = React.useState(false)

    const [filterPatientData, setFilterPatientData] = React.useState({
        logic: "and", filters: []

    })

    const filterChangeState = (filter) => {
        setFilterPatientData(filter)
    }

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };
    const initialSort = [
        {
            field: "lastName",
            dir: "asc",
        },
    ];
    const [sort, setPatientSort] = React.useState(initialSort);
    let filteredPatientList = props.patients ? props.patients : []
    //const [filteredPatientList, setFilteredPatientList] = React.useState(props.patients ? props.patients : [])


    const [myPatientsPermissions, setMyPatientsPermissions] = React.useState({isEdit: false, isView: false});
    const [patientsPermissions, setPatientsPermissions] = React.useState({isEdit: false, isView: false});

    React.useEffect(() => {
        setMyPatientsPermissions(PermissionsTool({
            permissionInfo: props.permissionInfo,
            viewPermissions: ["AH_VIEW_MY_PATIENTS"],
            editPermissions: ["AH_EDIT_MY_PATIENTS"]
        }));
        setPatientsPermissions(PermissionsTool({
            permissionInfo: props.permissionInfo,
            viewPermissions: ["AH_VIEW_PATIENT"],
            editPermissions: ["AH_EDIT_PATIENT"]
        }));
    }, [props?.permissionInfo]);

    React.useEffect(() => {
        props.PersonActions.clearContactPreferences()
    }, [props?.patients]);


    function refreshPatientList() {
        let filters = {}
        if (searchState?.length > 0) filters.searchAll = searchState
        if (filterPatientData?.filters) {
            if (filterPatientData.filters.length > 0) {
                filterPatientData.filters.forEach(x => {
                    if (x.field) {
                        filters[x.field] = x.value
                    }
                })
            }
        }
        let allPatients = false;
        if (!myPatients) {
            allPatients = true;
        }

        if (myInactivePatients) {
            filters["active"] = false;
        } else {
            filters["active"] = true;
        }

        props.PatientActions.getPatientList(dataState.take, dataState.skip, sort, filters, allPatients)

    }

    React.useEffect(() => {
            refreshPatientList();
        }, [dataState, sort, filterPatientData, searchState, myPatients, myInactivePatients]
    )


    const showCreatePatient = () => {
        props.PopupActions.showWindow(PopupWindows.CREATE_PATIENT_WINDOW)
    };
    const patientAddress = (props) => {
        const {street1 = "", street2 = "", city = "", state = "", zipCode = ""} = props.dataItem;
        const completeAddress = `${street1}${street2 ? ` ${street2}.` : ''}${city ? ` ${city}` : ''}${state ? ` ${state}` : ''}${zipCode ? ` ${zipCode}` : ''}`;
        if (completeAddress !== 'null' && completeAddress !== null) {
            return <td><span>{(completeAddress)}</span></td>
        } else {
            return <td><span></span></td>
        }
    }

    const PortalUserCell = (props) => {
        if (props.dataItem?.portalUser) {
            return <td>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}><FaCheckCircle
                    color={Colors.GREEN}/></div>
            </td>
        }
        return <td>
            {((patientsPermissions.isView && patientsPermissions.isEdit) || (myPatientsPermissions.isView && myPatientsPermissions.isEdit)) &&
                <div className={"flex flex-wrap justify-content-center gap-2"}>
                    <Button
                        text
                        icon={"pi pi-user-plus"}
                        size={"small"}
                        tooltip={"Invite this patient to the patient portal."}
                        disabled={!props.dataItem.active}
                        onClick={async () => {
                            await PopupActions.setWindowState(PopupWindows.USER_CREATE_PORTAL_REGISTRATION, props.dataItem)(dispatch)
                            await PopupActions.showWindow(PopupWindows.USER_CREATE_PORTAL_REGISTRATION)(dispatch)
                        }}/></div>}
        </td>

    }

    const patientDateAge = (props) => {
        const {dateOfBirth = ""} = props.dataItem;
        if (dateOfBirth === "")
            return "(N/A)"
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        const completeDate = `${dateOfBirth} (${age}y)`;
        return <td><span>{completeDate}</span></td>
    }

    const imgHeader = (props) => {
        return (
            <span
                style={{
                    display: "none",
                }}
            >
                {props.title}
            </span>
        );
    };

    const imgCell = (props) => {
        return (
            <td>
                <img src={props.dataItem.patientImage} style={{width: 30, height: 30}}/>
            </td>
        )
    }
    const startContent = (
        <React.Fragment>
            <div className="flex flex-wrap justify-content-center gap-2">
            <span className="p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText placeholder="Search" value={searchState} onChange={(e) => {
                    setSearchState(e.target.value)
                }}/>
            </span>

                <Button icon={"pi pi-ban"}
                        text
                        onClick={() => setSearchState("")}/>

                <Button
                    togglable={true}
                    icon="pi pi-filter"
                    text={!filterState}
                    size={"small"}
                    onClick={() => setFilterState(!filterState)}>Filter</Button>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_PATIENT]}>
                    <div className="flex align-items-center">
                        <Checkbox inputId="myPatients"
                                  onChange={(e) => setMyPatients(e.value)}
                                  checked={myPatients}/>
                        <label style={{fontSize: 14}} htmlFor="myPatients" className="ml-1">View My Patients</label>
                    </div>
                </ShowForPermissionComponent>

                <div className="flex align-items-center">
                    <Checkbox inputId="inactivePatients" value="Cheese" onChange={(e) => setInactiveMyPatients(e.value)}
                              checked={myInactivePatients}/>
                    <label style={{fontSize: 14}} htmlFor="inactivePatients" className="ml-1">View Inactive Patients</label>
                </div>

                {/*<Checkbox*/}
                {/*    value={myInactivePatients}*/}
                {/*    onChange={(e) => setInactiveMyPatients(e.value)}*/}
                {/*    label={"Inactive Patients"}/>*/}

            </div>
        </React.Fragment>
    )
    const edit = usePermission([PhixPermissions.AH_EDIT_PATIENT, PhixPermissions.AH_EDIT_MY_PATIENTS])

    function endContent() {

        return (
            <React.Fragment>
                <ShowForPermissionComponent
                    permissions={[PhixPermissions.AH_EDIT_PATIENT, PhixPermissions.AH_EDIT_MY_PATIENTS]}>
                    <Button className="rounded-button" text="Image"
                            label={"Invite"}
                            icon={"pi pi-user-plus"}
                            tooltip={"Invite a patient to use the portal"}
                            tooltipOptions={{position: "top"}}
                            onClick={(e) => props.PopupActions.showWindow(PopupWindows.USER_CREATE_PORTAL_REGISTRATION)}/>
                    <Button className="rounded-button"
                            icon={"pi pi-plus"}
                            label={"New"}
                            tooltip={"Create a new patient"}
                            tooltipOptions={{position: "top"}}
                            text
                            onClick={(e) => props.PopupActions.showWindow(PopupWindows.CREATE_PATIENT_WINDOW)}/>
                </ShowForPermissionComponent>
            </React.Fragment>
        )
    }

    return (
        <TileContainer>
            <TileHeader title="Patient List"
                        refresh={() => refreshPatientList()}
                        style={GlobalStyles.tileHeaderBlueWithBlack}/>
            <TileBody>
                <ColumnLayout col={12}>
                    <Menubar style={{}} start={startContent} end={endContent}/>
                </ColumnLayout>
                <ColumnLayout gutter={false} col={12} style={{height: "calc(100% - 85px)"}}>
                    <Grid
                        {...dataState}
                        style={styles.grid}

                        data={orderBy(filteredPatientList, sort)}
                        pageSize={dataState.limit}
                        // style={{height: "calc(100vh - 100px)"}}
                        total={props.totalCount}
                        filterable={filterState}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setPatientSort(e.sort);
                        }}
                        skip={dataState.skip}
                        onRowClick={(e) => {

                            props.PatientActions.clearPatientContext().then(() => {
                                    props.PatientActions.setPatientContext(e.dataItem).then(() => {
                                       history.push(`/patientPanel?patient_id=${e.dataItem.patientRoleId}`)
                                    })
                                    //Lets use the context pattern here.

                                }
                            )
                            //history.push(`/patientPanel/${e.dataItem.personId}`)
                        }}
                        pageable={{
                            buttonCount: 10,
                            info: true,
                            type: "numeric",
                            pageSizes: [50, 100, 150],
                            previousNext: true,
                        }}
                        onFilterChange={(e) => filterChangeState(e.filter)}
                        onDataStateChange={dataStateChange}
                        filter={filterPatientData}
                    >
                        {/* <GridColumn field="img" title="." headerCell={imgHeader} cell={imgCell} width={60}
                            filterable={false} /> */}
                        <GridColumn field="patientName" title="Patient Name"/>
                        {/* <GridColumn field="firstName" title="Patient Name" cell={name} /> */}
                        {/*<GridColumn field="lastName" value={filterPatientData} title="Last Name" />*/}
                        {/*<GridColumn field="firstName" value={filterPatientData} title="First Name" />*/}
                        {/* <GridColumn field="genderName" value={filterPatientData} title="Gender" /> */}


                        <GridColumn field="dateOfBirth" title="DOB" cell={patientDateAge}/>
                        <GridColumn field="facilityName" title="Facility" />
                        <GridColumn field="providerName" title="Provider" />
                        <GridColumn field="street1" title="Address" cell={patientAddress}/>
                        <GridColumn width={100} field="portalUser" title={"Portal"} cell={PortalUserCell}/>
                        {/* <GridColumn field="hraCompleted" title="HRA Completed" /> */}
                        {/* <GridColumn cell={CommandCell} width="200px" filterable={false} /> */}

                    </Grid>

                    {/*<PatientListLoaderComponent dataState={dataState} onDataReceived={dataReceived}/>*/}
                </ColumnLayout>

            </TileBody>


        </TileContainer>
    );
};

PatientListComponent.propTypes =
    {
        PatientActions: PropTypes.object,
        PopupActions: PropTypes.object,
    }

function mapStateToProps(state) {
    return {
        patients: state.patient.patientList.patients,
        totalCount: state.patient.patientList.totalCount,
        getHeartVariability: state.patient.getHeartVariability,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PersonActions: bindActionCreators(PersonActions, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientListComponent)
