import * as React from "react";
import {useEffect} from "react";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Button} from "primereact/button";
import {UserActions} from "../../action/UserAction";
import {Field, FieldDictionaryDropDown, FieldRow, FieldSection, FieldTextInput} from "../InputControls/FormFields";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {PatientAction} from "../../action/PatientAction"
import {useToast} from "../../providers/toast/ToastProvider";
import {Dialog} from "primereact/dialog";
import {Col} from "../../layout/GridLayout";
import DocumentUpload from "../shared/DocumentUpload";


const CreateRequestDocumentWindow = (props) => {
    const {addToast} = useToast()
    const {
        userContext,
        PopupActions,
        UserActions,
        AuthAction,
        createNewUser,
        userLicense,
        PatientActions,
        createRequestContext,
        requestContext,
        clinicalDocumentContext,
        ...others
    } = props;
    const [value, setFileValue] = React.useState("");
    const [errMessage, setErrMessage] = React.useState();
    const [documentId, setDocumentId] = React.useState("");
    const [patientdata, setPatientdata] = React.useState(null);
    const [requestId, setRequestId] = React.useState(null);

    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        formState: {errors,},
    } = useForm({defaultValues: {active: true, status: true}});

    useEffect(() => {
        if (errMessage)
            addToast({
                summary: "Document Error",
                detail: errMessage
            })
    }, [errMessage])


    useEffect(() => {
        if (typeof props.dictionaries['AH_DOCUMENT_CATEGORIES'] !== "undefined") {
            props.dictionaries['AH_DOCUMENT_CATEGORIES'].map(item => {

                if (item.code == Dictionary.AH_HOSPITAL_ADMISSION_DOCUMENTS) {
                    setDocumentId(item.id)
                    setValue("dictDocumentCategoryId", item.id)
                }

            });
        }

    }, [props.dictionaries['AH_DOCUMENT_CATEGORIES']]);

    useEffect(() => {
        if (createRequestContext) {
            setRequestId(createRequestContext.baseRequestId)
        }
    }, [createRequestContext])

    useEffect(() => {
        if (requestContext) {
            setRequestId(requestContext.id)
        }
    }, [requestContext])

    useEffect(() => {
        setPatientdata(props.BaseRequest.patientModel)
    }, [props.BaseRequest.patientModel]);

    useEffect(() => {
        if (props.clinicalDocument?.payload) {
            addToast({
                severity: "success",
                summary: "Document Uploaded",
                detail: "Document Uploaded Successfully."
            })
            if (props.hospitalizationContext?.id)
                props.PatientActions.getClinicalDocument({"hospitalizationId": props.hospitalizationContext?.id});
            hideDialog();
        }
    }, [props.clinicalDocument])

    const hideDialog = () => {
        PopupActions.hideWindow(PopupWindows.CREATE_REQUEST_DOCUMENT_WINDOW)
        reset({
            active: true,
            status: true,
            title: null,
            displayName: null,
            dictDocumentCategoryId: null
        });
    };


    const saveDocument = (form) => {
        let formData = new FormData();
        console.log("Save")

        formData.append("patientRoleId", props?.PatientContext?.patientRoleId);
        formData.append("active", form?.active);
        formData.append("status", form?.status ? form?.status : true);
        formData.append("title", form?.title);
        formData.append("displayName", form?.displayName);
        formData.append("fileName", value);
        formData.append('dictDocumentCategoryId', form?.dictDocumentCategoryId?.id ? form?.dictDocumentCategoryId.id : form?.dictDocumentCategoryId)
        let hospitalizationId = props.hospitalizationContext?.id;
        formData.append('hospitalizationId', hospitalizationId)
        if (hospitalizationId)
            props.PatientActions.clinicalDocument(formData).then(() => {
                hideDialog();
            });
    }


    return (
        <Dialog
            visible={props.showWindow[PopupWindows.CREATE_REQUEST_DOCUMENT_WINDOW]}
            header={"Upload Document"}
            modal={true}
            onHide={hideDialog}
            footer={<Col className={"justify-content-center"}>
                <Button
                    label={"Save"}
                    severity={"success"}
                    onClick={handleSubmit(saveDocument)}/>
                <Button label={"Cancel"}
                        severity={"danger"}
                        onClick={hideDialog}/>


            </Col>}>


            <FieldSection>
                <FieldRow>
                    <Field className={"col-6"} label={"Title"} field={"title"} error={errors}
                           control={control} register={register}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>
                    <Field className={"col-6"} label={"Display Name"} field={"displayName"} error={errors}
                           control={control} register={register}>
                        <FieldTextInput style={{border: "2px solid lightgray"}}/>
                    </Field>

                </FieldRow>
                <FieldRow>
                    <Field col={12} label={"Document category"} field={"dictDocumentCategoryId"} error={errors}
                           control={control}
                           register={register}
                           options={{required: "Document category is required."}}>
                        <FieldDictionaryDropDown code={Dictionary.AH_DOCUMENT_CATEGORIES}
                                                 defaultItemCode={"AH_HOSPITAL_ADMISSION_DOCUMENTS"}
                            // setDefaultValue={props.encounterContext ? props.encounterContext?.dictDocumentCategoryId : { displayName: "Select document category" }}
                                                 disabled={props.encounterContext?.dictDocumentCategoryId ? true : false}
                                                 dataItem={userContext}
                        />
                    </Field>

                    <Field col={12} label={"File Name"} field={"fileName"} error={errors}
                           control={control} register={register} options={{required: "File name is required."}}>
                        <DocumentUpload onChange={setFileValue}/>

                    </Field>
                </FieldRow>
            </FieldSection>


        </Dialog>
    );

}

CreateRequestDocumentWindow.propTypes = {
    showWindow: PropTypes.object,
    PopupActions: PropTypes.object,
    UserActions: PropTypes.object,
    authActions: PropTypes.object,
    PatientActions: PropTypes.object,
    PatientContext: PropTypes.object,
    popupState: PropTypes.object
}


function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        userContext: state.user.userContext,
        createNewUser: state.user.createNewUser,
        userLicense: state.user.userLicenseContext,
        PatientContext: state.patient.patientContext,
        clinicalDocument: state.patient.clinicalDocument,
        getUsersInfo: state.user.loggedinUser,
        clinicalDocumentContext: state.patient.clinicalDocumentContext,
        editClinicalDocument: state.patient.editClinicalDocument,
        encounterContext: state.encounters.encounterContext,
        popupState: state.popupWindow.state,
        dictionaries: state.dictionary.dictionaries,
        BaseRequest: state.BaseRequest,
        createRequestContext: state.BaseRequest.createRequestContext,
        requestContext: state.BaseRequest.RequestContext,
        hospitalizationID: state.patient.hospitalizationID,
        hospitalizationContext: state.patient.hospitalizationContext,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRequestDocumentWindow)