import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {AuthAction} from "../action/AuthAction";
import {LayoutAction} from "../action/LayoutAction";
import {connect, useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import {PhixPermissions} from "../config/GlobalConfig";
import moment from 'moment';
import TileHeader from "../layout/TileHeader";
import {Components} from "./shared/ComponentRegistry";
import {PanelBar, PanelBarItem} from "@progress/kendo-react-layout";
import {NotificationAction} from "../action/NotificationAction";
import {PopupWindowActions, PopupWindows} from "../action/PopupWindowAction";
import ShowForPermissionComponent from './shared/ShowForPermissionComponent';
import {Col, Container, Row} from "../layout/GridLayout";
import {TileContainer} from "../layout/TileContainer";
import GlobalStyles from "../config/GlobalStyles";
import {TileBody} from "../layout/TileBody";
import {Tooltip} from "@progress/kendo-react-tooltip";
import './panelBar.css';


const styles = {
    notificationWrapper: {
        width: "100%",
        position: 'relative',
        paddingTop: '35px',
        height: "100%",

    },
    notificationBody: {
        // minHeight: '35vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(100% - 55px)',
    },
    row: {
        width: "100%",
    },
    col3: {
        width: "16%",
        float: 'left',
        margin: '0',
        padding: '0px',
    },
    col2: {
        width: "20%",
        float: 'left',
        margin: '0',
        padding: '0px',
    },
    col1: {
        width: "2%",
        float: 'left',
        margin: '0',
        padding: '0px',
    },
    col6: {
        width: "68%",
        float: 'left',
        margin: '0px',
        padding: '0px',
    },
    col7: {
        width: "78%",
        float: 'left',
        margin: '0',
        padding: '0px',
    },
    col5: {
        width: "50%",
        float: 'left',
        margin: '0px',
        padding: '0px',
    },
    colP: {
        margin: '0px',
        padding: '2px 5px',
        fontSize: '12px',
    },
    colPRed: {
        margin: '0px',
        padding: '2px 5px',
        fontSize: '12px',
        color: '#f00',
    },
    colPB: {
        margin: '0px',
        padding: '2px 5px',
        fontSize: '12px',
        fontWeight: '600',
    },
    colIconAct: {
        margin: '5px',
        padding: '0px',
        fontSize: '22px',
        color: 'rgb(113, 186, 96)',
        position: 'relative',
    },
    colIcon: {
        margin: '5px',
        padding: '0px',
        fontSize: '22px',
        color: 'rgb(204, 204, 204)',
        position: 'relative',
    },
    colIconText: {
        position: 'absolute',
        fontSize: '11px',
        color: '#ffffff',
        left: '8px',
        top: '3px',
    },
    groupHeader: {
        backgroundColor: '#dce0ee',
        padding: '4px 5px',
        textAlign: 'center',
        width: '100%',
        float: 'left',
    },
    moreButton: {
        color: '#7585c2',
        fontWeight: '400',
        textDecoration: 'underline',
        float: 'right',
        background: 'none',
        border: 'none',
        margin: '0 5px',
        cursor: 'pointer',
    },
    messageAvatar: {
        border: '2px solid #ffd966',
        width: '25px', height: '25px',
        flexBasis: "25px",
        backgroundColor: "#fff",
        marginRight: "5px"
    },
}

const getFormattedDate = (latestUpdatedTime) => {
    const createdDate = moment.utc(latestUpdatedTime).local().startOf('day')
    const current = moment().startOf('day');
    const diffInDays = moment.duration(createdDate.diff(current)).asDays();


    if (diffInDays < 0) {
        return "Overdue Now";
    } else if (diffInDays === 0) {
        return "Today";
    } else if (diffInDays <= 7) {
        return "Next 7 Days";
    }
    return "Next 30 Days"

}

const isPassedDate = (date) => {
    const createdDate = moment.utc(date).local().startOf('day')
    const current = moment().startOf('day');
    const diffInDays = moment.duration(createdDate.diff(current)).asDays();

    // const inputDate = moment(date);
    // const diffInDays = inputDate.diff(moment(), 'days');
    return diffInDays < 0;
}
const calendarClick = () => {
    alert("Edit Not implemented yet");
}
const NotificationListHeader = () => (
    <Row style={{height: 25}}>
        <Col col={8}>
            <p style={{
                fontSize: '14px',
                fontWeight: '600',
                padding: '2px 5px',
                margin: '0',
                color: '#7484c2'
            }}> Description </p>
        </Col>
        <Col col={3}>
            <p style={{
                fontSize: '14px',
                fontWeight: '600',
                padding: '2px 5px',
                margin: '0',
                color: '#7484c2'
            }}>Due</p>
        </Col>
        <div style={styles.col3}>
        </div>
    </Row>
)


const NotificationItem = (props) => {
    const {notification, showNotificationReply} = props

    const toggleDialog = (notification) => {
        props.notificationActions.setNotificationContext(notification)
        props.PopupActions.showWindow(PopupWindows.NOTIFICATION_DETAILS_WINDOW)
    };


    return (
        <>
            <Row style={{padding: 5}}>
                <Col col={7} style={{cursor: 'pointer'}}
                     onClick={() => toggleDialog(notification)}>
                    <p style={notification.active ? styles.colPB : styles.colP}> {notification.displayMessage} </p>
                </Col>
                <Col col={4}>
                    <p style={isPassedDate(notification.dueDate) ? styles.colPRed : styles.colP}>
                        {moment.utc(notification.dueDate).local().format("M/D/YYYY")}
                    </p>
                </Col>
                <Col col={1} className={"flex-row-reverse"}>
                    {notification.actionTypeCode === "AH_AT_SCHEDULE_ENCOUNTER" &&
                        <i onClick={(e) => calendarClick()}
                           style={notification.active ? styles.colIcon : styles.colIconAct}
                           className={notification.active ? "fa fa-calendar-o" : "fa fa-calendar-check-o"}
                           aria-hidden="true"/>}


                </Col>

            </Row>
            {/*{showNotificationReply &&*/}
            {/*    <div style={styles.row}>*/}
            {/*        <div style={{*/}
            {/*            marginLeft: '35px', display: 'block', paddingLeft: '5px', borderLeft: 'solid 1px #ccc',*/}
            {/*        }}>*/}
            {/*            <div className="replayList">*/}
            {/*                <div className="row p-2 border-bottom align-middle">*/}
            {/*                    <div className="col-12">*/}
            {/*                        <div className="k-hbox">*/}
            {/*                            <Avatar shape="circle" type="icon" style={styles.messageAvatar}>*/}
            {/*                                <span class="k-icon k-i-user" style={{color: '#ffd966',}}/>*/}
            {/*                            </Avatar>*/}
            {/*                            <div>*/}
            {/*                                <h2 style={{*/}
            {/*                                    fontSize: "1em",*/}
            {/*                                    color: '#7585c2',*/}
            {/*                                    fontWeight: 600,*/}
            {/*                                    textDecoration: 'underline',*/}
            {/*                                    margin: 0,*/}
            {/*                                }}*/}
            {/*                                >*/}
            {/*                                    @ Reply div*/}
            {/*                                </h2>*/}
            {/*                                <p className="msgText"*/}
            {/*                                   style={{*/}
            {/*                                       margin: 0,*/}
            {/*                                       fontSize: "1em",*/}
            {/*                                       fontWeight: 400,*/}
            {/*                                       color: '#6c6c76'*/}
            {/*                                   }}*/}
            {/*                                >*/}
            {/*                                    Lorem ipsum*/}
            {/*                                </p>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
        </>
    )
}

const NotificationItemList = (props) => {
    const [showNotificationReplyFor, setShowNotificationReplyFor] = useState('');
    const {notifications} = props
    let previousGroupHeader = '';
    return notifications.map(notification => {
        let showHeader = false;
        const groupHeader = getFormattedDate(notification.dueDate);
        if (previousGroupHeader !== groupHeader) {
            showHeader = true;
            previousGroupHeader = groupHeader;
        }
        return (
            <Fragment key={notification.notificationId}>
                <NotificationItem
                    notificationActions={props.notificationActions}
                    PopupActions={props.PopupActions}
                    notification={notification}
                    showNotificationReply={showNotificationReplyFor === notification.notificationId}
                    toggleNotificationReply={setShowNotificationReplyFor}
                />
            </Fragment>
        )
    })
}
const NotificationComponent = (props) => {
    const userId = useSelector(state => state.auth.session.userRoleId)
    const notifications = useSelector(state => state.notification.notifications.data)
    const totalCount = useSelector(state => state.notification.notifications.totalCount)
    const patientContext = useSelector(state => state.patient.patientContext)
    const [updateCount, setUpdateCount] = React.useState(0);
    const [notificationList, setNotificationList] = React.useState({
        "Overdue Now": [],
        "Today": [],
        "Next 7 Days": [],
        "Next 30 Days": []
    });

    notifications.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))

    useEffect(() => {

        if (props.type === "PATIENT_PANEL") {
            if (patientContext?.patientRoleId) {
                props.notificationActions.getNotifications({
                    type: props.type,
                    userId: patientContext.patientRoleId,
                    limit: props.limit || 10,
                    offset: 0
                })
            }

        } else {
            if (userId) {
                props.notificationActions.getNotifications({
                    type: props.type || "LOGGED_IN_USER",
                    userId,
                    limit: props.limit || 10,
                    offset: 0
                })
            }
        }
    }, [userId, patientContext]);

    const loadMoreNotifications = () => {
        if (notifications.length < totalCount) {
            props.notificationActions.getNotifications({
                type: props.type,
                userId,
                limit: props.limit || 10,
                offset: 0
            })
        }
    }

    useEffect(() => {
        if (notifications?.length > 0) {
            let previousGroupHeader = '';
            let nList = {}
            nList["Overdue Now"] = [];
            nList["Today"] = [];
            nList["Next 7 Days"] = [];
            nList["Next 30 Days"] = [];
            let count = 0;

            notifications.forEach(n => {
                let now = moment()
                let notificationDate = n?.dueDate ? n.dueDate : null
                if (notificationDate) {
                    let nDate = moment(notificationDate)
                    let diff = nDate.diff(now, "days")
                    switch (true) {
                        case (diff < 0) :
                            nList["Overdue Now"].push(n)
                            break;
                        case (diff == 0):
                            nList["Today"].push(n)
                            break;
                        case (diff <= 7):
                            nList["Next 7 Days"].push(n)
                            break;
                        case (diff <= 30):
                            nList["Next 30 Days"].push(n)
                            break;

                    }

                }
            })
            setNotificationList(nList)
        }
    }, [notifications]);

    function NotificationPanel(props) {
        return (notifications.length > 0) ?
            (<Tooltip>
                    <PanelBar expandMode={"multiple"}>
                        {notificationList["Overdue Now"].length > 0 &&
                            <PanelBarItem expanded={true} selected={true} title={"Overdue Now"}
                                          headerClassName={"notification-panel-header notification-panel-header-urgent"}>
                                <Container style={styles.notificationBody}>
                                    <NotificationItemList
                                        notificationActions={props.notificationActions}
                                        PopupActions={props.PopupActions}
                                        notifications={notificationList["Overdue Now"]}/>
                                    <Row>

                                    </Row>
                                </Container>
                            </PanelBarItem>
                        }
                        {notificationList["Today"].length > 0 &&
                            <PanelBarItem expanded={true} selected={true} title="Today"
                                          headerClassName={"notification-panel-header"}>
                                <Container style={styles.notificationBody}>
                                    <NotificationItemList
                                        notificationActions={props.notificationActions}
                                        PopupActions={props.PopupActions}
                                        notifications={notificationList["Today"]}/>
                                    <Row>

                                    </Row>
                                </Container>
                            </PanelBarItem>
                        }
                        {notificationList["Next 7 Days"].length > 0 &&
                            <PanelBarItem expanded={true} selected={true} title="Next 7 Days"
                                          headerClassName={"notification-panel-header"}>
                                <Container style={styles.notificationBody}>
                                    <NotificationItemList
                                        notificationActions={props.notificationActions}
                                        PopupActions={props.PopupActions}
                                        notifications={notificationList["Next 7 Days"]}/>
                                    <Row>

                                    </Row>
                                </Container>
                            </PanelBarItem>
                        }
                        {notificationList["Next 30 Days"].length > 0 &&
                            <PanelBarItem expanded={true} selected={true} title="Next 30 Days"
                                          headerClassName={"notification-panel-header"}>
                                <Container style={styles.notificationBody}>
                                    <NotificationItemList
                                        notificationActions={props.notificationActions}
                                        PopupActions={props.PopupActions}
                                        notifications={notificationList["Next 30 Days"]}/>
                                    <Row>

                                    </Row>
                                </Container>
                            </PanelBarItem>
                        }
                    </PanelBar>
                </Tooltip>
            ) : <span style={{
                textAlign: 'center',
                width: '100%',
                float: 'left',
                margin: '30% 0',
            }}>No notifications to display</span>


    }

    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_NOTIFICATION]}>
                <TileContainer>

                    <TileHeader title="Notifications Worklist"
                                expanded={Components.NotificationComponent}
                                expandedProps={{type: "LOGGED_IN_USER", limit: 25}}
                                style={GlobalStyles.tileHeaderBlueWithBlack}/>
                    <TileBody>
                        <NotificationListHeader/>
                        <Col col={12} style={{overflowY: "scroll", height: "calc(100% - 50px)"}}>
                            <NotificationPanel {...props}/>
                        </Col>


                        <Row style={{height: 25}}>
                            <Col col={5}>
                                <p style={styles.colP}> Displaying 1
                                    - {notifications.length} of {totalCount} </p>
                            </Col>
                            <Col col={5}>
                                {
                                    notifications.length < totalCount &&
                                    <Row col={5}>
                                        <button style={styles.moreButton} onClick={loadMoreNotifications}> load
                                            more
                                        </button>
                                    </Row>
                                }
                            </Col>
                        </Row>


                    </TileBody>

                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )

}


NotificationComponent.propTypes = {
    authActions: PropTypes.object,
    notificationActions: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        userId: state.auth.session.userRoleId,
        notifications: state.notification.notifications.data,
        totalCount: state.notification.notifications.totalCount,
        patientContext: state.patient.patientContext,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthAction, dispatch),
        layoutActions: bindActionCreators(LayoutAction, dispatch),
        notificationActions: bindActionCreators(NotificationAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationComponent)