import * as React from "react";
import {Grid} from "@progress/kendo-react-grid";
import {UserActions} from "../action/UserAction";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Components} from "./shared/ComponentRegistry";
import TileHeader from "../layout/TileHeader";
import GlobalStyles from "../config/GlobalStyles";
import ShowForPermissionComponent from "./shared/ShowForPermissionComponent";
import {Col, Container, Row} from "react-grid-system";

const RafScoreComponent = (props) => {
    const data = [
        {Patient: "Dean Winchester", RAF: 1.33, Change: "+9.1%"},
        {Patient: "Sam Winchester", RAF: 3.14, Change: "+20.3%"},
        {Patient: "Leia Organa", RAF: 2.3, Change: "-4.3%"},

    ]
    return (
        <ShowForPermissionComponent permissions={[Permissions.AH_VIEW_PATIENT]}>
            <div style={GlobalStyles.tileWrapper}>
                <TileHeader title="RAF Scores" expanded={Components.RafScoreComponent} style={{fontSize: "15px"}}/>
                <Container style={GlobalStyles.panelContainer}>
                    <Row>
                        <Col style={{paddingLeft: 10, paddingBottom: 10, height: "30px"}} sm={12}>
                            <em><b>Network Wide : <span style={{color: "red"}}>2.26</span></b></em>
                        </Col>
                    </Row>
                    <Row style={GlobalStyles.panelRowFillHeight}>
                        <Col style={{...GlobalStyles.panelCol}} sm={12}>
                            <Grid style={{height: "100%", width: "100%"}} data={data}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ShowForPermissionComponent>
    );
};

RafScoreComponent.propTypes =
    {}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        UserActions: bindActionCreators(UserActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RafScoreComponent)
