import {DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Dialog} from "primereact/dialog"
import * as React from "react";
import {useState} from "react";
import {bindActionCreators} from "redux";
import {connect, useDispatch, useSelector} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {PatientAction} from "../../action/PatientAction";
import ContactPhoneComponent from "../shared/ContactPhoneComponent";
import PatientRelationshipsComponent from "../PatientRelationshipsComponent";
import {PersonActions} from "../../action/PersonAction";
import {Button} from "primereact/button";
import ContactEmailComponent from "../shared/ContactEmailComponent";
import ContactAddressComponent from "../shared/ContactAddressComponent";
import InsuranceInformationComponent from "../shared/InsuranceInformationComponent";
import ContactPreferencesComponent from "../shared/ContactPreferencesComponent";
import PatientCareTeamListComponent from "../PatientCareTeamListComponent";
import moment from "moment";
import {DictionaryIdFromObjectMaybe} from "../../util/MiscTools";
import {NoPermissions, PermissionsTool} from "../../util/PermissionsTool";
import {Col, Container, Row} from "../../layout/GridLayout";
import {TabStrip, TabStripTab} from "@progress/kendo-react-layout";
import {
    FormColumn,
    FormDateSelector,
    FormDictionaryDropdown,
    FormDropDown,
    FormFacilitySelector,
    FormRow,
    FormTextInput,
    FormToggleButton,
    usePhixForm
} from "../InputControls/NewFormFields";
import {Dictionary} from "../InputControls/NewDictionaryDropDownList";
import {useToast} from "../../providers/toast/ToastProvider";
import {MdLocalPharmacy} from "react-icons/md";
import {API_DATE_FORMAT, PhixPermissions} from "../../config/GlobalConfig";
import _ from "lodash";
import {ComponentUpdateAction} from "../../action/ComponentUpdateAction";
import PatientAttributeComponent from "../PatientAttributeComponent";


const ContactInformation = () => {
    return <>
        <div>
            <div className="sub-title">Contact Address</div>
            <ContactAddressComponent/>
        </div>

        <div>
            <div className="sub-title">Contact Phone</div>
            <ContactPhoneComponent/>
        </div>
        <div>
            <div className="sub-title">Contact Email</div>
            <ContactEmailComponent/>
        </div>
        <div>
            <div className="sub-title">Contact Preferences</div>
            <ContactPreferencesComponent/>
        </div>
    </>;
}

const Demographics = (props) => {
    // useTraceUpdate(props, "AddPatientWindow")
    const dispatch = useDispatch()
    const createPatientContext = useSelector(state => state.patient.createPatientContext)
    const patientScheduleData = useSelector(state => state.schedule.patientScheduleData)
    const [ePrescribeFlag, setePrescribeFlag] = useState(!createPatientContext?.eprescribePatient);
    const [isInitialSetDone, setIsInitialSetDone] = useState(false)
    const [popupShow, setPopupShow] = useState(false);
    const [popupContent, setPopupContent] = useState('');
    const [facilitySelected, setFacilitySelected] = useState(false)
    const {addToast} = useToast()
    const toggleDialog = () => {
        setPopupShow(!popupShow);
    };

    const [{
        handleSubmit,
        setValue,
        getValues,
        watch,
    }, PhixForm] = usePhixForm({
        defaultValues: {
            ...createPatientContext,
            active: createPatientContext && createPatientContext?.active !== null ? createPatientContext.active : true
        }
    });

    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === "facilityId") {
                setFacilitySelected(!!value[name])
            }

        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const title = ["Master", "Miss", "Mrs.", "Mr.", "Dr."];

    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (isInitialSetDone && createPatientContext) {
                if (['dateOfBirth', 'startDate', 'endDate'].indexOf(name) > -1) {
                    const left = value[name] && moment(value[name]).format("YYYY-MM-DD")
                    const right = createPatientContext[name] && moment(createPatientContext[name]).format("YYYY-MM-DD")
                    if (left != right) {
                        PatientAction.setPatientEditTab(true)(dispatch)
                    }
                } else if (typeof value[name] === "object" && typeof createPatientContext[name] === "object") {
                    if (createPatientContext[name]?.id !== value[name]?.id) {
                        PatientAction.setPatientEditTab(true)(dispatch)
                    }
                } else if (typeof value[name] === "object") {
                    if (createPatientContext[name] !== value[name]?.id) {
                        PatientAction.setPatientEditTab(true)(dispatch)
                    }
                } else if (typeof createPatientContext[name] === "object") {
                    if (createPatientContext[name]?.id !== value[name]) {
                        PatientAction.setPatientEditTab(true)(dispatch)
                    }
                } else if (createPatientContext[name] !== value[name]) {
                    PatientAction.setPatientEditTab(true)(dispatch)
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, isInitialSetDone]);

    React.useEffect(() => {
        setePrescribeFlag(!!createPatientContext?.eprescribePatient)
        // setEditPatientContext(createPatientContext)
    }, [createPatientContext])

    const getSingleEvent = (props) => {
        let closestEventArr = props.filter(element => moment(element.startDate).utc().local().format('YYYY-MM-DD HH:MM') > moment().utc().local().format('YYYY-MM-DD HH:MM'))
        return closestEventArr;
    }
    const savePatient = async (form) => {

        let payload = form;
        payload.active = form.active
        payload.facilityId = DictionaryIdFromObjectMaybe(payload?.facilityId)
        payload.dictGenderId = DictionaryIdFromObjectMaybe(payload?.dictGenderId)
        payload.dictGeographicRegionId = DictionaryIdFromObjectMaybe(payload?.dictGeographicRegionId)
        payload.dictServiceOrganizationId = DictionaryIdFromObjectMaybe(payload?.dictServiceOrganizationId)
        payload.dictEducationLevelId = DictionaryIdFromObjectMaybe(payload?.dictEducationLevelId)
        payload.dictServiceOrganizationId = DictionaryIdFromObjectMaybe(payload?.dictServiceOrganizationId)
        payload.dictPreferredLanguageId = DictionaryIdFromObjectMaybe(payload?.dictPreferredLanguageId)
        payload.dictEthnicityId = DictionaryIdFromObjectMaybe(payload?.dictEthnicityId)
        payload.dictRaceId = DictionaryIdFromObjectMaybe(payload?.dictRaceId)
        payload.dictMaritalStatusId = DictionaryIdFromObjectMaybe(payload?.dictMaritalStatusId)

        Object.keys(payload).forEach(key => {
            if (payload[key] === '' || payload[key] === null) {
                delete payload[key];
            }
        });

        const anyFutEvtExist = patientScheduleData?.filter(v => v?.patientRoleId === createPatientContext?.patientRoleId && v.meetingFormats?.find(v => v.code === "meeting_format_video"))
        const dis = getSingleEvent(anyFutEvtExist).map(v => v?.encounterType?.displayName + " on " + moment.utc(v.startDate).local().format("MMM Do YYYY") + " at " + moment.utc(v.startDate).local().format("h:mm a")).join("\n").trim();
        if (form.telehealthCapable === false && anyFutEvtExist.length > 0) {
            setPopupContent(`You have disabled Telehealth support for this patient. There are scheduled appointments that may be impacted:
${dis}
Please make sure that the events are update and the patient is notified of the change.`)
            setPopupShow(true)
        } else {
            setPopupContent('');
            setPopupShow(false)
        }
        if (createPatientContext?.patientRoleId) {
            payload.patientRoleId = createPatientContext.patientRoleId
            payload.personId = createPatientContext.personId
            let r = await PatientAction.updatePatient(payload)(dispatch)

            await ComponentUpdateAction.updatePatientContext()(dispatch)
            // await ComponentUpdateAction.updatePatientList()(dispatch)
        } else {
            let r = await PatientAction.createPatient(payload)(dispatch)
            if (r?.payload) {
                addToast({severity: "success", summary: "Patient Created", detail: "Patient saved successfully"})
            } else
                addToast({severity: "error", summary: "Failed to save", detail: _.isString(r) ? r : r?.message})
            // await ComponentUpdateAction.updatePatientList()(dispatch)

        }
        await PatientAction.setPatientEditTab(false)(dispatch)

    };


    async function createEprescribePatient(e) {
        e.preventDefault();

        const response = await PatientAction.createEprescribePatient({
            shouldCheckIn: true,
            patientRoleId: createPatientContext?.patientRoleId,
        })(dispatch);
        if (response?.payload) {
            await PatientAction.setPatientContextRemote(createPatientContext?.patientRoleId)(dispatch)
            addToast({severity: "success", summary: "Updated", detail: "Patient has been updated."})
        } else {
            addToast({severity: "error", summary: "Failed", detail: response})
            await PatientAction.setPatientContextRemote(createPatientContext?.patientRoleId)(dispatch)
        }
    }


    return <div style={{width: "100%"}}>
        <PhixForm style={{width: "100%"}}>
            <FormRow>
                <FormColumn col={2}>
                    <FormDropDown options={title} field={"title"} label={"Title"}/>
                </FormColumn>
                <FormColumn col={2}>
                    <FormTextInput label={"First Name"} field={"firstName"}
                                   rules={{required: "First Name is required."}}/>
                </FormColumn>
                <FormColumn col={2}>
                    <FormTextInput label={"Middle Name"} field={"middleName"}/>
                </FormColumn>
                <FormColumn col={3}>
                    <FormTextInput label={"Last Name"} field={"lastName"}
                                   rules={{required: "Last Name is required."}}/>
                </FormColumn>
                <FormColumn col={2}>
                    <FormDateSelector label={"Date of Birth"} field={"dateOfBirth"}
                                      dateFormat={API_DATE_FORMAT}
                                      rules={{required: "Date of birth is required."}}/>
                </FormColumn>
                <FormColumn col={1}>
                    <FormToggleButton label={"Active"} field={"active"}/>
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn col={4}>
                    <FormDictionaryDropdown field={"dictGenderId"} label={"Gender"} code={Dictionary.GENDER}
                                            rules={{required: "Gender is required."}}/>
                </FormColumn>
                <FormColumn col={4}>
                    <FormDictionaryDropdown field={"dictRaceId"} label={"Race"} code={Dictionary.RACE}/>
                </FormColumn>

                <FormColumn col={4}>
                    <FormDictionaryDropdown field={"dictEthnicityId"} label={"Ethnicity"} code={Dictionary.ETHNICITY}/>
                </FormColumn>

            </FormRow>
            <FormRow>
                <FormColumn col={4}>
                    <FormDictionaryDropdown field={"dictPreferredLanguageId"}
                                            label={"Preferred Language"}
                                            code={Dictionary.AH_LANGUAGES}/>
                </FormColumn>
                <FormColumn col={4}>
                    <FormDictionaryDropdown field={"dictEducationLevelId"}
                                            label={"Education"}
                                            code={Dictionary.AH_EDUCATION_LEVEL}/>
                </FormColumn>
                <FormColumn col={4}>
                    <FormDictionaryDropdown field={"dictMaritalStatusId"}
                                            label={"Marital Status"}
                                            code={Dictionary.MARITAL_STATUS}/>
                </FormColumn>
            </FormRow>
            <FormRow>
                {createPatientContext?.patientRoleId &&
                    <FormColumn col={4}>
                        ePrescribe:
                        {ePrescribeFlag && createPatientContext?.patientRoleId ? (
                            <Button size={"small"} icon={<MdLocalPharmacy style={{paddingRight: 2}}/>} text disabled
                                    style={{marginLeft: 20, height: 20}}
                                    label={"Enrolled"}/>
                        ) : (
                            <Button icon={<MdLocalPharmacy style={{paddingRight: 2}}/>} size={"small"}
                                    style={{marginLeft: 20, height: 20}} label={"Enroll"}
                                    onClick={createEprescribePatient}/>
                        )}
                    </FormColumn>}

                <FormColumn col={4}>
                    <FormToggleButton label={"Telehealth Capable"} field={"telehealthCapable"}/>
                </FormColumn>
            </FormRow>
            <div className="sub-title">Patient Information</div>
            <FormRow style={{marginTop: "0.1%"}}>
                <FormColumn col={4}>
                    <FormTextInput label={"Medicare Beneficiary ID"} field={"mbiId"}/>
                </FormColumn>
                <FormColumn col={4}>
                    <FormTextInput label={"MRN"} field={"medicalRecordNumber"} disabled={true}/>
                </FormColumn>
                <FormColumn col={4}>
                    <FormTextInput label={"External ID"} field={"externalId"}/>
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn col={4}>
                    <FormFacilitySelector label={"Select Facility"} field={"facilityId"}/>
                </FormColumn>
                {(facilitySelected || createPatientContext?.facilityId) &&
                    <FormColumn col={4}>
                        <FormDateSelector rules={{required: "Enrollment date is required when selecting a facility."}}
                                          dateFormat={API_DATE_FORMAT}
                                          label={"Enrollment Date"}
                                          field={"startDate"}/>
                    </FormColumn>}
                {(facilitySelected || createPatientContext?.facilityId) &&
                    <FormColumn col={4} label={"Member Dis-enrollment Date"} field={"endDate"}>
                        <FormDateSelector
                            label={"Disenrollment Date"}
                            dateFormat={API_DATE_FORMAT}
                            field={"endDate"}/>
                    </FormColumn>}
                <FormColumn col={4}>
                    <FormDictionaryDropdown field={"dictGeographicRegionId"}
                                            label={"Geographic Region"}
                                            code={Dictionary.AH_GEOGRAPHIC_REGION}/>
                </FormColumn>
                <FormColumn col={4}>
                    <FormDictionaryDropdown field={"dictServiceOrganizationId"}
                                            label={"Service Location"}
                                            code={Dictionary.AH_SERVICE_LOCATION}/>
                </FormColumn>


            </FormRow>
        </PhixForm>

        <Dialog onHide={toggleDialog} visible={popupShow}>
            <p
                style={{
                    margin: "25px",
                    textAlign: "center",
                    whiteSpace: 'pre'
                }}
            >
                {popupContent}
            </p>
            <DialogActionsBar>
                <Button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={toggleDialog}
                    lable={"Ok"}
                />
            </DialogActionsBar>
        </Dialog>
        <Row className={"justify-content-end"}>
            <Button

                onClick={handleSubmit(savePatient)}
                label={createPatientContext ? "Update" : "Create"}/>
        </Row>
    </div>;
};

const initialTabs = [
    {
        title: "  Demographics  ",
        key: "demographics",
        content: <Demographics/>,
        visible: true
    },
    {
        title: "  Contact Information ",
        key: "contact_information",
        content: <ContactInformation/>,
        visible: true
    },
    {
        title: "  Care Team ",
        key: "care_team",
        permissions: [PhixPermissions.AH_VIEW_CARETEAM],
        content: <PatientCareTeamListComponent/>,
        visible: true
    },
    {
        title: "Insurance Information",
        key: "insurance_information",
        content: <InsuranceInformationComponent/>,
        visible: true
    },
    {
        title: "  Patient Relationships ",
        key: "patient_relationships",
        content: <PatientRelationshipsComponent/>,
        visible: true
    },
    {
        title: "  Patient Attributes  ",
        key: "patient_relationships",
        content: <PatientAttributeComponent/>,
        visible: true
    }
];
const AddPatientWindow = (props) => {
    // useTraceUpdate(props, "Add patient dialog")
    const {permissionInfo, createPatientContext, showWindow} = props
    const permissions = useSelector(state => state.auth.session.permissions)
    const [myPatientsPermissions, setMyPatientsPermissions] = useState({isEdit: false, isView: false});
    const [patientsPermissions, setPatientsPermissions] = useState({isEdit: false, isView: false});

    const [selected, setSelected] = useState(0);

    React.useEffect(() => {
        setMyPatientsPermissions(PermissionsTool({
            permissionInfo: permissionInfo,
            viewPermissions: [PhixPermissions.AH_VIEW_MY_PATIENTS],
            editPermissions: [PhixPermissions.AH_EDIT_MY_PATIENTS]
        }));
        setPatientsPermissions(PermissionsTool({
            permissionInfo: permissionInfo,
            viewPermissions: [PhixPermissions.AH_VIEW_PATIENT],
            editPermissions: [PhixPermissions.AH_EDIT_PATIENT]
        }));
    }, [permissionInfo])


    const hideDialog = () => {
        setSelected(0);

        props.PatientActions.setPatientEditTab(false)
        props.PatientActions.clearPatientInsurance()
        props.PatientActions.clearPatientRelationships();
        props.PatientActions.clearEditPatientContext()
        props.PopupActions.hideWindow(PopupWindows.CREATE_PATIENT_WINDOW)
    };

    const handleSelect = (e) => {
        if (props?.patientEditTab && window.confirm("Please save your changes")) {
        } else {
            props.PatientActions.setPatientEditTab(false)
            setSelected(e.selected);
        }
    };

    const styles = {
        tabContainer: {
            width: "100%",
            minWidth: 100,
            background: "#BAC1E0",
            padding: "5px",
            textAlign: "center",
            color: "#FFF"
        },
        tabContainerSelected: {
            width: "100%",
            minWidth: 100,
            background: "#7280c0",
            padding: "5px",
            textAlign: "center",
            color: "#FFF"
        },
        tabIcon: {
            width: "100%",
            padding: "5px 0",
            fontSize: "1.5rem",
            color: "#FFF"
        },
        tabTitle: {
            width: "100%",
            color: "#FFF"
        },
    }
    const Title = (props) => {
        return (
            <Container style={selected === props.index ? styles.tabContainerSelected : styles.tabContainer}>
                <Row>
                    <Col>
                        <span style={styles.tabTitle} className="tabTitle">{props.content}</span>
                    </Col>
                </Row>
            </Container>
        );
    };
    const isPatientUpdate = (index) => {
        if (!createPatientContext?.patientRoleId && index > 0) {
            return false;
        } else {
            return true;
        }
    }
    return (<Dialog
        visible={showWindow[PopupWindows.CREATE_PATIENT_WINDOW]}
        header={createPatientContext?.patientRoleId ? "Update Patient" : "Create Patient"}
        onHide={hideDialog}
        maximizable
        style={{height: 800, width: 1000}}
    > {(myPatientsPermissions.isView || patientsPermissions.isView) ?
        <div>
            <TabStrip
                animation={false}
                className={"clinicalTabBar"}
                tabPosition={"top"}
                tabContentStyle={{height: "100%", width: "100%"}}
                selected={selected} onSelect={handleSelect}>
                {initialTabs.filter(v => !v?.permissions || v?.permissions?.some(r => permissions.includes(r))).map((item, index) => {
                    return (item.visible && isPatientUpdate(index) && <TabStripTab
                            title={<Title content={item.title} index={index}/>}
                            key={index}>
                            <div
                                style={{pointerEvents: myPatientsPermissions.isEdit || patientsPermissions.isEdit ? 'block' : 'none'}}>
                                {
                                    item.content
                                }
                            </div>
                        </TabStripTab>
                    );
                })}
            </TabStrip></div> : <NoPermissions/>}
    </Dialog>)
}

function mapStateToProps(state) {
    return {
        createPatientContext: state.patient.createPatientContext,
        patientEditTab: state.patient.patientEditTab,
        showWindow: state.popupWindow.showWindow,
        permissionInfo: state.auth.session.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientWindow)
