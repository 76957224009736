import * as React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {connect} from "react-redux";
import {Grid, GridColumn, GridToolbar} from "@progress/kendo-react-grid";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import {EditCommandCell} from "./EditCommandCell";
import {Button} from "primereact/button";
import {PersonActions} from "../../action/PersonAction";
import {v4 as uuidv4} from 'uuid';
import {InlineDictionaryDropDown, InlinePrimaryCheckbox, InlineTextInput} from "../InputControls/InlineFields";
import _ from "lodash";
import {PatientAction} from "../../action/PatientAction";

const DictionaryTypeDropdown = (props) => <InlineDictionaryDropDown
    {...props}
    idField={"dictionaryId"}
    displayField={"dictionaryDisplayName"}
    code={Dictionary.EMAIL_CONTACT}/>

const ContactEmailComponent = (props) => {

    const contactEmail = props.contactEmail

    const editField = "inEdit";
    const personId = props.personId

    React.useEffect(() => {
        props.PersonActions.getContactEmail(props?.personId || props?.createPatientContext?.personId);
    }, [props?.personId, props?.createPatientContext?.personId])
    const [data, setData] = React.useState(contactEmail);
    React.useEffect(() => {
        setData(contactEmail)
    }, [contactEmail])


    const addNew = () => {
        let primary = !contactEmail.find(e => e.isPrimary === true)
        let newDataItem = {
            id: uuidv4(),
            newItem: true,
            inEdit: true,
            isPrimary: primary,
            personId: props?.personId || props?.createPatientContext?.personId,
        };
        setData([...data, newDataItem]);
    };

    const validateFields = (dataItem) => {
        let errors = {}
        if (!dataItem.email) errors = {...errors, email: "This field is required."}
        if (!dataItem.dictionaryId) errors = {...errors, dictionaryId: "This field is required."}
        if (_.isEmpty(errors))
            return true
        else {
            itemChange({
                dataIndex: 0,
                dataItem: dataItem,
                field: "errors",
                value: errors,
            })
            return false
        }

    }

    const add = (dataItem) => {
        if (validateFields(dataItem)) {
            props.PersonActions.addContactEmail(props?.personId || props?.createPatientContext?.personId, dataItem.dictionaryId, dataItem.email, dataItem.isPrimary)
                .then(() => {
                    props.PersonActions.getContactEmail(props?.personId || props?.createPatientContext?.personId)
                    if (props.patientContext?.patientRoleId && props.patientContext?.patientRoleId === props.createPatientContext?.patientRoleId) props.PatientActions.setPatientContextRemote(props.patientContext.patientRoleId)
                })
        }
    };

    const update = (dataItem) => {
        if (validateFields(dataItem)) {
            props.PersonActions.updateContactEmail(
                dataItem.id,
                dataItem.dictionaryId,
                dataItem.email,
                dataItem.isPrimary).then(() => {
                props.PersonActions.getContactEmail(props?.personId || props?.createPatientContext?.personId)
                if (props.patientContext?.patientRoleId && props.patientContext?.patientRoleId === props.createPatientContext?.patientRoleId) props.PatientActions.setPatientContextRemote(props.patientContext.patientRoleId)
            })
        }
    };
    const edit = (props) => {
        return (
            <EditCommandCell
                {...props}
                editField={editField}
                cancel={cancel}
                remove={remove}
                update={update}
                add={add}
                edit={enterEdit}
            />
        );
    };
    const remove = (dataItem) => {
        if (window.confirm("Are you sure you want to delete this email?")) {
            props.PersonActions.removeContactEmail(dataItem.id).then(() => props.PersonActions.getContactEmail(personId))
        }

    }

    const cancel = (dataItem) => {
        const data1 = [...data];
        const originalItem = data.find((p) => p.id === dataItem.id);
        data1.splice(data1.indexOf(originalItem), 1);
        setData(data1);

    }

    const enterEdit = (dataItem) => {
        let newData = data.map((item) =>
            item.id === dataItem.id ? {...item, inEdit: true} : item
        );
        setData(newData);
    };

    const itemChange = (event) => {
        const field = event.field || "";
        const newData = data.map((item) =>
            item.id === event.dataItem.id
                ? {...item, [field]: event.value}
                : item
        );

        setData(newData);
    };
    //React.useEffect(() => // console.log(data), [data])
    return (
        <Grid
            editField={editField}
            data={data}
            onItemChange={itemChange}
            dataItemKey={"id"}
        >
            <GridToolbar>
                <Button onClick={addNew}
                        text
                        icon={"pi pi-plus"}/>

                <Button icon={"pi pi-refresh"}
                        text
                        onChange={() => props.PersonActions.getContactEmail(props?.personId || props?.createPatientContext?.personId)}/>
            </GridToolbar>
            <GridColumn
                field="dictionaryId"
                title="Type"
                //editor={"text"}
                cell={DictionaryTypeDropdown}
            />
            <GridColumn
                field="email"
                title="Email Address"
                cell={InlineTextInput} //TODO: make this an email input
            />
            <GridColumn
                editor={"boolean"}
                field="isPrimary"
                title="Primary"
                cell={InlinePrimaryCheckbox}
            />
            <GridColumn field="action" title="Action" cell={edit} width={"120px"}/>
        </Grid>
    )
}

ContactEmailComponent.propTypes = {
    PersonActions: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        createPatientContext: state.patient.createPatientContext,
        personModel: state.person.personModel,
        contactEmail: state.person.personContextEmail,
        patientContext: state.patient.patientContext,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PatientActions: bindActionCreators(PatientAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactEmailComponent)