import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {orderBy} from "@progress/kendo-data-query";
import {bindActionCreators} from "redux";
import {QualityMeasureActions} from "../../action/QualityMeasureActions";
import {PatientAction} from "../../action/PatientAction";
import {Col, Row} from "react-grid-system";

const initialSort = [{
    field: 'ProductName',
    dir: 'asc'
}];

const CustomCell = (props) => {
    const field = props.field || "";
    const status = props.dataItem[field];
    let value = "NULL";
    if (status === true) {
        value = "Up to Date";
    } else if (status === false) {
        value = "Open";
    }
    return (
        <div>{value}</div>
    );
};
const QualityMeasuresComponent = (props) => {
    const [sort, setSort] = React.useState(initialSort);
    let [QualityMeasures, setQualityMeasures] = useState(null);

    useEffect(() => {
        props.PatientActions.getQualityMeasureByPatientId(props.patient.patientContext.patientRoleId);

    }, [])

    useEffect(() => {
        setQualityMeasures(props.patient.QualityMeasureList);

    }, [props.patient.QualityMeasureList])

    return (
        <div style={{margin: 0, width: "100%", height: "100%"}}>
            <Row style={{height: "100%"}}>
                <Col sm={12} style={{width: "100%", height: "100%"}}>
                    <Grid
                        style={{height: '100%'}}
                        data={QualityMeasures && orderBy(QualityMeasures, sort)} sortable={true} sort={sort}
                        onSortChange={e => {
                            setSort(e.sort);
                        }}
                    >

                        <GridColumn field="description" title="Measure"/>
                        <GridColumn field="status" title="Status" cell={CustomCell}/>

                    </Grid>
                </Col>
            </Row>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        patientModel: state.patient.patientModel,
        patient: state.patient,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        QualityMeasureActions: bindActionCreators(QualityMeasureActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityMeasuresComponent)