import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {WindowActionsBar} from "@progress/kendo-react-dialogs";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PopupWindowActions as PopupActions, PopupWindows} from "../../action/PopupWindowAction";
import {LaboratoryAction} from "../../action/LaboratoryAction";
import {Col, Container, Row} from "react-grid-system";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {filterBy} from "@progress/kendo-data-query";
import {GrInstall} from 'react-icons/gr'
import {Tooltip} from "@progress/kendo-react-tooltip";
import {Fade} from "@progress/kendo-react-animation";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import AeriosLoadingIndicator from "../AeriosLoadingIndicator";

const AddLabVendorWindow = (props) => {

    const dispatch = useDispatch();
    const data = useSelector(state => state.laboratory.laboratoryList.labs);
    const [filter, setFilter] = useState({logic: "and", filters: []})
    const showWindow = useSelector(state => state.popupWindow.showWindow,)
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [loading, setLoading] = useState(false)
    const hideDialog = () => {

        PopupActions.hideWindow(PopupWindows.ADD_LAB_VENDOR_WINDOW)(dispatch);

    };
    useEffect(() => {
        if (showWindow[PopupWindows.ADD_LAB_VENDOR_WINDOW])
            LaboratoryAction.getDornLaboratories().then((data) => {
                    dispatch(data)
                }
            )
    }, [showWindow]);

    const AddressCell = (props) => {
        return <td>{props.dataItem?.address?.address1 + " " + props.dataItem?.address?.city + " " + props.dataItem?.address?.stateProvinceCode}</td>
    }

    function ActionCell(props) {
        const dispatch = useDispatch();
        const Notify = () => {
            setSuccess(true)
            setTimeout(() => setSuccess(false), 5000)
        }

        async function handleInstall(guid) {
            setLoading(true)
            let response = await LaboratoryAction.installDornLaboratory(guid)
            if (response?.payload === true) {
                Notify()
                let payload = await LaboratoryAction.getInstalledDornLaboratories()
                dispatch(payload)
                let available = await LaboratoryAction.getDornLaboratories()
                dispatch(available)
            } else
                setErrorMessage(`There was an error installing the lab: ${guid} please contact your administrator.`)
            setLoading(false)
        }

        return <td>
            <Tooltip anchorElement="target" parentTitle={true}>
                <Container style={{height: "100%", padding: 0, alignContent: "center", justifyContent: "center"}}>
                    <Row style={{height: "100%"}}>

                        <Col style={{height: "100%"}} sm={3}>
                            <Button title={"Install Lab"} onClick={() => handleInstall(props.dataItem.labGuid)}>
                                <GrInstall color={"green"}/>
                            </Button>
                        </Col>

                    </Row>
                </Container>
            </Tooltip>
        </td>
    }


    return (
        <Dialog header={"Add Lab Vendor"} onHide={hideDialog}
                style={{width: 800}}
                visible={showWindow[PopupWindows.ADD_LAB_VENDOR_WINDOW]}>
            <AeriosLoadingIndicator loading={loading} message={"Loading Compendium. This may take some time..."}>
                <Container fluid style={{width: "100%", padding: 20, height: "100%"}}>
                    <NotificationGroup style={{
                        top: 0,
                        left: "50%",
                        transform: "translateX(-50%)",
                    }}>
                        <Fade>
                            {success && (
                                <Notification
                                    type={{
                                        style: "success",
                                        icon: true,
                                    }}
                                    closable={true}
                                    onClose={() => setSuccess(false)}
                                >
                                    <span>Lab Compendium is being imported</span>
                                </Notification>
                            )}
                        </Fade>
                        <Fade>
                            {errorMessage?.message && (
                                <Notification
                                    type={{
                                        style: "error",
                                        icon: true,
                                    }}
                                    closable={true}
                                    onClose={() => setErrorMessage(null)}
                                >
                                    <span>{errorMessage?.message}</span>
                                </Notification>
                            )}
                        </Fade>
                    </NotificationGroup>
                    <Row style={{height: "100%"}}>
                        <Grid style={{height: "100%"}}
                              data={data && filterBy(data, filter)}
                              filterable
                              filter={filter}
                              onFilterChange={(e) => setFilter(e.filter)}
                              sortable={true}>

                            <GridColumn cell={ActionCell}></GridColumn>
                            <GridColumn field={"name"} title={"Lab Name"}/>
                            <GridColumn field={"labType"} title={"Lab Type"}/>
                            <GridColumn field={"phoneNumber"} title={"Phone Number"}/>
                            <GridColumn field={"address"} title={"Address"} cell={AddressCell}/>
                        </Grid>
                    </Row>

                </Container>
            </AeriosLoadingIndicator>

            <WindowActionsBar>
                <Button themeColor={"primary"} onClick={hideDialog}>
                    Finish
                </Button>
            </WindowActionsBar>
        </Dialog>);
};


export default AddLabVendorWindow;
