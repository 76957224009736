export const ActionTypes = {
    POPUP_SHOW_WINDOW: 'POPUP_SHOW_WINDOW',
    POPUP_HIDE_WINDOW: 'POPUP_HIDE_WINDOW',
    SHOW_LINE_CHART_WINDOW: 'SHOW_LINE_CHART',
    HIDE_LINE_CHART_WINDOW: 'HIDE_LINE_CHART_WINDOW',
    UPDATE_LINE_CHART_WINDOW: 'UPDATE_LINE_CHART_WINDOW',
    SET_POPUP_WINDOW_STATE: "SET_POPUP_WINDOW_STATE",
    CLEAR_POPUP_WINDOW_STATE: "CLEAR_POPUP_WINDOW_STATE",
}

export const PopupWindows = {
    CARE_TEAM_MENU: "CARE_TEAM_MENU",
    CREATE_PATIENT_WINDOW: "CREATE_PATIENT_WINDOW",
    CARE_PLAN_WINDOW: "CARE_PLAN_WINDOW",
    LINE_CHART_WINDOW: "LINE_CHART_WINDOW",
    NOTIFICATION_DETAILS_WINDOW: "NOTIFICATION_DETAILS_WINDOW",
    ADD_MEDICAL_PROBLEM_WINDOW: "ADD_MEDICAL_PROBLEM_WINDOW",
    SEARCH_ICD_CODE_WINDOW: "SEARCH_ICD_CODE_WINDOW",
    SEARCH_RxNorm_CODE_WINDOW: "SEARCH_RxNorm_CODE_WINDOW",
    ADD_IMMUNIZATION_WINDOW: "ADD_IMMUNIZATION_WINDOW",
    SEARCH_IMMUNIZATION_CODE_WINDOW: "SEARCH_IMMUNIZATION_CODE_WINDOW",
    SEARCH_IMMUNIZATION_MF_CODE_WINDOW: "SEARCH_IMMUNIZATION_MF_CODE_WINDOW",
    CREATE_ORDER_WINDOW: "CREATE_ORDER_WINDOW",
    CREATE_USER_WINDOW: "CREATE_USER_WINDOW",
    CREATE_REQUEST_WINDOW: "CREATE_REQUEST_WINDOW",
    EDIT_REQUEST_WINDOW: "EDIT_REQUEST_WINDOW",
    EDIT_FACILITY_WINDOW: "EDIT_FACILITY_WINDOW",
    EDIT_USER_WINDOW: "EDIT_USER_WINDOW",
    EDIT_USER_PROFILE_WINDOW: "EDIT_USER_PROFILE_WINDOW",
    ADD_CODE_WINDOW: "ADD_CODE_WINDOW",
    ADD_DICTIONARY_WINDOW: "ADD_DICTIONARY_WINDOW",
    QUALITY_MEASURE_WINDOW: "QUALITY_MEASURE_WINDOW",
    ADD_ALLERGIES_WINDOW: "ADD_ALLERGIES_WINDOW",
    ADD_MEDICATIONS_WINDOW: "ADD_MEDICATIONS_WINDOW",
    ADD_HOSPITALIZATIONS_SURGERIES_WINDOW: "ADD_HOSPITALIZATIONS_SURGERIES_WINDOW",
    SHOW_DOCUMENT_WINDOW: "SHOW_DOCUMENT_WINDOW",
    ADD_SURGERIES_WINDOW: "ADD_SURGERIES_WINDOW",
    ADD_NOTIFICATION_EVENT_WINDOW: "ADD_NOTIFICATION_EVENT_WINDOW",
    PATIENT_LIST_FOR_PIE: "PATIENT_LIST_FOR_PIE",
    USER_CREATE_PORTAL_REGISTRATION: "USER_CREATE_PORTAL_REGISTRATION",
    CREATE_QUERY_PANEL_WINDOW: "CREATE_QUERY_PANEL_WINDOW",
    CREATE_EPRESCRIBE_USER_WINDOW: "CREATE_EPRESCRIBE_USER_WINDOW",
    ACTIVITY_LEVEL_BAR_WINDOW: "ACTIVITY_LEVEL_BAR_WINDOW",
    ENCOUNTER_WINDOW: "ENCOUNTER_WINDOW",
    SIGN_ENCOUNTER_WINDOW: "SIGN_ENCOUNTER_WINDOW",
    CREATE_DOCUMENT_WINDOW: "CREATE_DOCUMENT_WINDOW",
    WEIGHT_MANAGEMENT_BAR_WINDOW: "WEIGHT_MANAGEMENT_BAR_WINDOW",
    HEART_RATE_MANAGEMENT_BAR_WINDOW: "HEART_RATE_MANAGEMENT_BAR_WINDOW",
    HEART_RATE_VARIABILITY_MANAGEMENT_BAR_WINDOW: "HEART_RATE_VARIABILITY_MANAGEMENT_BAR_WINDOW",
    EDIT_VITAL: "EDIT_VITAL",
    EDIT_SOCIAL_HISTORY: "EDIT_SOCIAL_HISTORY",
    EDIT_FAMILY_HISTORY: "EDIT_FAMILY_HISTORY",
    CREATE_REQUEST_DOCUMENT_WINDOW: "CREATE_REQUEST_DOCUMENT_WINDOW",
    ENCOUNTER_CODING_WINDOW: "ENCOUNTER_CODING_WINDOW",
    ADD_GOAL_TO_CONDITION_WINDOW: "ADD_GOAL_TO_CONDITION_WINDOW",
    ADD_STRATEGY_TO_GOAL: "ADD_STRATEGY_TO_GOAL",
    INSURANCE_PLAN_WINDOW: "INSURANCE_PLAN_WINDOW",
    CLINICAL_TRIAL_ELIGIBILITY_PATIENT_WINDOW: "CLINICAL_TRIAL_ELIGIBILITY_PATIENT_WINDOW",
    FORM_REPORTS: "FORM_REPORTS",
    ADD_LAB_VENDOR_WINDOW: "ADD_LAB_VENDOR_WINDOW",
    ADD_LAB_ROUTE_WINDOW: "ADD_LAB_ROUTE_WINDOW",
    PDF_VIEWER_WINDOW : "PDF_VIEWER_WINDOW",
    ADD_ENCOUNTER_PATIENT_INSTRUCTIONS : "ADD_ENCOUNTER_PATIENT_INSTRUCTIONS",
    SIGNATURE_WINDOW : "SIGNATURE_WINDOW",
}

function showWindow(window, state) {
    return async dispatch => {
        if (state)
            await dispatch({type: ActionTypes.SET_POPUP_WINDOW_STATE, window, state})
        dispatch({type: ActionTypes.POPUP_SHOW_WINDOW, window})
    }
}

function setWindowState(window, state) {
    return async (dispatch) => dispatch({type: ActionTypes.SET_POPUP_WINDOW_STATE, window, state})
}

function clearWindowState(window) {
    return async dispatch => dispatch({type: ActionTypes.CLEAR_POPUP_WINDOW_STATE, window})
}

function hideWindow(window, clearState = false) {
    return async dispatch => {
        if (clearState) {
            await dispatch({type: ActionTypes.CLEAR_POPUP_WINDOW_STATE, window})
            await dispatch({type: ActionTypes.POPUP_HIDE_WINDOW, window})
        } else {
            await dispatch({type: ActionTypes.CLEAR_POPUP_WINDOW_STATE, window})
            await dispatch({type: ActionTypes.POPUP_HIDE_WINDOW, window})

        }

    }
}

function showLineChart(title, categories, data, offset, setOffset) {
    return async dispatch => dispatch({
        type: ActionTypes.SHOW_LINE_CHART_WINDOW, window: PopupWindows.LINE_CHART_WINDOW, data, categories, title, offset, setOffset
    })
}

function updateLineChart(title, categories, data, offset, setOffset) {
    return async dispatch => dispatch({
        type: ActionTypes.UPDATE_LINE_CHART_WINDOW, data, categories, title, offset, setOffset
    })
}

function hideLineChart() {
    return async dispatch => dispatch({
        type: ActionTypes.HIDE_LINE_CHART_WINDOW, window: PopupWindows.LINE_CHART_WINDOW
    })
}

export const PopupWindowActions = {
    showWindow: showWindow, hideWindow, showLineChart, hideLineChart, setWindowState, clearWindowState, updateLineChart
}
