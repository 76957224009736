import {OPENEMR_URL} from "../config/GlobalConfig";
// import {Base64} from 'js-base64';

let openemrWindow;

function openWindow(openEmrUrl) {
    if (openemrWindow) {
        openemrWindow.close()
        openemrWindow = window.open(openEmrUrl, "_SELF")
        openemrWindow.focus()
        // openemrWindow.location = openEmrUrl
        // openemrWindow.focus()
    } else {
        openemrWindow = window.open(openEmrUrl, "_SELF")
        openemrWindow.focus()

    }
}

function openWindowInNewTab(openEmrUrl) {
    if (openemrWindow) {
        openemrWindow.close()
        openemrWindow = window.open(openEmrUrl, "_blank")
        openemrWindow.focus()
        // openemrWindow.location = openEmrUrl
        // openemrWindow.focus()
    } else {
        openemrWindow = window.open(openEmrUrl, "_blank")
        openemrWindow.focus()

    }
}

function closeOpenEmrWindow() {
    if (openemrWindow) {
        openemrWindow.close()
    }
}

function goToEncounter(patientId, encounterId) {
    return async (dispatch, getState) => {
        let authToken = localStorage.getItem('emrToken')//localStorage.getItem('token');
        let username = getState().user.loggedinUser.userName
        let params = "auth=SSOLogin&token=" + authToken + "&username=" + username + "&ahpat_id=" + patientId + "&ahenc_id=" + encounterId + "";
        let openEmrUrl = "https://" + OPENEMR_URL + "/interface/main/main_screen.php?" //+ Base64.encode(params);
        // console.log("open url",openEmrUrl)
        openWindow(openEmrUrl)

    }
}

function goToEPrescribe() {
    return (dispatch, getState) => {
        let SAMLValue = getState().user.getSAML?.payload?.samlResponse;
        let params = "value=" + SAMLValue
        let openEmrUrl = "https://eprescribeqa.allscripts.com/SAML/SSO.aspx?" + params;
        // console.log(openEmrUrl)
        openWindowInNewTab(openEmrUrl)
    }
}

function createEncounter(patientId) {
    return async (dispatch, getState) => {
        let authToken = localStorage.getItem('emrToken')//localStorage.getItem('token');
        let username = getState().user.loggedinUser.userName
        let params = "auth=SSOLogin&token=" + authToken + "&username=" + username + "&ahpat_id=" + patientId + "";
        let openEmrUrl = "https://" + OPENEMR_URL + "/interface/main/main_screen.php?" // + Base64.encode(params);
        // console.log(openEmrUrl)
        openWindow(openEmrUrl)
    }
}

function createEncounterType(patientId, encounterType) {
    return async (dispatch, getState) => {
        let authToken = localStorage.getItem('emrToken')//localStorage.getItem('token');
        let username = getState().user.loggedinUser.userName
        let params = "auth=SSOLogin&token=" + authToken + "&username=" + username + "&ahpat_id=" + patientId + "&ahenc_type=" + encounterType + "";
        let openEmrUrl = "https://" + OPENEMR_URL + "/interface/main/main_screen.php?" // + Base64.encode(params);
        // console.log(openEmrUrl)
        openWindow(openEmrUrl)
    }
}

export const OpenEmrActions = {
    goToEncounter: goToEncounter,
    createEncounter: createEncounter,
    createEncounterType: createEncounterType,
    closeOpenEmrWindow: closeOpenEmrWindow,
    goToEPrescribe: goToEPrescribe
}
