import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    FORMS_LIST_FORMS: "FORMS_LIST_FORMS",
    ADMIN_FORMS_LIST_FORMS: "ADMIN_FORMS_LIST_FORMS",
    FORMS_SCHEMA_HEADER_IS_ACTIVE: "FORMS_SCHEMA_HEADER_IS_ACTIVE",
    FORMS_SET_EDIT_FORM: "FORMS_SET_EDIT_FORM",
    SAVE_ENCOUNTER_FORM: "SAVE_ENCOUNTER_FORM",
    GET_ENCOUNTER_FORMS: "GET_ENCOUNTER_FORMS",
    DELETE_ENCOUNTER_FORMS: "DELETE_ENCOUNTER_FORMS",
    CLEAR_ENCOUNTER_FORMS: "CLEAR_ENCOUNTER_FORMS",
    GET_FORM_BY_ID: "GET_FORM_BY_ID",
    FORM_REPORT_INPUT_DATA: "FORM_REPORT_INPUT_DATA",
}


export function saveEncounterFormSchema(payload) {
    return WrapAuthentication("form/save",
        {
            schemaId: payload.schemaId,
            formHeaderId: payload.formHeaderId,
            formData: payload.formData,
            patientRoleId: payload.patientRoleId,
            encounterId: payload.encounterId,
            dictFormStatus: payload.dictFormStatus

        }, null)
}


export function deleteEncounterFormSchema(payload) {
    return WrapAuthentication("form/delete",
        {
            payload: payload
        }, null)
}

export function getFormSchemaListByEncounterId(encounterId) {
    return WrapAuthentication("formschema/get/byEncounter",
        {
            payload: encounterId
        }, ActionTypes.GET_ENCOUNTER_FORMS)
}


export function getFormSchemaList(limit, offset, sort, filter) {
    return WrapAuthentication("formschema/list",
        {
            payload: "",
            sort: sort ? {sorts: sort} : null,
            filter: filter ? filter : {},
            limit: limit,
            offset: offset
        }, ActionTypes.FORMS_LIST_FORMS)
}

export function getAdminFormSchemaList(limit, offset, sort, filter) {
    return WrapAuthentication("formschema/admin/list",
        {
            payload: "",
            sort: sort ? {sorts: sort} : null,
            filter: filter ? filter : {},
            limit: limit,
            offset: offset
        }, ActionTypes.ADMIN_FORMS_LIST_FORMS)
}

export function saveNewFormSchema(code, displayName, dictDocumentFormCategoriesId) {
    return WrapAuthentication("formschema/create",
        {
            payload: {
                code, displayName,
                description: displayName,
                formSchema: "{}",
                formData: "{}",
                formUiSchema: "{}",
                dictDocumentFormCategoriesId
            },
        }, null)
}

export function updateFormSchemaHeaderActive(formHeaderId, active) {
    return WrapAuthentication("formschema/update/active",
        {
            payload: {
                formHeaderId,
                active: active
            },
        }, null)
}


export function saveFormSchema(payload) {
    return WrapAuthentication("formschema/save",
        {
            payload: {
                id: payload.id,
                code: payload.code,
                dictFormCategoryId: payload.dictFormCategoryId,
                displayName: payload.displayName,
                description: payload.description,
                formSchema: payload.formSchema,
                formData: payload.formData,
                formUiSchema: payload.formUiSchema
            },
        }, null)
}

export function publishFormSchema(payload) {
    return WrapAuthentication("formschema/publish",
        {
            payload: {
                id: payload.id,
                code: payload.code,
                formSchemaId: payload.formSchemaId,
                dictFormCategoryId: payload.dictFormCategoryId,
                displayName: payload.displayName,
                description: payload.description,
                formSchema: payload.formSchema,
                formData: payload.formData,
                formUiSchema: payload.formUiSchema
            },
        }, null)
}


export function setEditFormContext(form) {
    return async (dispatch) => {
        dispatch({type: ActionTypes.FORMS_SET_EDIT_FORM, payload: form})
    }
}

export function clearFormContext() {
    return async (dispatch) => {
        dispatch({type: ActionTypes.FORMS_SET_EDIT_FORM, payload: {}})
    }
}

export function clearFormElements() {
    return async (dispatch) => {
        dispatch({type: ActionTypes.CLEAR_ENCOUNTER_FORMS, payload: []})
    }
}

export function getFormById(formId) {
    return WrapAuthentication("form/get/id",
        {
            payload: {
                id: formId
            }
        }, ActionTypes.GET_FORM_BY_ID)
}

export function setFormReportInputData(FormReportInputData) {
    return async dispatch => {
        dispatch({type: ActionTypes.FORM_REPORT_INPUT_DATA, payload: {payload: FormReportInputData}})
    }
}

export const FormAction =
    {
        getFormSchemaList: getFormSchemaList,
        getAdminFormSchemaList: getAdminFormSchemaList,
        saveNewFormSchema: saveNewFormSchema,
        setEditFormContext: setEditFormContext,
        saveFormSchema: saveFormSchema,
        publishFormSchema: publishFormSchema,
        saveEncounterFormSchema: saveEncounterFormSchema,
        getFormSchemaListByEncounterId: getFormSchemaListByEncounterId,
        deleteEncounterFormSchema: deleteEncounterFormSchema,
        clearFormContext: clearFormContext,
        clearFormElements: clearFormElements,
        updateFormSchemaHeaderActive: updateFormSchemaHeaderActive,
        getFormById: getFormById,
        setFormReportInputData: setFormReportInputData
    }