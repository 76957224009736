import GlobalStyles from "../../config/GlobalStyles";
import ActivityStreamComponent from "../ActivityStreamComponent";
import AnalyticProfilePatientPanelComponent from "../PatientPanelComponents/AnalyticProfilePatientPanelComponent";
import ModelOfCareComponent from "../ModelOfCareComponent";
import NotificationComponent from "../NotificationComponent";
import PatientGridComponent from "../PatientGridComponent";
import PatientListComponent from "../PatientListComponent";
import AdvanceDirectiveComponent from "../PatientPanelComponents/AdvanceDirectiveComponent";
import ClinicalProgramsComponent from "../PatientPanelComponents/ClinicalProgramsComponent";
import NextAppointmentComponent from "../PatientPanelComponents/NextAppointmentComponent";
import PatientDemographicsComponent from "../PatientPanelComponents/PatientDemographicsComponent";
import PatientPanelTabComponent from "../PatientPanelComponents/PatientPanelTabComponent";
import SleepQualityPatientPanelComponent from '../PatientPanelComponents/SleepQualityPatientPanelComponent';
import SleepQualityCareTeamComponent from "../CareTeamComponents/SleepQualityCareTeamComponent";
import ActivityLevelComponent from "../CareTeamComponents/ActivityLevelComponent";
import QualityMeasureCareTeamComponent from "../CareTeamComponents/QualityMeasureCareTeamComponent";
import RafScoreComponent from "../RafScoreComponent";
import RequestComponent from "../TaskListComponent";
import SchedulerComponent from "../CareTeamComponents/SchedulerComponent";
import TileHeader from '../../layout/TileHeader';
import AssessmentComponent from "../AssessmentComponent";
import ActivityLevelPatientPanel from "../PatientPanelComponents/ActivityLevelPatientPanel";
import WeightManagementComponent from "../CareTeamComponents/WeightManagementComponent";
import WeightManagementPatientPanelComponent from "../PatientPanelComponents/WeightManagementPatientPanelComponent"
import BloodPressureComponent from "../PatientPanelComponents/BloodPressureComponent";
import BloodPressureCareTeamComponent from "../CareTeamComponents/BloodPressureCareTeamComponent"
import DocumentViewerComponent from "../PatientPanelComponents/DocumentsTab/DocumentViewerComponent"
import HeartRateVariabilityComponent from "../CareTeamComponents/HeartRateVariabilityComponent";
import RestingHeartRateCareTeamComponent from "../CareTeamComponents/RestingHeartRateCareTeamComponent";
import UtilizationMetricsComponent from "../CareTeamComponents/UtilizationMetricsComponent";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import RiskPatientCareTeam from "../CareTeamComponents/RiskComponentCareTeam";
import HeartRatePatientPanelComponent from "../PatientPanelComponents/HeartRatePatientPanelComponent";
import HRVPatientPanelComponent from "../PatientPanelComponents/HRVPatientPanelComponent";
import AnalyticProfileCareTeamComponent from "../CareTeamComponents/AnalyticProfileCareTeamComponent";
import CohortAnalysisComponent from "../CohortAnalysisComponent";
import ActivityStreamComponentV2 from "../ActivityStreamComponentV2";
import MedicationsComponent from "../PatientPanelComponents/ConditionsTab/Medications/MedicationsComponentV2";

//Components will need to be registered here to be expandable

export const Components = {
    RiskPatientCareTeam: "RiskPatientCareTeam",
    NotificationComponent: "NotificationComponent",
    ActivityStreamComponent: "ActivityStreamComponent",
    SchedulerComponent: "SchedulerComponent",
    PatientPanelTabComponent: "PatientPanelTabComponent",
    RafScoreComponent: "RafScoreComponent",
    QualityMeasureCareTeamComponent: "QualityMeasureCareTeamComponent",
    ModelOfCareComponent: "ModelOfCareComponent",
    RequestComponent: "RequestComponent",
    PatientListComponent: "PatientListComponent",
    PatientDetailsComponent: "PatientDetailsComponent",
    AdvanceDirectiveComponent: "AdvanceDirectiveComponent",
    NextAppointmentComponent: "NextAppointmentComponent",
    ClinicalProgramComponent: "ClinicalProgramComponent",
    AnalyticProfilePatientPanelComponent: "AnalyticProfilePatientPanelComponent",
    SleepQualityPatientPanelComponent: "SleepQualityPatientPanelComponent",
    SleepQualityCareTeamComponent: "SleepQualityCareTeamComponent",
    ActivityLevelComponent: "ActivityLevelComponent",
    PatientGridComponent: "PatientGridComponent",
    AssessmentComponent: "AssessmentComponent",
    ActivityLevelPatientPanel: "ActivityLevelPatientPanel",
    WeightManagementComponent: "WeightManagementComponent",
    WeightManagementPatientPanelComponent: "WeightManagementPatientPanelComponent",
    BloodPressureComponent: "BloodPressureComponent",
    BloodPressureCareTeamComponent: "BloodPressureCareTeamComponent",
    DocumentViewerComponent: "DocumentViewerComponent",
    UtilizationMetricsComponent: "UtilizationMetricsComponent",
    HeartRateVariabilityComponent: "HeartRateVariabilityComponent",
    RestingHeartRateCareTeamComponent: "RestingHeartRateCareTeamComponent",
    HeartRatePatientPanelComponent: "HeartRatePatientPanelComponent",
    AnalyticProfileCareTeamComponent: "AnalyticProfileCareTeamComponent",
    HRVPatientPanelComponent: "HRVPatientPanelComponent",
    CohortAnalysisComponent: "CohortAnalysisComponent",
    MedicationsComponent: "MedicationsComponent"
}

const NotFound = (props) => {
    return (
        <TileContainer>
            <TileHeader title={"Component Not found!"}/>
            <TileBody>
                <div>Please register {props.name} in ComponentRegistry</div>
            </TileBody>l
        </TileContainer>)

}

export const ParseComponent = (c) => {
    const Component = getComponent(c.i)
    return (
        <div style={GlobalStyles.tileWindow} key={c.i}>
            <Component {...c.params} />
        </div>
    )
}

export function getComponent(name) {
    // return (<div>Please register {name} in ComponentRegistry</div>)
    switch (name) {
        case Components.AnalyticProfileCareTeamComponent:
            return AnalyticProfileCareTeamComponent
        case Components.NotificationComponent:
            return NotificationComponent
        case Components.ActivityStreamComponent:
            return ActivityStreamComponent
        case Components.SchedulerComponent:
            return SchedulerComponent
        case Components.PatientPanelTabComponent:
            return PatientPanelTabComponent
        case Components.RafScoreComponent:
            return RafScoreComponent
        case Components.QualityMeasureCareTeamComponent:
            return QualityMeasureCareTeamComponent
        case Components.ModelOfCareComponent:
            return ModelOfCareComponent
        case Components.RequestComponent:
            return RequestComponent
        case Components.PatientListComponent:
            return PatientListComponent
        case Components.PatientDetailsComponent:
            return PatientDemographicsComponent
        case Components.AdvanceDirectiveComponent:
            return AdvanceDirectiveComponent
        case Components.NextAppointmentComponent:
            return NextAppointmentComponent
        case Components.ClinicalProgramComponent:
            return ClinicalProgramsComponent
        case Components.AnalyticProfilePatientPanelComponent:
            return AnalyticProfilePatientPanelComponent
        case Components.SleepQualityCareTeamComponent:
            return SleepQualityCareTeamComponent
        case Components.SleepQualityPatientPanelComponent:
            return SleepQualityPatientPanelComponent
        case Components.ActivityLevelPatientPanel:
            return ActivityLevelPatientPanel
        case Components.ActivityLevelComponent:
            return ActivityLevelComponent
        case Components.PatientGridComponent:
            return PatientGridComponent
        case Components.AssessmentComponent:
            return AssessmentComponent
        case Components.WeightManagementComponent:
            return WeightManagementComponent
        case Components.WeightManagementPatientPanelComponent:
            return WeightManagementPatientPanelComponent
        case Components.BloodPressureComponent:
            return BloodPressureComponent
        case Components.BloodPressureCareTeamComponent:
            return BloodPressureCareTeamComponent
        case Components.DocumentViewerComponent:
            return DocumentViewerComponent
        case Components.UtilizationMetricsComponent:
            return UtilizationMetricsComponent
        case Components.HeartRateVariabilityComponent:
            return HeartRateVariabilityComponent
        case Components.RestingHeartRateCareTeamComponent:
            return RestingHeartRateCareTeamComponent
        case Components.RiskPatientCareTeam:
            return RiskPatientCareTeam
        case Components.HeartRatePatientPanelComponent:
            return HeartRatePatientPanelComponent
        case Components.HRVPatientPanelComponent:
            return HRVPatientPanelComponent
        case Components.CohortAnalysisComponent:
            return CohortAnalysisComponent
        default:
            // // console.log.error(`Component not found ${name}`)
            return (props) => NotFound({...props, name: name})
    }
}
