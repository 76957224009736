import React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Button} from "primereact/button";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../../action/DictionaryDispatchAction";
import {ObservationsThresholdAction} from "../../../action/ObservationsThresholdAction";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {
    InlineColorPicker,
    InlineDictionaryDropDown,
    InlineTextInput,
    RangeFilterCell
} from "../../InputControls/InlineFields";
import _ from "lodash";
import {Fade, Slide} from "@progress/kendo-react-animation";
import {Col, Row} from "react-grid-system";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Container} from "../../../layout/GridLayout";

const OperatorsDropdown = (props) =>
    <InlineDictionaryDropDown
        {...props}
        dataItem={props.dataItem}
        idField={"dictOperatorId"}
        field={"dictOperatorId"}
        displayField={"dictOperatorDisplayName"}
        code={"AH_OPERATORS"}/>

const EditCommandCell = (props) => {
    const {dataItem} = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem?.newItem || false;
    return inEdit ? (
        <td className="k-command-cell">
            <Button
                icon={isNewItem ? "pi pi-save" : "pi pi-save"}
                text
                severity={"success"}
                onClick={() => isNewItem ? props.add(dataItem) : props.update(dataItem)}> {isNewItem ? "Save" : "Save"} </Button>
            <Button
                severity={"danger"}
                icon={"pi pi-ban"}
                onClick={() => props.cancel(dataItem)}>{"Cancel"}</Button>
        </td>
    ) : (
        <td className="k-command-cell">
            <Button icon={"pi pi-pencil"}
                    text
                    severity={"success"}
                    onClick={() => props.edit(dataItem)}/>
            <Button
                style={isNewItem ? {backgroundColor: "#E06666"} : {}}
                severity={"danger"}
                text
                icon={"pi pi-trash"}
                onClick={() =>
                    props.remove(dataItem)
                }/>
        </td>
    );
};


function ObservationsList(props) {
    const {ObservationsThresholdAction, observationThresholdConfiguration, observation} = props
    const [data, setData] = React.useState([]);
    const editField = "inEdit";
    const [errors, setErrors] = React.useState({})
    const [show, setShow] = React.useState(false)
    const [success, setSuccess] = React.useState(false);

    React.useEffect(() => {
        props.DictionaryActions.getDictionaryByCode("AH_OPERATORS");
    }, []);

    React.useEffect(() => {
        if (observation?.id)
            props.ObservationsThresholdAction.getObservationThresholdsById(observation.id);
    }, []);

    React.useEffect(() => {
        if (props.observationThresholdConfiguration[observation.id]) {
            setData(props.observationThresholdConfiguration[observation.id]
                .filter(v => v.dictObservationId == observation.id));
        }
    }, [props.observationThresholdConfiguration])

    const addNew = () => {
        let newDataItem = {
            id: uuidv4(),
            newItem: true,
            inEdit: true
        };
        setData([...data, newDataItem]);
    };


    const enterEdit = (dataItem) => {
        let newData = data.map((item) =>
            item.id === dataItem.id ? {...item, inEdit: true} : item
        );
        setData(newData);
    };

    const itemChange = (event) => {
        const field = event.field || "";
        let dictOperatorDescription = (field == "dictOperatorId") ? props.dictionaries.AH_OPERATORS.find(v => v.id == event.value).description : event.dataItem?.dictOperatorDescription;
        const newData = data.map((item) => {
            if (item.id === event.dataItem.id) {
                if (item.errors && item.errors[field]) {
                    delete item.errors[field];
                }
                return {...item, [field]: event.value, "dictOperatorDescription": dictOperatorDescription};
            } else {
                return item;
            }
        });
        setData(newData);
    };

    const cancel = (dataItem) => {
        const data1 = [...data];
        const originalItem = data.find((p) => p.id === dataItem.id);
        if (!originalItem.newItem) {
            props.ObservationsThresholdAction.getObservationThresholdsById(observation.id);
        } else {
            data1.splice(data1.indexOf(originalItem), 1);
            setData(data1);
        }
    }


    const remove = (dataItem) => {
        if (window.confirm("Are you sure you want to delete this observation threshold ?")) {
            props.ObservationsThresholdAction.deactivateObservationThresholds(dataItem.id).then(() => {
                props.ObservationsThresholdAction.getObservationThresholdsById(observation.id);
            })
            if (data.length <= 1)
                props.ObservationsThresholdAction.setObservationThresholdsToEmpty(observation.id)
        }
    }


    const validateFields = (dataItem) => {
        let errors = {}
        if (!dataItem.description) errors = {...errors, description: "This field is required."}
        if (!dataItem.dictOperatorId) errors = {...errors, operation: "This field is required."}
        if (dataItem.value) {
            if (dataItem.dictOperatorDescription == "Between") {
                if (!dataItem.value.max || !dataItem.value.min) {
                    errors = {...errors, value: "This field is required."}
                }
            } else {
                if (!dataItem.value.value)
                    errors = {...errors, value: "This field is required."}
            }
        } else {
            errors = {...errors, value: "This field is required."}
        }
        if (_.isEmpty(errors)) {
            setErrors({});
            setSuccess(false);
            return true
        } else {
            /*itemChange({
                dataIndex: 0,
                dataItem: dataItem,
                field: "errors",
                value: errors,
            })*/
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
            setErrors(errors);
            return false
        }

    }

    const add = (dataItem) => {
        let payload = {
            "dictObservationId": observation.id,
            "description": dataItem?.description,
            "operationId": dataItem?.dictOperatorId,
            "value": JSON.stringify(dataItem?.value),
            "rangeText": dataItem?.rangeText,
            "rangeColor": dataItem?.rangeColor
        }

        if (validateFields(dataItem)) {
            props.ObservationsThresholdAction.addObservationThresholds(payload)
                .then(() => {
                    props.ObservationsThresholdAction.getObservationThresholdsById(observation.id);
                    props.ObservationsThresholdAction.getSavedObservationThresholds();
                })
            setErrors({})
        }
    };

    const update = (dataItem) => {
        let payload = {
            "id": dataItem?.id,
            "dictObservationId": observation.id,
            "description": dataItem?.description,
            "operationId": dataItem?.dictOperatorId,
            "value": JSON.stringify(dataItem?.value),
            "rangeText": dataItem?.rangeText,
            "rangeColor": dataItem?.rangeColor
        }
        if (validateFields(dataItem)) {
            props.ObservationsThresholdAction.updateObservationThresholds(payload)
                .then(() => {
                    props.ObservationsThresholdAction.getObservationThresholdsById(observation.id);
                    props.ObservationsThresholdAction.getSavedObservationThresholds();
                })
            setErrors({})
        }
    };

    const edit = (props) => {
        return (
            <EditCommandCell
                {...props}
                editField={editField}
                cancel={cancel}
                remove={remove}
                update={update}
                add={add}
                edit={enterEdit}
            />
        );
    };

    const childFactory = (child) => {
        if (!child.props.in) {
            return null;
        }
        return child;
    };
    const slideElements = () => {
        setShow(!show);
    };

    return (<Container>
        <Row>
            <Col col={10}>
                <span>{observation?.displayName}&nbsp;&nbsp;</span>
                <span></span>
                {
                    show ? <span className="k-icon k-i-arrow-60-down" onClick={slideElements}></span> :
                        <span className="k-icon k-i-arrow-60-right" onClick={slideElements}></span>
                }
            </Col>
        </Row>
        <Slide childFactory={childFactory} transitionExitDuration={1500}>
            {
                show ? <>
                    <Row style={{marginBottom: '1%'}}>
                        <Col className={"flex-grow-1"}/>

                        <Col className={"flex-grow-0"}>
                            <Button onClick={addNew} icon={"pi pi-plus"} severity={"success"} text/>
                        </Col>
                    </Row>
                    <Row>
                        <NotificationGroup style={{
                            top: 0,
                            left: "50%",
                            transform: "translateX(-50%)",
                        }}>
                            <Fade>
                                {success && (
                                    <Notification
                                        type={{
                                            style: _.isEmpty(errors) ? "success" : "error",
                                            icon: true,
                                        }}
                                        closable={true}
                                        onClose={() => setSuccess(false)}
                                    >
                                        <span>{_.isEmpty(errors) ? "" : "Required mandatory fields"}</span>
                                    </Notification>
                                )}
                            </Fade>
                        </NotificationGroup>
                    </Row>

                    <Grid
                        editField={editField}
                        data={data}
                        onItemChange={itemChange}
                    >
                        <GridColumn field="description" title="Description" cell={InlineTextInput}/>
                        <GridColumn field="dictOperatorId" title="Operation" width={100} cell={OperatorsDropdown}/>
                        <GridColumn field="value" title="Value" format="{0:c}" cell={RangeFilterCell}/>
                        <GridColumn field="rangeText" title="Range Text" cell={InlineTextInput}/>
                        <GridColumn field="rangeColor" title="Range Color" width={120} cell={InlineColorPicker}/>
                        <GridColumn field="Actions" title="Action" width={120} cell={edit}/>
                    </Grid></> : <></>
            }
        </Slide>
    </Container>);
};


function mapStateToProps(state) {
    return {
        observationThresholdConfiguration: state.observationThreshold.observationThresholdContext,
        dictionaries: state.dictionary.dictionaries,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        ObservationsThresholdAction: bindActionCreators(ObservationsThresholdAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ObservationsList)