import {connect} from "react-redux";
import {DrawerContent, TileLayout} from "@progress/kendo-react-layout";
import EncounterConfigurationComponent from "../EncounterConfigurationComponent";
import React from "react";

const encounterConfigurationTile = [
    {
        defaultPosition: {
            col:1,
            colSpan: 4,
            rowSpan: 6,
        },
        body: <EncounterConfigurationComponent/>,
        header: "Encounter Configuration"
    }
];


const styles = {
    layout:
        {
            width: "95%",
            height: "calc(100vh - 90px)"
        }
}

function EncounterConfigurationPage(props) {
    return <>
        <DrawerContent style={{width: "100%"}}>
            <TileLayout
                style={styles.layout}
                ignoreDrag={(e) => {
                    return !(e.target.classList.contains("k-card-title"));
                }} items={encounterConfigurationTile}>
            </TileLayout>
        </DrawerContent>
    </>
}


function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EncounterConfigurationPage)