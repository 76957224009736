import * as React from 'react';
import {useEffect} from 'react';
import {Field, FormElement} from '@progress/kendo-react-form';
import { Error } from "@progress/kendo-react-labels";
import {Label} from '@progress/kendo-react-labels';
import {TextArea} from '@progress/kendo-react-inputs';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {PatientAction} from "../action/PatientAction";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import {UserActions} from "../action/UserAction";
import DictionaryDropDownList, {Dictionary} from './InputControls/DictionaryDropDownList';
import {DictionaryDispatchAction} from "../action/DictionaryDispatchAction";
import PatientDropDownList from "./InputControls/PatientDropDownList";
import { RadioGroup } from "@progress/kendo-react-inputs";

const ParticipantsEditor = props => {
    const {UserActions, userList} = props
    const initialSort = [
        {
            field: "firstName",
            dir: "asc",
        },
    ];
    const [data, setData] = React.useState(props.userList)
    const [filter, setFilter] = React.useState("")
    const [sort, setSort] = React.useState(initialSort);
    const [currentUserData, setcurrentUserData] = React.useState([]);

    useEffect(() => {
        let filters = {}
        filters.active = true;
        UserActions.getUserListFilter(sort, filters, true)
        setData(props.userList)
    }, [])

    React.useEffect(() => {
        let getUserData = [];
        for(let i = 0; i < props?.currentUser.length; i++) {
            const name = props?.currentUser[i]?.displayName ? props?.currentUser[i]?.displayName.split(" - ")[0] : props?.currentUser[i]?.firstName + " " + props?.currentUser[i]?.lastName;
            getUserData.push({
                            userRoleId:props?.currentUser[i]?.userRoleId,
                            displayName:name
            });
        }
        props?.onChange?.call(undefined, {
                value: getUserData
        });
        setData(props.userList)
    }, [props?.userList, props.currentUser]);

    useEffect(() => {
        let filter = {}
        filter.active = true;
        UserActions.getUserListFilter(sort, filter, true)
        setData(props.userList)
    }, [sort, filter])

    const handleChange = event => {
        if (props.onChange) {
            props.onChange.call(undefined, {
                value: event.value
            });
        }
    };
    const filterChange = (event) => {
        setFilter(event.filter)
    };

    React.useEffect(() => {
        let filter = {}
        filter.searchAll = filter?.value;
        filter.active=true;
        UserActions.getUserListFilter(sort, filter, true)
        setData(props.userList)
    }, [filter]);
    
    return <MultiSelect
        data={data}
        onChange={handleChange}
        value={props.userList && props.userList.length > 0 && (props.value || currentUserData)}
        //itemRender={itemRender}
        textField="displayName"
        dataItemKey="userRoleId"
        filterable={true}
        onFilterChange={filterChange}
    />
};

const MeetingFormatEditor = props => {
    const [value, setValue] = React.useState(props?.value);
    const handleChange = event => {
        if (props.onChange) {
            if (event.value) {
                setValue([...event.value]);
                props.onChange.call(undefined, {
                    value: event.value
                });
            }
        }
    };
    return <MultiSelect
        data={props.meetingFormatDataList}
        onChange={handleChange}
        value={value}
        textField="displayName"
        dataItemKey="id"
    />
}
const MeetingTypeEditor = (props) => {
    const handleChange = event => {
        if (props.onChange) {
            props.onChange.call(undefined, {
                value: event?.target ? event?.target?.value : event.value
            });
        }
    };
    return <DictionaryDropDownList
        style={{width: "100%"}}
        code={Dictionary.ENCOUNTER_TYPE}
        onChange={handleChange}
        idField={"dictionaryId"}
        value={props.value?.id}
        // textField={"dictionaryDisplayName"}
    />
}
const TelehealthAddressEditor = (props) => {
    const handleChange = event => {
        if (props.onChange) {
            props.onChange.call(undefined, {
                value: event.value
            });
        }
    };
    return  <div className="flex flex-column gap-3">
                <RadioGroup data={props.categories} value={props.categories.length ===1 ? props.categories[0].value : props?.value} onChange={handleChange}/>
           </div>
}
const SchedulerForm = props => {
    const {patientList, userList, dictionaries} = props;
    const [isTelehealthCapable, setIsTelehealthCapable] = React.useState(true)
    const [isPatientSelected, setIsPatientSelected] = React.useState(false)
    const [meetingFormats, setMeetingFormats] = React.useState([])
    const [selectedUsers, setSelectedUsers] = React.useState([])
    const [selectedTelehealthAddress, setSelectedTelehealthAddress] = React.useState([])
    const [selectedMeetingFormats, setSelectedMeetingFormats] = React.useState([])
    const [currentUsers, setCurrentUsers] = React.useState([])

    React.useEffect(() => {
       props.DictionaryActions.getDictionaryByCode(Dictionary.MEETING_FORMAT)
       props.UserActions.getAllUsers();
       setCurrentUsers([])
    }, [])

    React.useEffect(() => {
        if (props?.scheduleContext?.Participants) {
            setCurrentUsers(props?.scheduleContext?.Participants)
        } else {
            setCurrentUsers([props?.currentUser])
        }
    }, [props?.scheduleContext])

    React.useEffect(() => {
        if (props?.providerUser?.userRoleId) {
            setCurrentUsers([props?.providerUser]);
        }
    }, [props?.providerUser])

    React.useEffect(() => {
       const selectedUserIds = selectedUsers?.map(v=> v.userRoleId);
       const add = props?.allUsers?.filter(v => selectedUserIds.indexOf(v.userRoleId) > -1 && v.telehealthAddress)
       .map(v=> ({
        label: v.telehealthAddress,
        value: v.userRoleId
       }))
       if (add.length > 0){
            setSelectedTelehealthAddress(add);
       } else {
            setSelectedTelehealthAddress([]);
       }
    }, [selectedUsers])

    React.useEffect(() => {
        if (dictionaries) {
            if (isTelehealthCapable === false) {
                setMeetingFormats(dictionaries[Dictionary.MEETING_FORMAT]?.filter(v=> v?.code?.toLowerCase() !== "meeting_format_video"))
            } else {
                setMeetingFormats(dictionaries[Dictionary.MEETING_FORMAT])
            }
        }
    }, [isTelehealthCapable])

    const handlePatientChange = (e) => {
        const patient = e?.value;
        if (patient && patient?.providerRoleId && patient?.patientRoleId !== props?.scheduleContext?.Patient) {
            props?.UserActions.getUserById({userRoleId: patient?.providerRoleId})
        } else if (patient?.patientRoleId === props?.scheduleContext?.Patient) {
            setCurrentUsers(props?.scheduleContext?.Participants)
        } else {
            setCurrentUsers([props?.currentUser])
            props?.UserActions.clearProviderUser()
        }
        const isVideoSelected = selectedMeetingFormats?.find(v=> v?.code?.toLowerCase() === "meeting_format_video")
        if (isVideoSelected && patient && patient?.patientRoleId) {
            if (patient?.telehealthCapable === null || patient?.telehealthCapable === false) {
                alert("This patient can not support Telehealth visits.");
            }
        }
    }

    const requiredValidatorMeetingFormats = (p) => {
        setSelectedMeetingFormats(p)
        return "";
    }

    const requiredValidator = (p) => {
        const patient = props.patientList?.patients?.find(v=> v.patientRoleId === (p?.patientRoleId || p))
        if (patient && patient?.patientRoleId) {
            setIsPatientSelected(true);
            if (patient?.telehealthCapable === null || patient?.telehealthCapable === false ) {
                setIsTelehealthCapable(false);
            } else {
                setIsTelehealthCapable(true);
            }
        } else {
            setIsTelehealthCapable(true);
        }
        return "";
    }

    return (
        <FormElement horizontal={false}>
            <div className="k-form-field">
                <Label>
                    Title
                </Label>
                <div className="k-form-field-wrap">
                    <Field name={'Title'} component={TextArea} rows={1}/>
                    {(props.name == 'Title' && !props.value) && <Error>{"Field is required."}</Error>}
                </div>
            </div>
            <div className="row" style={{display: 'flex'}}>
                <div className="k-form-field col-6" style={{width: '99%', margin: '0.5%'}}>
                    <Label>
                        Patient
                    </Label>
                    <div className="k-form-field-wrap">
                        <Field name={'Patient'} validator={requiredValidator} onChange={handlePatientChange} component={PatientDropDownList}/>
                    </div>
                </div>
                <div className="k-form-field col-6" style={{width: '99%', margin: '0.5%'}}>
                    <Label>
                        Participants
                    </Label>
                    <div className="k-form-field-wrap">
                        <Field
                            name={'Participants'}
                            onChange={(e)=> setSelectedUsers(e.value)}
                            UserActions={props.UserActions}
                            userList={props.userList}
                            component={ParticipantsEditor}
                            currentUser={currentUsers}/>
                    </div>
                </div>
            </div>
            <div className="row" style={{display: 'flex'}}>
                <div className="k-form-field col-6" style={{width: '99%', margin: '0.5%'}}>
                    <Label style={{width: '100%'}}>
                        Meeting Format
                    </Label>
                    <div className="k-form-field-wrap">
                        <Field
                            name={'MeetingFormat'}
                            validator={requiredValidatorMeetingFormats}
                            isTelehealthCapable={isTelehealthCapable}
                            isPatientSelected={isPatientSelected}
                            meetingFormatDataList={meetingFormats}
                            component={MeetingFormatEditor}/>
                    </div>
                </div>
                {selectedTelehealthAddress.length > 1 && selectedMeetingFormats?.find(v=> v?.code?.toLowerCase() === "meeting_format_video") &&
                    <div className="k-form-field col-6" style={{width: '99%', margin: '0.5%'}}>
                        <tr>
                        <td><Label>
                            Select Telehealth Address
                        </Label></td>
                        <td><i className={"fa fa-asterisk"} style={{fontSize:"10px", color: "red"}}></i></td>
                        </tr>
                        <div className="k-form-field-wrap">
                            <Field
                                name={'telehealthAddressUserRoleId'}
                                categories={selectedTelehealthAddress}
                                component={TelehealthAddressEditor}/>
                        </div>
                    </div>
                }
                <div className="k-form-field col-6" style={{width: '99%', margin: '0.5%'}}>
                    <Label>
                        Meeting Type
                    </Label>
                    <div className="k-form-field-wrap">
                        <Field
                            name={'MeetingType'}
                            meetingFormatDataList={dictionaries[Dictionary.ENCOUNTER_TYPE]}
                            component={MeetingTypeEditor}/>
                    </div>
                </div>
            </div>
        </FormElement>
    )
};
SchedulerForm.propTypes = {
    patientActions: PropTypes.object,
}
const mapStateToProps = (state) => {
    return {
        patientList: state.patient.patientList,
        patientContext: state.patient.patientContext,
        userList: state.user.userList,
        allUsers: state.user.allUsers,
        dictionaries: state.dictionary.dictionaries,
        currentUser: state.user.loggedinUser,
        providerUser: state.user.providerUser,
        scheduleContext: state.schedule.scheduleContext,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        patientActions: bindActionCreators(PatientAction, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerForm)

