import {Window} from "@progress/kendo-react-dialogs";
import React from 'react';
import GlobalStyles from '../../config/GlobalStyles';
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {bindActionCreators} from "redux";
import {FormAction} from "../../action/FormAction";
import {connect} from 'react-redux';
import {Col, Row} from "react-grid-system";

const FormReportsWindow = (props) => {

    const [formReportData, setFormReportData] = React.useState([])

    React.useEffect(() => {
        if (props.FormReportInputData?.formHeaderId)
            props.FormActions.getFormById(props.FormReportInputData?.formHeaderId);
    }, [props?.FormReportInputData]);

    function getObject(theObject, key) {
        var result = null;
        if(Array.isArray(theObject)) {
            for(var i = 0; i < theObject.length; i++) {
                result = getObject(theObject[i], key);
                if (result) {
                    break;
                }
            }
        } else{
            for(var prop in theObject) {
                if(prop === key) {
                     return theObject[key];
                }
                if(typeof theObject[prop] === 'object') {
                    result = getObject(theObject[prop], key);
                    if (result) {
                        break;
                    }
                }
            }
        }
        return result;
    }

    function objToHtmlListInternal(obj, fSchema, fSchemaUI, ele, res) {
        const order = fSchemaUI ? fSchemaUI["ui:order"] : [];
        const schema = getObject(fSchema, ele)
        const data = obj[ele];
        if (Array.isArray(data)) {
            data.map((d,k1)=> {
                if (typeof d === 'object') {
                    return <>{Object.entries(d).forEach(([k,v])=> {
                        objToHtmlListInternal({[k]:v}, schema, fSchemaUI[k], k, res)
                    })}</>
                }
            })

        } else if (typeof data === 'object') {
                order && order.forEach(k=> {
                    const dis = data[k] == 0 ? ""+data[k] : data[k];
                    if (dis) {
                        objToHtmlListInternal({[k]:data[k]}, schema, fSchemaUI[k],  k, res)
                    }
                })
        } else {
            const dataToDisplay = typeof data === 'boolean'? (data === true ? 'Yes' : 'No') : data == 0 ? ""+data : data;
            if (schema?.title && dataToDisplay) {
                if (isNaN(dataToDisplay)) {
                     res.push({
                         title: schema?.title,
                         value: dataToDisplay
                     })
                } else {
                    const index = schema.enum.indexOf(data);
                    const formDataValue = schema.enumNames[index];
                    res.push({
                         title: schema?.title,
                         value: formDataValue
                    });
                }

            }
        }

    }

    function objToHtmlList(obj, fSchema, fSchemaUI) {
        const order = fSchemaUI ? fSchemaUI["ui:order"] : [];
        var res = [];
        order && order.forEach(ele=> {
            return objToHtmlListInternal(obj, fSchema, fSchemaUI[ele], ele, res)
        })
        return res;
    }



    React.useEffect(() => {
        if (props?.FormReportByIdData?.formData && props?.FormReportByIdData?.formSchema) {
            const formData = typeof props?.FormReportByIdData?.formData === 'object' ? props?.FormReportByIdData?.formData : JSON.parse(props?.FormReportByIdData?.formData)
            const formSchemaData = typeof props?.FormReportByIdData?.formSchema === 'object' ? props?.FormReportByIdData?.formSchema : JSON.parse(props?.FormReportByIdData?.formSchema)
            const formUiSchema = typeof props?.FormReportByIdData?.formUiSchema === 'object' ? props?.FormReportByIdData?.formUiSchema : JSON.parse(props?.FormReportByIdData?.formUiSchema)
            let localFormReport = objToHtmlList(formData, formSchemaData, formUiSchema);
            setFormReportData(localFormReport);
        }
    }, [props?.FormReportByIdData?.formData, props?.FormReportByIdData?.formSchema])

    const hideDialog = () => {
        props.PopupActions.hideWindow(PopupWindows.FORM_REPORTS)
    }

    if (props.showWindow[PopupWindows.FORM_REPORTS]) {
        const GlobStyle = {
            ...GlobalStyles.newTitleWrapper,
            overflow: 'hidden', width: '100%',
        }

        return (
            <Window
                title={props?.FormReportInputData?.assessmentTypeName}
                modal={true}
                onClose={hideDialog}
                initialHeight={500}
                initialWidth={650}>

                <div style={GlobStyle}>
                    {formReportData.map((formReportData, index) => (
                        <Row style={{textAlign: 'center', marginBottom: '15px'}}>
                            <Col>
                                <span style={{fontWeight: "bold"}}>{formReportData.title}</span>
                            </Col>
                            <Col>
                                <span>{formReportData.value}</span>
                            </Col>
                        </Row>
                    ))}

                </div>
            </Window>
        )
    } else {
        return null
    }
}

function mapStateToProps(state) {
    return {
        FormReportByIdData: state.form?.formReportById,
        showWindow: state.popupWindow.showWindow,
        FormReportInputData: state.form?.FormReportInputData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        FormActions: bindActionCreators(FormAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormReportsWindow);