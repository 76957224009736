import {ActionTypes} from "../action/LayoutAction";


const initialState = {
    layoutContext: {},
    layoutList: []
}

export const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_LAYOUT:
            return {...state, layoutContext: action.payload.payload};
        case ActionTypes.GET_SAVED_LAYOUT:
            return {...state, layoutList: action.payload.payload.length > 0 ? action.payload.payload[0] : [], totalCount: action.payload.totalCount};
        default:
            return state
    }

}

