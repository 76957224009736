import {ActionTypes} from "../action/WindowAction";


const initialState = {}

export const windowReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CLEAR_WINDOW_STATE:
            return {...state, [action.window]: {}}
        case ActionTypes.SET_WINDOW_STATE:
            return {...state, [action.window]: action.state}
        default:
            return state
    }

}

