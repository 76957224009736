import {TENANT_ID} from "../config/GlobalConfig";
import {url} from "../config/Url";
import {WrapAuthentication} from "../util/AuthWrapper";
import {ParseResponse} from "../util/ParseResponse";

export const ActionTypes = {
    GET_TENANT_CONFIGURATION: "GET_TENANT_CONFIGURATION",
    GET_PERMISSION_GROUP_CONFIG: "GET_PERMISSION_GROUP_CONFIG"
}

function getTenantConfiguration() {
    return async dispatch => {

        let response = await fetch(url('configuration/tenant'), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                payload: {
                    tenantId: TENANT_ID
                }
            })
        }).catch(error => {
            //TODO: Add error handling
            dispatch({type: "AH_ERROR", payload: error})
            // console.log.error(error)
        })
        if (response?.ok) {
            let json = await ParseResponse(response);
            dispatch({type: ActionTypes.GET_TENANT_CONFIGURATION, payload: json});
        }

    }

}

function getPermissionGroupConfiguration() {
    return WrapAuthentication("configuration/permission/group",
        {}, ActionTypes.GET_PERMISSION_GROUP_CONFIG)

}


export const ConfigurationActions =
    {
        getTenantConfiguration: getTenantConfiguration,
        getPermissionGroupConfiguration: getPermissionGroupConfiguration
    }