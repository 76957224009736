import {url} from "../config/Url";
import {ParseResponse} from "../util/ParseResponse";
import {WrapAuthentication} from "../util/AuthWrapper";
import {TENANT_ID} from "../config/GlobalConfig";

export const ActionTypes = {
    MESSAGE_GET_MESSAGE: "MESSAGE_GET_MESSAGE",
    MESSAGE_SEND_MESSAGE: "MESSAGE_SEND_MESSAGE",
    MESSAGE_GET_REPLIES: "MESSAGE_GET_REPLIES",

    MESSAGE_SET_CHANNEL_CONTEXT: "MESSAGE_SET_CHANNEL_CONTEXT",
    MESSAGE_CLEAR_CHANNEL_CONTEXT: "MESSAGE_CLEAR_CHANNEL_CONTEXT",

    MESSAGE_GET_SUBSCRIBED_CHANNELS: "MESSAGE_GET_SUBSCRIBED_CHANNELS",
}


//TODO: This will need to have a channel in context to get the messages. This can't be hardcoded.
function getMessagesForUser(userRoleId, limit = 100, offset = 0) {
    return WrapAuthentication("messages/user",
        {
            payload: userRoleId,
            limit: limit,
            offset: offset
        }, ActionTypes.MESSAGE_GET_MESSAGE)
}

function getMessagesForPatient(patientRoleId, limit = 100, offset = 0) {
    return WrapAuthentication("messages/patient",
        {
            payload: patientRoleId,
            limit: limit,
            offset: offset
        }, ActionTypes.MESSAGE_GET_MESSAGE)
}

//TODO: This should be using a redux store.
function getReplies(payload) {
    return async dispatch => {

        let requestObject = {
            // payload: "782805bd-0ee6-4d25-816c-e55def51bf06",
            payload: payload.messageId,
            limit: 10,
            offset: 0
        }

        let response = await fetch(url('messages/replies'), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'X-AUTH-TOKEN': localStorage.getItem('token'),
                'TENANT-ID': TENANT_ID,
            },
            body: JSON.stringify(requestObject)
        });

        return await ParseResponse(response);
    }
}

//TODO: This should be using a redux store.
function sendMessage(channelId, message, mentions, replyToMessageId) {
    return async dispatch => {
        const body = {channelId: channelId, message: message, mentions: mentions || []};
        // console.log(body)
        if (replyToMessageId) {
            body.replyToMessageId = replyToMessageId;
        }
        let response = await fetch(url('messages/send'), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'TENANT-ID': TENANT_ID,
                'X-AUTH-TOKEN': localStorage.getItem('token')
            },
            body: JSON.stringify(body)
        });
        if (response.ok) {
            // console.log(response)
            let json = await ParseResponse(response);
            return json
        } else {
            // console.log(response)
            let json = await ParseResponse(response);
            return json
        }
    }
}


// function getSubscribedChannels() {
//     return WrapAuthentication('messages/subscribed', {},
//         ActionTypes.MESSAGE_GET_SUBSCRIBED_CHANNELS, ActionTypes.MESSAGE_GET_SUBSCRIBED_CHANNELS_ERROR
//     )
// }


function setChannelContext(channel) {
    return async dispatch =>
        dispatch({type: ActionTypes.MESSAGE_SET_CHANNEL_CONTEXT, payload: channel})
}

function subscribePatientChannel(patientRoleId) {
    return WrapAuthentication('messages/subscribe/patient',
        {payload: {patientRoleId: patientRoleId}},
        ActionTypes.MESSAGE_SET_CHANNEL_CONTEXT
    )
}

function subscribeUserChannel() {
    return WrapAuthentication('messages/subscribe/user', {},
        ActionTypes.MESSAGE_SET_CHANNEL_CONTEXT
    )
}


function clearChannelContext() {
    return (dispatch) => {
        dispatch({action: ActionTypes.MESSAGE_CLEAR_CHANNEL_CONTEXT})
    }
}

export const MessageActions =
    {
        getMessagesForPatient: getMessagesForPatient,
        getMessagesForUser: getMessagesForUser,
        sendMessage: sendMessage,
        getReplies: getReplies,
        setChannelContext,
        clearChannelContext,
        subscribePatientChannel: subscribePatientChannel,
        subscribeUserChannel: subscribeUserChannel,
    }