import * as React from "react";
import {useEffect, useState} from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {PatientAction} from "../../../../action/PatientAction";
import {Button} from "primereact/button";
import {PopupWindowActions, PopupWindows} from "../../../../action/PopupWindowAction";
import {FamilyHistoryActions} from "../../../../action/FamilyHistoryAction";
import ShowForPermissionComponent from "../../../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../../../config/GlobalConfig";
import {Col, Container, Row} from "../../../../layout/GridLayout";

const Conditions = (props) => {
    if (props?.dataItem?.conditions && props?.dataItem?.conditions != undefined) {
        return <td>
            <ul style={{"listStyle": "none"}}>
                {
                    props?.dataItem?.conditions.map(function (item, i) {
                        return <li>{item.description}</li>
                    })
                }
            </ul>
        </td>
    } else {
        return <td></td>
    }
}

const AliveCell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const ageOfDeath = props?.dataItem?.ageOfDeath ? props?.dataItem?.ageOfDeath : null;
    const causeOfDeath = props?.dataItem?.causeOfDeath ? props?.dataItem?.causeOfDeath : null;
    let displayValue
    switch (value) {
        case true:
            displayValue = "Living"
            break;
        case false:
            displayValue = ageOfDeath ? `Deceased at ${ageOfDeath}` : "Deceased";
            break;
        default:
            displayValue = ''
    }
    return (
        <td>
            <span style={{width: '100%', float: 'left'}}> {displayValue} </span>
            <span style={{width: '100%', float: 'left'}}> {causeOfDeath} </span>
        </td>
    );
}

const FamilyHistoryComponent = (props) => {
    let [familyhistory, setFamilyHistory] = useState(null);
    const {
        FamilyHistoryActions,
        PatientActions,
        ...others
    } = props;
    useEffect(() => {
        PatientActions.getFamilyHistory(props.patient.patientContext.patientRoleId);
        FamilyHistoryActions.getFamilyHistoryData(props.patient.patientContext.patientRoleId);
        return () => {
        }
    }, [])
    React.useEffect(() => {
        if (props?.patient?.patientContext?.patientRoleId) {
            PatientActions.getFamilyHistory(props.patient.patientContext.patientRoleId);
            FamilyHistoryActions.getFamilyHistoryData(props.patient.patientContext.patientRoleId)
        }
    }, [props?.patient?.patientContext])

    useEffect(() => {
        setFamilyHistory(props.familyhistory);
    }, [props.familyhistory])

    const editHandler = () => {
        props.PopupActions.setWindowState(PopupWindows.EDIT_FAMILY_HISTORY);
        props.PopupActions.showWindow(PopupWindows.EDIT_FAMILY_HISTORY);
    }

    return (
        <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_FAMILY_HISTORY]}>
            <Container className={"align-content-start"} style={{height: "100%", padding: 0, margin: 0}}>
                <Col style={{height: 50}} col={12}>
                    <Row>
                        <Col className={"align-items-center"}>
                            <h5>Family Member History </h5>
                        </Col>
                        <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_FAMILY_HISTORY]}>
                            <Button text="Image"
                                    icon={"pi pi-pencil"}
                                    size={"small"}
                                    tooltip={"Edit family history"}
                                    onClick={() => editHandler()}/>
                        </ShowForPermissionComponent>
                    </Row>
                </Col>
                <Row style={{width: "100%", height: "calc(100% - 50px)", padding: 0}}>
                    <Grid style={{height: "calc(100% - 10px)", width: "100%"}}
                          data={familyhistory}>
                        <GridColumn field="dictRelationDisplayName" title="Family member"/>
                        <GridColumn field="gender" title="Gender"/>
                        <GridColumn field="alive" title="Alive" cell={AliveCell}/>
                        <GridColumn field="conditions" title="Conditions" cell={Conditions}/>
                    </Grid>
                </Row>
            </Container>
        </ShowForPermissionComponent>
    );
};

function mapStateToProps(state) {
    return {
        patientModel: state.patient.patientModel,
        familyhistory: state.patient.familyHistoryList,
        patient: state.patient,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        FamilyHistoryActions: bindActionCreators(FamilyHistoryActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyHistoryComponent);
