import {ActionTypes} from "../action/PermissionAction";


const initialState = {
    permissionGroups: [],
    adminPermissions: [],
    adminPermissionsCategories: {},
    permissionCategories: [],
}

export const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PERMISSION_GET_PERMISSION_GROUPS:
            return {...state, permissionGroups: action.payload.payload};
        case ActionTypes.ADMIN_PERMISSION_GET_PERMISSION_GROUPS:
            return {...state, adminPermissions: action.payload};
        case ActionTypes.PERMISSION_DICTIONARY_LIST_SUCCESS:
            return {...state, adminPermissionsCategories: {...state.adminPermissionsCategories, [action.code]: action.payload.payload}, pagination: {...state.pagination, [action.code]: {totalCount: action.payload.totalCount}} }
        case ActionTypes.PERMISSION_CATEGORY_LIST_SUCCESS:
            return {...state, permissionCategories: action.payload.payload }
        default:
            return state
    }

}

