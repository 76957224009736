import * as React from "react";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "primereact/button";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import _ from "lodash";
import {UserActions} from "../../action/UserAction";
import {AuthAction} from "../../action/AuthAction";
import LicenseComponent from "../shared/LicenseComponent";
import ContactAddressComponent from "../shared/ContactAddressComponent";
import ContactPhoneComponent from "../shared/ContactPhoneComponent";
import ContactEmailComponent from "../shared/ContactEmailComponent";
import {
    Field,
    FieldCheckboxInput,
    FieldDictionaryDropDown,
    FieldPasswordInput,
    FieldPermissionGroupDropDown,
    FieldRow,
    FieldSection,
    FieldTextInput,
    FieldUserDropDown
} from "../InputControls/FormFields";
import {passwordRegex, PhixPermissions} from "../../config/GlobalConfig";
import {PermissionsTool} from "../../util/PermissionsTool";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Dialog} from "primereact/dialog";
import {Col, Container} from "../../layout/GridLayout";
import {RxIcon} from "../shared/Icons";

const styles = {
    notification: {right: 0, bottom: 0, alignItems: "flex-start", flexWrap: "wrap-reverse"},
}

const EditUserWindow = (props) => {
    const dispatch = useDispatch()
    const showWindow = useSelector(state => state.popupWindow.showWindow)
    const userContext = useSelector(state => state.user.userContext)
    const auth = useSelector(state => state.auth)
    const createNewUser = useSelector(state => state.user.createNewUser)
    const userLicense = useSelector(state => state.user.userLicenseContext)
    const permissionInfo = useSelector(state => state.auth.session.permission)

    const [isAdmin, setIsAdmin] = React.useState(userContext?.eprescribeUser ? true : false);
    const [userInfoModel, setUserInfoModel] = React.useState(false)
    const [isError, setIsError] = React.useState(false);
    const [errMessage, setErrMessage] = React.useState();
    const [stateLicenses, setStateLicenses] = React.useState({});
    const [DEALicenses, setDEALicenses] = React.useState({});

    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        formState: {errors, isDirty, isSubmitting, touchedFields, submitCount},
    } = useForm({defaultValues: {active: true}});
    const [adminUserPermission, setAdminUserPermission] = React.useState({isEdit: false, isView: false})

    React.useEffect(() => {
        setAdminUserPermission(PermissionsTool({
            permissionInfo: permissionInfo,
            viewPermissions: [PhixPermissions.AH_ADMIN_VIEW_USERS],
            editPermissions: [PhixPermissions.AH_ADMIN_EDIT_USERS]
        }));
    }, [permissionInfo]);

    React.useEffect(() => {
        userContext?.userRoleId && UserActions.getUsersInfo({createdUserRoleId: userContext?.userRoleId});
    }, [])

    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });

    const initialSort = [
        {
            field: "firstName",
            dir: "asc",
        },
    ];

    React.useEffect(() => {
        setIsError(!!createNewUser?.payload?.message);
        setErrMessage(createNewUser?.payload?.message ? createNewUser?.payload?.message : "");
    }, [createNewUser])

    React.useEffect(() => {
        setIsAdmin(!userContext?.eprescribeUser);
    }, [userContext])

    React.useEffect(() => {
        const deaLicenseData = userLicense.length ? userLicense.filter((e) => e?.dictLicenseTypeCode === "AH_LICENSE_DEA") : userContext?.licenseDetails?.filter((e) => e?.dictLicenseTypeCode === "AH_LICENSE_DEA");
        const stateLicenseData = userLicense.length ? userLicense.filter((e) => e?.dictLicenseTypeCode === "AH_LICENSE_STATE") : userContext?.licenseDetails?.filter((e) => e?.dictLicenseTypeCode === "AH_LICENSE_STATE");
        if (deaLicenseData?.length > 0) {
            setDEALicenses(deaLicenseData[0])
        }
        if (stateLicenseData?.length > 0) {
            setStateLicenses(stateLicenseData[0])
        }
    }, [userLicense])

    const hideDialog = async () => {
        await PopupWindowActions.hideWindow(PopupWindows.EDIT_USER_WINDOW)(dispatch)
        await UserActions.clearUserContext()(dispatch)
        await UserActions.clearLicenseContext()(dispatch)
        reset({
            userRoleId: null,
            credentials: null,
            personId: null,
            userName: null,
            active: true,
            firstName: null,
            middleName: null,
            lastName: null,
            suffix: null,
            npi: null,
            dictPermissionGroupId: null,
            dictProviderTypeId: null,
            primaryUserRoleSpecialtyCodeId: null,
            secondaryUserRoleSpecialtyCodeId: null,
            managerUserRoleId: null,
            supervisorUserRoleId: null,
            telehealthAddress: null
        });
    };


    const disabled = !!userContext;

    const saveUser = async (form) => {
        let payload = {}
        payload.userRoleId = form.userRoleId
        payload.personId = form.personId
        payload.userName = form.userName
        payload.active = form?.active
        payload.firstName = form?.firstName
        payload.middleName = form?.middleName
        payload.lastName = form?.lastName
        payload.suffix = form?.suffix
        payload.credentials = form?.credentials
        payload.npi = form?.npi
        payload.mfaSecret = userContext?.mfaSecret
        payload.mfaStatus = userContext?.mfaStatus
        payload.telehealthAddress = form?.telehealthAddress
        //TODO: We may want to change this.
        payload.portalUser = false
        payload.dictPermissionGroupId = form?.dictPermissionGroupId?.id ? form.dictPermissionGroupId.id : form?.dictPermissionGroupId
        payload.dictProviderTypeId = form?.dictProviderTypeId?.id ? form.dictProviderTypeId.id : form?.dictProviderTypeId
        payload.primaryUserRoleSpecialtyCodeId = form?.primaryUserRoleSpecialtyCodeId?.id ? form.primaryUserRoleSpecialtyCodeId.id : form?.primaryUserRoleSpecialtyCodeId
        payload.secondaryUserRoleSpecialtyCodeId = form?.secondaryUserRoleSpecialtyCodeId?.id ? form.secondaryUserRoleSpecialtyCodeId.id : form?.secondaryUserRoleSpecialtyCodeId
        payload.managerUserRoleId = form?.managerUserRoleId?.userRoleId ? form?.managerUserRoleId.userRoleId : form?.managerUserRoleId
        payload.supervisorUserRoleId = form?.supervisorUserRoleId?.userRoleId ? form?.supervisorUserRoleId.userRoleId : form?.supervisorUserRoleId
        if (form.userRoleId) {
            payload.userRoleId = form.userRoleId
            await UserActions.updateUser(payload)(dispatch)
            await hideDialog()
        } else {
            payload.secure = form?.secure
            await UserActions.createUser(payload)(dispatch)
        }

    };
    const resetMfa = async (userID, mfaMethods) => {
        var payload = {
            "userRoleId": userID,
            "MFAMethod": mfaMethods
        }
        await UserActions.resetMfaSecret(payload)(dispatch)
    };


    React.useEffect(() => {
        if (userContext && showWindow[PopupWindows.EDIT_USER_WINDOW]) {
            setValue("userRoleId", userContext?.userRoleId)
            setValue("credentials", userContext?.credentials)
            setValue("personId", userContext?.personId)
            setValue("userName", userContext?.userName)
            setValue("active", userContext?.active)
            setValue("firstName", userContext?.firstName)
            setValue("middleName", userContext?.middleName)
            setValue("lastName", userContext?.lastName)
            setValue("suffix", userContext?.suffix)
            setValue("npi", userContext?.npi)
            setValue("dictPermissionGroupId", userContext?.dictPermissionGroupId)
            setValue("dictProviderTypeId", userContext?.dictProviderTypeId)
            setValue("primaryUserRoleSpecialtyCodeId", userContext?.primaryUserRoleSpecialtyCodeId)
            setValue("secondaryUserRoleSpecialtyCodeId", userContext?.secondaryUserRoleSpecialtyCodeId)
            setValue("managerUserRoleId", userContext?.managerUserRoleId)
            setValue("supervisorUserRoleId", userContext?.supervisorUserRoleId)
            setValue("telehealthAddress", userContext?.telehealthAddress)
        }
    }, [userContext?.userRoleId])

    const onSubmitEprescribe = async (e) => {
        e.preventDefault()
        await UserActions.setUserContext(userContext)(dispatch)
        await PopupWindowActions.showWindow(PopupWindows.CREATE_EPRESCRIBE_USER_WINDOW)(dispatch)
        setUserInfoModel(true);
    }


    const UsernameIsUnique = async (username) => {
        if (userContext && userContext?.userName === username) {
            return true;
        }
        let user = await UserActions.checkUsername(username)(dispatch)
        try {
            if (user?.code === "E-145") {
                return true;
            } else {
                return "This username is already assigned to another user. Please select a different username.";
            }
        } catch (e) {
            //return "This username is already assigned to another user. Please select a different username.";
        }
        return true;
    };
    const unlockUser = async (e) => {
        e.preventDefault();
        if (userContext?.userRoleId) {
            await AuthAction.unlockUser({payload: {userRoleId: userContext.userRoleId}})(dispatch)
            await UserActions.setUserContextByUserRoleId(userContext.userRoleId)(dispatch)
            await UserActions.getUserListFilter(initialSort, {}, true, false, dataState.take, dataState.skip)(dispatch)
        } else alert("An error has occured. The user cannot be found.")
    }

    const isLocked = () => {
        if (adminUserPermission.isEdit) {
            return false
        } else if (adminUserPermission.isView) {
            return true;
        }
    }

    return (<Dialog
            maximizable
            header={!_.isEmpty(userContext) ? `Update User` : "Add New User"}
            style={{width: "80%"}}
            footer={<Container>
                <Col className={"flex-grow-1"}/>
                <Col>
                    <Button severity={"success"}
                            onClick={handleSubmit(saveUser)}
                            label={userContext?.userRoleId ? "Update" : "Save"}/>
                    <Button onClick={hideDialog}
                            label={"Cancel"}
                            severity={"danger"}/>
                </Col>
            </Container>}
            modal={true}
            visible={showWindow[PopupWindows.EDIT_USER_WINDOW]}
            onHide={hideDialog}
            initialHeight={800}
            initialWidth={1000}>

            <div className={"sub-title"} style={{paddingTop: 10, paddingBottom: 10}}>User Information</div>
            <div style={{pointerEvents: isLocked() ? 'none' : 'block'}}>
                <FieldSection>
                    <FieldRow>
                        <Field col={6} label={"First Name"} field={"firstName"} error={errors}
                               control={control}
                               register={register} options={{required: "First Name is required."}}>
                            <FieldTextInput/>
                        </Field>
                        <Field col={3} label={"Middle Name"} field={"middleName"} error={errors}
                               control={control}
                               register={register}>
                            <FieldTextInput/>
                        </Field>
                        <Field col={2} label={"User Active?"} field={"active"} error={errors}
                               control={control}
                               register={register}>
                            <FieldCheckboxInput/>
                        </Field>
                        {
                            userContext && userContext?.locked === true ?
                                <Field col={1} label={" "} field={"active"} error={errors}
                                       control={control}
                                       register={register}>
                                    <Button style={{marginLeft: "-125%", backgroundColor: "#e06666", color: "white"}}
                                            className="buttons-container-button"
                                            icon="pi pi-lock"
                                            onClick={(e) => unlockUser(e)}
                                    >
                                        <span style={{color: "white"}}>Unlock</span>
                                    </Button>
                                </Field> : <></>
                        }

                    </FieldRow>
                    <FieldRow>
                        <Field col={6} label={"Last Name"} field={"lastName"} error={errors} register={register}
                               control={control}
                               options={{required: "Last Name is required."}}>
                            <FieldTextInput/>

                        </Field>
                        <Field col={3} label={"Suffix"} field={"suffix"} error={errors} register={register}
                               control={control}>
                            <FieldTextInput/>
                        </Field>
                        <Field col={3} label={"Credentials"} field={"credentials"} error={errors}
                               control={control}
                               register={register}>
                            <FieldTextInput/>
                        </Field>
                    </FieldRow>
                    <FieldRow>

                        <Field col={6} label={"User Name"} field={"userName"} error={errors}
                               control={control}
                               options={!userContext ? {
                                   required: "Username is required.",
                                   validate: UsernameIsUnique
                               } : {validate: UsernameIsUnique}} register={register}>
                            <FieldTextInput disabled={userContext}/>

                        </Field>
                        {!userContext && (

                            <Field col={5} label={"Password"} field={"secure"} error={errors}
                                   control={control}
                                   form
                                   register={register}
                                   options={!userContext ? {
                                       required: "Password is required.",
                                       validate: "Minimum requirements not met",
                                       pattern: {
                                           value: passwordRegex,
                                           message: "Password does not meet requirements"
                                       }
                                   } : {}}>
                                <FieldPasswordInput showToggle={true}/>
                            </Field>

                        )}
                    </FieldRow>
                    <FieldRow>
                        <Field col={6} label={"Telehealth Address (External)"} field={"telehealthAddress"}
                               error={errors}
                               control={control}
                               register={register}>
                            <FieldTextInput/>
                        </Field>
                        <Col col={3} style={{paddingTop: 30}}>
                            <Button
                                onClick={() => resetMfa(userContext.userRoleId, userContext.mfaMethods)}
                                text={!userContext?.mfaStatus}
                                icon={"pi pi-lock"}
                                severity={"danger"}
                                label={userContext?.userRoleId && userContext?.mfaStatus ? "Reset MFA" : "MFA Not Enabled"}
                                disabled={userContext?.mfaStatus === false || (auth &&
                                    auth.session &&
                                    auth.session.permissionGroup &&
                                    auth.session?.permissionGroup?.code !== 'AH_ADMIN')}/>


                            {userContext && <Button
                                // disabled={userContext?.eprescribeUser}
                                text={userContext?.eprescribeUser}
                                icon={userContext?.eprescribeUser ? <RxIcon style={{marginRight: 5}}/> :
                                    <RxIcon style={{marginRight: 5}}/>}
                                severity={userContext?.eprescribeUser ? "success" : "secondary"}
                                label={userContext?.eprescribeUser ? "User Enrolled eRx" : "Enroll User"}
                                onClick={onSubmitEprescribe}>
                            </Button>}


                        </Col>
                    </FieldRow>


                    <FieldRow>
                        <Field control={control} col={6} label={"Provider NPI"} field={"npi"} error={errors}
                               register={register}>
                            <FieldTextInput/>
                        </Field>
                        <Field control={control} col={6} label={"Permission Group"} field={"dictPermissionGroupId"}
                               error={errors}
                               register={register}>
                            <FieldPermissionGroupDropDown dataItem={userContext}/>
                        </Field>
                    </FieldRow>

                    <FieldRow>
                        <Field control={control}
                               col={6}
                               label={"Provider Type"}
                               field={"dictProviderTypeId"}
                               error={errors}
                               register={register}>
                            <FieldDictionaryDropDown code={Dictionary.PROVIDER_TYPE}
                                                     dataItem={userContext}/>
                        </Field>
                        <Field control={control} col={6} label={"Primary Specialty Code"}
                               field={"primaryUserRoleSpecialtyCodeId"}
                               error={errors}
                               register={register}
                               options={{required: "Primary Specialty Code is required."}}
                        >
                            <FieldDictionaryDropDown code={Dictionary.AH_EPRESCRIBE_SPECIALTYCODE}
                                                     defaultItemCode={"US"}/>
                        </Field>
                    </FieldRow>
                    <FieldRow>
                        <Field control={control} col={6} label={"Manager"} field={"managerUserRoleId"} error={errors}
                               register={register}>
                            <FieldUserDropDown/>
                        </Field>
                        <Field control={control} col={6} label={"Secondary Specialty Code"}
                               field={"secondaryUserRoleSpecialtyCodeId"}
                               error={errors}
                               register={register}
                        >
                            <FieldDictionaryDropDown code={Dictionary.AH_EPRESCRIBE_SPECIALTYCODE}/>

                        </Field>

                    </FieldRow>
                    <FieldRow>
                        <Field control={control} col={6} label={"Supervisor"} field={"supervisorUserRoleId"}
                               error={errors}
                               register={register}>
                            <FieldUserDropDown/>

                        </Field>
                    </FieldRow>

                </FieldSection>

                {disabled && (
                    <div>
                        <div className="sub-title">Provider Licenses</div>
                        <LicenseComponent userRoleId={userContext?.userRoleId}/>
                    </div>
                )}
                <NotificationGroup style={styles.notification}>
                    {isError ?
                        <Notification type={{style: "error", icon: true}} closable={true}
                                      onClose={() => setIsError(false)}>
                            {errMessage}
                        </Notification>
                        : null}

                </NotificationGroup>
                {disabled && (
                    <div>
                        <div className="sub-title">Contact Address</div>
                        <ContactAddressComponent
                            personId={userContext?.personId}
                        />
                    </div>
                )}

                {disabled && (
                    <div>
                        <div className="sub-title">Contact Phone</div>
                        <ContactPhoneComponent
                            personId={userContext?.personId}
                        />
                    </div>
                )}
                {disabled && (
                    <div>
                        <div className="sub-title">Contact Email</div>
                        <ContactEmailComponent
                            personId={userContext?.personId}
                        />
                    </div>
                )}

            </div>
        </Dialog>
    )
};


export default EditUserWindow
