import {DatePicker, DateTimePicker} from "@progress/kendo-react-dateinputs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Label} from "@progress/kendo-react-labels";
import * as React from "react";
import {useState} from "react";
// import {Col, Container, Row} from 'react-grid-system';
import {Col, Container, Row} from "../../layout/GridLayout";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {Controller} from "react-hook-form";
import DictionaryDropDownList from "./DictionaryDropDownList";
import DictionarySearchList from "./DictionarySearchList";
import FacilityDropDownList from "./FacilityDropDownList";
import MedicalProblemDropDownList from "./MedicalProblemDropDownList";
import MultiMedicalProblemSelect from "./MultiMedicalProblemSelect";
import PermissionGroupDropDownList from "./PermissionGroupDropDownList";
import UserDropDownList from "./UserDropDownList";
import PatientDropDownList from "./PatientDropDownList";
import MultiDictionarySelect from "./MultiDictionarySelect";
import {Checkbox, RadioButton} from "@progress/kendo-react-inputs";
import {filterBy} from "@progress/kendo-data-query";
import {InsurancePlanComboBox} from "./InsurancePlanComboBox";
import { Editor } from 'primereact/editor';



export const FieldColumn = (props) => {
    return <Col {...props} style={{paddingBottom: 10}}>
        {props.children}
    </Col>
}

export const FieldRow = (props) => {
    return <Row gutter style={{width: "100%"}} className={"phix-field-row"}>
        {props.children}
    </Row>
}

export const FieldSection = (props) => {
    const {disabled = false} = props
    if (disabled)
        return (
            <Container {...props}>
                <fieldset disabled={true}>
                    {props.children}
                </fieldset>
            </Container>)
    else
        return (
            <Container  {...props}>
                {props.children}
            </Container>)


}
export const Field = (props) => {
    const {
        position = "top",
        field,
        className,
        label = "",
        error,
        register,
        options,
        control,
        disabled,
        col,
        name,
        value,
        hideError,
        placeholder = "",
        form = false
    } = props
    let fields = field.split(".")
    let errorobj = error[fields[0]];
    if (field.indexOf(".") !== -1) {
        errorobj = error[fields[0]]?.[fields[1]]
    }

    return (<FieldColumn col={col} className={className}>
        {position === "top" && label !== "" && <Label style={{
            width: "100%",
            fontSize: 14,
            paddingBottom: 2,
            fontWeight: "bold",
            backgroundColor: errorobj?.message || (errorobj && errorobj.type === "validate") ? "yellow" : "white",
        }}>{label}</Label>}
        <div className={"k-form-field-wrap"}>
            {form ?
                <form>
                    {React.cloneElement(props.children, {
                        field,
                        error,
                        register,
                        options,
                        control: control,
                        disabled,
                        name,
                        value,
                        placeholder
                    })}
                </form> :
                React.cloneElement(props.children, {
                    field,
                    error,
                    register,
                    options,
                    control: control,
                    disabled,
                    name,
                    value,
                    placeholder
                })
            }
            {position === "right" && label !== "" && <Label
                style={{
                    display: "inline-block",
                    fontWeight: "bold",
                    marginLeft: "2px",
                    width: "auto",
                    backgroundColor: errorobj?.message || (errorobj && errorobj.type === "validate") ? "yellow" : "white",
                }}>{label}</Label>}
            {!hideError && <span className="k-error-colored"> {errorobj?.message}</span>}
        </div>
    </FieldColumn>)
}
export const Column = (props) => {
    return (<FieldColumn col={props.col ? props.col : ""} {...props}>
        {props.children}
    </FieldColumn>)
}
export const FieldTextInput = (props) => {
    const {field, register, options} = props
    return <input className={"k-input k-textbox"}
                  type={"text"}
                  disabled={props.disabled}
                  id={field}
                  {...props}
                  {...register(field, options ? options : {})}

    />

}
export const FieldNumberInput = (props) => {
    const {field, register, options} = props
    return <input className={"k-input k-textbox"}
                  {...props}
                  {...register(field, options ? options : {})}
                  type={"number"}
                  disabled={props.disabled}
                  id={field}
    />

}
/*
* There is a bug here that you have to set the default value to a blank string
* Reseting the form doesn't work without a default value!
* useForm({defaultValues: {description: ""}});
 */
export const FieldTextAreaInput = (props) => {
    const {field, style, control, options, rows, placeholder} = props
    const [value, setValue] = React.useState("");
    return <Controller
        render={({field}) => <textarea
            style={{width: "100%", ...style}}
            {...field}
            rows={rows}
            placeholder={placeholder}
        />}
        rules={options}
        control={control}
        name={field}
    />

}

export const FieldEditorInput = (props) => {
    return  <Controller
               name={props?.field}
               control={props?.control}
               rules={props?.options}
               render={({ field }) => <Editor style={props?.style} id={field.name} name={props?.field} value={field.value} onTextChange={(e) => field.onChange(e.textValue)}/>}
           />

}

export const FieldPasswordInput = (props) => {
    const {field, register, options, showToggle} = props
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };
    return (<div className="pass-wrapper" style={{display: "inline-block", width: "100%", position: "relative"}}>
            {" "}
            <input
                style={{width: "100%"}}
                placeholder="Password"
                name="password"
                autoComplete="new-password"
                type={passwordShown ? "text" : "password"}
                {...register(field, options ? options : {})}
            />
            <i onClick={togglePasswordVisiblity}
               style={{position: "absolute", right: 5, top: 0}}>{passwordShown ? <AiOutlineEyeInvisible/> :
                <AiOutlineEye/>}</i>{" "}
        </div>
    )

}

export const FieldCheckboxInput = (props) => {
    const {field, style, control, options, code, defaultItemCode, disabled} = props
    const [value, setValue] = React.useState(null);

    return <Controller
        render={({field}) => <div style={{marginTop: 10}}>
            <Checkbox
                style={{...style}}
                defaultItemCode={defaultItemCode}
                label={props.label}
                code={code}
                active={props?.active}
                {...field}
                disabled={disabled}
            /></div>}
        rules={options}
        control={control}
        value={value}
        onChange={([value]) => {
            setValue(value);
            return value;
        }}
        name={field}
    />
    // return <Checkbox
    //     {...props}
    //     {...register(field, options ? options : {})}
    //     id={field}
    // />

}

export const FieldRadioInput = (props) => {
    const {field, register, options} = props
    return <input
        {...props}
        {...register(field, options ? options : {})}
        className={"k-radio k-radio-md"}
        id={field}
        type={"radio"}
    />

}
/**
 * This function will only return the first 500 dictionary entries.
 * Please use FieldDictionarySearchList if you have a dictionary with more entries
 */
export const FieldDictionaryDropDown = (props) => {
    const {field, style, control, options, code, defaultItemCode, disabled, active} = props
    const [value, setValue] = React.useState(null);
    return <Controller
        render={({field}) => <DictionaryDropDownList
            style={{width: "100%", height: "30px", ...style}}
            defaultItemCode={defaultItemCode}
            code={code}
            active={props?.active}
            {...field}
            disabled={disabled}
        />}
        rules={options}
        control={control}
        value={value}
        onChange={([value]) => {
            setValue(value);
            return value;
        }}
        name={field}
    />


}

export const FieldInsurancePlanComboBox = (props) => {
    const {field, style, control, options, code, defaultItemId, disabled, active} = props
    const [value, setValue] = React.useState(null);
    return <Controller
        render={({field}) => <InsurancePlanComboBox
            style={{width: "100%", height: "30px", ...style}}
            defaultItemId={defaultItemId}
            code={code}
            active={props?.active}
            {...field}
            disabled={disabled}
        />}
        rules={options}
        control={control}
        value={value}
        onChange={([value]) => {
            setValue(value);
            return value;
        }}
        name={field}
    />


}


export const FieldDictionarySearchList = (props) => {
    const {field, options, code, style, control} = props
    const [value, setValue] = React.useState(null);
    return <Controller
        render={({field}) => <DictionarySearchList
            style={{width: "100%", height: "30px", ...style}}
            code={code}
            {...field}
        />}
        rules={options}
        control={control}
        value={value}
        onChange={([value]) => {
            setValue(value);
            return value;
        }}
        name={field}
    />

}
export const FieldDatePicker = (props) => {
    const {field, register, options, disabled, control} = props
    const [selectedDate, setSelectedDate] = React.useState(null);

    return <Controller
        render={(props) => <DatePicker
            {...props.field}
            width={"100%"}
            format="yyyy-MM-dd"
            disabled={disabled}

        />}
        rules={options}
        control={control}
        valueName="selected"
        selected={selectedDate}
        onChange={([selected]) => {
            setSelectedDate(selected);
            return selected;
        }}

        placeholderText="Select Date"
        name={field}
        defaultValue={null}
    />
}
export const FieldDateTimePicker = (props) => {
    const {field, register, options, control} = props
    const [selectedDate, setSelectedDate] = React.useState(null);

    return <Controller
        render={(props) => <DateTimePicker
            {...props.field}
            width={"100%"}
            // format="yyyy-MM-dd"

        />}
        rules={options}
        control={control}
        valueName="selected"
        selected={selectedDate}
        onChange={([selected]) => {
            setSelectedDate(selected);
            return selected;
        }}

        placeholderText="Select Date/Time"
        name={field}
        defaultValue={null}
    />
}

export const FieldMultiProblemSelector = (props) => {

    const {field, options, style, control, patientRoleIDprop, propReferralCheck, isActive} = props

    return <Controller
        render={({field}) => <MultiMedicalProblemSelect
            style={{width: "100%", height: "400px", ...style}}
            {...field}
            patientRoleIDprop={patientRoleIDprop}
            propReferralCheck={propReferralCheck}
            fieldRequestFrom={field}
            isActive={isActive}
        />}
        rules={options}
        control={control}
        name={field}
    />
}

export const FieldDropDown = (props) => {
    const {field, register, options, disabled, control, textField, dataItemKey, defaultValue} = props
    const [value, setValue] = React.useState(null);
    const [data, setData] = React.useState(props.data.slice());
    const filterData = (filter) => {
        const data = props.data.slice();
        return filterBy(data, filter);
    };
    const filterChange = (event) => {
        setData(filterData(event.filter));
    };
    return <Controller
        render={(props) => <DropDownList
            {...props.field}
            textField={textField}
            filterable={true}
            onFilterChange={filterChange}
            dataItemKey={dataItemKey}
            data={data}
            style={{width: "100%"}}
            disabled={disabled}

        />}
        rules={options}
        control={control}
        valueName="selected"
        selected={value}
        onChange={([selected]) => {
            setValue(selected);
            return selected;
        }}

        placeholderText="Select"
        name={field}
        defaultValue={defaultValue}
    />
}


export const FieldUserDropDown = (props) => {
    const {field, options, control} = props
    const [value, setValue] = React.useState(null);

    return <Controller
        render={(props) => <UserDropDownList
            style={{width: "100%", height: "30px"}}
            {...props.field}

        />}
        rules={options}
        control={control}
        valueName="value"
        value={value}
        onChange={([value]) => {
            setValue(value);
            return value;
        }}
        name={field}
    />

}

export const FieldFacilityDropDown = (props) => {
    const {field, register, dataItem, options} = props
    return <FacilityDropDownList
        style={{width: "100%", height: "30px"}}
        labelName={null}
        idField={field}
        dataItem={dataItem}
        {...register(field, options ? options : {})}
    />

}

export const FieldMedicalProblemDropDown = (props) => {

    const {field, style, control, options} = props
    const [value, setValue] = React.useState(null);
    return <Controller
        render={({field}) =>
            <MedicalProblemDropDownList
                style={{width: "100%", height: "30px", ...style}}
                {...field}
            />}
        rules={options}
        control={control}
        valueName="value"
        value={value}
        onChange={([value]) => {
            setValue(value);
            return value;
        }}
        name={field}
    />


}

export const FieldMultiDictionarySelect = (props) => {

    const {field, style, control, options, code} = props
    const [value, setValue] = React.useState(null);
    return <Controller
        render={({field}) =>
            <MultiDictionarySelect
                useIndex={props.useIndex}
                code={code}
                {...field}
            />}
        rules={options}
        control={control}
        valueName="value"
        value={value}
        onChange={([value]) => {
            setValue(value);
            return value;
        }}
        name={field}
    />


}

export const FieldPermissionGroupDropDown = (props) => {
    const {field, style, control, options, code, defaultItemCode} = props
    const [value, setValue] = React.useState(null);
    return <Controller
        render={({field}) => <PermissionGroupDropDownList
            style={{width: "100%", height: "30px", ...style}}
            defaultItemCode={defaultItemCode}
            code={code}
            idField={field}
            className={"k-checkbox"}
            {...props}
            {...field}
        />}
        rules={options}
        control={control}
        valueName="value"
        value={value}
        onChange={([value]) => {
            setValue(value);
            return value;
        }}
        name={field}
    />
}

export const FieldPatientDropdown = (props) => {
    const {field, style, control, options, code, defaultItemCode} = props
    const [value, setValue] = React.useState(null);
    return <Controller
        render={({field}) =>
            <PatientDropDownList
                style={{width: "100%", height: "30px", ...style}}
                {...props}
                {...field}
            />}
        rules={options}
        control={control}
        valueName="value"
        value={value}
        onChange={([value]) => {
            setValue(value);
            return value;
        }}
        name={field}
    />
}

export const FieldRadio = (props) => {

    const {field, style, control, options, defaultItemCode, value, defaultChecked} = props
    const [selectedValue, setSelectedValue] = React.useState(defaultChecked);
    const handleChange = React.useCallback(
        (e) => {
            setSelectedValue(e.value);
        },
        [setSelectedValue]
    );
    return <RadioButton
        options={options}
        control={control}
        value={value}
        onChange={handleChange}
        name={field}
        checked={selectedValue === value}
    />
}