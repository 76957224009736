import * as React from "react";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {UserActions} from "../action/UserAction";
import {Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {Button} from "primereact/button";
import {Input} from "@progress/kendo-react-inputs";
import {PopupWindowActions, PopupWindows} from "../action/PopupWindowAction";
import {debounce} from "lodash";
import {VeradigmRenderer, VeradigmSsoComponent} from "./shared/VeradigmRenderer";
import {Col, Row} from "react-grid-system";
import {useDispatch, useSelector} from "react-redux";
import {PhixPermissions} from "../config/GlobalConfig";
import ShowForPermissionComponent from "./shared/ShowForPermissionComponent";
import {Checkbox} from "./InputControls/Checkbox";
import {DropdownFilterCell} from "./InputControls/DropdownFilterCell";


const UserListComponent = (props) => {
    const styles = {
        grid: {
            height: "calc(100vh - 250px)"
        }
    }

    const initialSort = [
        {
            field: "firstName",
            dir: "asc",
        },
    ];
    const initialFilter = {
        logic: "and",
        filters: [],
    };
    const dispatch = useDispatch()
    const totalCount = useSelector(state => state.user.totalCount)
    const currentUser = useSelector(state => state.user.loggedinUser)
    const users = useSelector(state => state.user.userList)
    const saml = useSelector(state => state.user.getSAML)
    const [eprescribeFlag, setEprescribeFlag] = React.useState(false)
    const [showAllFlag, setshowAllFlag] = React.useState(false);
    const [filter, setFilter] = React.useState(initialFilter);
    const [filterState, setFilterState] = React.useState(false)
    const [sort, setSort] = React.useState(initialSort);
    const [data, setData] = React.useState([]);
    const [searchState, setSearchState] = React.useState("")

    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });
    const toggleDialogAdd = () => {
        PopupWindowActions.showWindow(PopupWindows.EDIT_USER_WINDOW)(dispatch);
    };
    const toggleDialogEdit = (user) => {
        UserActions.setUserContext(user)(dispatch)
        PopupWindowActions.showWindow(PopupWindows.EDIT_USER_WINDOW)(dispatch);
    };

    React.useEffect(() => {
        let filters = {}
        if (searchState.length > 0) filters.searchAll = searchState
        if (filter?.filters) {
            if (filter.filters.length > 0) {
                filter.filters.forEach(x => {
                    filters[x.field] = x.value
                })
            }
        }
        UserActions.getUserListFilter(sort, filters, !showAllFlag, false, dataState.take, dataState.skip)(dispatch)
    }, [dataState, sort, filter, showAllFlag, searchState])


    React.useEffect(() => {
            let listdata = users.map((obj) => {
                return {...obj, active: `${obj.active}`}
            })
            setData(listdata)

        }, [users]
    )


    const showAllfn = (e) => {
        console.log(e)
        let v = e.target.value;
        setshowAllFlag(v);
        // setFilterState(false);
    }

    async function submitEprescribe() {
        if (currentUser?.eprescribeUser) {
            await UserActions.getSamlTaskMode()(dispatch)
            setEprescribeFlag(true)
        }
    }

    const userEdit = (props) => {
        return (
            <td className="k-command-cell">
                <Button
                    severity={"success"}
                    text
                    tooltip={`Edit user`}
                    tooltipOptions={{position: "top"}}
                    icon={"pi pi-pencil"}
                    onClick={() => toggleDialogEdit({
                        ...props.dataItem,
                        active: props.dataItem.active === "true"
                    })}/>
            </td>
        );
    };
    const isUserActive = (props) => {
        return <td><Checkbox id={props.dataItem.active} checked={props.dataItem.active === "true"}/></td>
    }
    const IsMFAEnabled = (props) => {
        return <td><Checkbox id={props.dataItem?.userRoleId && props.dataItem?.mfaStatus} checked={props.dataItem?.userRoleId && props.dataItem?.mfaStatus}/></td>
    }

    const MFAEnableFilter = (props) => {
        return <DropdownFilterCell
                    {...props}
                    data={['Enabled', 'Disabled']}
                    defaultItem={"Select MFA Status"}
                />
    }

    function handleFilterChange(filter) {
        // console.log(filter)
    }

    const onSearch = debounce((e) => {
        let temp = e.target.value;
        setshowAllFlag(false);
        setFilter({searchAll: temp})
    }, 500);

    const UserNameWithLock = (p) => {
        if (p.dataItem.locked) {
            return <td>
                <Row>
                    <Col sm={8}>
                        {p.dataItem.userName}
                    </Col>
                    <Col>
                        <span style={{color: '#e06666'}} class="k-icon k-i-lock"></span>
                    </Col>
                </Row>
            </td>
        } else {
            return <td>
                {p.dataItem.userName}
            </td>

        }

    }


    return (<ShowForPermissionComponent component permissions={[PhixPermissions.AH_ADMIN_VIEW_USERS]}>
        <div style={{margin: 0, width: "100%"}}>

            <Toolbar style={{width: "100%"}}>
                <Input
                    style={{width: "50%"}}
                    placeholder={"Search"}
                    id={"input-id"}
                    //value={searchState}
                    onChange={(e) => setSearchState(e.value)}
                    // onChange={onSearch}
                />

                <ToolbarItem>
                    <Button className="rounded-button" togglable={true} icon="pi pi-filter" text="Filter Users"
                            onClick={() => setFilterState(!filterState)}/>
                </ToolbarItem>
                <ToolbarSpacer/>
                <ToolbarItem>
                    <Checkbox label="Show All" onChange={showAllfn} checked={showAllFlag}/>
                </ToolbarItem>
                <ToolbarSpacer/>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_ADMIN_EDIT_USERS]}>
                    <ToolbarItem>
                        <Button className="rounded-button"
                                icon="pi pi-plus" text
                                tooltip={"Add new user"}
                                tooltipOptions={{position: "top"}}
                                onClick={toggleDialogAdd}/>

                    </ToolbarItem>
                </ShowForPermissionComponent>
                <ToolbarItem>
                    {currentUser?.eprescribeUser && (
                        <Button
                            primary={true}
                            onClick={submitEprescribe}
                        >eRx Utility Mode</Button>
                    )}
                </ToolbarItem>
            </Toolbar>

            {eprescribeFlag && (
                <VeradigmRenderer>
                    <VeradigmSsoComponent
                        value={saml?.samlResponse ? saml.samlResponse : null}
                        setEprescribeFlag={setEprescribeFlag}/>
                </VeradigmRenderer>
            )}

            <Grid
                skip={dataState.skip}
                pageSize={dataState.take}
                style={styles.grid}
                data={data}
                filterable={filterState}
                filter={filter}
                total={totalCount}
                onFilterChange={(e) => {
                    setFilter(e.filter)
                }}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                    setSort(e.sort);
                }}
                pageable={{
                    buttonCount: 10,
                    info: true,
                    type: "numeric",
                    pageSizes: [1, 5, 10],
                    previousNext: true,
                }}>
                <GridColumn field="firstName" title="First Name"/>
                <GridColumn field="lastName" title="Last Name"/>
                <GridColumn field="userName" title="Username" cell={UserNameWithLock} sortable={false}/>
                <GridColumn field="dictPermissionGroupDisplayName" title="Role"/>
                <GridColumn field="email" title="Email"/>
                <GridColumn filterCell={MFAEnableFilter} field="mfaStatus" title="MFA Enabled" cell={IsMFAEnabled} width={120}/>
                <GridColumn filterable={false} field="active" title="Active" cell={isUserActive} width={80}/>
                <GridColumn sortable={false} filterable={false} field="action" title="Action" cell={userEdit}
                            width={80}/>
            </Grid>


        </div>
    </ShowForPermissionComponent>)
}


export default UserListComponent
