import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PopupWindowActions, PopupWindows} from "../../../../action/PopupWindowAction";
import {Colors, PhixPermissions} from "../../../../config/GlobalConfig";
import ShowForPermissionComponent from "../../../shared/ShowForPermissionComponent";
import {Col, Container} from "../../../../layout/GridLayout";
import PhixDataGrid from "../../../../typescript/component/data/PhixDataGrid";
import {PostApi} from "../../../../util/AuthWrapper";
import {GridToolbarContainer, GridToolbarQuickFilter} from "@mui/x-data-grid";
import {ActiveToggleButton, AddButton, RxButton} from "../../../../typescript/component/button/Buttons";
import {NewDateGridCellWithFormat} from "../../../../util/DateTools";
import {useComponentUpdate} from "../../../../typescript/context/ComponentUpdateProvider";
import {Components} from "../../../shared/ComponentRegistry";
import {VeradigmRenderer, VeradigmSsoComponent} from "../../../shared/VeradigmRenderer";
import {UserActions} from "../../../../action/UserAction";
import MedicationActions from "../../../../action/MedicationAction";


export default function MedicationsComponent(props) {
    const patientContext = useSelector(state => state.patient.patientContext)
    const {register, update, components} = useComponentUpdate()
    const [updateComponent, setUpdateComponent] = useState()

    useEffect(() => {
        let updateNum = components[Components.MedicationsComponent]
        if (updateNum !== undefined && updateNum !== updateComponent)
            setUpdateComponent(updateNum)
    }, [components]);

    const saml = useSelector(state => state.user.getSAMLValuePatientContext)
    useEffect(() => {
        register(Components.MedicationsComponent)
        syncMedications()
    }, []);

    const [eprescribeFlag, setEprescribeFlag] = React.useState(false)
    const [syncing, setSyncing] = React.useState(true)
    const dispatch = useDispatch()


    const [active, setActive] = React.useState(true)
    const PatientContext = useSelector(state => state.patient.patientContext)

    async function submitEprescribe() {
        if (patientContext?.patientRoleId) {
            await UserActions.getSAMLValuePatientContext({patientRoleId: patientContext?.patientRoleId})(dispatch)
            setEprescribeFlag(true)
        }
    }

    async function syncMedications() {
        setSyncing(true)
        await PostApi("veradigm/patient/medication/sync", {payload: {patientRoleId: patientContext?.patientRoleId}})
        update(Components.MedicationsComponent)
        setSyncing(false)

    }

    const getdata = useCallback(
        MedicationActions.GetMedicationsPaginatedFn(PatientContext?.patientRoleId, active)
        , [PatientContext, updateComponent, active]
    )
    const MedicationDiagnoses = ({row}) => {
        return <td>{
            row?.medicalProblems?.map(v => <tr>{v?.displayName || v?.description}</tr>)
        }</td>;
    }
    let columns = [
        {
            field: "displayName",
            headerName: "Medication Name",
            flex: 2,
        },
        {
            field: "medicalProblems",
            headerName: "Linked Diagnoses",
            sortable: false,
            filterable: false,
            renderCell: (params) => MedicationDiagnoses(params),
            flex: 2
        },
        {
            field: "startDate", headerName: "Start Date", flex: 1,
            valueGetter: NewDateGridCellWithFormat("MM/dd/yyyy")
        },
        {
            field: "endDate", headerName: "End Date", flex: 1,
            valueGetter: NewDateGridCellWithFormat("MM/dd/yyyy")
        },
        {
            field: "dictClinicalStatusDisplayName",
            headerName: "Clinical Status",
            flex: 1
        },

    ]

    function OpenCreateMedicationWindow(context) {
        PopupWindowActions.showWindow(PopupWindows.ADD_MEDICATIONS_WINDOW, {medicationContext: context})(dispatch)
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer container>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ORDER]}>
                    <AddButton tooltip={"Add Manual Medication"} onClick={e => OpenCreateMedicationWindow(undefined)}/>
                </ShowForPermissionComponent>


                {patientContext?.eprescribePatient &&
                    <RxButton tooltip={"ePrescribe"} onClick={submitEprescribe}/>}
                {syncing && <div style={{color: Colors.RED}}>Syncing Medications</div>}
                <ActiveToggleButton
                    style={{border: "none", marginLeft: "auto", height: 30, width: 30}}
                    tooltip={"Toggle Active Only"} onChange={() => setActive(!active)} selected={active}/>
                <GridToolbarQuickFilter debounceMs={300}/>
            </GridToolbarContainer>
        );
    }

    return (
        <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_ORDER]} component>
            <Container style={{height: "100%"}}>
                <Col col={12} style={{height: "100%"}}>
                    <PhixDataGrid
                        style={{width: "calc(100% - 0px)"}}
                        getRowId={r => r.medicationId}
                        density={"compact"}
                        rows={[]}
                        columns={columns}
                        disableRowSelectionOnClick
                        sortModel={[{
                            field: "displayName",
                            sort: "asc"
                        }]}
                        onRowClick={e => OpenCreateMedicationWindow(e.row)}
                        loadingIndicator
                        apiFunction={getdata}
                        slots={{toolbar: CustomToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: {debounceMs: 500},
                            },
                        }}
                    />
                </Col>
                {
                    eprescribeFlag && (
                        <VeradigmRenderer>
                            <VeradigmSsoComponent
                                value={saml?.samlResponse ? saml.samlResponse : null}
                                setEprescribeFlag={setEprescribeFlag}/>
                        </VeradigmRenderer>
                    )
                }
            </Container>


        </ShowForPermissionComponent>
    );
}