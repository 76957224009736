import {ActionTypes as BaseRequestActionTypes} from "../action/BaseRequestAction";

const initialState = {
    RequestList: [],
    // RequestListError:null,
    RequestContext: null,
    createRequestContext: null,
    Requestupdated:null,
    ViewByAssignedUser:false,
    onlycompleted:false,
    requestListContext : {
        assignedUser:localStorage.userRoleId,
        createdUser:null,
        requestListCategory:{name: "In progress / Not Started" , key: "in_progress_not_started", codes: ["AH_IN_PROGRESS", "AH_NOT_STARTED"] },
        isFilterApplied: false
    }

}

export const BaseRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case BaseRequestActionTypes.REQUEST_LIST_SUCCESS:
            return {
                ...state,
                RequestList: action.payload.payload,
            };
        case BaseRequestActionTypes.REQUEST_LIST_SUCCESS_ERROR:
            return {...state, RequestListError: action.payload}
        case BaseRequestActionTypes.REQUEST_SET_CREATE_CONTEXT:
            return {...state, createRequestContext: action.payload.payload, Requestupdated: true}
        case BaseRequestActionTypes.UPDATED_REQUEST_CONTEXT:
            return {...state, Requestupdated: action.payload.payload}
        case BaseRequestActionTypes.UPDATED_REQUEST_CONTEXT_ERROR:
            return {...state, RequestupdatedError: action.payload}
        case BaseRequestActionTypes.REQUEST_SET_CONTEXT:
            return {...state, RequestContext: action.payload}
        case BaseRequestActionTypes.GET_REQUEST_CONTEXT:
            return {...state, RequestContext: action.payload.payload}
        case BaseRequestActionTypes.REQUEST_SET_CREATE_CONTEXT_ERROR:
            return {...state, createRequestContextError: action.payload.payload}
        case BaseRequestActionTypes.CLEAR_REQUEST_CONTEXT:
            return {...state, createRequestContext: null,RequestContext: null,Requestupdated:null}   
        case BaseRequestActionTypes.BY_USER:
            return {...state, ViewByAssignedUser:action.payload}   
        case BaseRequestActionTypes.ONLY_COMPLETED:
            return {...state, onlycompleted:action.payload}
        case BaseRequestActionTypes.SET_REQUEST_LIST_CONTEXT:
            return {...state, requestListContext :action.payload}
        case BaseRequestActionTypes.GET_ESIGN:
            return {...state, eSign:action.payload.payload}  
        case BaseRequestActionTypes.CREATE_ESIGN:
            return {...state, createesign:action.payload}  
        case BaseRequestActionTypes.CLEAR_ESIGN:
            return {...state, createesign:null, eSign:null}  
        case BaseRequestActionTypes.GET_PATIENT_MODEL:
            return {...state, patientModel:action.payload.payload}  
        case BaseRequestActionTypes.GET_PATIENT_DEMOGRAPHIC:
            return {...state, patientModelDemographic:action.payload.payload}  
        case BaseRequestActionTypes.CLEAR_PATIENT_MODEL:
            return {...state, patientModel:null}  
        case BaseRequestActionTypes.CLEAR_PATIENT_DEMOGRAPHIC:
            return {...state, patientModelDemographic:null}  

            
        default:
            return state
    }

}

