import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    CREATE_SCHEDULE: "CREATE_SCHEDULE",
    GET_SCHEDULE: "GET_SCHEDULE",
    GET_SCHEDULE_BY_USER: "GET_SCHEDULE_BY_USER",
    UPDATE_SCHEDULE: "UPDATE_SCHEDULE",
    DELETE_SCHEDULE: "DELETE_SCHEDULE",
    GET_PATIENT_SCHEDULE: "GET_PATIENT_SCHEDULE",
    SET_SCHEDULER_FILTERS: "SET_SCHEDULER_FILTERS",
    SET_SCHEDULER_CONTEXT: "SET_SCHEDULER_CONTEXT"
}

function createSchedule(payload) {
    return WrapAuthentication('schedule/create',
        {payload},
        ActionTypes.CREATE_SCHEDULE
    )
}

function getSchedule(payload) {
    return WrapAuthentication('schedule/get/user/id',
        {payload},
        ActionTypes.GET_SCHEDULE
    )
}

function getScheduleAll(payload) {
    return WrapAuthentication('schedule/get/all',
        {payload},
        ActionTypes.GET_SCHEDULE_BY_USER
    )
}

function setSchedulerFilters(key, value) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_SCHEDULER_FILTERS, payload: {key: key, value: value}})
    }
}
function setSchedulerContext(dataItem) {
    return async dispatch => {
        dispatch({type: ActionTypes.SET_SCHEDULER_CONTEXT, payload: dataItem})
    }
}

function getAllParticipantByUserRoleID(payload) {
    return WrapAuthentication('schedule/get/participants/user/id',
        {payload},
        ActionTypes.GET_SCHEDULE_BY_USER)
}

function getAllSchedulesByUSerRoleAsParticipants(payload) {
    return WrapAuthentication('schedule/get/user/as/participant',
        {payload},
        ActionTypes.GET_SCHEDULE_BY_USER)
}

function getPatientSchedule(payload) {
    return WrapAuthentication('schedule/get/patient/id',
        {payload},
        ActionTypes.GET_PATIENT_SCHEDULE)
}

function updateSchedule(payload) {
    return WrapAuthentication('schedule/update',
        {payload},
        ActionTypes.UPDATE_SCHEDULE
    )
}

function deleteSchedule(payload) {
    return WrapAuthentication('schedule/delete',
        {payload},
        ActionTypes.DELETE_SCHEDULE
    )
}

export const ScheduleActions =
    {
        getSchedule: getSchedule,
        getScheduleAll: getScheduleAll,
        updateSchedule: updateSchedule,
        createSchedule: createSchedule,
        deleteSchedule: deleteSchedule,
        getPatientSchedule: getPatientSchedule,
        getAllParticipantByUserRoleID: getAllParticipantByUserRoleID,
        getAllSchedulesByUSerRoleAsParticipants: getAllSchedulesByUSerRoleAsParticipants,
        setSchedulerFilters: setSchedulerFilters,
        setSchedulerContext: setSchedulerContext,
    }