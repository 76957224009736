import {IconButton, IconButtonProps, ToggleButtonProps, TooltipProps} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import MedicationIcon from '@mui/icons-material/Medication';
import CheckIcon from "@mui/icons-material/Check";
import ToggleButton from "@mui/material/ToggleButton";
import {Colors} from "../../../config/GlobalConfig";

export interface ButtonProps extends IconButtonProps {
    tooltip?: string,
    tooltipProps?: TooltipProps
}


export function AddButton(props: ButtonProps) {
    return (
        <Tooltip title={props.tooltip}>
            <IconButton {...props}>
                <AddCircleIcon/>
            </IconButton>
        </Tooltip>
    )
}

export function RxButton(props: ButtonProps) {
    return (
        <Tooltip title={props.tooltip}>
            <IconButton {...props}>
                <MedicationIcon/>
            </IconButton>
        </Tooltip>
    )
}

export interface ActiveToggleButtonProps extends ToggleButtonProps {
    onChange: () => null,
    selected: boolean,
    tooltip: string
}

export function ActiveToggleButton(props: ActiveToggleButtonProps) {


    return (
        <Tooltip title={props.tooltip}>
            <ToggleButton
                {...props}
                value="check"
                selected={props.selected}
                onChange={props.onChange}


            >
                <CheckIcon style={{color: props.selected ? Colors.GREEN : Colors.RED}}/>
            </ToggleButton>
        </Tooltip>
    )
}