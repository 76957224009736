import {createBrowserHistory} from "history";
import thunk from "redux-thunk";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {authReducer} from "./AuthStore";
import {notificationReducer} from "./NotificationStore";
import {patientReducer} from "./PatientStore";
import {scheduleReducer} from "./SchedulerStore";
import {userReducer} from "./UserStore";
import {dictionaryReducer} from "./DictionaryStore";
import {dictionaryTypeReducer} from "./DictionaryTypeStore";
import {expandableWindowReducer} from "./ExpandableWindowStore";
import {popupWindowReducer} from "./PopupWindowStore";
import {personReducer} from "./PersonStore";
import {messageReducer} from "./MessageStore";
import {interfaceReducer} from "./InterfaceStore";
import {debugReducer} from "./DebugStore";
import {facilityReducer} from "./FacilityStore";
import {windowReducer} from "./WindowStore";
import {loadingReducer} from "./LoadingStore";
import {permissionReducer} from "./PermissionStore";
import {BaseRequestReducer} from "./BaseRequestStore";
import {QualityMeasureReducer} from "./QualityMeasureStore";
import {configurationReducer} from "./ConfigurationStore";
import {ComponentUpdateReducer} from "./ComponentUpdateStore";
import {formReducer} from "./FormStore";
import {encounterReducer} from "./EncounterStore";
import {observationThresholdReducer} from "./ObservationThresholdStore";
import {insuranceReducer} from "./InsuranceStore";
import {laboratoryReducer} from "./LaboratoryStore";
import {securitySettingsReducer} from "./SecuritySettingsStore";
import {layoutReducer} from "./LayoutStore";

export const history = createBrowserHistory();

const middleware = [thunk, routerMiddleware(history)];
const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            name: 'MyApp', actionsBlacklist: ['REDUX_STORAGE_SAVE']
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(...middleware),
    // other store enhancers if any
);

const appReducer = combineReducers({
    auth: authReducer,
    notification: notificationReducer,
    schedule: scheduleReducer,
    patient: patientReducer,
    permission: permissionReducer,
    person: personReducer,
    user: userReducer,
    dictionary: dictionaryReducer,
    dictionaryType: dictionaryTypeReducer,
    expandedWindow: expandableWindowReducer,
    popupWindow: popupWindowReducer,
    messages: messageReducer,
    interface: interfaceReducer,
    router: connectRouter(history),
    debug: debugReducer,
    facility: facilityReducer,
    windowState: windowReducer,
    loading: loadingReducer,
    BaseRequest: BaseRequestReducer,
    QualityMeasure: QualityMeasureReducer,
    Configuration: configurationReducer,
    ComponentUpdate: ComponentUpdateReducer,
    form: formReducer,
    encounters: encounterReducer,
    observationThreshold: observationThresholdReducer,
    insurance: insuranceReducer,
    laboratory: laboratoryReducer,
    securitySettings: securitySettingsReducer,
    layout: layoutReducer,

})

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('userRoleId')
        const {router, Configuration} = state

        state = {router, Configuration}
    }
    return appReducer(state, action)
}


export const basicReduxStore = createStore(
    rootReducer,
    enhancer,
);
