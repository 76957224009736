import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {useForm} from "react-hook-form";
import {Field, FieldDropDown, FieldPermissionGroupDropDown, FieldRow} from "./InputControls/FormFields";
import {Col, Container} from "../layout/GridLayout";
import {PermissionAction} from "../action/PermissionAction";
import {LayoutAction} from "../action/LayoutAction";
import {Button} from "primereact/button";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {filterBy, orderBy} from '@progress/kendo-data-query';

const PermissionGroupConfigurationComponent = (props) => {

    const {
        register,
        handleSubmit,
        reset,
        control,
        getValues,
        setValue,
        watch,
        formState: {errors, isDirty, isSubmitting, touchedFields, submitCount},
    } = useForm({defaultValues: {active: true}});

    const [filter, setFilter] = React.useState({logic: "and", filters: []})
    const [dataState, setDataState] = React.useState({
        take: 5,
        skip: 0,
    });

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const initialSort = [
        {
            field: "displayName",
            dir: "desc",
        },
    ];
    const [sort, setSort] = React.useState(initialSort);
    const buildFilters = () => {
        let filters = {}
        if (filter?.filters) {
            if (filter.filters.length > 0) {
                filter.filters.forEach(x => {
                    filters[x.field] = x.value
                })
            }

        }
        return filters;
    }

    React.useEffect(() => {
        props.LayoutActions.getLayout();
        props.LayoutActions.getSavedPageLayout(dataState.take, dataState.skip, sort, buildFilters());
    }, []);

    React.useEffect(() => {
        props.LayoutActions.getSavedPageLayout(dataState.take, dataState.skip, sort, buildFilters());
    }, [dataState]);

    const savePermissions = (form) => {
        if ((form?.permissionGroup?.id || form?.permissionGroup) && form?.defaultWorkspace) {
            const workspaceId = props?.layout?.find(v => v.workspaceLayoutDisplayName === form?.defaultWorkspace);
            props.LayoutActions.saveLayout(form?.permissionGroup?.id || form?.permissionGroup, workspaceId?.workspaceLayoutId).then(() => {
                props.LayoutActions.getSavedPageLayout(dataState.take, dataState.skip, sort, buildFilters());
            })
        }
    }

    return <Container style={{paddingLeft: 20}}>
        <FieldRow>
            <Field label={"Permission Group"} field={"permissionGroup"}
                   error={errors} register={register} control={control}
                   options={{required: "Permission group is required."}}>
                <FieldPermissionGroupDropDown/>
            </Field>
            <Field label={"Default Workspace"} field={"defaultWorkspace"} error={errors} register={register}
                   control={control}>
                <FieldDropDown data={props?.layout?.map(v => v?.workspaceLayoutDisplayName)}/>
            </Field>
            <Field label={" "} field={""} error={errors} register={register}>
                <span>Sets the default workspace configuration for the permission group</span>
            </Field>
            <Field label={" "} field={""} error={errors} register={register}>
                <Button text
                        onClick={handleSubmit(savePermissions)}> Save
                </Button>
            </Field>
        </FieldRow>
        <Col>
            <Grid
                style={{height: "100%"}}
                data={props.layoutList && filterBy(orderBy(props.layoutList, sort), filter)}
                pageSize={dataState.limit}
                sortable={true}
                skip={dataState.skip}
                take={dataState.take}
                total={props?.totalCount}
                filter={filter}
                onFilterChange={(e) => setFilter(e.filter)}
                onDataStateChange={dataStateChange}
                pageable={{
                    pageSizes: [5, 15, 20],
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    previousNext: true,
                }}>
                <GridColumn field="displayName" title="Permission Group"/>
                <GridColumn field="workspaceLayoutDisplayName" title="Default Workspace"/>
            </Grid>
        </Col>
    </Container>;
};


function mapStateToProps(state) {
    return {
        permissionGroup: state.permission.permissionGroups,
        layout: state.layout.layoutContext,
        layoutList: state.layout.layoutList,
        totalCount: state.layout.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PermissionAction: bindActionCreators(PermissionAction, dispatch),
        LayoutActions: bindActionCreators(LayoutAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionGroupConfigurationComponent)
