import {ActionTypes} from "../action/UserAction";


const initialState = {
    userList: [],
    allUsers: [],
    userContext: null,
    createUserContext: null,
    updated: false,
    userLicenseContext: [],
    loggedinUser: {},
    sleepQualityPie: [],
    sleepQualityPieContext: {},
    activityLevelPie: [],
    userTasks: {taskCount: 0},
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.USER_LIST_SUCCESS: {
            const userListWithDisplayName = action.payload.payload.map(item => ({
                ...item,
                displayName: `${item.firstName} ${item.middleName || ""} ${item.lastName}`,
                fullName: `${item.firstName} ${item.middleName || ""} ${item.lastName}`
            }));
            return {...state, userList: userListWithDisplayName, totalCount: action.payload.totalCount};
        }
        case ActionTypes.ALL_USERS: {
            const userListWithDisplayName = action.payload.payload.map(item => ({
                ...item,
                displayName: `${item.firstName} ${item.middleName || ""} ${item.lastName}`,
                fullName: `${item.firstName} ${item.middleName || ""} ${item.lastName}`
            }));
            return {...state, allUsers: userListWithDisplayName};
        }
        case ActionTypes.USER_LIST_ERROR:
            return {...state, userListError: action.payload};
        case ActionTypes.LOGGEDIN_USER_SUCCESS:
            return {...state, loggedinUser: action.payload.payload};
        case ActionTypes.UPDATE_USER_IN_LIST: {

            let user = action.payload.payload
            let userList = state.userList.map(d => {
                return (d.userRoleId === user.userRoleId) ? user : d
            })
            return {...state, userList}

        }
        case ActionTypes.ADD_USER_LIST: {
            return {
                ...state,
                userList: [...state.userList, action.payload.payload],
                userContext: action.payload.payload
            }

        }
        // case ActionTypes.USEER_SET_CREATE_USER_CONTEXT:
        //     return {...state, createUserContext: action.payload.payload}
        case ActionTypes.USER_UPDATE:
            return {...state, updated: action.payload.payload}
        case ActionTypes.CLEAR_UPDATE:
            return {...state, updated: false}
        case ActionTypes.SET_USER_CONTEXT:
            return {...state, userContext: action.payload?.payload ? action.payload.payload : action.payload}
        case ActionTypes.RESET_SECRET:
            return {...state, userContext: {...state.userContext, mfaStatus: false}}//: action.payload?.payload ? action.payload.payload : action.payload}
        case ActionTypes.USER_GET_LICENSES:
            return {...state, userLicenseContext: action.payload.payload}
        case ActionTypes.USER_CLEAR_LICENSES:
            return {...state, userLicenseContext: []}
        case ActionTypes.CLEAR_USER_CONTEXT:
            return {...state, userContext: null}
        case ActionTypes.SLEEP_QUALITY_PIE:
            return {...state, sleepQualityPie: action.payload.payload}
        case ActionTypes.ACTIVITY_LEVEL:
            return {...state, activityLevelPie: action.payload.payload}
        case ActionTypes.PIE_GRAPH_SET_CONTEXT:
            return {...state, sleepQualityPieContext: action.payload.payload}
        //TODO: this needs to be one call I think. Only one pie window is open at a time.
        case ActionTypes.PIE_GRAPH_CLEAR_CONTEXT:
            return {...state, sleepQualityPieContext: {}}
        case ActionTypes.WEIGHT_MANAGEMENT:
            return {...state, weightManagement: action.payload}
        case ActionTypes.GET_USERS_INFO:
            return {...state, getUsersInfo: action.payload.payload}
        case ActionTypes.CREATE_NEW_USER:
            return {...state, createNewUser: action.payload}
        case ActionTypes.GET_SAML:
            return {...state, getSAML: action.payload.payload}
        case ActionTypes.GET_PATIENTS_LIST:
            return {...state, getPatientsList: action.payload.payload}
        case ActionTypes.GET_EPRESCRIBE_USERS_AND_PATIENTS:
            return {...state, getEprescribeUsersAndPatients: action.payload.payload}
        case ActionTypes.GET_SAML_VALUE_PATIENT_CONTEXT:
            return {...state, getSAMLValuePatientContext: action.payload.payload}
        case ActionTypes.GET_VERADIGM_USER_TASKS:
            return {...state, userTasks: action.payload.payload}
        case ActionTypes.GET_USER_BY_ID:
            return {...state, providerUser: action.payload.payload}
        case ActionTypes.CLEAR_PROVIDER_USER:
            return {...state, providerUser: {}}
        case ActionTypes.SIGNATURE_DOWNLOAD:
            return {...state, signature: action.payload.payload}
        case ActionTypes.SIGNATURE_DOWNLOAD_CLEAR:
            return {...state, signature: null}

        default:
            return state
    }

}

