import {ColumnLayout, GridLayout} from "./GridLayout";

export const TileBody = (props) => {

    const containerStyle = {
        width: "100%",
        height: "100%",
        padding: 0,
        margin: 0

    }
    const rowStyle = {
        width: "100%",
        backgroundColor: '#EEEEEE',
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        padding: 0,
        margin: 0,
        border: '4px solid rgb(221, 221, 221)'
    }

    return (
        <ColumnLayout col={12} style={{...rowStyle, ...props.style}}>
            <GridLayout style={{...containerStyle, ...props.containerStyle}} {...props}>
                {props.children}
            </GridLayout>
        </ColumnLayout>
    )
}
