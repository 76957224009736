import {DrawerContent, TileLayout} from "@progress/kendo-react-layout";
import FacilitiesListComponent from "../FacilitiesListComponent";
import React from "react";

const userListTile = [
    {
        defaultPosition: {
            col: 1,
            colSpan: 4,
            rowSpan: 6,
        },
        body: <FacilitiesListComponent/>,
        header: "Manage Facilities",
        className: "user-list-header"
    }
];

function AdminFacilityPage(props) {
    return <>
        <DrawerContent><TileLayout style={{
            width: "96%",
            height: "calc(100vh - 90px)"
        }} ignoreDrag={(e) => {
            return !(e.target.classList.contains("k-card-title"));
        }} className={"main-screen user-header-component"} items={userListTile}/></DrawerContent>
    </>
}


export default AdminFacilityPage