import {Checkbox, Input} from "@progress/kendo-react-inputs";
import * as React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect, useSelector} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {Button} from "primereact/button";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {filterBy} from "@progress/kendo-data-query";
import {DictionaryTypeAction} from "../../action/DictionaryTypeAction";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {PermissionAction} from "../../action/PermissionAction";
import moment from "moment";
import {Grid, GridColumn} from "@progress/kendo-react-grid";

import {v4 as uuidv4} from "uuid";
import {Field, FieldDictionaryDropDown, FieldDropDown, FieldRow, FieldSection} from "../InputControls/FormFields";
import {useForm} from "react-hook-form";
import {Dialog} from "primereact/dialog";
import {Col, Container} from "../../layout/GridLayout";

const EditCommandDialog = (props) => {
    const {
        handleSubmit,
        register,
        control,
        reset,
        setValue,
        watch,
        getValues,
        formState: {errors},
    } = useForm({defaultValues: {description: ""}});
    const dictionaryTypes = useSelector(state => state.dictionaryType.dictionaryTypeList)
    const [dataItem, setDataItem] = React.useState(props?.dataItem);
    const [data, setData] = React.useState([]);
    const [dictionaryEntryCode, setDictionaryEntryCode] = React.useState(null)

    React.useEffect(() => {
        setValue("dictionaryEntry", dataItem?.toId)
    }, [dictionaryEntryCode])

    React.useEffect(() => {
        setData(dictionaryTypes || [])
    }, [dictionaryTypes])

    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'relatedDictionary') {
                let v = value?.relatedDictionary?.code;
                if (v) {
                    setDictionaryEntryCode(v);
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    React.useEffect(() => {
        const relatedDict = data.find(v => v.id === dataItem?.toDictionTypeId);
        setValue("relatedDictionary", relatedDict)
        setDictionaryEntryCode(relatedDict?.code)
        setValue("relationshipType", dataItem?.dictRelationshipId)
    }, [props])

    const handleCloseDialog = () => {
        reset();
        setDataItem(null)
        setData([])
        props.cancelEdit();
    }


    const FormRows = () => {
        return <FieldSection>
            <FieldRow>
                <Field label={"Related Dictionary"} field={"relatedDictionary"}
                       value="Related Dictionary"
                       error={errors}
                       control={control}
                       options={{required: "Related Dictionary is required."}}
                       register={register}>
                    <FieldDropDown
                        textField={"displayName"}
                        dataItemKey={"id"}
                        data={data}/>
                </Field>
            </FieldRow>
            <FieldRow>
                <Field label={"Dictionary Entry"} field={"dictionaryEntry"} error={errors}
                       control={control}
                       options={{required: "Dictionary Entry is required"}}
                       register={register}>
                    <FieldDictionaryDropDown
                        code={dictionaryEntryCode}
                    />
                </Field>
            </FieldRow>
            <FieldRow>
                <Field label={"Relationship Type"} field={"relationshipType"} error={errors}
                       control={control}
                       options={{required: "Relationship type is required"}}
                       register={register}>
                    <FieldDictionaryDropDown
                        code={"AH_DICT_RELATIONSHIP"}
                    />
                </Field>
            </FieldRow>
        </FieldSection>

    }

    const saveForm = () => {
        const form = getValues()
        props.onSubmit({...form, id: dataItem?.id || uuidv4()});
        handleCloseDialog();
    }

    return <Dialog visible={props.visible} header={!dataItem || dataItem?.newItem ? "Add" : "Edit"}
                   style={{width: 400}}
                   onHide={handleCloseDialog}>
        <FormRows/>
        <Container gutter className="flex-row-reverse">
            <Col className={"flex-grow-0"}>
                <Button
                    size={"small"}
                    type={"submit"}
                    onClick={saveForm}
                    label={!dataItem || dataItem?.newItem ? "Save" : "Update"}
                />
            </Col>
            <Col className={"flex-grow-0"}>
                <Button size={"small"}
                        onClick={handleCloseDialog}
                        label={"Cancel"}/>
            </Col>
        </Container>
    </Dialog>;
};

const RelationshipTypes = (props) => {
    const editField = "inEdit";
    const [dictionaryRelationshipTypeData, setDictionaryRelationshipTypeData] = React.useState(props?.relationshipData)
    const [openForm, setOpenForm] = React.useState(false);
    const [editItem, setEditItem] = React.useState(null);
    const [data, setData] = React.useState([]);
    const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);
    };

    React.useEffect(() => {
        setDictionaryRelationshipTypeData(props?.relationshipData);
    }, [props?.relationshipData]);

    const handleSubmit = (event) => {
        const payload = {
            fromId: props?.dictCodeContext?.id,
            toId: event?.dictionaryEntry?.id,
            relationshipId: event?.relationshipType?.id
        }
        props.DictionaryActions.addRelationshipToDictionary(payload).then(() => {
            props.DictionaryActions.getRelationshipToDictionary({fromId: props?.dictCodeContext?.id}).then(() => setOpenForm(false))
        })
    };

    const deleteRelationship = (di) => {
        const payload = {
            fromId: props?.dictCodeContext?.id,
            toId: di?.toId || di?.dictionaryEntry?.id,
            relationshipId: di?.dictRelationshipId || di?.relationshipType?.id
        }
        if (window.confirm("Are you sure you want to delete this relationship? This can affect downstream analytics.")) {
            props.DictionaryActions.deleteRelationshipToDictionary(payload).then(() => {
                props.DictionaryActions.getRelationshipToDictionary({fromId: props?.dictCodeContext?.id}).then(() => setOpenForm(false))
            })
        }
    }

    const handleCancelEdit = () => {
        setOpenForm(false);
        setEditItem(null)
    };
    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} enterEdit={enterEdit}/>
    );
    const addNew = () => {
        setOpenForm(true);
    };

    const EditCommandCell = (props) => {
        return (
            <td>
                {
                    props.newItem ? <Button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={() => props.cancelEdit()}
                    >
                        Cancel
                    </Button> : <Button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={() => deleteRelationship(props.dataItem)}
                    >
                        Delete
                    </Button>
                }
            </td>
        );
    };

    return <div style={{marginTop: "2%"}}>
        <Grid
            data={dictionaryRelationshipTypeData}
        >
            <GridColumn field={"toDictionTypeDisplayName"} title="Related Dictionary"/>
            <GridColumn field={"toDisplayName"} title="Dictionary Entry"/>
            <GridColumn field={"relationshipDisplayName"} title="Relationship Type"/>
            <GridColumn width={200} field="Action"
                        title={<Button onClick={addNew} text icon={"pi pi-plus-circle"} size={"small"}
                                       tooltip={"Add relationship"}/>}
                        cell={MyEditCommandCell}/>
        </Grid>

        <EditCommandDialog
            visible={openForm}
            data={props?.dictionaryTypeData}
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            dataItem={editItem}
        />

    </div>;
}

const AddDictionaryWindow = (props) => {
    const {DictionaryTypeActions, dictionaries, DictionaryActions, dictionaryTypes} = props
    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });
    const [fields, setFields] = React.useState({})
    const setValue = (field, value) => {
        setFields({...fields, [field]: value})
    }
    const [dictionaryTypeData, setDictionaryTypeData] = React.useState([])
    const [relationshipData, setRelationshipData] = React.useState([])
    const [errors, setErrors] = React.useState({});
    const [isFormInEdit, setIsFormInEdit] = React.useState(true);
    const [hasRelationships, setHasRelationships] = React.useState(false);
    const [typeState, setTypeState] = React.useState(
        //props.showWindow[PopupWindows.ADD_CODE_WINDOW].selectedDictionaryType
        {
            selectedDictionaryType: {
                code: "Select Type",
                id: null,
            }
        }
    );
    const [dictCodeContext, setDictCodeContext] = React.useState(props.popupState[PopupWindows.ADD_CODE_WINDOW]?.dictCodeContext)
    const filterData = (filter) => {
        return filterBy(dictionaryTypes.slice(), filter);
    };
    const filterChange = (event) => {
        setDictionaryTypeData(filterData(event.filter));
    };

    React.useEffect(() => {
        setDictionaryTypeData(dictionaryTypes)
    }, [dictionaryTypes])

    React.useEffect(() => {
        if (props.relationshipContext) {
            setRelationshipData(props.relationshipContext.map(v => {
                return {...v, id: uuidv4(), newItem: false, inEdit: false}
            }));
        }
    }, [props?.relationshipContext])

    const handleTypeChange = (event) => {
        setTypeState({
            selectedDictionaryType: event.target.value,
        });
        setValue('dictionaryTypeId', event.target.value)
    };
    const reset = () => {
        setFields({})
        setErrors({})
        setDictCodeContext(null)

    }
    const hideDialog = () => {
        reset()
        setHasRelationships(false);
        setIsFormInEdit(true);
        props.PermissionAction.getAllPermissionGroups();
        let parentSelectedDictionaryType = props.popupState[PopupWindows.ADD_CODE_WINDOW]?.parentSelectedDictionaryType?.id;
        DictionaryActions.getDictionaryList(parentSelectedDictionaryType, dataState.take, dataState.skip)
        props.PopupActions.hideWindow(PopupWindows.ADD_CODE_WINDOW);
        props.PopupActions.clearWindowState(PopupWindows.ADD_CODE_WINDOW);
    };
    const handleSubmit = () => {
        saveDictionaryCode(fields)
    }

    React.useEffect(() => {
        if (props.popupState[PopupWindows.ADD_CODE_WINDOW]?.dictCodeContext) {
            let dictValue = props.popupState[PopupWindows.ADD_CODE_WINDOW]?.dictCodeContext
            let parentSelectedDictionaryType = props.popupState[PopupWindows.ADD_CODE_WINDOW]?.parentSelectedDictionaryType?.id;
            let seldictionaryTypeId = dictionaryTypeData.find(x => x.id === dictValue.dictTypeId)
            let newFields = {}
            if (dictValue?.id) newFields.id = dictValue.id
            if (dictValue?.code) newFields.code = dictValue.code
            if (dictValue?.codeType) newFields.codeType = dictValue.codeType
            if (dictValue?.description) newFields.description = dictValue.description
            if (dictValue?.displayName) newFields.displayName = dictValue.displayName
            if (dictValue?.active) newFields.active = dictValue.active
            if (dictValue?.dictTypeId) newFields.dictionaryTypeId = seldictionaryTypeId
            setDictCodeContext(dictValue ? dictValue : {})
            setIsFormInEdit(newFields && newFields?.dictionaryTypeId && newFields?.dictionaryTypeId?.code === "AH_PERMISSION_GROUP")
            setFields(newFields)
            DictionaryActions.getRelationshipToDictionary({fromId: dictValue?.id})
        }
    }, [props.popupState])

    const saveDictionaryCode = (form) => {
        if (form.id) {
            let parentSelectedDictionaryType = props.popupState[PopupWindows.ADD_CODE_WINDOW]?.parentSelectedDictionaryType?.id;
            //let displayName = dictCodeContext.displayName;
            let savePayload = {
                id: form.id,
                description: form?.description,
                dictionaryTypeId: parentSelectedDictionaryType,
                active: form?.active,
                code: form?.code,
                displayName: form?.displayName,
                inactivateDate: (form.active) ? null : moment(new Date()).local().format("YYYY-MM-DD")
            };
            DictionaryTypeActions.updateDictionaryCode(savePayload)
                .then(() => {
                    hideDialog()
                    DictionaryActions.getDictionaryList(parentSelectedDictionaryType, dataState.take, dataState.skip)
                })
        } else {
            let savePayload = {
                code: form?.code,
                description: form?.description,
                displayName: form?.displayName,
                dictionaryTypeId: form?.dictionaryTypeId?.id,
                active: form?.active
            };

            DictionaryTypeActions.createDictionaryCode(savePayload)
                .then(() => {
                    hideDialog()
                    DictionaryActions.getDictionaryList(savePayload.dictionaryTypeId, dataState.take, dataState.skip)
                })
        }
    }

    React.useEffect(() => {
        if (props.popupState[PopupWindows.ADD_CODE_WINDOW]?.selectedDictionaryType) {
            //setFields({ ...fields, ['dictionaryTypeId']: props.popupState[PopupWindows.ADD_CODE_WINDOW]?.selectedDictionaryType })
            //setFields({...fields, ['active']: true})
            let newFields = {}
            newFields.dictionaryTypeId = props.popupState[PopupWindows.ADD_CODE_WINDOW]?.selectedDictionaryType;
            newFields.active = true
            setFields(newFields)
        }
    }, [props.showWindow.ADD_CODE_WINDOW])

    const handleDescription = (e) => {
        setFields({...fields, description: e.target.value, displayName: e.target.value});
    }

    return (
        <Dialog
            visible={props.showWindow[PopupWindows.ADD_CODE_WINDOW]}
            header={isFormInEdit ? "Create Code" : "Add Code"}
            onHide={hideDialog}>
            {fields.id &&
                <div className={"row"} style={{width: "100%"}}>
                    <div className="k-form-field">
                        <Checkbox value={hasRelationships} onChange={(e) => setHasRelationships(e.value)}
                                  label={"Has Relationship to Another Dictionary?"}/>
                        {hasRelationships === true &&
                            <RelationshipTypes dictionaries={props.dictionaries} dictCodeContext={dictCodeContext}
                                               DictionaryActions={DictionaryActions}
                                               dictionaryTypeData={dictionaryTypeData}
                                               relationshipData={relationshipData}/>}
                    </div>
                </div>
            }
            <div className={"row"} style={{width: "100%"}}>
                <div className="k-form-field"> Type
                    <div>
                        <DropDownList
                            filterable={true}
                            onFilterChange={filterChange}
                            dataItemKey="id"
                            textField="displayName"
                            style={{width: 400}}
                            //value={typeState.selectedDictionaryType}
                            value={fields?.dictionaryTypeId}
                            onChange={handleTypeChange}
                            data={dictionaryTypeData}
                            disabled={dictCodeContext?.id ? true : false}
                            defaultValue="Select Dictionary Type"/>
                    </div>
                </div>
            </div>
            <div className={"row"} style={{width: "100%"}}>
                <div className="k-form-field"> Code
                    <Input
                        name="code"
                        placeholder=""
                        value={fields?.code}
                        onChange={(e) => setValue('code', e.target.value)}
                        disabled={dictCodeContext?.id ? true : false}
                    />
                </div>
            </div>
            <div className={"row"} style={{width: "100%"}}>
                <div className="k-form-field"> Description
                    <Input
                        name="code"
                        placeholder=""
                        value={fields?.description}
                        //onChange={(e) => setValue('description', e.target.value)}
                        onChange={(e) => handleDescription(e)}
                    />
                </div>
            </div>
            <div className={"row"} style={{width: "100%"}}>
                <div className="k-form-field"> Display Name
                    <Input
                        name="displayName"
                        placeholder=""
                        value={fields?.displayName}
                        onChange={(e) => setValue('displayName', e.target.value)}
                    />
                </div>
            </div>
            <div className={"row"} style={{width: "100%"}}>
                <div className="k-form-field">
                    <Checkbox
                        label={"Active"}
                        onChange={(e) => setValue('active', e.target.value)}
                        value={fields?.active}
                        //defaultChecked={dictCodeContext ? dictCodeContext.active : true}
                    />
                </div>
            </div>

            <Container gutter className="flex-row-reverse">
                <Col className={"flex-grow-0"}>
                    <Button primary onClick={handleSubmit}
                            label={dictCodeContext?.id ? "Update" : "Save"}
                            size={"small"}/>
                </Col>
                <Col className={"flex-grow-0"}>
                    <Button onClick={hideDialog}
                            size={"small"}
                            label={"Cancel"}/>

                </Col>
            </Container>
        </Dialog>
    );

};

AddDictionaryWindow.propTypes = {
    showWindow: PropTypes.object,
    PopupActions: PropTypes.object,
    DictionaryActions: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        popupState: state.popupWindow.state,
        dictionaryTypes: state.dictionaryType.dictionaryTypeList,
        relationshipContext: state.dictionary.relationshipContext,
        dictionaries: state.dictionary.dictionaryList,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        PermissionAction: bindActionCreators(PermissionAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDictionaryWindow);
