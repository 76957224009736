import * as React from "react";
import {connect} from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {ListBox} from "@progress/kendo-react-listbox";
import {bindActionCreators} from "redux";
import {PatientAction} from "../../../action/PatientAction";
import {PopupWindowActions, PopupWindows} from "../../../action/PopupWindowAction";
import {DictionaryDispatchAction} from "../../../action/DictionaryDispatchAction";
import {Input} from "@progress/kendo-react-inputs";
import {orderBy} from "@progress/kendo-data-query";
import {Button} from "primereact/button";
import {Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {CarePlanActions} from "../../../action/CarePlanAction";
import {Col, Container, Row} from "../../../layout/GridLayout";
import ShowForPermissionComponent from "../../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../../config/GlobalConfig";
import DictionaryDropDownList from "../../InputControls/DictionaryDropDownList";
import {InlineDictionaryDropDown, InlineTextInput} from "../../InputControls/InlineFields";

const SELECTED_FIELD = "selected";


const GoalStatusDropdown = (props) =>
    <InlineDictionaryDropDown
        {...props}
        dataItem={props.dataItem}
        idField={"dictGoalStatusId"}
        field={"dictGoalStatusId"}
        defaultItemCode={"AH_GOAL_STATUS_IN_PROGRESS"}
        displayField={"dictGoalStatusDisplayName"}
        code={"AH_CARE_PLAN_GOAL_STATUS"}/>

const GoalCategoryDropdown = (props) =>
    <InlineDictionaryDropDown
        {...props}
        dataItem={props.dataItem}
        idField={"dictGoalCategoryId"}
        field={"dictGoalCategoryId"}
        displayField={"dictGoalCategoryDisplayName"}
        code={"AH_CARE_PLAN_GOAL_CATEGORY"}/>

const StrategiesDropdown = (props) =>
    <InlineDictionaryDropDown
        {...props}
        dataItem={props.dataItem}
        idField={"dictGoalStrategyId"}
        field={"dictGoalStrategyId"}
        displayField={"dictGoalStrategyDisplayName"}
        code={"AH_CARE_PLAN_STRATEGIES"}/>

const EditCommandCell = (props) => {
    const {dataItem} = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem?.newItem || false;
    return inEdit ? (
        <td className="k-command-cell">
            <Container>
                <Col className={"flex-grow-0"}>
                    <Button
                        icon={"pi pi-save"}
                        size={"small"}
                        severity={"success"}
                        text
                        onClick={() => isNewItem ? props.add(dataItem) : props.update(dataItem)}/>
                </Col>
                {
                    !isNewItem &&
                    <Col className={"flex-grow-0"}>
                        <Button
                            size={"small"}
                            text
                            severity={"danger"}
                            icon={"pi pi-ban"}
                            onClick={() =>
                                props.remove(dataItem)
                            }/>
                    </Col>
                }
            </Container>
        </td>
    ) : (
        <td className="k-command-cell">

        </td>
    );
};
const CarePlanTabComponent = (props) => {

    const [dataState, setDataState] = React.useState({
        take: 5, skip: 0,
    });

    const [searchState, setSearchState] = React.useState("")


    const [problems, setProblems] = React.useState([])


    const [filterState, setFilterState] = React.useState(false)

    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };

    const initialSort = [{
        field: "displayName", dir: "asc",
    },];

    const [sort, setCarePlanSort] = React.useState(initialSort);

    const expandChange = (event) => {
        let newData = problems.map((item) => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setProblems(newData);
    };


    React.useEffect(() => {
        props.CarePlanActions.getCarePlanForPatientContext(dataState.take, dataState.skip, sort)
    }, [props.patientContext])

    React.useEffect(() => {
        if (props.carePlan?.medicalProblems) {
            setProblems(
                props.carePlan.medicalProblems.map(v => {
                    const existingProblem = problems && problems.find(f => f.id === v.id);
                    if (existingProblem) {
                        if (existingProblem.inEdit) {
                            v.inEdit = true;
                        } else {
                            v.inEdit = false;
                        }
                        if (existingProblem.expanded) {
                            v.expanded = true;
                        } else {
                            v.expanded = false;
                        }
                    }
                    return v;
                })
            );
        }
    }, [props.carePlan])

    const filterData = (d) => {
        if (searchState.length > 0) {
            return problems.filter(d => d.displayName.toLowerCase().includes(searchState.toLowerCase()) || d.dictCategoryDisplayName.toLowerCase().includes(searchState.toLowerCase()) || d.description.toLowerCase().includes(searchState.toLowerCase()) || d.dictStatusDisplayName.toLowerCase().includes(searchState.toLowerCase()))
        } else {
            return d
        }
    }

    const enterEdit = (dataItem) => {
        let newData = problems.map((item) =>
            item.id === dataItem.id ? {
                ...item,
                inEdit: !dataItem.inEdit,
                expanded: dataItem.inEdit ? false : true
            } : {...item, inEdit: false, expanded: false}
        );
        setProblems(newData);
    };
    const editRow = (p) => {
        return <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_CAREPLAN]}>
            <Button size="small" style={{marginTop: "50%", marginBottom: "50%"}}
                    text
                    severity={p.dataItem.inEdit ? "danger" : false}
                    icon={p.dataItem.inEdit ? "pi pi-ban" : "pi pi-pencil"}
                    onClick={() => enterEdit(p.dataItem)}/>

        </ShowForPermissionComponent>
    }

    const ProblemDisplay = (p) => {
        return <td> {
            p.dataItem.diagnosisCodes?.map(v=> v.dictDiagnosisDisplayName).join("; ")
        }
        </td>
    }

    const DetailComponent = (prop) => {

        const {diagnosisCodes, determinants, goals} = prop.dataItem

        const [determinant, setDeterminant] = React.useState(null)
        const [goalData, setGoalData] = React.useState(goals.map(v => {
            if (prop.dataItem.inEdit) {
                v.inEdit = true;
            } else {
                v.inEdit = false;
            }
            if (prop.dataItem.expanded) {
                v.expanded = true;
            } else {
                v.expanded = false;
            }
            v.newItem = false;
            return v;
        }));

        const DiagnosisItem = (p) => {
            let {dataItem, selected, ...others} = p;
            return (<li {...others}>
                <div>
                    <span>Code: {dataItem.dictDiagnosisCode}</span>
                </div>
            </li>);
        };

        const CustomDeterminant = (cp) => {
            const {dataItem, selected, ...others} = cp;
            return (
                <li {...others} style={{position: "inherit"}}>
                    {
                        prop.dataItem.inEdit ?
                            <Row sm={2}>
                                <Col>
                                    <span>{dataItem.dictDeterminantDisplayName}</span>
                                </Col>
                                <Col col={2}>
                                    <span class="k-icon k-i-delete"
                                          onClick={() => removeDeterminant(dataItem.id)}></span>
                                </Col>
                            </Row> : <span>{dataItem.dictDeterminantDisplayName}</span>
                    }
                </li>
            );
        };
        const expandGoalChange = (event) => {
            let newData = goalData.map((item) => {
                if (item.id === event.dataItem.id) {
                    item.expanded = !event.dataItem.expanded;
                }
                return item;
            });
            setGoalData(newData);
        };


        const addNewDeterminant = () => {
            props.CarePlanActions.addDeterminantForProblemEdit(prop.dataItem.id, determinant.id).then(() => {
                props.CarePlanActions.getCarePlanForPatientContext(dataState.take, dataState.skip, sort);
            });
        };

        const removeDeterminant = (id) => {
            props.CarePlanActions.removeDeterminantForProblemEdit(id).then(() => {
                props.CarePlanActions.getCarePlanForPatientContext(dataState.take, dataState.skip, sort);
            });
        }

        const determinantsOnChange = (e) => {
            setDeterminant(e.value);
        }

        const GoalDetailComponent = (p) => {
            const {dataItem} = p
            const [strategies, setStrategies] = React.useState(dataItem.strategies.map(v => {
                if (prop.dataItem.inEdit) {
                    v.inEdit = true;
                } else {
                    v.inEdit = false;
                }
                return v;
            }))
            const addNewStrategyToGoal = () => {
                props.PopupActions.setWindowState(PopupWindows.ADD_STRATEGY_TO_GOAL, {
                    problem: prop.dataItem,
                    goal: p.dataItem
                });
                props.PopupActions.showWindow(PopupWindows.ADD_STRATEGY_TO_GOAL);
            }
            const updateStrategy = (item) => {
                props.CarePlanActions.updateStrategyToGoal(item.id, item.dictGoalStrategyId, prop.dataItem.id, p.dataItem.id, item.description).then(() => {
                    props.CarePlanActions.getCarePlanForPatientContext(dataState.take, dataState.skip, sort);
                });
            }
            const removeStrategy = (it) => {
                props.CarePlanActions.deleteStrategyToGoal(it.id, prop.dataItem.id).then(() => {
                    props.CarePlanActions.getCarePlanForPatientContext(dataState.take, dataState.skip, sort);
                });
            }
            const strategyEdit = (sp) => {
                return (

                        <EditCommandCell
                            {...sp}
                            editField={'inEdit'}
                            remove={removeStrategy}
                            update={updateStrategy}
                        />

                );
            }

            const strategyItemChange = (event) => {
                const field = event.field || "";
                const newData = strategies.map((item) => {
                    if (item.id === event.dataItem.id) {
                        return {
                            ...item,
                            isEdited: item[field] && item[field] === event.value ? false : true,
                            [field]: event.value
                        };
                    } else {
                        return item;
                    }
                });
                setStrategies(newData);
            }
            return (
                <Container>
                    <Row>
                        <Col col={2} className={"flex-grow-0"}>
                            <h5>Strategies</h5>
                        </Col>
                        {
                            prop.dataItem.inEdit ?
                                <Col >
                                    <Button size="small" onClick={addNewStrategyToGoal} icon={"plus"}> Add
                                        Strategy </Button>
                                </Col> : <></>
                        }
                    </Row>
                    <Grid
                        data={strategies}
                        editField={'inEdit'}
                        onItemChange={strategyItemChange}>
                        <GridColumn field={"dictGoalStrategyDisplayName"} title={"Strategy"} cell={StrategiesDropdown}/>
                        <GridColumn field={"description"} title={"Description"} cell={InlineTextInput}/>
                        <GridColumn title={" "} cell={strategyEdit} width={"120px"}/>
                    </Grid>
                </Container>)
        }


        const goalItemChange = (event) => {
            const field = event.field || "";
            const newData = goalData.map((item) => {
                if (item.id === event.dataItem.id) {
                    return {
                        ...item,
                        isEdited: item[field] && item[field] === event.value ? false : true,
                        [field]: event.value
                    };
                } else {
                    return item;
                }
            });
            setGoalData(newData);
        }
        const cancel = (dataItem) => {
            const data1 = [...goalData];
            const originalItem = goalData.find((p) => p.id === dataItem.id);
            if (!originalItem.newItem) {
                setGoalData(data1.map(v => {
                    if (v.id == originalItem.id)
                        v.inEdit = false;
                    return v;
                }))
            } else {
                data1.splice(data1.indexOf(originalItem), 1);
                setGoalData(data1);
            }
        }

        const remove = (dataItem) => {
            props.CarePlanActions.deleteCarePlanGoal(dataItem.id).then(() => {
                props.CarePlanActions.getCarePlanForPatientContext(dataState.take, dataState.skip, sort);
            });
        }

        const enterEdit = (dataItem) => {
            let newData = goalData.map((item) =>
                item.id === dataItem.id ? {...item, inEdit: true} : item
            );
            setGoalData(newData);
        };

        const add = (dataItem) => {
            props.CarePlanActions.addGoalToCondition(prop.dataItem.id, dataItem.description, dataItem.dictGoalStatusId, dataItem.dictGoalCategoryId).then(() => {
                props.CarePlanActions.getCarePlanForPatientContext(dataState.take, dataState.skip, sort);
            });
        };

        const addNewGoal = () => {
            props.CarePlanActions.setEditCarePlanCondition({payload: prop.dataItem});
            props.PopupActions.showWindow(PopupWindows.ADD_GOAL_TO_CONDITION_WINDOW);
        };

        const update = (dataItem) => {
            props.CarePlanActions.updateCarePlanGoal(dataItem.id, prop.dataItem.id, dataItem.description, dataItem.dictGoalStatusId, dataItem.dictGoalCategoryId).then(() => {
                props.CarePlanActions.getCarePlanForPatientContext(dataState.take, dataState.skip, sort);
            });
        };

        const GoalEdit = (props) => {
            return (
                <EditCommandCell
                    {...props}
                    editField={'inEdit'}
                    cancel={cancel}
                    remove={remove}
                    update={update}
                    add={add}
                    edit={enterEdit}
                />
            );
        };


        return (
            <Container style={{background: "#e8ecfa"}}>
                <Row>
                    <Col col={6}>
                        <h5 style={{margin: 0, marginBottom: 5}}>Determinants</h5>
                        {
                            prop.dataItem.inEdit ?
                                <Row>
                                    <Col col={10}>
                                        <DictionaryDropDownList
                                            textField={"displayName"}
                                            value={determinant}
                                            style={{height: "30px"}}
                                            code={"AH_CARE_PLAN_DETERMINANTS"}
                                            onChange={determinantsOnChange}
                                        />
                                    </Col>
                                    <Col>
                                        <Button size="small" text onClick={addNewDeterminant} icon={"pi pi-plus"}/>
                                    </Col>
                                </Row> : <></>
                        }
                    </Col>
                    <Col col={6}>
                        <h5 style={{margin: 0, marginBottom: 5}}>Diagnosis Codes</h5>
                    </Col>
                </Row>
                <Row style={{height: "100px"}}>
                    <Col col={6}>
                        <ListBox
                            style={{width: "100%", height: "100%"}}
                            item={CustomDeterminant}
                            data={determinants}
                            textField={"dictDeterminantDisplayName"}
                        />
                    </Col>
                    <Col col={6}>
                        <ListBox
                            style={{width: "100%", height: "100%"}}
                            item={DiagnosisItem}
                            data={diagnosisCodes}
                            textField={"dictDiagnosisCode"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col col={12}>
                        <Row>
                            <Col col={4}>
                                <h5>Goals</h5>
                            </Col>
                            {
                                prop.dataItem.inEdit ?
                                    <Col style={{marginLeft: "3%"}}>
                                        <Button size="small" onClick={addNewGoal} icon={"pi pi-plus"}
                                                label={"Add Goal"}/>
                                    </Col>
                                    : <></>
                            }
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col col={12}>

                        <Grid
                            detail={GoalDetailComponent}
                            data={goalData}
                            editField={'inEdit'}
                            expandField="expanded"
                            onExpandChange={expandGoalChange}
                            onItemChange={goalItemChange}
                        >
                            <GridColumn field={"description"} title={"Goal"} cell={InlineTextInput}/>
                            <GridColumn field={"dictGoalStatusDisplayName"} title={"Status"} cell={GoalStatusDropdown}/>
                            <GridColumn field={"dictGoalCategoryDisplayName"} title={"Category"}
                                        cell={GoalCategoryDropdown}/>
                            <GridColumn title={" "} cell={GoalEdit} width={"120px"}/>
                        </Grid>
                    </Col>

                </Row>

            </Container>)
    }

    return <ShowForPermissionComponent component permissions={[PhixPermissions.AH_VIEW_CAREPLAN]}>
        <Container style={{height: "100%", padding: 0, margin: 0}}>
            <Row>
                <Col col={12}>
                    <Toolbar>
                        <Input
                            style={{width: "50%"}}
                            placeholder={"Search"}
                            id={"input-id"}
                            value={searchState}
                            onChange={(e) => setSearchState(e.value)}
                        />
                        <ToolbarSpacer/>

                        <ToolbarItem>
                            <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_CAREPLAN]}>
                                <Button size="small" icon="pi pi-plus" text="Image"
                                        tooltip={"Add to care plan"}
                                        onClick={(e) => props.PopupActions.showWindow(PopupWindows.CARE_PLAN_WINDOW)}/>
                            </ShowForPermissionComponent>
                        </ToolbarItem>
                    </Toolbar>
                </Col>
            </Row>
            <Row style={{height: "calc(100% - 50px)"}}>
                <Col col={12} style={{width: "100%", height: "100%"}}>
                    <Grid
                        {...dataState}
                        style={{height: "100%"}}
                        data={orderBy(filterData(problems), sort)}
                        detail={DetailComponent}
                        expandField="expanded"
                        onExpandChange={expandChange}
                        pageSize={dataState.limit}
                        total={props.totalCount}
                        filterable={filterState}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setCarePlanSort(e.sort);
                        }}
                        skip={dataState.skip}
                        pageable={{
                            buttonCount: 20, info: true, type: "numeric", pageSizes: true, previousNext: true,
                        }}

                        onDataStateChange={dataStateChange}
                    >
                        <GridColumn title="Problem" cell={ProblemDisplay}/>
                        <GridColumn field="dictCategoryDisplayName" title="Category"/>
                        <GridColumn field="description" title="Description"/>
                        <GridColumn field="dictStatusDisplayName" title="Clinical Status"/>
                        <GridColumn title="" cell={editRow} width={80}/>
                    </Grid>
                </Col>
            </Row>
        </Container>
    </ShowForPermissionComponent>
};

function mapStateToProps(state) {
    return {
        carePlan: state.patient.carePlan,
        patientContext: state.patient.patientContext,
        permissionInfo: state.auth.session?.permissionInfo
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        CarePlanActions: bindActionCreators(CarePlanActions, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarePlanTabComponent)