import {connect} from "react-redux";
import React from "react";
import TileHeader from "../../layout/TileHeader";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {ScheduleActions} from "../../action/SchedulerAction";
import moment from "moment";
import {Button} from "primereact/button";
import {OpenEmrActions} from "../../action/OpenEmrAction";
import {Col, Container, Row} from "../../layout/GridLayout";
import {TileContainer} from "../../layout/TileContainer";
import {TileBody} from "../../layout/TileBody";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {WindowActions} from "../../action/WindowAction";
import {EncounterAction} from "../../action/EncounterAction";
import {PhixPermissions} from "../../config/GlobalConfig";
import {UserActions} from "../../action/UserAction";
import {SecuritySettingsAction} from "../../action/SecuritySettingsAction";

const styles = {
    container: {
        width: "100%",
        padding: 0,
        margin: 0,
        marginTop: "10px",
        height: "calc(100% - 31px)",
    },
    row: {
        height: "100%",
        padding: 0,
        margin: 0,

    },
    titleh3: {
        color: '#7485c2',
        fontSize: '12px',
        padding: '0',
        margin: '5px 0',
        textTransform: 'uppercase',
    },
    clockIcon: {
        fontSize: '40px',
        color: '#FFF',
    },
    timeh2: {
        margin: '0',
        padding: '0',
        color: '#FFF',
        fontSize: '25px',
        lineHeight: '25px',
    },
    timep: {
        margin: '0',
        padding: '0',
        color: '#FFF',
        fontSize: '16px',
        fontWeight: 'bold',

    },
    titleh4: {
        margin: '0',
        padding: '0',
        color: '#888a8e',
        fontSize: '18px',
    },
    titleh5: {
        margin: '0',
        padding: '0',
        color: '#888a8e',
        fontSize: '22px',
    },
    videoIcon: {
        position: 'relative',
        fontSize: '18px',
        color: '#990001',
        top: '1px',
        left: '10px',
    },
    videoP: {
        margin: '0',
        padding: '0',
        color: '#000',
        fontSize: '12px',
        fontWeight: 'bold',
    },
}

const getSingleEvent = (props) => {
    let closestEventArr = [];
    let test1 = props.filter(element => moment(element.startDate).utc().local().format('YYYY-MM-DD HH:MM') > moment().utc().local().format('YYYY-MM-DD HH:MM')).reduce(function (prev, curr) {
        return prev.startDate < curr.startDate ? prev : curr;
    }, {})
    if (Object.keys(test1).length != 0) {
        closestEventArr.push(test1)
    }

    return closestEventArr;
}

const CustomView = (props) => {
    let closestEventArr = getSingleEvent(props.patientScheduleData);
    let participantsText;
    let patientData
    if (closestEventArr.length > 0) {
        patientData = closestEventArr.map(function (data) {
            if (data.participants.length >= 2) {
                participantsText = "With multiple participants";
            } else if (data.participants.length === 1) {
                let name = data.participants.map((val) => {
                    return val.firstName + ' ' + ((val.lastName) ? val.lastName : '')
                })
                participantsText = "With  " + name.toString();
            } else if (data.participants.length === 0) {
                participantsText = "Meeting alone ";
            } else {
                participantsText = '';
            }
            let formatText = '';
            let isVideo = false;
            if (data.meetingFormats !== undefined) {
                data.meetingFormats.map((val) => {
                    if (val.displayName === 'Audio') {
                        formatText = ",audio only,"
                    } else if (val.displayName === 'Group Text') {
                        formatText = "using group text"
                    } else if (val.displayName === 'Video') {
                        formatText = "over video"
                        isVideo = true;
                    } else {
                        formatText = "in person" // In person
                    }
                })
                if (data.meetingFormats.length > 1) {
                    formatText = "over multiple meeting formats";
                }
            }
            let patientText = '';
            if (data.Patient) {
                patientText = "regarding " + data.patientFirstName + ' ' + data.patientLastName
            }

            return (
                <Container style={{height: "100%", width: "100%"}}>
                    <Row>
                        <Col col={12}>
                            <h4 style={styles.titleh4}> {data.title}
                                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}>
                                    {isVideo ? props?.TelehealthAddress ?
                                        <a href={props?.TelehealthAddress} target="_blank"><span
                                            class="fa fa-video-camera"
                                            style={{
                                                position: 'initial',
                                                left: '0px',
                                                top: '10px',
                                                color: '#990001',
                                                fontSize: '25px',
                                                marginLeft: '4px',
                                            }}
                                        /></a> : <a target="_blank"><span class="fa fa-video-camera"
                                                                          style={{
                                                                              position: 'initial',
                                                                              left: '0px',
                                                                              top: '10px',
                                                                              color: '#990001',
                                                                              fontSize: '25px',
                                                                              marginLeft: '4px',
                                                                          }}
                                        /></a> : <></>
                                    }</ShowForPermissionComponent>
                            </h4>
                        </Col>
                    </Row>
                    <Col col={12}>
                        <p style={styles.videoP}>{participantsText} {formatText} {patientText}</p>
                    </Col>
                    {data?.encounterType?.displayName &&
                        <Col col={12}>
                            <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}>
                                <Button
                                    label={data ? data?.encounterType?.displayName : ""}
                                    severity={"secondary"}
                                    onClick={() => {
                                        let payload = {dictEncounterTypeId: data?.encounterType?.id};
                                        props.WindowActions.setWindowState("PatientPanelTabComponent", {selectedTab: 6});
                                        props.PopupActions.showWindow(PopupWindows.ENCOUNTER_WINDOW, payload);
                                    }
                                    }
                                    style={{
                                        height: 22,
                                        position: 'absolute',
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        right: '0', margin: '0 15px'
                                    }}/>
                            </ShowForPermissionComponent>

                        </Col>
                    }

                </Container>
            )
        })
    }
    return patientData
}
const displayTime = (props) => {
    let closestEventArr = getSingleEvent(props);
    let patientData = closestEventArr.map(function (data) {
        let createdDate = moment.utc(data.endDate).local().startOf('day')
        let current = moment().startOf('day');
        let diffInDays = moment.duration(createdDate.diff(current)).asDays();

        if (diffInDays >= 0) {
            return (
                <Row style={{height: "100%"}} key={data.id}>
                    <Col col={8}>
                        <Row>
                            <h2 style={styles.timeh2}> {moment.utc(data.startDate).local().format("h:mm a")} </h2>
                        </Row>
                        <Row>
                            <p style={styles.timep}>{moment.utc(data.startDate).local().format("MMM Do YYYY")}</p>
                        </Row>
                    </Col>
                    <Col col={4} className={"flex  justify-content-center"}>
                        <span className="fa fa-clock-o" style={styles.clockIcon}/>
                    </Col>


                </Row>)
        } else {
            return null
        }
    })
    return patientData

}
const NextAppointmentComponent = (props) => {
    const {ScheduleActions, OpenEmrActions} = props
    const [teleURL, setTeleURL] = React.useState([]);

    const createEncounterHandler = () => {
        props.WindowActions.setWindowState("PatientPanelTabComponent", {selectedTab: 6});
        props.EncounterAction.setEncounterContext({});
        props.PopupActions.showWindow(PopupWindows.ENCOUNTER_WINDOW);
    }

    React.useEffect(() => {
        const payload = {tenantId: props.tenant.tenantId, userRoleId: props.loggedInUser.userRoleId};
        props.SecuritySettingsActions.getSecuritySetting(payload);
    }, []);

    React.useEffect(() => {
        const payload = {
            "patientId": (props.patientContext) ? props.patientContext.patientRoleId : ''
        }
        ScheduleActions.getPatientSchedule(payload);
    }, [])
    React.useEffect(() => {
        props.userActions.getUser({userRoleId: props.userRoleId})
    }, [props.authenticated])

    React.useEffect(() => {
        const url = props?.securitySettings?.find(v => v.key && v.value && v.key === "telehealthAddress")
        if (url) {
            setTeleURL(url.value["telehealthAddress"])
        }
    }, [props?.securitySettings])

    let appointmentDetails_r;
    let appointmentDetails_l;
    if (props.patientScheduleData !== undefined && props.patientScheduleData.length > 0) {
        appointmentDetails_r = CustomView({
            patientScheduleData: props.patientScheduleData,
            patientContext: props.patientContext,
            OpenEmrActions: props.OpenEmrActions,
            PopupActions: props.PopupActions,
            WindowActions: props.WindowActions,
            EncounterAction: props.EncounterAction,
            TelehealthAddress: teleURL
        })
        appointmentDetails_l = displayTime(props.patientScheduleData)
    }

    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_SCHEDULE]}>
                <TileContainer>
                    <TileHeader headerColor={"black"} title={"Next Appointment"} span={"k-icon k-i-calendar"}>
                        <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}>
                            <Button
                                title="Start an unscheduled encounter"
                                label={"Start Encounter"}
                                onClick={createEncounterHandler}
                                style={{padding: '0px 5px', marginTop: 6, height: 25}}/>
                        </ShowForPermissionComponent>
                    </TileHeader>
                    <TileBody>
                        {(appointmentDetails_l !== undefined && appointmentDetails_l.length > 0) ?
                            <>
                                <Row style={styles.row}>
                                    <Col col={4}
                                         style={{padding: '10px 0px 15px 10px', height: "100%", background: '#7585c2'}}>
                                        {appointmentDetails_l}
                                    </Col>
                                    <Col col={8}
                                         style={{
                                             padding: '12px 0px 15px 25px',
                                             height: "100%",
                                             background: ' #d6dbe9',
                                         }}>
                                        {appointmentDetails_r}
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row style={styles.row}>
                                    <Col col={2} style={{
                                        padding: '12px 0px 15px 15px',
                                        height: "100%",
                                        background: '#7585c2'
                                    }}/>
                                    <Col col={10}
                                         style={{
                                             padding: '12px 0px 15px 25px',
                                             height: "100%",
                                             background: ' #d6dbe9',
                                         }}>
                                        <span style={{...styles.titleh5}}> No upcoming events scheduled. </span>
                                    </Col>
                                </Row>
                            </>
                        }

                    </TileBody>
                </TileContainer>
            </ShowForPermissionComponent>
        </React.Fragment>
    )

}
NextAppointmentComponent.propTypes =
    {
        scheduleActions: PropTypes.object,
    }

function mapStateToProps(state) {
    return {
        patientScheduleData: state.schedule.patientScheduleData,
        patientContext: state.patient.patientContext,
        userTelUrl: state.user.loggedinUser.telehealthAddress,
        authenticated: state.auth.authenticated,
        userRoleId: state.auth?.session?.userRoleId,
        tenant: state.Configuration.tenant.tenant,
        loggedInUser: state.user.loggedinUser,
        securitySettings: state.securitySettings.securitySettings,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        ScheduleActions: bindActionCreators(ScheduleActions, dispatch),
        OpenEmrActions: bindActionCreators(OpenEmrActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        WindowActions: bindActionCreators(WindowActions, dispatch),
        EncounterAction: bindActionCreators(EncounterAction, dispatch),
        userActions: bindActionCreators(UserActions, dispatch),
        SecuritySettingsActions: bindActionCreators(SecuritySettingsAction, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(NextAppointmentComponent)
