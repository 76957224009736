import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    DICTIONARY_TYPE_LIST_SUCCESS: "DICTIONARY_TYPE_LIST_SUCCESS",
    DICTIONARY_TYPE_LIST_ERROR: "DICTIONARY_TYPE_LIST_ERROR",
    SET_DICTIONARY_TYPE_CONTEXT: "SET_DICTIONARY_TYPE_CONTEXT",
    CLEAR_DICTIONARY_TYPE_CONTEXT: "CLEAR_DICTIONARY_TYPE_CONTEXT",
    CREATE_DICTIONARY_CODE: "CREATE_DICTIONARY_CODE",
    UPDATE_DICTIONARY_CODE: "UPDATE_DICTIONARY_CODE"
}

function setDictionaryTypeContext(dictionaryType) {
    return async dispatch =>
        dispatch({type: ActionTypes.SET_DICTIONARY_TYPE_CONTEXT, payload: dictionaryType})

}

function clearDictionaryTypeContext(dictionaryType) {
    return async dispatch =>
        dispatch({type: ActionTypes.CLEAR_DICTIONARY_TYPE_CONTEXT})

}

function createDictionaryCode(payload) {
    return WrapAuthentication('dictionary/add/code',
        {"payload": payload},
        ActionTypes.CREATE_DICTIONARY_CODE)
}

function addDictionary(payload) {
    return WrapAuthentication('dictionary/type/create',
        {"payload": payload},
        ActionTypes.ADD_DICTIONARY)
}

function updateDictionaryCode(payload) {
    return WrapAuthentication('dictionary/update',
        {payload: payload},
        ActionTypes.UPDATE_DICTIONARY_CODE)
}

function getDictionaryTypeList(limit, offset) {
    return WrapAuthentication('dictionary/type/all',
        {payload: null, limit, offset},
        ActionTypes.DICTIONARY_TYPE_LIST_SUCCESS
    )
}

function searchDictionaryTypeList(search, limit, offset) {
    return WrapAuthentication('dictionary/type/search', {
            payload: search,
            limit, offset
        },
        ActionTypes.DICTIONARY_TYPE_LIST_SUCCESS
    )
}


export const DictionaryTypeAction =
    {
        getDictionaryTypeList: getDictionaryTypeList,
        searchDictionaryTypeList: searchDictionaryTypeList,
        setDictionaryTypeContext: setDictionaryTypeContext,
        clearDictionaryTypeContext: clearDictionaryTypeContext,
        createDictionaryCode: createDictionaryCode,
        addDictionary: addDictionary,
        updateDictionaryCode: updateDictionaryCode
    }