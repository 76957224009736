import * as React from "react";
import {
    ButtonGroup,
    SplitButton,
    Toolbar,
    ToolbarItem,
    ToolbarSeparator,
    ToolbarSpacer,
} from "@progress/kendo-react-buttons";
import {Button} from "primereact/button"
import {Signature} from "@progress/kendo-react-inputs";
import {loadMessages} from "@progress/kendo-react-intl";
import {Window} from "@progress/kendo-react-dialogs";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {useDispatch, useSelector} from "react-redux";
import DocumentUpload from "../shared/DocumentUpload";
import {UserActions} from "../../action/UserAction";
import {AuthAction} from "../../action/AuthAction";
import {InputText} from 'primereact/inputtext';

loadMessages(
  {
    upload: {
      dropFilesHere: "",
      select: "Browse Image...",
    },
  },
  "en-US"
);
const SignatureWindow = () => {
  const [value, setValue] = React.useState();
  const [color, setColor] = React.useState();
  const [strokeWidth, setStrokeWidth] = React.useState(3);
  const [strokeWidthText, setStrokeWidthText] = React.useState("Wide");
  const [showUpload, setShowUpload] = React.useState(false);
  const [fileValue, setFileValue] = React.useState("");
  const [error, setError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch()
  const showWindow = useSelector(state => state.popupWindow.showWindow)
  const userContext = useSelector(state => state.user.userContext)
  const signature = useSelector(state => state.user.signature)
  const loggedinUser = useSelector(state => state.user.loggedinUser)

  React.useEffect(()=>{
    if(signature && userContext.id === signature.userRoleId) {
        setValue(signature.fileBase64?.startsWith("data:image/png;base64,") ? signature.fileBase64 : "data:image/png;base64,"+signature.fileBase64 )
    }
  }, [signature])

  const strokeWidthOptions = [
    {
      text: "Normal",
      value: 1,
    },
    {
      text: "Wide",
      value: 3,
    },
  ];
  const onChange = (e) => {
    setValue(e.value);
  };

  const onStrokeWidthChange = (e) => {
    setStrokeWidth(e.item.value);
    setStrokeWidthText(e.item.text);
  };
  const onDrawClick = () => {
    setShowUpload(false);
  };
  const onUploadClick = () => {
    setShowUpload(true);
  };

  const  onSave = async () => {
    if (userContext?.id) {
        let formData = new FormData();
        formData.append("userRoleId" , userContext?.id);
        if (showUpload && fileValue) {
            formData.append("fileName", fileValue)
        } else if (value) {
            var base64 = await fetch(value)
            var blob = await base64.blob();
            var file = new File([blob], "phix.png", { lastModified: new Date().getTime(), type: "image/png" });
            formData.append("fileName", file)
        }
        let userName = loggedinUser.userName;
        let response = await AuthAction.verifyPassword(userName, password)(dispatch)
        if (response === true) {
            await UserActions.uploadSignature(formData)(dispatch);
            hideDialog()
        } else {
            setError("Invalid password");
        }
    }
  };
  const onClear = () => {
    setValue(undefined);
    setFileValue(undefined)
    setError("")
  };
  const hideDialog = () => {
      onClear()
      setError("");
      const payload = {userRoleId: userContext?.userRoleId};
      UserActions.downloadSignature(payload)(dispatch)
      PopupWindowActions.hideWindow(PopupWindows.SIGNATURE_WINDOW)(dispatch)
  };
  if (showWindow[PopupWindows.SIGNATURE_WINDOW]) {
      return (
          <Window
              title={"Signature"}
              style={{height:"500px", width: "800px", "zIndex": "10150 !important"}}
              visible={showWindow[PopupWindows.SIGNATURE_WINDOW]}
              onClose={hideDialog}
              >
            <div style={{display: "flex", justifyContent: "center"}}>
              <div className="content-wrapper">
                <Toolbar>
                  {!showUpload && (
                    <>
                      <SplitButton
                          items={strokeWidthOptions}
                          text={strokeWidthText}
                          textField="text"
                          onItemClick={onStrokeWidthChange}
                        />
                      <ToolbarSeparator />
                    </>
                  )}

                  <ToolbarSpacer />

                  <ToolbarItem>
                    <ButtonGroup>
                      <Button
                        togglable={true}
                        selected={!showUpload}
                        onClick={onDrawClick}
                        icon="brush"
                      >
                        Draw
                      </Button>
                      <Button
                        togglable={true}
                        selected={showUpload}
                        onClick={onUploadClick}
                        icon="upload"
                      >
                        Upload
                      </Button>
                    </ButtonGroup>
                  </ToolbarItem>
                </Toolbar>
                {showUpload ? <DocumentUpload onChange={setFileValue}/> : (<div className={"signature-wrapper"} style={{height: "270px"}}>
                    <Signature
                      value={value}
                      onChange={onChange}
                      color={color}
                      strokeWidth={strokeWidth}
                      smooth={true}
                      maximizable={false}
                      hideLine={true}
                    />
                </div>)}
                <Toolbar>
                    <div className="flex flex-row gap-2">
                        <label htmlFor="password">Password</label>
                        <InputText
                            style={{width: "100%"}}
                            placeholder="Password"
                            id="password"
                            name="password"
                            autoComplete="new-password"
                            type={"password"}
                            onChange={(e)=> setPassword(e?.target?.value)}
                            value={password}
                        />
                        {error !== "" && <label style={{width: "100%", color: "red"}} className="k-error-colored">
                            {error}
                        </label>}
                    </div>
                </Toolbar>

                <Toolbar>
                  <Button
                    icon="pi pi-check"
                    themeColor="primary"
                    disabled={!value && !fileValue}
                    onClick={onSave}
                  >
                    Save
                  </Button>

                  {!showUpload && <Button onClick={onClear}>Clear</Button>}
                </Toolbar>

              </div>
            </div>
          </Window>
          );
      } else {
        return null;
      }
};

export default SignatureWindow