import {Window} from "@progress/kendo-react-dialogs";
import {Input} from "@progress/kendo-react-inputs";
import * as React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {DictionaryTypeAction} from "../../action/DictionaryTypeAction";
import {connect} from "react-redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {PatientAction} from "../../action/PatientAction";
import {PersonActions} from "../../action/PersonAction";
import {Grid, GridColumn} from "@progress/kendo-react-grid";

const SearchImmunizationWindow = (props) => {

    const [filterState, setFilterState] = React.useState(false)
    const hideDialog = () => {
        PatientAction.clearEditPatientContext();
        props.PopupActions.hideWindow(PopupWindows.SEARCH_IMMUNIZATION_CODE_WINDOW);
    };

    const [dataState, setDataState] = React.useState({
        take: 5,
        skip: 0,
    });
    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };
    const SearchVaccine = () => {
        //setVisiblesearch(!visiblesearch);
    };
    if (props.showWindow[PopupWindows.SEARCH_IMMUNIZATION_CODE_WINDOW])
        return (
            <Window title={"CVX Code Search"} onClose={hideDialog} initialWidth={550} initialHeight={450} modal={true}>
                <form className="k-form">
                    <div className={"row"} style={{width: "100%"}}>
                        <div className="k-form-field">
                <span style={{width: "80%", float: 'left',}}>
              <Input placeholder="Search"/>
              </span>
                            <span style={{width: "20%", float: 'left', textAlign: "right",}}>
              <input type="button" className="k-button k-primary" value="Search " onClick={SearchVaccine}/>
              </span>
                        </div>
                    </div>

                    <div className={"row"} style={{width: "100%"}}>
                        <div className="k-form-field">
                            <Grid
                                {...dataState}
                                style={{height: "calc(100% - 50px)"}}
                                data={props.patients}
                                pageSize={dataState.limit}
                                // style={{height: "calc(100vh - 100px)"}}
                                total={props.totalCount}
                                filterable={filterState}
                                sortable={true}
                                skip={dataState.skip}
                                pageable={{
                                    buttonCount: 20,
                                    info: true,
                                    type: "numeric",
                                    pageSizes: true,
                                    previousNext: true,
                                }}

                                onDataStateChange={dataStateChange}>
                                <GridColumn field="code" title="Code"/>
                                <GridColumn field="Description" title="Description"/>
                                <GridColumn field="Select One" title="Select One"/>
                            </Grid>
                        </div>
                    </div>


                    <div className="text-right" style={{marginTop: '15px', textAlign: 'center'}}>
                        <button type="button" className="k-button k-primary" onClick={hideDialog}>
                            Add Selected
                        </button>
                    </div>
                </form>
            </Window>
        );
    else return null;
};

SearchImmunizationWindow.propTypes = {
    showWindow: PropTypes.object,
    DictionaryActions: PropTypes.object,
    DictionaryTypeAction: PropTypes.object,
    PopupActions: PropTypes.object,
    PatientActions: PropTypes.object,
    createPatientContext: PropTypes.object,
    patient: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        createPatientContext: state.patient.createPatientContext,
        contactAddress: state.person.contactAddress,
        patient: state.patient,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        DictionaryTypeActions: bindActionCreators(DictionaryTypeAction, dispatch),
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchImmunizationWindow);
