import {WrapAuthentication} from "../util/AuthWrapper";

export const ActionTypes = {
    PERSON_GET_PERSON_MODEL: "PERSON_GET_PERSON_MODEL",
    PERSON_CLEAR_PERSON_MODEL: "PERSON_CLEAR_PERSON_MODEL",

    PERSON_ADD_CONTACT_ADDRESS: "PERSON_ADD_CONTACT_ADDRESS",

    PERSON_ADD_CONTACT_PHONE: "PERSON_ADD_CONTACT_PHONE",
    PERSON_GET_CONTACT_PHONE: "PERSON_GET_CONTACT_PHONE",

    PERSON_ADD_CONTACT_EMAIL: "PERSON_ADD_CONTACT_EMAIL",
    PERSON_GET_CONTACT_EMAIL: "PERSON_GET_CONTACT_EMAIL",

    PERSON_GET_CONTACT_ADDRESS: "PERSON_GET_CONTACT_ADDRESS",
    GET_CONTACT_ADDRESS: "GET_CONTACT_ADDRESS",
    GET_CONTACT_PREFERENCES: "GET_CONTACT_PREFERENCES",
    UPDATE_CONTACT_PREFERENCES: "UPDATE_CONTACT_PREFERENCES",
    CLEAR_CONTACT_PREFERENCES: "CLEAR_CONTACT_PREFERENCES",
}

function getPersonModel(personId) {
    return WrapAuthentication("person/model/get",
        {payload: {personId: personId}},
        ActionTypes.PERSON_GET_PERSON_MODEL)
}

function addContactPhone(personId, dictionaryId, phone, isPrimary) {
    return WrapAuthentication("person/contact/phone/create",
        {
            payload: {
                personId,
                dictionaryDisplayName: "",
                dictionaryId,
                phone,
                isPrimary
            }
        },
        ActionTypes.PERSON_ADD_CONTACT_PHONE)
}

function updateContactPhone(id, dictionaryId, phone, isPrimary) {
    return WrapAuthentication("person/contact/phone/update",
        {
            payload: {
                id,
                dictionaryId,
                phone,
                isPrimary
            }
        },
        ActionTypes.PERSON_ADD_CONTACT_PHONE)
}

function getContactPhone(personId) {
    return WrapAuthentication("person/contact/phone/get",
        {
            payload: {
                personId,
            }
        },
        ActionTypes.PERSON_GET_CONTACT_PHONE)
}

function removeContactPhone(id) {
    return WrapAuthentication("person/contact/phone/delete",
        {
            payload: {
                phoneId: id
            }
        },
        ActionTypes.PERSON_ADD_CONTACT_PHONE)
}

function updateContactEmail(id, dictionaryId, email, isPrimary) {
    return WrapAuthentication("person/contact/email/update",
        {
            payload: {
                id,
                dictionaryId,
                email,
                isPrimary
            }
        },
        ActionTypes.PERSON_ADD_CONTACT_PHONE)
}

function removeContactEmail(id) {
    return WrapAuthentication("person/contact/email/delete",
        {
            payload: {
                emailId: id
            }
        },
        ActionTypes.PERSON_ADD_CONTACT_PHONE
    )
}

function getContactEmail(personId) {
    return WrapAuthentication("person/contact/email/get",
        {
            payload: {personId}
        },
        ActionTypes.PERSON_GET_CONTACT_EMAIL
    )
}

function addContactEmail(personId, dictionaryId, email, isPrimary) {
    return WrapAuthentication("person/contact/email/create",
        {
            payload: {personId, dictionaryId, email, isPrimary}
        },
        ActionTypes.PERSON_ADD_CONTACT_EMAIL
    )
}

function getContactAddress(personId) {
    return WrapAuthentication("person/contact/address/get",
        {
            payload: {personId}
        },
        ActionTypes.PERSON_GET_CONTACT_ADDRESS
    )
}

function getContactAddressById(id) {
    return WrapAuthentication("person/contact/address/get/id",
        {
            payload: {id: id}
        },
        ActionTypes.GET_CONTACT_ADDRESS
    )
}


function addContactAddress(personId, dictionaryId, street1, street2, city, dictStateId, zipCode, country, isPrimary) {
    return WrapAuthentication("person/contact/address/create",
        {
            payload: {
                personId,
                dictionaryId,
                street1,
                street2,
                city,
                dictStateId,
                zipCode,
                country,
                isPrimary

            }
        },
        ActionTypes.PERSON_ADD_CONTACT_PHONE
    )
}

function updateContactAddress(addressId, dictionaryId, street1, street2, city, dictStateId, zipCode, country, isPrimary) {
    return WrapAuthentication("person/contact/address/update",
        {
            payload: {
                addressId,
                dictionaryId,
                street1,
                street2,
                city,
                dictStateId,
                zipCode,
                country,
                isPrimary

            }
        },
        ActionTypes.PERSON_ADD_CONTACT_PHONE
    )
}

function removeContactAddress(id) {
    return WrapAuthentication("person/contact/address/delete",
        {
            payload: {
                addressId: id
            }
        },
        ActionTypes.PERSON_ADD_CONTACT_PHONE
    )
}

function getContactPreferences(payload) {
    return WrapAuthentication("person/contact/preferences/get",
        {
            payload: payload
        },
        ActionTypes.GET_CONTACT_PREFERENCES
    )
}

function updateContactPreferences(payload) {
    return WrapAuthentication("person/contact/preferences/update",
        {
            payload: payload
        },
        ActionTypes.UPDATE_CONTACT_PREFERENCES
    )
}

function clearContactPreferences() {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_CONTACT_PREFERENCES})
    }
}

export const PersonActions =
    {
        getPersonModel,
        addContactPhone,
        updateContactPhone,
        getContactPhone,
        removeContactPhone,
        addContactEmail: addContactEmail,
        getContactEmail,
        removeContactEmail,
        updateContactEmail,
        addContactAddress,
        getContactAddress: getContactAddress,
        updateContactAddress,
        removeContactAddress,
        getContactAddressById,
        getContactPreferences: getContactPreferences,
        updateContactPreferences,
        clearContactPreferences

    }