import * as React from "react";
import {GridToolbar} from "@progress/kendo-react-grid";
import {PatientAction} from "../action/PatientAction";
import {DictionaryDispatchAction} from "../action/DictionaryDispatchAction";
import {bindActionCreators} from "redux";
import {connect, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {Dictionary,} from "./InputControls/DictionaryDropDownList";
import {
    Field,
    FieldCheckboxInput,
    FieldDictionaryDropDown,
    FieldRow,
    FieldSection,
    FieldTextInput
} from "./InputControls/FormFields";
import {Col, Row} from "../layout/GridLayout";
import {Checkbox} from "@progress/kendo-react-inputs";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import {Label} from "@progress/kendo-react-labels";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Fade} from "@progress/kendo-react-animation";
import {PopupWindowActions} from "../action/PopupWindowAction";


const EditCommandCell = (props) => {
    const {data} = props;
    const inEdit = data[props.editField] || false;
    const isNewItem = data?.newItem || false;
    return inEdit ? (
        <div style={{marginTop: "5%"}}>
            <Button
                icon={isNewItem ? "pi pi-save" : "pi pi-save"}
                severity={"success"}
                text
                onClick={(e) => isNewItem ? props.add(e) : props.update(e)}> </Button>
            <Button
                text
                severity={"danger"}
                icon={isNewItem ? "pi pi-ban" : "pi pi-trash"}
                onClick={(e) =>
                    isNewItem ? props.cancel(e, data) : props.remove(e)}> </Button>
        </div>
    ) : (
        <div style={{marginTop: "5%"}}>
            <Button icon={"pi pi-pencil"} text onClick={(e) => props.edit(e)}> </Button>
        </div>
    );
};

const PatientRelationshipsDetails = (props) => {
    const [dataItem, setDataItem] = React.useState(null);
    const [preferredPhoneNo, setPreferredPhoneNo] = React.useState("");
    const [preferredEmailId, setPreferredEmailId] = React.useState("");
    const [selectPreferredPhoneNo, setSelectPreferredPhoneNo] = React.useState(false);
    const [selectPreferredEmailId, setSelectPreferredEmailId] = React.useState(false);
    const [relationshipRoles, setRelationshipRoles] = React.useState([]);
    const [validated, setValidated] = React.useState(true);
    const [canWeDiscussMedicalConditions, setCanWeDiscussMedicalConditions] = React.useState(false);
    const dictionaries = useSelector(state => state.dictionary.dictionaries)
    const patientRelationshipContext = useSelector(state => state.patient.allPatientRelationshipContext)
    const editField = "inEdit";
    const {
        handleSubmit,
        register,
        control,
        reset,
        setValue,
        getValues,
        watch,
        formState: {errors},
    } = useForm({defaultValues: {description: ""}});

    React.useEffect(() => {
        const item =  patientRelationshipContext.find(v=> v.id === dataItem?.id)
        setValue("id", item?.id)
        setValue("firstName", item?.firstName);
        setValue("lastName", item?.lastName);
        setValue("dictRelationshipType", item?.dictRelationshipTypeId || item?.dictRelationshipType?.id);
        setValue("isPrimary", item?.isPrimary);
        if (item?.preferredPhoneNo) {
            setPreferredPhoneNo(item?.preferredPhoneNo)
        }
        if (item?.preferredEmailId) {
            setPreferredEmailId(item?.preferredEmailId)
        }
        setCanWeDiscussMedicalConditions(item?.canWeDiscussMedicalConditions)
        if (item?.role?.length > 0 && dictionaries[Dictionary.AH_RELATIONSHIP_ROLE]) {
            const drrIds = item?.role?.map(v=> v?.id);
            setRelationshipRoles(dictionaries[Dictionary.AH_RELATIONSHIP_ROLE]?.filter(v=> drrIds?.indexOf(v?.id) > -1))
        }
    }, [patientRelationshipContext && dataItem && dictionaries[Dictionary.AH_RELATIONSHIP_ROLE]]);

    React.useEffect(()=> {
        setDataItem(props?.dataItem);
    },[props?.dataItem])

    const enterEdit = (e) => {
        e.preventDefault();
        setDataItem({...dataItem, inEdit: true, newItem: false});
    };

    const remove = (e) => {
        e.preventDefault();
        props.PatientActions.deletePatientRelationships({id: dataItem?.id, patientRoleId: dataItem?.patientRoleId}).then(() => {
            props.PatientActions.getAllPatientRelationships({patientRoleId: props?.patientContext?.patientRoleId})
        });
    }

    const addPatientRelationships = (form) => {
        if (form?.firstName && form?.lastName && relationshipRoles.length > 0) {
            var payload = {
                    patientRoleId: props?.patientContext?.patientRoleId,
                    firstName: form?.firstName,
                    lastName: form?.lastName,
                    dictRelationshipTypeId: form?.dictRelationshipType?.id || form?.dictRelationshipType,
                    dictRelationshipRoleId: relationshipRoles.map(v=> v?.id),
                    preferredPhoneNo: preferredPhoneNo,
                    preferredEmailId: preferredEmailId,
                    canWeDiscussMedicalConditions: canWeDiscussMedicalConditions,
                    isPrimary: form?.isPrimary || false
                }
            if (dataItem?.id) {
                payload["id"] = dataItem?.id;
                props.PatientActions.updatePatientRelationships(payload).then(() => {
                    props.PatientActions.getAllPatientRelationships({patientRoleId: props?.patientContext?.patientRoleId})
                });
            } else {
                props.PatientActions.addPatientRelationships(payload).then(() => {
                    props.PatientActions.getAllPatientRelationships({patientRoleId: props?.patientContext?.patientRoleId})
                });
            }
        } else {
            setValidated(false);
        }
    }

    const EditButtons = () => {
        return (
            <EditCommandCell
                data={dataItem}
                editField={editField}
                cancel={props.cancel}
                remove={remove}
                update={handleSubmit(addPatientRelationships)}
                add={handleSubmit(addPatientRelationships)}
                edit={enterEdit}
            />
        );
    };

    const preferredPhoneNoOnChange = (e) => {
        setPreferredPhoneNo(e.target.value)
    }
    const preferredEmailIdOnChange = (e) => {
        setPreferredEmailId(e.target.value)
    }

    return dataItem && <div>
    <FieldSection>
        <div style={{pointerEvents: dataItem.inEdit ? undefined : 'none'}}>
        <FieldRow>
            <Field col={3} label={"First Name"} field={"firstName"} error={errors} register={register}>
                <FieldTextInput/>
            </Field>
            <Field col={2} label={"Last Name"} field={"lastName"} error={errors} register={register}>
                <FieldTextInput/>
            </Field>
            <Col col={3}>
                <Label style={{
                        width: "100%",
                        backgroundColor: "white",
                    }}>Relationship Role</Label>
                    <MultiSelect
                        data={dictionaries[Dictionary.AH_RELATIONSHIP_ROLE]}
                        textField="displayName"
                        dataItemKey="id"
                        name = "dictRelationshipRole"
                        value={relationshipRoles}
                        onChange={(e) => setRelationshipRoles(e.value) }
                        style={{
                          width: "220px",
                        }}
                        placeholder="Please select ..."
                      />
            </Col>
            <Field col={3} label={"Relationship Type"} field={"dictRelationshipType"} register={register}
                   error={errors} control={control}>
                <FieldDictionaryDropDown code={Dictionary.AH_RELATIONSHIP_TYPE}/>
            </Field>
            <Field col={1} label={"Primary"} field={"isPrimary"} register={register}
                   error={errors} control={control}>
                <FieldCheckboxInput/>
            </Field>
        </FieldRow>
        </div>
        <br></br>
        <Row>
            <Col  style={{pointerEvents: dataItem.inEdit ? undefined : 'none'}} col={4}>
                <span>Preferred method of contact</span>
            </Col>
            <Col style={{pointerEvents: dataItem.inEdit ? undefined : 'none'}}  col={6}>
                <Checkbox value={canWeDiscussMedicalConditions} onChange={(e)=> setCanWeDiscussMedicalConditions(e.target.value)} label={"Can we discuss patients medical condition with this person?"}/>
            </Col>
            <Col>
                <EditButtons/>
            </Col>
        </Row>
        <br></br>
        <Row  style={{pointerEvents: dataItem.inEdit ? undefined : 'none'}}>
            <Col col={1} style={{paddingBottom: 20}}>
                <span>Phone</span>
            </Col>
            <Col col={3}>
                <input onChange={preferredPhoneNoOnChange} className={"k-input k-textbox"} value={preferredPhoneNo} type={"text"} />
            </Col>
        </Row>
        <br></br>
        <Row  style={{pointerEvents: dataItem.inEdit ? undefined : 'none'}}>
            <Col col={1}>
                <span>Email</span>
            </Col>
            <Col col={3}>
                <input onChange={preferredEmailIdOnChange} value={preferredEmailId} className={"k-input k-textbox"} type={"text"} />
            </Col>
        </Row>
    </FieldSection>
    <hr></hr>
    <NotificationGroup style={{
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
    }}>
        <Fade>
            {!validated && (
                <Notification
                    type={{
                        style: "error",
                        icon: true,
                    }}
                    closable={true}
                    onClose={() => setValidated(true)}
                >
                    <span>{"Mandatory fields are missing"}</span>
                </Notification>
            )}
        </Fade>
    </NotificationGroup>
    </div>
}


const PatientRelationshipsComponent = (props) => {
    const [data, setData] = React.useState([]);

    const addNew = (e) => {
        e.preventDefault();
        let newDataItem = {
            newItem: true,
            inEdit: true
        };
        setData([...data, newDataItem]);
    };


    React.useEffect(() => {
        props.DictionaryActions.searchDictionaryList(Dictionary.AH_RELATIONSHIP_ROLE);
    }, []);

    React.useEffect(()=>{
        const pr = props?.patientRelationshipContext?.map(v=> ({...v, newItem: false, inEdit:false}));
        setData([...pr])
    },[props?.patientRelationshipContext])

    React.useEffect(()=>{
        props.PatientActions.getAllPatientRelationships({patientRoleId: props?.patientContext?.patientRoleId}).then(res => {
            const pr = res.payload?.map(v=> ({...v, newItem: false, inEdit:false}));
            setData([...pr])
        })
    },[props?.patientContext?.patientRoleId]);

    const refresh = (e) => {
        e.preventDefault();
        if (props?.patientContext?.patientRoleId) {
            props.PatientActions.getAllPatientRelationships({patientRoleId: props?.patientContext?.patientRoleId})
        }
    }

    const cancel = (e, dataItem) => {
        e.preventDefault();
        const data1 = [...data];
        const originalItem = data.find((p) => p.id === dataItem.id);
        if (!originalItem.newItem) {
            if (props?.patientContext?.patientRoleId) {
                props.PatientActions.getAllPatientRelationships({patientRoleId: props?.patientContext?.patientRoleId})
            }
        } else {
            data1.splice(data1.indexOf(originalItem), 1);
            setData(data1);
        }
    }


    return (
            <div>
                <GridToolbar>
                    <Button icon={"pi pi-plus"} text severity={"success"} onClick={addNew}/>
                    {/*<Button icon={"pi pi-refresh"} onClick={refresh}/>*/}
                </GridToolbar>
                {
                    data.map((d, i) => {
                        return <div>
                            <PatientRelationshipsDetails
                                key={i}
                                cancel={(e)=> cancel(e,d)}
                                PatientActions={props.PatientActions}
                                patientContext={props.patientContext}
                                dataItem={{...d}}/>
                        </div>
                    })
                }
            </div>
        )
};

function mapStateToProps(state) {
    return {
        patients: state.patient.patientList.patients,
        permissionInfo: state.auth.session.permissions,
        patientContext: state.patient.createPatientContext,
        patientRelationshipContext: state.patient.allPatientRelationshipContext,
        dictionaries: state.dictionary.dictionaries,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientRelationshipsComponent)
