import {ActionTypes} from "../action/QualityMeasureActions";


let initialState = {
    MeasureCohort:[],
    MeasureCohortList:[],
    MeasureCohortDetails:[],
    cohortType:{
        "displayName": "My Patients",
    },
    seconddropdown:{
        defaultValue:"",
        dropdownvalues:[]
    },
    dropdownDefaultValues:"",
    selectedMeasureDisplayName:"",
    workflow:"piechart",
}

export const QualityMeasureReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_MEASURE_COHORT_SUCCESS:
            return {...state, MeasureCohort: action.payload.payload}
        case ActionTypes.GET_MEASURE_COHORT_ERROR:
            return {...state, MeasureCohort: []}
        case ActionTypes.GET_MEASURE_COHORTLIST_SUCCESS:
            return {...state, MeasureCohortList: action.payload.payload}
        case ActionTypes.GET_MEASURE_COHORTLIST_ERROR:
            return {...state, MeasureCohortList: []}
        case ActionTypes.GET_MEASURE_COHORTDETAILS_SUCCESS:
            return {...state, MeasureCohortDetails: action.payload.payload}
        case ActionTypes.GET_MEASURE_COHORTDETAILS_CLEAR:
            return {...state, MeasureCohortDetails: []}
        case ActionTypes.GET_MEASURE_COHORTDETAILS_ERROR:
            return {...state, MeasureCohortDetails: []}
        case ActionTypes.SET_COHORT_TYPE:
            return {...state, cohortType: action.payload,  
            seconddropdown:{
                defaultValue:"",
                dropdownvalues:[],
                displayName:""
            },
            MeasureCohort:[],
            MeasureCohortList:[],
            MeasureCohortDetails:[],
        }
        case ActionTypes.SET_SECOND_DROPDOWN_DEFAULT_VALUE:
            return {...state,seconddropdown:{...state.seconddropdown,defaultValue: action.payload}}
        case ActionTypes.SET_DROPDOWN_VALUES:
            return {...state,seconddropdown:{...state.seconddropdown,dropdownvalues: action.payload.payload,displayName:action.displayName}}
        case ActionTypes.CLEAR_DROPDOWN_VALUES:
            return {...state,seconddropdown:{...state.seconddropdown,dropdownvalues: {defaultValue:"",dropdownvalues:[]}}}
        case ActionTypes.SET_DROPDOWN_DEFAULT_VALUE:
            return {...state, dropdownDefaultValues: action.payload}
        case ActionTypes.SET_MEASURE_SELECTION:
            return {...state, selectedMeasureDisplayName: action.payload}
        case ActionTypes.SET_WORKFLOW:
            return {...state, workflow: action.payload}
        default:
            return state
    }

}

