import React, {useEffect} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {PatientAction} from "../../action/PatientAction";
import {isObject} from "lodash";
import _ from "lodash";

const PatientDropDownList = (props) => {
    const {value, defaultItemCode} = props
    const [data, setData] = React.useState([])
    const [filter, setFilter] = React.useState("")

    const dropdownRef = React.createRef()
    const [internalValue, setInternalValue] = React.useState(null)


    const filterChange = (event) => {
        setFilter(event.filter)
    };

    const initialSort = [
        {
            field: "lastName",
            dir: "asc",
        },
    ];

    const onSetDefaultValue = (value) => {
        if (props.onChange) {
            props.onChange({target: {value: value}});
        }
    };

    const handleChange = (e) => {
        if (e.value) {
            setInternalValue(e.value)
            if (e.value.patientRoleId) {
                if (props.onChange) {
                    props.onChange(e)
                }
            }
        }
    }
    React.useEffect(() => {
        if (props.patients?.length) {
            let data = props.patients.map((d) => {
                return {...d, patientName: d?.lastName + ', ' + d?.firstName + " - " + d?.dateOfBirth}
            })
            setData(data)
        }
    }, [props.patients])

    React.useEffect(() => {
            let filters = {}
            filters.searchAll = filter?.value
            filters.active = true;
            if (!value?.patientRoleId) {
                if (filter?.id)
                    filters.id = filter.id
            }
            props.PatientActions.getPatientList(50, 0, initialSort, filters)
        }
        ,
        [filter]
    )

    useEffect(() => {
        if (isObject(value)) {
            if (internalValue !== value)
                setInternalValue(value)
            return;
        }
        if (!internalValue?.patientRoleId && value) {
            let d = data ? data.find((c) => c.patientRoleId === value) : null

            if (!d) {
                setFilter({id: value, value: null})
            }
            if (d?.patientRoleId !== value?.patientRoleId) {
                setInternalValue(d)
            }
        } else if (!internalValue?.id && !_.isNil(defaultItemCode)) {
              let v = data ? data.find((c) => c.patientRoleId === defaultItemCode) : undefined
              if (!_.isNil(v)) {
                  onSetDefaultValue(v)
                  setInternalValue(v)
              }
        }


    }, [defaultItemCode, value, data])
    let DefaultVal = {patientName: "Please Select ..."};

    return (<>
        <DropDownList style={props.style}
                      ref={dropdownRef}
                      disabled={props.disabled}
                      data={data}
                      defaultItem={DefaultVal}
                      value={internalValue}
                      dataItemKey={"patientRoleId"}
                      textField={"patientName"}
                      filterable={true}
                      onFilterChange={filterChange}
                      onChange={handleChange}
        />

    </>)
}


function mapStateToProps(state) {
    return {
        patients: state.patient.patientList.patients,
        totalCount: state.patient.patientList.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientDropDownList)