import {Dialog} from "primereact/dialog";
import {connect, useDispatch} from "react-redux";
import React, {useCallback} from "react";
import {bindActionCreators} from "redux";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import FormPreviewWindow from "../form/FormRendererComponent";
import {FormAction, getFormSchemaList, setEditFormContext} from "../../action/FormAction";
import {SocialHistoryActions} from "../../action/SocialHistoryAction";
import {Button} from "primereact/button";
import {Container} from "../../layout/GridLayout";


const SocialHistoryWindow = (props) => {
    const code = 'ah_social_history';

    const dispatch = useDispatch();

    const hideDialog = async () => {
        props.PopupActions.hideWindow(PopupWindows.EDIT_SOCIAL_HISTORY)
        props.FormActions.clearFormContext();
        props.FormActions.setEditFormContext({});
    }

    const clearForm = () => {
        props.FormActions.clearFormContext();
        props.FormActions.setEditFormContext({});
        hideDialog();
    }

    const refreshForms = useCallback((sort, filter) =>
        dispatch(getFormSchemaList(100, 0, sort, filter)
        ), [dispatch]
    )

    function DisplayForm() {
        if (props?.formList?.payload) {
            //const payload = props?.formList?.payload.filter(item => item.code == code)[0];
            const payload = props.socialHistoryData;
            setFormContext(payload)
        } else {
            refreshForms()
        }

        if (props.editFormContext && Object.keys(props.editFormContext).length > 0) {
            return <FormPreviewWindow
                show={false}
                editFormContext={props.editFormContext}/>
        } else {
            return null;
        }
    }

    const setFormContext = useCallback((form) => dispatch(setEditFormContext(form)), [dispatch])

    function saveSocialHistoryForm(form, isDraft) {
        if (form) {
            let savePayload = {
                //schemaId: form.formSchemaId,
                //formHeaderId: form?.id,
                //formData: JSON.stringify(form.formData),

                patientRoleId: props.socialHistoryData?.patientRoleId || props?.patientContext?.patientRoleId,
                encounterId: props.socialHistoryData?.encounterId,
                dictSocialHistoryId: null,
                //createdDate:'',
                value: JSON.stringify(form.formData)
                //dictFormStatus: props.dictionaries['AH_FORM_STATUS'].find(v => isDraft ? v.code == "Draft" : v.code == "Complete").id
            };
            props.SocialHistoryActions.createSocialHistory(savePayload)
                .then(() => {
                    clearForm();
                    props.SocialHistoryActions.getSocialHistory(props.patientContext?.patientRoleId)
                    props.SocialHistoryActions.getSocialHistoryData(props.patientContext?.patientRoleId)
                    hideDialog();
                    //props.FormActions.getFormSchemaListByEncounterId(encounterContext?.id);
                    // props.FormActions.getFormSchemaListByEncounterId(encounterContext?.id).then(() => {
                    //     props.EncounterAction.getAllEncounterFormConfig({ dictEncounterTypeId: encounterType.id });
                    // });
                });
        }
    }


    return (
        <Dialog visible={props.showWindow[PopupWindows.EDIT_SOCIAL_HISTORY]}
                header={"Edit Social History"}
                maximizable
                footer={<Container className={"justify-content-end"}>
                    <Button label={"Save"}
                            severity={"success"}
                            onClick={() => {
                                if (props.editFormContext) {
                                    saveSocialHistoryForm(props.editFormContext, false)
                                }
                            }}/>

                    <Button label={"Cancel"}
                            severity={"danger"}
                            onClick={clearForm}/>

                </Container>}
                onHide={hideDialog}>
            {
                (Object.keys(props.socialHistoryData).length > 0) ?
                    <div style={{height: "100%", overflowY: "auto"}}>
                        <DisplayForm/>

                    </div> : <div> Form was not found </div>
            }

        </Dialog>
    );

}

function mapStateToProps(state) {
    return {
        showWindow: state.popupWindow.showWindow,
        editFormContext: state.form.editFormContext,
        formList: state.form.formList,
        socialHistoryData: state.patient.socialHistoryData,
        patientContext: state.patient.patientContext,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        FormActions: bindActionCreators(FormAction, dispatch),
        SocialHistoryActions: bindActionCreators(SocialHistoryActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialHistoryWindow);