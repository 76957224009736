import {getter, Typography} from "@progress/kendo-react-common";
import * as React from "react";
import {bindActionCreators} from "redux";
import {PopupWindowActions, PopupWindows,} from "../../action/PopupWindowAction";
import {useForm} from "react-hook-form";
import {EncounterAction} from "../../action/EncounterAction";
import {PatientAction} from "../../action/PatientAction";
import {ImmunizationActions} from "../../action/ImmunizationAction";
import {DictionaryDispatchAction} from "../../action/DictionaryDispatchAction";
import {BaseRequestAction} from "../../action/BaseRequestAction";
import {UserActions} from "../../action/UserAction";
import {Button} from "primereact/button"
import {Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {Slide} from "@progress/kendo-react-animation";
import {Dictionary} from "../InputControls/DictionaryDropDownList";
import DocumentViewerComponent from "../../components/PatientPanelComponents/DocumentsTab/DocumentViewerComponent";
import DocumentInfoComponent from "../../components/PatientPanelComponents/DocumentsTab/DocumentInfoComponent";
import {
    Field,
    FieldCheckboxInput,
    FieldColumn,
    FieldDatePicker,
    FieldDictionaryDropDown,
    FieldEditorInput,
    FieldRow,
    FieldSection,
    FieldUserDropDown
} from "../InputControls/FormFields";
import {ConditionsAssociatedEncounter} from "./EncounterComponents/ConditionsAssociatedEncounter";
import moment from "moment";
import {FormSelectorCombo} from "../InputControls/FormSelectorCombo/FormSelectorCombo";
import {Col, Container, Row} from "../../layout/GridLayout";
import FormPreviewWindow from "../form/FormRendererComponent";
import {FormAction} from "../../action/FormAction";
import {VeradigmRenderer, VeradigmSsoComponent} from "../shared/VeradigmRenderer";
import {Badge} from "@progress/kendo-react-indicators";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {PhixPermissions} from "../../config/GlobalConfig";
import {PermissionsTool} from "../../util/PermissionsTool";
import {Dialog} from "primereact/dialog";
import {BsCardList, BsPen} from "react-icons/bs";
import {FaPrescription, FaVideo} from "react-icons/fa6";
import {CiMedicalClipboard} from "react-icons/ci";
import {connect} from "react-redux";
import {GridLayout, GridLayoutItem} from "@progress/kendo-react-layout";
import {ConfirmDialog} from 'primereact/confirmdialog';


const ReasonArea = (props) => {
    return <FieldColumn>
        <Field label={"Reason for Encounter"} field={"reasonForEncounter"}
               error={props?.error}
               register={props?.register}
               control={props?.control}>
            <FieldEditorInput
                style={{height: '135px', width: '800px'}}
            />
        </Field>
    </FieldColumn>
}

const HPIArea = (props) => {
    return <FieldColumn>
        <Field label={"HPI"} field={"hpi"}
               error={props?.error}
               register={props?.register}
               control={props?.control}>
            <FieldEditorInput
                style={{height: '135px', width: '800px'}}
            />
        </Field>
    </FieldColumn>
}

const EncounterWindow = (props) => {

    const {
        handleSubmit,
        register,
        control,
        reset,
        setValue,
        watch,
        getValues,
        formState: {errors},
    } = useForm({defaultValues: {description: ""}});

    const {PatientActions, patient, ImmunizationAction} = props;

    const [encounterContext, setEncounterContext] = React.useState(props?.encounterContext)
    const [encounterCodingPermission, setEncounterCodingPermission] = React.useState({isEdit: false, isView: false})
    const [encounterPermission, setEncounterPermission] = React.useState({isEdit: false, isView: false})
    const [referralPermission, setReferralPermissions] = React.useState({isEdit: false, isView: false})
    const [requestPermission, setRequestPermissions] = React.useState({isEdit: false, isView: false})
    const [eprescribeFlag, setEprescribeFlag] = React.useState(false)
    const [show, setShow] = React.useState(true);
    const [formContext, setFormContext] = React.useState({})
    const [encounterType, setEncounterType] = React.useState(null);
    const [showUploadDocument, setShowUploadDocument] = React.useState(false);
    const [flag, setFlag] = React.useState(false);
    const [selectedDocument, setSelectedDocument] = React.useState("")
    const [recommendedForms, setRecommendedForms] = React.useState([])
    const [optionalForms, setOptionalForms] = React.useState([])
    const [isStartTimer, setIsStartTimer] = React.useState(false);
    const [isToast, setIsToast] = React.useState(false);
    const [message, setMessage] = React.useState("")
    const [filter, setFilter] = React.useState({})
    const [closeDialog, setCloseDialog] = React.useState(false)


    React.useEffect(() => {
        let encounter = props.encounterContext
        if (props.encounterContext?.id) {
            props.FormActions.getFormSchemaListByEncounterId(encounter?.id)
            props.PatientActions.getClinicalDocument({"encounterId": encounter.id});
            setEncounterContext({
                ...encounter,
                "dictDocumentCategoryId": props.dictionaries['AH_DOCUMENT_CATEGORIES']?.find(v => v.code === "AH_ENCOUNTER_DOCUMENTS")?.id
            });
            setValue("id", encounter?.id)
            if (encounter?.encounterTimer?.startTime) {
                setIsStartTimer(true);
            } else {
                setIsStartTimer(false);
            }
            props.EncounterAction.getEncounterCodingServiceLineByEncounterId({id: props.encounterContext?.id});
        } else {
            setEncounterContext({});
        }
        if (encounter.encounterDate) {
            setValue("encounterDate", moment(encounter?.encounterDate, "YYYY-MM-DD").toDate())
        } else {
            setValue("encounterDate", new Date());
        }
        setValue("dictEncounterTypeId", encounter?.dictEncounterTypeId)

        if (encounter.providerRoleId) {
            setValue("encounter_provider", encounter.providerRoleId)
        } else {
            setValue("encounter_provider", props.loggedinUser.userRoleId);
        }
        setValue("pos", encounter?.placeOfService)
        setValue("telehealth", encounter?.telehealth)
        setValue("reasonForEncounter", encounter?.reasonForEncounter);
        setValue("hpi", encounter?.hpi);
        setShow(true);
    }, [props.encounterContext]);


    React.useMemo(() => {
        setEncounterCodingPermission(PermissionsTool({
            permissionInfo: props.permissionInfo,
            viewPermissions: [PhixPermissions.AH_VIEW_ENCOUNTER_CODING],
            editPermissions: [PhixPermissions.AH_EDIT_ENCOUNTER_CODING]
        }));
        setEncounterPermission(PermissionsTool({
            permissionInfo: props.permissionInfo,
            viewPermissions: [PhixPermissions.AH_VIEW_ENCOUNTER],
            editPermissions: [PhixPermissions.AH_EDIT_ENCOUNTER]
        }));
        setReferralPermissions(PermissionsTool({
            permissionInfo: props.permissionInfo,
            viewPermissions: [PhixPermissions.AH_VIEW_REFERRALS],
            editPermissions: [PhixPermissions.AH_EDIT_REFERRALS]
        }));
        setRequestPermissions(PermissionsTool({
            permissionInfo: props.permissionInfo,
            viewPermissions: [PhixPermissions.AH_VIEW_REQUEST],
            editPermissions: [PhixPermissions.AH_EDIT_REQUEST]
        }));
    }, [props?.permissionInfo]);

    React.useEffect(() => {
        if (patient?.patientRoleId) {
            setFilter({...filter, patientRoleId: patient?.patientRoleId})
            PatientActions.getMedicalProblems(patient?.patientRoleId);
            PatientActions.getMedications(patient?.patientRoleId);
            PatientActions.getAllergies(patient?.patientRoleId);
            PatientActions.getHospitalization(patient?.patientRoleId);
            ImmunizationAction.getImmunization({"patientRoleId": patient.patientRoleId})
        }
    }, [patient]);

    React.useEffect(() => {
        props.DictionaryActions.getDictionaryByCode("AH_DOCUMENT_CATEGORIES");
        props.DictionaryActions.getDictionaryByCode("AH_ENCOUNTER_STATUS");
        props.DictionaryActions.getDictionaryByCode("AH_FORM_STATUS");
        props.DictionaryActions.getDictionaryByCode("AH_ENCOUNTER_TYPE");
    }, []);


    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });
    const [sort, setSort] = React.useState([{
        field: "displayName",
        dir: "asc",
    }]);

    React.useEffect(() => {
        if (encounterType?.id) props.EncounterAction.getAllEncounterFormConfig({
            dictEncounterTypeId: encounterType.id,
            patientRoleId: props?.patient?.patientRoleId
        })
    }, [encounterType]);

    React.useEffect(() => {
        props.FormActions.getAdminFormSchemaList(dataState.take, dataState.skip, sort, filter)
        props.FormActions.getFormSchemaList(dataState.take, dataState.skip, sort, filter)
    }, [dataState, sort, filter])

    React.useEffect(() => {
        if (props.encounterFormConfig.length > 0) {
            const recIds = props.encounterFormConfig[0]?.recommendedForms.map(v => v.id);
            const optIds = props.encounterFormConfig[0]?.optionalForms.map(v => v.id);
            if (encounterContext && encounterContext.dictEncounterStatusCode === "Signed" && encounterContext.forms && encounterContext.forms.length > 0) {
                const recommendedCodes = encounterContext.forms[0]?.recommended.map(v => props.adminFormList.find(et => et.id == v));
                setRecommendedForms([...recommendedCodes.map(v => {
                    const ele = props.formElements.find(e => e?.code === v?.code);
                    if (ele) {
                        return ele;
                    } else {
                        return v;
                    }
                })]);
                const optionalCodes = encounterContext.forms[0]?.optional.map(v => props.adminFormList.find(et => et.id == v))
                setOptionalForms([...optionalCodes.map(v => {
                    const ele = props.formElements.find(e => e?.code === v?.code);
                    if (ele) {
                        return ele;
                    } else {
                        return v;
                    }
                })]);
            } else {
                setRecommendedForms([...props.encounterFormConfig[0]?.recommendedForms.map(v => {
                    const ele = props.formElements.find(e => e?.code === v?.code);
                    if (ele) {
                        return ele;
                    } else {
                        return v;
                    }
                })]);
                setOptionalForms([...props.encounterFormConfig[0]?.optionalForms.map(v => {
                    const ele = props.formElements.find(e => e?.code === v?.code);
                    if (ele) {
                        return ele;
                    } else {
                        return v;
                    }
                })]);
            }

        } else {
            clearForm();
            setRecommendedForms([])
            setOptionalForms([])
        }
    }, [props.encounterFormConfig]);

    React.useEffect(() => {
        if (!props.clinicalDocumentContext?.setIsToast)
            props.PatientActions.setClinicalDocumentContext({
                ...props.clinicalDocumentContext,
                setMessage: setMessage,
                setIsToast: setIsToast
            })
    }, [props.clinicalDocumentContext])

    const inputFileRef = React.useRef(null);

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener(
                "load",
                () => {
                    resolve(reader?.result)
                }, false)
        })
    }

    const fileValidation = (file) => {
        if (file.length > 0) {
            for (const i = 0; i <= file.length - 1; i++) {
                const fileSize = file.item(i).size;
                const file = Math.round(fileSize / 1024);
                if (file >= (1024 * 20)) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    };

    React.useEffect(() => {
        if (encounterContext?.id) props.PatientActions.getClinicalDocument({"encounterId": encounterContext.id});
        props.FormActions.getFormSchemaList(dataState.take, dataState.skip, sort, filter);
        props.FormActions.getAdminFormSchemaList(dataState.take, dataState.skip, sort, filter)
    }, [encounterType]);

    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'dictEncounterTypeId') {
                let v = value?.dictEncounterTypeId?.displayName;
                if (v) {
                    setEncounterType(value?.dictEncounterTypeId);
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    const [selectedState, setSelectedState] = React.useState({
        "medications": {},
        "allergyProblem": {},
        "hospitalization": {},
        "medicalProblem": {},
        "immunizations": {},
    });

    React.useEffect(() => {
        if (props.popupState[PopupWindows.ENCOUNTER_WINDOW]?.dictEncounterTypeId)
            setValue("dictEncounterTypeId", props.popupState[PopupWindows.ENCOUNTER_WINDOW].dictEncounterTypeId)
    }, [props.popupState])

    React.useEffect(() => {
        if (props?.medicalProblemId?.id && props.showWindow[PopupWindows.ENCOUNTER_WINDOW]) {
            setSelectedState({
                ...selectedState,
                medicalProblem: {...selectedState.medicalProblem, [props?.medicalProblemId?.id]: true}
            })
        }
    }, [props?.medicalProblemId?.id])

    React.useEffect(() => {
        if (props?.medicationId?.id && props.showWindow[PopupWindows.ENCOUNTER_WINDOW]) {
            setSelectedState({
                ...selectedState,
                medications: {...selectedState.medications, [props?.medicationId?.id]: true}
            })
        }
    }, [props?.medicationId?.id])

    React.useEffect(() => {
        if (props?.allergyId?.id && props.showWindow[PopupWindows.ENCOUNTER_WINDOW]) {
            setSelectedState({
                ...selectedState,
                allergyProblem: {...selectedState.allergyProblem, [props?.allergyId?.id]: true}
            })
        }
    }, [props?.allergyId?.id])

    React.useEffect(() => {
        if (props?.immunizationDataNew?.id && props.showWindow[PopupWindows.ENCOUNTER_WINDOW]) {
            setSelectedState({
                ...selectedState,
                immunizations: {...selectedState.immunizations, [props?.immunizationDataNew?.id]: true}
            })
        }
    }, [props?.immunizationDataNew?.id])

    React.useEffect(() => {
        if (props?.newHospitalizationId?.id && props.showWindow[PopupWindows.ENCOUNTER_WINDOW]) {
            setSelectedState({
                ...selectedState,
                hospitalization: {...selectedState.hospitalization, [props?.newHospitalizationId?.id]: true}
            })
        }
    }, [props?.newHospitalizationId?.id])

    const radioGroupValidator = (value) =>
        !value ? "Type of Confirmation is required" : "";

    function startTimer(e) {
        e.preventDefault();
        if (encounterContext?.id && !encounterContext?.encounterTimer?.startTime) {
            const payload = {
                encounterId: encounterContext?.id
            }
            props.EncounterAction.setStartTimerEncounter(payload);
        }
    }

    function endTimer(e) {
        if (e) e.preventDefault();
        if (encounterContext?.id && encounterContext?.encounterTimer?.startTime && !encounterContext?.encounterTimer?.endTime) {
            const payload = {
                encounterId: encounterContext?.id
            }
            props.EncounterAction.setEndTimerEncounter(payload);
        }
    }

    const hideDialog = async () => {
        setCloseDialog(false);
        if (encounterContext?.id && encounterContext?.encounterTimer?.startTime) {
            alert("Please stop encounter timer.")
        } else {
            setSelectedState({
                "medications": {},
                "allergyProblem": {},
                "hospitalization": {},
                "medicalProblem": {},
                "immunizations": {}
            });
            setEncounterContext(null);
            setSelectedDocument(null);
            setEncounterType(null);
            props.PatientActions.clearClinicalDocumentContext();
            props.PatientActions.clearGetClinicalDocument();
            props.EncounterAction.clearEncounterContext();
            props.EncounterAction.clearCreatedIssues();
            props.EncounterAction.clearEncounterFormConfig();
            props.FormActions.clearFormContext();
            props.FormActions.clearFormElements();
            props.BaseRequestAction.clearContext();
            if (props?.patient?.patientRoleId)
                props.PatientActions.getEncounterList(props.patient.patientRoleId, 50, 0)
            if (encounterContext?.patientRoleId)
                props.PatientActions.getEncounterList(encounterContext.patientRoleId, 50, 0)
            await reset({
                id: null,
                encounterDate: null,
                dictEncounterTypeId: null,
                encounter_provider: null,
                pos: null,
            });
            props.PopupActions.hideWindow(PopupWindows.ENCOUNTER_WINDOW);
            props.PopupActions.hideWindow(PopupWindows.SIGN_ENCOUNTER_WINDOW);
        }
    };


    React.useEffect(() => {
        if (props?.encounterSignedStatus === true) {
            hideDialog()
        }
    }, [props?.encounterSignedStatus])

    const clearForm = () => {
        props.FormActions.clearFormContext();
        props.FormActions.setEditFormContext({});
    }

    const isAnyFormInDraftMode = () => {
        let status = props.dictionaries['AH_FORM_STATUS'].find(v => v.code == "Draft");
        const isFormDraft = props.formElements.find(v => v.dictFormStatus === status?.id);
        if (isFormDraft && (encounterContext && encounterContext.dictEncounterStatusCode !== "Signed")) {
            const isOk = window.confirm("The are incomplete forms in this encounter. Continue?");
            return isOk;
        } else {
            return true;
        }
    }

    const recommendedFormsCheckBeforeESign = () => {
        const existingForms = props.formElements.map(v => v.code);
        const isRecommended = recommendedForms.every(r => existingForms.indexOf(r.code) > -1);
        if (!isRecommended && (encounterContext && encounterContext.dictEncounterStatusCode !== "Signed")) {
            const isOk = window.confirm("There are recommended/required forms not included in this encounter. Continue?");
            return isOk;
        } else {
            return true;
        }
    }

    const gotoSignEncounter = () => {
        if (encounterContext?.id && encounterContext?.encounterTimer?.startTime) {
            alert("Please stop encounter timer.");
        } else {
            saveEncounters(getValues()).then(() => {
                if (recommendedFormsCheckBeforeESign() && isAnyFormInDraftMode()) {
                    props.PopupActions.showWindow(PopupWindows.SIGN_ENCOUNTER_WINDOW, {signEncounterContext: encounterContext ? encounterContext : {}});
                }
            });
        }
    }

    const isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    const numberOfDaysOld = (date) => {
        if (isToday(new Date(date))) {
            return 0;
        } else {
            let today = new Date();
            let compare = new Date(date);
            const diffTime = Math.abs(compare - today);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        }
    }

    const unlockIconCell = (encounter) => {
        const days = numberOfDaysOld(encounter.encounterDate);
        const icon = (days >= 1 && days < 3) ? <span style={{color: '#E69138'}} className="k-icon k-i-unlock"/> :
            (days >= 3) ? <span style={{color: '#E06666'}} className="k-icon k-i-unlock"/> :
                <span style={{color: '#999999'}} className="k-icon k-i-unlock"/>;
        return icon;
    }

    const encounterDateCell = (encounter) => {
        return (<td>{`${encounter?.encounterDate.split("T")[0]}`}</td>)
    }
    const lockIconCell = (encounter) => {
        const icon = encounter.isLocked ? (
            <span style={{color: '#6AA84F'}} className="k-icon k-i-lock"/>
        ) : (
            unlockIconCell(encounter)
        );
        const style = {
            color: encounter.isLocked
                ? "rgb(243, 23, 0, 0.32)"
                : "rgb(55, 180, 0,0.32)",
        };

        return (
            <td>
                <span>{icon}</span>
            </td>
        );
    }

    async function submitEprescribe(e) {
        if (patient?.patientRoleId) {
            await props.UserActions.getSAMLValuePatientContext({patientRoleId: patient?.patientRoleId});
            setEprescribeFlag(true);
        }
    }

    const encounterUserHeader = () => {
        let pFirstName = encounterContext?.patientFirstName || (props?.patient?.firstName ? props.patient.firstName : "");
        let pLastName = encounterContext?.patientLastName || (props?.patient?.lastName ? props.patient.lastName : "");
        let pMiddleName = encounterContext?.patientMiddleName || (props?.patient?.middleName ? props.patient.middleName : "");

        let patientName = pFirstName + " " + pMiddleName + " " + pLastName;
        let dos = encounterContext && encounterContext.encounterDate ? new Date(encounterContext.encounterDate).toLocaleDateString('en-US') : new Date().toLocaleDateString('en-US');
        let provideName = (encounterContext && encounterContext.providerFirstName ? encounterContext.providerFirstName : props.loggedinUser.firstName) +
            " " + (encounterContext && encounterContext.providerLastName ? encounterContext.providerLastName : props.loggedinUser.lastName);
        let providerCredentials = encounterContext && encounterContext.providerCredentials ? encounterContext.providerCredentials : "";

        return (<>
            <ToolbarItem> <span
                style={{fontWeight: "bold", color: "#7585C2"}}> {encounterType?.displayName} </span></ToolbarItem>
            <ToolbarItem><span
                style={{color: "#7585C2"}}> {encounterType?.displayName ? "for Patient: " : "Patient:"} &nbsp; </span></ToolbarItem>
            <ToolbarItem><span style={{color: "#7585C2"}}> {patientName} </span></ToolbarItem>
            <ToolbarItem><span style={{
                fontWeight: "bold",
                color: "#7585C2"
            }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DOS: </span></ToolbarItem>
            <ToolbarItem><span style={{color: "#7585C2"}}> {dos} </span></ToolbarItem>
            <ToolbarItem><span style={{
                fontWeight: "bold",
                color: "#7585C2"
            }}> &nbsp;&nbsp;&nbsp;&nbsp; Provider:  </span></ToolbarItem>
            <ToolbarItem><span style={{color: "#7585C2"}}> {provideName} </span></ToolbarItem>
            <ToolbarItem><span style={{color: "#7585C2"}}> &nbsp; {providerCredentials} </span></ToolbarItem>
        </>);

    }
    const EncounterHeader = () => {
        return (<Toolbar style={{background: "#DDE0EE"}}>
            {encounterUserHeader()}
            <ToolbarSpacer/>
            {encounterContext ? <ToolbarItem><span>{lockIconCell(encounterContext)}</span></ToolbarItem> :
                <span style={{color: '#999999'}} className="k-icon k-i-unlock"/>}
            <ToolbarItem>
                <Button severity={"secondary"}
                        disabled={encounterContext?.isLocked}
                        onClick={() =>
                            saveEncountersForClose().then(() => {
                                setCloseDialog(false)
                                hideDialog();
                            })
                        }> Save and Close
                </Button>
            </ToolbarItem>
            <ToolbarItem>
                <Button severity={"secondary"}
                        disabled={encounterContext?.isLocked}
                        onClick={handleSubmit(saveEncounters)}> Save as Draft
                </Button>
            </ToolbarItem>
            <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}>
                <ToolbarItem>
                    <Button severity={"success"}
                            label={"e-Sign"}
                            icon={BsPen}
                            onClick={gotoSignEncounter}/>
                </ToolbarItem>
            </ShowForPermissionComponent>
        </Toolbar>);
    }

    const idGetter = getter("id");

    const slideElements = () => {
        setShow(!show);
    };

    const fieldRows = () => {
        return <FieldRow>
            <Field col={2} label={"Encounter Type"} field={"dictEncounterTypeId"}
                   value="Encounter Type"
                   error={errors}
                   register={register}
                   control={control}>
                <FieldDictionaryDropDown
                    code={Dictionary.ENCOUNTER_TYPE}
                    active={true}
                    defaultItemCode={"AH_ENCOUNTER_TYPE"}
                    setDefaultValue={{displayName: "Select encounter type"}}
                />
            </Field>
            <Field col={2} label={"Date of Service"} field={"encounterDate"} error={errors}
                   register={register}
                   control={control}
                   options={{required: "Date is required"}}>
                <FieldDatePicker/>
            </Field>
            <Field col={2} label={"Provider"} field={"encounter_provider"} error={errors}
                   control={control}
                   register={register}
                   options={{required: "Assigned provider is required"}}>
                <FieldUserDropDown/>
            </Field>
            <Field col={2} label={"Place Of Service"} field={"pos"}
                   value="Place Of Service"
                   error={errors}
                   register={register}
                   control={control}>
                <FieldDictionaryDropDown
                    code={Dictionary.PLACE_OF_SERVICE}
                    defaultItemCode={"AH_POS"}
                />
            </Field>
            <Field col={2} label={"Telehealth"} field={"telehealth"}
                   error={errors}
                   register={register}
                   control={control}>
                <FieldCheckboxInput style={{marginBottom: "2%"}}/>
            </Field>
        </FieldRow>

    }

    const isDisableTaskButton = () => {
        if (referralPermission.isEdit || requestPermission.isEdit) {
            return false
        } else if (referralPermission.isView || requestPermission.isView) {
            return true;
        }
        return false;
    }


    function EncounterToolBar() {
        return <Row style={{border: "solid 2px #DDDDDD", margin: '0', height: "100%"}}>
            <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}>
                <Col style={{borderRight: "solid 2px #DDDDDD", margin: '10px', padding: '0px 0px'}}>
                    &nbsp;
                    <Button style={{
                        background: "#7585C2",
                        border: "none",
                        pointerEvents: !encounterContext?.isLocked && encounterPermission.isEdit ? undefined : 'none'
                    }}
                            icon="pi pi-clock"
                            label={"Start Timer"}
                            onClick={(e) => {
                                startTimer(e)
                            }}
                            disabled={isStartTimer}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button style={{
                        background: "#E06666",
                        border: "none",
                        pointerEvents: !encounterContext?.isLocked && encounterPermission.isEdit ? undefined : 'none'
                    }}
                            icon="pi pi-clock"
                            label={"End Timer"}
                            onClick={(e) => {
                                endTimer(e)
                            }}/>

                </Col>
            </ShowForPermissionComponent>
            <Col style={{
                borderRight: "solid 2px #DDDDDD",
                margin: '10px',
                flex: "1 0 0px",
                textAlign: "center",
                padding: '0px 0px'
            }}>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_ANALYTICS]}>
                    <Button style={{
                        background: "#7585C2",
                        pointerEvents: !encounterContext?.isLocked && encounterPermission.isView && encounterPermission.isEdit ? undefined : 'none'
                    }} icon="aggregate-fields"
                            onClick={() => {
                                props.PopupActions.showWindow(PopupWindows.CLINICAL_TRIAL_ELIGIBILITY_PATIENT_WINDOW)
                            }}>Clinical Trials
                        <Badge shape={"dot"} themeColor={"error"}>2</Badge>
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </ShowForPermissionComponent>
                {props?.loggedinUser?.telehealthAddress &&
                    <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}><Button
                        icon={<FaVideo style={{marginRight: 5}}/>} label="Telehealth"
                        style={{background: "#7585C2", border: "none"}}
                        onClick={() => {
                            if (props?.tenantTelehealthAddress) {
                                window.open(props?.tenantTelehealthAddress);
                                setValue("telehealth", true);
                            }
                        }}>
                    </Button></ShowForPermissionComponent>}
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_ENCOUNTER_CODING]}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button icon={<CiMedicalClipboard style={{marginRight: 5}}/>} label="Coding"
                            style={{background: "#7585C2", border: "none"}}
                            onClick={() => {
                                if (props?.encounterContext?.id) {
                                    props.EncounterAction.setEncounterContext({
                                        ...encounterContext,
                                        isAdmin: false
                                    });
                                    props.PopupActions.showWindow(PopupWindows.ENCOUNTER_CODING_WINDOW)
                                }
                            }}>
                    </Button>
                </ShowForPermissionComponent>
            </Col>
            <Col style={{
                textAlign: "center",
                flex: "1 0 0px",
                margin: '6px',
                padding: '0px 0px'
            }}>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}>
                    <Button style={{
                        background: "#7585C2",
                        border: "none"
                    }}
                            icon={BsCardList}
                            primary={true}
                            onClick={(e) => {
                                props.PopupActions.showWindow(PopupWindows.ADD_ENCOUNTER_PATIENT_INSTRUCTIONS)
                            }}>
                        &nbsp;Instructions
                    </Button>
                </ShowForPermissionComponent>
                {patient?.eprescribePatient === true ? (
                    <>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}>
                            <Button style={{
                                background: "#7585C2",
                                border: "none",
                                pointerEvents: !encounterContext?.isLocked && encounterPermission.isEdit ? undefined : 'none'
                            }}
                                    icon={FaPrescription}
                                    primary={true}
                                    onClick={(e) => {
                                        submitEprescribe(e)
                                    }}>
                                &nbsp;ePrescribe
                            </Button>
                        </ShowForPermissionComponent></>
                ) : <></>}
                {(referralPermission.isView || requestPermission.isView || referralPermission.isEdit || requestPermission.isEdit) && <>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button style={{
                        background: "#7585C2",
                        border: "none",
                        pointerEvents: isDisableTaskButton() || encounterContext?.isLocked ? 'none' : undefined
                    }}
                            icon="pi pi-check-circle"
                            label={"Tasks"}
                            onClick={() => {
                                props.PopupActions.showWindow(PopupWindows.CREATE_REQUEST_WINDOW)
                            }}/></>}
            </Col>
        </Row>
    }

    const sliderSection = () => {
        return <>
            <div
                style={{pointerEvents: !encounterContext?.isLocked && encounterPermission.isView && encounterPermission.isEdit ? undefined : 'none'}}>
                {fieldRows()}
            </div>
            <div>
                <GridLayout gap={{rows: 2, cols: 6}}>
                    <GridLayoutItem row={1} col={1}>
                        <div
                            style={{pointerEvents: !encounterContext?.isLocked && encounterPermission.isView && encounterPermission.isEdit ? undefined : 'none'}}>
                            <ReasonArea
                                error={errors}
                                register={register}
                                control={control}/>
                            <HPIArea
                                error={errors}
                                register={register}
                                control={control}/>
                        </div>
                    </GridLayoutItem>
                    <GridLayoutItem row={1} col={6}>
                        <ConditionsAssociatedEncounter
                            selectedState={selectedState}
                            setSelectedState={setSelectedState}
                            disabled={!encounterContext?.isLocked && encounterPermission.isView && encounterPermission.isEdit}
                        />
                    </GridLayoutItem>
                </GridLayout>
            </div>
        </>
    }

    const children = show ? sliderSection() : null;

    const childFactory = (child) => {
        if (!child.props.in) {
            return null;
        }
        return child;
    };

    async function saveEncountersForClose() {
        const form = getValues();
        if (form.dictEncounterTypeId && form.encounterDate && form.encounter_provider) {
            await saveEncounters(form)
        }
        return;
    }

    async function saveEncounters(form) {
        if (!(form.dictEncounterTypeId && form.encounterDate && form.encounter_provider)) {
            alert("Complete the required fields");
        } else {
            if (form?.id) {
                let savePayload = {
                    id: form?.id,
                    dictEncounterTypeId: (form.dictEncounterTypeId && form.dictEncounterTypeId.id) ? form.dictEncounterTypeId.id : form.dictEncounterTypeId,
                    encounterDate: form?.encounterDate.toISOString(),
                    patientRoleId: encounterContext?.patientRoleId || props?.patient?.patientRoleId,
                    providerRoleId: (form.encounter_provider && form.encounter_provider.userRoleId) ? form.encounter_provider.userRoleId : form.encounter_provider,
                    placeOfService: (form.pos && form.pos.id) ? form.pos.id : form.pos,
                    reasonForEncounter: form?.reasonForEncounter,
                    hpi: form?.hpi,
                    telehealth: form?.telehealth,
                    isLocked: encounterContext?.isLocked,
                    dictEncounterStatusId: encounterContext?.dictEncounterStatusId || props.dictionaries['AH_ENCOUNTER_STATUS'].find(v => v.code == "Draft").id
                };
                for (let key in selectedState) {
                    savePayload[key] = [];
                    for (let id in selectedState[key]) {
                        if (selectedState[key][id] && id != key)
                            savePayload[key].push(id);
                    }
                }
                await props.EncounterAction.updateEncounter(savePayload);
                if (props?.patient?.patientRoleId)
                    await props.PatientActions.getEncounterList(props.patient.patientRoleId, 50, 0)
            } else {
                let savePayload = {
                    dictEncounterTypeId: (form.dictEncounterTypeId && form.dictEncounterTypeId.id) ? form.dictEncounterTypeId.id : form.dictEncounterTypeId,
                    encounterDate: form?.encounterDate.toISOString(),
                    patientRoleId: encounterContext?.patientRoleId || props?.patient?.patientRoleId,
                    providerRoleId: (form.encounter_provider && form.encounter_provider.userRoleId) ? form.encounter_provider.userRoleId : form.encounter_provider,
                    placeOfService: (form.pos && form.pos.id) ? form.pos.id : form.pos,
                    reasonForEncounter: form?.reasonForEncounter,
                    hpi: form?.hpi,
                    telehealth: form?.telehealth,
                    dictEncounterStatusId: props.dictionaries['AH_ENCOUNTER_STATUS'].find(v => v.code == "Draft").id
                };
                for (let key in selectedState) {
                    savePayload[key] = [];
                    for (let id in selectedState[key]) {
                        if (selectedState[key][id] && id != key)
                            savePayload[key].push(id);
                    }
                }
                await props.EncounterAction.saveEncounter(savePayload);
                if (props?.patient?.patientRoleId)
                    await props.PatientActions.getEncounterList(props.patient.patientRoleId, 50, 0)
            }
        }
    }

    const saveEncounterCoding = () => {
        if (encounterContext?.id) {
            if (encounterContext.hasOwnProperty("encounterCodingSaved")) {
                props.EncounterAction.saveEncounterCodingByEncounterId({
                    id: encounterContext?.id,
                    save: !encounterContext.encounterCodingSaved
                }).then(() => {
                    props.EncounterAction.getEncounter({id: encounterContext?.id});
                });
            }
        }
    }

    function saveEncounterForm(form, isDraft) {
        if (form && encounterContext && encounterContext.id) {
            let savePayload = {
                schemaId: form.formSchemaId,
                formHeaderId: form?.id,
                formData: JSON.stringify(form.formData),
                patientRoleId: encounterContext?.patientRoleId || props?.patient?.patientRoleId,
                encounterId: encounterContext.id,
                dictFormStatus: props.dictionaries['AH_FORM_STATUS'].find(v => isDraft ? v.code === "Draft" : v.code === "Complete").id
            };
            props.FormActions.saveEncounterFormSchema(savePayload)
                .then(() => {
                    clearForm();
                    props.FormActions.getFormSchemaListByEncounterId(encounterContext?.id).then(() => {
                        props.EncounterAction.getAllEncounterFormConfig({
                            dictEncounterTypeId: encounterType.id,
                            patientRoleId: props?.patient?.patientRoleId
                        });
                    });
                });
        }
    }

    function deleteEncounterForm(form) {
        if (form && encounterContext && encounterContext.id) {
            let savePayload = {
                formHeaderId: form?.id
            };
            props.FormActions.deleteEncounterFormSchema(savePayload)
                .then(() => {
                    clearForm()
                    props.FormActions.getFormSchemaListByEncounterId(encounterContext?.id)
                    props.EncounterAction.getAllEncounterFormConfig({
                        dictEncounterTypeId: encounterType.id,
                        patientRoleId: props?.patient?.patientRoleId
                    })
                })

        }
    }

    function setIsDisable() {
        if (props.editFormContext) {
            let status = props.dictionaries['AH_FORM_STATUS'].find(v => v.id == props.editFormContext.dictFormStatus);
            if (status && status.code == "Complete") {
                return true;
            } else {
                return false;
            }
        } else {
            return false
        }

    }

    function deactivateDocument(clinicalDocument) {
        if (window.confirm("Are you sure you want to delete this document ?")) {
            setSelectedDocument(null);
            props.PatientActions.deleteClinicalDocument(
                {
                    "clinicalDocumentId": clinicalDocument.clinicalDocumentId,
                    "dictDocumentCategoryId": props.dictionaries['AH_DOCUMENT_CATEGORIES']?.find(v => v.code == "AH_ENCOUNTER_DOCUMENTS")?.id
                }).then(() => {
                props.PatientActions.getClinicalDocument({"encounterId": encounterContext.id});
            })
        }
    }

    function DisplayForm() {
        if (props.editFormContext && Object.keys(props.editFormContext).length > 0) {
            setSelectedDocument(null);
            return <FormPreviewWindow
                show={(encounterContext && encounterContext.isLocked) || (!encounterPermission.isEdit && encounterPermission.isView)}
                editFormContext={props.editFormContext}/>
        } else {
            return null;
        }
    }

    function DisplayFormElements(p) {
        return p.forms.length > 0 ? p.forms.map((e) => {
            return (<Row key={e.id}>
                <Col col={2}>
                    {
                        props.formElements.find(v => v.code === e.code) ?
                            (props.dictionaries['AH_FORM_STATUS'].find(v => v.id === e.dictFormStatus)?.code === "Complete" ?
                                <span style={{color: '#6AA84F'}}
                                      className="k-icon k-i-checkmark-circle"/> :
                                <span style={{color: 'rgb(251,194,50)'}}
                                      className="k-icon k-i-checkmark-circle"/>) :
                            (p.isOptional ? <span className="k-icon k-i-shape-circle"/> :
                                <svg width="15" height="15" fill="red" className="bi bi-exclamation-circle-fill"
                                     viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                </svg>)
                    }
                </Col>
                <Col>
                    <Button text
                            onClick={(event) => {
                                setSelectedDocument(null);
                                const ele = props.formElements.find(v => v.code === e.code);
                                if (ele) {
                                    setFormContext(ele)
                                    props.FormActions.setEditFormContext(ele)
                                } else {
                                    setFormContext(e)
                                    props.FormActions.setEditFormContext(e)
                                }
                            }}>
                        {e.displayName}
                    </Button>
                </Col>
                <Col col={2}>
                    {
                        props.formElements.find(v => v.code === e.code) ?
                            <span class="k-icon k-i-delete" onClick={(event) => {
                                const ele = props.formElements.find(v => v.code === e.code);
                                ele ? deleteEncounterForm(ele) : deleteEncounterForm(e)
                            }}></span> : <></>
                    }
                </Col>
            </Row>)
        }) : <></>;

    }

    function formContainer() {
        return <Container fluid style={{height: "calc(100% - 200px)"}}>
            <Row style={{height: "100%"}}>
                <Col col={3} style={{height: "100%", overflowY: "auto"}}>
                    <Container>
                        <div>
                            <Row>
                                <Col>
                                    <Typography.h5>Included Elements</Typography.h5>
                                </Col>
                            </Row>
                            <div
                                style={encounterPermission.isView && encounterPermission.isEdit ? {} : {pointerEvents: 'none'}}>
                                <Row>
                                    <Col col={10}>
                                        <span style={{textDecoration: "underline"}}>Documents</span>
                                    </Col>
                                    <Col style={{pointerEvents: encounterContext.isLocked ? 'none' : 'block'}}>
                                        <i className="fa fa-upload" onClick={(e) => {
                                            setShowUploadDocument(true);
                                            setTimeout(() => {
                                                if (encounterContext && encounterContext.id) {
                                                    props.EncounterAction.setEncounterContext({
                                                        ...encounterContext,
                                                        "dictDocumentCategoryId": props.dictionaries['AH_DOCUMENT_CATEGORIES']?.find(v => v.code === "AH_ENCOUNTER_DOCUMENTS")?.id
                                                    }).then(() => {
                                                        props.PopupActions.showWindow(PopupWindows.CREATE_DOCUMENT_WINDOW, {
                                                            encounterId: encounterContext.id,
                                                            documentTypeCode: "AH_ENCOUNTER_DOCUMENTS"
                                                        });
                                                    })

                                                }
                                            }, 500);
                                        }} style={{fontSize: "1rem", cursor: "pointer"}}
                                           aria-hidden="true"></i>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col>
                                        {props.clinicalDocuments ? props.clinicalDocuments.map((e) => {
                                            return (<Row key={e.clinicalDocumentId}>
                                                <Col col={1}>
                                                    <span style={{color: '#999999'}}
                                                          className={iconClassName(e.fileName)}/>
                                                </Col>
                                                <Col>
                                                    <Button text
                                                            onClick={(event) => {
                                                                props.PatientActions.setClinicalDocumentContext({
                                                                    ...e,
                                                                    setMessage: setMessage,
                                                                    setIsToast: setIsToast
                                                                })
                                                                clearForm();
                                                                setSelectedDocument(e);
                                                            }}
                                                            label={e.displayName}/>
                                                </Col>
                                                <Col col={2}>
                                                    <span class="k-icon k-i-delete" onClick={(event) => {
                                                        deactivateDocument(e)
                                                    }}></span>
                                                </Col>
                                            </Row>)
                                        }) : null}
                                    </Col>
                                </Row>
                            </div>
                            <br></br>
                            <hr></hr>
                            <div>
                                <Row>
                                    <Col col={10}>
                                        <span style={{textDecoration: "underline"}}>Recommended Forms</span>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col>
                                        <DisplayFormElements forms={recommendedForms} isOptional={false}/>
                                    </Col>
                                </Row>
                                <br></br>
                                <hr></hr>
                                <Row>
                                    <Col col={10}>
                                        <span style={{textDecoration: "underline"}}>Optional Forms</span>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col>
                                        <DisplayFormElements forms={optionalForms} isOptional={true}/>
                                    </Col>
                                </Row>
                                <br></br>
                                <hr></hr>
                                <Row>
                                    <Col>
                                        <span style={{textDecoration: "underline"}}>Additional Forms</span>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col>
                                        {props.formElements.filter(v => !(recommendedForms.find(r => r.code === v.code) || optionalForms.find(o => o.code === v.code))).map((e) => {
                                            return (<Row key={e.id}>
                                                <Col col={2}>
                                                    {
                                                        props.dictionaries['AH_FORM_STATUS'].find(v => v.id === e.dictFormStatus)?.code === "Complete" ?
                                                            <span style={{color: '#6AA84F'}}
                                                                  className="k-icon  k-i-checkmark-circle"/> :
                                                            <span style={{color: 'rgb(251,194,50)'}}
                                                                  className="k-icon k-i-check-circle k-i-checkmark-circle"/>
                                                    }

                                                </Col>
                                                <Col>
                                                    <Button text
                                                            label={e.displayName}
                                                            onClick={(event) => {
                                                                setSelectedDocument(null);
                                                                setFormContext(e)
                                                                props.FormActions.setEditFormContext(e)
                                                            }}/>


                                                </Col>
                                                <Col col={2}>
                                                    <span class="k-icon k-i-delete" onClick={(event) => {
                                                        deleteEncounterForm(e)
                                                    }}></span>
                                                </Col>
                                            </Row>)
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{pointerEvents: encounterContext.isLocked ? 'none' : 'block'}}>
                                        <FormSelectorCombo/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                </Col>
                {selectedDocument ?
                    <Col col={9}>
                        <Container>
                            <Row>
                                <Col col={12} style={{marginLeft: "78%", marginBottom: "1%"}}>
                                    <span> This Document: </span>
                                    <Button style={{background: "#E06666"}} text="Image"
                                            onClick={() => {
                                                setSelectedDocument(null)
                                            }}>
                                        Clear
                                    </Button>
                                </Col>
                                <Col col={12} style={{border: "1px solid #f1eded",}}>
                                    <DocumentInfoComponent setFlag={setFlag} flag={flag}
                                                           selectedDocument={selectedDocument}
                                                           setSelectedDocument={setSelectedDocument}/>
                                </Col>
                                <Col col={12} style={{
                                    border: "1px solid #f1eded",
                                    height: "100vh",
                                    marginTop: "20px"
                                }}>
                                    <DocumentViewerComponent setFlag={setFlag} flag={flag}
                                                             selectedDocument={selectedDocument}
                                                             setSelectedDocument={setSelectedDocument}/>
                                </Col>
                            </Row></Container>
                    </Col> : null
                }
                <Col style={{height: "100%", overflowY: "auto"}}>
                    <DisplayForm/>
                </Col>
            </Row>
        </Container>;

    }

    const is = (fileName, ext) => new RegExp(`.${ext}\$`).test(fileName);

    function iconClassName(text) {
        if (is(text, "pdf")) {
            return "k-icon k-i-file-pdf";
        } else if (is(text, "html")) {
            return "k-icon k-i-html";
        } else if (is(text, "jpg|png|bmp")) {
            return "k-icon k-i-image";
        } else if (is(text, "bmp")) {
            return "k-icon k-i-file";
        } else {
            return "k-icon k-i-folder";
        }
    }

    const footerContent = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => {
                setCloseDialog(false)
                hideDialog();
            }
            } className="p-button-text"/>
            <Button label="Save as Draft" icon="pi pi-check" onClick={() => {
                saveEncountersForClose().then(() => {
                    setCloseDialog(false)
                    hideDialog();
                })
            }} autoFocus/>
        </div>
    );


    return (
        <>
            <Dialog
                visible={props.showWindow[PopupWindows.ENCOUNTER_WINDOW]}
                header={"Encounters"}
                maximizable
                style={{height: null, width: "90vw", overflowY: null}}
                onHide={() => encounterContext && encounterContext.isLocked === true ? hideDialog() : setCloseDialog(true)}>
                <div>
                    <EncounterHeader/>
                    <br></br>
                    <EncounterToolBar/>
                </div>
                <div style={{height: "620px", overflowY: "auto"}}>
                    <div>
                        <Col>
                            {
                                show ? <span onClick={slideElements} className="k-icon k-i-arrow-60-down"
                                             style={{fontSize: 25, paddingRight: 10}}/>
                                    : <span onClick={slideElements} className="k-icon k-i-arrow-60-right"
                                            style={{fontSize: 25, paddingRight: 10}}/>
                            }
                        </Col>
                        <Col style={{marginLeft: "2%", marginTop: "-2%"}}>
                            <FieldSection>
                                <Slide childFactory={childFactory} transitionExitDuration={1500}>
                                    {children}
                                </Slide>
                            </FieldSection>
                        </Col>
                    </div>
                    {
                        encounterContext && encounterContext.id ?
                            <>
                                <hr/>
                                {
                                    props.editFormContext && Object.keys(props.editFormContext).length > 0 && encounterPermission.isEdit && encounterPermission.isView && !encounterContext.isLocked ?
                                        <div style={{
                                            marginLeft: "68%",
                                            pointerEvents: encounterContext.isLocked ? 'none' : 'block'
                                        }}>
                                            <span> This Form: </span>
                                            <Button
                                                severity={"secondary"}
                                                style={{marginRight: 5}}
                                                label={"Save as Draft"}
                                                onClick={() => {
                                                    if (props.editFormContext) {
                                                        saveEncounterForm(props.editFormContext, true)
                                                    }
                                                }}/>


                                            <Button label={"Save and Complete"}
                                                    severity={"success"}
                                                    style={{marginRight: 5}}
                                                    onClick={() => {
                                                        if (props.editFormContext) {
                                                            saveEncounterForm(props.editFormContext, false)
                                                        }
                                                    }}/>

                                            <Button severity={"danger"}
                                                    onClick={clearForm}
                                                    label={"Cancel"}/>


                                        </div> : null
                                }
                                {formContainer()}</> : null
                    }
                    {
                        encounterContext && encounterContext.id ?
                            <>
                                <hr/>
                                {
                                    props.editFormContext && Object.keys(props.editFormContext).length > 0 && encounterPermission.isEdit && encounterPermission.isView && !encounterContext.isLocked ?
                                        <div style={{
                                            marginLeft: "68%",
                                            pointerEvents: encounterContext.isLocked ? 'none' : 'block'
                                        }}>
                                            <span> This Form: </span>
                                            <Button
                                                severity={"secondary"}
                                                style={{marginRight: 5}}
                                                label={"Save as Draft"}
                                                onClick={() => {
                                                    if (props.editFormContext) {
                                                        saveEncounterForm(props.editFormContext, true)
                                                    }
                                                }}/>
                                            <Button label={"Save and Complete"}
                                                    severity={"success"}
                                                    style={{marginRight: 5}}
                                                    onClick={() => {
                                                        if (props.editFormContext) {
                                                            saveEncounterForm(props.editFormContext, false)
                                                        }
                                                    }}/>
                                            <Button severity={"danger"}
                                                    onClick={clearForm}
                                                    label={"Cancel"}/>
                                        </div> : null
                                }</> : null
                    }
                    <Container>
                        {eprescribeFlag &&
                            <VeradigmRenderer>
                                <VeradigmSsoComponent
                                    value={props?.getSAMLValuePatientContext?.samlResponse ? props?.getSAMLValuePatientContext?.samlResponse : null}
                                    setEprescribeFlag={setEprescribeFlag}/>
                            </VeradigmRenderer>
                        }
                    </Container>
                    <ConfirmDialog
                        visible={closeDialog}
                        header={"Save Encounter"}
                        message="Please save as draft before exiting."
                        icon="pi pi-exclamation-triangle"
                        position={'center'}
                        onHide={() => setCloseDialog(false)}
                        footer={footerContent}
                        draggable={false}
                        closable={false}
                        resizable={false}>
                    </ConfirmDialog>
                </div>
            </Dialog>
        </>
    )
}


function mapStateToProps(state) {
    return {
        loggedinUser: state.user.loggedinUser,
        tenantTelehealthAddress: state?.Configuration?.tenant?.telehealthAddress?.telehealthAddress,
        showWindow: state.popupWindow.showWindow,
        patient: state.patient.patientContext,
        popupState: state.popupWindow.state,
        userList: state.user.userList,
        medicalProblems: state.patient.medicalProblems,
        medications: state.patient.medications,
        immunizationData: state.patient.immunizationData,
        encounterContext: state.encounters.encounterContext,
        encounterSignedStatus: state.encounters.encounterSignedStatus,
        encounterDocuments: state.encounters.encounterDocuments,
        allergies: state.patient.allergies,
        hospitalization: state.patient.hospitalization,
        dictionaries: state.dictionary.dictionaries,
        editFormContext: state.form.editFormContext,
        formElements: state.form.formElements,
        clinicalDocumentContext: state.patient.clinicalDocumentContext,
        clinicalDocuments: state.patient.getClinicalDocument,
        encounterFormConfig: state.encounters.encounterFormConfig,
        formList: state.form.formList.payload,
        adminFormList: state.form.adminFormList.payload,
        getSAMLValuePatientContext: state.user.getSAMLValuePatientContext,
        permissionInfo: state.auth.session.permissions,
        serviceLines: state.encounters.encounterCoding.serviceLines,
        medicalProblemId: state.encounters.medicalProblemId,
        medicationId: state.encounters.medicationId,
        allergyId: state.encounters.allergyId,
        newHospitalizationId: state.encounters.hospitalizationId,
        immunizationDataNew: state.encounters.immunizationId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        EncounterAction: bindActionCreators(EncounterAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        FormActions: bindActionCreators(FormAction, dispatch),
        BaseRequestAction: bindActionCreators(BaseRequestAction, dispatch),
        UserActions: bindActionCreators(UserActions, dispatch),
        ImmunizationAction: bindActionCreators(ImmunizationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EncounterWindow)