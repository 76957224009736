// import './wdyr';
import 'font-awesome/css/font-awesome.min.css';
import ReactDOM from 'react-dom';
import App from './App';
import './App.scss';
import './index.css';


import {DevSupport} from "@react-buddy/ide-toolbox";
import {ConnectedRouter} from "connected-react-router";
import {Provider} from 'react-redux';
import {injectStore} from "./config/Url";
import {basicReduxStore, history} from "./store";
import ToastProvider from "./providers/toast/ToastProvider";
import {CssBaseline} from "@mui/material";
import {ComponentUpdateProvider} from "./typescript/context/ComponentUpdateProvider";


injectStore(basicReduxStore);

ReactDOM.render(
    <Provider store={basicReduxStore}>
        <ConnectedRouter history={history}>
            <ToastProvider>
                <ComponentUpdateProvider>
                    <DevSupport>
                        <CssBaseline>
                            <App/>
                        </CssBaseline>
                    </DevSupport>
                </ComponentUpdateProvider>
            </ToastProvider>
        </ConnectedRouter>
    </Provider>

    , document.getElementById('root'));

