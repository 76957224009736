import DictionaryDropDownList from "./DictionaryDropDownList";
import React from "react";
import PropTypes from "prop-types";
import {Checkbox, ColorPicker, Input, MaskedTextBox} from "@progress/kendo-react-inputs";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import moment from "moment";
import {Col, Row} from "react-grid-system";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

const ErrorCell = (props) => {
    const {field, dataItem} = props
    //console.log("ErrorCell ErrorCell ",props)
    if (dataItem) {
        if (dataItem.hasOwnProperty("errors")) {
            if (dataItem.errors.hasOwnProperty(field)) {
                return (
                    <Tooltip openDelay={300}
                             position="top"
                             anchorElement="element"
                             style={{background: "dimgray", color: "white"}}
                             tooltipStyle={{background: "white", color: "white"}}>
                            <span className={"k-icon k-i-exclamation-circle"}
                                  style={{width: "100%", color: "red"}}
                                  title={dataItem.errors[field]}>
                                <div>{props.children}</div>
                            </span>
                    </Tooltip>
                )

            } else {
                return <div>{props.children}</div>
            }
        } else {
            return <div>{props.children}</div>
        }
    }
    return <div style={{width: "100%"}}> {props.children}</div>

}

export const InlineDictionaryDropDown = (props) => {
    const {code, dataItem} = props
    const errors = dataItem?.errors
    const field = props.field ? props.field : "dictionary"
    const displayField = props.displayField ? props.displayField : "displayName"
    // React.useEffect(() => {
    //     console.log(dataItem)
    // }, [dataItem])

    const handleChange = (e) => {
        if (props.onChange) {
            const data = {
                dataIndex: props.dataIndex,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.id,
            }
            props.onChange(data);

        }
    };


    return <>
        <td>
            <ErrorCell dataItem={dataItem} field={field}>
                {props.dataItem.inEdit ? (

                    <DictionaryDropDownList
                        {...props}
                        style={{
                            width: "100%",
                        }}
                        value={dataItem[field]}
                        code={code}
                        onChange={handleChange}/>


                ) : (
                    props.dataItem[displayField] ? props.dataItem[displayField] : ""
                )}
            </ErrorCell>

        </td>
    </>

}

InlineDictionaryDropDown.propTypes = {
    dataItem: PropTypes.object.isRequired,
    code: PropTypes.string.isRequired,
    displayField: PropTypes.string.isRequired,
}

export const InlineDataDropDown = (props) => {
    const {code, dataItem} = props
    const errors = dataItem?.errors
    const [internalValue, setInternalValue] = React.useState(null)
    const [data, setData] = React.useState(props.data)
    const field = props.field ? props.field : "id"
    const displayField = props.displayField ? props.displayField : "displayName"
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    const filterData = (filter) => {
        return filterBy(data.slice(), filter);
    };
    const filterChange = (event) => {
       setData(filterData(event.filter));
    };
    const handleChange = (e) => {
        if (props.onChange) {
            const newData = {
                dataIndex: props.dataIndex,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.id,
            }
            props.onChange(newData);
        }
    };
    return <>
        <td>
            <ErrorCell dataItem={dataItem} field={field}>
                {props.dataItem.inEdit ? (
                    <DropDownList
                        style={{
                            width: "100%",
                        }}
                        filterable={true}
                        data={data}
                        value={data.find((c) => c.id === dataValue)}
                        onFilterChange={filterChange}
                        textField={displayField}
                        onChange={handleChange}/>
                ) : (
                    props.dataItem[displayField] ? props.dataItem[displayField] : ""
                )}
            </ErrorCell>

        </td>
    </>

}

InlineDataDropDown.propTypes = {
    dataItem: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    displayField: PropTypes.string.isRequired,
}


export const InlinePrimaryCheckbox = (props) => {
    const handleChange = (e) => {
        if (props.onChange) {
            const data = {
                dataIndex: props.dataIndex,
                dataItem: props.dataItem,
                field: "isPrimary",
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            }
            props.onChange(data);

        }
    };
    const value = props.dataItem[props.field]
    return (
        <td>
            <ErrorCell>
                <Checkbox id={props.dataItem.isPrimary}
                          onChange={handleChange}
                          disabled={!props.dataItem.inEdit}
                          checked={value}
                />
            </ErrorCell>
        </td>)
}

export const InlinePhoneInput = (props) => {
    const {field, dataItem} = props
    const handleChange = (e) => {
        if (props.onChange) {
            const data = {
                dataIndex: props.dataIndex,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            }
            props.onChange(data);

        }
    };
    const value = props.dataItem[props.field] || ''
    return (
        <td>
            <ErrorCell dataItem={dataItem} field={field}>
                <MaskedTextBox
                    style={{width: "100%"}}
                    disabled={!dataItem.inEdit}
                    mask="(999) 000-0000"
                    value={value}
                    onChange={handleChange}/>
            </ErrorCell>

        </td>
    )
}


export const InlineTextInput = (props) => {
    const {field, dataItem} = props
    //console.log("props props ",props)
    const handleChange = (e) => {
        if (props.onChange) {
            const data = {
                dataIndex: props.dataIndex,
                dataItem: props.dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            }
            props.onChange(data);

        }
    };
    const value = props.dataItem[props.field]
    return (
        <td key={dataItem.id}>
            <ErrorCell dataItem={dataItem} field={field}>
                <Input
                    style={{width: "100%"}}
                    disabled={!dataItem.inEdit}
                    value={value}
                    onChange={handleChange}/>
            </ErrorCell>
        </td>
    )
}
export const InlineDatePicker = (props) => {
    const {field, dataItem} = props
    const handleChange = (e) => {
        if (props.onChange) {
            const data = {
                dataIndex: props.dataIndex,
                dataItem: props.dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            }
            props.onChange(data);

        }
    };
    const value = props.dataItem[props.field] ? moment(props.dataItem[props.field], "YYYY-MM-DD").toDate() : null
    return (
        <td key={dataItem.id}>

            <ErrorCell dataItem={dataItem} field={field}>
                <DatePicker
                    style={{width: "100%"}}
                    disabled={!dataItem.inEdit}
                    value={value}
                    onChange={handleChange}/>
            </ErrorCell>

        </td>
    )
}


export const InlineColorPicker = (props) => {
    const {field, dataItem} = props
    const handleChange = (e) => {
        if (props.onChange) {
            const data = {
                dataIndex: props.dataIndex,
                dataItem: props.dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.value,
            }
            props.onChange(data);

        }
    };
    const value = props.dataItem[props.field] ? props.dataItem[props.field] : null
    return (
        <td key={dataItem.id}>

            <ErrorCell dataItem={dataItem} field={field}>
                <ColorPicker
                    style={{width: "100%"}}
                    view="gradient"
                    value={value}
                    disabled={!dataItem.inEdit}
                    onChange={handleChange}
                    gradientSettings={{
                        opacity: false,
                    }}
                />
            </ErrorCell>

        </td>
    )
}


export const RangeFilterCell = (props) => {
    let minTextBox = null;
    let maxTextBox = null;
    let valueTextBox = null;

    const {field, dataItem} = props
    const value = props.dataItem[props.field] || null;

    const onChange = (event) => {
        props.onChange({
            value: {
                value: valueTextBox?.value,
                min: minTextBox?.value,
                max: maxTextBox?.value,
            },
            dataIndex: props.dataIndex,
            dataItem: props.dataItem,
            field: field,
            syntheticEvent: event.syntheticEvent,
        });
    };
    return (<td>{props.dataItem.dictOperatorDescription === "Between" ?
        <Row>
            <Col sm={5}>
                <ErrorCell dataItem={dataItem} field={field}><Input
                    type={"text"}
                    width="100%"
                    value={value && value.min}
                    ref={(numeric) => {
                        minTextBox = numeric;
                    }}
                    disabled={!dataItem.inEdit}
                    onChange={onChange}
                /></ErrorCell>
            </Col>
            <Col>to</Col>
            <Col sm={5}>
                <ErrorCell dataItem={dataItem} field={field}><Input
                    type={"text"}
                    width="100%"
                    value={value && value.max}
                    disabled={!dataItem.inEdit}
                    ref={(numeric) => {
                        maxTextBox = numeric;
                    }}
                    onChange={onChange}
                /></ErrorCell>
            </Col>
        </Row> :
        <ErrorCell dataItem={dataItem} field={field}><Input
            type={"text"}
            width="100%"
            value={value && value.value}
            ref={(numeric) => {
                valueTextBox = numeric;
            }}
            disabled={!dataItem.inEdit}
            onChange={onChange}
        /></ErrorCell>
    }

    </td>);
};

export const InlineNumberInput = (props) => {
    const {field, dataItem} = props
    const handleChange = (e) => {
        if (props.onChange) {
            const data = {
                dataIndex: props.dataIndex,
                dataItem: props.dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            }
            props.onChange(data);

        }
    };
    const value = props.dataItem[props.field]
    return (
        <td key={dataItem.id}>
            <ErrorCell dataItem={dataItem} field={field}>
                <Input
                    type={"number"}
                    style={{width: "100%"}}
                    disabled={!dataItem.inEdit}
                    value={value}
                    step="0.01"
                    onChange={handleChange}/>
            </ErrorCell>
        </td>
    )
}