import React, {useEffect, useState} from 'react';
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import ShowForPermissionComponent from "../shared/ShowForPermissionComponent";
import {TileContainer} from "../../layout/TileContainer";
import TileHeader from "../../layout/TileHeader";
import {TileBody} from "../../layout/TileBody";
import {Col, Row} from "../../layout/GridLayout";
import {Colors, PhixPermissions} from "../../config/GlobalConfig";
import {useDispatch, useSelector} from "react-redux";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import PopupWindow from "../shared/PopupWindow";
import {PatientAction} from "../../action/PatientAction";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {UserActions as UserAction} from "../../action/UserAction";
import {DictionaryDispatchAction as DictionaryAction} from "../../action/DictionaryDispatchAction";
import {ListBox} from "primereact/listbox";
import {Button} from "primereact/button";

const AnalyticBar = (props) => {

    const [total, setTotal] = useState(0)
    const [greenWidth, setGreenWidth] = useState(0)
    const [yellowWidth, setYellowWidth] = useState(0)
    const [orangeWidth, setOrangeWidth] = useState(0)
    const [redWidth, setRedWidth] = useState(0)
    const [addgreenWidth, setAddGreenWidth] = useState(0)
    const [blueWidth, setBlueWidth] = useState(0)
    useEffect(() => {
        let total = props?.red?.count + props?.yellow?.count + props.orange?.count + props.green?.count + props.addgreen?.count + props.blue?.count
        setGreenWidth(props.green?.count / total * 100)
        setYellowWidth(props.yellow?.count / total * 100)
        setOrangeWidth(props.orange?.count / total * 100)
        setRedWidth(props.red?.count / total * 100)
        setAddGreenWidth(props.addgreen?.count / total * 100)
        setBlueWidth(props.blue?.count / total * 100)
        setTotal(total)
    }, [props.red, props.green, props.yellow, props.orange, props.addgreen, props.blue])

    return (
        <Row>
            <div className={"flex flex-row w-full"}>
                <div title={props.red?.textType} className={"flex"} onClick={() => props.onClick({
                    label: props.label,
                    color: "red",
                    size: props.red?.count,
                    patientsRoleId: props.red?.patientsId
                })}
                     style={{
                         display: "flex",
                         alignItems: "center",
                         justifyContent: "center",
                         backgroundColor: Colors.RED,
                         width: `${redWidth}%`
                     }}>
                    {props.red?.count > 0 &&
                        <span style={{fontSize: 14, color: "white"}}>{props.red?.count}</span>}
                </div>
                <div className={"flex"} title={props.orange?.textType} onClick={() => props.onClick({
                    label: props.label,
                    color: "orange",
                    size: props.orange?.count,
                    patientsRoleId: props.orange?.patientsId
                })}
                     style={{
                         display: "flex",
                         alignItems: "center",
                         justifyContent: "center",
                         backgroundColor: Colors.ORANGE,
                         width: `${orangeWidth}%`
                     }}>
                    {props.orange?.count > 0 &&
                        <span style={{fontSize: 14, color: "white"}}>{props.orange?.count}</span>}
                </div>
                <div className={"flex"} title={props.yellow?.textType} onClick={() => props.onClick({
                    label: props.label,
                    color: "yellow",
                    size: props.yellow?.count,
                    patientsRoleId: props.yellow?.patientsId
                })}
                     style={{
                         display: "flex",
                         alignItems: "center",
                         justifyContent: "center",
                         backgroundColor: Colors.YELLOW,
                         width: `${yellowWidth}%`
                     }}>
                    {props.yellow?.count > 0 &&
                        <span style={{fontSize: 14, color: "white"}}>{props.yellow?.count}</span>}
                </div>
                <div className={"flex"} title={props.green?.textType} onClick={() => props.onClick({
                    label: props.label,
                    color: "green",
                    size: props.green?.count,
                    patientsRoleId: props.green?.patientsId
                })} style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: Colors.GREEN,
                    width: `${greenWidth}%`
                }}>
                    {props.green?.count > 0 &&
                        <span style={{fontSize: 14, color: "white"}}>{props.green?.count}</span>}
                </div>

                <div className={"flex"} title={props.addgreen?.textType} onClick={() => props.onClick({
                    label: props.label,
                    color: "#a3c37c",
                    size: props.addgreen?.count,
                    patientsRoleId: props.addgreen?.patientsId
                })} style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#a3c37c",
                    width: `${addgreenWidth}%`
                }}>
                    {props.addgreen?.count > 0 &&
                        <span style={{fontSize: 14, color: "white"}}>{props.addgreen?.count}</span>}
                </div>

                <div className={"flex"} title={props.blue?.textType} onClick={() => props.onClick({
                    label: props.label,
                    color: "#3D85C6",
                    size: props.blue?.count,
                    patientsRoleId: props.blue?.patientsId
                })} style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#3D85C6",
                    width: `${blueWidth}%`
                }}>
                    {props.blue?.count > 0 &&
                        <span style={{fontSize: 14, color: "white"}}>{props.blue?.count}</span>}
                </div>
            </div>


        </Row>)
}

const AnalyticProfileCareTeamComponent = (props) => {
    const dispatch = useDispatch()

    const [showPatientList, setShowPatientList] = useState(false)
    const [title, setTitle] = useState("")
    const [filterValue, setFilterValue] = useState("")
    const [data, setData] = useState([])
    const [analyticalData, setAnalyticalData] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const patientList = useSelector(state => state.patient.patientList.patients)
    const AnalyticProfileDetails = useSelector(state => state.patient.AnalyticProfileDetails)
    const getDictionaryList = useSelector(state => state.dictionary.dictionaries?.AH_PATIENT_AGGREGATE)


    useEffect(() => {
        setData(patientList)
    }, [patientList])

    const handleClick = (cohort) => {

        UserAction.getPatientsList({patientRoleId: cohort.patientsRoleId})(dispatch)
        PopupWindowActions.showWindow(PopupWindows.PATIENT_LIST_FOR_PIE)(dispatch)
    }

    useEffect(() => {
        DictionaryAction.searchDictionaryList("AH_PATIENT_AGGREGATE")(dispatch).then(() =>
            PatientAction.AnalyticProfileDetails("CareTeam")(dispatch)
        )
    }, [])


    useEffect(() => {
        let dataAnalyticProfileCareTeam = [];
        if (getDictionaryList?.length > 0 && AnalyticProfileDetails?.CareTeam) {
            const activeListPatientAggs = getDictionaryList.filter(item => {
                return item.active === true;
            });

            activeListPatientAggs.map(activeListPatientAgg => {
                // NILESH: You have to check existence before calling a function on something that may not exist
                const analyticProfileCareTeamDataCategory = AnalyticProfileDetails?.CareTeam?.filter(item => {
                    return item.attributeType === activeListPatientAgg.code;
                });
                let childData = {
                    red: {
                        count: 0,
                        patientsId: []
                    },
                    yellow: {
                        count: 0,
                        patientsId: []
                    },
                    orange: {
                        count: 0,
                        patientsId: []
                    },
                    green: {
                        count: 0,
                        patientsId: []
                    },
                    addgreen: {
                        count: 0,
                        patientsId: [],
                        color: "#a3c37c"
                    },
                    blue: {
                        count: 0,
                        patientsId: [],
                        color: "#3D85C6"
                    },
                    type: activeListPatientAgg?.code,
                    displayName: activeListPatientAgg?.displayName
                }
                for (let i = 0; i < analyticProfileCareTeamDataCategory[0]?.attributeDetails?.length; i++) {
                    if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.numericValue >= 10 && activeListPatientAgg?.code === "AH_LACE") {
                        childData.red.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.red.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.numericValue < 10 && activeListPatientAgg?.code === "AH_LACE") {
                        childData.green.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.green.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.attributeText === "Fit" && activeListPatientAgg?.code === "AH_FRAILTY_INDEX") {
                        childData.green.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.green.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.attributeText === "Mild" && activeListPatientAgg?.code === "AH_FRAILTY_INDEX") {
                        childData.yellow.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.yellow.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.attributeText === "Moderate" && activeListPatientAgg?.code === "AH_FRAILTY_INDEX") {
                        childData.orange.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.orange.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.attributeText === "Severe" && activeListPatientAgg?.code === "AH_FRAILTY_INDEX") {
                        childData.red.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.red.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.attributeText === "Mild" && activeListPatientAgg?.code === "AH_CHARLSON") {
                        childData.green.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.green.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.attributeText === "Moderate" && activeListPatientAgg?.code === "AH_CHARLSON") {
                        childData.orange.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.orange.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.attributeText === "Severe" && activeListPatientAgg?.code === "AH_CHARLSON") {
                        childData.red.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.red.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.attributeText === "No Risk" && activeListPatientAgg?.code === "AH_CHARLSON") {
                        childData.blue.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.blue.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.attributeText === "High Risk" && activeListPatientAgg?.code === "AH_FALL_RISK") {
                        childData.red.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.red.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.attributeText === "Low Risk" && activeListPatientAgg?.code === "AH_FALL_RISK") {
                        childData.green.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.green.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    } else if (analyticProfileCareTeamDataCategory[0]?.attributeDetails[i]?.attributeText === "No Risk" && activeListPatientAgg?.code === "AH_FALL_RISK") {
                        childData.addgreen.patientsId.push(analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].patientRoleId)
                        childData.addgreen.textType = analyticProfileCareTeamDataCategory[0]?.attributeDetails[i].attributeTextType;
                    }

                }

                childData.red.count = childData.red.patientsId.length;
                childData.yellow.count = childData.yellow.patientsId.length;
                childData.orange.count = childData.orange.patientsId.length;
                childData.green.count = childData.green.patientsId.length;
                childData.addgreen.count = childData.addgreen.patientsId.length;
                childData.blue.count = childData.blue.patientsId.length;
                dataAnalyticProfileCareTeam.push(childData);
                childData = {
                    red: {
                        count: 0,
                        patientsId: []
                    },
                    yellow: {
                        count: 0,
                        patientsId: []
                    },
                    orange: {
                        count: 0,
                        patientsId: []
                    },
                    green: {
                        count: 0,
                        patientsId: []
                    },
                    addgreen: {
                        count: 0,
                        patientsId: [],
                        color: "#a3c37c"
                    },
                    blue: {
                        count: 0,
                        patientsId: [],
                        color: "#3D85C6"
                    },
                    type: "",
                    displayName: ""
                }
            });
            setAnalyticalData(dataAnalyticProfileCareTeam);
        }
    }, [getDictionaryList, AnalyticProfileDetails])

    const AggregateRenderer = (props) => {
        return (
            <Row>
                <Col col={6}>
                    {props.displayName}
                </Col>
                <Col col={6}>
                    <AnalyticBar onClick={handleClick} {...props}/>
                </Col>
            </Row>
        )
    }

    return (
        <React.Fragment>
            <ShowForPermissionComponent component={true} permissions={[PhixPermissions.AH_VIEW_PATIENT_METRICS]}>
                <TileContainer>
                    <TileHeader headerColor={"black"}
                                className="boxHeader ah-draggable-header"
                                title={"Analytic Profile Cohort"} image={"../images/analytic.png"}>
                        <Button style={{color: "black"}} text icon={showFilter ? "pi pi-filter-fill" : "pi pi-filter"}
                                onClick={() => {
                                    if (showFilter) setFilterValue("")
                                    setShowFilter(!showFilter)
                                }}/>
                    </TileHeader>
                    <TileBody >
                        <ListBox
                            filter={showFilter}
                            filterValue={filterValue}
                            onFilterValueChange={(e) => setFilterValue(e.value)}
                            // value={"displayName"}
                            optionLabel={"displayName"}
                            itemTemplate={AggregateRenderer}
                            options={analyticalData}
                            className={"w-full"}
                            emptyMessage={<div className={"flex justify-content-center w-full"}><p>No records
                                available.</p></div>}/>
                    </TileBody>
                </TileContainer>
                <PopupWindow title={title} show={showPatientList} handleHide={() => setShowPatientList(false)}>
                    <Grid
                        style={{width: "100%", height: "100%"}}
                        data={data}>
                        <GridColumn field={"patientName"} title={"Patient Name"}/>
                    </Grid>

                </PopupWindow>
            </ShowForPermissionComponent>
        </React.Fragment>
    );
};


export default AnalyticProfileCareTeamComponent