import {connect, useSelector, useDispatch} from "react-redux";
import React, {useCallback} from "react";
import {bindActionCreators} from "redux";
import {PopupWindowActions, PopupWindows} from "../../action/PopupWindowAction";
import {EncounterAction} from "../../action/EncounterAction";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Row} from "../../layout/GridLayout";
import {useForm} from "react-hook-form";
import {
    Field,
    FieldColumn,
    FieldRow,
    FieldSection,
    FieldEditorInput,
    FieldTextInput
} from "../InputControls/FormFields";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const AddPatientInstructionsWindow = (props) => {

     const {
        handleSubmit,
        register,
        control,
        reset,
        setValue,
        watch,
        getValues,
        formState: {errors},
     } = useForm({defaultValues: {description: ""}});

    const dispatch = useDispatch();

    const showWindow = useSelector(state => state?.popupWindow?.showWindow[PopupWindows.ADD_ENCOUNTER_PATIENT_INSTRUCTIONS])
    const encounterContext = useSelector(state => state.encounters.encounterContext)
    const patientContext = useSelector(state => state.patient.patientContext)

    const hideDialog = async () => {
        await reset()
        await PopupWindowActions.hideWindow(PopupWindows.ADD_ENCOUNTER_PATIENT_INSTRUCTIONS, true)(dispatch)
    }

    React.useEffect(()=>{
        if (encounterContext?.id) {
            setValue("patientInstructions", encounterContext?.patientInstructions)
            setValue("nextAppointment", encounterContext?.nextAppointment)
        }
    },[showWindow])

    const saveOrUpdateInstructions = (form) => {
        const payload = {
            encounterId: encounterContext?.id,
            patientRoleId: patientContext?.patientRoleId,
            patientInstructions: form?.patientInstructions,
            nextAppointment: form?.nextAppointment
        }
        EncounterAction.saveEncounterInstructions(payload)(dispatch).then(()=> hideDialog());

    }

    const DisplayForm = () => {
        return <div>
            <FieldSection>
                <GridLayout gap={{ rows: 2, cols: 1}}>
                    <GridLayoutItem row={1} col={1} style={{width: "800px"}}>
                        <Field label={""} field={"patientInstructions"}
                               error={errors}
                               register={register}
                               control={control}>
                            <FieldEditorInput
                                style={{ height: '135px' }}
                            />
                        </Field>
                    </GridLayoutItem>
                    <GridLayoutItem row={2} col={1}>
                        <Field label={"Next Appointment:"} field={"nextAppointment"}
                               error={errors}
                               register={register}
                               control={control}>
                            <FieldTextInput/>
                        </Field>
                    </GridLayoutItem>
                </GridLayout>
            </FieldSection>

        </div>
    }

    return (
        <Dialog header={"Patient Instructions"}
                maximizable
                visible={showWindow && encounterContext?.id}
                footer={<Row className={"justify-content-end"}>
                    <Button severity={"success"}
                            label={"Save"}
                            onClick={handleSubmit(saveOrUpdateInstructions)}/>
                    <Button label={"Cancel"}
                            severity={"danger"}
                            onClick={hideDialog}/>
                </Row>}
                onHide={hideDialog}>
            <div style={{height: "100%", overflowY: "auto"}}>
                <DisplayForm/>
            </div>

        </Dialog>
    );

}

export default AddPatientInstructionsWindow