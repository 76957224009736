import {PostApi, WrapAuthentication, WrapAuthenticationForm} from "../util/AuthWrapper";
import {TENANT_ID} from "../config/GlobalConfig";

export const ActionTypes = {
    REGISTER_PORTAL_USER: "REGISTER_PORTAL_USER",
    USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
    LOGGEDIN_USER_SUCCESS: "LOGGEDIN_USER_SUCCESS",
    SET_USER_CONTEXT: "SET_USER_CONTEXT",
    CLEAR_USER_CONTEXT: "CLEAR_USER_CONTEXT",
    USER_UPDATE: "USER_UPDATE",
    CLEAR_UPDATE: "CLEAR_UPDATE",
    USER_GET_LICENSES: "USER_GET_LICENSES",
    USER_CLEAR_LICENSES: "USER_CLEAR_LICENSES",
    UPDATE_USER_IN_LIST: "UPDATE_USER_IN_LIST",
    ADD_USER_LIST: "ADD_USER_LIST",
    CHECK_USERNAME: "CHECK_USERNAME",
    GET_SECRET: "GET_SECRET",
    SAVE_SECRET: "SAVE_SECRET",
    RESET_SECRET: "RESET_SECRET",
    VALIDATE_OTP: "VALIDATE_OTP",
    SLEEP_QUALITY_PIE: "SLEEP_QUALITY_PIE",
    PIE_GRAPH_SET_CONTEXT: "PIE_GRAPH_SET_CONTEXT",
    PIE_GRAPH_CLEAR_CONTEXT: "PIE_GRAPH_CLEAR_CONTEXT",
    ACTIVITY_LEVEL: "ACTIVITY_LEVEL",
    USER_CREATE_PORTAL_REGISTRATION: "USER_CREATE_PORTAL_REGISTRATION",
    WEIGHT_MANAGEMENT: "WEIGHT_MANAGEMENT",
    GET_USERS_INFO: "GET_USERS_INFO",
    CREATE_NEW_USER: "CREATE_NEW_USER",
    GET_SAML: "GET_SAML",
    GET_PATIENTS_LIST: "GET_PATIENTS_LIST",
    BLOOD_PRESSURE: "BLOOD_PRESSURE",
    GET_EPRESCRIBE_USERS_AND_PATIENTS: "GET_EPRESCRIBE_USERS_AND_PATIENTS",
    GET_SAML_VALUE_PATIENT_CONTEXT: "GET_SAML_VALUE_PATIENT_CONTEXT",
    GET_VERADIGM_USER_TASKS: "GET_VERADIGM_USER_TASKS",
    ALL_USERS: "ALL_USERS",
    GET_USER_BY_ID: "GET_USER_BY_ID",
    CLEAR_PROVIDER_USER: "CLEAR_PROVIDER_USER",
    SIGNATURE_UPLOAD: "SIGNATURE_UPLOAD",
    SIGNATURE_DOWNLOAD: "SIGNATURE_DOWNLOAD",
    SIGNATURE_DOWNLOAD_CLEAR: "SIGNATURE_DOWNLOAD_CLEAR",
}

function setUserContext(user) {
    return async dispatch =>
        dispatch({type: ActionTypes.SET_USER_CONTEXT, payload: user})

}

function setUserContextByUserRoleId(userRoleId) {
    return WrapAuthentication('user/id', {
            payload: {
                userRoleId: userRoleId
            }
        },
        ActionTypes.SET_USER_CONTEXT
    )

}

function clearUserContext(user) {
    return async dispatch => {
        dispatch({type: ActionTypes.CLEAR_USER_CONTEXT})
        dispatch({type: ActionTypes.CLEAR_UPDATE})
    }

}

/**
 * @deprecated Use getUserListFilter instead
 */
function getUserList(activeOnly = null, portalUser = false) {
    return WrapAuthentication('user/all', {
            payload: {activeOnly: activeOnly, portalUsers: portalUser}
        },
        ActionTypes.USER_LIST_SUCCESS
    )
}

function getAllUsers(activeOnly = null, portalUser = false, limit = 100, offset = 0) {
    let filter = {}
    filter.searchAll = null
    filter.active = true
    return WrapAuthentication('user/filter/all', {
            payload: {
                activeOnly: activeOnly,
                portalUsers: portalUser
            },
            limit: limit,
            offset: offset,
            filter,
            sort: null,
        },
        ActionTypes.ALL_USERS
    )
}

function getUserListFilter(sort, filter, activeOnly = null, portalUser = false, limit = 100, offset = 0) {
    return WrapAuthentication('user/filter/all', {
            payload: {
                activeOnly: activeOnly,
                portalUsers: portalUser
            },
            limit: limit,
            offset: offset,
            filter,
            sort: sort ? {sorts: sort} : null,
        },
        ActionTypes.USER_LIST_SUCCESS
    )
}

function getUser(payload) {
    return WrapAuthentication('user/id', {
            payload: payload
        },
        ActionTypes.LOGGEDIN_USER_SUCCESS
    )
}

function getUsersInfo(payload) {
    return WrapAuthentication('veradigm/user/info', {
            payload: payload
        },
        ActionTypes.GET_USERS_INFO
    )
}

function getSAML() {
    return WrapAuthentication('veradigm/saml', {payload: {}},
        ActionTypes.GET_SAML
    )
}

function getSamlTaskMode() {
    return WrapAuthentication('veradigm/saml/task', {payload: {}},
        ActionTypes.GET_SAML
    )
}

function registerPortalUser(payload) {
    return WrapAuthentication("portal/registration/claim", {payload}, ActionTypes.REGISTER_PORTAL_USER)

}

function getVeradigmUserTasks() {
    return WrapAuthentication('veradigm/user/task', {},
        ActionTypes.GET_VERADIGM_USER_TASKS
    )
}

function getSamlUtilityMode() {
    return WrapAuthentication('veradigm/saml/utility', {payload: {}},
        ActionTypes.GET_SAML
    )
}

function createNewUser(payload) {
    return WrapAuthentication('veradigm/user/create', {
            payload: payload
        },
        ActionTypes.CREATE_NEW_USER
    )
}


function createUser(user) {
    return WrapAuthentication("user/create",
        {"payload": user},
        ActionTypes.ADD_USER_LIST)
}

function updateUser(user) {
    return WrapAuthentication("user/update",
        {"payload": user},
        ActionTypes.UPDATE_USER_IN_LIST)
}

function getLicensesForUser(userRoleId) {
    return WrapAuthentication("user/license/get",
        {"payload": {userRoleId}},
        ActionTypes.USER_GET_LICENSES)
}

function checkUsername(username) {
    return WrapAuthentication("user/check/username",
        {
            "payload": {
                "username": username
            }
        },
        ActionTypes.CHECK_USERNAME)
}

function clearLicenseContext() {
    return async dispatch =>
        dispatch({type: ActionTypes.USER_CLEAR_LICENSES})
}

function updateUserLicense(payload) {
    return WrapAuthentication("user/license/update",
        {payload: payload})
}

function createUserLicense(payload) {
    return WrapAuthentication("user/license/create",
        {payload: payload})
}

function deleteUserLicense(licenseId) {
    return WrapAuthentication("user/license/delete",
        {payload: {licenseId}})
}

function requestMfaSecretKey(username, password, method) {
    // return WrapAuthentication("user/get/secretKey",
    return WrapAuthentication("session/getSecretKey",
        {
            payload: {username, password, method, tenantId: TENANT_ID}
        }, ActionTypes.GET_SECRET)
}

function saveMfaSecret(payload) {
    return WrapAuthentication("user/mfa/register",
        {
            payload: payload
        }, ActionTypes.SAVE_SECRET)
}

function validateMFATOTP(payload) {
    return WrapAuthentication("session/validateMFATOTP",
        {
            payload: payload
        }, ActionTypes.VALIDATE_OTP)
}

function resetMfaSecret(payload) {
    return WrapAuthentication("/user/mfa/reset",
        {
            payload: payload
        }, ActionTypes.RESET_SECRET)
}

function getPatientSleepQualityAverages(payload) {
    return WrapAuthentication("observation/patients/sleep/average", {
        payload: payload
    }, ActionTypes.SLEEP_QUALITY_PIE)
}

function WeightManagement(payload) {
    return WrapAuthentication("observation/weightManagement", {
        payload: payload
    }, ActionTypes.WEIGHT_MANAGEMENT)
}


function getPatientsList(payload) {
    return WrapAuthentication("patients/get/ids", {
        payload: payload
    }, ActionTypes.GET_PATIENTS_LIST)
}


function getActivityLevel(payload) {
    return WrapAuthentication("observation/patients/period", {
        payload: payload
    }, ActionTypes.ACTIVITY_LEVEL)
}

function setPiePatientListBySleepFilter(payload) {
    return WrapAuthentication("observation/patients/list/sleep/average",
        {payload: payload},
        ActionTypes.PIE_GRAPH_SET_CONTEXT)
}

function clearSetPieGraphContext() {
    return async dispatch =>
        dispatch({type: ActionTypes.PIE_GRAPH_CLEAR_CONTEXT})
}

function getEprescribeUserAndPatient(payload) {
    return WrapAuthentication("veradigm/getEprescribeUsersAndPatients",
        {payload: payload},
        ActionTypes.GET_EPRESCRIBE_USERS_AND_PATIENTS)
}

function getSAMLValuePatientContext(payload) {
    return WrapAuthentication("veradigm/saml/patient",
        {payload: payload},
        ActionTypes.GET_SAML_VALUE_PATIENT_CONTEXT)
}

function createPortalRegistration(payload) {
    return WrapAuthentication("portal/registration/create", {
        payload: payload
    }, ActionTypes.USER_CREATE_PORTAL_REGISTRATION)
}

async function addOrderableItemFavoriteToCurrentUser(orderableItemId) {
    return await PostApi("user/order/favorite/add", {payload: {orderableItemId}})
}

async function removeOrderableItemFavoriteToCurrentUser(orderableItemId) {
    return await PostApi("user/order/favorite/remove", {payload: {orderableItemId}})
}


async function addDictionaryFavoriteToCurrentUser(dictionaryId, dictionaryTypeId) {
    return await PostApi("user/dictionary/favorite/add", {payload: {dictionaryId, dictionaryTypeId}})
}

async function removeDictionaryFavoriteToCurrentUser(dictionaryId, dictionaryTypeId) {
    return await PostApi("user/dictionary/favorite/remove", {payload: {dictionaryId, dictionaryTypeId}})
}

function uploadSignature(payload) {
    return WrapAuthenticationForm("user/signature/upload", payload, ActionTypes.SIGNATURE_UPLOAD)
}

function downloadSignature(payload) {
    return WrapAuthentication("user/signature/download", {
        payload: payload
    }, ActionTypes.SIGNATURE_DOWNLOAD)
}

function clearSignature() {
    return async dispatch =>
        dispatch({type: ActionTypes.SIGNATURE_DOWNLOAD_CLEAR})
}

function getUserById(payload) {
    return WrapAuthentication('user/id', {
            payload: payload
        },
        ActionTypes.GET_USER_BY_ID
    )
}

function clearProviderUser(payload) {
    return async dispatch =>
        dispatch({type: ActionTypes.CLEAR_PROVIDER_USER})
}

export const UserActions =
    {
        getUserListFilter: getUserListFilter,
        getUserList: getUserList,
        getUser: getUser,
        setUserContext: setUserContext,
        clearUserContext: clearUserContext,
        createUser: createUser,
        updateUser: updateUser,
        checkUsername: checkUsername,
        getLicensesForUser: getLicensesForUser,
        updateUserLicense: updateUserLicense,
        createUserLicense: createUserLicense,
        clearLicenseContext: clearLicenseContext,
        deleteUserLicense: deleteUserLicense,
        requestMfaSecretKey: requestMfaSecretKey,
        saveMfaSecret: saveMfaSecret,
        resetMfaSecret: resetMfaSecret,
        validateMFATOTP: validateMFATOTP,
        getPatientSleepQualityAverages: getPatientSleepQualityAverages,
        setPiePatientListBySleepFilter: setPiePatientListBySleepFilter,
        clearSetPieGraphContext: clearSetPieGraphContext,
        getActivityLevel: getActivityLevel,
        createPortalRegistration: createPortalRegistration,
        WeightManagement: WeightManagement,
        getUsersInfo: getUsersInfo,
        createNewUser: createNewUser,
        getSAML: getSAML,
        getPatientsList: getPatientsList,
        // getEprescribeUserAndPatient: getEprescribeUserAndPatient,
        getSAMLValuePatientContext: getSAMLValuePatientContext,
        getSamlTaskMode: getSamlTaskMode,
        getSamlUtilityMode: getSamlUtilityMode,
        getVeradigmUserTasks: getVeradigmUserTasks,
        registerPortalUser: registerPortalUser,
        setUserContextByUserRoleId: setUserContextByUserRoleId,
        getAllUsers: getAllUsers,
        getUserById: getUserById,
        uploadSignature: uploadSignature,
        downloadSignature: downloadSignature,
        clearSignature: clearSignature,
        clearProviderUser: clearProviderUser,


    }

export const UserPostAction = {
    addOrderableItemFavoriteToCurrentUser: addOrderableItemFavoriteToCurrentUser,
    removeOrderableItemFavoriteToCurrentUser: removeOrderableItemFavoriteToCurrentUser,
    addDictionaryFavoriteToCurrentUser: addDictionaryFavoriteToCurrentUser,
    removeDictionaryFavoriteToCurrentUser: removeDictionaryFavoriteToCurrentUser
}