import * as React from "react";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import OrdersResultsComponent from "./OrdersResults/OrdersResultsComponent";
import LongitudinalResultsComponent from "./LongitudinalResults/LongitudinalResultsComponent"
import {Col, Container, Row} from "react-grid-system";
import {TabPanel, TabView} from "primereact/tabview";

const initialTabs = [
    {
        title: "Orders Results",
        content: <OrdersResultsComponent/>,
        visible: true,
    },
    {
        title: "Longitudinal Results",
        content: <LongitudinalResultsComponent/>,
        visible: true,
    },
];

const styles = {
    tabContainer: {
        width: "100%",
        background: "#BAC1E0",
        padding: "5px",
        textAlign: "center",
        color: "#FFF"
    },
    tabContainerSelected: {
        width: "100%",
        background: "#7280c0",
        padding: "5px",
        textAlign: "center",
        color: "#FFF"
    },
    tabTitle: {
        width: "100%",
        color: "#FFF"
    },
}


const Title = (props) => {
    return (
        <Container style={props.selected === props.index ? styles.tabContainerSelected : styles.tabContainer}>
            <Row>
                <Col>
                    <span style={styles.tabTitle} className="tabTitle">{props.content}</span>
                </Col>
            </Row>


        </Container>


    );
};

const ClinicalOrderTabComponent = (props) => {


    return (
        <TabView panelContainerStyle={{height: "100%"}}
                 style={{height: "calc(100% - 35px)"}}>
            <TabPanel header={"Orders"} style={{height: "100%"}}>
                <OrdersResultsComponent/>
            </TabPanel>
            <TabPanel header={"Results"} style={{height: "100%"}}>
                {/*<LongitudinalResultsComponent/>*/}
            </TabPanel>
        </TabView>
    );
};


export default ClinicalOrderTabComponent