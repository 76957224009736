import {useDispatch} from "react-redux"
import {useState} from "react"
import {DrawerContent, TabStrip, TabStripTab} from "@progress/kendo-react-layout";
import {Container, Row} from "react-grid-system";
import {ExternalVendorsComponent} from "./ExternalVendorsComponent";
import {Colors} from "../../../config/GlobalConfig";
import VendorAccountComponent from "./VendorAccountComponent";
import {TabPanel, TabView} from "primereact/tabview";


function VendorAdminPage(props) {
    const dispatch = useDispatch();

    const [selected, setSelected] = useState(0)
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const TitleTab = (props) => {
        return (
            <div style={{
                padding: 10,
                color: props.selected === props.index ? Colors.BLUE : Colors.DISABLED,
                backgroundColor: props.selected === props.index ? "white" : Colors.LIGHT_GRAY
            }}>
                {props.title}
            </div>)
    }

    return (<DrawerContent style={{width: "calc(100% - 50px)", height: "calc(100vh - 50px)"}}>
            <Container fluid style={{width: "100%", padding: 20, height: "100%"}}>
                <TabView>
                    <TabPanel header={"Vendor"}>
                        <ExternalVendorsComponent/>
                    </TabPanel>
                    <TabPanel header={"Account"}>
                        <VendorAccountComponent/>
                    </TabPanel>
                    <TabPanel header={"Adapter"}>

                    </TabPanel>
                </TabView>
            </Container>
        </DrawerContent>

    )

}

export default VendorAdminPage