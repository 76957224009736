import * as React from "react";
import {connect, useSelector} from "react-redux";
import "@progress/kendo-react-intl";
import "@progress/kendo-react-dropdowns";
import "react-router-dom";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {bindActionCreators} from "redux";
import {PatientAction} from "../../../action/PatientAction";
import {Input} from "@progress/kendo-react-inputs";
import {Button} from "primereact/button";
import {Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import {filterBy, orderBy} from '@progress/kendo-data-query';
import {PopupWindowActions, PopupWindows,} from "../../../action/PopupWindowAction";
import PropTypes from "prop-types";
import {EncounterAction} from "../../../action/EncounterAction";
import {PersonActions} from "../../../action/PersonAction";
import {DropdownFilterCell} from "../../InputControls/DropdownFilterCell";
import {DropdownFilterCellCustom} from "../../InputControls/DropdownFilterCellCustom";
import {PDFExport} from "@progress/kendo-react-pdf";
import {FormAction} from "../../../action/FormAction";
import {PhixPermissions} from "../../../config/GlobalConfig";
import {DictionaryDispatchAction} from "../../../action/DictionaryDispatchAction";
import {BaseRequestAction} from "../../../action/BaseRequestAction";
import moment from 'moment';
import ShowForPermissionComponent, {usePermission} from "../../shared/ShowForPermissionComponent";
import {FaWeight} from "react-icons/fa";
import _ from "lodash";
import {DateTimeGridCellWithFormat} from "../../../util/DateTools";


const style= {
    logo: {
            minHeight: "80px",
            minWidth: "95px",
            maxWidth: "95px",
            maxHeight: "80px",
            width: "100%",
            background: "#FFF",
            padding: "0px",
            marginTop: "5px",
            border: "0",
        }
}

const EncountersTabComponent = (props) => {
    const {PatientActions, patientContext, encounters, totalCount} = props

    const [dataState, setDataState] = React.useState({
        take: 50,
        skip: 0,
    });

    const tenantName = useSelector((state) => state.Configuration?.tenant?.tenant?.tenantName)
    let phixLogo = "../../../images/phix/Phix Logo_2c_vector.png";
    const [isEncounterPDFExporting, setIsEncounterPDFExporting] = React.useState(false);
    const [isBriefEncounterPDFExporting, setIsBriefEncounterPDFExporting] = React.useState(false);
    const pdfExportComponent = React.useRef(null);
    const [pdfExportEncounter, setPdfExportEncounter] = React.useState(null);
    const [searchState, setSearchState] = React.useState("")
    const [data, setData] = React.useState([])
    const [filterState, setFilterState] = React.useState(false)
    const [filter, setFilter] = React.useState({logic: "and", filters: []})
    const viewEncounterSummaryPermission = usePermission([PhixPermissions.AH_VIEW_ENCOUNTER_SUMMARY])
    const viewFullEncounterPermission = usePermission([PhixPermissions.AH_VIEW_ENCOUNTER])

    const initialSort = [
        {
            field: "encounterDate",
            dir: "desc",
        },
    ];
    const [sort, setSort] = React.useState(initialSort);
    const dataStateChange = (e) => {
        setDataState(e.dataState);
    };
    const buildFilters = () => {
        let filters = {}
        if (searchState.length > 0) filters.searchAll = searchState
        if (filter?.filters) {
            if (filter.filters.length > 0) {
                filter.filters.forEach(x => {
                    filters[x.field] = x.value
                })
            }

        }
        return filters;
    }
    React.useEffect(() => {
        if (isEncounterPDFExporting) {
            pdfExportComponent.current.save(() => {
                setIsEncounterPDFExporting(false);
            })
        }
    }, [isEncounterPDFExporting])
    React.useEffect(() => {
        if (isBriefEncounterPDFExporting) {
            pdfExportComponent.current.save(() => {
                setIsBriefEncounterPDFExporting(false);
            })
        }
    }, [isBriefEncounterPDFExporting])

    React.useEffect(() => {
        const listdata = encounters.list.map((obj) => {

            return {...obj, provider: `${obj?.providerLastName}, ${obj?.providerFirstName}`}
        })
        setData(listdata)
    }, [encounters])

    React.useEffect(() => {
       setPdfExportEncounter(props?.pdfData?.encounterContext)
    }, [props?.pdfData])

    // React.useEffect(() => {
    //     if (patientContext?.patientRoleId) {
    //         PatientActions.getMedicalProblems(patientContext?.patientRoleId)
    //         PatientActions.getHospitalization(patientContext?.patientRoleId)
    //         PatientActions.getMedications(patientContext?.patientRoleId)
    //         PatientActions.getAllergies(patientContext?.patientRoleId)
    //     }
    // }, [patientContext]);


    React.useEffect(() => {
        PatientActions.getEncounterList(patientContext.patientRoleId, dataState.take, dataState.skip, sort, buildFilters())
        props.BaseRequestAction.getpatientDemographics(patientContext.patientRoleId);
        props.DictionaryActions.searchDictionaryList("AH_POS", 500, 0, null, {})
    }, [])
    React.useEffect(() => {
        let sort_temp = sort;
        if (sort.length > 0 && sort[0].field && sort[0].field === "provider") {
            sort_temp = [{...sort_temp[0], "field": "providerLastName"}, {
                ...sort_temp[0],
                "field": "providerFirstName"
            }]
            //sort_temp = [{...sort_temp[0],"field":"providerLastName"}]
        }
        PatientActions.getEncounterList(patientContext.patientRoleId, dataState.take, dataState.skip, sort_temp, buildFilters())

    }, [sort, dataState, searchState, filterState])


    const isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
    }

    const numberOfDaysOld = (date) => {
        if (isToday(new Date(date))) {
            return 0;
        } else {
            let today = new Date();
            let compare = new Date(date);
            const diffTime = Math.abs(compare - today);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        }
    }

    const unlockIconCell = (props) => {
        const days = numberOfDaysOld(props.dataItem.encounterDate);
        const icon = (days >= 1 && days < 3) ? <span style={{color: '#E69138'}} className="k-icon k-i-unlock"/> :
            (days >= 3) ? <span style={{color: '#E06666'}} className="k-icon k-i-unlock"/> :
                <span style={{color: '#999999'}} className="k-icon k-i-unlock"/>;
        return icon;
    }

    const encounterDateCell = (props) => {
        return (<td>{`${props.dataItem?.encounterDate.split("T")[0]}`}</td>);
    }
    const encounterReasonCell = (props) => {
        return (<td>{`${props.dataItem?.reasonForEncounter.replaceAll(/(<([^>]+)>)/ig, "")}`}</td>);
    }
    const lockIconCell = (props) => {
        const icon = props.dataItem.isLocked ? (
            <span style={{color: '#6AA84F'}} className="k-icon k-i-lock"/>
        ) : (
            unlockIconCell(props)
        );
        const style = {
            color: props.dataItem.isLocked
                ? "rgb(243, 23, 0, 0.32)"
                : "rgb(55, 180, 0,0.32)",
        };

        return (
            <td>
                <span style={{marginRight: '6%'}}>{icon}</span>
                <span>{props.dataItem.dictEncounterStatusCode}</span>
            </td>
        );
    }

    const codingLockIconCell = (props) => {
        return props.dataItem.encounterCodingId ?
            (props.dataItem.encounterCodingSaved === true ? (
                <td>
                    <Button
                        icon={"pi pi-lock"}
                        label={"Coded"}
                        severity={"success"}/>
                </td>
            ) : (
                <td><Button
                    icon={"pi pi-lock-open"}
                    label={"Coding"}
                    text
                    style={{backgroundColor: 'rgb(230,145,56)'}}/>
                </td>
            )) : <td></td>;
    }

    function startTimer(item) {
        if (item?.id && !item?.encounterTimer?.startTime) {
            const payload = {
                encounterId: item?.id
            }
            props.EncounterAction.setStartTimerEncounter(payload).then(() => {
                props.PopupActions.showWindow(PopupWindows.ENCOUNTER_WINDOW);
            });
        }
    }

    const exportPDFWithComponent = (item, pdfType) => {
        props.EncounterAction.getEncounterPDFData({
            encounterId: item.dataItem?.id,
            patientRoleId: props?.patientContext?.patientRoleId
        }).then(() => {
            if (pdfType === "encounterPdf") {
                setIsEncounterPDFExporting(true);
            } else if (pdfType === "briefSummary") {
                setIsBriefEncounterPDFExporting(true)
            }
        });
    }
    const CommandCell = (item) => (
        <td className="k-command-cell">
            <tr>
                <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}>
                    <td><Button icon={"pi pi-pencil"}
                            text
                            tooltip={"Edit Encounter"}
                            onClick={(e) => {
                                gotoEncounterHandler(item)
                            }}
                    /></td>
                </ShowForPermissionComponent>
                {
                    item.dataItem?.dictEncounterStatusDisplayName === "Signed" ? <>
                        <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER, PhixPermissions.AH_VIEW_ENCOUNTER, PhixPermissions.AH_VIEW_ENCOUNTER_LOCKED]}>
                            <td><Button tooltip={"Encounter PDF"} text="Image" onClick={(e) => exportPDFWithComponent(item, "encounterPdf")}><i class="fa fa-files-o" aria-hidden="true"></i>
                            </Button></td>
                        </ShowForPermissionComponent>
                        <ShowForPermissionComponent permissions={[PhixPermissions.AH_VIEW_ENCOUNTER_SUMMARY, PhixPermissions.AH_EDIT_ENCOUNTER, PhixPermissions.AH_VIEW_ENCOUNTER, PhixPermissions.AH_VIEW_ENCOUNTER_LOCKED]}>
                            <td><Button tooltip={"Brief Patient Summary"} text="Image" onClick={(e) => exportPDFWithComponent(item, "briefSummary")}><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                            </Button></td>
                        </ShowForPermissionComponent>
                    </>: <></>
                }
            </tr>
        </td>
    );

    const gotoEncounterHandler = (item) => {
        props.EncounterAction.clearEncounterFormConfig();
        props.EncounterAction.clearEncounterServiceLines();
        props.EncounterAction.getEncounter({id: item.dataItem.id}).then(()=> {
            props.PopupActions.showWindow(PopupWindows.ENCOUNTER_WINDOW);
        })
    }
    const createEncounterHandler = () => {
        props.EncounterAction.clearEncounterFormConfig();
        props.EncounterAction.clearEncounterServiceLines();
        props.EncounterAction.clearEncounterServiceLines();
        props.EncounterAction.setEncounterContext({});
        props.PopupActions.showWindow(PopupWindows.ENCOUNTER_WINDOW);
    }

    const encounterFilterCell = (props) => (
        <DropdownFilterCell
            {...props}
            data={['Draft', 'Signed']}
            defaultItem={"Select Encounter"}
        />
    );
    const statusFilterCell = (props) => (
        <DropdownFilterCellCustom
            {...props}
            data={['Coded', 'Coding']}
            //defaultItem={"Select Encounter"}
        />
    );

    const PatientName = () => {
        let pFirstName = pdfExportEncounter?.patientFirstName;
        let pLastName = pdfExportEncounter?.patientLastName;

        return <span>{pFirstName + " " + pLastName}</span>

    }

    const PDFExportPageTemplate = (props) => {
        return  <>
                    <hr/>
                    <table style={{marginLeft: "3%"}}>
                        <tbody>
                            <tr>
                                <td style={{"width": "93%"}}><span>{tenantName || "Phix Health"} </span></td>
                                <td><span>Page : &nbsp; {props.pageNum}</span></td>
                            </tr>
                            <tr>
                                <td style={{"width": "93%"}}>Printed:&nbsp;{moment(new Date()).format('YYYY-MM-DD')}</td>
                                <td> ID: {pdfExportEncounter?.encounterId}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
    };

    const SignedUser = (p) => {
        const item = p.item;
        const su = props.userList.find(v => v.userRoleId === item?.signedByUserRoleId);
        return <>
            <tr>Electronically Signed
                by &nbsp;{su?.firstName} &nbsp;{su?.lastName} &nbsp; on {moment(item?.signedDate).format('YYYY-MM-DD')} at &nbsp; {moment(item?.signedDate).format('hh:mm a')}</tr>
            <tr>{item?.addendum}</tr>
        </>
    }

    function flatten(arr) {
        return arr.reduce(function (flat, toFlatten) {
            return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
        }, []);
    }

    function getObject(theObject, key) {
        var result = null;
        if(Array.isArray(theObject)) {
            for(var i = 0; i < theObject.length; i++) {
                result = getObject(theObject[i], key);
                if (result) {
                    break;
                }
            }
        } else{
            for(var prop in theObject) {
                if(prop === key) {
                     return theObject[key];
                }
                if(typeof theObject[prop] === 'object') {
                    result = getObject(theObject[prop], key);
                    if (result) {
                        break;
                    }
                }
            }
        }
        return result;
    }

    function objToHtmlListInternal(obj, fSchema, fSchemaUI, ele) {
        const order = fSchemaUI ? fSchemaUI["ui:order"] : [];
        const schema = getObject(fSchema, ele)
        const data = obj[ele];
        if (Array.isArray(data)) {
            return<>
                    <tr>
                        <text> {schema?.title} </text>
                    </tr>
                    <ul>
                    {data.map((d,k1)=> {
                        if (typeof d === 'object') {
                            return <>{Object.entries(d).map(([k,v])=> {
                                return <li>{objToHtmlListInternal({[k]:v}, schema, fSchemaUI[k], k)}</li>
                            })}</>
                        } else {
                            const dis = d == 0 ? ""+d : d;
                            return dis && <li><text>{dis}</text></li>
                        }
                    })}
                    </ul>
                </>

        } else if (typeof data === 'object') {
            return<>
                    {schema?.title ? <><tr>
                        <text> {schema?.title} </text>
                    </tr>
                    <ul>
                    {
                        order && order.map(k=> {
                            const dis = data[k] == 0 ? ""+data[k] : data[k];
                            if (dis) {
                                return <li>{objToHtmlListInternal({[k]:data[k]}, schema, fSchemaUI[k],  k)}</li>
                            } else {
                                return <></>
                            }
                        })
                    }
                    </ul>
                    </> :
                        <>{order && order.map(k=> {
                            const dis = data[k] == 0 ? ""+data[k] : data[k];
                            if (dis) {
                                return <>{objToHtmlListInternal({[k]:data[k]}, schema, fSchemaUI[k],  k)}</>
                            } else {
                                return <></>
                            }
                        })}</>
                       }
                </>
        } else {
            if (!isNaN(data) && schema?.enum && schema?.enumNames) {
                 const index = schema.enum.indexOf(data);
                 const formDataValue = schema.enumNames[index];
                 return schema?.title && formDataValue && <tr>
                            <text>{schema?.title + ": " +  formDataValue}</text>
                           </tr>
            } else {
                 const dataToDisplay = typeof data === 'boolean'? (data === true ? 'Yes' : 'No') : data == 0 ? ""+data : data;
                 return schema?.title && dataToDisplay && <tr>
                         <text>{schema?.title + ": " +  dataToDisplay}</text>
                        </tr>
            }
        }

    }

    function objToHtmlList(obj, fSchema, fSchemaUI) {
        const order = fSchemaUI ? fSchemaUI["ui:order"] : [];
        return <>{order && order.map(ele=> {
            return objToHtmlListInternal(obj, fSchema, fSchemaUI[ele], ele)
        })}</>
    }

    const removeEmptyFieldsFromFamilyHistory = (fd, code) => {
        if (code === "ah_family_history") {
            for (var key in fd) {
                switch(key) {
                    case "AH_CHILD":
                    case "AH_SIBLINGS":
                        fd[key] = fd[key].filter(v=> {
                            const keys = Object.keys(v);
                            return keys.length > 1
                        })
                        break;
                    case "AH_FATHER":
                    case "AH_MOTHER":
                    case "AH_MATERNAL_GRANDMOTHER":
                    case "AH_MATERNAL_GRANDFATHER":
                    case "AH_PATERNAL_GRANDMOTHER":
                    case "AH_PATERNAL_GRANDFATHER":
                        if (Object.keys(fd[key]).length <= 1) {
                            delete fd[key];
                        }
                        break;
                }
            }
        }
        return fd;
    }

    const FormDataTable = (p) => {
        return <div style={{padding: "2%"}} id="pdf_encounter">
            <table style={{borderCollapse: "collapse"}}>
                <tbody>
                <tr>
                    <h5 style={{
                        borderBottom: "2px solid currentColor",
                        display: 'inline-block',
                        lineHeight: "1"
                    }}><text>{p.name.trim() +  " :"}</text></h5>
                </tr>
                {
                    objToHtmlList(removeEmptyFieldsFromFamilyHistory(p.fd, p.code), typeof p.fs === 'object' ? p.fs : JSON.parse(p.fs) , typeof p.fsUI === 'object' ? p.fsUI : JSON.parse(p.fsUI))
                }
                </tbody>
            </table>
        </div>;
    }

    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    const EncounterLinkedIssues = (p) => {
        const diagnosisCodes = pdfExportEncounter?.medicalProblems?.flatMap(v => v.diagnosisCode);
        const serviceLinesCodes = props?.pdfData?.encounterServiceLine?.flatMap(d => d?.diagnoses.flatMap(v => {
            if (typeof v === "object") {
                return v.selected ? [v.code] : [];
            } else {
                return [v]
            }
        }));
        let data = []
        if (_.isArray(serviceLinesCodes))
            data = (serviceLinesCodes && props?.medicalProblems?.map(v => {
                return {
                    "code": v.diagnosisCode.map(d => d.code).join(", "),
                    "displayName": v.diagnosisCode.length > 0 ? v.diagnosisCode.map(d => d.description).join(", ") : v.description,
                    "id": v.id
                }
            })?.filter(v => serviceLinesCodes?.indexOf(v.code) > -1)) || []
        return <div style={p?.style}> {getUniqueListBy(diagnosisCodes.concat(data), "code").map(v => {
            return <tr>
                    <td>{v.displayName}:</td>
                    <td></td>
                    <td>{v.code}</td>
                   </tr>
        })}</div>
    }
    const getSingleEvent = () => {
        let closestEventArr = [];
        let test1 = props?.patientScheduleData.filter(element => moment(element.startDate).utc().local().format('YYYY-MM-DD HH:MM') > moment().utc().local().format('YYYY-MM-DD HH:MM')).reduce(function (prev, curr) {
            return prev.startDate < curr.startDate ? prev : curr;
        }, {})
        if (Object.keys(test1).length != 0) {
            closestEventArr.push(test1)
        }

        return closestEventArr;
    }

    const NextAppointment = () => {
        let closestEventArr = getSingleEvent();
        let patientData = closestEventArr.map(function (data) {
            let date = moment.utc(data.startDate);
            let createdDate = moment.utc(data.endDate).local().startOf('day')
            let day = date.format("dddd, MMMM DD, YYYY hh:mm a")
            let current = moment().startOf('day');
            let diffInDays = moment.duration(createdDate.diff(current)).asDays();
            let isVideoExist = data.meetingFormats.find(v => v.code === "meeting_format_video")
            if (diffInDays >= 0) {
                return (<div>
                    <span style={{marginRight:"2%"}}>{day}</span>
                    <span>with</span>&nbsp;
                    <span>{pdfExportEncounter?.providerFirstName}&nbsp;{pdfExportEncounter?.providerLastName}</span>
                    {isVideoExist ? <>
                        <br></br><span>This is a video visit-you should receive details by email</span></> : <></>}
                </div>)
            } else {
                return null
            }
        })
        return patientData;
    }

    function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    const ActiveProblems = () => {
        const problems = props?.medicalProblems?.concat(props?.medications, props?.allergies);
        const groupByCategory = groupBy(problems)
    }
    const PDFExportPageTemplateForLimited = (props) => {
        return<>
                <hr/>
                <table style={{marginLeft: "5%"}}>
                    <tbody>
                    <tr>
                        <td style={{"width": "75%"}}><PatientName/></td>
                        <td><span>MRN : &nbsp; {patientContext?.medicalRecordNumber}</span></td>
                    </tr>
                    <tr>
                        <td style={{"width": "75%"}}> Visit ID: {pdfExportEncounter?.encounterId}</td>
                        <td>Printed:&nbsp;{moment(new Date()).utc().local().format('MM/DD/YYYY hh:mm a')}</td>
                    </tr>
                    </tbody>
                </table>
            </>;
    }


    const LimitedPDFContent = () => {
        const weight = props?.pdfData?.observations.find(v => v.dictObservationTypeCode === "AH_BODY_WEIGHT_MEASURED")?.value;
        return <PDFExport
            ref={pdfExportComponent}
            pageTemplate={PDFExportPageTemplateForLimited}
            paperSize="Letter"
            margin={{left: "1cm", top: "1cm", right: "1cm", bottom: "2.5cm"}}
            scale={0.6}
            fileName={`Report for ${new Date().getFullYear()}`}
            author="KendoReact Team"
        >
            <div id="adform" style={{minHeight: "30cm"}}>
                <table style={{width:"100%"}}>
                    <tr>
                        <th style={{width:"30%"}}></th>
                        <th style={{width:"70%"}}></th>
                    </tr>
                    <tr>
                        <td>
                            <img src={phixLogo} style={style.logo}/>
                        </td>
                        <td>
                            <h3 style={{textAlign: "left", marginLeft:"50px", marginTop:'55px'}}>Patient Visit Summary</h3>
                        </td>
                    </tr>
                </table>
                <hr></hr>
                <div>
                    <table style={{width: "100%"}}>
                        <tbody>
                            <tr>
                                <td>
                                    Name: &nbsp; <PatientName/>
                                </td>
                                <td>
                                    DOB: &nbsp; {moment(pdfExportEncounter.patientDateOfBirth).format('MM/DD/YYYY')}
                                </td>
                                <td>
                                    Phone: &nbsp; {patientContext?.phone ? <>{patientContext?.phone}</> : <></>}
                                </td>
                                <td>

                                    <i className={"fa fa-calendar"}/> &nbsp;  Date of Service: &nbsp;
                                    {moment(pdfExportEncounter.encounterDate).format('MM/DD/YYYY')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr></hr>
                <div style={{textAlign: "left"}}>
                    <table style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <td>
                                <h5 style={{textAlign: "left"}}><strong>Today's Visit</strong></h5>
                                <div>
                                    <span>You saw {' '}</span>
                                    <span>{' '}{pdfExportEncounter?.providerFirstName} {' '} {pdfExportEncounter?.providerLastName}</span>
                                    <span> on {' '}</span>
                                    <span>{moment(pdfExportEncounter.encounterDate).format('MM/DD/YYYY')} for: </span>&nbsp;
                                    <span>{pdfExportEncounter?.dictEncounterTypeDisplayName}{'. '}</span><br></br>
                                    <span>The following issues were addressed: </span>
                                </div>
                                <EncounterLinkedIssues/>
                            </td>
                            <td>
                                <h5 style={{textAlign: "left"}}><strong>Next Appointment</strong></h5>
                                <NextAppointment/>
                                <hr></hr>
                                <div>
                                    <table>
                                        <tr>
                                            <td>Blood Pressure</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Weight</td>
                                        </tr>
                                        <tr>
                                            <td><i
                                                class="fa fa-heartbeat" style={{"color":"rgb(255, 69, 0)"}}></i> &nbsp;{props?.pdfData?.observations.find(v => v.dictObservationTypeCode === "AH_SYSTOLIC_BP")?.value}&nbsp;/ {props?.pdfData?.observations.find(v => v.dictObservationTypeCode === "AH_DIASTOLIC_BP")?.value}
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <FaWeight style={{"color":"rgb(61, 133, 198)"}}/> &nbsp;{weight ? weight + " lbs": ""}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <hr></hr>
                <h5><strong>Instructions</strong></h5>
                <div>
                    <table>
                        <tr>
                            {props?.pdfData?.pharmacyNameAddress?.name && <td style={{width: "500px"}}>
                                <span>Pick up the following prescriptions at</span>&nbsp;
                                <span>{props?.pdfData?.pharmacyNameAddress?.name}</span>
                            </td>}
                            <td style={{textAlign: "center"}}>
                                <span><strong>Lab ordered today:</strong></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>{props?.pdfData?.pharmacyNameAddress?.address ? Object.values(props?.pdfData?.pharmacyNameAddress?.address).filter(v => v).join(", ") : ""}</span>
                            </td>
                        </tr>
                        <br></br>
                        <tr>
                            {props?.pdfData?.medicationPrescriptions?.map(v => <tr><td>{v}</td></tr>)}
                        </tr>
                    </table>
                </div>
                <br></br>
                <div>
                    <span>{' '}{pdfExportEncounter?.patientInstructions} {' '}</span>
                </div>
                <div>
                    <span>{' '}{pdfExportEncounter?.nextAppointment} {' '}</span>
                </div>
                <hr></hr>
                <div>
                    <h5><strong> Medical Problems </strong></h5>
                    <br></br>
                    {
                        props?.medicalProblems.filter(v=> ["FHIR_PROBLEM_ACTIVE", "FHIR_PROBLEM_RECURRENCE", "FHIR_PROBLEM_RELAPSE", "FHIR_PROBLEM_REMISSION"].indexOf(v.dictProblemStatusCode) > -1).map(v => <><span>{v.displayName || v.description}</span><br></br></>)
                    }
                    <br></br>
                    <h5><strong> Medications </strong></h5>
                    <br></br>
                    {
                        props?.medications.filter(v=> v.dictClinicalStatusCode === "AH_MEDICATION_CLINICAL_ACTIVE").map(v => <><span>{v.displayName || v.description}</span><br></br></>)
                    }
                    <br></br>
                    <h5><strong> Allergies </strong></h5>
                    <br></br>
                    {
                        props?.allergies.filter(v=> ["FHIR_PROBLEM_ACTIVE", "FHIR_PROBLEM_RECURRENCE", "FHIR_PROBLEM_RELAPSE", "FHIR_PROBLEM_REMISSION"].indexOf(v.dictClinicalStatusCode) > -1).map(v => <><span>{v.displayName || v.description}</span><br></br></>)
                    }
                    <br></br>
                    <h5><strong> Immunizations </strong></h5>
                    <br></br>
                    {
                        props?.immunizationData?.map(v => <><span>{v.dictVaccineCvxDisplayName}</span><br></br></>)
                    }
                    <br></br>
                </div>
            </div>
        </PDFExport>

    }

    const FullPDFContent = () => {
        return <PDFExport
            ref={pdfExportComponent}
            pageTemplate={PDFExportPageTemplate}
            paperSize="Letter"
            margin={{left: "1cm", top: "1cm", right: "1cm", bottom: "2.5cm"}}
            scale={0.6}
            fileName={`Report for ${new Date().getFullYear()}`}
            author="KendoReact Team"
        >
            <div style={{minHeight: "30cm"}}>
                <div style={{margin: "auto", padding: "10px"}}>
                    <table>
                        <tbody>
                        <tr>
                            <td><p>Patient Name: &nbsp; {
                                <PatientName/>} &nbsp;&nbsp; DOB:&nbsp;{moment(pdfExportEncounter.patientDateOfBirth).format('MM/DD/YYYY')} &nbsp; MRN: {patientContext?.medicalRecordNumber} &nbsp; Date
                                of Service: &nbsp; {moment(pdfExportEncounter.encounterDate).format('MM/DD/YYYY')}</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <h3 style={{textAlign: "center", padding: "10px"}}>Encounter Note</h3>
                <div>
                    <div style={{marginLeft: "1%", marginRight: "1%", border: "1px solid black"}}>
                        <div style={{padding: "2%"}}>
                            <table style={{borderCollapse: "collapse"}}>
                                <tbody>
                                <tr><strong>Patient Name: &nbsp;</strong> {<PatientName/>}</tr>
                                <tr><strong>MBI Number: &nbsp;</strong> {props?.patientDemographics?.mbiId}</tr>
                                <tr><strong>Date of
                                    Birth: &nbsp;</strong> {moment(pdfExportEncounter.patientDateOfBirth).format('YYYY-MM-DD')}
                                </tr>
                                <tr><strong>MRN: &nbsp;</strong> {patientContext?.medicalRecordNumber}
                                </tr>
                                <tr><strong>Sex: &nbsp;</strong> {patientContext?.dictGenderName}</tr>
                                {
                                    pdfExportEncounter?.facilityDisplayName ? <tr><strong>Facility
                                        Name:</strong> &nbsp; {pdfExportEncounter?.facilityDisplayName}</tr> : <></>
                                }
                                {
                                    props?.contactAddress ? <tr><strong>Facility
                                        Address: </strong> &nbsp;&nbsp; {[props?.pdfData?.contactAddress?.street1, props?.pdfData?.contactAddress?.street2, props?.pdfData?.contactAddress?.city, props?.pdfData?.contactAddress?.dictStateDisplayName, props?.pdfData?.contactAddress?.state, props?.pdfData?.contactAddress?.zipcode].filter(v => v && v.length > 0).join(", ")}
                                    </tr> : <></>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br></br>
                    <div style={{marginLeft: "1%", marginRight: "1%", border: "1px solid black"}}>
                        <div style={{padding: "2%"}}>
                            <table style={{"borderCollapse": "collapse"}}>
                                <tbody>
                                <tr>
                                    <span><strong>Date of service: &nbsp;</strong> {new Date(pdfExportEncounter.encounterDate).toLocaleDateString('en-US')}</span>
                                </tr>
                                <tr>
                                    <span><strong>Provider Name: &nbsp;</strong> {pdfExportEncounter?.providerFirstName} {' '}{pdfExportEncounter?.providerLastName}</span>
                                </tr>
                                <tr>
                                    <span><strong>Encounter Type: &nbsp;&nbsp;</strong> {" "} {pdfExportEncounter?.dictEncounterTypeDisplayName} {pdfExportEncounter?.telehealth === true ? " (Telehealth) " : ""}</span>
                                </tr>
                                <tr>
                                    <span><strong>POS Code: &nbsp; &nbsp;</strong>{props.dictionaries?.AH_POS?.find(v => v.id === pdfExportEncounter?.placeOfService)?.displayName}</span>
                                </tr>
                                <tr>
                                    <span><strong>Reason For Encounter: &nbsp;&nbsp;&nbsp;&nbsp; </strong> {pdfExportEncounter?.reasonForEncounter?.replaceAll(/(<([^>]+)>)/ig, '')}</span>
                                </tr>
                                <tr>
                                    <span><strong>HPI: &nbsp;&nbsp;&nbsp;&nbsp; </strong> {pdfExportEncounter?.hpi}</span>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br></br>
                    <div style={{marginLeft: "1%", marginRight: "1%", border: "1px solid black"}}>
                        <div style={{padding: "3%"}}>
                            <table style={{borderCollapse: "collapse"}}>
                                <tbody>
                                <tr>
                                    <h5 style={{
                                        borderBottom: "2px solid currentColor",
                                        display: 'inline-block',
                                        lineHeight: "1"
                                    }}>{"Encounter Linked Issues :"}</h5>
                                </tr>
                                <h6><strong>Medical Problems</strong></h6>
                                <div style={{marginLeft: "5%"}}>
                                    <EncounterLinkedIssues/>
                                </div>
                                <h6><strong>Medications</strong></h6>
                                <div style={{marginLeft: "5%"}}>
                                {
                                    pdfExportEncounter?.medications?.map(v => {
                                        return <tr>
                                                   <td>{v.displayName}:</td>
                                                   <td></td>
                                                   <td>{v.diagnosisCode ? v.diagnosisCode.map(d => d.code).join(", ") : ""}</td>
                                                  </tr>
                                    })
                                }
                                </div>
                                <h6><strong>Allergies</strong></h6>
                                <div style={{marginLeft: "5%"}}>
                                {
                                    pdfExportEncounter?.allergyProblem?.map(v => {
                                        return <tr>
                                                   <td>{v.displayName}:</td>
                                                   <td></td>
                                                   <td>{v.diagnosisCode ? v.diagnosisCode.map(d => d.code).join(", ") : ""}</td>
                                                  </tr>
                                    })
                                }
                                </div>
                                <h6><strong>Hospitalizations</strong></h6>
                                <div style={{marginLeft: "5%"}}>
                                {
                                    pdfExportEncounter?.hospitalization?.map(v => {
                                         return <tr>
                                                   <td>{v.description}:</td>
                                                   <td></td>
                                                   <td>{v.diagnosisCode ? v.diagnosisCode.map(d => d.code).join(", ") : ""}</td>
                                                  </tr>
                                    })
                                }
                                </div>
                                <h6><strong>Immunizations</strong></h6>
                                <div style={{marginLeft: "5%"}}>
                                {
                                    pdfExportEncounter?.immunizations?.map(v => {
                                         return <tr>
                                                   <td>{v.dictVaccineCvxDisplayName}:</td>
                                                   <td></td>
                                                   <td>{v.dictVaccineCvxCode}</td>
                                                  </tr>
                                    })
                                }
                                </div>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br></br>
                    {
                        props.pdfData?.formSchema?.filter(f => (f.code === "ah_pain_assessment" && f.dictFormCategoryCode === "AH_ASSESSMENT") || f.dictFormCategoryCode !== "AH_ASSESSMENT").map(form => {
                            return <>
                                <div style={{
                                    marginLeft: "1%",
                                    marginRight: "1%",
                                    border: "1px solid black"
                                }}>
                                    <FormDataTable fd={form.formData} name={form.displayName} code={form.code}
                                                   fs={form.formSchema} fsUI={form.formUiSchema}/>
                                </div>
                                <br></br>
                            </>
                        })
                    }
                    <div style={{marginLeft: "1%", marginRight: "1%", border: "1px solid black"}}>
                        <div style={{padding: "3%"}}>
                            <h5 style={{
                                borderBottom: "2px solid currentColor",
                                display: 'inline-block',
                                lineHeight: "1"
                            }}>{"Screenings/Assesments :"}</h5>
                            <table style={{borderCollapse: "collapse"}}>
                                <tbody>
                                {
                                    props?.pdfData?.assessments.map(v => {
                                        return v?.formDisplayName ? <tr>
                                            <td><span>{v?.formDisplayName}</span></td>
                                            <td></td>
                                            <td><span>{v?.assessmentValue}</span></td>
                                            <td></td>
                                            <td><span>{v?.assessmentResult}</span></td>
                                        </tr> : <></>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br></br>
                    <hr/>
                    <table style={{marginLeft: "5%"}}>
                        <tbody>
                        {
                            props?.pdfData?.eSignEncounter.map(v => {
                                return <tr>
                                    <SignedUser item={v}/>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </PDFExport>

    }


    return (
        <ShowForPermissionComponent component={true}
                                    permissions={[PhixPermissions.AH_VIEW_ENCOUNTER, PhixPermissions.AH_VIEW_ENCOUNTER_LOCKED, PhixPermissions.AH_VIEW_ENCOUNTER_SUMMARY]}>
            <div style={{margin: 0, width: "100%", height: "calc(100% - 55px)"}}>
                <Toolbar>
                    <Input
                        style={{width: "50%"}}
                        placeholder={"Search"}
                        id={"input-id"}
                        value={searchState}
                        onChange={(e) => setSearchState(e.value)}
                    />
                    <Button icon={"pi pi-ban"} text style={{margin: 0}} onClick={() => setSearchState("")}/>
                    <ToolbarItem>
                        <Button text
                                tooltip={"Toggle filtering"}
                                size={"small"}
                                onClick={() => setFilterState(!filterState)}
                                icon={(filterState) ? "pi pi-filter-fill" : "pi pi-filter"}/>
                    </ToolbarItem>
                    <ToolbarSpacer/>

                    <ToolbarItem>
                        <ShowForPermissionComponent permissions={[PhixPermissions.AH_EDIT_ENCOUNTER]}>
                            <Button tooltip="Start an unscheduled encounter"
                                    icon={"pi pi-plus"}
                                    text
                                    label={"New Encounter"}
                                    onClick={() => createEncounterHandler()}/>
                        </ShowForPermissionComponent>
                    </ToolbarItem>


                </Toolbar>
                <div className="col-12" style={{width: "100%", height: "100%"}}>
                    <Grid
                        {...dataState}
                        style={{height: "100%"}}
                        // data={data}
                        data={data && filterBy(orderBy(data, sort), filter)}
                        pageSize={dataState.limit}
                        total={totalCount}
                        filterable={filterState}
                        sortable={true}
                        skip={dataState.skip}
                        onRowClick={(item) => {
                            if (viewFullEncounterPermission)
                                gotoEncounterHandler(item)
                        }}
                        pageable={{
                            pageSizes: [50, 100, 150],
                            buttonCount: 10,
                            info: true,
                            type: "numeric",
                            previousNext: true,
                        }}
                        filter={filter}
                        onFilterChange={(e) => setFilter(e.filter)}
                        onDataStateChange={dataStateChange}

                        // sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}>
                        <GridColumn field="encounterDate" title="Date" cell={DateTimeGridCellWithFormat("MM/dd/yyyy")} width={100}/>
                        <GridColumn field="encounterNumber" title="ID" width={60}/>
                        <GridColumn field="dictEncounterTypeDisplayName" title="Encounter Type"/>
                        <GridColumn field="provider" title="Provider"/>
                        {/* <GridColumn field="reasonForEncounter" title="Reason" cell={encounterReasonCell} /> */}
                        <GridColumn title="Encounter Status" field="dictEncounterStatusCode" cell={lockIconCell}
                                    filterCell={encounterFilterCell} width={140}/>
                        {viewFullEncounterPermission &&
                            <GridColumn title="Coding Status" field="encounterCodingSaved" cell={codingLockIconCell}
                                        filterCell={statusFilterCell} width={135}/>}
                        <GridColumn title="Actions" cell={CommandCell}/>
                    </Grid>
                    {isEncounterPDFExporting && <FullPDFContent/>}
                    {isBriefEncounterPDFExporting && <LimitedPDFContent/>}
                </div>
            </div>
        </ShowForPermissionComponent>
    );
};

EncountersTabComponent.propTypes =
    {
        PatientActions: PropTypes.object,
        PopupActions: PropTypes.object,
    }

function mapStateToProps(state) {
    return {
        patientContext: state.patient.patientContext,
        contactAddress: state.person.contactAddress,
        encounters: state.patient.encounters,
        encounterAssessments: state.encounters.assessments,
        totalCount: state.patient.encounters.totalCount,
        loggedinUser: state.user.loggedinUser,
        dictionaries: state.dictionary.dictionaries,
        formList: state.form.formList.payload,
        adminFormList: state.form.adminFormList.payload,
        formElements: state.form.formElements,
        medicalProblems: state.patient.medicalProblems,
        medications: state.patient.medications,
        allergies: state.patient.allergies,
        hospitalization: state.patient.hospitalization,
        immunizationData: state.patient.immunizationData,
        tenantConfig: state.Configuration.tenant,
        patientDemographics: state?.BaseRequest?.patientModelDemographic,
        esignEncounters: state.encounters.esignEncounters,
        userList: state.user.userList,
        serviceLines: state.encounters.encounterCoding.serviceLines,
        permissionInfo: state.auth.session.permissions,
        patientScheduleData: state.schedule.patientScheduleData,
        pdfData: state.encounters.pdfData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        PatientActions: bindActionCreators(PatientAction, dispatch),
        PersonActions: bindActionCreators(PersonActions, dispatch),
        PopupActions: bindActionCreators(PopupWindowActions, dispatch),
        EncounterAction: bindActionCreators(EncounterAction, dispatch),
        DictionaryActions: bindActionCreators(DictionaryDispatchAction, dispatch),
        FormActions: bindActionCreators(FormAction, dispatch),
        BaseRequestAction: bindActionCreators(BaseRequestAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EncountersTabComponent)