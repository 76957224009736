import * as React from 'react';
import {useRef} from 'react';
import {Menu, MenuItem, AppBarSection} from '@progress/kendo-react-layout';
import {Popup} from "@progress/kendo-react-popup";
import ChangePassword from "./ChangePassword";
import MFASettingsComponent from "./MFASettingsComponent";
import {Button} from "@progress/kendo-react-buttons";
import ViewProfileComponent from "./ViewProfileComponent.js";
import LogoutComponent from "./LogoutComponent";
import {Col, Container, Row} from "react-grid-system";
import './SettingMenuBar.css';

const styles = {
    popup: {
        float: "right",
        marginRight: "10px",
        position: "relative",
        //zIndex: "100",
        boxShadow: "1rem 1rem 0.5rem grey",
        // borderRadius : "5px"
    },
    closeBtn: {
        float: "right",
        margin: "20px",
    },
    menuItem:
        {
            fontSize: "14px",
            fontFamily: "verdana",
            marginLeft: "0.7rem",
            marginTop: "0.5rem",
        }
}
const SettingsMenuComponent = props => {
    const anchor = React.useRef(null);
    const [showPopup, setShowPopup] = React.useState(false);
    const [showCP, setShowCP] = React.useState(false);
    const [showVP, setShowVP] = React.useState(false);
    const [showMFA, setShowMFA] = React.useState(false);
    const [showLogout, setShowLogout] = React.useState(false);
    const [showPreferences, setShowPreferences] = React.useState(false);

    const handleClick = e => {

        e.stopPropagation();
        if (node.current) {
            if (node.current.contains(e.target)
                || e.target.className.indexOf("k-item") != -1
                || e.target.className.indexOf("k-button") != -1
                || e.target.className.indexOf("k-list-optionlabel") != -1
            ) {
                return;
            } else
                closePopup();
        }
    };
    React.useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const closePopup = (e) => {
        setShowPopup(false);
        setShowVP(false);
        setShowCP(false);
        setShowMFA(false);
        setShowLogout(false);
        setShowPreferences(false);
    }
    const node = useRef();
    const onSelect = event => {

        closePopup();
        setShowPopup(true);
        if (event.item.text === "Change Password") {
            setShowCP(true);
        } else if (event.item.text === "View Profile") {
            setShowVP(true);
        } else if (event.item.text === "MFA Settings") {
            setShowMFA(true);
        } else if (event.item.text === "Preferences") {
            setShowPreferences(true);
        } else if (event.item.text === "Logout") {
            // props.history.push('/login')
            setShowLogout(true);
        } else {
            setShowPopup(false);
        }
    };
    const handleCancel = () => {
        setShowPopup(false);
        setShowLogout(false)
        ;
    }
    const renderComponent = () => {
        if (showCP) {
            return <ChangePassword/>;
        } else if (showVP) {
            return <ViewProfileComponent close={closePopup}/>;
        } else if (showMFA) {
            return <MFASettingsComponent close={closePopup}/>;
        } else if (showPreferences) {
            // return <CustomComp/>;
        } else if (showLogout) {
            return <LogoutComponent onCancel={handleCancel}/>
        }
    }
    const itemRender = (props) =>
            <div class="k-avatar k-rounded-full k-avatar-md k-avatar-solid k-avatar-solid-primary">
                <span class="k-avatar-image">
                    <img src="../images/user-pic.jpg" />
                </span>
            </div>;
    return <>
        <div ref={anchor}>
            <Menu onSelect={onSelect} style={{zIndex: "9999"}}>
                <MenuItem text="" Style={{color: "white"}} render={itemRender}>
                    <MenuItem text="" disabled={true}/>
                    <MenuItem text="View Profile" cssStyle={styles.menuItem}/>
                    <MenuItem text="_________________________" disabled={true}/>
                    <MenuItem text="Change Password" cssStyle={styles.menuItem}/>
                    <MenuItem text="MFA Settings" cssStyle={styles.menuItem}/>
                    <MenuItem text="_________________________" disabled={true}/>
                    <MenuItem text="Logout" cssStyle={styles.menuItem}/>
                    <MenuItem text="" disabled={true}/>
                </MenuItem>
            </Menu>

            <Popup anchor={anchor.current} show={showPopup} style={styles.popup}>
                <Container>
                    <Row>
                        <Col xs={10}/>
                        <Col xs={2}>
                            <Button icon={"k-icon k-i-close"} primary={true} style={styles.closeBtn} onClick={closePopup}/>
                        </Col>
                    </Row>
                    <Row>
                        {renderComponent()}
                    </Row>

                </Container>
            </Popup>
        </div>
    </>;
};

export default SettingsMenuComponent;